import React from 'react';
import { Link } from 'react-router-dom';

import PanelButton from 'components/PanelButton';

const RegistryContactValidationButtons: React.FC = () => {
  return (
    <div className="p-3 border-top">
      <PanelButton
        className="btn btn-secondary"
        forwardedAs={Link}
        to="/registro/contatos"
      >
        Ver meus contatos de registro
      </PanelButton>
    </div>
  );
};

export default RegistryContactValidationButtons;
