import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export default (reducers: Reducer): Reducer => {
  const rootPersistConfig = {
    key: 'painelhostnetwebts',
    storage,
    whitelist: ['auth', 'client', 'sites', 'options'],
    stateReconciler: autoMergeLevel2,
  };

  const persistedReducer = persistReducer(rootPersistConfig, reducers);

  return persistedReducer;
};
