import { useQuery } from 'react-query';

import api from 'services/api';

type UltramailResponse = {
  data: {
    total: string;
    utilizado: string;
    disponivel: string;
    possuiSiteComUltramailAtivo: boolean;
    percentualUtilizado: number;
    servidores: {
      site: string;
      webmail: string;
      mboxHost: string;
      smtpHost: string;
      mboxSslHost: string;
      smtpSslHost: string;
    }[];
    quotaDetalhada: {
      alocada: number;
      ativo: boolean;
      mbox: boolean;
      livre: number;
      percentualUtilizado: number;
      site: string;
      utilizada: number;
    }[];
    dominiosMtd: {
      id: string;
      domain: string;
      mbox: 'TRUE' | 'FALSE';
    }[];
  };
};

type Ultramail = {
  total: number;
  utilizado: number;
  disponivel: number;
  percentualUtilizado: number;
  possuiSiteComUltramailAtivo: boolean;
  servidores: {
    site: string;
    webmail: string;
    mboxHost: string;
    smtpHost: string;
    mboxSslHost: string;
    smtpSslHost: string;
  }[];
  quotaDetalhada: {
    site: string;
    ativo: boolean;
    mbox: boolean;
    alocada: number;
    utilizada: number;
    livre: number;
    percentualUtilizado: number;
  }[];
  dominiosMtd: {
    id: string;
    domain: string;
    mbox: 'TRUE' | 'FALSE';
  }[];
};

const initialData: Ultramail = {
  total: 0,
  utilizado: 0,
  disponivel: 0,
  percentualUtilizado: 0,
  possuiSiteComUltramailAtivo: false,
  servidores: [],
  quotaDetalhada: [],
  dominiosMtd: [],
};

function formatUltramail(ultramailResponse: UltramailResponse): Ultramail {
  const {
    disponivel,
    total,
    utilizado,
    possuiSiteComUltramailAtivo,
    percentualUtilizado,
    quotaDetalhada,
    servidores,
    dominiosMtd,
  } = ultramailResponse.data;

  const ultramail = {
    disponivel: parseFloat(disponivel),
    total: parseFloat(total),
    utilizado: parseFloat(utilizado),
    possuiSiteComUltramailAtivo,
    percentualUtilizado,
    quotaDetalhada,
    servidores,
    dominiosMtd,
  };

  return ultramail;
}

async function loadUltramail(idSite: number): Promise<Ultramail> {
  const { data } = await api.get<UltramailResponse>(
    `painel/v1/site/${idSite}/ultramail`,
  );

  const ultramail = formatUltramail(data);

  return ultramail;
}

export default function useUltramail(idSite: number) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  } = useQuery<Ultramail>(['ultramail', idSite], () => loadUltramail(idSite), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const ultramail = data || initialData;

  return {
    ultramail,
    error: isError,
    loading: isLoading,
    isFetching,
    isFetched,
    refetch,
  };
}
