import { useQuery } from 'react-query';

import api from 'services/api';

type UltramailDomainRedirectItemResponse = {
  dominio: string;
  emailDestino: string;
  emailOrigem: string;
  idAlias: string;
  idDominio: string;
  idRedirecionamento: string;
  redirecionamento: string;
};

type UltramailDomainRedirectsResponse = {
  data: UltramailDomainRedirectItemResponse[];
};

type UltramailDomainRedirect = {
  dominio: string;
  emailDestino: string;
  emailOrigem: string;
  idAlias: string;
  idDominio: string;
  idRedirecionamento: string;
  redirecionamento: string;
};

const initialData: UltramailDomainRedirect[] = [];

function formatUltramailDomainRedirect(
  emailRedirect: UltramailDomainRedirectItemResponse,
): UltramailDomainRedirect {
  return emailRedirect;
}

async function loadUltramailDomainRedirects(): Promise<
  UltramailDomainRedirect[]
> {
  const ultramailDomainRedirectResponse = await api.get<UltramailDomainRedirectsResponse>(
    `painel/v1/ultramail-dominio/redirecionamento`,
  );

  const ultramailDomainRedirects = ultramailDomainRedirectResponse.data.data.map(
    formatUltramailDomainRedirect,
  );

  return ultramailDomainRedirects;
}

export default function useUltramailDomainRedirects() {
  const { data, ...rest } = useQuery<UltramailDomainRedirect[]>(
    ['ultramailDomainsRedirect'],
    () => loadUltramailDomainRedirects(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ultramailDomainRedirects = data || initialData;

  return {
    ultramailDomainRedirects,
    ...rest,
  };
}
