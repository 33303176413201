import styled from 'styled-components';

export const Container = styled.div``;

export const Info = styled.div`
  font-size: 24px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.blue {
    color: var(--color-blue);
  }
`;
