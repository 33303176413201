import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Form, Row, Col, Card, InputGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';

import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PanelButton from 'components/PanelButton';

import { Container } from './styles';

interface AddCreditFormValues {
  credits: string;
}

interface AddCreditResponse {
  data: {
    urlPagamento: string;
  };
}

const AddCredit: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { idCliente, contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const addCredit = useCallback(
    async (values: AddCreditFormValues, formikHelpers) => {
      try {
        const valor: number = parseFloat(
          values.credits.replace(/\./, '').replace(/,/, '.'),
        );
        const addCreditResponse = await api.post<AddCreditResponse>(
          `/assine/v1/pagamento/credito`,
          {
            idCliente,
            valor,
            ...(contaComSite && { idSite }),
          },
        );
        window.open(addCreditResponse.data.data.urlPagamento);
      } catch (err) {
        swal.fire({
          title: t('pages:addCredit.requestFailed'),
          html: err.response.data.error_description,
        });
      } finally {
        formikHelpers.resetForm();
      }
    },
    [contaComSite, idCliente, idSite, t],
  );

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:addCredit.title')}
          description={t('pages:addCredit.description')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Como_faco_para_adicionar_o_credito"
        />
        <Formik
          enableReinitialize
          initialValues={{
            credits: '',
          }}
          validationSchema={Yup.object().shape({
            credits: Yup.string().test(
              'credits',
              t('pages:addCredit.invalidValue'),
              (value: string | undefined) => {
                if (!value) {
                  return false;
                }
                const valor: number = parseFloat(
                  value.replace(/\./, '').replace(/,/, '.'),
                );
                if (valor < 9.9) {
                  return false;
                }
                return true;
              },
            ),
          })}
          onSubmit={addCredit}
        >
          {props => {
            function handleOnValueChange(value: string | undefined): void {
              let credits = '';
              if (value !== '' && value !== undefined) {
                credits = value.search(/^[.,]/) === 0 ? `0${value}` : value;
              }
              props.setFieldValue('credits', credits);
            }
            return (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row} controlId="addCredit.credits">
                    <Form.Label column sm={2}>
                      {t('pages:addCredit.credits')}
                    </Form.Label>
                    <Col sm={10}>
                      <InputGroup className="mr-sm-2" size="sm">
                        <InputGroup.Prepend>
                          <InputGroup.Text>R$</InputGroup.Text>
                        </InputGroup.Prepend>
                        <CurrencyInput
                          intlConfig={{ locale: 'pt-br' }}
                          id="credits"
                          className="form-control"
                          name="credits"
                          placeholder="0,00"
                          disabled={props.isSubmitting}
                          onValueChange={handleOnValueChange}
                          onBlur={props.handleBlur}
                          decimalsLimit={2}
                          decimalScale={2}
                          maxLength={12}
                          allowNegativeValue={false}
                          value={props.values.credits}
                        />
                      </InputGroup>
                      <Form.Text className="text-muted">
                        {t('pages:addCredit.minCredit', { min: '9,90' })}
                      </Form.Text>
                      {props.errors.credits && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="d-block"
                        >
                          {props.errors.credits}
                        </Form.Control.Feedback>
                      )}
                    </Col>
                  </Form.Group>
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('pages:addCredit.addingCredit')
                      : t('pages:addCredit.addCredit')}
                  </PanelButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default AddCredit;
