import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Error from 'components/Error';
import Loading from 'components/Loading';
import TableWrapper from 'components/TableWrapper';
import TableSubHeader from 'components/TableSubHeader';
import TableHeader from 'components/TableHeader';
import TableButton from 'components/TableButton';
import TableNoData from 'components/TableNoData';

import hashIds from 'services/hashIds';

import useInternationalDomainTransfers from 'hooks/useInternationalDomainTransfers';

import HelpContent from './components/HelpContent';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type TransferEventsHistoryProps = RouteComponentProps<MatchParams>;

const TransferEventsHistory: React.FC<TransferEventsHistoryProps> = ({
  match,
}) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const {
    isLoading,
    isError,
    internationalTransfers,
  } = useInternationalDomainTransfers();

  const [transfer, setTransfer] = useState<
    typeof internationalTransfers[number]
  >({
    idDomain: 0,
    domain: '',
    destination: '',
    requestDate: '',
    type: '',
    status: '',
    statusDescription: '',
    authInfo: '',
    history: [],
  });

  const [error, setError] = useState(isError);

  useEffect(() => {
    setError(false);

    if (isLoading) {
      return;
    }

    const selectedTransfer = internationalTransfers.find(
      internationalTransfer => internationalTransfer.idDomain === idDomain,
    );

    if (selectedTransfer === undefined) {
      setError(true);
      return;
    }

    setTransfer(selectedTransfer);
  }, [idDomain, internationalTransfers, isLoading]);

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <TableWrapper>
        <TableHeader
          title={t('pages:internationalDomainTransferEvents.title')}
          description={t(
            'pages:internationalDomainTransferEvents.description',
            {
              dominio: transfer.domain,
            },
          )}
          startOpen="help"
          helpContent={<HelpContent />}
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/registro/transferencia">
              {t('common:back')}
            </TableButton>
          </div>
          <div />
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            columns={[
              {
                name: t('common:date'),
                width: '150px',
                selector: 'date',
              },
              {
                name: t('common:description'),
                selector: 'description',
              },
            ]}
            data={transfer.history}
            progressPending={isLoading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default withRouter(TransferEventsHistory);
