import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionExternalLink from 'components/SideMenu/components/SideMenuAccordionExternalLink';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { GlobeIcon } from './styles';

interface DomainRegistryOptionsProps {
  site: string;
}

const DomainRegistryOptions: React.FC<DomainRegistryOptionsProps> = () => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="6"
      text={t('components:sideMenu.domainRegistration')}
      icon={<GlobeIcon size={15} />}
      elements={[
        <SideMenuAccordionExternalLink
          text={t('components:sideMenu.howToRegisterMyDomain')}
          href="https://ajuda.hostnet.com.br/registro-de-dominio-na-hostnet/"
          target="_BLANK"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.domainRegister')}
          to="/registro/dominio-registrar"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.renewDomain')}
          to="/registro/renovar"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.registeredDomains')}
          to="/registro/dominios"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.domainContacts')}
          to="/registro/contatos"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.entitiesBR')}
          to="/registro/entidades-br"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.domainTransfer')}
          to="/registro/transferencia"
        />,
      ]}
    />
  );
};

export default DomainRegistryOptions;
