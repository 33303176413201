import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

export const Container = styled.div`
  .form-control[type='text'] {
    margin-top: -7px;
    margin-bottom: -7px;
    margin-left: -12px;
  }
  .form-control[name='sensitiveData'] {
    text-indent: 20px;
  }
  .invalid-feedback {
    margin-bottom: -12px;
  }
`;

export const VisibilityCheck = styled.span`
  cursor: pointer;
  position: absolute;
  left: 12px;
`;

export const BlackCircle = styled.span`
  &:after {
    content: '\\25CF\\25CF\\25CF\\25CF\\25CF\\25CF';
    position: relative;
    font-size: 10px;
    letter-spacing: 3px;
    left: 20px;
  }
`;

export const CustomInput = styled.div``;

export const ItemLabel = styled.h5`
  font-size: 18px;
  font-weight: 500;

  color: var(--color-blue);
`;

export const TabLink = styled(Link)`
  font-size: 13px;
  color: var(--color-midnight-blue);
  transition: all 0.3s ease-in-out;
`;

export const Item = styled(ListGroup.Item)`
  padding: 0.25rem 0.75rem;
  font-size: 11px;
  text-align: center;
  width: 100%;
  &.active {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    color: white;
  }
  &:focus {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
    outline: none;
    color: white;
  }
`;

export const x = styled.div`
  position: relative;
  :before {
    content: '';
    display: table-cell;
    table-layout: unset;
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: transparent;
    bottom: 78px;
    left: 0px;
    z-index: 1;
    box-shadow: inset 0 -100px 50px -50px white;
  }
`;

export const Description = styled.dl`
  > dt {
    font-weight: 400;
  }
  > dd {
    font-weight: 600;
  }
`;
