import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt, FaMoneyCheck } from 'react-icons/fa';
import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableIconButton from 'components/TableIconButton';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';

import api from 'services/api';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface InvoiceItemResponse {
  idNotaFiscal: number;
  email1: string;
  site: string;
  notaCarioca: string;
  dataEmissao: string;
  mesReferencia: string;
  anoReferencia: string;
  valor: string;
}

interface InvoiceResponse {
  data: InvoiceItemResponse[];
}

interface Invoice {
  idNotaFiscal: number;
  email1: string;
  site: string;
  notaCarioca: string;
  dataEmissao: string;
  mesReferencia: string;
  anoReferencia: string;
  valor: string;
}

const ListInvoices: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<Invoice[]>([]);
  const [invoicesFilterText, setInvoicesFilterText] = useState('');

  const { idCliente } = useSelector((state: StoreState) => state.client.info);

  useEffect(() => {
    async function loadInvoice() {
      try {
        setError(false);
        setLoading(true);

        const invoicesResponse = await api.get<InvoiceResponse>(
          `financeiro/v1/nota-fiscal`,
          { params: { idCliente } },
        );
        setInvoices(invoicesResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadInvoice();
  }, [idCliente]);

  useEffect(() => {
    setFilteredInvoices(
      filter(
        invoices,
        ['email1', 'site', 'dataEmissao', 'valor'],
        invoicesFilterText,
      ),
    );
  }, [invoices, invoicesFilterText]);

  const columns = [
    {
      name: t('common:account'),
      selector: 'email1',
      cell: (row: Invoice) => row.email1,
    },
    {
      name: t('common:site'),
      selector: 'site',
      cell: (row: Invoice) => row.site,
    },
    {
      name: t('pages:invoices.issueDate'),
      width: '200px',
      selector: 'dataEmissao',
      sortable: true,
      wrap: true,
      cell: (row: Invoice) => (
        <div className="d-flex justify-content-between w-100 align-items-center p-1">
          <div className="p-1">
            {format(parseISO(row.dataEmissao), 'dd/MM/yyyy')}
          </div>
          {row.notaCarioca && (
            <div className="ml-3">
              <TableIconButton
                tooltipConfig={{
                  id: 'display',
                  placement: 'right',
                  content: 'Visualizar nota fiscal',
                }}
                href={`${row.notaCarioca}`}
                target="_blank"
              >
                <FaMoneyCheck size={16} />
              </TableIconButton>
            </div>
          )}
        </div>
      ),
    },
    {
      name: t('pages:invoices.reference'),
      width: '150px',
      selector: 'referencia',
      cell: (row: Invoice) =>
        format(
          parseISO(
            `${row.anoReferencia}-${row.mesReferencia.padStart(2, '0')}`,
          ),
          "MMMM 'de' yyyy",
          {
            locale: pt,
          },
        ),
    },
    {
      name: t('common:label.value'),
      selector: 'valor',
      cell: (row: Invoice) => formatCurrency(parseFloat(row.valor)),
      width: '150px',
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:invoices.title')}
          description={<div>{t('pages:invoices.description')}</div>}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#A_Hostnet_emite_nota_fiscal_para_Boleto_Como_faco_para_receber"
        />

        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setInvoicesFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            highlightOnHover
            pagination
            columns={columns}
            data={filteredInvoices}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default ListInvoices;
