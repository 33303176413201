import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetSmsResponse = {
  data: {
    idCobranca: number;
    idSite: number;
    site: string;
    quantidadeMensal: number;
    quantidadeAdicional: number;
    quantidadeTotal: number;
    quantidadeConsumida: number;
    quantidadeExcedida: number;
    valorUnitario: number;
    valorTotal: number;
    saldo: number;
    mes: number;
    ano: number;
    consumoDiario: {
      [key: string]: number;
    };
  }[];
};

type RatchetSms = {
  idCobranca: number;
  idSite: number;
  site: string;
  quantidadeMensal: number;
  quantidadeAdicional: number;
  quantidadeTotal: number;
  quantidadeConsumida: number;
  quantidadeExcedida: number;
  valorUnitario: number;
  valorTotal: number;
  saldo: number;
  mes: number;
  ano: number;
  consumoDiario: {
    data: string;
    consumo: number;
  }[];
};

const initialData: RatchetSms = {
  idCobranca: 0,
  idSite: 0,
  site: '',
  quantidadeMensal: 0,
  quantidadeAdicional: 0,
  quantidadeTotal: 0,
  quantidadeConsumida: 0,
  quantidadeExcedida: 0,
  valorUnitario: 0,
  valorTotal: 0,
  saldo: 0,
  mes: 0,
  ano: 0,
  consumoDiario: [],
};

function formatResponse(response: RatchetSmsResponse): RatchetSms {
  const { consumoDiario, ...rest } = response.data[0];

  const keys = Object.keys(consumoDiario);

  const formattedConsumption: {
    data: string;
    consumo: number;
  }[] = [];

  keys.forEach(key => {
    formattedConsumption.push({
      data: `${key}/${rest.mes}/${rest.ano}`,
      consumo: consumoDiario[key],
    });
  });

  return {
    ...rest,
    consumoDiario: formattedConsumption,
  };
}

async function loadRatchetSms(
  idSite: number,
  month: string,
  year: string,
): Promise<RatchetSms> {
  const smsResponse = await api.get<RatchetSmsResponse>(
    `painel/v1/ratchet/cobranca`,
    {
      params: {
        idSite,
        idService: 16,
        mes: month,
        ano: year,
      },
    },
  );

  if (smsResponse.data.data.length > 0) {
    return formatResponse(smsResponse.data);
  }

  return initialData;
}

export default function useRatchetSms(idSite: number, month = '', year = '') {
  const { data, ...rest } = useQuery<RatchetSms>(
    ['ratchetSms', idSite, month, year],
    () => loadRatchetSms(idSite, month, year),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetSms = data || initialData;

  return {
    ratchetSms,
    ...rest,
  };
}
