import React from 'react';
import { Card } from 'react-bootstrap';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import Loading from 'components/Loading';

import PanelCard from '../PanelCard';

import { Container, NewsList, Item, Title, Date } from './styles';

interface Post {
  id: number;
  title: string;
  date: string;
  link: string;
}

interface NewsProps {
  posts: Post[];
  loading: boolean;
}

const News: React.FC<NewsProps> = ({ posts, loading }) => {
  if (loading) {
    return (
      <Container>
        <PanelCard>
          <Loading />
        </PanelCard>
      </Container>
    );
  }

  return (
    <Container>
      <PanelCard>
        <Card.Header>
          <h5>Novidades</h5>
        </Card.Header>
        <Card.Body className="p-0">
          <NewsList>
            {posts.map(post => (
              <Item key={post.id} className="border-bottom p-3">
                <Title>
                  <a rel="noopener noreferrer" target="_blank" href={post.link}>
                    {post.title}
                  </a>
                </Title>
                <Date className="text-muted">
                  {post.date !== ''
                    ? format(parseISO(post.date), 'dd/MM/yyyy', {
                        locale: pt,
                      })
                    : ''}
                </Date>
              </Item>
            ))}
          </NewsList>
        </Card.Body>
      </PanelCard>
    </Container>
  );
};

export default News;
