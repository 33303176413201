import React from 'react';

import { Container } from './styles';

interface FormSubtitleProps {
  subTitle: string;
}

const FormSubtitle: React.FC<FormSubtitleProps> = ({ subTitle }) => {
  return <Container>{subTitle}</Container>;
};

export default FormSubtitle;
