import produce from 'immer';
import { AuthAction } from '../auth/types';

import { OptionsAction, OptionsState } from './types';

type Action = OptionsAction | AuthAction;

const initialState: OptionsState = {
  list: [],
  error: false,
  loading: false,
};

export default function options(
  state = initialState,
  action: Action,
): OptionsState {
  return produce(state, draft => {
    switch (action.type) {
      case '@options/OPTIONS_REQUEST':
        draft.loading = true;
        draft.error = false;
        break;
      case '@options/OPTIONS_SUCCESS':
        draft.list = action.payload.options;
        draft.loading = false;
        draft.error = false;
        break;
      case '@auth/LOGOUT':
        draft = initialState;
        break;
      default:
        return draft;
    }

    return draft;
  });
}
