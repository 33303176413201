/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Card, Col, Form, ProgressBar, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import * as Yup from 'yup';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import hashIds from 'services/hashIds';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import useEmails from 'hooks/useEmails';
import useUltramail from 'hooks/useUltramail';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type EmailEditProps = RouteComponentProps<MatchParams>;

interface EmailItemResponse {
  id: string;
  mailAddr: string;
  dominio: string;
  quota: string;
  quotaGB: number;
  quotaUtilizada: string;
  porcentualUtilizado: string;
  bloqueado: boolean;
  mailBox: string;
  servidor: string;
  descricao: string;
}

interface LoadEmailResponse {
  data: EmailItemResponse[];
}

interface EditEmailQuotaResponse {
  data: {
    email: EmailItemResponse;
  };
}

interface EditEmailDescriptionResponse {
  data: EmailItemResponse[];
}

interface ChangePassFormValues {
  password: string;
  confirm: string;
}

interface EditEmailFormValues {
  quota: string;
  description: string;
}

interface Email {
  id: number;
  mailAddr: string;
  dominio: string;
  quota: number;
  quotaGB: number;
  quotaUtilizada: number;
  porcentualUtilizado: number;
  bloqueado: boolean;
  mailBox: string;
  servidor: string;
  descricao: string;
}

const Edit: React.FC<EmailEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idEmail] = hashIds.decode(match.params.id);
  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { refetch: refetchUltramail } = useUltramail(idSite);
  const { refetch: refetchEmails } = useEmails(idSite);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);

  const [email, setEmail] = useState<Email>({
    id: 0,
    mailAddr: '',
    dominio: '',
    quota: 0,
    quotaGB: 0,
    quotaUtilizada: 0,
    porcentualUtilizado: 0,
    bloqueado: false,
    mailBox: '',
    servidor: '',
    descricao: '',
  });

  function formatEmail({
    id,
    quota,
    quotaUtilizada,
    porcentualUtilizado,
    ...emailRest
  }: EmailItemResponse) {
    return {
      id: parseInt(id, 10),
      quota: parseInt(quota, 10),
      quotaUtilizada: parseInt(quotaUtilizada, 10),
      porcentualUtilizado: parseInt(porcentualUtilizado, 10),
      ...emailRest,
    };
  }

  useEffect(() => {
    async function loadEmail() {
      try {
        setLoading(true);
        setError(false);

        const emailResponse = await api.get<LoadEmailResponse>(
          `painel/v1/site/${idSite}/email/${idEmail}`,
        );

        if (emailResponse.data.data.length > 0) {
          setEmail(formatEmail(emailResponse.data.data[0]));
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadEmail();
  }, [idEmail, idSite]);

  async function editEmail(values: EditEmailFormValues) {
    try {
      setUpdating(true);

      const { quota, description } = values;

      let updatedEmail = null;

      if (description !== email.descricao) {
        const editEmailDescriptionResponse = await api.put<EditEmailDescriptionResponse>(
          `painel/v1/site/${idSite}/email/${idEmail}`,
          {
            descricao: description,
          },
        );

        [updatedEmail] = editEmailDescriptionResponse.data.data;
      }

      if (parseInt(quota, 10) !== email.quota) {
        const editEmailQuotaResponse = await api.put<EditEmailQuotaResponse>(
          `painel/v1/site/${idSite}/email/${idEmail}`,
          {
            tamanho: quota,
          },
        );

        updatedEmail = editEmailQuotaResponse.data.data.email;
      }

      if (updatedEmail) {
        setEmail(formatEmail(updatedEmail));
        refetchEmails();
        refetchUltramail();
      }

      toast.fire(t('pages:emailEdit.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:emailEdit.failed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function changePassword(
    values: ChangePassFormValues,
    formikHelpers: FormikHelpers<ChangePassFormValues>,
  ) {
    try {
      setUpdatingPassword(true);

      const { password } = values;

      await api.put(`painel/v1/site/${idSite}/email/${idEmail}`, {
        senha: password,
      });

      toast.fire(t('pages:emailEdit.changePassSuccess'));

      formikHelpers.resetForm();
    } catch (err) {
      swal.fire({
        title: t('pages:emailEdit.changePassFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdatingPassword(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader title={t('pages:emailEdit.formTitle')} description="" />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            quota: String(email.quota),
            description: email.descricao,
          }}
          validationSchema={Yup.object().shape({
            quota: Yup.number()
              .typeError(t('pages:emailEdit.validation.informValidNumber'))
              .required(t('pages:emailEdit.validation.informValidNumber'))
              .min(1024, t('pages:emailEdit.validation.sizeMin')),
          })}
          onSubmit={editEmail}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:emailEdit.diskSpace')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={
                        email.mailBox && email.mailBox.replace('=', '@')
                      }
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="email.currentQuota">
                  <Form.Label column sm={2}>
                    {t('pages:emailEdit.usedQuotaTitle', {
                      email: email.mailBox && email.mailBox.replace('=', '@'),
                    })}
                  </Form.Label>
                  <Col sm={10}>
                    <ProgressBar now={email.porcentualUtilizado} />
                    <Form.Text className="text-muted">
                      <Trans
                        i18nKey="pages:emailEdit.usedQuota"
                        values={{
                          quota: email.quota,
                          porcentual: email.porcentualUtilizado,
                          utilizada: email.quotaUtilizada,
                        }}
                      />
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="email.newQuota">
                  <Form.Label column sm={2}>
                    {t('pages:emailEdit.diskSpace')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="quota"
                      placeholder={t('pages:emailEdit.enterDiskSpace')}
                      value={props.values.quota}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.quota && !!props.errors.quota}
                      disabled={updating}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:emailEdit.minimumSize')}.
                    </Form.Text>

                    <Form.Control.Feedback type="invalid">
                      {props.errors.quota}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:emailEdit.emailDescription')}
                />

                <Form.Group as={Row} controlId="email.description">
                  <Form.Label column sm={2}>
                    {t('common:label.description')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.description &&
                        !!props.errors.description
                      }
                      disabled={updating}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:emailEdit.emailDescriptionExample')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  size="sm"
                  disabled={updating || !props.isValid || !props.dirty}
                >
                  {updating ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/emails"
                  disabled={updating}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:emailEdit.changePassFormTitle')}
          description=""
        />

        <Formik
          validateOnMount
          initialValues={{
            password: '',
            confirm: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(t('validations.password'))
              .min(8, t('validations.passwordMin'))
              .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
              .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
              .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
              .matches(
                RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                t('validations.passwordChar'),
              ),
            confirm: Yup.string()
              .required(t('validations.passwordConfirm'))
              .oneOf(
                [Yup.ref('password'), null],
                t('validations.invalidPasswordConfirm'),
              ),
          })}
          onSubmit={changePassword}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="email.Password">
                  <Form.Label column sm={2}>
                    {t('common:password')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.password && !!props.errors.password
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('common.matchPassword')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="email.ConfirmPassword">
                  <Form.Label column sm={2}>
                    {t('common:confirmPassword')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="confirm"
                      value={props.values.confirm}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirm && !!props.errors.confirm
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirm}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  disabled={updatingPassword || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
