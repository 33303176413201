import { useQuery } from 'react-query';

import api from 'services/api';

interface CronJobItemResponse {
  active: boolean;
  content: string;
  description: string;
  idCronJob: string;
  mailTo: string;
}

interface CronJobsResponse {
  data: CronJobItemResponse[];
}

interface CronJob {
  idCronJob: number;
  content: string;
  active: boolean;
  description: string;
  mailTo: string;
}

const initialData: CronJob[] = [];

function formatCronJob({ idCronJob, ...rest }: CronJobItemResponse) {
  return {
    idCronJob: parseInt(idCronJob, 10),
    ...rest,
  };
}

async function loadCronJobs(idSite: number) {
  const cronJobsResponse = await api.get<CronJobsResponse>(
    `painel/v1/site/${idSite}/cron-job`,
  );
  const formattedCronJobs = cronJobsResponse.data.data.map(formatCronJob);

  return formattedCronJobs;
}

export default function useCronJobs(idSite: number) {
  const { data, ...rest } = useQuery<CronJob[]>(
    ['cronJobs', idSite],
    () => loadCronJobs(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const cronJobs = data || initialData;

  return { cronJobs, ...rest };
}
