import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Card, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';
import Loading from 'components/Loading';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useSiteQuota from 'hooks/useSiteQuota';

import { Container, Range } from './styles';

const Quota: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { quota, refetch, isLoading, isError } = useSiteQuota(idSite);

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  type SiteQuotaFormValues = {
    quotaDiscos: typeof quota.quotaSite.discos;
  };

  type EmailQuotaFormValues = {
    quotaEmails: typeof quota.quotaEmail.emails;
  };

  async function handleSiteQuotaSubmit(values: SiteQuotaFormValues) {
    try {
      const data = values.quotaDiscos.map(quotaSite => {
        return {
          quotaAlocadaMB: quotaSite.quotaAlocadaMB,
          conta: quotaSite.conta,
          servidor: quotaSite.servidor,
          quotaUtilizadaMB: quotaSite.quotaUtilizadaMB,
        };
      });

      await api.post(`painel/v1/site/${idSite}/quota/site`, {
        disco: data,
      });

      refetch();

      toast.fire(t('pages:quota.siteQuotaRequestSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:quota.siteQuotaRequestFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  async function handleEmailQuotaSubmit(values: EmailQuotaFormValues) {
    try {
      const data = values.quotaEmails.map(quotaEmail => {
        return {
          alocadaGB: quotaEmail.alocadaGB,
          ativo: quotaEmail.ativo,
          livreGB: quotaEmail.livreGB,
          percentualUtilizado: quotaEmail.percentualUtilizado,
          site: quotaEmail.site,
          utilizadaGB: quotaEmail.utilizadaGB,
        };
      });

      await api.post(`painel/v1/site/${idSite}/quota/email`, {
        email: data,
      });

      refetch();

      toast.fire(t('pages:quota.emailQuotaRequestSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:quota.emailQuotaRequestFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      {quota.quotaSite.discos.length > 0 && (
        <FormWrapper>
          <FormHeader
            title={t('pages:quota.siteTitle')}
            description={t('pages:quota.siteDescription')}
            directHelpLink="https://ajuda.hostnet.com.br/quota-de-espaco-no-painel-de-controle/"
          />
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{
              quotaDiscos: quota.quotaSite.discos,
            }}
            onSubmit={handleSiteQuotaSubmit}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  {!quota.quotaSite.planoIlimitado && (
                    <div>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:quota.planTotal')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue={`${quota.quotaSite.espacoDiscoPlanoMB} MB`}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:quota.totalContracted')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Control
                            plaintext
                            readOnly
                            defaultValue={`${quota.quotaSite.espacoDiscoServicosAdicionaisMB} MB`}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  )}
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:quota.totalAllocated')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={`${quota.quotaSite.espacoDiscoTotalAlocadoMB} MB`}
                      />
                    </Col>
                  </Form.Group>

                  {quota.quotaSite.discos.map((disco, index) => {
                    return (
                      <div key={disco.conta}>
                        <hr />
                        <div className="my-3" />
                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('common:label.account')}
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={disco.conta}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('pages:quota.server')}
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={disco.servidor}
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('pages:quota.quota')}
                          </Form.Label>
                          {!quota.quotaSite.planoIlimitado ? (
                            <Col sm={10} className="position-relative">
                              <Form.Control
                                readOnly
                                value={
                                  props.values.quotaDiscos[index].quotaAlocadaMB
                                }
                              />
                              <Range
                                min="0"
                                max={quota.quotaSite.espacoDiscoTotalMB}
                                step="1"
                                defaultValue={disco.quotaAlocadaMB}
                                onChange={e => {
                                  const newQuota = props.values.quotaDiscos;

                                  newQuota[index] = {
                                    ...props.values.quotaDiscos[index],
                                    quotaAlocadaMB: parseFloat(e.target.value),
                                  };

                                  props.setFieldValue(
                                    'quotaDiscos',
                                    props.values.quotaDiscos,
                                  );
                                }}
                              />
                              <div
                                className="support"
                                style={{
                                  width: `${
                                    (disco.quotaAlocadaMB /
                                      quota.quotaSite.espacoDiscoTotalMB) *
                                    100
                                  }%`,
                                }}
                              />
                              <Form.Text className="text-muted">
                                {t('pages:quota.quotaTextMB')}
                              </Form.Text>
                            </Col>
                          ) : (
                            <Col sm={10}>
                              <Form.Control
                                plaintext
                                readOnly
                                defaultValue="Ilimitado"
                              />
                            </Col>
                          )}
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('pages:quota.usedQuota')}
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={`${disco.quotaUtilizadaMB} MB`}
                            />
                            <Form.Text className="text-muted">
                              {t('pages:quota.usedQuotaText')}
                            </Form.Text>
                          </Col>
                        </Form.Group>
                      </div>
                    );
                  })}
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton type="submit" disabled={props.isSubmitting}>
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}

      {quota.quotaEmail.emails.length > 0 && (
        <FormWrapper>
          <FormHeader
            title={t('pages:quota.emailTitle')}
            description={t('pages:quota.emailDescription')}
            directHelpLink="https://ajuda.hostnet.com.br/quota-de-espaco-no-painel-de-controle/"
          />
          <Formik
            validateOnMount
            enableReinitialize
            initialValues={{
              quotaEmails: quota.quotaEmail.emails,
            }}
            onSubmit={handleEmailQuotaSubmit}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:quota.planTotal')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={`${quota.quotaEmail.emailPlanoGB} GB`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:quota.totalContracted')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={`${quota.quotaEmail.emailContratadoGB} GB`}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:quota.totalAllocated')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={`${quota.quotaEmail.emailAlocadoGB} GB`}
                      />
                    </Col>
                  </Form.Group>

                  {quota.quotaEmail.emails.map((email, index) => {
                    if (!email.ativo) {
                      return <div key={`email-${email.site}`} />;
                    }

                    return (
                      <div key={`email-${email.site}`}>
                        <hr />
                        <div className="my-3" />
                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('common:label.site')}
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={email.site}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label column sm={2}>
                            {t('pages:quota.quota')}
                          </Form.Label>
                          <Col sm={10} className="position-relative">
                            <Form.Control
                              readOnly
                              value={quota.quotaEmail.emails[index].alocadaGB}
                            />
                            <Range
                              disabled={props.isSubmitting}
                              min="1"
                              max={quota.quotaEmail.emailTotalGB}
                              step=".5"
                              value={email.alocadaGB}
                              onChange={e => {
                                const newEmailsQuota = quota.quotaEmail.emails;

                                newEmailsQuota[index] = {
                                  ...newEmailsQuota[index],
                                  alocadaGB: parseFloat(e.target.value),
                                };

                                props.setFieldValue(
                                  'quotaEmails',
                                  props.values.quotaEmails,
                                );
                              }}
                            />
                            <div
                              className="support"
                              style={{
                                width: `${
                                  ((email.alocadaGB - 1) /
                                    (quota.quotaEmail.emailTotalGB - 1)) *
                                  100
                                }%`,
                              }}
                            />
                            <Form.Text className="text-muted">
                              {t('pages:quota.quotaTextGB')}
                            </Form.Text>
                          </Col>
                        </Form.Group>
                      </div>
                    );
                  })}
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton type="submit" disabled={props.isSubmitting}>
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}
    </Container>
  );
};

export default Quota;
