import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';

export const Container = styled.div`
  @media only screen and (max-width: 991px) {
    display: flex;
    align-items: center;

    transition: 0.5s;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &.open {
      height: 65px;
      opacity: 1;
      visibility: visible;
      overflow: initial;
    }
  }
`;

export const DropdownMenu = styled(NavDropdown)`
  a {
    color: var(--color-black);
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 17em;
    padding: 15px 0;
    transform: translateY(30px);

    left: 16px;

    visibility: visible;
    overflow: visible;

    border: none;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);

    &:before {
      content: '';
      border: 10px solid transparent;
      border-left-color: #fff;
      border-top-color: #fff;
      position: absolute;
      border-radius: 3px;
      top: -8px;
      left: 15px;
      transform: rotate(45deg);
      z-index: 1002;
    }
  }
`;

export const DropDownContent = styled.div`
  .dropdown-item {
    font-size: 15px;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #f1f1f1;
    }

    &:active {
      color: var(--color-black);
    }
  }
`;
