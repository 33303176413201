import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCogs } from 'react-icons/fa';
import { Form, Card } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';

import { Container } from './styles';

const Mautic: React.FC = () => {
  const { t } = useTranslation();

  const action = 'https://apps.hostnet.com.br/login';

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dadosCliente, setDadosCliente] = useState('');

  const client = useSelector((state: StoreState) => state.client.info);
  const site = useSelector((state: StoreState) => state.site.info);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    async function getDadosCliente() {
      try {
        setError(false);
        const data = {
          site: site.site,
          idSite: site.idSite,
          idCliente: client.idCliente,
        };
        const response = await api.post(`painel/v1/apps/cript`, data);
        setDadosCliente(response.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getDadosCliente();
  }, [client, site]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          directHelpLink="https://ajuda.hostnet.com.br/instalando-o-mautic-na-hostnet/"
          title={t('pages:mautic.title')}
          description={t('pages:mautic.description')}
        />
        <Form ref={formRef} method="POST" action={action} target="_BLANK">
          <Card.Body className="fieldset">
            <p>{t('pages:mautic.mauticDescription.p1')}</p>
            <p>{t('pages:mautic.mauticDescription.p2')}</p>
            <p>
              {t('pages:mautic.mauticDescription.p3')}
              <br />
              <a
                href="https://ajuda.hostnet.com.br/instalando-o-mautic-na-hostnet/"
                target="_blank"
                rel="noreferrer"
              >
                https://ajuda.hostnet.com.br/instalando-o-mautic-na-hostnet/
              </a>
            </p>
            <p>
              {t('pages:mautic.mauticDescription.p4')}
              <br />
              <a
                href="http://hostnet.com/cursomautic"
                target="_blank"
                rel="noreferrer"
              >
                http://hostnet.com/cursomautic
              </a>
            </p>
            <p>
              {t('pages:mautic.mauticDescription.p5')}
              <br />
              <a
                href="http://hostnet.com/mauticfacebook"
                target="_blank"
                rel="noreferrer"
              >
                http://hostnet.com/mauticfacebook
              </a>
            </p>
            <input type="hidden" name="StOrigem" value="Painel" />
            <input type="hidden" name="StApp" value="Mautic" />
            <input name="DadosCliente" value={dadosCliente} type="hidden" />
          </Card.Body>
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton type="submit" className="mr-1" disabled={false}>
              {t('pages:mautic.accessInstaller')}
            </PanelButton>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default Mautic;
