import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { MoneyIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface FinanceOptionsProps {
  site: string;
  options: Option[];
}

const FinanceOptions: React.FC<FinanceOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="2"
      text={t('components:sideMenu.finance')}
      icon={<MoneyIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.addCredit')}
          to="/financeiro/adicionar-credito"
        />,
        options.some(option => option.codigo === 'notas.fiscais') && (
          <SideMenuAccordionLink
            text={t('components:sideMenu.invoices')}
            to="/financeiro/notas-fiscais"
          />
        ),
        <SideMenuAccordionLink
          text={t('components:sideMenu.financeStatements')}
          to="/financeiro/extrato"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.bankSlipHistory')}
          to="/financeiro/historico-boletos"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.periodicity')}
          to="/financeiro/forma-pagamento"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.paymentMethod')}
          to="/financeiro/forma-pagamento"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.creditCards')}
          to="/financeiro/cartoes-credito"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.suspensionPause')}
          to="/financeiro/suspender-congelamento"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.financialEntities')}
          to="/financeiro/entidades"
        />,
      ]}
    />
  );
};

export default FinanceOptions;
