import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetHttpTrafficItem = {
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetHttpTrafficResponse = {
  data: {
    total: number;
    totalKb: number;
    totalMb: number;
    totalGb: number;
    trafegoDiario: {
      [key: string]: RatchetHttpTrafficItem;
    };
  };
};

type RatchetHttpTrafficDay = {
  date: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetHttpTraffic = {
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
  trafegoDiario: RatchetHttpTrafficDay[];
};

const initialData: RatchetHttpTraffic = {
  total: 0,
  totalKb: 0,
  totalMb: 0,
  totalGb: 0,
  trafegoDiario: [],
};

function formatResponse(
  ratchetHttpTrafficResponse: RatchetHttpTrafficResponse,
): RatchetHttpTraffic {
  const statistics = ratchetHttpTrafficResponse.data.trafegoDiario;

  const isObject = typeof statistics === 'object';
  const isNotArray = !Array.isArray(statistics);
  const isNotNull = statistics !== null;

  const trafficByDay: RatchetHttpTrafficDay[] = [];

  if (isObject && isNotArray && isNotNull) {
    const dateKeys = Object.keys(statistics);

    dateKeys.forEach(dateKey => {
      trafficByDay.push({ ...statistics[dateKey], ...{ date: dateKey } });
    });
  }

  return {
    total: ratchetHttpTrafficResponse.data.total,
    totalKb: ratchetHttpTrafficResponse.data.totalKb,
    totalMb: ratchetHttpTrafficResponse.data.totalMb,
    totalGb: ratchetHttpTrafficResponse.data.totalGb,
    trafegoDiario: trafficByDay,
  };
}

async function loadRatchetHttpTraffic(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetHttpTraffic> {
  const httpTrafficResponse = await api.get<RatchetHttpTrafficResponse>(
    `painel/v1/ratchet/trafego/http`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const httpTraffic = formatResponse(httpTrafficResponse.data);

  return httpTraffic;
}

export default function useRatchetHttpTraffic(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetHttpTraffic>(
    ['ratchetHttpTraffic', site, initialDate, finalDate],
    () => loadRatchetHttpTraffic(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetHttpTraffic = data || initialData;

  return {
    ratchetHttpTraffic,
    ...rest,
  };
}
