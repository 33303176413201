import React from 'react';
import { ListGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';

interface DnsModalProps {
  open: boolean;
  loading: boolean;
  dns: string[];
  hide: () => void;
}

const DnsModal: React.FC<DnsModalProps> = ({
  open,
  loading,
  hide,
  dns = [],
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={open} onHide={hide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:siteRedirects.dnsServers')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <ListGroup variant="flush">
            {dns.map(value => (
              <ListGroup.Item key={value}>{value}</ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {!loading && dns.length === 0 && <p>{t('common:noDataToList')}</p>}
      </Modal.Body>
      <Modal.Footer>
        <PanelButton variant="primary" onClick={hide}>
          {t('common.close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DnsModal;
