import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { StyledComponentProps } from 'styled-components';

import { StyledTableIconButton } from './styles';

type TableIconButtonProps = StyledComponentProps<Button, {}, {}, ''> & {
  tooltipConfig?: {
    id: string;
    placement: 'top' | 'bottom' | 'right' | 'left';
    content: React.ReactNode;
  };
};

const TableIconButton: React.FC<TableIconButtonProps> = ({
  tooltipConfig,
  ...rest
}) => {
  if (!tooltipConfig) {
    return <StyledTableIconButton {...rest} />;
  }

  return (
    <OverlayTrigger
      placement={tooltipConfig.placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip id={tooltipConfig.id}>{tooltipConfig.content}</Tooltip>}
    >
      <StyledTableIconButton {...rest} />
    </OverlayTrigger>
  );
};

export default TableIconButton;
