import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaDatabase } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { StoreState } from 'store/createStore';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import api from 'services/api';
import toast from 'services/toast';
import history from 'services/history';
import swal from 'services/swal';

import useDatabases from 'hooks/useDatabases';
import { Container } from './styles';

interface SuggestionResponse {
  data: {
    banco: string;
    usuario: string;
  };
}

interface Suggestion {
  banco: string;
  usuario: string;
}

interface NewDatabaseFormValues {
  password: string;
  confirm: string;
  description: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { refetch } = useDatabases(idSite);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [suggestion, setSuggestion] = useState<Suggestion>({
    banco: '',
    usuario: '',
  });

  useEffect(() => {
    async function loadSuggestion() {
      try {
        setLoading(true);
        setError(false);

        const response = await api.get<SuggestionResponse>(
          `painel/v1/site/${idSite}/banco-mysql/sugestao`,
        );

        setSuggestion({
          banco: response.data.data.banco,
          usuario: response.data.data.usuario,
        });
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSuggestion();
  }, [idSite]);

  const createDatabase = useCallback(
    async (
      values: NewDatabaseFormValues,
      formikHelpers: FormikHelpers<NewDatabaseFormValues>,
    ) => {
      try {
        const { password, description } = values;

        await api.post(`painel/v1/site/${idSite}/banco-mysql`, {
          senha: password,
          descricao: description,
        });

        await refetch();

        toast.fire(t('pages:databasesMySqlCreate.success'));

        formikHelpers.resetForm();

        history.push(`/banco-de-dados-mysql`);
      } catch (err) {
        swal.fire({
          title: t('pages:databasesMySqlCreate.failed'),
          html: err.response.data.error_description,
        });
      }
    },
    [idSite, refetch, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:databasesMySqlCreate.title')}
          description=""
          directHelpLink="https://ajuda.hostnet.com.br/mysql"
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            password: '',
            confirm: '',
            description: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(t('validations.password'))
              .min(8, t('validations.passwordMin'))
              .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
              .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
              .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
              .matches(
                RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                t('validations.passwordChar'),
              ),
            confirm: Yup.string()
              .required(t('validations.passwordConfirm'))
              .oneOf(
                [Yup.ref('password'), null],
                t('validations.invalidPasswordConfirm'),
              ),
          })}
          onSubmit={createDatabase}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="form-database-name">
                  <Form.Label column sm={2}>
                    {t('common:label.database')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={suggestion.banco}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="form-database-user">
                  <Form.Label column sm={2}>
                    {t('common:label.user')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={suggestion.usuario}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="database.Password">
                  <Form.Label column sm={2}>
                    {t('common:label.password')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.password && !!props.errors.password
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('common.matchPassword')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="database.ConfirmPassword">
                  <Form.Label column sm={2}>
                    {t('common:label.confirmPassword')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="confirm"
                      value={props.values.confirm}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirm && !!props.errors.confirm
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirm}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="database.description">
                  <Form.Label column sm={2}>
                    {t('common:label.description')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.description &&
                        !!props.errors.description
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.description}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('pages:databasesMySqlCreate.descriptionTip')}
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/banco-de-dados-mysql"
                  disabled={props.isSubmitting}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
