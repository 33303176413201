import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FaCloud } from 'react-icons/fa';

import PageTitle from 'components/PageTitle';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';
import history from 'services/history';

import { StoreState } from 'store/createStore';

import Loading from 'components/Loading';

const Confirm: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const site = urlSearchParams.get('site');
  const token = urlSearchParams.get('tokenConfirmacao');

  const { list: sites } = useSelector((state: StoreState) => state.sites);

  const confirmSite = sites.find(accountSite => accountSite.site === site);

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    async function confirmCronJob() {
      try {
        setUpdating(true);

        if (!confirmSite || !token || !site) {
          return;
        }

        await api.post(
          `painel/v1/site/${confirmSite.idSite}/cron-job/confirmacao`,
          {
            tokenConfirmacao: token,
          },
        );

        toast.fire('Cronjob confirmado com sucesso');
      } catch (err) {
        swal.fire({
          title: 'Ação não concluída',
          html: 'Não foi possível confirmar o e-mail',
          icon: 'error',
        });
      } finally {
        setUpdating(false);
        history.push('/cloud/crontab');
      }
    }

    confirmCronJob();
  }, [confirmSite, site, token]);

  return (
    <div>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      {updating && <Loading />}
    </div>
  );
};

export default Confirm;
