import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaGlobeAmericas } from 'react-icons/fa';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import Error from 'components/Error';
import Loading from 'components/Loading';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';

import api from 'services/api';
import hashIds from 'services/hashIds';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type BuyProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
    promocao: 'TRUE' | 'FALSE';
  }[];
}

interface BuyDomainResponse {
  data: {
    chave: string;
    url: string;
  };
}

const Buy: React.FC<BuyProps> = ({ match }) => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [idDomain] = hashIds.decode(match.params.id);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [domainName, setDomainName] = useState('');

  useEffect(() => {
    async function loadDomain() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainResponse = await api.get<DomainResponse>(
          `registrobr/v1/dominio/${idDomain}`,
        );

        const domain = domainResponse.data.data[0];

        setDomainName(domain.dominio);

        if (domain.promocao === 'FALSE') {
          setError(true);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDomain();
  }, [idDomain]);

  async function handleBuyDomain() {
    try {
      setSubmitting(true);

      const response = await api.put<BuyDomainResponse>(
        `registrobr/v1/dominio`,
        {
          idSite,
          idDominio: idDomain,
          comprar: 1,
          urlVoltar: 'https://painel4.hostnet.com.br/',
        },
      );

      window.location.href = response.data.data.url;
    } catch (err) {
      swal.fire({
        title: t('commmon:operationFailed'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    } finally {
      setSubmitting(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:brDomainBuy.title')}
          description={t('pages:brDomainBuy.description')}
          startOpen
          helpContent={<div>{t('pages:brDomainBuy.helpContent.p1')}</div>}
          helpLinks={[
            {
              text: t('pages:brDomainBuy.helpLinks.link1'),
              url:
                'https://www.hostnet.com.br/aditivo-de-contrato-para-concessao-de-dominios-gratis/',
            },
          ]}
        />
        <Card.Body className="fieldset">
          <FormSubtitle subTitle={t('pages:brDomainBuy.formSubtitle')} />
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              {t('common:domain')}
            </Form.Label>
            <Col sm={10}>
              <Form.Control plaintext readOnly value={domainName} />
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={2} />
            <Col sm={10}>
              <PanelButton
                variant="primary"
                onClick={handleBuyDomain}
                disabled={submitting}
              >
                {submitting ? t('common:sending') : t('common:buy')}
              </PanelButton>
            </Col>
          </Form.Group>
        </Card.Body>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Buy);
