import { useQuery } from 'react-query';

import api from 'services/api';

type ExtensionItemResponse = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: boolean;
  tipo: string;
};

type ExtensionsResponse = {
  data: ExtensionItemResponse[];
};

type RioExtension = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: boolean;
  tipo: string;
};

const initialData: RioExtension[] = [];

async function loadRioDomainExtensions() {
  const { data } = await api.get<ExtensionsResponse>(`rrpproxy/v1/extensao`, {
    params: {
      'filter[tipo]': 'REGISTRO',
    },
  });

  const domains = data.data;

  return domains;
}

export default function useRioDomainExtensions() {
  const { data, ...rest } = useQuery<RioExtension[]>(
    'rioDomainsExtensions',
    () => loadRioDomainExtensions(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const rioExtensions = data || initialData;

  return {
    rioExtensions,
    ...rest,
  };
}
