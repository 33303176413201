import { useQuery } from 'react-query';

import api from 'services/api';

interface ContactItemResponse {
  id: number;
  idCliente: number;
  contato: string;
  nome: string;
  sobreNome: string;
  empresa: string;
  email: string;
  endereco: string;
  cep: string;
  cidade: string;
  estado: string;
  pais: string;
  ddi: string;
  pessoaFisica: boolean;
  telefone: string;
  cpfCnpj: string;
  cadastro: string;
  status: string;
  validarIdentidade: boolean;
}

interface ContactsResponse {
  data: ContactItemResponse[];
}

interface RioContact {
  id: number;
  name: string;
  lastName: string;
  company: string;
  contact: string;
  email: string;
  address: string;
  zipCode: string;
  document: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  phone: string;
  naturalPerson: boolean;
  validateIdentity: boolean;
}

function formatContact(contactResponse: ContactItemResponse): RioContact {
  return {
    id: contactResponse.id,
    name: contactResponse.nome,
    lastName: contactResponse.sobreNome,
    company: contactResponse.empresa,
    contact: contactResponse.contato,
    email: contactResponse.email,
    address: contactResponse.endereco,
    zipCode: contactResponse.cep,
    document: contactResponse.cpfCnpj,
    city: contactResponse.cidade,
    state: contactResponse.estado,
    country: contactResponse.pais,
    countryCode: contactResponse.ddi,
    phone: contactResponse.telefone,
    naturalPerson: contactResponse.pessoaFisica,
    validateIdentity: contactResponse.validarIdentidade,
  };
}

async function loadRioDomainContacts(): Promise<RioContact[]> {
  const { data } = await api.get<ContactsResponse>(`rrpproxy/v1/contato`);

  const contacts = data.data.map(formatContact);

  return contacts;
}

export default function useRioDomainContacts(idContact?: number) {
  const initialData: RioContact[] = [];

  const { data, isError, isLoading, isFetching, isFetched, refetch } = useQuery<
    RioContact[]
  >('rioDomainContacts', () => loadRioDomainContacts(), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const contacts = data || initialData;

  const filteredContacts = idContact
    ? contacts.filter(contact => contact.id === idContact)
    : contacts;

  return {
    contacts: filteredContacts,
    loading: isLoading,
    fetching: isFetching,
    isFetched,
    error: isError,
    refetch,
  };
}
