import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaCogs } from 'react-icons/fa';
import { Formik } from 'formik';
import { StoreState } from 'store/createStore';
import * as Yup from 'yup';

import FormHeader from 'components/FormHeader';
import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useHostnetCoursesAccount from 'hooks/useHostnetCoursesAccount';

interface FormValues {
  email: string;
  password: string;
  confirm: string;
}

const Courses: React.FC = () => {
  const { t } = useTranslation();

  const [creating, setCreating] = useState(false);

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { account, isLoading, isError, refetch } = useHostnetCoursesAccount(
    idSite,
  );

  async function handleSubmit(values: FormValues) {
    try {
      setCreating(true);

      await api.post('/painel/v1/academia-hostnet/conta', {
        idSite,
        senha: values.password,
      });

      refetch();

      toast.fire('Usuário cadastrado com sucesso.');
    } catch (err) {
      swal.fire(
        'Não foi possível cadastrar o usuário.',
        err.response.data.error_description,
      );
    } finally {
      setCreating(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title="Academia Hostnet - Curso de Mautic e WordPress"
          description="Caso o curso desejado não esteja disponível na sua conta, solicitamos que abra um chamado em nosso HelpDesk."
        />

        {account.cadastrado && (
          <Card.Body className="fieldset">
            <PanelButton
              onClick={() =>
                window.open(
                  'https://www.hostnet.com.br/academia/wp-login.php',
                  '_blank',
                )
              }
            >
              Acessar Academia Hostnet
            </PanelButton>
          </Card.Body>
        )}

        {!account.cadastrado && (
          <div>
            <Formik
              enableReinitialize
              validateOnMount
              initialValues={{
                email: account.email,
                password: '',
                confirm: '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required(),
                password: Yup.string()
                  .required(t('validations.password'))
                  .min(8, t('validations.passwordMin'))
                  .matches(
                    RegExp('^(?=.*[0-9])'),
                    t('validations.passwordNumber'),
                  )
                  .matches(
                    RegExp('^(?=.*[a-z])'),
                    t('validations.passwordLower'),
                  )
                  .matches(
                    RegExp('^(?=.*[A-Z])'),
                    t('validations.passwordUpper'),
                  )
                  .matches(
                    RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                    t('validations.passwordChar'),
                  ),
                confirm: Yup.string()
                  .required(t('validations.passwordConfirm'))
                  .oneOf(
                    [Yup.ref('password'), null],
                    t('validations.invalidPasswordConfirm'),
                  ),
              })}
              onSubmit={handleSubmit}
            >
              {props => (
                <Form onSubmit={props.handleSubmit}>
                  <Card.Body className="fieldset">
                    <FormSubtitle subTitle="Escolha uma senha no formulário abaixo para criar sua conta na Academia Hostnet." />

                    <Form.Group as={Row} controlId="newAccount.user">
                      <Form.Label column sm={2}>
                        {t('pages:emailCreate.user')}
                      </Form.Label>
                      <Col sm={10}>{account.email}</Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="newAccount.Password">
                      <Form.Label column sm={2}>
                        {t('common:label.password')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="password"
                          name="password"
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.password && !!props.errors.password
                          }
                          disabled={creating}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.password}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common.matchPassword')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="newAccount.ConfirmPassword">
                      <Form.Label column sm={2}>
                        {t('common:label.confirmPassword')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="password"
                          name="confirm"
                          value={props.values.confirm}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.confirm && !!props.errors.confirm
                          }
                          disabled={creating}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.confirm}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Card.Body>

                  <div className="border-top pt-2 pb-2 pl-3">
                    <PanelButton
                      type="submit"
                      className="mr-1"
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      {props.isSubmitting
                        ? t('common:sending')
                        : t('common:send')}
                    </PanelButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </FormWrapper>
    </div>
  );
};

export default Courses;
