import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { FaWindowRestore } from 'react-icons/fa';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

interface MatchParams {
  subconta: string;
}

type FtpSubaccountEditProps = RouteComponentProps<MatchParams>;

interface FtpSubaccountResponseItem {
  conta: string;
  subconta: string;
  diretorio: string;
}

interface FtpSubaccountResponse {
  data: FtpSubaccountResponseItem[];
}

interface FtpSubaccount {
  conta: string;
  subconta: string;
  diretorio: string;
}

interface EditFtpsubaccountFormValues {
  subaccount: string;
  directory: string;
  password: string;
  confirmPassword: string;
}

const Edit: React.FC<FtpSubaccountEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const { subconta: subaccount } = match.params;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ftpSubaccount, setFtpSubaccount] = useState<FtpSubaccount>({
    conta: '',
    subconta: '',
    diretorio: '',
  });

  useEffect(() => {
    async function loadFtpSubaccount() {
      try {
        setError(false);
        setLoading(true);

        const ftpSubaccountResponse = await api.get<FtpSubaccountResponse>(
          `painel/v1/subconta-ftp/${subaccount}`,
        );

        if (ftpSubaccountResponse.data.data.length > 0) {
          const [subaccountResponse] = ftpSubaccountResponse.data.data;

          subaccountResponse.diretorio = subaccountResponse.diretorio.replace(
            `/home/${subaccountResponse.conta}/`,
            '',
          );

          setFtpSubaccount(subaccountResponse);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadFtpSubaccount();
  }, [subaccount]);

  async function editFtpSubaccount(values: EditFtpsubaccountFormValues) {
    try {
      const { directory, password } = values;

      await api.put(`painel/v1/subconta-ftp/${subaccount}`, {
        diretorio: `/home/${ftpSubaccount.conta}/${directory}`,
        senha: password,
      });

      toast.fire(t('pages:editFtpSubaccount.requestSuccess'));

      history.push(`/site/subcontas-ftp`);
    } catch (err) {
      swal.fire({
        title: t('pages:editFtpSubaccount.requestFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:editFtpSubaccount.editFtpSubaccount')}
          description={t('pages:editFtpSubaccount.description')}
          directHelpLink="https://ajuda.hostnet.com.br/subcontas-de-ftp/"
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            subaccount: ftpSubaccount.subconta,
            directory: ftpSubaccount.diretorio,
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            directory: Yup.string()
              .required(
                t('pages:editFtpSubaccount.validation.informValidDirectory'),
              )
              .test(
                'directory',
                t('pages:editFtpSubaccount.validation.informValidDirectory'),
                value => {
                  if (
                    value &&
                    value.trim() !== '' &&
                    value.search(/^([\w.\-_]+)([/]?)([\w.\-_]+)$/) !== -1
                  ) {
                    return true;
                  }
                  return false;
                },
              ),
            password: Yup.string()
              .required(t('validations.password'))
              .min(8, t('validations.passwordMin'))
              .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
              .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
              .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
              .matches(
                RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                t('validations.passwordChar'),
              ),
            confirmPassword: Yup.string()
              .required(t('validations.passwordConfirm'))
              .oneOf(
                [Yup.ref('password'), null],
                t('validations.invalidPasswordConfirm'),
              ),
          })}
          onSubmit={editFtpSubaccount}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:editFtpSubaccount.subaccount')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      id="subconta"
                      name="subconta"
                      defaultValue={props.values.subaccount}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.directory')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          /home/{ftpSubaccount.conta}/
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="directory"
                        name="directory"
                        value={props.values.directory}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.directory && !!props.errors.directory
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted" />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.directory}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.password')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      id="password"
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.password && !!props.errors.password
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('common.matchPassword')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.confirmPassword')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={props.values.confirmPassword}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/site/subcontas-ftp"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
