import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';
import SideMenuAccordionExternalLink from 'components/SideMenu/components/SideMenuAccordionExternalLink';

import { filterMenuOptions } from 'components/SideMenu/filterMenuOptions';

import { IdCardIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface AccountOptionsProps {
  site: string;
  usuario: string;
  options: Option[];
}

type MenuElement = {
  element: React.ReactNode;
  requiredOptions: String[];
};

const AccountOptions: React.FC<AccountOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  const menuElements: MenuElement[] = [
    {
      element: (
        <SideMenuAccordionExternalLink
          text={t('components:sideMenu.register')}
          href="https://id.hostnet.com.br/cadastro/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionExternalLink
          text={t('components:sideMenu.user')}
          href="https://id.hostnet.com.br/usuario/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.additionalUsers')}
          to="/usuarios-adicionais"
        />
      ),
      requiredOptions: ['usuarios.adicionais'],
    },
    {
      element: (
        <SideMenuAccordionExternalLink
          text={t('components:sideMenu.password')}
          href="https://id.hostnet.com.br/senha/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.additionalServices')}
          to="/servicos-adicionais"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.partnerships')}
          to="/parcerias-e-promocoes"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.cancel')}
          to="/cancelamento"
        />
      ),
      requiredOptions: ['cancelamento'],
    },
  ];

  const availableMenuItems = filterMenuOptions(options, menuElements);

  return (
    <SideMenuAccordion
      eventKey="1"
      text={t('components:sideMenu.account')}
      icon={<IdCardIcon size={15} />}
      elements={availableMenuItems}
    />
  );
};

export default AccountOptions;
