export default function formatDataSize(
  value: string | number,
  format: string | null = null,
): string {
  const intVal = parseFloat(`${value}`);
  if ((!format && intVal < 1024) || format === 'bytes') {
    return `${intVal} bytes`;
  }
  if ((!format && intVal < 1024 ** 2) || format === 'KB') {
    return `${(intVal / 1024).toFixed(0)} KB`;
  }
  if ((!format && intVal < 1024 ** 3) || format === 'MB') {
    return `${(intVal / 1024 ** 2).toFixed(1)} MB`;
  }
  if ((!format && intVal >= 1024 ** 3) || format === 'GB') {
    return `${(intVal / 1024 ** 3).toFixed(1)} GB`;
  }
  return `${intVal} bytes`;
}
