import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import swal from 'services/swal';

import useInternationalDomainContacts from 'hooks/useInternationalDomainContacts';
import useInternationalDomainExtensions from 'hooks/useInternationalDomainsExtensions';

import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import HelpContent from './components/HelpContent';

import { Container } from './styles';

interface TransferDomainFormValues {
  idContact: number;
  domain: string;
  extension: string;
  authInfo: string;
}

interface TransferRequestResponse {
  data: {
    url: string;
  };
}

const Transfer: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const {
    internationalExtensions,
    isLoading: loadingExtensions,
    isError: errorExtensions,
  } = useInternationalDomainExtensions();

  const {
    contacts,
    loading: loadingContacts,
    error: errorContacts,
  } = useInternationalDomainContacts();

  function findExtensionPrice(extension: string) {
    const findExtension = internationalExtensions.find(
      internationalExtension => internationalExtension.extensao === extension,
    );

    if (!findExtension) {
      return '0';
    }

    return findExtension.valor;
  }

  async function submitTransferDomainForm(values: TransferDomainFormValues) {
    try {
      const { domain, extension, idContact, authInfo } = values;

      const response = await api.post<TransferRequestResponse>(
        'enom/v1/transferencia',
        {
          idContato: idContact,
          ...(contaComSite && { idSite }),
          dominio: `${domain}.${extension}`,
          authInfo,
          urlVoltar: 'https://painel4.hostnet.com.br/',
        },
      );

      window.location.href = response.data.data.url;
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loadingContacts || loadingExtensions) {
    return <Loading />;
  }

  if (errorContacts || errorExtensions) {
    return <Error />;
  }

  if (contacts.length === 0) {
    return (
      <Container>
        <PageTitle
          title={t('titles:domainRegistry.title')}
          description={t('titles:domainRegistry.description')}
          icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
        />
        <FormWrapper>
          <FormHeader
            startOpen
            helpContent={<HelpContent />}
            title={t('pages:internationalDomainTransfer.title')}
            description={t('pages:internationalDomainTransfer.description')}
            helpLinks={[]}
          />
          <Card.Body className="fieldset">
            <div>{t('pages:internationalDomainTransfer.noContacts')}</div>
            <div className="mt-3">
              <PanelButton
                forwardedAs={Link}
                to="/registro/contato-internacional-adicionar"
              >
                {t('pages:internationalDomainTransfer.newContact')}
              </PanelButton>
            </div>
          </Card.Body>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          startOpen
          helpContent={<HelpContent />}
          title={t('pages:internationalDomainTransfer.title')}
          description={t('pages:internationalDomainTransfer.description')}
          helpLinks={[]}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            idContact: contacts.length > 0 ? contacts[0].id : 0,
            domain: '',
            extension: 'com',
            price: findExtensionPrice('com'),
            authInfo: '',
          }}
          validationSchema={Yup.object().shape({
            domain: Yup.string()
              .required(t('validations:requiredField'))
              .matches(
                /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                t('pages:internationalDomainTransfer.validations.domainFormat'),
              ),
            authInfo: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={submitTransferDomainForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:internationalDomainTransfer.formSubtitle')}
                />

                <Form.Group
                  as={Row}
                  controlId="internationalDomainTransfer.domain"
                >
                  <Form.Label column sm={2} className="required">
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="domain"
                      value={props.values.domain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.domain && !!props.errors.domain
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.domain}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      <Trans>
                        {t('pages:internationalDomainTransfer.domainTip')}
                      </Trans>
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="internationalDomainTransfer.extension"
                >
                  <Form.Label column sm={2} className="required">
                    {t('common:extension')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="extension"
                      as="select"
                      value={props.values.extension}
                      onChange={e => {
                        props.handleChange(e);
                        props.setFieldValue(
                          'price',
                          findExtensionPrice(e.target.value),
                        );
                      }}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.extension && !!props.errors.extension
                      }
                      disabled={props.isSubmitting}
                    >
                      {internationalExtensions.map(state => (
                        <option key={state.extensao} value={state.extensao}>
                          {state.extensao}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.extension}
                    </Form.Control.Feedback>

                    <div className="text-danger mt-2" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="internationalDomainTransfer.price"
                >
                  <Form.Label column sm={2}>
                    {t('common:price')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      readOnly
                      plaintext
                      name="price"
                      value={formatCurrency(Number(props.values.price))}
                      isInvalid={!!props.touched.price && !!props.errors.price}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.price}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="internationalDomainTransfer.contact"
                >
                  <Form.Label column sm={2} className="required">
                    {t('common:contact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="idContact"
                      as="select"
                      value={props.values.idContact}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.idContact && !!props.errors.idContact
                      }
                      disabled={props.isSubmitting}
                    >
                      {contacts.map(contact => (
                        <option key={contact.id} value={contact.id}>
                          {contact.name} - {contact.email}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.idContact}
                    </Form.Control.Feedback>

                    <div className="text-danger mt-2" />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="internationalDomainTransfer.authInfo"
                >
                  <Form.Label column sm={2} className="required">
                    Auth-info
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="authInfo"
                      value={props.values.authInfo}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.authInfo && !!props.errors.authInfo
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:internationalDomainTransfer.authInfoTip')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.authInfo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/transferencia"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Transfer;
