import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { FaEnvelope } from 'react-icons/fa';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

interface FormValues {
  email: string;
}

const Blacklist: React.FC = () => {
  const { t } = useTranslation();

  async function removeEmailBlacklist(values: FormValues) {
    try {
      const { email } = values;

      await api.delete(`painel/v1/blacklist/email`, {
        params: {
          email,
        },
      });

      toast.fire(t('pages:removeEmailBlacklist.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:removeEmailBlacklist.failure'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:removeEmailBlacklist.title')}
          description={t('pages:removeEmailBlacklist.description')}
          helpContent={
            <div>
              <p>{t('pages:removeEmailBlacklist.helpContent.p1')}</p>
              <p>{t('pages:removeEmailBlacklist.helpContent.p2')}</p>
              <p>{t('pages:removeEmailBlacklist.helpContent.p3')}</p>
            </div>
          }
          helpLinks={[
            {
              url: 'https://ajuda.hostnet.com.br/blacklists-ou-listas-negras/',
              text: t('pages:removeEmailBlacklist.linkBlacklist'),
            },
          ]}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
          })}
          onSubmit={removeEmailBlacklist}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="blacklist.Email">
                  <Form.Label column sm={2}>
                    {t('common:label.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:removeEmailBlacklist.emailHelpText')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Blacklist;
