import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  Row,
  Form,
  InputGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import * as Yup from 'yup';

import swal from 'services/swal';
import history from 'services/history';
import toast from 'services/toast';
import api from 'services/api';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import useEmails from 'hooks/useEmails';
import useUltramail from 'hooks/useUltramail';

import { Container } from './styles';

interface FormValues {
  user: string;
  site: string;
  size: number;
  password: string;
  confirm: string;
  description: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { refetch: refetchEmails } = useEmails(idSite);
  const { ultramail, refetch: refetchUltramail } = useUltramail(idSite);

  const [creating, setCreating] = useState(false);

  async function createEmail(values: FormValues) {
    try {
      setCreating(true);

      const { user, password, size, description, site: selectedSite } = values;

      await api.post(`painel/v1/site/${idSite}/email`, {
        conta: user,
        senha: password,
        tamanho: size,
        descricao: description,
        ...(selectedSite !== site && { siteExtra: values.site }),
      });

      refetchEmails();
      refetchUltramail();

      toast.fire(t('pages:emailCreate.success'));

      history.push(`/emails`);
    } catch (err) {
      swal.fire({
        title: t('pages:emailCreate.failure'),
        html: err.response.data.error_description,
      });
    } finally {
      setCreating(false);
    }
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      user: '',
      site,
      size: 1024,
      password: '',
      confirm: '',
      description: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.string()
        .required(t('pages:emailCreate.validation.user'))
        .test('validUser', t('pages:emailCreate.validation.userFormat'), val =>
          /^[a-z][_a-z0-9-]*(\.[_a-z0-9-]+)?$/.test(val || ''),
        ),
      size: Yup.number()
        .typeError(t('pages:emailCreate.validation.size'))
        .required(t('pages:emailCreate.validation.size'))
        .min(1024, t('pages:emailCreate.validation.sizeMin')),
      password: Yup.string()
        .required(t('validations.password'))
        .min(8, t('validations.passwordMin'))
        .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
        .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
        .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
        .matches(
          RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
          t('validations.passwordChar'),
        ),
      confirm: Yup.string()
        .required(t('validations.passwordConfirm'))
        .oneOf(
          [Yup.ref('password'), null],
          t('validations.invalidPasswordConfirm'),
        ),
    }),
    onSubmit: async values => createEmail(values),
  });

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:emailCreate.formTitle')}
          description={t('pages:emailCreate.formDescription')}
          helpContent={
            <div>
              <p>
                <strong>{t('common:label.warning')}</strong>
              </p>
              <ul>
                <li>{t('pages:emailCreate.helpContent.li1')}</li>
                <li>{t('pages:emailCreate.helpContent.li2')}</li>
                <li>{t('pages:emailCreate.helpContent.li3')}</li>
                <li>{t('pages:emailCreate.helpContent.li4')}</li>
              </ul>
            </div>
          }
        />

        <Form onSubmit={formik.handleSubmit}>
          <Card.Body className="fieldset">
            <FormSubtitle
              subTitle={t('pages:emailCreate.emailConfiguration')}
            />
            <Form.Group as={Row} controlId="newEmail.user">
              <Form.Label column sm={2}>
                {t('pages:emailCreate.user')}
              </Form.Label>
              <Col sm={10}>
                <InputGroup className="mb-1">
                  <Form.Control
                    name="user"
                    value={formik.values.user}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.touched.user && !!formik.errors.user}
                    disabled={creating}
                  />
                  <DropdownButton
                    as={InputGroup.Append}
                    variant="outline-secondary"
                    title={`@${formik.values.site}`}
                    id="input-group-dropdown-2"
                  >
                    {ultramail.quotaDetalhada.map(ultramailSite => {
                      if (!ultramailSite.ativo) {
                        return <></>;
                      }

                      return (
                        <Dropdown.Item
                          key={ultramailSite.site}
                          onSelect={() =>
                            formik.setFieldValue('site', ultramailSite.site)
                          }
                        >
                          @{ultramailSite.site}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.user}
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Text className="text-muted">
                  {t('pages:emailCreate.emailServiceTip')}{' '}
                  <Link to="/servico-email">clicando aqui</Link>
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="newEmail.Size">
              <Form.Label column sm={2}>
                {t('pages:emailCreate.size')}
              </Form.Label>
              <Col sm={10}>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    name="size"
                    value={formik.values.size}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={!!formik.touched.size && !!formik.errors.size}
                    disabled={creating}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                  </InputGroup.Append>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.size}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="newEmail.Password">
              <Form.Label column sm={2}>
                {t('common:label.password')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.touched.password && !!formik.errors.password
                  }
                  disabled={creating}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.password}
                </Form.Control.Feedback>
                <Form.Text className="text-muted">
                  {t('common.matchPassword')}
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="newEmail.ConfirmPassword">
              <Form.Label column sm={2}>
                {t('common:label.confirmPassword')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  name="confirm"
                  value={formik.values.confirm}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={
                    !!formik.touched.confirm && !!formik.errors.confirm
                  }
                  disabled={creating}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.confirm}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="newEmail.Description">
              <Form.Label sm={2} column>
                {t('pages:emailCreate.description')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="text"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={creating}
                />
                <Form.Text className="text-muted">
                  {t('pages:emailCreate.descriptionHelpText')}.
                </Form.Text>
              </Col>
            </Form.Group>
          </Card.Body>

          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton
              type="submit"
              variant="primary"
              className="mr-1"
              disabled={creating || !formik.isValid}
            >
              {creating ? t('common:sending') : t('common:send')}
            </PanelButton>

            <PanelButton
              variant="secondary"
              forwardedAs={Link}
              to="/emails"
              disabled={creating}
            >
              {t('common:label.back')}
            </PanelButton>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default New;
