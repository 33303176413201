import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import swal from 'services/swal';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

interface FtpSubaccountsActionsProps {
  selectedCount: number;
  selectedRows: { subconta: string }[];
  updating: boolean;
  deleteFtpSubaccount: () => void;
}

const FtpSubaccountActions: React.FC<FtpSubaccountsActionsProps> = ({
  selectedCount,
  selectedRows,
  updating,
  deleteFtpSubaccount,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={updating}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/site/subconta-ftp/${selectedRows[0].subconta}/editar`}
            size="sm"
            className="mx-1"
            disabled={updating}
          >
            {t('common:edit')}
          </TableButton>
        </>
      )}
      <TableButton
        className="mx-1"
        variant="danger"
        size="sm"
        disabled={updating}
        onClick={async () => {
          const { isConfirmed } = await swal.fire({
            title: t('common:label.warning'),
            text: t('pages:ftpSubaccounts.deleteWarning'),
            showCancelButton: true,
          });

          if (isConfirmed) {
            deleteFtpSubaccount();
          }
        }}
      >
        {t('common:delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default FtpSubaccountActions;
