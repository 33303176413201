import { action } from 'typesafe-actions';

import { Option } from './types';

export function optionsRequest({
  idClient,
  idSite,
}: {
  idClient: Number;
  idSite?: Number;
}) {
  return action('@options/OPTIONS_REQUEST', { idClient, idSite });
}

export function optionsSuccess({ options }: { options: Option[] }) {
  return action('@options/OPTIONS_SUCCESS', {
    options,
  });
}

export function optionsFailure() {
  return action('@options/OPTIONS_FAILURE');
}
