import styled from 'styled-components';

export const Container = styled.div`
  .dropdown-toggle {
    border-color: #ced4da;
    color: #212529;
    background-color: white;
    border-radius: 0;
    width: 100%;
    text-align: left;
  }
  .dropdown-toggle::after {
    right: 8px;
    top: 13px;
    position: absolute;
  }
  .dropdown-toggle:hover {
    color: white;
    border-color: var(--color-azure-blue);
    background-color: var(--color-azure-blue);
  }
  .dropdown-menu.show {
    width: 100%;
    border-radius: 0;
  }
  .dropdown-menu.show .search-input {
    border-radius: 0;
  }
  .show > .dropdown-toggle {
    border-color: var(--color-azure-blue);
    background-color: var(--color-azure-blue);
  }
  .search-input {
    width: calc(100% - 32px);
  }
  .dropdown-item:active {
    background-color: var(--color-azure-blue);
    border: var(--color-azure-blue);
  }
`;

export const DropdownContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;
