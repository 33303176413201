import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { MdCheck, MdClose } from 'react-icons/md';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import Error from 'components/Error';
import TableIconButton from 'components/TableIconButton';
import TableNoData from 'components/TableNoData';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface TemporaryUrlItem {
  ativo: boolean;
  enderecoProvisorio: string;
  expiracao: string;
  site: string;
}

interface TemporaryUrlResponse {
  data: TemporaryUrlItem[];
}

interface ActivateResponse {
  data: TemporaryUrlItem[];
}

interface DeactivateResponse {
  data: TemporaryUrlItem[];
}

interface TemporaryUrl {
  ativo: boolean;
  enderecoProvisorio: string;
  expiracao: string;
  site: string;
}

const TemporaryUrl: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [temporaryUrls, setTemporaryUrls] = useState<TemporaryUrl[]>([]);
  const [filteredTemporaryUrls, setFilteredTemporaryUrls] = useState<
    TemporaryUrl[]
  >([]);

  const [temporaryUrlsFilterText, setTemporaryUrlsFilterText] = useState('');

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    async function loadTemporaryUrl() {
      try {
        setLoading(true);
        setError(false);

        const temporaryUrlsResponse = await api.get<TemporaryUrlResponse>(
          `painel/v1/site/${idSite}/endereco-provisorio`,
        );

        setTemporaryUrls(temporaryUrlsResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadTemporaryUrl();
  }, [idSite]);

  useEffect(() => {
    setFilteredTemporaryUrls(
      filter(temporaryUrls, ['site'], temporaryUrlsFilterText),
    );
  }, [temporaryUrlsFilterText, temporaryUrls]);

  const activate = useCallback(
    async (site: string) => {
      try {
        setUpdating(true);

        const activateResponse = await api.post<ActivateResponse>(
          `painel/v1/site/${idSite}/endereco-provisorio`,
          { site },
        );

        setTemporaryUrls(activateResponse.data.data);

        toast.fire(t('pages:temporaryUrl.activateSuccess'));
      } catch (err) {
        swal.fire(t('common:label.operationFailed'));
      } finally {
        setUpdating(false);
      }
    },
    [idSite, t],
  );

  const deactivate = useCallback(
    async (site: string) => {
      try {
        setUpdating(true);

        const deactivateResponse = await api.delete<DeactivateResponse>(
          `painel/v1/site/${idSite}/endereco-provisorio`,
          { data: { site } },
        );

        setTemporaryUrls(deactivateResponse.data.data);

        toast.fire(t('pages:temporaryUrl.deactivateSuccess'));
      } catch (err) {
        swal.fire(t('common:label.operationFailed'));
      } finally {
        setUpdating(false);
      }
    },
    [idSite, t],
  );

  const columns = [
    {
      name: t('common:label.site'),
      selector: 'site',
    },
    {
      name: 'Status',
      cell: (row: TemporaryUrl) => (
        <div className="w-100 d-flex align-items-center justify-content-between p-1">
          <div>{row.ativo ? 'Ativado' : 'Desativado'}</div>
          {row.ativo ? (
            <TableIconButton
              variant="outline-primary"
              disabled={updating}
              onClick={() => deactivate(row.site)}
              tooltipConfig={{
                id: 'deactivate',
                content: t('common:deactivate'),
                placement: 'right',
              }}
            >
              <MdClose size={16} />
            </TableIconButton>
          ) : (
            <TableIconButton
              variant="outline-primary"
              disabled={updating}
              onClick={() => activate(row.site)}
              tooltipConfig={{
                id: 'activate',
                content: t('common:activate'),
                placement: 'right',
              }}
            >
              <MdCheck size={16} />
            </TableIconButton>
          )}
        </div>
      ),
    },
    {
      name: t('common:label.address'),
      selector: 'enderecoProvisorio',
      cell: (row: TemporaryUrl) => (row.ativo ? row.enderecoProvisorio : '---'),
    },
    {
      name: t('common:label.expiration'),
      selector: 'expiracao',
      cell: (row: TemporaryUrl) => (row.ativo ? row.expiracao : '---'),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:temporaryUrl.title')}
          description={t('pages:temporaryUrl.subTitle')}
          directHelpLink="https://ajuda.hostnet.com.br/endereco-provisorio/"
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput
              onChange={value => setTemporaryUrlsFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <DataTable
          noHeader
          columns={columns}
          data={filteredTemporaryUrls}
          progressPending={loading}
          progressComponent={<Loading />}
          noDataComponent={
            <TableNoData text={t('common:label.noDataToList')} />
          }
          className="datatables-table"
        />
      </TableWrapper>
    </Container>
  );
};

export default TemporaryUrl;
