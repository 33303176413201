import React from 'react';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface File {
  nome: string;
  tipo: string;
  tamanho: string;
  caminho: string;
  ultModificacao: string;
  ultModificacaoTs: number;
  owner: string;
  permissao: string;
  permissaoSimbolica: string;
}

interface FileManagerActionsProps {
  loading: boolean;
  setShowModalRename: (value: boolean) => void;
  setShowModalPermissions: (value: boolean) => void;
  selectedCount: number;
  selectedRows: File[];
  deleteFile: () => void;
  download: (filesToDownload: File[]) => void;
}

const FileManagerActions: React.FC<FileManagerActionsProps> = ({
  loading,
  setShowModalRename,
  setShowModalPermissions,
  selectedCount,
  selectedRows,
  deleteFile,
  download,
}) => {
  const { t } = useTranslation();

  if (selectedCount === 1 && selectedRows[0].tipo === 'back') {
    return <></>;
  }

  const fileSelectedExists = !!selectedRows.find(f => f.tipo === 'file');

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && [
        <TableButton
          className="mr-2"
          size="sm"
          disabled={loading}
          onClick={() => setShowModalRename(true)}
        >
          {t('pages:fileManager.rename')}
        </TableButton>,
        <TableButton
          className="mr-2"
          size="sm"
          disabled={loading}
          onClick={() => setShowModalPermissions(true)}
        >
          {t('pages:fileManager.permissions')}
        </TableButton>,
      ]}

      {fileSelectedExists && (
        <TableButton
          onClick={() => download(selectedRows)}
          className="mr-2"
          size="sm"
          disabled={loading}
        >
          {t('pages:fileManager.download')}
        </TableButton>
      )}
      <TableButton
        className="mr-2"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={() => deleteFile()}
      >
        {t('common:delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default FileManagerActions;
