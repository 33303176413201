import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaFolderOpen, FaSearch } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { format, parseISO } from 'date-fns';
import { InputGroup, FormControl } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import formatDataSize from 'utils/formatDataSize';

import PageTitle from 'components/PageTitle';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';

import FileManagerActions from './components/FileManagerActions';
import ModalFileUpload from './components/ModalFileUpload';
import ModalNewFolder from './components/ModalNewFolder';
import ModalPermissions from './components/ModalPermissions';
import ModalRename from './components/ModalRename';

import {
  Container,
  BreadcrumbItem,
  StyledTableFooter,
  StyledIcon,
  FolderIcon,
  FileIcon,
  Foldername,
  Filename,
  Filesize,
} from './styles';

interface BreadCrumbLink {
  text: string;
  to: string;
}

interface File {
  nome: string;
  tipo: string;
  tamanho: string;
  caminho: string;
  ultModificacao: string;
  ultModificacaoTs: number;
  owner: string;
  permissao: string;
  permissaoSimbolica: string;
}

interface SelectedRowsInfo {
  selectedCount: number;
  selectedRows: File[];
}

const FileManager: React.FC = () => {
  const { t } = useTranslation();

  const { site } = useSelector((state: StoreState) => state.site.info);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [loading, setLoading] = useState(false);

  const [currDir, setCurrDir] = useState('');
  const [toggleCleared, setToggleCleared] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState<File[]>([]);

  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [loadCount, setLoadCount] = useState<number>(0);

  const [showModalRename, setShowModalRename] = useState<boolean>(false);
  const [showModalFileUpload, setShowModalFileUpload] = useState<boolean>(
    false,
  );
  const [showModalNewFolder, setShowModalNewFolder] = useState<boolean>(false);
  const [showModalPermissions, setShowModalPermissions] = useState<boolean>(
    false,
  );
  const [filterText, setFilterText] = useState<string>('');

  useEffect(() => {
    async function get() {
      try {
        const back = {
          tipo: 'back',
          nome: '..',
        };

        setLoading(true);

        const filesResponse = await api.get(`https://a.apihn.co/ftp/v1/dir`, {
          params: { site, caminho: currDir === '' ? '/' : currDir },
        });

        setFiles([
          back,
          ...filesResponse.data.data.arquivos.sort(
            (a: File, b: File): number =>
              +(a.tipo > b.tipo) || +(a.tipo === b.tipo) - 1,
          ),
        ]);

        setError(false);
      } catch (err) {
        swal.fire({
          title: t('pages:fileManager.fileListError'),
          html: err.response.data.error_description,
        });

        setError(true);
      } finally {
        setLoading(false);
      }
    }
    get();
  }, [site, currDir, loadCount, t]);

  useEffect(() => {
    const filtered = files.filter(f => {
      if (f.tipo === 'back') {
        return true;
      }
      return f.nome.toLowerCase().includes(filterText.toLowerCase());
    });
    setFilteredFiles(filtered);
  }, [filterText, files]);

  function openDirectory(nome: string) {
    setToggleCleared(!toggleCleared);
    setFilterText('');
    if (nome === '..') {
      const path = currDir.split('/').slice(0, -1).join('/');
      setCurrDir(path);
    } else {
      setCurrDir(`${currDir}/${nome}`);
    }
  }

  const columns = [
    {
      name: t('common:name'),
      selector: 'tipo',
      sortable: true,
      sortFunction: (a: File, b: File): number => {
        if (b.tipo === 'back') {
          return 0;
        }
        return a.tipo === b.tipo ? +(a.nome > b.nome) : +(a.tipo > b.tipo);
      },
      cell: (row: File) => (
        <>
          {row.tipo !== 'file' ? (
            <>
              <StyledIcon
                className="blue"
                onClick={() => openDirectory(row.nome)}
              >
                <FolderIcon />
              </StyledIcon>
              <div>
                <Foldername onClick={() => openDirectory(row.nome)}>
                  {row.nome}
                </Foldername>
              </div>
            </>
          ) : (
            <>
              <StyledIcon className="gray">
                <FileIcon />
              </StyledIcon>
              <div>
                <Filename>{row.nome}</Filename>
                <Filesize>{formatDataSize(row.tamanho)}</Filesize>
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: t('common:date'),
      selector: 'ultModificacaoTs',
      width: '150px',
      sortable: true,
      sortFunction: (a: File, b: File): number => {
        if (b.tipo === 'back') {
          return 0;
        }
        return a.ultModificacaoTs - b.ultModificacaoTs;
      },
      cell: (row: File): string => {
        if (!row.ultModificacao) {
          return '';
        }
        return format(
          parseISO(row.ultModificacao),
          `dd/MM/yyyy ${row.ultModificacao.length >= 18 ? 'HH:mm' : ''}`,
        );
      },
    },
    {
      name: t('pages:fileManager.owner'),
      selector: 'owner',
      width: '120px',
      sortable: true,
      sortFunction: (a: File, b: File): number => {
        if (b.tipo === 'back') {
          return 0;
        }
        return +a.owner - +b.owner;
      },
    },
    {
      name: t('pages:fileManager.permission'),
      selector: 'permissaoSimbolica',
      width: '120px',
      sortable: true,
      sortFunction: (a: File, b: File): number => {
        if (b.tipo === 'back') {
          return 0;
        }
        return +a.permissao - +b.permissao;
      },
    },
  ];

  function renderBreadCrumb() {
    const breadcrumb: BreadCrumbLink[] = [];
    currDir.split('/').map((item: string, key: number) => {
      const url = currDir
        .split('/')
        .slice(0, key + 1)
        .join('/');
      return breadcrumb.push({ text: item, to: url });
    });
    return (
      <>
        {breadcrumb.map(item => {
          return (
            <BreadcrumbItem
              variant="link"
              key={`link-${item.to}`}
              onClick={() => setCurrDir(`${item.to}`)}
            >{`${item.text}/`}</BreadcrumbItem>
          );
        })}
      </>
    );
  }

  async function deleteFile() {
    const AsyncConfirm = (itens: File[]) =>
      new Promise(resolve => {
        const title =
          itens.length > 1
            ? `Deseja remover todos os arquivos selecionados?`
            : `Deseja remover o arquivo ${itens[0].nome}?`;
        swal
          .fire({
            title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sim',
          })
          .then(f => {
            if (f.isConfirmed) {
              return resolve(true);
            }
            return resolve(false);
          });
      });
    const confirm = await AsyncConfirm(selectedRowsInfo.selectedRows);
    if (confirm) {
      deleteConfirmed();
    }
  }

  async function deleteConfirmed() {
    try {
      setLoading(true);
      await Promise.all(
        selectedRowsInfo.selectedRows.map(async item => {
          if (item.tipo === 'dir') {
            await api.delete(`https://a.apihn.co/ftp/v1/dir`, {
              params: {
                site,
                caminho: `${currDir}/${item.nome}`,
              },
            });
          }
          if (item.tipo === 'file') {
            await api.delete(`https://a.apihn.co/ftp/v1/arquivo`, {
              params: {
                site,
                caminho: `${currDir}/${item.nome}`,
              },
            });
          }
        }),
      );

      const rows = files.filter((file: File) => {
        return !selectedRowsInfo.selectedRows.find(
          (rowInfo: File) => rowInfo.nome === file.nome,
        );
      });
      setFiles(rows);

      setToggleCleared(!toggleCleared);
      if (selectedRowsInfo.selectedRows.length > 1) {
        toast.fire(t('pages:fileManager.deleteSuccess_plural'));
      } else {
        toast.fire(t('pages:fileManager.deleteSuccess'));
      }
    } catch (err) {
      swal.fire({
        title: t('pages:fileManager.deleteError'),
        html: err.response.data.error_description,
      });
    } finally {
      setLoading(false);
    }
  }

  async function download(filesToDownload: File[]) {
    try {
      setLoading(true);

      const filesToDownloadFiltered = filesToDownload.filter(
        item => item.tipo === 'file',
      );

      if (filesToDownloadFiltered.length > 10) {
        swal.fire({
          title: t('pages:fileManager.downloadError'),
          html: t('pages:fileManager.downloadErrorExplanation', {
            length: filesToDownloadFiltered.length,
          }),
        });

        setLoading(false);

        return false;
      }

      await Promise.all(
        filesToDownloadFiltered.map(async item => {
          if (item.tipo === 'file') {
            const { caminho, nome } = item;

            const fileDownloadResponse = await api.get(
              `https://a.apihn.co/ftp/v1/arquivo`,
              {
                params: { caminho, site },
              },
            );

            const { conteudo, tipoMime } = fileDownloadResponse.data.data;
            const a = document.createElement('a');

            a.href = `data:${
              tipoMime || 'application/octet-stream;'
            };base64,${conteudo}`;

            a.download = nome;
            a.click();
          }
        }),
      );
    } catch (err) {
      swal.fire({
        title: t('pages:fileManager.downloadError'),
        html: err.response.data.error_description,
      });
    } finally {
      setLoading(false);
    }

    return true;
  }

  let DataTableProps = {};

  if (filteredFiles.length === 1) {
    DataTableProps = {
      paginationComponent: () => (
        <StyledTableFooter>
          <span className="text-muted">
            {t('pages:fileManager.emptyFolder')}
          </span>
        </StyledTableFooter>
      ),
    };
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:fileManager.title')}
        description={t('titles:fileManager.description')}
        icon={<FaFolderOpen color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title={t('pages:fileManager.title')}
          description={renderBreadCrumb()}
          directHelpLink="https://ajuda.hostnet.com.br/gerenciador-de-arquivos-do-painel-de-controle/"
        />
        <TableSubHeader>
          <div>
            <TableButton
              size="sm"
              onClick={() => {
                setShowModalNewFolder(true);
              }}
            >
              {t('pages:fileManager.newFolder')}
            </TableButton>
            <TableButton size="sm" onClick={() => setShowModalFileUpload(true)}>
              {t('pages:fileManager.uploadFile')}
            </TableButton>
          </div>
          <div>
            <InputGroup className="mr-sm-2" size="sm">
              <FormControl
                placeholder="Filtro"
                aria-label="Filtro"
                size="sm"
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            dense
            subHeaderWrap
            noHeader={
              selectedRowsInfo.selectedCount === 0 ||
              (selectedRowsInfo.selectedCount === 1 &&
                selectedRowsInfo.selectedRows[0].tipo === 'back')
            }
            selectableRows
            highlightOnHover
            clearSelectedRows={toggleCleared}
            pagination
            data={filteredFiles}
            columns={columns}
            progressPending={loading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <FileManagerActions
                loading={loading}
                deleteFile={deleteFile}
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                setShowModalRename={(value: boolean) => {
                  setShowModalRename(value);
                }}
                setShowModalPermissions={(value: boolean) => {
                  setShowModalPermissions(value);
                }}
                download={download}
              />
            }
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
              noRowsPerPage: filteredFiles.length === 1,
            }}
            paginationTotalRows={filteredFiles.length - 1}
            {...DataTableProps}
            className="datatables-table"
          />
        </div>
      </TableWrapper>
      <ModalNewFolder
        site={site}
        currDir={currDir}
        loadCount={loadCount}
        setLoadCount={value => setLoadCount(value)}
        showModalNewFolder={showModalNewFolder}
        setShowModalNewFolder={value => setShowModalNewFolder(value)}
        setLoading={value => setLoading(value)}
      />
      <ModalRename
        site={site}
        currDir={currDir}
        loadCount={loadCount}
        setLoadCount={value => setLoadCount(value)}
        setLoading={value => setLoading(value)}
        showModalRename={showModalRename}
        setShowModalRename={value => setShowModalRename(value)}
        selectedCount={selectedRowsInfo.selectedCount}
        nome={selectedRowsInfo.selectedRows[0]?.nome || ''}
        toggleCleared={toggleCleared}
        setToggleCleared={value => setToggleCleared(value)}
      />
      <ModalFileUpload
        site={site}
        currDir={currDir}
        loading={loading}
        loadCount={loadCount}
        setLoadCount={value => setLoadCount(value)}
        showModalFileUpload={showModalFileUpload}
        setShowModalFileUpload={value => setShowModalFileUpload(value)}
        existentFiles={files}
      />
      <ModalPermissions
        site={site}
        currDir={currDir}
        setLoading={value => setLoading(value)}
        showModalPermissions={showModalPermissions}
        setShowModalPermissions={value => setShowModalPermissions(value)}
        selectedRows={selectedRowsInfo.selectedRows}
        loadCount={loadCount}
        setLoadCount={value => setLoadCount(value)}
        toggleCleared={toggleCleared}
        setToggleCleared={value => setToggleCleared(value)}
      />
    </Container>
  );
};

export default FileManager;
