import { useQuery } from 'react-query';

import api from 'services/api';

type FtpResponse = {
  data: {
    host: string;
    servidor: string;
    usuario: string;
    versaoPhp: string;
  };
};

type FtpInfo = {
  host: string;
  server: string;
  user: string;
  phpVersion: string;
};

const initialData: FtpInfo = {
  host: '',
  server: '',
  user: '',
  phpVersion: '',
};

async function loadFtpInfo(idSite: number) {
  const { data } = await api.get<FtpResponse>(`painel/v1/site/${idSite}/ftp`);

  const { host, servidor, usuario, versaoPhp } = data.data;

  const ftpInfo = {
    host,
    server: servidor,
    user: usuario,
    phpVersion: versaoPhp,
  };

  return ftpInfo;
}

export default function useFtpInfo(idSite: number) {
  const { data, isError, isLoading } = useQuery<FtpInfo>(
    ['ftp', idSite],
    () => loadFtpInfo(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
    },
  );

  const ftpInfo = data || initialData;

  return { ftpInfo, error: isError, loading: isLoading };
}
