export default function refreshStore() {
  const currentVersion = 'e187413c1d15cb36287db7765950dc37';

  const versionKey = localStorage.getItem('painelhostnetwebts:versionKey');

  if (versionKey && versionKey !== currentVersion) {
    localStorage.clear();
    window.location.reload();
  }

  if (!versionKey) {
    localStorage.setItem('painelhostnetwebts:versionKey', currentVersion);
  }
}
