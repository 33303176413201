import { useQuery } from 'react-query';

import api from 'services/api';

type MauticSmsLicenseKeyResponse = {
  data: {
    id: number;
    licenca: string;
    status: string;
  };
};

type MauticSmsLicenseKey = {
  id: number;
  licenca: string;
  status: string;
};

const initialData: MauticSmsLicenseKey = {
  id: 0,
  licenca: '',
  status: '',
};

async function loadMauticSmsLicenseKey(
  idSite: number,
): Promise<MauticSmsLicenseKey> {
  const response = await api.get<MauticSmsLicenseKeyResponse>(
    `painel/v1/site/${idSite}/mautic-sms/licenca`,
  );

  return {
    id: response.data.data.id,
    licenca: response.data.data.licenca,
    status: response.data.data.status,
  };
}

export default function useMauticSmsLicenseKey(idSite: number) {
  const { data, ...rest } = useQuery<MauticSmsLicenseKey>(
    ['mauticSmsLicenseKey', idSite],
    () => loadMauticSmsLicenseKey(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const mauticSmsLicenseKey = data || initialData;

  return { mauticSmsLicenseKey, ...rest };
}
