import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Formik } from 'formik';
import { Form, Card, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import ReCAPTCHA from 'react-google-recaptcha';

import api from 'services/api';
import swal from 'services/swal';

import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PanelButton from 'components/PanelButton';

import { Container } from './styles';

interface ReissuePaymentResponse {
  data: {
    sucesso: boolean;
  };
}

interface ReissuePaymentFormValues {
  user: string;
  site: string;
  email: string;
}

const ReissuePayment: React.FC = () => {
  const { t } = useTranslation();

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [recaptchaCheck, setRecaptchaCheck] = useState<Boolean>(true);

  const sendReissuePayment = useCallback(
    async (values: ReissuePaymentFormValues, formikHelpers) => {
      const { user, site, email } = values;
      try {
        await api.post<ReissuePaymentResponse>('financeiro/v1/segunda-via', {
          emailCliente: user,
          destinatario: email,
          site,
        });
        swal.fire({
          icon: 'success',
          title: t('pages:reissuePayment.reissuePaymentSuccess', { email }),
          html: t('pages:reissuePayment.reissuePaymentSuccessDescription'),
        });
      } catch (err) {
        swal.fire({
          title: t('pages:reissuePayment.reissuePaymentFail'),
          html: err.response.data.error_description,
        });
      } finally {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        formikHelpers.resetForm();
      }
    },
    [t],
  );

  function onChange(value: string | null) {
    if (value) {
      setRecaptchaCheck(true);
    }
  }

  function onExpired() {
    setRecaptchaCheck(false);
  }

  const validateDomain = useCallback((value: string | undefined) => {
    if (!value) {
      return true;
    }
    return (
      value !== undefined &&
      value.trim() !== '' &&
      value.search(
        /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]+$/,
      ) !== -1
    );
  }, []);

  return (
    <Container>
      <PageTitle
        title={t('pages:reissuePayment.title')}
        description={t('pages:reissuePayment.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:reissuePayment.title')}
          description={t('pages:reissuePayment.description')}
          helpContent={
            <div>
              <p>{t('pages:reissuePayment.helpContent.p1')}</p>
              <p>
                <strong>{t('common:warning')}</strong>
              </p>
              <p>{t('pages:reissuePayment.helpContent.p2')}</p>
            </div>
          }
          helpLinks={[
            {
              url: 'https://www.hostnet.com.br/info/',
              text: t('pages:reissuePayment.helpContent.a1'),
            },
            {
              url: 'https://assine.hostnet.com.br/',
              text: t('pages:reissuePayment.helpContent.a2'),
            },
          ]}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            user: '',
            site: '',
            email: '',
          }}
          validationSchema={Yup.object().shape({
            user: Yup.string().required(
              t('pages:reissuePayment.validation.user'),
            ),
            site: Yup.string().test(
              'site',
              t('pages:siteNameChange.validation.informValidDomain'),
              validateDomain,
            ),
            email: Yup.string()
              .email(t('pages:crontabScheduling.validation.isemail'))
              .required(t('pages:reissuePayment.validation.email')),
          })}
          onSubmit={sendReissuePayment}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="reissue.user">
                  <Form.Label column sm={2} className="required">
                    {t('pages:reissuePayment.user')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="user"
                      value={props.values.user}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.user && !!props.errors.user}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:reissuePayment.userDescription')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.user}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="reissue.site">
                  <Form.Label column sm={2}>
                    {t('common.site')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="site"
                      value={props.values.site}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.site && !!props.errors.site}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:reissuePayment.siteDescription')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.site}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="reissue.email">
                  <Form.Label column sm={2} className="required">
                    {t('common.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:reissuePayment.emailDescription')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Row>
                  <Col sm={{ offset: 2, span: 10 }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lfh5goTAAAAAONLF5ZWoVCLi5guK0zHIpvWy4E1"
                      onChange={(value: string | null) => onChange(value)}
                      onExpired={onExpired}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  variant="primary"
                  onClick={props.submitForm}
                  disabled={
                    props.isSubmitting || !props.isValid || !recaptchaCheck
                  }
                >
                  {t('common:send')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default ReissuePayment;
