import styled from 'styled-components';

export const Container = styled.div`
  .score-word {
    font-size: 12px !important;
    position: absolute;
    right: 0;
  }
  .password-feedback-fix {
    min-height: 18px;
    display: inline-block;
  }
  .password-feedback {
    min-height: 18px;
    display: inline-block;
  }
`;
