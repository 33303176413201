import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Form, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import { Formik } from 'formik';

import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useUltramail from 'hooks/useUltramail';

import { Container } from './styles';

type SecuritySettingsItemResponse = {
  domain: string;
  dmarc: {
    rua: string;
    ruf: string;
  };
};

interface SecuritySettingsResponse {
  data: SecuritySettingsItemResponse;
}

interface SecuritySettings {
  domain: string;
  rua: string;
  ruf: string;
}

interface EditSecuritySettingsResponse {
  data: SecuritySettingsItemResponse;
}

interface EditSecuritySettingsFormValues {
  domain: string;
  rua: string;
  ruf: string;
}

const DmarcSettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { ultramail } = useUltramail(idSite);

  const [selectedSite, setSelectedSite] = useState(site);

  const [securitySettings, setSecuritySettings] = useState<SecuritySettings>({
    domain: site,
    rua: '',
    ruf: '',
  });

  const formatSecuritySettings = useCallback(
    ({ domain, dmarc }: SecuritySettingsItemResponse) => ({
      domain,
      rua: dmarc.rua,
      ruf: dmarc.ruf,
    }),

    [],
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ultramail.possuiSiteComUltramailAtivo) {
      return;
    }

    const activeUltramail = ultramail.quotaDetalhada.find(
      ultramailSite => ultramailSite.ativo,
    );

    if (activeUltramail) {
      setSelectedSite(activeUltramail.site);
    }
  }, [ultramail]);

  useEffect(() => {
    async function loadSecuritySettings() {
      try {
        setError(false);
        setLoading(true);

        const securitySettingsResponse = await api.get<SecuritySettingsResponse>(
          `painel/v1/email/protecao`,
          {
            params: { site: selectedSite },
          },
        );

        const formattedSecuritySettings = formatSecuritySettings(
          securitySettingsResponse.data.data,
        );

        setSecuritySettings(formattedSecuritySettings);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSecuritySettings();
  }, [selectedSite, formatSecuritySettings]);

  const editSecuritySettings = useCallback(
    async (values: EditSecuritySettingsFormValues) => {
      try {
        const { domain, rua, ruf } = values;

        const securitySettingsResponse = await api.put<EditSecuritySettingsResponse>(
          `painel/v1/email/protecao`,
          {
            site: domain,
            dmarc: {
              rua,
              ruf,
            },
          },
        );

        const formattedSecuritySettings = formatSecuritySettings(
          securitySettingsResponse.data.data,
        );

        setSecuritySettings(formattedSecuritySettings);

        toast.fire(t('pages:emailSecuritySettings.successSettings'));
      } catch (err) {
        swal.fire({
          title: t('pages:emailSecuritySettings.failSettings'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [formatSecuritySettings, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:emailSecuritySettings.dmarcTitle')}
          description={t('pages:emailSecuritySettings.advancedDescription')}
          helpContent={
            <div>
              <p>{t('pages:emailSecuritySettings.dmarcHelpContent.p1')}</p>
              <p>{t('pages:emailSecuritySettings.dmarcHelpContent.p2')}</p>
              <p>{t('pages:emailSecuritySettings.dmarcHelpContent.p3')}</p>
            </div>
          }
          helpLinks={[
            {
              text: 'Saiba mais sobre DMARC',
              url: 'https://www.hostnet.com.br/info/dmarc/',
            },
          ]}
        />
        <Formik
          enableReinitialize
          initialValues={{
            domain: securitySettings.domain,
            rua: securitySettings.rua,
            ruf: securitySettings.ruf,
          }}
          validationSchema={Yup.object().shape({
            rua: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            ruf: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
          })}
          onSubmit={editSecuritySettings}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                {ultramail.possuiSiteComUltramailAtivo && (
                  <div>
                    <Form.Group as={Row} controlId="form-domain">
                      <Form.Label column sm={2}>
                        {t('common:site')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="domain"
                          value={props.values.domain}
                          onChange={event =>
                            setSelectedSite(event.target.value)
                          }
                          disabled={props.isSubmitting}
                        >
                          {ultramail.quotaDetalhada.map(
                            siteUltramail =>
                              siteUltramail.ativo && (
                                <option
                                  key={siteUltramail.site}
                                  value={siteUltramail.site}
                                >
                                  {siteUltramail.site.toLowerCase()}
                                </option>
                              ),
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="form-rua">
                      <Form.Label column sm={2}>
                        {t('pages:emailSecuritySettings.dmarcRua')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="rua"
                          placeholder={t(
                            'pages:emailSecuritySettings.dmarcRuaPlaceholder',
                          )}
                          value={props.values.rua}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={!!props.touched.rua && !!props.errors.rua}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted" />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.rua}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="form-ruf">
                      <Form.Label column sm={2}>
                        {t('pages:emailSecuritySettings.dmarcRuf')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ruf"
                          placeholder={t(
                            'pages:emailSecuritySettings.dmarcRufPlaceholder',
                          )}
                          value={props.values.ruf}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={!!props.touched.ruf && !!props.errors.ruf}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted" />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ruf}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </div>
                )}
                <div>
                  {!ultramail.possuiSiteComUltramailAtivo && (
                    <p>{t('pages:emailSecuritySettings.noSecuritySettings')}</p>
                  )}
                </div>
              </Card.Body>

              {ultramail.possuiSiteComUltramailAtivo && (
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/emails/configuracoes-seguranca"
                    disabled={props.isSubmitting}
                  >
                    {t('common:label.back')}
                  </PanelButton>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default DmarcSettings;
