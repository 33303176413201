import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import PanelCard from 'components/PanelCard';

import useBalance from 'hooks/useBalance';
import useTotalCost from 'hooks/useTotalCost';

import formatCurrency from 'utils/formatCurrency';

import { FinancialInfo, BasicLink } from './styles';

const PaymentInfo: React.FC = () => {
  const { t } = useTranslation();

  const { idCliente, tipoCobranca } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { info } = useSelector((state: StoreState) => state.site);

  const { totalCost } = useTotalCost(idCliente, info.idSite, tipoCobranca);

  const { balance } = useBalance(idCliente, info.idSite, tipoCobranca);

  return (
    <Row className="my-4">
      <Col xl={3} md={6} className="mb-4 mb-xl-0">
        <PanelCard>
          <Card.Body>
            <div className="mb-4">
              <h6 className="m-0">{t('common:value')}</h6>
              <FinancialInfo className="blue">
                {`R$${totalCost.total}`}
              </FinancialInfo>
            </div>
            <BasicLink to="/financeiro/adicionar-credito">
              Adicionar crédito
            </BasicLink>
          </Card.Body>
        </PanelCard>
      </Col>

      <Col xl={3} md={6} className="mb-4 mb-xl-0">
        <PanelCard>
          <Card.Body>
            <div className="mb-4">
              <h6 className="m-0">Vencimento</h6>
              <FinancialInfo className="violet">
                {totalCost.expirationDay}
              </FinancialInfo>
            </div>
            <BasicLink to="/financeiro/forma-pagamento">
              Forma de pagamento
            </BasicLink>
          </Card.Body>
        </PanelCard>
      </Col>

      <Col xl={3} md={6} className="mb-4 mb-xl-0">
        <PanelCard>
          <Card.Body>
            <div className="mb-4">
              <h6 className="m-0">Saldo</h6>
              <FinancialInfo className="yellow">
                {formatCurrency(balance.balance)}
              </FinancialInfo>
            </div>
            <BasicLink to="/financeiro/extrato">Extrato financeiro</BasicLink>
          </Card.Body>
        </PanelCard>
      </Col>

      <Col xl={3} md={6}>
        <PanelCard>
          <Card.Body>
            <div className="mb-4">
              <h6 className="m-0">Pagamento</h6>
              <FinancialInfo className="green">
                {totalCost.paymentForm}
              </FinancialInfo>
            </div>
            <BasicLink to="/financeiro/historico-boletos">
              Boletos abertos
            </BasicLink>
          </Card.Body>
        </PanelCard>
      </Col>
    </Row>
  );
};

export default PaymentInfo;
