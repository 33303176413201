import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

interface ConnectionsModalProps {
  site: string;
  show: boolean;
  close: () => void;
  banco: string;
  ownerInterno: string;
  ownerExterno: string;
}

const ConnectionsModal: React.FC<ConnectionsModalProps> = ({
  site,
  close,
  show,
  banco,
  ownerExterno,
  ownerInterno,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={() => close()} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {t('pages:databasesMySql.databaseConnections')} - {banco}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <div>
            <a
              href={`http://amysql.${site}`}
              target="_blank"
              rel="noopener noreferrer"
              className="font-weight-bold"
            >
              amysql.{site}
            </a>
            <p>{t('pages:databasesMySql.linkAdminDescription')}.</p>
          </div>
        </div>
        <div className="mt-4">
          <div className="font-weight-bold">
            {ownerInterno}.{site}
          </div>
          <p>{t('pages:databasesMySql.internalAddressDescription')}.</p>
        </div>
        <div className="mt-4">
          <div className="font-weight-bold">
            {ownerExterno}.{site}
          </div>
          <p>{t('pages:databasesMySql.externalAddressDescription')}.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PanelButton variant="primary" onClick={() => close()}>
          {t('common:button.close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ConnectionsModal;
