import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaDatabase } from 'react-icons/fa';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';

import { StoreState } from 'store/createStore';

import useMySqlLimits from 'hooks/useMySqlLimits';
import useMySqlSettings from 'hooks/useMySqlSettings';

import swal from 'services/swal';
import toast from 'services/toast';
import api from 'services/api';

import { Container } from './styles';

interface MySqlMemorySettingsFormValues {
  memory: number;
  keyBufferSize: number;
  queryCacheSize: number;
  tmpTableSize: number;
  innodbBufferPoolSize: number;
  innodbLogBufferSize: number;
  sortBufferSize: number;
  readBufferSize: number;
  readRndBufferSize: number;
  joinBufferSize: number;
  threadStack: number;
}

const MemorySettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, idPlano } = useSelector(
    (state: StoreState) => state.site.info,
  );

  const { mySqlSettings, isLoading, isError, refetch } = useMySqlSettings(
    idSite,
  );

  const {
    mysqlLimits,
    isLoading: loadingLimits,
    isError: errorMySqlLimits,
  } = useMySqlLimits(idPlano);

  async function handleFormSubmit(values: MySqlMemorySettingsFormValues) {
    try {
      await api.put(`painel/v1/site/${idSite}/mysql`, {
        keyBufferSize: values.keyBufferSize * 1024,
        queryCacheSize: values.queryCacheSize * 1024,
        tmpTableSize: values.tmpTableSize * 1024,
        innodbBufferPoolSize: values.innodbBufferPoolSize * 1024,
        innodbLogBufferSize: values.innodbLogBufferSize * 1024,
        sortBufferSize: values.sortBufferSize,
        readBufferSize: values.readBufferSize,
        readRndBufferSize: values.readRndBufferSize,
        joinBufferSize: values.joinBufferSize,
        threadStack: values.threadStack,
      });

      await refetch();

      toast.fire(t('pages:mySqlCloudSettings.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:mySqlCloudSettings.failed'),
        html: err.response.data.error_description,
      });
    }
  }

  function restoreDefaultSettings(
    formikHelpers: FormikHelpers<MySqlMemorySettingsFormValues>,
  ) {
    swal
      .fire({
        icon: 'warning',
        title: t('common:confirmOperation'),
        html: t('pages:mySqlCloudMemorySettings.confirmRestore'),
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary btn-sm mr-3',
          cancelButton: 'btn btn-secondary btn-sm',
        },
      })
      .then(result => {
        if (result.isConfirmed) {
          formikHelpers.setValues({
            memory: mySqlSettings.memory * 1000 * 0.78,
            keyBufferSize: mysqlLimits.min.keyBufferSize,
            queryCacheSize: mysqlLimits.min.queryCacheSize,
            tmpTableSize: mysqlLimits.min.tmpTableSize,
            innodbBufferPoolSize: mysqlLimits.min.innodbBufferPoolSize,
            innodbLogBufferSize: mysqlLimits.min.innodbLogBufferSize,
            sortBufferSize: mysqlLimits.min.sortBufferSize,
            readBufferSize: mysqlLimits.min.readBufferSize,
            readRndBufferSize: mysqlLimits.min.readRndBufferSize,
            joinBufferSize: mysqlLimits.min.joinBufferSize,
            threadStack: mysqlLimits.min.threadStack,
          });

          formikHelpers.submitForm();
        }
      });
  }

  if (isLoading || loadingLimits) {
    return <Loading />;
  }

  if (isError || errorMySqlLimits) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:mySqlCloudMemorySettings.title')}
          description={t('pages:mySqlCloudMemorySettings.description')}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            memory: mySqlSettings.memory * 1000 * 0.78,
            keyBufferSize: Math.round(mySqlSettings.keyBufferSize / 1024),
            queryCacheSize: Math.round(mySqlSettings.queryCacheSize / 1024),
            tmpTableSize: Math.round(mySqlSettings.tmpTableSize / 1024),
            innodbBufferPoolSize: Math.round(
              mySqlSettings.innodbBufferPoolSize / 1024,
            ),
            innodbLogBufferSize: Math.round(
              mySqlSettings.innodbLogBufferSize / 1024,
            ),
            sortBufferSize: mySqlSettings.sortBufferSize,
            readBufferSize: mySqlSettings.readBufferSize,
            readRndBufferSize: mySqlSettings.readRndBufferSize,
            joinBufferSize: mySqlSettings.joinBufferSize,
            threadStack: mySqlSettings.threadStack,
          }}
          validationSchema={Yup.object().shape({
            keyBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.keyBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.keyBufferSize,
                  max: mysqlLimits.max.keyBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.keyBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.keyBufferSize,
                  max: mysqlLimits.max.keyBufferSize,
                }),
              ),
            queryCacheSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.queryCacheSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.queryCacheSize,
                  max: mysqlLimits.max.queryCacheSize,
                }),
              )
              .max(
                mysqlLimits.max.queryCacheSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.queryCacheSize,
                  max: mysqlLimits.max.queryCacheSize,
                }),
              ),
            tmpTableSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.tmpTableSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.tmpTableSize,
                  max: mysqlLimits.max.tmpTableSize,
                }),
              )
              .max(
                mysqlLimits.max.tmpTableSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.tmpTableSize,
                  max: mysqlLimits.max.tmpTableSize,
                }),
              ),
            innodbBufferPoolSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.innodbBufferPoolSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.innodbBufferPoolSize,
                  max: mysqlLimits.max.innodbBufferPoolSize,
                }),
              )
              .max(
                mysqlLimits.max.innodbBufferPoolSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.innodbBufferPoolSize,
                  max: mysqlLimits.max.innodbBufferPoolSize,
                }),
              ),
            innodbLogBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.innodbLogBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.innodbLogBufferSize,
                  max: mysqlLimits.max.innodbLogBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.innodbLogBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.innodbLogBufferSize,
                  max: mysqlLimits.max.innodbLogBufferSize,
                }),
              ),
            sortBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.sortBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.sortBufferSize,
                  max: mysqlLimits.max.sortBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.sortBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.sortBufferSize,
                  max: mysqlLimits.max.sortBufferSize,
                }),
              ),
            readBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.readBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.readBufferSize,
                  max: mysqlLimits.max.readBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.readBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.readBufferSize,
                  max: mysqlLimits.max.readBufferSize,
                }),
              ),
            readRndBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.readRndBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.readRndBufferSize,
                  max: mysqlLimits.max.readRndBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.readRndBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.readRndBufferSize,
                  max: mysqlLimits.max.readRndBufferSize,
                }),
              ),
            joinBufferSize: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.joinBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.joinBufferSize,
                  max: mysqlLimits.max.joinBufferSize,
                }),
              )
              .max(
                mysqlLimits.max.joinBufferSize,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.joinBufferSize,
                  max: mysqlLimits.max.joinBufferSize,
                }),
              ),
            threadStack: Yup.number()
              .required(t('validations:requiredField'))
              .min(
                mysqlLimits.min.threadStack,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.threadStack,
                  max: mysqlLimits.max.threadStack,
                }),
              )
              .max(
                mysqlLimits.max.threadStack,
                t('validations:enterANumberBetween', {
                  min: mysqlLimits.min.threadStack,
                  max: mysqlLimits.max.threadStack,
                }),
              ),
          })}
          onSubmit={handleFormSubmit}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset pt-0">
                <FormSubtitle
                  subTitle={t('pages:mySqlCloudMemorySettings.usageProfile')}
                />
                <Form.Group
                  className="mb-4"
                  as={Row}
                  controlId="entity.document"
                >
                  <Form.Label column sm={2}>
                    Total de memória usada
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      value={props.values.memory}
                    />
                    <Form.Text className="text-muted">
                      Só é permitido usar no máximo 80% da memória contratada do
                      plano.
                    </Form.Text>
                  </Col>
                </Form.Group>

                <FormSubtitle
                  subTitle={t('pages:mySqlCloudMemorySettings.memoryLimits')}
                />

                <Form.Group as={Row} controlId="mysqlMemory.keyBufferSize">
                  <Form.Label column sm={2}>
                    key_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="keyBufferSize"
                        value={props.values.keyBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.keyBufferSize &&
                          !!props.errors.keyBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.keyBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.keyBufferSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.queryCacheSize">
                  <Form.Label column sm={2}>
                    query_cache_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="queryCacheSize"
                        value={props.values.queryCacheSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.queryCacheSize &&
                          !!props.errors.queryCacheSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.queryCacheSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.queryCacheSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.tmpTableSize">
                  <Form.Label column sm={2}>
                    tmp_table_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="tmpTableSize"
                        value={props.values.tmpTableSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.tmpTableSize &&
                          !!props.errors.tmpTableSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.tmpTableSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.tmpTableSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="mysqlMemory.innodbBufferPoolSize"
                >
                  <Form.Label column sm={2}>
                    innodb_buffer_pool_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="innodbBufferPoolSize"
                        value={props.values.innodbBufferPoolSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.innodbBufferPoolSize &&
                          !!props.errors.innodbBufferPoolSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.innodbBufferPoolSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t(
                        'pages:mySqlCloudMemorySettings.tips.innodbBufferPoolSize',
                      )}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="mysqlMemory.innodbLogBufferSize"
                >
                  <Form.Label column sm={2}>
                    innodb_log_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="innodbLogBufferSize"
                        value={props.values.innodbLogBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.innodbLogBufferSize &&
                          !!props.errors.innodbLogBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">MB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.innodbLogBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t(
                        'pages:mySqlCloudMemorySettings.tips.innodbLogBufferSize',
                      )}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.sortBufferSize">
                  <Form.Label column sm={2}>
                    sort_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="sortBufferSize"
                        value={props.values.sortBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.sortBufferSize &&
                          !!props.errors.sortBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">KB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.sortBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.sortBufferSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.readBufferSize">
                  <Form.Label column sm={2}>
                    read_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="readBufferSize"
                        value={props.values.readBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.readBufferSize &&
                          !!props.errors.readBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">KB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.readBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.readBufferSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.readRndBufferSize">
                  <Form.Label column sm={2}>
                    read_rnd_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="readRndBufferSize"
                        value={props.values.readRndBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.readRndBufferSize &&
                          !!props.errors.readRndBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">KB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.readRndBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t(
                        'pages:mySqlCloudMemorySettings.tips.readRndBufferSize',
                      )}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.joinBufferSize">
                  <Form.Label column sm={2}>
                    join_buffer_size
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="joinBufferSize"
                        value={props.values.joinBufferSize}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.joinBufferSize &&
                          !!props.errors.joinBufferSize
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">KB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.joinBufferSize}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.joinBufferSize')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysqlMemory.threadStack">
                  <Form.Label column sm={2}>
                    thread_stack
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        name="threadStack"
                        value={props.values.threadStack}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.threadStack &&
                          !!props.errors.threadStack
                        }
                        disabled={props.isSubmitting}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon2">KB</InputGroup.Text>
                      </InputGroup.Append>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.threadStack}
                      </Form.Control.Feedback>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t('pages:mySqlCloudMemorySettings.tips.threadStack')}
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  onClick={() => restoreDefaultSettings(props)}
                  className="mr-1"
                  disabled={props.isSubmitting}
                >
                  {t('pages:mySqlCloudSettings.restore')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default MemorySettings;
