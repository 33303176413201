import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import Error from 'components/Error';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { Container } from './styles';

interface PendingNameChangeResponse {
  data: [] | { idTicket: number; dataSolicitacao: string };
}

interface RequestNameChangeResponse {
  data: { idTicket: number; dataSolicitacao: string };
}

interface PendingNameChange {
  idTicket: number;
  dataSolicitacao: string;
}

interface NameChangeFormValues {
  newSite: string;
  termToConclude: 'TRUE' | 'FALSE';
}

const NameChange: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pendingNameChange, setPendingNameChange] = useState<PendingNameChange>(
    {
      idTicket: 0,
      dataSolicitacao: '',
    },
  );

  useEffect(() => {
    async function loadPendingNameChange() {
      try {
        setLoading(true);
        setError(false);

        const pendingNameChangeResponse = await api.get<PendingNameChangeResponse>(
          `clientes/v1/site/${idSite}/troca`,
        );

        if ('idTicket' in pendingNameChangeResponse.data.data) {
          const {
            idTicket,
            dataSolicitacao,
          } = pendingNameChangeResponse.data.data;

          setPendingNameChange({ idTicket, dataSolicitacao });
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadPendingNameChange();
  }, [idSite]);

  async function requestNameChange(
    values: NameChangeFormValues,
    formikHelpers: FormikHelpers<NameChangeFormValues>,
  ) {
    try {
      const nameChangeResponse = await api.post<RequestNameChangeResponse>(
        `clientes/v1/site/${idSite}/troca`,
        {
          siteNovo: values.newSite.toLowerCase(),
        },
      );

      const { idTicket, dataSolicitacao } = nameChangeResponse.data.data;

      setPendingNameChange({
        idTicket,
        dataSolicitacao,
      });

      toast.fire(t('pages:siteNameChange.requestSuccess'));

      formikHelpers.resetForm();
    } catch (err) {
      swal.fire({
        title: t('pages:siteNameChange.requestFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  const validateDomain = useCallback((value: string | undefined) => {
    return (
      value !== undefined &&
      value.trim() !== '' &&
      value.search(
        /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]+$/,
      ) !== -1
    );
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:siteNameChange.title')}
          description={t('pages:siteNameChange.description')}
          directHelpLink="https://ajuda.hostnet.com.br/troca-do-site-de-dominio-no-painel-de-controle/"
        />
        {pendingNameChange.idTicket !== 0 ? (
          <div className="p-4">
            <Trans
              i18nKey="pages:siteNameChange.helpContent.pendingRequest"
              values={{ idTicket: pendingNameChange.idTicket }}
            >
              content
              <Link to={`helpdesk/ticket/${pendingNameChange.idTicket}`} />
            </Trans>
          </div>
        ) : (
          <Formik
            enableReinitialize
            initialValues={{
              newSite: '',
              termToConclude: 'FALSE',
            }}
            validationSchema={Yup.object().shape({
              newSite: Yup.string()
                .required(
                  t('pages:siteNameChange.validation.informValidDomain'),
                )
                .test(
                  'newSite',
                  t('pages:siteNameChange.validation.informValidDomain'),
                  validateDomain,
                ),
              termToConclude: Yup.string().required(
                t('pages:siteNameChange.validation.informValidRadio'),
              ),
            })}
            onSubmit={requestNameChange}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('common:label.site')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control plaintext readOnly defaultValue={site} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:siteNameChange.newSite')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        type="text"
                        id="newSite"
                        name="newSite"
                        placeholder={t(
                          'pages:siteNameChange.newSitePlaceholder',
                        )}
                        value={props.values.newSite}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.newSite && !!props.errors.newSite
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted" />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.newSite}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:siteNameChange.termToConclude')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="termToConclude-yes"
                        name="termToConclude"
                        label={t('common:yes')}
                        value="TRUE"
                        checked={props.values.termToConclude === 'TRUE'}
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.termToConclude &&
                          !!props.errors.termToConclude
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="termToConclude-no"
                        name="termToConclude"
                        label={t('common:no')}
                        value="FALSE"
                        checked={props.values.termToConclude === 'FALSE'}
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.termToConclude &&
                          !!props.errors.termToConclude
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:siteNameChange.termToConcludeInfo')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.termToConclude}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    disabled={
                      props.isSubmitting ||
                      !props.isValid ||
                      props.values.termToConclude === 'FALSE'
                    }
                  >
                    {props.isSubmitting
                      ? t('common:requesting')
                      : t('common:request')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </FormWrapper>
    </Container>
  );
};

export default NameChange;
