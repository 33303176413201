import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PhoneInput from 'components/PhoneInput';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';
import history from 'services/history';

import useBrDomainsContacts from 'hooks/useBrDomainsContacts';
import useBrDomainsEntities from 'hooks/useBrDomainsEntities';
import useClient from 'hooks/useClient';

import { Container } from './styles';

interface FormValues {
  idContact: number;
  type: 'FISICA' | 'JURIDICA';
  document: string;
  name: string;
  responsible: string;
  address: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  phone: string;
  phoneExt: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { contacts, loading } = useBrDomainsContacts();
  const { refetch } = useBrDomainsEntities();
  const { client, loading: loadingClient } = useClient();

  function formatPhone(phone: string): string {
    if (phone.includes('.')) {
      return phone.split('.')[1];
    }

    return phone;
  }

  async function submitForm(values: FormValues) {
    try {
      const fullPhone = `${values.countryCode}.${values.phone.replace(
        /[^\d]/g,
        '',
      )}`;

      await api.post(`registrobr/v1/entidade`, {
        idContato: values.idContact,
        cpfCnpj: values.document.replace(/[^\d]/g, ''),
        nome: values.name,
        responsavel: values.responsible,
        endereco: values.address,
        numero: values.number,
        cidade: values.city,
        cep: values.zipCode.replace(/[^\d]/g, ''),
        estado: values.state,
        telefone: fullPhone,
        ramal: values.phoneExt,
        complemento: values.complement,
      });

      refetch();

      toast.fire(t('pages:brDomainEntitiesNew.success'));

      history.push(`/registro/entidades-br`);
    } catch (err) {
      swal.fire({
        title: t('pages:brDomainEntitiesNew.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (loading || loadingClient) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:brDomainEntitiesNew.title')}
          description={t('pages:brDomainEntitiesNew.description')}
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            idContact: contacts.length > 0 ? contacts[0].id : 0,
            type: 'FISICA',
            document: '',
            name: '',
            responsible: '',
            address: client.endereco,
            number: client.numero,
            complement: client.complemento,
            city: client.cidade,
            state: client.estado,
            zipCode: client.cep,
            countryCode: '55',
            phone: formatPhone(client.telefone),
            phoneExt: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            responsible: Yup.string().when('type', {
              is: 'JURIDICA',
              then: Yup.string().required(t('validations:requiredField')),
            }),
            document: Yup.string()
              .when('type', {
                is: 'JURIDICA',
                then: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 14,
                ),
                otherwise: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 11,
                ),
              })
              .required(t('validations:requiredField')),
            address: Yup.string().required(t('validations:requiredField')),
            number: Yup.string().required(t('validations:requiredField')),
            city: Yup.string().required(t('validations:requiredField')),
            state: Yup.string().required(t('validations:requiredField')),
            countryCode: Yup.number()
              .required(t('validations:requiredField'))
              .typeError(t('validations:invalidNumber')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:brDomainEntitiesNew.legend')}
                />
                <Form.Group as={Row} controlId="brEntityNew.idContact">
                  <Form.Label column sm={2}>
                    {t('common:contact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="idContact"
                      as="select"
                      value={props.values.idContact}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.idContact && !!props.errors.idContact
                      }
                      disabled={props.isSubmitting}
                    >
                      {contacts.map(state => (
                        <option key={state.id} value={state.id}>
                          {state.contact}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.idContact}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="form-type">
                  <Form.Label column sm={2}>
                    {t('common:type')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="natural-contact"
                      type="radio"
                      label={t('common:naturalPerson')}
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('type', 'FISICA')}
                      checked={props.values.type === 'FISICA'}
                    />
                    <Form.Check
                      inline
                      custom
                      id="legal-contact"
                      type="radio"
                      label={t('common:legalPerson')}
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('type', 'JURIDICA')}
                      checked={props.values.type === 'JURIDICA'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.document">
                  <Form.Label column sm={2} className="required">
                    {props.values.type === 'JURIDICA'
                      ? t('common:cnpj')
                      : t('common:cpf')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputMask
                      name="document"
                      mask={
                        props.values.type === 'FISICA'
                          ? '999.999.999-99'
                          : '99.999.999/9999-99'
                      }
                      value={props.values.document}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={props.isSubmitting}
                      className={`form-control ${
                        !!props.touched.document &&
                        !!props.errors.document &&
                        'is-invalid'
                      }`}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:brDomainEntitiesNew.documentTip')}
                    </Form.Text>
                    {props.values.type === 'FISICA' && (
                      <Form.Text className="text-muted">
                        {t('pages:brDomainEntitiesNew.legalAgeTip')}
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {props.errors.document}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.name">
                  <Form.Label column sm={2} className="required">
                    {props.values.type === 'FISICA'
                      ? t('common:name')
                      : t('pages:brDomainEntitiesNew.companyName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {props.values.type === 'JURIDICA' && (
                  <Form.Group as={Row} controlId="contact.responsible">
                    <Form.Label column sm={2} className="required">
                      {t('pages:brDomainEntitiesNew.nameOfResponsible')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="responsible"
                        value={props.values.responsible}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.responsible &&
                          !!props.errors.responsible
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.responsible}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row} controlId="contact.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.number">
                  <Form.Label column sm={2} className="required">
                    {t('common:number')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="number"
                      value={props.values.number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.number && !!props.errors.number
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.complement">
                  <Form.Label column sm={2}>
                    {t('common:complement')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="complement"
                      value={props.values.complement}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.complement && !!props.errors.complement
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.complement}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as={InputMask}
                      mask="99999-999"
                      name="zipCode"
                      value={props.values.zipCode}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.zipCode && !!props.errors.zipCode
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.city">
                  <Form.Label column sm={2} className="required">
                    {t('common:city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.state">
                  <Form.Label column sm={2} className="required">
                    {t('common:state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="state"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.phone">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.phoneExt">
                  <Form.Label column sm={2}>
                    {t('common:extensionNumber')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="phoneExt"
                      value={props.values.phoneExt}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.phoneExt && !!props.errors.phoneExt
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.phoneExt}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/entidades-br"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
