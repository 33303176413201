import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { StoreState } from '../createStore';

import auth from './auth/reducer';
import sites from './sites/reducer';
import site from './site/reducer';
import client from './client/reducer';
import options from './options/reducer';

const sitePersistConfig = {
  key: 'site',
  storage,
  blacklist: ['loading', 'error'],
};

export default combineReducers<StoreState>({
  auth,
  client,
  sites,
  site: persistReducer(sitePersistConfig, site),
  options,
});
