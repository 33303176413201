import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';
import history from 'services/history';

import useUltramailDomains from 'hooks/useUltramailDomains';
import useUltramailDomainsRedirects from 'hooks/useUltramailDomainsRedirects';

import { Container } from './styles';

interface createRedirectFormValues {
  dominio: string;
  emailOrigem: string;
  emailDestino: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { ultramailDomains } = useUltramailDomains();
  const { refetch } = useUltramailDomainsRedirects();

  const availableUltramailDomains = ultramailDomains.filter(
    ultramailDomain => ultramailDomain.ativo,
  );

  async function create(values: createRedirectFormValues) {
    try {
      const { emailOrigem, emailDestino, dominio } = values;

      await api.post('painel/v1/ultramail-dominio/redirecionamento', {
        dominio,
        emailOrigem: `${emailOrigem}@${dominio}`,
        emailDestino,
      });

      toast.fire(t('pages:newRedirectEmail.success'));

      refetch();

      history.push(`/emails/redirecionamentos-dominio`);
    } catch (err) {
      swal.fire({
        title: t('pages:newRedirectEmail.fail'),
        html: err.response.data.error_description,
      });
    }
  }

  if (availableUltramailDomains.length === 0) {
    return (
      <Container>
        <PageTitle
          title={t('pages:emails.title')}
          description={t('pages:emails.description')}
          icon={<FaEnvelope color="#FFFFFF" size={24} />}
        />
        <FormWrapper>
          <FormHeader
            title={t('pages:emailDomainRedirectsNew.title')}
            description={t('pages:emailDomainRedirectsNew.description')}
            helpContent={<div />}
          />
          <Card.Body className="fieldset">
            {t('pages:emailDomainRedirectsNew.noDomains')}
          </Card.Body>
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton
              variant="secondary"
              forwardedAs={Link}
              to="/emails/redirecionamentos-dominio"
            >
              {t('common:back')}
            </PanelButton>
          </div>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:newRedirectEmail.title')}
          description={t('pages:newRedirectEmail.description')}
          helpContent={
            <div>
              <p>{t('pages:newRedirectEmail.helpContent.p1')}</p>
              <p>{t('pages:newRedirectEmail.helpContent.p2')}</p>
            </div>
          }
          helpLinks={[
            {
              url:
                'https://ajuda.hostnet.com.br/redirecionamento-de-email-pelo-painel-de-controle/',
              text: t('pages:newRedirectEmail.linkRedirects'),
            },
          ]}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            dominio: availableUltramailDomains[0].dominio,
            emailOrigem: '',
            emailDestino: '',
          }}
          validationSchema={Yup.object().shape({
            emailOrigem: Yup.string()
              .test(
                'validEmail',
                t('validations:invalidEmail'),
                val =>
                  (val !== undefined &&
                    /^[a-z][_a-z0-9-]*(\.[_a-z0-9-]+)?$/.test(val)) ||
                  val === '*',
              )
              .required(t('validations:requiredField')),
            emailDestino: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
          })}
          onSubmit={create}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="newRedirectEmail.emailOrigem">
                  <Form.Label column sm={2}>
                    {t('pages:newRedirectEmail.redirectEmail')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="emailOrigem"
                        value={props.values.emailOrigem}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.emailOrigem &&
                          !!props.errors.emailOrigem
                        }
                        disabled={props.isSubmitting}
                      />
                      <DropdownButton
                        as={InputGroup.Append}
                        variant="outline-secondary"
                        title={`@${props.values.dominio}`}
                        id="input-group-dropdown-2"
                      >
                        {availableUltramailDomains.map(ultramailDomain => (
                          <Dropdown.Item
                            key={ultramailDomain.dominio}
                            onSelect={() =>
                              props.setFieldValue(
                                'dominio',
                                ultramailDomain.dominio,
                              )
                            }
                          >
                            @{ultramailDomain.dominio}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.emailOrigem}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newRedirectEmail.emailDestino">
                  <Form.Label column sm={2}>
                    {t('pages:newRedirectEmail.redirectTo')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        name="emailDestino"
                        value={props.values.emailDestino}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.emailDestino &&
                          !!props.errors.emailDestino
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.emailDestino}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-2"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/emails/redirecionamentos-dominio"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
