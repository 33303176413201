import React from 'react';
import { Button } from 'react-bootstrap';
import { StyledComponentProps } from 'styled-components';

import { StyledButton } from './styles';

type PanelButtonProps = StyledComponentProps<Button, {}, {}, ''>;

const PanelButton: React.FC<PanelButtonProps> = props => {
  return <StyledButton {...props} />;
};

export default PanelButton;
