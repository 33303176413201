import styled from 'styled-components';

import { Navbar } from 'react-bootstrap';

export const Container = styled(Navbar)`
  box-shadow: 0 2px 4px 0 rgba(43, 43, 43, 0.1);

  .mobile {
    @media only screen and (max-width: 991px) {
      flex-flow: column;
    }
  }
`;

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-grow: 1;
  background: #fff;
`;
