import { useQuery } from 'react-query';

import api from 'services/api';

interface TicketItemResponse {
  idTicket: number;
  idCliente: string;
  idSituacao: number;
  assunto: string;
}

interface TicketsResponse {
  data: TicketItemResponse[];
}

const initialData = 0;

async function loadPendingTickets(): Promise<number> {
  const { data } = await api.get<TicketsResponse>(`helpdesk/v1/chamado`);

  const pendingTickets = data.data.filter(ticket => ticket.idSituacao === 3)
    .length;

  return pendingTickets;
}

export default function usePendingTickets() {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<number>(
    'pendingTickets',
    () => loadPendingTickets(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const pendingTickets = data || initialData;

  return {
    pendingTickets,
    error: isError,
    loading: isLoading,
    isFetching,
    refetch,
  };
}
