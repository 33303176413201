import { useQuery } from 'react-query';

import api from 'services/api';

interface DatabaseItemResponse {
  id: number;
  banco: string;
  descricao: string;
  ativo: boolean;
  usuario: string;
  server: string;
  ownerExterno: string;
  ownerInterno: string;
  remocao: string;
}

interface DatabasesResponse {
  data: DatabaseItemResponse[];
}

interface Database {
  id: number;
  banco: string;
  descricao: string;
  ativo: boolean;
  server: string;
  usuario: string;
  ownerExterno: string;
  ownerInterno: string;
  remocao: string;
}

function formatDatabase(database: DatabaseItemResponse): Database {
  const {
    id,
    banco,
    descricao,
    ativo,
    usuario,
    server,
    ownerExterno,
    ownerInterno,
    remocao,
  } = database;

  return {
    id,
    banco,
    descricao,
    ativo,
    usuario,
    server,
    ownerExterno,
    ownerInterno,
    remocao,
  };
}

async function loadDatabases(idSite: number): Promise<Database[]> {
  const databasesResponse = await api.get<DatabasesResponse>(
    `painel/v1/site/${idSite}/banco-mysql`,
  );

  const databases = databasesResponse.data.data.map(formatDatabase);

  return databases;
}

const initialData: Database[] = [];

export default function useDatabases(idSite: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    Database[]
  >(['databases', idSite], () => loadDatabases(idSite), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const databases = data || initialData;

  return {
    databases,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
