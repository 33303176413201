import { useQuery } from 'react-query';

import api from 'services/api';

type ExtraSiteItemResponse = {
  id: string;
  site: string;
  sitePrincipal: string;
  idSitePrincipal: number;
};

type ExtraSitesResponse = {
  data: ExtraSiteItemResponse[];
};

type ExtraSite = {
  id: number;
  site: string;
  sitePrincipal: string;
  idSitePrincipal: number;
};

const initialData: ExtraSite[] = [];

function formatSite(site: ExtraSiteItemResponse): ExtraSite {
  const { id, ...rest } = site;

  return {
    id: Number(id),
    ...rest,
  };
}

async function loadExtraSites(idSite: number): Promise<ExtraSite[]> {
  const extraSitesResponse = await api.get<ExtraSitesResponse>(
    `clientes/v1/site/${idSite}/site-extra`,
  );

  const extraSites = extraSitesResponse.data.data.map(formatSite);

  return extraSites;
}

export default function useExtraSites(idSite: number) {
  const { data, ...rest } = useQuery<ExtraSite[]>(
    ['extraSites', idSite],
    () => loadExtraSites(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const extraSites = data || initialData;

  return { extraSites, ...rest };
}
