import { useQuery } from 'react-query';

import api from 'services/api';

interface CountriesResponse {
  data: {
    idPais: number;
    pais: string;
    sigla: string;
    continente: string;
    paisEn: string;
  }[];
}

type Country = {
  idPais: number;
  pais: string;
  sigla: string;
  continente: string;
  paisEn: string;
};

const initialData: Country[] = [];

async function loadCountries(): Promise<Country[]> {
  const countriesResponse = await api.get<CountriesResponse>(`regiao/v1/pais`);

  const formattedCountries = countriesResponse.data.data.map(
    ({ idPais, pais, sigla, continente, paisEn }) => ({
      idPais,
      pais,
      sigla,
      continente,
      paisEn,
    }),
  );

  return formattedCountries;
}

export default function useCountries() {
  const { data, isError, isLoading } = useQuery<Country[]>(
    ['countries'],
    () => loadCountries(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      placeholderData: initialData,
    },
  );

  const countries = data || initialData;

  return { countries, error: isError, loading: isLoading };
}
