import { action } from 'typesafe-actions';

import { Site, Resources } from './types';

export function siteRequest(idSite: number) {
  return action('@site/SITE_REQUEST', { idSite });
}

export function siteSuccess({
  info,
  resources,
}: {
  info: Site;
  resources: Resources;
}) {
  return action('@site/SITE_SUCCESS', { info, resources });
}

export function siteFailure() {
  return action('@site/SITE_FAILURE');
}

export function setSelectedSiteId(idSite: number) {
  return action('@site/SET_SELECTED_SITE_ID', { idSite });
}

export function setSelectedSiteName(siteName: string) {
  return action('@site/SET_SELECTED_SITE_NAME', { siteName });
}
