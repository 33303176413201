import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';
import SideMenuAccordionExternalLink from 'components/SideMenu/components/SideMenuAccordionExternalLink';

import { filterMenuOptions } from 'components/SideMenu/filterMenuOptions';

import { EnvelopeIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface EmailOptionsProps {
  site: string;
  options: Option[];
}

type MenuElement = {
  element: React.ReactNode;
  requiredOptions: String[];
};

const EmailOptions: React.FC<EmailOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  const menuElements: MenuElement[] = [
    {
      element: (
        <SideMenuAccordionExternalLink
          text={t('components:sideMenu.howToConfigureMyEmail')}
          href="https://www.hostnet.com.br/config.email/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailAccounts')}
          to="/emails"
        />
      ),
      requiredOptions: ['contas.de.email'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailStatistics')}
          to="/emails/estatisticas"
        />
      ),
      requiredOptions: ['estatisticas.de.email'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailInvalidDestination')}
          to="/emails/destino-invalido"
        />
      ),
      requiredOptions: ['email.destinatarios.rejeitados'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailDenounced')}
          to="/emails/denunciado"
        />
      ),
      requiredOptions: ['emails.denunciados'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailBlacklistRemove')}
          to="/emails/blacklist"
        />
      ),
      requiredOptions: ['remover.email.blacklist'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.redirect_plural')}
          to="/emails/redirecionamentos"
        />
      ),
      requiredOptions: ['redirecionamentos.de.email'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.redirect_plural')}
          to="/emails/redirecionamentos-dominio"
        />
      ),
      requiredOptions: ['redirecionamentos.de.email.sem.site'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailCryptography')}
          to="/emails/criptografia"
        />
      ),
      requiredOptions: ['criptografia.de.email'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailSecuritySettings')}
          to="/emails/configuracoes-seguranca"
        />
      ),
      requiredOptions: ['configuracoes.de.seguranca.de.email'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailMarketing')}
          to="/painel3?route=email.marketing"
          target="_blank"
          rel="noopener noreferrer"
        />
      ),
      requiredOptions: ['email.marketing'],
    },
    {
      element: (
        <SideMenuAccordionLink
          text={t('components:sideMenu.emailScript')}
          to="/emails/script"
        />
      ),
      requiredOptions: ['script.de.envio.de.email'],
    },
  ];

  const availableMenuItems = filterMenuOptions(options, menuElements);

  return (
    <SideMenuAccordion
      eventKey="5"
      text={t('components:sideMenu.email')}
      icon={<EnvelopeIcon size={15} />}
      elements={availableMenuItems}
    />
  );
};

export default EmailOptions;
