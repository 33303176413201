import React from 'react';
import { Card } from 'react-bootstrap';

import PanelCard from 'components/PanelCard';

import { Container } from './styles';

import CongelamentoButtons from './components/CongelamentoButtons';
import CreditCardExpirationButtons from './components/CreditCardExpirationButtons';
import DomainRenewalButtons from './components/DomainRenewalButtons';
import RegistryContactValidationButtons from './components/RegistryContactValidationButtons';

interface NoticeProps {
  id: number;
  title: string;
  message: string;
  code?: string;
  level?: string;
  redTitle?: boolean;
}

const Notice: React.FC<NoticeProps> = ({
  title,
  message,
  code = '',
  level = '',
  redTitle = false,
}) => {
  return (
    <Container className={`prioridade-${level} mb-4`}>
      <PanelCard>
        <Card.Header>
          <h5 className={redTitle ? 'text-danger' : ''}>{title}</h5>
        </Card.Header>
        <Card.Body>
          <div className="notice-content">
            <p>{message}</p>
          </div>
        </Card.Body>

        {code === 'expiracao.cartao.de.credito' && (
          <CreditCardExpirationButtons />
        )}

        {code === 'congelamento.em.atraso' && <CongelamentoButtons />}

        {code === 'congelamento.congelado' && <CongelamentoButtons />}

        {code === 'congelamento.inativo' && <CongelamentoButtons />}

        {code === 'expiracao.dominio' && <DomainRenewalButtons />}

        {code === 'validacao.contato.registro.pendente' && (
          <RegistryContactValidationButtons />
        )}
      </PanelCard>
    </Container>
  );
};

export default Notice;
