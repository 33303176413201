import React from 'react';

import { OptionButton, MenuBadge, Text } from './styles';

interface SideMenuButtonProps {
  text: string;
  onClick: () => void;
  icon: React.ReactNode;
  notifications?: number;
}

const SideMenuButton: React.FC<SideMenuButtonProps> = ({
  text,
  onClick,
  icon,
  notifications = 0,
  ...props
}) => {
  return (
    <OptionButton onClick={onClick} className="active" {...props}>
      {icon}
      <Text>{text}</Text>
      {notifications > 0 && (
        <MenuBadge variant="success">{notifications}</MenuBadge>
      )}
    </OptionButton>
  );
};

export default SideMenuButton;
