import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 10px 15px;
  background: #fafafa;
  border-top: 1px solid #dee2e6 !important;

  .btn {
    margin-right: 10px;
  }

  .btn:hover {
    color: white;
    border-color: transparent;
  }
`;
