import { applyMiddleware, createStore, Middleware, Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

import { AuthAction, AuthState } from './modules/auth/types';
import { SitesAction, SitesState } from './modules/sites/types';
import { SiteAction, SiteState } from './modules/site/types';
import { ClientAction, ClientState } from './modules/client/types';
import { OptionsAction, OptionsState } from './modules/options/types';

export interface StoreState {
  auth: AuthState;
  client: ClientState;
  sites: SitesState;
  site: SiteState & PersistPartial;
  options: OptionsState;
}

export type StoreAction =
  | AuthAction
  | ClientAction
  | SitesAction
  | SiteAction
  | OptionsAction;

export default (
  reducers: Reducer<StoreState, StoreAction>,
  middlewares: Middleware[],
) => {
  const enhancer = applyMiddleware(...middlewares);

  return createStore(reducers, composeWithDevTools(enhancer));
};
