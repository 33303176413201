import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Error from 'components/Error';
import PanelButton from 'components/PanelButton';

import useInternationalDomainTransfers from 'hooks/useInternationalDomainTransfers';

import api from 'services/api';
import swal from 'services/swal';
import hashIds from 'services/hashIds';
import history from 'services/history';

import HelpContent from './components/HelpContent';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type TransferRestartProps = RouteComponentProps<MatchParams>;

interface RestartTransferFormValues {
  authInfo: string;
}

const TransferRestart: React.FC<TransferRestartProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const {
    isLoading,
    internationalTransfers,
  } = useInternationalDomainTransfers();

  const [transfer, setTransfer] = useState<
    typeof internationalTransfers[number]
  >();

  useEffect(() => {
    const findTransfer = internationalTransfers.find(
      internationalTransfer => internationalTransfer.idDomain === idDomain,
    );

    if (!findTransfer) {
      return;
    }

    setTransfer(findTransfer);
  }, [idDomain, internationalTransfers]);

  async function restartTransfer(values: RestartTransferFormValues) {
    try {
      await api.put(`enom/v1/transferencia`, {
        idDominio: idDomain,
        authInfo: values.authInfo,
      });

      swal.fire({
        icon: 'success',
        title: '',
        html: t('pages:internationalDomainTransferRestart.success'),
      });

      history.push(`/registro/transferencia`);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!transfer) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          startOpen
          helpContent={<HelpContent />}
          title={t('pages:internationalDomainTransferRestart.title')}
          description={t(
            'pages:internationalDomainTransferRestart.description',
          )}
          helpLinks={[]}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            authInfo: transfer.authInfo,
          }}
          validationSchema={Yup.object().shape({
            authInfo: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={restartTransfer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={transfer.domain} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="authInfo">
                  <Form.Label column sm={2}>
                    Auth-info
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="authInfo"
                      value={props.values.authInfo}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.authInfo && !!props.errors.authInfo
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.authInfo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/transferencia"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(TransferRestart);
