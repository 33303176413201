import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Tab, Nav, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Loading from 'components/Loading';
import PanelCard from 'components/PanelCard';

import { StoreState } from 'store/createStore';

import useFtpInfo from 'hooks/useFtpInfo';
import useUltramail from 'hooks/useUltramail';
import useDnsServers from 'hooks/useDnsServers';

import { Container, NavItem, ItemLabel, TabLink } from './styles';

const SiteConfig: React.FC = () => {
  const { t } = useTranslation();

  const { info } = useSelector((state: StoreState) => state.site);

  const { ftpInfo } = useFtpInfo(info.idSite);
  const { dnsServers } = useDnsServers(info.idSite);
  const { ultramail, loading: loadingUltramail } = useUltramail(info.idSite);

  const emailServerInfo = ultramail.servidores.find(
    serversInfo => serversInfo.site === info.site,
  );

  if (loadingUltramail) {
    return <Loading />;
  }

  return (
    <Container className="mb-5">
      <div className="mb-5 d-none d-md-block">
        <PanelCard>
          <Card.Body>
            <Tab.Container id="left-tabs-example" defaultActiveKey="settings">
              <Nav className="flex-column flex-sm-row justify-content-around border-sm-bottom">
                <NavItem className="text-center mb-4 mb-sm-0">
                  <Nav.Link eventKey="settings" className="pb-3">
                    {t('pages:home.siteSettings')}
                  </Nav.Link>
                </NavItem>
                <NavItem className="text-center mb-4 mb-sm-0">
                  <Nav.Link eventKey="dns" className="pb-3">
                    {t('common:dnsServers')}
                  </Nav.Link>
                </NavItem>
                {ultramail.possuiSiteComUltramailAtivo && (
                  <NavItem className="text-center mb-4 mb-sm-0">
                    <Nav.Link eventKey="email" className="pb-3">
                      {t('common:email')}
                    </Nav.Link>
                  </NavItem>
                )}
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="settings">
                  <Row className="p-4 justify-content-around">
                    <Col md={3} className="mb-4 mb-md-0">
                      <ItemLabel>{t('common:site')}</ItemLabel>
                      <p className="font-weight-light">
                        https://www.{info.site}
                      </p>

                      <ItemLabel>{t('common:plan')}</ItemLabel>
                      <p className="font-weight-light">{info.plano}</p>

                      <TabLink to="/site/editar-plano">
                        {t('pages:home.changePlan')}
                      </TabLink>
                    </Col>

                    {info.tecnologia !== 'REDIRECT' && (
                      <Col md={4} className="mb-4 mb-md-0">
                        <ItemLabel>{t('pages:home.ftpAccess')}</ItemLabel>
                        <div className="font-weight-light mb-3">
                          <div>Host: {ftpInfo.host}</div>
                          <div>
                            {t('pages:home.provisionalHost')}: {ftpInfo.server}
                          </div>
                          <div>
                            {t('common:user')}: {ftpInfo.user}
                          </div>
                        </div>
                        <div>
                          <TabLink to="/site/senha-ftp">
                            {t('common:changePassword')}
                          </TabLink>
                          <br />
                          <TabLink to="/site/gerenciador-de-arquivos">
                            {t('pages:home.fileManager')}
                          </TabLink>
                        </div>
                      </Col>
                    )}

                    {info.tecnologia === 'PHP' && (
                      <Col md={2}>
                        <ItemLabel>PHP</ItemLabel>
                        <p>
                          {t('common:version')}: {ftpInfo.phpVersion}
                        </p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://ajuda.hostnet.com.br/php-detalhes/"
                        >
                          {t('pages:home.aboutPHPHostnet')}
                        </a>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://ajuda.hostnet.com.br/versao-do-php/"
                        >
                          {t('pages:home.aboutPHPVersion')}
                        </a>
                      </Col>
                    )}
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="dns">
                  <Row className="p-4 justify-content-around">
                    <Col md={4} className="mb-4 mb-md-0">
                      <ItemLabel>{t('common:dnsServers')}</ItemLabel>
                      {dnsServers.map(dns => (
                        <div className="font-weight-light" key={dns}>
                          {dns.slice(0, -1)}
                        </div>
                      ))}
                    </Col>
                    <Col md={4}>
                      <ItemLabel>{t('common:knowMore')}</ItemLabel>
                      <p>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.hostnet.com.br/info/dns/#O_que_e_DNS"
                        >
                          {t('pages:home.whatIsDns')}
                        </a>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.hostnet.com.br/info/dns/#Como_editar_o_DNS_do_meu_dominio"
                        >
                          {t('pages:home.howToEditMyDomainDns')}
                        </a>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.hostnet.com.br/info/alterar-dns-na-registro-br/"
                        >
                          {t('pages:home.howToChangeDnsInRegistroBr')}
                        </a>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://ajuda.hostnet.com.br/utilizando-a-cloudflare-por-conta-propria/"
                        >
                          {t('pages:home.usingCloudflareDNS')}
                        </a>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://ajuda.hostnet.com.br/endereco-provisorio/"
                        >
                          {t('pages:home.temporartyAddress')}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="email">
                  <Row className="p-4 justify-content-around">
                    <Col md={5} className="mb-4 mb-md-0">
                      <ItemLabel>
                        {t('pages:home.withDigitalCertificate')}
                      </ItemLabel>
                      <div className="font-weight-light">
                        Entrada (IMAP): {emailServerInfo?.mboxHost} / Porta: 993
                      </div>
                      <div className="font-weight-light">
                        Entrada (POP): {emailServerInfo?.mboxHost} / Porta: 995
                      </div>
                      <div className="font-weight-light">
                        Saída (SMTP): {emailServerInfo?.smtpHost} / Porta: 465
                      </div>
                      <ItemLabel className="mt-4">
                        {t('pages:home.withoutDigitalCertificate')}
                      </ItemLabel>
                      <div className="font-weight-light">
                        Entrada (IMAP): {emailServerInfo?.mboxHost} / Porta: 143
                      </div>
                      <div className="font-weight-light">
                        Entrada (POP): {emailServerInfo?.mboxHost} / Porta: 110
                      </div>
                      <div className="font-weight-light">
                        Saída (SMTP): {emailServerInfo?.smtpHost} / Porta: 587
                      </div>
                    </Col>
                    <Col md={3}>
                      <ItemLabel>{t('common:knowMore')}</ItemLabel>
                      <p>
                        <Link to="/emails">
                          {t('pages:home.emailAccounts')}
                        </Link>
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.hostnet.com.br/config.email/"
                        >
                          {t('pages:home.howToSetUpYourEmail')}
                        </a>
                        <br />

                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={emailServerInfo?.webmail}
                        >
                          Webmail
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card.Body>
        </PanelCard>
      </div>

      <div className="mb-5 d-block d-md-none">
        <PanelCard>
          <Card.Header>
            <h5>{t('pages:home.siteSettings')}</h5>
          </Card.Header>
          <Card.Body>
            <Row className="pt-3 pb-3 justify-content-around">
              <Col md={3} className="mb-4 mb-md-0">
                <ItemLabel>{t('common:label.site')}</ItemLabel>
                <p className="font-weight-light">https://{info.site}</p>

                <ItemLabel>{t('common:label.plan')}</ItemLabel>
                <p className="font-weight-light">{info.plano}</p>

                <TabLink to="/site/editar-plano">
                  {t('pages:home.changePlan')}
                </TabLink>
              </Col>

              {info.tecnologia !== 'REDIRECT' && (
                <Col md={4} className="mb-4 mb-md-0">
                  <ItemLabel>{t('pages:home.ftpAccess')}</ItemLabel>
                  <div className="font-weight-light mb-3">
                    <div>Host: {ftpInfo.host}</div>
                    <div>
                      {t('pages:home.provisionalHost')}: {ftpInfo.server}
                    </div>
                    <div>
                      {t('common:label.user')}: {ftpInfo.user}
                    </div>
                  </div>
                  <div>
                    <TabLink to="/home">
                      {t('common:label.changePassword')}
                    </TabLink>
                    <br />
                    <TabLink to="/site/gerenciador-de-arquivos">
                      {t('pages:home.fileManager')}
                    </TabLink>
                  </div>
                </Col>
              )}

              {info.tecnologia === 'PHP' && (
                <Col md={2}>
                  <ItemLabel>PHP</ItemLabel>
                  <p>
                    {t('common:label.version')}: {ftpInfo.phpVersion}
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ajuda.hostnet.com.br/php-detalhes/"
                  >
                    {t('common:knowMore')}
                  </a>
                </Col>
              )}
            </Row>
          </Card.Body>
        </PanelCard>
      </div>

      <div className="mb-4 d-block d-md-none">
        <PanelCard>
          <Card.Header>
            <h5>{t('common:dnsServers')}</h5>
          </Card.Header>
          <Card.Body>
            <Row className="pt-3 pb-3 justify-content-around">
              <Col md={4} className="mb-4 mb-md-0">
                <ItemLabel>{t('common:label.dnsServers')}</ItemLabel>
                {dnsServers.map(dns => (
                  <div className="font-weight-light" key={dns}>
                    {dns.slice(0, -1)}
                  </div>
                ))}
              </Col>
              <Col md={4}>
                <ItemLabel>{t('common:label.knowMore')}</ItemLabel>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.hostnet.com.br/info/dns/#O_que_e_DNS"
                  >
                    {t('pages:home.whatIsDns')}
                  </a>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.hostnet.com.br/info/dns/#Como_editar_o_DNS_do_meu_dominio"
                  >
                    {t('pages:home.howToEditMyDomainDns')}
                  </a>
                  <br />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.hostnet.com.br/info/alterar-dns-na-registro-br/"
                  >
                    {t('pages:home.howToChangeDnsInRegistroBr')}
                  </a>
                </p>
              </Col>
            </Row>
          </Card.Body>
        </PanelCard>
      </div>

      {ultramail.possuiSiteComUltramailAtivo && (
        <div className="mb-4 d-block d-md-none">
          <PanelCard>
            <Card.Header>
              <h5>{t('common:email')}</h5>
            </Card.Header>
            <Card.Body>
              <Row className="pt-3 pb-3 justify-content-around">
                <Col md={5} className="mb-4 mb-md-0">
                  <ItemLabel>
                    {t('pages:home.withDigitalCertificate')}
                  </ItemLabel>
                  <div className="font-weight-light">
                    Entrada (IMAP): {emailServerInfo?.mboxHost} / Porta: 993
                  </div>
                  <div className="font-weight-light">
                    Entrada (POP): {emailServerInfo?.mboxHost} / Porta: 995
                  </div>
                  <div className="font-weight-light">
                    Saída (SMTP): {emailServerInfo?.smtpHost} / Porta: 465
                  </div>
                  <ItemLabel className="mt-4">
                    {t('pages:home.withoutDigitalCertificate')}
                  </ItemLabel>
                  <div className="font-weight-light">
                    Entrada (IMAP): {emailServerInfo?.mboxHost} / Porta: 143
                  </div>
                  <div className="font-weight-light">
                    Entrada (POP): {emailServerInfo?.mboxHost} / Porta: 110
                  </div>
                  <div className="font-weight-light">
                    Saída (SMTP): {emailServerInfo?.smtpHost} / Porta: 587
                  </div>
                </Col>
                <Col md={3}>
                  <ItemLabel>{t('common:label.knowMore')}</ItemLabel>
                  <p>
                    <Link to="/emails">{t('pages:home.emailAccounts')}</Link>
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.hostnet.com.br/config.email/"
                    >
                      {t('pages:home.howToSetUpYourEmail')}
                    </a>
                    <br />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={emailServerInfo?.webmail}
                    >
                      Webmail
                    </a>
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </PanelCard>
        </div>
      )}
    </Container>
  );
};

export default SiteConfig;
