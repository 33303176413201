import styled from 'styled-components';
import { DropdownButton } from 'react-bootstrap';

export const StyledDropdownButton = styled(DropdownButton)`
  background-color: white;

  button.dropdown-toggle {
    color: #212529;

    font-size: 11px;
    line-height: 13px;

    border-color: #ced4da;
    padding: 8px 14px;
  }

  button.dropdown-toggle:hover {
    color: white;
    border-color: transparent;
    background-color: var(--color-azure-blue);
  }

  &.show button.dropdown-toggle {
    color: white;
    background-color: var(--color-blue);
  }

  .dropdown-menu {
    font-size: 14px;
    padding: 0 0 0.5rem 0;
  }

  .dropdown-menu .dropdown-item {
    padding: 8px 24px;
  }

  .dropdown-menu .dropdown-item:hover {
    color: white;

    transition: 0.25s;
    background-color: var(--color-blue);
  }
`;
