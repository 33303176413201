import { action } from 'typesafe-actions';

import { Site } from './types';

export function sitesSuccess({ sites }: { sites: Site[] }) {
  return action('@sites/SITES_SUCCESS', { sites });
}

export function selectFavoriteSiteRequest(idSite: number) {
  return action('@sites/SELECT_FAVORITE_SITE_REQUEST', { idSite });
}

export function setFavoriteSite(idFavoriteSite: number) {
  return action('@sites/SET_FAVORITE_SITE', { idFavoriteSite });
}

export function selectFavoriteSiteFailure() {
  return action('@sites/SELECT_FAVORITE_SITE_FAILURE');
}
