import React from 'react';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

import { Container } from './styles';

interface ErrorProps {
  message?: string;
  reloadButton?: boolean;
}

const Error: React.FC<ErrorProps> = ({
  message = '',
  reloadButton = false,
}) => {
  const { t } = useTranslation();

  const errorMessage = message || t('common:failedToLoadPageData');

  return (
    <Container>
      <div className="mb-4">{errorMessage}</div>

      {reloadButton && (
        <PanelButton onClick={() => window.location.reload()}>
          {t('common:reload')}
        </PanelButton>
      )}
    </Container>
  );
};

export default Error;
