import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Loading from 'components/Loading';
import TableWrapper from 'components/TableWrapper';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import TableHeader from 'components/TableHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import PageTitle from 'components/PageTitle';

import useUltramailDomains from 'hooks/useUltramailDomains';
import useUltramailDomainRedirects from 'hooks/useUltramailDomainsRedirects';

import filter from 'utils/filter';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import RedirectActions from './components/RedirectActions';
import UltramailDomainActions from './components/UltramailDomainsActions';

// import { Container } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { ultramailDomains, isLoading, refetch } = useUltramailDomains();
  const {
    ultramailDomainRedirects,
    isFetching: isFetchingRedirects,
    refetch: refetchRedirects,
  } = useUltramailDomainRedirects();

  const [domainsFilterText, setDomainsFilterText] = useState('');
  const [redirectsFilterText, setRedirectsFilterText] = useState('');

  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof ultramailDomains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [redirectsSelectedRowsInfo, setRedirectsSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof ultramailDomainRedirects;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const filteredUltramailDomains = filter(
    ultramailDomains,
    ['dominio'],
    domainsFilterText,
  );

  const filteredRedirects = filter(
    ultramailDomainRedirects,
    ['emailDestino', 'emailOrigem'],
    redirectsFilterText,
  );

  async function handleActivate(domain: string) {
    try {
      setUpdating(true);

      await api.post(`painel/v1/ultramail-dominio`, {
        dominio: domain,
      });

      await refetch();

      setToggleCleared(prev => !prev);

      toast.fire(t('pages:emailDomainRedirects.ultramailSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function handleDeactivate(domain: string) {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:warning'),
        text: t('pages:emailDomainRedirects.deactivateUltramailWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setUpdating(true);

      await api.delete(`painel/v1/ultramail-dominio`, {
        params: {
          dominio: domain,
        },
      });

      await refetch();

      setToggleCleared(prev => !prev);

      toast.fire(t('pages:emailDomainRedirects.ultramailFailed'));
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function deleteRedirect() {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:warning'),
        text: t('pages:emailDomainRedirects.deleteRedirectWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      const {
        dominio,
        emailDestino,
        emailOrigem,
      } = redirectsSelectedRowsInfo.selectedRows[0];

      await api.delete(`painel/v1/ultramail-dominio/redirecionamento`, {
        params: {
          dominio,
          emailDestino,
          emailOrigem,
        },
      });

      setToggleCleared(!toggleCleared);

      refetchRedirects();

      toast.fire(t('pages:emailDomainRedirects.deleteSuccess'));
    } catch (err) {
      swal.fire({
        icon: 'error',
        title: t('common:operationFailed'),
        text: `${t('pages:emailDomainRedirects.deleteFailed')}`,
      });
    } finally {
      setDeleting(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:emailDomainRedirects.availableDomains')}
          description={t('pages:emailDomainRedirects.ultramailWithoutHosting')}
          helpContent={<div />}
        />

        <TableSubHeader>
          <div />

          <div>
            <FilterInput onChange={value => setDomainsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            subHeaderWrap
            pagination
            selectableRows
            clearSelectedRows={toggleCleared}
            columns={[
              {
                name: t('common:domain'),
                selector: 'dominio',
                minWidth: '240px',
                sortable: true,
              },
              {
                name: 'Ultramail',
                selector: 'quota',
                width: '160px',
                sortable: true,
                cell: row =>
                  `${row.ativo ? t('common:active') : t('common:inactive')}`,
              },
            ]}
            data={filteredUltramailDomains}
            progressPending={isLoading}
            selectableRowDisabled={() => updating}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
            contextComponent={
              <UltramailDomainActions
                loading={updating}
                handleActivate={(domain: string) => handleActivate(domain)}
                handleDeactivate={(domain: string) => handleDeactivate(domain)}
                selectedRows={selectedRowsInfo.selectedRows}
                selectedCount={selectedRowsInfo.selectedCount}
              />
            }
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:emailDomainRedirects.title')}
          description={t('pages:emailDomainRedirects.description')}
          helpContent={<div />}
          helpLinks={[
            {
              url:
                'https://ajuda.hostnet.com.br/redirecionamento-de-email-pelo-painel-de-controle/',
              text: t('pages:emailDomainRedirects.linkRedirects'),
            },
          ]}
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/emails/redirecionamentos-dominio/adicionar"
            >
              Novo redirecionamento
            </TableButton>
          </div>

          <div>
            <FilterInput onChange={value => setRedirectsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={redirectsSelectedRowsInfo.selectedCount === 0}
            pagination
            selectableRows
            selectableRowDisabled={() => deleting}
            defaultSortField="emailOrigem"
            onSelectedRowsChange={rows => setRedirectsSelectedRowsInfo(rows)}
            clearSelectedRows={toggleCleared}
            data={filteredRedirects}
            columns={[
              {
                name: t('common:email'),
                selector: 'emailOrigem',
                sortable: true,
              },
              {
                name: t('pages:emailDomainRedirects.redirectTo'),
                selector: 'emailDestino',
                sortable: true,
              },
            ]}
            contextComponent={
              <RedirectActions
                loading={deleting}
                selectedCount={redirectsSelectedRowsInfo.selectedCount}
                deleteRedirect={() => deleteRedirect()}
              />
            }
            progressPending={isFetchingRedirects}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default List;
