import React, { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';

function format(inputValue: string) {
  const digits = !inputValue ? '' : inputValue.replace(/[^\d]/g, '');

  if (!digits || digits.length < 10) {
    return inputValue;
  }

  const cut = digits.length === 10 ? 6 : 7;
  const max = digits.length > 11 ? 11 : digits.length;

  return `(${digits.substring(0, 2)}) ${digits.substring(
    2,
    cut,
  )}-${digits.substring(cut, max)}`;
}

function mask(inputValue: string) {
  if (!inputValue || inputValue.length === 0) {
    return '(99) 9999-9999';
  }

  const formatted = format(inputValue);

  return formatted.length >= 6 && formatted[5] === '9'
    ? '(99) 99999-9999'
    : '(99) 9999-9999';
}

interface PhoneInputProps {
  name: string;
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: ChangeEvent) => void;
  isInvalid: boolean;
  value: string;
  disabled: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  name,
  handleChange,
  handleBlur,
  isInvalid,
  value,
  disabled,
}) => {
  return (
    <InputMask
      name={name}
      mask={mask(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      disabled={disabled}
      className={`form-control ${isInvalid && 'is-invalid'}`}
    />
  );
};

export default PhoneInput;
