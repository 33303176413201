import { useQuery } from 'react-query';

import api from 'services/api';

interface CountryStatesResponse {
  data: {
    idEstado: number;
    estado: string;
    sigla: string;
  }[];
}

type State = {
  id: number;
  state: string;
  uf: string;
};

const initialData: State[] = [];

async function loadCountryStates(country = 'Brasil'): Promise<State[]> {
  const countriesResponse = await api.get<CountryStatesResponse>(
    `regiao/v1/estado?pais=${country}`,
  );

  const formattedCountryStates = countriesResponse.data.data.map(
    ({ idEstado, estado, sigla }) => ({
      id: idEstado,
      state: estado,
      uf: sigla,
    }),
  );

  return formattedCountryStates;
}

export default function useCountryStates(country = 'Brasil') {
  const { data, ...rest } = useQuery<State[]>(
    ['countryState', country],
    () => loadCountryStates(country),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
    },
  );

  const countryStates = data || initialData;

  return { countryStates, ...rest };
}
