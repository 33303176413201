import React from 'react';

import { StyledAlert } from './styles';

interface AlertProps {
  variant: string;
}

const Alert: React.FC<AlertProps> = ({ variant, children }) => {
  return <StyledAlert variant={variant}>{children}</StyledAlert>;
};

export default Alert;
