import { useQuery } from 'react-query';

import api from 'services/api';

type InternationalDomainTransferItemResponse = {
  idDominio: number;
  dominio: string;
  destino: string;
  dataRequisicao: string;
  tipo: string;
  status: string;
  statusDescricao: string;
  authInfo: string;
  historico: {
    hostnetData: string;
    hostnetStatusDescricao: string;
  }[];
};

type InternationalDomainTransfersResponse = {
  data: InternationalDomainTransferItemResponse[];
};

type Event = {
  date: string;
  description: string;
};

type InternationalDomainTransfer = {
  idDomain: number;
  domain: string;
  destination: string;
  requestDate: string;
  type: string;
  status: string;
  statusDescription: string;
  authInfo: string;
  history: Event[];
};

const initialData: InternationalDomainTransfer[] = [];

function formatDomainTransfer(
  transfer: InternationalDomainTransferItemResponse,
): InternationalDomainTransfer {
  return {
    idDomain: transfer.idDominio,
    domain: transfer.dominio,
    destination: transfer.destino,
    requestDate: transfer.dataRequisicao,
    type: transfer.tipo,
    status: transfer.status,
    statusDescription: transfer.statusDescricao,
    authInfo: transfer.authInfo,
    history: transfer.historico.map(eventItem => ({
      date: eventItem.hostnetData,
      description: eventItem.hostnetStatusDescricao,
    })),
  };
}

async function loadInternationalDomainTransfers() {
  const { data } = await api.get<InternationalDomainTransfersResponse>(
    `enom/v1/transferencia`,
    {
      params: {
        tipo: ['TRANSFERENCIA'],
        status: ['ERRO', 'PENDENTE', 'PROCESSANDO'],
      },
    },
  );

  const transfers = data.data.map(formatDomainTransfer);

  return transfers;
}

export default function useInternationalDomainTransfers() {
  const { data, ...rest } = useQuery<InternationalDomainTransfer[]>(
    'internationalDomainTransfers',
    () => loadInternationalDomainTransfers(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const internationalTransfers = data || initialData;

  return { internationalTransfers, ...rest };
}
