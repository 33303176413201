import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addYears, differenceInYears } from 'date-fns';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Formik } from 'formik';

import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PageTitle from 'components/PageTitle';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import hashIds from 'services/hashIds';
import swal from 'services/swal';

import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type SettingsProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
    expiracao: string;
  }[];
}

interface ExtensionsResponse {
  data: {
    idExtensao: number;
    extensao: string;
    valor: string;
    periodoMinimo: number;
    periodoMaximo: number;
    promocao: 'TRUE' | 'FALSE';
    tipo: string;
  }[];
}

interface FormValues {
  domainName: string;
  period: number;
}

interface RenewRequestResponse {
  data: {
    url: string;
  };
}

const Renew: React.FC<SettingsProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [domainName, setDomainName] = useState('');

  const [expirationDate, setExpirationDate] = useState<Date>(new Date());
  const [extensionMinPeriod, setExtensionMinPeriod] = useState(0);
  const [extensionMaxPeriod, setExtensionMaxPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [renewValue, setRenewValue] = useState(0.0);

  useEffect(() => {
    async function loadDomain() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainResponse = await api.get<DomainResponse>(
          `registrobr/v1/dominio/${idDomain}`,
        );

        if (domainResponse.data.data.length < 1) {
          setError(true);
          return;
        }

        const domainItemResponse = domainResponse.data.data[0];

        const domainTld = domainItemResponse.dominio
          .split(/\./)
          .slice(-2)
          .join('.');

        const extensionResponse = await api.get<ExtensionsResponse>(
          `registrobr/v1/extensao`,
          {
            params: {
              'filter[extensao]': domainTld,
              'filter[tipo]': 'RENOVACAO',
            },
          },
        );

        if (extensionResponse.data.data.length < 1) {
          setError(true);
          return;
        }

        const extensionItemResponse = extensionResponse.data.data[0];

        setDomainName(domainItemResponse.dominio);
        setExtensionMaxPeriod(extensionItemResponse.periodoMaximo);
        setExtensionMinPeriod(extensionItemResponse.periodoMinimo);
        setExpirationDate(new Date(domainItemResponse.expiracao));
        setRenewValue(parseFloat(extensionItemResponse.valor));
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDomain();
  }, [idDomain]);

  useEffect(() => {
    const dateLimit = addYears(new Date(), 10);
    dateLimit.setHours(0, 0, 0, 0);

    const differenceYears = differenceInYears(
      dateLimit,
      new Date(expirationDate),
    );

    const renewalYearLimit =
      extensionMaxPeriod <= differenceYears
        ? extensionMaxPeriod
        : differenceYears;

    setMaxPeriod(renewalYearLimit);
  }, [expirationDate, extensionMaxPeriod]);

  function createRenewPeriodElements(min: number, max: number) {
    const elements = [];

    for (let i = min; i <= max; i += 1) {
      elements.push(
        <option key={`period-${i}`} value={i}>{`${i} ${t('common:year', {
          count: i,
        }).toLowerCase()}`}</option>,
      );
    }

    return elements;
  }

  async function handleSubmit(values: FormValues) {
    try {
      const response = await api.post<RenewRequestResponse>(
        'registrobr/v1/pedido-renovacao',
        {
          periodo: values.period,
          dominio: values.domainName,
          ...(contaComSite && { idSite }),
        },
      );

      window.location.href = response.data.data.url;
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          directHelpLink="https://ajuda.hostnet.com.br/renovacao-e-expiracao-de-dominios/"
          title={t('pages:brDomainRenew.title')}
          description={t('pages:brDomainRenew.description')}
        />
        <Formik
          enableReinitialize
          initialValues={{
            domainName,
            period: 1,
          }}
          onSubmit={handleSubmit}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:brDomainRenew.formSubtitle')}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={domainName} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainNew.registrationPeriod">
                  <Form.Label column sm={2} className="required">
                    {t('pages:brDomainNew.registrationPeriod')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="period"
                      value={props.values.period}
                      onChange={props.handleChange}
                      disabled={props.isSubmitting}
                    >
                      {createRenewPeriodElements(extensionMinPeriod, maxPeriod)}
                    </Form.Control>

                    <Form.Text className="text-muted">
                      {t('pages:brDomainRenew.periodTip')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:price')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      value={formatCurrency(
                        props.values.period * Number(renewValue),
                      )}
                    />
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Renew);
