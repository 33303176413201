import Swal from 'sweetalert2';

const toast = Swal.mixin({
  toast: true,
  icon: 'success',
  position: 'top-end',
  timer: 4000,
  timerProgressBar: false,
  showConfirmButton: false,
  showCloseButton: true,
  didOpen: activeToast => {
    activeToast.addEventListener('mouseenter', Swal.stopTimer);
    activeToast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export default toast;
