import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import api from 'services/api';
import hashIds from 'services/hashIds';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type DnsProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
  }[];
}

interface DnsResponse {
  data: string[];
}

interface DnsFormValues {
  nameServer1: string;
  nameServer2: string;
  nameServer3: string;
  nameServer4: string;
  nameServer5: string;
  nameServer6: string;
}

const Dns: React.FC<DnsProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dns, setDns] = useState<string[]>([]);
  const [domainName, setDomainName] = useState('');

  useEffect(() => {
    async function loadDns() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainResponse = await api.get<DomainResponse>(
          `rrpproxy/v1/dominio/${idDomain}`,
        );

        const dnsResponse = await api.get<DnsResponse>(
          `rrpproxy/v1/dominio/${idDomain}/dns`,
        );

        setDomainName(domainResponse.data.data[0].dominio);
        setDns(dnsResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDns();
  }, [idDomain]);

  async function updateDns(
    values: DnsFormValues,
    formikHelpers: FormikHelpers<DnsFormValues>,
  ) {
    try {
      const nameServers = Object.values(values).filter(
        nameServer => nameServer !== '',
      );

      const updateDnsResponse = await api.put<DnsResponse>(
        `rrpproxy/v1/dominio/${idDomain}/dns`,
        {
          nameServers,
        },
      );

      setDns(updateDnsResponse.data.data);

      toast.fire(t('pages:rioDomainDns.updateDnsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:rioDomainDns.updateDnsFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:rioDomainDns.title')}
          description={t('pages:rioDomainDns.description')}
          helpContent={
            <div>
              <ul>
                <li>{t('pages:rioDomainDns.helpContent.help1')}.</li>
                <li>{t('pages:rioDomainDns.helpContent.help2')}.</li>
              </ul>
            </div>
          }
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer1: dns[0],
            nameServer2: dns[1],
            nameServer3: dns[2],
            nameServer4: dns[3],
            nameServer5: dns[4],
            nameServer6: dns[5],
          }}
          validationSchema={Yup.object().shape({
            nameServer1: Yup.string()
              .min(4, t('pages:rioDomainDns.validations.minLength'))
              .required(t('pages:rioDomainDns.validations.required')),
            nameServer2: Yup.string()
              .min(4, 'pages:rioDomainDns.validations.minLength')
              .required(t('pages:rioDomainDns.validations.required')),
          })}
          onSubmit={updateDns}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={domainName} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns1">
                  <Form.Label column sm={2}>
                    Name server 1
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer1"
                      value={props.values.nameServer1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer1 &&
                        !!props.errors.nameServer1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer1}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns2">
                  <Form.Label column sm={2}>
                    Name server 2
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer2"
                      value={props.values.nameServer2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer2 &&
                        !!props.errors.nameServer2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns3">
                  <Form.Label column sm={2}>
                    Name server 3
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer3"
                      value={props.values.nameServer3}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer3 &&
                        !!props.errors.nameServer3
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer3}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns4">
                  <Form.Label column sm={2}>
                    Name server 4
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer4"
                      value={props.values.nameServer4}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer4 &&
                        !!props.errors.nameServer4
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer4}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns5">
                  <Form.Label column sm={2}>
                    Name server 5
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer5"
                      value={props.values.nameServer5}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer5 &&
                        !!props.errors.nameServer5
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer5}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns6">
                  <Form.Label column sm={2}>
                    Name server 6
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer6"
                      value={props.values.nameServer6}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer6 &&
                        !!props.errors.nameServer6
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer6}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Dns);
