import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCogs } from 'react-icons/fa';
import { Form, Card } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';

import { Container } from './styles';

const AppsInstalados: React.FC = () => {
  const { t } = useTranslation();

  const action = 'https://apps.hostnet.com.br/login';

  const { list: options } = useSelector((state: StoreState) => state.options);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dadosCliente, setDadosCliente] = useState('');

  const { idCliente } = useSelector((state: StoreState) => state.client.info);
  const { site, idSite } = useSelector((state: StoreState) => state.site.info);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    async function getDadosCliente() {
      try {
        setLoading(true);
        setError(false);
        const data = {
          site,
          idSite,
          idCliente,
        };
        const response = await api.post(`painel/v1/apps/cript`, data);
        setDadosCliente(response.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getDadosCliente();
  }, [idCliente, idSite, site, options]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          directHelpLink="https://ajuda.hostnet.com.br/instalador-de-aplicativos/"
          title={t('pages:appsInstalados.title')}
          description={t('pages:appsInstalados.description')}
        />
        <Form ref={formRef} method="POST" action={action} target="_BLANK">
          <Card.Body className="fieldset">
            <p>{t('pages:appsInstalados.appsInstaladosDescription.p1')}</p>
            <input type="hidden" name="StApp" value="instalados" />
            <input type="hidden" name="StOrigem" value="Painel" />
            <input name="DadosCliente" value={dadosCliente} type="hidden" />
          </Card.Body>
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton type="submit" className="mr-1" disabled={false}>
              {t('pages:appsInstalados.accessInstaller')}
            </PanelButton>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default AppsInstalados;
