import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Card, Col, Form, Row } from 'react-bootstrap';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import api from 'services/api';
import hashIds from 'services/hashIds';
import swal from 'services/swal';
import toast from 'services/toast';
import history from 'services/history';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type CreditCardValidateProps = RouteComponentProps<MatchParams>;

interface RequestValidateResponse {
  data: { idTicket: number; dataSolicitacao: string };
}

interface CreditCardItemResponse {
  idCartao: number;
  idCliente: number;
  idSite: number;
  numero: string;
  numerosFinais: string;
  validado: boolean;
}

interface CreditCardResponse {
  data: CreditCardItemResponse[];
}

interface CreditCard {
  idCartao: number;
  idCliente: number;
  idSite: number;
  numero: string;
  numerosFinais: string;
  validado: boolean;
}

const CreditCardValidate: React.FC<CreditCardValidateProps> = ({ match }) => {
  const [id] = hashIds.decode(match.params.id);
  // const id = 60028;

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const [creditCard, setCreditCard] = useState<CreditCard[]>([]);

  const client = useSelector((state: StoreState) => state.client.info);
  const site = useSelector((state: StoreState) => state.site.info);

  const formatCreditCard = useCallback(
    (creditCardResponse: CreditCardItemResponse[]) => {
      const formatedCreditCard = creditCardResponse.map(card => {
        return {
          idCartao: card.idCartao,
          idCliente: card.idCliente,
          idSite: card.idSite,
          numero: card.numero,
          numerosFinais: `**** **** **** ${card.numerosFinais}`,
          validado: card.validado,
        };
      });

      return formatedCreditCard;
    },
    [],
  );

  useEffect(() => {
    async function loadCreditCard() {
      try {
        setError(false);
        setLoading(true);

        const params = { idCartao: id, idCliente: client.idCliente };

        const creditCardResponse = await api.get<CreditCardResponse>(
          `clientes/v1/cartao`,
          { params },
        );

        const formatedCreditCard = formatCreditCard(
          creditCardResponse.data.data,
        );

        setCreditCard(formatedCreditCard);
      } catch (err) {
        setError(false);
      } finally {
        setLoading(false);
      }
    }

    loadCreditCard();
  }, [id, client.idCliente, formatCreditCard]);

  const requestValidate = useCallback(async () => {
    try {
      setRequesting(true);

      const requestValidateResponse = await api.post<RequestValidateResponse>(
        `clientes/v1/cartao/validacao`,
        {
          idCliente: client.idCliente,
          ...(client.contaComSite && { idSite: site.idSite }),
        },
      );

      if ('idTicket' in requestValidateResponse.data.data) {
        toast.fire(t('pages:creditCardValidate.requestSuccess'));

        const { idTicket } = requestValidateResponse.data.data;

        history.push(`/helpdesk/ticket/${idTicket}`);
      }
    } catch (err) {
      swal.fire(t('pages:creditCardValidate.requestFailed'));
    } finally {
      setRequesting(false);
    }
  }, [client.idCliente, client.contaComSite, site.idSite, t]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          startOpen
          title={t('pages:creditCardValidate.title')}
          description={t('pages:creditCardValidate.description')}
          helpContent={
            <Trans>
              <p>{t('pages:creditCardValidate.helpContent.p1')}</p>
              <p>{t('pages:creditCardValidate.helpContent.p2')}</p>
              <p>{t('pages:creditCardValidate.helpContent.p3')}</p>
              <p>{t('pages:creditCardValidate.helpContent.p4')}</p>
              <p>{t('pages:creditCardValidate.helpContent.p5')}</p>
            </Trans>
          }
          helpLinks={[
            {
              url:
                'https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Validacao_Cartao_de_Credito',
              text: 'Validação de cartões de crédito.',
            },
            {
              url:
                'https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Cartao_de_Credito',
              text: 'Saiba mais sobre cartões de crédito.',
            },
          ]}
        />

        {creditCard.length > 0 && (
          <Form>
            <Card.Body className="fieldset">
              <Form.Group as={Row} controlId="form-cartao">
                <Form.Label column sm={2}>
                  {t('common:label.number')}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={creditCard[0].numerosFinais}
                  />
                </Col>
              </Form.Group>
            </Card.Body>

            <div className="border-top pt-2 pb-2 pl-3">
              <PanelButton
                className="mr-1"
                disabled={requesting || loading}
                size="sm"
                onClick={() => requestValidate()}
              >
                {requesting
                  ? t('common:button.requesting')
                  : t('common:button.request')}
              </PanelButton>
              <PanelButton
                variant="secondary"
                forwardedAs={Link}
                to="/financeiro/cartoes-credito"
                disabled={requesting || loading}
              >
                {t('common:back')}
              </PanelButton>
            </div>
          </Form>
        )}
      </FormWrapper>
    </Container>
  );
};

export default withRouter(CreditCardValidate);
