import React from 'react';
import { FaShoppingCart } from 'react-icons/fa';

import PanelButton from 'components/PanelButton';

// import { Container } from './styles';

interface FinishPaymentButtonProps {
  sessionKey: string;
}

const FinishPaymentButton: React.FC<FinishPaymentButtonProps> = ({
  sessionKey,
}) => {
  return (
    <PanelButton
      onClick={() =>
        window.open(
          `https://assine2.hostnet.com.br/credito/produto.contratar?CHAVE=${sessionKey}`,
          '_self',
        )
      }
      size={24}
    >
      <FaShoppingCart color="#FFFFFF" size={24} className="pr-2" />
      <span style={{ fontSize: 14, verticalAlign: 'middle' }}>
        Finalizar Compra
      </span>
    </PanelButton>
  );
};

export default FinishPaymentButton;
