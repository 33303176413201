import styled from 'styled-components';
import { Accordion } from 'react-bootstrap';

export const Text = styled.div`
  flex: 1;
`;

export const List = styled.ul`
  padding: 10px 0;
  margin: 0;
  font-size: 14px;

  list-style: none;

  background-color: rgba(18, 42, 86, 0.5);
`;

export const ListItem = styled.li`
  display: block;
`;

export const MenuAccordion = styled(Accordion.Collapse)``;
