import styled from 'styled-components';
import { ListGroup, Card } from 'react-bootstrap';

export const Container = styled.div``;

export const Chat = styled.div`
  .received-chat .msg {
    background: rgba(44, 69, 147, 0.1);
    border-radius: 0 5px 5px 5px;
    box-shadow: 2px 3px 7px 0 rgb(43 43 43 / 15%);
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;
    max-width: 100%;
    min-width: 50%;
  }

  .received-chat .msg:before {
    content: '';
    position: absolute;
    left: -7px;
    top: -7px;
    transform: rotate(45deg);
    border: 7px solid transparent;
    border-bottom-color: rgba(44, 69, 147, 0.1);
  }

  .send-chat {
    text-align: right;
  }

  .send-chat .msg {
    background: rgba(48, 169, 110, 0.1);
    border-radius: 5px 0 5px 5px;
    box-shadow: -2px 3px 7px 0 rgb(43 43 43 / 15%);
    margin-bottom: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;
    max-width: 100%;
    min-width: 50%;
  }

  .send-chat .msg:before {
    content: '';
    position: absolute;
    right: -7px;
    top: -7px;
    transform: rotate(45deg);
    border: 7px solid transparent;
    border-right-color: rgba(48, 169, 110, 0.1);
  }

  .msg-header {
    font-weight: 600;
    text-align: left;
  }

  .msg-body {
    white-space: pre-line;
    text-align: left;
  }
`;

export const Item = styled(ListGroup.Item)`
  padding: 0.25rem 0.75rem;
  font-size: 11px;

  &.active {
    background-color: var(--color-blue);
    border-color: var(--color-blue);
  }
`;

export const Text = styled(Card.Text)`
  white-space: pre-line;
`;

export const AttachButton = styled.a`
  color: #212529;
  font-size: 11px;
  border-color: #ced4da;
  border-color: #6c757d;
  padding: 8px 14px;
  transition: all ease-in 0.3s;

  :hover {
    background-color: var(--color-azure-blue);
  }
`;
