import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const StyledTableIconButton = styled(Button)`
  display: inline-flex;

  color: #212529;
  border-color: #ced4da;
  background: #fff;

  border-radius: 2px;

  padding: 3px 14px;

  transition: all ease-in 0.3s;

  :hover {
    background-color: var(--color-azure-blue);
    border-color: transparent;
  }

  :disabled {
    color: inherit;
    background-color: inherit;
    border-color: #ced4da;
  }

  &[aria-disabled='true'] {
    color: inherit;
    background-color: inherit;
    border-color: #ced4da;
  }
`;
