import { useQuery } from 'react-query';

import api from 'services/api';

type NoticeItemResponse = {
  idAviso: number;
  titulo: string;
  codigo: string;
  mensagem: string;
  prioridade: string;
  tipo: string;
};

interface TipNoticesResponse {
  data: NoticeItemResponse[];
}

interface Notice {
  id: number;
  title: string;
  code: string;
  message: string;
  level: string;
  type: string;
}

const initialData: Notice[] = [];

function formatNotice(notice: NoticeItemResponse): Notice {
  return {
    id: Number(notice.idAviso),
    title: notice.titulo,
    message: notice.mensagem,
    code: notice.codigo,
    level: notice.prioridade,
    type: notice.tipo,
  };
}

async function loadTipNotices(): Promise<Notice[]> {
  const noticesResponse = await api.get<TipNoticesResponse>(
    `painel/v1/aviso-dica`,
  );

  const notices = noticesResponse.data.data.map(formatNotice);

  return notices;
}

export default function useTipNotices() {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Notice[]>(
    ['TipNotices'],
    () => loadTipNotices(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const tipNotices = data || initialData;

  return {
    tipNotices,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
