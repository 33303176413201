import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import PanelButton from 'components/PanelButton';

import { Container } from './styles';

const Unavailable: React.FC = () => {
  const { t } = useTranslation();

  function redirectHostnetId() {
    const currentLocation = window.location.origin;
    const returnUrl = encodeURIComponent(
      `/login/authorize?clientId=painel4&state=${currentLocation}`,
    );

    window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
  }

  return (
    <Container>
      <Card className="mt-5 card w-75 mx-auto">
        <Card.Body>
          <Card.Title>{t('pages:unavailable.panelUnavailable')}</Card.Title>
          <Card.Text>{t('pages:unavailable.description')}</Card.Text>
          <Card.Text>
            Caso esteja acessando com um usuário adicional, será necessário{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://assine.hostnet.com.br/"
            >
              fazer um cadastro completo.
            </a>{' '}
          </Card.Text>
          <Card.Text>
            Após criar seu cadastro, o usuário principal da conta deverá
            recadastrar o seu usuário como adicional através do painel de
            controle.
          </Card.Text>
          <Card.Text>
            Caso tenha alguma dúvida, entre em contato através de um de nossos{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.hostnet.com.br/atendimento-ao-cliente/"
            >
              canais de atendimento
            </a>{' '}
            para que possamos lhe ajudar.
          </Card.Text>
          <PanelButton variant="primary" onClick={() => redirectHostnetId()}>
            {t('pages:unavailable.accessWithOtherAccount')}
          </PanelButton>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Unavailable;
