import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 35px;
  padding-right: 35px;

  @media only screen and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
