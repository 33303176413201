import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import { parseISO, format } from 'date-fns';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface AvailableDatesResponse {
  data: string[];
}

type backupMethod = 'ADD' | 'OVERWRITE' | 'REPLACE';

interface RequestBackupResponse {
  data: {
    action: backupMethod;
    date: string;
    idTask: string;
    status: string;
  };
}

interface PendingBackupResponse {
  data: {
    action: string;
    date: string;
    idTask: string;
    status: string;
  };
}

interface PendingBackup {
  action: string;
  date: string;
  idTask: string;
  status: string;
}

const Backup: React.FC = () => {
  const { t } = useTranslation();

  const requestOnHelpdesk = false;

  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);

  const [date, setDate] = useState('');
  const [dates, setDates] = useState<string[]>([]);
  const [method, setMethod] = useState<backupMethod>('ADD');
  const [pendingBackup, setPendingBackup] = useState<PendingBackup>({
    action: '',
    date: '',
    idTask: '',
    status: '',
  });

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    async function loadBackupInfos() {
      try {
        setError(false);
        setLoading(true);

        const datesResponse = await api.get<AvailableDatesResponse>(
          `painel/v1/site/${idSite}/backup/data`,
        );

        const pendingBackupResponse = await api.get<PendingBackupResponse>(
          `painel/v1/site/${idSite}/backup`,
        );

        if (pendingBackupResponse.data.data.idTask) {
          setPendingBackup(pendingBackupResponse.data.data);
        }

        setDates(datesResponse.data.data);

        if (datesResponse.data.data.length > 0) {
          setDate(datesResponse.data.data[0]);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadBackupInfos();
  }, [idSite]);

  const renderMethodDescription = useCallback(() => {
    switch (method) {
      case 'ADD':
        return t('pages:siteBackup.addDescription');
      case 'OVERWRITE':
        return t('pages:siteBackup.overwriteDescription');
      case 'REPLACE':
        return t('pages:siteBackup.replaceDescription');
      default:
        return '';
    }
  }, [method, t]);

  const requestBackup = useCallback(async () => {
    try {
      setRequesting(true);

      const requestBackupResponse = await api.post<RequestBackupResponse>(
        `painel/v1/site/${idSite}/backup`,
        {
          data: date,
          acao: method.toLowerCase(),
        },
      );

      setPendingBackup(requestBackupResponse.data.data);

      toast.fire(t('pages:siteBackup.backupRequestSuccess'));
    } catch (err) {
      swal.fire(t('pages:siteBackup.backupRequestFailed'));
    } finally {
      setRequesting(false);
    }
  }, [date, idSite, method, t]);

  const translateMethod = useCallback(
    methodName => {
      switch (methodName) {
        case 'ADD':
          return t('pages:siteBackup.add');
        case 'OVERWRITE':
          return t('pages:siteBackup.overwrite');
        case 'REPLACE':
          return t('pages:siteBackup.replace');
        default:
          return '';
      }
    },
    [t],
  );

  const cancelBackupRequest = useCallback(async () => {
    try {
      setRequesting(true);

      await api.delete(
        `painel/v1/site/${idSite}/backup/${pendingBackup.idTask}`,
      );

      setPendingBackup({
        action: '',
        date: '',
        idTask: '',
        status: '',
      });

      toast.fire(t('pages:siteBackup.backupCancelSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:siteBackup.backupCancelFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setRequesting(false);
    }
  }, [idSite, pendingBackup.idTask, t]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (requestOnHelpdesk) {
    return (
      <Container>
        <PageTitle
          title={t('titles:sites.title')}
          description={t('titles:sites.description')}
          icon={<FaWindowRestore color="#ffffff" size={24} />}
        />
        <FormWrapper>
          <FormHeader
            startOpen
            title={t('pages:siteBackup.title')}
            description=""
            directHelpLink="https://ajuda.hostnet.com.br/backup-na-hostnet/"
          />
          <Card.Body>
            <div>
              <Trans>{t('pages:siteBackup.requestOnHelpdesk')}</Trans>
            </div>
            <div className="mt-4">
              <PanelButton forwardedAs={Link} to="/helpdesk/ticket/novo">
                {t('pages:siteBackup.openTicket')}
              </PanelButton>
            </div>
          </Card.Body>
        </FormWrapper>
      </Container>
    );
  }

  if (pendingBackup.idTask !== '') {
    return (
      <Container>
        <PageTitle
          title={t('titles:sites.title')}
          description={t('titles:sites.description')}
          icon={<FaWindowRestore color="#ffffff" size={24} />}
        />
        <FormWrapper>
          <FormHeader
            title={t('pages:siteBackup.backupInProgressTitle')}
            description=""
            directHelpLink="https://ajuda.hostnet.com.br/backup-na-hostnet/"
          />
          <Card.Body>
            <div>{t('pages:siteBackup.inProgress.p1')}</div>
            <div>{t('pages:siteBackup.inProgress.p2')}</div>
            <div>{t('pages:siteBackup.inProgress.p3')}</div>
            <br />

            <div className="form-group row">
              <span className="col-sm-2 col-form-label">
                {t('common:label.site')}
              </span>
              <div className="col-sm-10">
                <input
                  readOnly
                  type="text"
                  className="form-control-plaintext"
                  id="staticEmail"
                  value={site}
                />
              </div>
            </div>

            <div className="form-group row">
              <span className="col-sm-2 col-form-label">
                {t('pages:siteBackup.method')}
              </span>
              <div className="col-sm-10">
                <input
                  readOnly
                  type="text"
                  className="form-control-plaintext"
                  id="staticMethod"
                  value={translateMethod(pendingBackup.action)}
                />
              </div>
            </div>

            <div className="form-group row">
              <span className="col-sm-2 col-form-label">
                {t('common:label.date')}
              </span>
              <div className="col-sm-10">
                <input
                  readOnly
                  type="text"
                  className="form-control-plaintext"
                  id="staticDate"
                  value={format(parseISO(pendingBackup.date), 'dd/MM/yyyy')}
                />
              </div>
            </div>
          </Card.Body>

          <div className="pt-2 pb-3 pl-3">
            <PanelButton
              disabled={requesting || loading}
              onClick={() => cancelBackupRequest()}
            >
              {requesting ? t('common:canceling') : t('common:cancel')}
            </PanelButton>
          </div>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:siteBackup.title')}
          description={t('pages:siteBackup.description')}
          directHelpLink="https://ajuda.hostnet.com.br/backup-na-hostnet/"
        />

        <Form>
          <Card.Body className="fieldset">
            <Form.Group as={Row} controlId="form-site">
              <Form.Label column sm={2}>
                {t('common:label.site')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control plaintext readOnly defaultValue={site} />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="form-method">
              <Form.Label column sm={2}>
                {t('pages:siteBackup.method')}
              </Form.Label>
              <Col sm={10}>
                <Form.Check
                  inline
                  custom
                  id="backup-method-add"
                  type="radio"
                  label={t('pages:siteBackup.add')}
                  name="backup-method"
                  onChange={() => setMethod('ADD')}
                  checked={method === 'ADD'}
                />
                <Form.Check
                  inline
                  custom
                  id="backup-method-overwrite"
                  type="radio"
                  label={t('pages:siteBackup.overwrite')}
                  name="backup-method"
                  onChange={() => setMethod('OVERWRITE')}
                  checked={method === 'OVERWRITE'}
                />
                <Form.Check
                  inline
                  custom
                  id="backup-method-replace"
                  type="radio"
                  label={t('pages:siteBackup.replace')}
                  name="backup-method"
                  onChange={() => setMethod('REPLACE')}
                  checked={method === 'REPLACE'}
                />

                <Form.Text className="text-muted">
                  {renderMethodDescription()}
                </Form.Text>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="form-date">
              <Form.Label column sm={2}>
                {t('pages:siteBackup.availableDates')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  value={date}
                  onChange={event => setDate(event.target.value)}
                >
                  {dates.map(availableDate => (
                    <option key={String(availableDate)} value={availableDate}>
                      {format(parseISO(availableDate), 'dd/MM/yyyy')}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Card.Body>

          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton
              disabled={requesting || loading}
              size="sm"
              onClick={() => requestBackup()}
            >
              {requesting
                ? t('common:button.requesting')
                : t('common:button.request')}
            </PanelButton>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default Backup;
