import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Container, Arrow } from './styles';

interface TableActionsWrapperProps {
  loading: boolean;
}

const TableActionsWrapper: React.FC<TableActionsWrapperProps> = ({
  children,
  loading,
}) => {
  return (
    <Container>
      <div>
        <Arrow size={21} />
        {children}
      </div>
      <div className="mr-3">
        {loading && <Spinner animation="border" variant="primary" size="sm" />}
      </div>
    </Container>
  );
};

export default TableActionsWrapper;
