import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Card, Col, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';

import swal from 'services/swal';
import hashIds from 'services/hashIds';
import history from 'services/history';
import api from 'services/api';

import useRioDomains from 'hooks/useRioDomains';
import useRioDomainContacts from 'hooks/useRioDomainContacts';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type ChangeContactProps = RouteComponentProps<MatchParams>;

interface FormValues {
  domain: string;
  currentContact: string;
  idContact: number;
}

const ChangeContact: React.FC<ChangeContactProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const { domains, loading: loadingDomains } = useRioDomains();
  const { contacts, loading: loadingContacts } = useRioDomainContacts();

  const [domain, setDomain] = useState('');
  const [otherContacts, setOtherContacts] = useState<typeof contacts>([]);
  const [currentContact, setCurrentContact] = useState('');

  useEffect(() => {
    const findDomain = domains.find(rioDomain => rioDomain.id === idDomain);

    if (findDomain) {
      setDomain(findDomain.dominio);
      setCurrentContact(findDomain.contato);
    }
  }, [domains, idDomain]);

  useEffect(() => {
    const validContacts = contacts.filter(
      contact => contact.contact !== currentContact,
    );

    setOtherContacts(validContacts);
  }, [contacts, currentContact]);

  async function submitForm(values: FormValues) {
    try {
      await api.put(`rrpproxy/v1/dominio/${idDomain}/contato`, {
        idContato: values.idContact,
      });

      swal.fire({
        icon: 'success',
        html: t('pages:rioDomainChangeContact.success'),
      });

      history.push(`/registro/contatos`);
    } catch (err) {
      swal.fire({
        title: t('pages:rioDomainChangeContact.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (loadingDomains || loadingContacts) {
    return <Loading />;
  }

  if (otherContacts.length === 0) {
    return (
      <Container>
        <PageTitle
          title={t('titles:domainRegistry.title')}
          description={t('titles:domainRegistry.description')}
          icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
        />

        <FormWrapper>
          <FormHeader
            title={t('pages:rioDomainChangeContact.title')}
            description={t('pages:rioDomainChangeContact.description')}
            helpContent={
              <div>
                <ul>
                  <li>
                    {t('pages:rioDomainChangeContact.helpContent.help1')}.
                  </li>
                  <li>
                    {t('pages:rioDomainChangeContact.helpContent.help2')}.
                  </li>
                </ul>
              </div>
            }
          />
          <Card.Body className="fieldset">
            <div>{t('pages:rioDomainChangeContact.noOtherContacts')}</div>
          </Card.Body>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:rioDomainChangeContact.title')}
          description={t('pages:rioDomainChangeContact.description')}
          helpContent={
            <div>
              <ul>
                <li>{t('pages:rioDomainChangeContact.helpContent.help1')}.</li>
                <li>{t('pages:rioDomainChangeContact.helpContent.help2')}.</li>
              </ul>
            </div>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            domain,
            currentContact,
            idContact: otherContacts.length > 0 ? otherContacts[0].id : 0,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('common:domain')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="domain"
                      value={props.values.domain}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:rioDomainChangeContact.currentContact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="currentContact"
                      value={props.values.currentContact}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainChange.contact">
                  <Form.Label column sm={2}>
                    {t('pages:rioDomainChangeContact.newContact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="idContact"
                      value={props.values.idContact}
                      onChange={props.handleChange}
                      disabled={props.isSubmitting}
                    >
                      {otherContacts.map(contact => (
                        <option key={contact.id} value={contact.id}>
                          {`${contact.contact} - ${contact.name} - ${contact.email}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:sending')
                    : t('pages:rioDomainChangeContact.requestExchange')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/contatos"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(ChangeContact);
