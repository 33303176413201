import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

import hashIds from 'services/hashIds';

interface CreditCardActionsProps {
  selectedCount: number;
  selectedRows: {
    idCartao: number;
    idFormaPagamento: number;
    expirado: boolean;
    validado: boolean;
    ordem: number;
  }[];
  loading: boolean;
  selectCreditCard: () => void;
  deleteCreditCard: () => void;
}

const CreditCardActions: React.FC<CreditCardActionsProps> = ({
  selectedCount,
  loading,
  selectedRows,
  selectCreditCard,
  deleteCreditCard,
}) => {
  const { t } = useTranslation();
  const creditCardHashId = hashIds.encode(selectedRows[0].idCartao);

  const [remove, setRemove] = useState(false);

  useEffect(() => {
    for (let i = 0; i < selectedRows.length; i += 1) {
      if (
        selectedRows[i].idFormaPagamento === 14 &&
        selectedRows[i].ordem === 1
      ) {
        setRemove(false);
        break;
      } else {
        setRemove(true);
      }
    }
  }, [selectedRows]);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 &&
        selectedRows[0].validado !== true &&
        selectedRows[0].expirado !== true && (
          <TableButton
            forwardedAs={Link}
            to={`/financeiro/cartoes-credito/validacao/${creditCardHashId}`}
            size="sm"
            className="mx-1"
            disabled={loading}
          >
            {t('common:validate')}
          </TableButton>
        )}
      {selectedCount === 1 &&
        selectedRows[0].ordem !== 1 &&
        selectedRows[0].expirado !== true && (
          <TableButton
            className="mx-1"
            size="sm"
            disabled={loading}
            onClick={selectCreditCard}
          >
            {t('pages:creditCards.selectMain')}
          </TableButton>
        )}
      {remove && (
        <TableButton
          className="mx-1"
          variant="danger"
          size="sm"
          disabled={loading}
          onClick={deleteCreditCard}
        >
          {t('common:delete')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default CreditCardActions;
