import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import useSites from 'hooks/useSites';
import useBrDomainContacts from 'hooks/useBrDomainsContacts';
import useBrDomainExtensions from 'hooks/useBrDomainsExtensions';
import useBrDomainEntities from 'hooks/useBrDomainsEntities';

import Error from 'components/Error';
import PanelButton from 'components/PanelButton';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormSubtitle from 'components/FormSubtitle';
import FormHeader from 'components/FormHeader';
import Loading from 'components/Loading';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';
import toast from 'services/toast';

import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface AvailableDomainResponse {
  data: {
    descricao: string;
    disponivel: boolean;
    dominio: boolean;
  };
}

interface RegistrationRequestResponse {
  data: {
    url: string;
  };
}

interface FormValues {
  domain: string;
  confirmDomain: string;
  idExtension: number;
  idContact: number;
  idEntity: number;
  usePromotionalRegistry: boolean;
  extension: string;
  period: number;
  nameServer1: string;
  nameServer2: string;
  nameServer3: string;
  nameServer4: string;
  nameServer5: string;
  nameServer6: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { sites, loading: loadingSites } = useSites();

  const {
    contacts,
    loading: loadingContacts,
    isFetched: fetchedContacts,
  } = useBrDomainContacts();

  const {
    brExtensions,
    isLoading: loadingExtensions,
    isFetched: fetchedDomainExtensions,
  } = useBrDomainExtensions();

  type Extension = typeof brExtensions[number];

  const {
    entities,
    isLoading: loadingEntities,
    isFetched: fetchedEntities,
  } = useBrDomainEntities();

  const [domain, setDomain] = useState('');
  const [extension, setExtension] = useState<Extension>({
    extensao: '',
    idExtensao: 0,
    periodoMaximo: 10,
    periodoMinimo: 1,
    promocao: 'FALSE',
    tipo: 'REGISTRO',
    valor: '00.0',
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [available, setAvailable] = useState(true);
  const [promotionalRegistry, setPromotionalRegistry] = useState(false);
  const [idPromotionalSite, setIdPromotionalSite] = useState(0);

  const hasContacts = contacts.length > 0;
  const hasEntities = entities.length > 0;

  useEffect(() => {
    setLoading(
      loadingContacts || loadingExtensions || loadingSites || loadingEntities,
    );
  }, [loadingContacts, loadingExtensions, loadingSites, loadingEntities]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);

    const extensionParam = urlSearchParams.get('extensao');

    if (!fetchedDomainExtensions) {
      return;
    }

    const findExtension = brExtensions.find(
      brExtension => brExtension.extensao === extensionParam,
    );

    if (!findExtension) {
      setError(true);
      return;
    }

    setExtension(findExtension);
  }, [fetchedDomainExtensions, brExtensions, search]);

  useEffect(() => {
    const siteWithPromotionalRegistration = sites.find(
      site => site.site === domain && site.registroPromocionalDisponivel,
    );

    if (siteWithPromotionalRegistration) {
      setPromotionalRegistry(true);
      setIdPromotionalSite(siteWithPromotionalRegistration.idSite);
    }
  }, [domain, sites]);

  useEffect(() => {
    async function loadData() {
      try {
        setError(false);
        setLoading(true);

        const urlSearchParams = new URLSearchParams(search);

        const domainParam = urlSearchParams.get('dominio');
        const extensionParam = urlSearchParams.get('extensao');

        if (!domainParam || !extensionParam) {
          setError(true);
          return;
        }

        const domainName = `${domainParam}.${extensionParam}`;

        setDomain(domainName);

        const response = await api.get<AvailableDomainResponse>(
          'registrobr/v1/dominio-disponibilidade',
          {
            params: {
              dominio: domainName,
            },
          },
        );

        setAvailable(response.data.data.disponivel);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    loadData();
  }, [search]);

  function createRegistrationPeriodElements(
    minPeriod: number,
    maxPeriod: number,
  ) {
    const elements = [];

    for (let i = minPeriod; i <= maxPeriod; i += 1) {
      elements.push(
        <option key={`period-${i}`} value={i}>{`${i} ${t('common:year', {
          count: i,
        }).toLowerCase()}`}</option>,
      );
    }

    return elements;
  }

  async function submitForm(values: FormValues) {
    if (promotionalRegistry && values.usePromotionalRegistry) {
      await submitPromotionalRegistry(values);
    } else {
      await submitRegistrationRequest(values);
    }
  }

  async function submitRegistrationRequest(values: FormValues) {
    try {
      const nameServers = [
        values.nameServer1,
        values.nameServer2,
        values.nameServer3,
        values.nameServer4,
        values.nameServer5,
        values.nameServer6,
      ].filter(nameServer => nameServer !== '');

      const response = await api.post<RegistrationRequestResponse>(
        'registrobr/v1/pedido-registro',
        {
          idContato: values.idContact,
          idEntidade: values.idEntity,
          idExtensao: values.idExtension,
          dominio: values.domain,
          periodo: values.period,
          nameServers,
          urlVoltar: 'https://painel4.hostnet.com.br/',
          ...(contaComSite && { idSite }),
        },
      );

      window.location.href = response.data.data.url;
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  async function submitPromotionalRegistry(values: FormValues) {
    try {
      await api.post<RegistrationRequestResponse>(
        'registrobr/v1/registro-promocional',
        {
          idContato: values.idContact,
          idEntidade: values.idEntity,
          idSite: idPromotionalSite,
        },
      );

      toast.fire(t('pages:brDomainNew.success'));

      history.push(`/home`);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (fetchedContacts && !hasContacts) {
    return <Error message={t('pages:brDomainNew.noContacts')} />;
  }

  if (fetchedEntities && !hasEntities) {
    return <Error message={t('pages:brDomainNew.noEntities')} />;
  }

  if (!available) {
    return (
      <Container>
        <PageTitle
          title={t('titles:domainRegistry.title')}
          description={t('titles:domainRegistry.description')}
          icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
        />
        <FormWrapper>
          <Error message={t('pages:brDomainNew.domainUnavailable')} />
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          directHelpLink="https://ajuda.hostnet.com.br/registro-de-dominios-internacionais/"
          title={t('pages:brDomainNew.title')}
          description={t('pages:brDomainNew.description')}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            domain,
            confirmDomain: '',
            idExtension: extension.idExtensao,
            extension: extension.extensao,
            idContact: contacts.length > 0 ? contacts[0].id : 0,
            idEntity: entities.length > 0 ? entities[0].idEntity : 0,
            usePromotionalRegistry: promotionalRegistry,
            period: extension.periodoMinimo,
            nameServer1: 'nsa1.hostnet.com.br',
            nameServer2: 'nsa2.hostnet.com.br',
            nameServer3: 'nsa3.hostnet.com.br',
            nameServer4: 'nsa4.hostnet.com.br',
            nameServer5: 'nsa5.hostnet.com.br',
            nameServer6: 'nsa6.hostnet.com.br',
          }}
          validationSchema={Yup.object().shape({
            confirmDomain: Yup.string()
              .required(t('pages:brDomainNew.validation.domainConfirm'))
              .oneOf(
                [Yup.ref('domain'), null],
                t('pages:brDomainNew.validation.invalidDomainConfirm'),
              ),
            nameServer1: Yup.string().when('usePromotionalRegistry', {
              is: false,
              then: Yup.string()
                .min(4, t('pages:brDomainNew.validation.minLength'))
                .required(t('pages:brDomainNew.validation.required')),
            }),
            nameServer2: Yup.string().when('usePromotionalRegistry', {
              is: false,
              then: Yup.string()
                .min(4, t('pages:brDomainNew.validation.minLength'))
                .required(t('pages:brDomainNew.validation.required')),
            }),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('common:domain')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="domain"
                      value={props.values.domain}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainNew.confirmDomain">
                  <Form.Label column sm={2} className="required">
                    {t('pages:brDomainNew.confirmDomain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="confirmDomain"
                      value={props.values.confirmDomain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirmDomain &&
                        !!props.errors.confirmDomain
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirmDomain}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainNew.contact">
                  <Form.Label column sm={2} className="required">
                    {t('common:contact')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="idContact"
                      value={props.values.idContact}
                      onChange={props.handleChange}
                      disabled={props.isSubmitting}
                    >
                      {contacts.map(contact => (
                        <option key={contact.id} value={contact.id}>
                          {`${contact.contact} - ${contact.name} - ${contact.email}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="domainNew.entity">
                  <Form.Label column sm={2} className="required">
                    {t('common:entity')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="idEntity"
                      value={props.values.idEntity}
                      onChange={props.handleChange}
                      disabled={props.isSubmitting}
                    >
                      {entities.map(entity => (
                        <option key={entity.idEntity} value={entity.idEntity}>
                          {entity.cpfCnpj}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                {promotionalRegistry && (
                  <Form.Group
                    as={Row}
                    controlId="domainNew.usePromotionalRegistry"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:brDomainNew.freeRegistrationPromotion')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        id="natural-contact"
                        type="radio"
                        label={t('common:yes')}
                        disabled={props.isSubmitting}
                        onChange={() =>
                          props.setFieldValue('usePromotionalRegistry', true)
                        }
                        checked={props.values.usePromotionalRegistry}
                      />
                      <Form.Check
                        inline
                        custom
                        id="legal-contact"
                        type="radio"
                        label={t('common:no')}
                        disabled={props.isSubmitting}
                        onChange={() =>
                          props.setFieldValue('usePromotionalRegistry', false)
                        }
                        checked={!props.values.usePromotionalRegistry}
                      />
                      <Form.Text className="text-success">
                        {t('pages:brDomainNew.usePromotionalRegistry', {
                          dominio: props.values.domain,
                        })}
                      </Form.Text>
                    </Col>
                  </Form.Group>
                )}

                {!props.values.usePromotionalRegistry && (
                  <>
                    <Form.Group
                      as={Row}
                      controlId="domainNew.registrationPeriod"
                    >
                      <Form.Label column sm={2} className="required">
                        {t('pages:brDomainNew.registrationPeriod')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="period"
                          value={props.values.period}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        >
                          {createRegistrationPeriodElements(
                            extension.periodoMinimo,
                            extension.periodoMaximo,
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm={2}>
                        {t('common:price')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          plaintext
                          readOnly
                          value={formatCurrency(
                            props.values.period * Number(extension.valor),
                          )}
                        />
                      </Col>
                    </Form.Group>

                    <FormSubtitle subTitle={t('common:dnsServers')} />

                    <Form.Group as={Row} controlId="dns1">
                      <Form.Label column sm={2} className="required">
                        Name server 1
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer1"
                          value={props.values.nameServer1}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer1 &&
                            !!props.errors.nameServer1
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer1}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dns2">
                      <Form.Label column sm={2} className="required">
                        Name server 2
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer2"
                          value={props.values.nameServer2}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer2 &&
                            !!props.errors.nameServer2
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer2}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dns3">
                      <Form.Label column sm={2}>
                        Name server 3
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer3"
                          value={props.values.nameServer3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer3 &&
                            !!props.errors.nameServer3
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer3}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dns4">
                      <Form.Label column sm={2}>
                        Name server 4
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer4"
                          value={props.values.nameServer4}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer4 &&
                            !!props.errors.nameServer4
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer4}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dns5">
                      <Form.Label column sm={2}>
                        Name server 5
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer5"
                          value={props.values.nameServer5}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer5 &&
                            !!props.errors.nameServer5
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer5}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="dns6">
                      <Form.Label column sm={2}>
                        Name server 6
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="nameServer6"
                          value={props.values.nameServer6}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.nameServer6 &&
                            !!props.errors.nameServer6
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.nameServer6}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominio-registrar"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
