import addCredit from './addCredit.json';
import additionalServices from './additionalServices.json';
import additionalUsers from './additionalUsers.json';
import additionalUsersNew from './additionalUsersNew.json';
import additionalUsersEdit from './additionalUsersEdit.json';
import bankSlipHistory from './bankSlipHistory.json';
import brDomainBuy from './brDomainBuy.json';
import brDomainContactNew from './brDomainContactNew.json';
import brDomainContactEdit from './brDomainContactEdit.json';
import brDomainEntities from './brDomainEntities.json';
import brDomainEntitiesNew from './brDomainEntitiesNew.json';
import brDomainEntitiesEdit from './brDomainEntitiesEdit.json';
import brDomainNew from './brDomainNew.json';
import brDomainDns from './brDomainDns.json';
import brDomainRenew from './brDomainRenew.json';
import brDomainSettings from './brDomainSettings.json';
import brDomainTransfer from './brDomainTransfer.json';
import brDomainTransferConfirm from './brDomainTransferConfirm.json';
import cancellation from './cancellation.json';
import cloudSettings from './cloudSettings.json';
import creditCardNew from './creditCardNew.json';
import creditCards from './creditCards.json';
import creditCardValidate from './creditCardValidate.json';
import crontab from './crontab.json';
import crontabScheduling from './crontabScheduling.json';
import databasesMySql from './databasesMysql.json';
import databaseMySqlBackup from './databaseMySqlBackup.json';
import databasesMySqlCreate from './databasesMySqlCreate.json';
import databaseMySqlEdit from './databaseMysqlEdit.json';
import dealsAndPartneships from './dealsAndPartneships.json';
import domainContacts from './domainContacts.json';
import domains from './domains.json';
import domainRegistryNew from './domainRegistryNew.json';
import domainRenew from './domainRenew.json';
import domainTransfer from './domainTransfer.json';
import editFtpSubaccount from './editFtpSubaccount.json';
import emailCreate from './emailCreate.json';
import emailCryptography from './emailCryptography.json';
import emailDomainRedirects from './emailDomainRedirects.json';
import emailDomainRedirectsNew from './emailDomainRedirectsNew.json';
import emailEdit from './emailEdit.json';
import emails from './emails.json';
import emailScript from './emailScript.json';
import emailSecuritySettings from './emailSecuritySettings.json';
import emailServices from './emailServices.json';
import entities from './entities.json';
import entityCreate from './entityCreate.json';
import entityEdit from './entityEdit.json';
import extraSettings from './extraSettings.json';
import fileManager from './fileManager.json';
import financeStatements from './financeStatements.json';
import ftpPassword from './ftpPassword.json';
import ftpSubaccounts from './ftpSubaccounts.json';
import helpdesk from './helpdesk.json';
import home from './home.json';
import internationalDomainBuy from './internationalDomainBuy.json';
import internationalDomainContactEdit from './internationalDomainContactEdit.json';
import internationalDomainContactNew from './internationalDomainContactNew.json';
import internationalDomainDns from './internationalDomainDns.json';
import internationalDomainSettings from './internationalDomainSettings.json';
import internationalDomainNew from './internationalDomainNew.json';
import internationalDomainRenew from './internationalDomainRenew.json';
import internationalDomainTransfer from './internationalDomainTransfer.json';
import internationalDomainTransferEvents from './internationalDomainTransferEvents.json';
import internationalDomainTransferRestart from './internationalDomainTransferRestart.json';
import internationalOwnNameServer from './internationalOwnNameServer.json';
import invoices from './invoices.json';
import mautic from './mautic.json';
import mx from './mx.json';
import mySqlCloudSettings from './mySqlCloudSettings.json';
import mySqlCloudMemorySettings from './mySqlCloudMemorySettings.json';
import newFtpSubaccount from './newFtpSubaccount.json';
import newRedirectEmail from './newRedirectEmail.json';
import paymentMethod from './paymentMethod.json';
import phpSettings from './phpSettings.json';
import quota from './quota.json';
import redirectsEmail from './redirectsEmail.json';
import reissuePayment from './reissuePayment.json';
import reissuePaymentBarCode from './reissuePaymentBarCode.json';
import removeEmailBlacklist from './removeEmailBlacklist.json';
import resourceMonitoring from './resourceMonitoring.json';
import rioDomainChangeContact from './rioDomainChangeContact.json';
import rioDomainContactEdit from './rioDomainContactEdit.json';
import rioDomainContactNew from './rioDomainContactNew.json';
import rioDomainDns from './rioDomainDns.json';
import rioDomainNew from './rioDomainNew.json';
import rioDomainSettings from './rioDomainSettings.json';
import siteBackup from './siteBackup.json';
import siteCdn from './siteCdn.json';
import siteChangePlan from './siteChangePlan.json';
import siteNameChange from './siteNameChange.json';
import siteRedirects from './siteRedirects.json';
import siteRedirectsNew from './siteRedirectsNew.json';
import siteSettings from './siteSettings.json';
import siteSettingsEdit from './siteSettingsEdit.json';
import siteSettingsNew from './siteSettingsNew.json';
import subDomainEdit from './subDomainEdit.json';
import subDomainNew from './subDomainNew.json';
import suspensionPause from './suspensionPause.json';
import temporaryUrl from './temporaryUrl.json';
import ticket from './ticket.json';
import ticketNew from './ticketNew.json';
import unavailable from './unavailable.json';
import webfacil from './webfacil.json';
import webfacilConverted from './webfacilConverted.json';
import wordpress from './wordpress.json';
import appsInstalados from './appsInstalados.json';

export default {
  addCredit,
  additionalServices,
  additionalUsers,
  additionalUsersNew,
  additionalUsersEdit,
  bankSlipHistory,
  brDomainNew,
  brDomainBuy,
  brDomainContactNew,
  brDomainContactEdit,
  brDomainDns,
  brDomainEntities,
  brDomainEntitiesNew,
  brDomainEntitiesEdit,
  brDomainRenew,
  brDomainSettings,
  brDomainTransfer,
  brDomainTransferConfirm,
  cancellation,
  cloudSettings,
  creditCardNew,
  creditCards,
  creditCardValidate,
  crontab,
  crontabScheduling,
  databasesMySql,
  databaseMySqlBackup,
  databasesMySqlCreate,
  databaseMySqlEdit,
  dealsAndPartneships,
  domainContacts,
  domains,
  domainRegistryNew,
  domainRenew,
  domainTransfer,
  editFtpSubaccount,
  emailCreate,
  emailCryptography,
  emailDomainRedirects,
  emailDomainRedirectsNew,
  emailEdit,
  emails,
  emailScript,
  emailSecuritySettings,
  emailServices,
  entities,
  entityCreate,
  entityEdit,
  extraSettings,
  fileManager,
  financeStatements,
  ftpPassword,
  ftpSubaccounts,
  helpdesk,
  home,
  internationalDomainBuy,
  internationalDomainContactEdit,
  internationalDomainContactNew,
  internationalDomainDns,
  internationalDomainSettings,
  internationalDomainNew,
  internationalDomainRenew,
  internationalDomainTransfer,
  internationalDomainTransferEvents,
  internationalDomainTransferRestart,
  internationalOwnNameServer,
  invoices,
  mautic,
  mx,
  mySqlCloudSettings,
  mySqlCloudMemorySettings,
  newFtpSubaccount,
  newRedirectEmail,
  paymentMethod,
  phpSettings,
  quota,
  redirectsEmail,
  reissuePayment,
  reissuePaymentBarCode,
  removeEmailBlacklist,
  resourceMonitoring,
  rioDomainChangeContact,
  rioDomainContactEdit,
  rioDomainContactNew,
  rioDomainDns,
  rioDomainNew,
  rioDomainSettings,
  siteBackup,
  siteCdn,
  siteChangePlan,
  siteNameChange,
  siteRedirects,
  siteRedirectsNew,
  siteSettings,
  siteSettingsEdit,
  siteSettingsNew,
  subDomainEdit,
  subDomainNew,
  suspensionPause,
  temporaryUrl,
  ticket,
  ticketNew,
  unavailable,
  webfacil,
  webfacilConverted,
  wordpress,
  appsInstalados,
};
