import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Card, Form, Row, Col } from 'react-bootstrap';

import api from 'services/api';
import swal from 'services/swal';

import formatCurrency from 'utils/formatCurrency';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import PanelDropdown from './components/PanelDropdown';

import { Container } from './styles';

interface PlansResponse {
  data: {
    idProduto: number;
    idProdutoCategoria: number;
    categoria: string;
    produto: string;
    tipoProduto: string;
    ativo: string;
    valor: string;
  }[];
}

interface Plan {
  ativo: string;
  categoria: string;
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  tipoProduto: string;
  valor: string;
}

const ChangePlan: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site, plano, valor } = useSelector(
    (state: StoreState) => state.site.info,
  );

  const [planosDisponiveis, setPlanosDisponiveis] = useState<Plan[]>([]);
  const [grupos, setGrupos] = useState<string[]>([]);
  const [planoSelecionado, setPlanoSelecionado] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPlanoSelecionado(null);
  }, [site]);

  useEffect(() => {
    async function loadPlanosInfos() {
      const planosResponse = await api.get<PlansResponse>(
        `clientes/v1/produto?tipo=PLANO&ativos=1`,
      );

      const g: string[] = [];

      planosResponse.data.data.map(item => {
        item.valor = formatCurrency(parseFloat(item.valor));
        if (g.indexOf(item.categoria) === -1) {
          g.push(item.categoria);
        }
        return true;
      });

      setGrupos(g);

      const plansSortedByPrice = planosResponse.data.data.sort(
        (a, b) => Number(a.valor) - Number(b.valor),
      );

      setPlanosDisponiveis(plansSortedByPrice);
    }
    loadPlanosInfos();
  }, []);

  async function changePlan() {
    try {
      setLoading(true);

      await api.post(`/clientes/v1/site/${idSite}/plano/troca`, {
        idPlanoNovo: planoSelecionado && planoSelecionado.idProduto,
      });

      swal.fire({
        icon: 'success',
        title: t('pages:siteChangePlan.requestSuccess'),
        html: t('pages:siteChangePlan.requestSuccessInfo'),
      });
    } catch (err) {
      swal.fire({
        title: t('pages:siteChangePlan.requestFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setPlanoSelecionado(null);
      setLoading(false);
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:siteChangePlan.title')}
          description={t('pages:siteChangePlan.description')}
          directHelpLink="https://ajuda.hostnet.com.br/troca-de-plano-no-painel-de-controle/"
        />

        <Card.Body className="fieldset">
          <FormSubtitle subTitle={t('pages:siteChangePlan.currentPlan')} />
          <Form.Group as={Row} className="mt-4">
            <Form.Label column sm={2}>
              {t('common:site')}
            </Form.Label>
            <Col sm={10}>
              <Form.Control plaintext readOnly value={site} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mt-4">
            <Form.Label column sm={2}>
              {t('common:plan')}
            </Form.Label>
            <Col sm={10}>
              <Form.Control plaintext readOnly value={plano} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mt-4">
            <Form.Label column sm={2}>
              {t('common:value')}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                plaintext
                readOnly
                value={`${formatCurrency(parseFloat(valor))}`}
              />
            </Col>
          </Form.Group>
        </Card.Body>

        <Card.Body className="fieldset">
          <FormSubtitle subTitle={t('pages:siteChangePlan.choseNewPlan')} />
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              {t('pages:siteChangePlan.newPlan')}
            </Form.Label>
            <Col sm={10}>
              <PanelDropdown
                selectedValue={
                  planoSelecionado
                    ? planoSelecionado.produto
                    : `-- ${t('pages:siteChangePlan.selectPlan')} --`
                }
                planos={planosDisponiveis}
                grupos={grupos}
                planoAtual={plano}
                onChange={(item: Plan) => setPlanoSelecionado(item)}
              />
              {planoSelecionado && planoSelecionado.categoria === 'E-mail' && (
                <div>
                  <Form.Text className="text-muted">
                    {t('pages:siteChangePlan.planEmailDescription')}
                  </Form.Text>
                  <Form.Text>
                    <strong>{t('common:warning')}:</strong>{' '}
                    {t('pages:siteChangePlan.planWarning')}
                  </Form.Text>
                </div>
              )}
            </Col>
          </Form.Group>
          {planoSelecionado && (
            <Form.Group as={Row} className="mt-4">
              <Form.Label column sm={2}>
                {t('common:value')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  plaintext
                  readOnly
                  value={planoSelecionado ? `${planoSelecionado.valor}` : ''}
                />
              </Col>
            </Form.Group>
          )}
        </Card.Body>
        <div className="border-top pt-2 pb-2 pl-3">
          <PanelButton
            type="submit"
            onClick={changePlan}
            disabled={!planoSelecionado || loading}
          >
            {loading ? t('common:requesting') : t('common:request')}
          </PanelButton>
        </div>
      </FormWrapper>
    </Container>
  );
};

export default ChangePlan;
