import { useQuery } from 'react-query';

import api from 'services/api';

type DomainItemResponse = {
  idDominio: number;
  idContato: number;
  idEntidade: number;
  dominio: string;
  promocao: 'TRUE' | 'FALSE';
  expiracao: string;
  status: string;
  contatoEmail: string;
  periodoRenovacao: boolean;
  periodoRecuperacao: boolean;
  renovacaoDisponivel: boolean;
  contatoRelacionado: {
    contato: string;
  };
};

type DomainsResponse = {
  data: DomainItemResponse[];
};

type BrDomain = {
  idDomain: number;
  idContact: number;
  domain: string;
  promotional: boolean;
  expiration: string;
  status: string;
  contactEmail: string;
  renewalPeriod: boolean;
  recoveryPeriod: boolean;
  availableToRenew: boolean;
  relatedContact: string;
};

const initialData: BrDomain[] = [];

function formatDomain(domain: DomainItemResponse): BrDomain {
  return {
    idDomain: domain.idDominio,
    idContact: domain.idContato,
    domain: domain.dominio,
    promotional: domain.promocao === 'TRUE',
    expiration: domain.expiracao,
    status: domain.status,
    contactEmail: domain.contatoEmail,
    renewalPeriod: domain.periodoRenovacao,
    recoveryPeriod: domain.periodoRecuperacao,
    availableToRenew: domain.renovacaoDisponivel,
    relatedContact: domain.contatoRelacionado.contato,
  };
}

async function loadBrDomains() {
  const { data } = await api.get<DomainsResponse>(`registrobr/v1/dominio`);

  const domains = data.data.map(formatDomain);

  return domains;
}

export default function useBrDomains() {
  const { data, ...rest } = useQuery<BrDomain[]>(
    'brDomains',
    () => loadBrDomains(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      placeholderData: initialData,
      retry: false,
    },
  );

  const domains = data || initialData;

  return { domains, ...rest };
}
