import { produce } from 'immer';
import { AuthState, AuthAction } from './types';

const initialState: AuthState = {
  authenticated: false,
  accessToken: '',
  refreshToken: '',
  userId: 0,
  expiresIn: 0,
  accessType: '',
  loading: false,
  error: false,
};

export default function auth(
  state = initialState,
  action: AuthAction,
): AuthState {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/AUTHORIZATION_REQUEST':
        draft.error = false;
        draft.loading = true;
        break;

      case '@auth/AUTHORIZATION_SUCCESS':
        draft = {
          ...draft,
          ...action.payload,
          ...{ loading: false, authenticated: true },
        };
        break;

      case '@auth/AUTHORIZATION_FAILURE':
        draft.loading = false;
        draft.error = true;
        break;

      case '@auth/LOGOUT':
        draft = initialState;
        break;

      default:
        return draft;
    }

    return draft;
  });
}
