import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import api from 'services/api';
import hashIds from 'services/hashIds';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type SettingsProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
    contatoAdministrativo: string;
    contatoCobranca: string;
    contatoTecnico: string;
  }[];
}

interface DnssecResponse {
  data: {
    dnsSec: 'true' | 'false';
  };
}

interface DnsSecFormValues {
  dnssec: boolean;
}

interface ContactFormValues {
  admin: string;
  tech: string;
  billing: string;
}

const Settings: React.FC<SettingsProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dnssec, setDnssec] = useState(false);
  const [domainName, setDomainName] = useState('');

  const [adminContact, setAdminContact] = useState('');
  const [techContact, setTechContact] = useState('');
  const [billingContact, setBillingContact] = useState('');

  useEffect(() => {
    async function loadSettings() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainApiCall = api.get<DomainResponse>(
          `registrobr/v1/dominio/${idDomain}`,
        );

        const dnsSecApiCall = api.get<DnssecResponse>(
          `registrobr/v1/dominio/${idDomain}/dnssec`,
        );

        const [domainResponse, dnsSecResponse] = await Promise.all([
          domainApiCall,
          dnsSecApiCall,
        ]);

        if (domainResponse.data.data.length === 0) {
          setError(true);
          return;
        }

        const domain = domainResponse.data.data[0];

        setDomainName(domain.dominio);

        setAdminContact(domain.contatoAdministrativo);
        setTechContact(domain.contatoTecnico);
        setBillingContact(domain.contatoCobranca);

        setDnssec(dnsSecResponse.data.data.dnsSec === 'true');
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSettings();
  }, [idDomain]);

  async function submitDnsSecForm(values: DnsSecFormValues) {
    try {
      await api.put(`registrobr/v1/dominio/${idDomain}/dnssec`, {
        dnsSec: values.dnssec ? 1 : 0,
      });

      toast.fire(t('pages:brDomainSettings.settingsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:brDomainSettings.settingsFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  async function submitContactsForm(values: ContactFormValues) {
    try {
      if (adminContact !== values.admin) {
        await api.put(`registrobr/v1/dominio`, {
          idDominio: idDomain,
          tipoContato: 'admin',
          contato: values.admin,
          editar_contato: 1,
        });
      }

      if (techContact !== values.tech) {
        await api.put(`registrobr/v1/dominio`, {
          idDominio: idDomain,
          tipoContato: 'tech',
          contato: values.tech,
          editar_contato: 1,
        });
      }

      if (billingContact !== values.billing) {
        await api.put(`registrobr/v1/dominio`, {
          idDominio: idDomain,
          tipoContato: 'billing',
          contato: values.billing,
          editar_contato: 1,
        });
      }

      toast.fire(t('pages:brDomainSettings.settingsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:brDomainSettings.settingsFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:brDomainSettings.title')}
          description={t('pages:brDomainSettings.description')}
          startOpen
          helpContent={<div />}
        />
        <Formik
          enableReinitialize
          initialValues={{ dnssec }}
          onSubmit={submitDnsSecForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:brDomainSettings.domainSettings')}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={domainName} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dnssec-field">
                  <Form.Label column sm={2}>
                    DNSSEC
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      disabled={props.isSubmitting}
                      id="dnssec-enabled"
                      type="radio"
                      label={t('common:yes')}
                      name="dnssec-enabled"
                      onChange={() => props.setFieldValue('dnssec', true)}
                      checked={props.values.dnssec}
                    />
                    <Form.Check
                      inline
                      custom
                      disabled={props.isSubmitting}
                      id="dns-disabled"
                      type="radio"
                      label={t('common:no')}
                      name="dnssec-disabled"
                      onChange={() => props.setFieldValue('dnssec', false)}
                      checked={!props.values.dnssec}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2} />
                  <Col sm={10}>
                    <PanelButton type="submit" disabled={props.isSubmitting}>
                      {props.isSubmitting
                        ? t('common:sending')
                        : t('common:send')}
                    </PanelButton>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Form>
          )}
        </Formik>

        <Formik
          enableReinitialize
          initialValues={{
            admin: adminContact,
            tech: techContact,
            billing: billingContact,
          }}
          validationSchema={Yup.object().shape({
            admin: Yup.string().required(t('validations:requiredField')),
            tech: Yup.string().required(t('validations:requiredField')),
            billing: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={submitContactsForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:brDomainSettings.brContacts')}
                />
                <Form.Group as={Row} controlId="admin">
                  <Form.Label column sm={2}>
                    Admin
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="admin"
                      value={props.values.admin}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.admin && !!props.errors.admin}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.admin}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="tech">
                  <Form.Label column sm={2}>
                    Técnico
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="tech"
                      value={props.values.tech}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.tech && !!props.errors.tech}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.tech}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="billing">
                  <Form.Label column sm={2}>
                    Cobrança
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="billing"
                      value={props.values.billing}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.billing && !!props.errors.billing
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.billing}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2} />
                  <Col sm={10}>
                    <PanelButton
                      type="submit"
                      className="mr-1"
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      {props.isSubmitting
                        ? t('common:sending')
                        : t('common:send')}
                    </PanelButton>

                    <PanelButton
                      variant="secondary"
                      forwardedAs={Link}
                      to="/registro/dominios"
                      disabled={props.isSubmitting}
                    >
                      {t('common:back')}
                    </PanelButton>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Settings);
