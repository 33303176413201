import React from 'react';
import { Card } from 'react-bootstrap';

import { Container, ResourceCard, IconWrapper, Name } from './styles';

interface SiteResourceProps {
  name: string;
  value: string;
  icon: React.ReactNode;
  color?: string;
}

const SiteResource: React.FC<SiteResourceProps> = ({
  name,
  value,
  icon,
  color = 'blue',
}) => {
  return (
    <Container>
      <ResourceCard className={`${color}`}>
        <Card.Body>
          <div className="d-flex w-100 justify-content-between align-items-center">
            <div>
              <IconWrapper className="icon-wrapper">{icon}</IconWrapper>
            </div>
            <div>
              <Name className="m-b-5 text-white text-right">{value}</Name>
              <h6 className="m-b-0 text-white text-right">{name}</h6>
            </div>
          </div>
        </Card.Body>
      </ResourceCard>
    </Container>
  );
};

export default SiteResource;
