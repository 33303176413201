import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpContentStep2: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-3">
        <strong>{t('common:warning')}</strong>
      </div>
      <ul>
        <li>{t('pages:brDomainTransfer.step2HelpContent.p1')}</li>
        <li>{t('pages:brDomainTransfer.step2HelpContent.p2')}</li>
      </ul>
    </div>
  );
};

export default HelpContentStep2;
