import { useQuery } from 'react-query';

import api from 'services/api';

interface PostsItemResponse {
  id: number;
  title: {
    rendered: string;
  };
  date: string;
  link: string;
}

type PostsResponse = PostsItemResponse[];

interface Post {
  id: number;
  title: string;
  date: string;
  link: string;
}

function parseTitle(title: string) {
  const txt = document.createElement('textarea');
  txt.innerHTML = title;

  return txt.textContent || '';
}

function formatPost(postResponse: PostsItemResponse) {
  return {
    id: postResponse.id,
    title: parseTitle(postResponse.title.rendered),
    date: postResponse.date,
    link: postResponse.link,
  };
}

async function loadHostnetBlogPosts() {
  const postsResponse = await api.get<PostsResponse>(
    `https://www.hostnet.com.br/wp-json/wp/v2/posts`,
  );

  const posts = postsResponse.data.map(formatPost);

  return posts;
}

export default function useHostnetBlogPosts() {
  const initialData: Post[] = [
    {
      id: 0,
      title: 'Acesse nosso blog e conheça nossa novidades!',
      date: '',
      link: 'https://www.hostnet.com.br/blog',
    },
  ];

  const { data, ...rest } = useQuery<Post[]>(
    'hostnetBlogPosts',
    () => loadHostnetBlogPosts(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const posts = data || initialData;

  return {
    posts,
    ...rest,
  };
}
