import { useQuery } from 'react-query';

import api from 'services/api';

type ClientItemResponse = {
  idCliente: number;
  email1: string;
  email2: string;
  nome: string;
  cpfCnpj: string;
  responsavel: string;
  endereco: string;
  numero: string;
  estado: string;
  complemento: string | null;
  cidade: string;
  cep: string;
  telefone: string;
  idFormaPagamento: number;
  formaPagamento: string;
  idEntidadeFinanceira: number;
  tipoCobranca: 'MULTIPLA' | 'UNICA';
  painelBloqueado: boolean;
};

type ClientResponse = {
  data: ClientItemResponse[];
};

type Client = {
  idCliente: number;
  email1: string;
  email2: string;
  nome: string;
  cpfCnpj: string;
  responsavel: string;
  endereco: string;
  numero: string;
  estado: string;
  complemento: string;
  cidade: string;
  cep: string;
  telefone: string;
  idFormaPagamento: number;
  formaPagamento: string;
  idEntidadeFinanceira: number;
  tipoCobranca: 'MULTIPLA' | 'UNICA';
  painelBloqueado: boolean;
};

const initialData: Client = {
  idCliente: 0,
  email1: '',
  email2: '',
  nome: '',
  cpfCnpj: '',
  responsavel: '',
  endereco: '',
  numero: '',
  estado: '',
  complemento: '',
  cidade: '',
  cep: '',
  telefone: '',
  idFormaPagamento: 0,
  formaPagamento: '',
  idEntidadeFinanceira: 0,
  tipoCobranca: 'UNICA',
  painelBloqueado: false,
};

function formatClient(client: ClientItemResponse): Client {
  const {
    idCliente,
    idFormaPagamento,
    idEntidadeFinanceira,
    complemento,
    ...clientRest
  } = client;

  return {
    idCliente: Number(idCliente),
    idFormaPagamento: Number(idFormaPagamento),
    idEntidadeFinanceira: Number(idEntidadeFinanceira),
    complemento: complemento === null ? '' : complemento,
    ...clientRest,
  };
}

async function loadClient(): Promise<Client> {
  const clientResponse = await api.get<ClientResponse>(`clientes/v1/cliente`);

  const client = clientResponse.data.data.map(formatClient);

  return client[0];
}

export default function useClient() {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Client>(
    'client',
    () => loadClient(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const client = data || initialData;

  return {
    client,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
