import { useQuery } from 'react-query';

import api from 'services/api';

type EntityItemResponse = {
  idEntidade: number;
  idCliente: number;
  idContato: number;
  idProvedor: number;
  nome: string;
  provedor: string;
  contato: string;
  responsavel: string;
  cep: string;
  estado: string;
  endereco: string;
  cidade: string;
  complemento: string;
  cpfCnpj: string;
  numero: string;
  pais: string;
  status: string;
  telefone: string;
  ramal: string;
  dataAtivacao: string;
};

type EntitiesResponse = {
  data: EntityItemResponse[];
};

type BrEntity = {
  idEntity: number;
  idCustomer: number;
  idContact: number;
  idProvider: number;
  name: string;
  provider: string;
  contact: string;
  responsible: string;
  zipCode: string;
  state: string;
  address: string;
  city: string;
  complement: string;
  cpfCnpj: string;
  number: string;
  country: string;
  status: string;
  phone: string;
  extensionNumber: string;
  type: 'FISICA' | 'JURIDICA';
};

function formatEntity(entityResponse: EntityItemResponse): BrEntity {
  return {
    idEntity: entityResponse.idEntidade,
    idCustomer: entityResponse.idCliente,
    idContact: entityResponse.idContato,
    idProvider: entityResponse.idProvedor,
    name: entityResponse.nome,
    provider: entityResponse.provedor,
    contact: entityResponse.contato,
    responsible: entityResponse.responsavel,
    zipCode: entityResponse.cep,
    state: entityResponse.estado,
    address: entityResponse.endereco,
    city: entityResponse.cidade,
    complement: entityResponse.complemento,
    cpfCnpj: entityResponse.cpfCnpj,
    number: entityResponse.numero,
    country: entityResponse.pais,
    status: entityResponse.status,
    phone: entityResponse.telefone.split('.')[1],
    extensionNumber: entityResponse.ramal,
    type: entityResponse.cpfCnpj.length > 11 ? 'JURIDICA' : 'FISICA',
  };
}

async function loadBrDomainEntities() {
  const { data } = await api.get<EntitiesResponse>(`registrobr/v1/entidade`);

  const entities = data.data.map(formatEntity);

  return entities;
}

export default function useBrDomainEntities() {
  const initialData: BrEntity[] = [];

  const { data, ...rest } = useQuery<BrEntity[]>(
    'brDomainsEntities',
    () => loadBrDomainEntities(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const entities = data || initialData;

  return {
    entities,
    ...rest,
  };
}
