import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

interface SiteSettingsActionsProps {
  selectedCount: number;
  selectedRows: {
    domain: string;
    cache: boolean;
    tipo: string;
  }[];
  deleteVHost: () => void;
  clearCache: () => void;
  loadDns: (site: string) => void;
  loading: boolean;
}

const SiteSettingsActions: React.FC<SiteSettingsActionsProps> = ({
  selectedRows,
  selectedCount,
  loading,
  loadDns,
  clearCache,
  deleteVHost,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            className="mx-1"
            disabled={loading}
            forwardedAs={Link}
            to={`/cloud/configuracoes-sites/editar/${selectedRows[0].domain}`}
          >
            {t('common:edit')}
          </TableButton>
          <TableButton
            className="mx-1"
            disabled={loading}
            onClick={() => loadDns(selectedRows[0].domain)}
          >
            {t('pages:siteSettings.seeDns')}
          </TableButton>
        </>
      )}
      {selectedCount === 1 && selectedRows[0].cache && (
        <TableButton
          className="mx-1"
          disabled={loading}
          onClick={() => clearCache()}
        >
          {t('pages:siteSettings.cacheClear')}
        </TableButton>
      )}

      {(selectedCount > 1 || selectedRows[0].tipo === 'Extra') && (
        <TableButton
          className="mx-1"
          variant="danger"
          disabled={loading}
          onClick={() => deleteVHost()}
        >
          {t('common:delete')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default SiteSettingsActions;
