import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface CrontabActionsProps {
  loading: boolean;
  selectedCount: number;
  selectedRows: {
    idCronJob: number;
    active: boolean;
  }[];
  deleteCronJob: () => void;
  activate: () => void;
  deactivate: () => void;
}

const CrontabActions: React.FC<CrontabActionsProps> = ({
  loading,
  selectedCount,
  selectedRows,
  activate,
  deactivate,
  deleteCronJob,
}) => {
  const { t } = useTranslation();

  const cronJob = selectedRows[0];

  const cronJobHashId = hashIds.encode(cronJob.idCronJob);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && !cronJob.active && (
        <>
          <TableButton
            disabled={loading}
            className="mx-1"
            forwardedAs={Link}
            to={`/cloud/crontab/${cronJobHashId}`}
          >
            {t('common:edit')}
          </TableButton>
          <TableButton className="mx-1" disabled={loading} onClick={activate}>
            {t('common:activate')}
          </TableButton>
          <TableButton
            className="mx-1"
            variant="danger"
            disabled={loading}
            onClick={deleteCronJob}
          >
            {t('common:delete')}
          </TableButton>
        </>
      )}

      {selectedCount === 1 && cronJob.active && (
        <TableButton className="mx-1" disabled={loading} onClick={deactivate}>
          {t('common:deactivate')}
        </TableButton>
      )}

      {selectedCount > 1 && (
        <>
          <TableButton className="mx-1" disabled={loading} onClick={deactivate}>
            {t('common:deactivate')}
          </TableButton>
          <TableButton
            className="mx-1"
            variant="danger"
            disabled={loading}
            onClick={deleteCronJob}
          >
            {t('common:delete')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default CrontabActions;
