import React from 'react';
import { useLocation } from 'react-router-dom';

import { OptionLink, MenuBadge, Text } from './styles';

interface SideMenuLinkProps {
  text: string;
  to: string;
  icon: React.ReactNode;
  notifications?: number;
  target?: string;
  rel?: string;
}

const SideMenuLink: React.FC<SideMenuLinkProps> = ({
  text,
  to,
  icon,
  notifications = 0,
  ...props
}) => {
  const location = useLocation();

  return (
    <OptionLink
      to={to}
      className={to === location.pathname ? 'active' : ''}
      {...props}
    >
      {icon}
      <Text>{text}</Text>
      {notifications > 0 && (
        <MenuBadge variant="success">{notifications}</MenuBadge>
      )}
    </OptionLink>
  );
};

export default SideMenuLink;
