import React from 'react';
import { Card } from 'react-bootstrap';

import PanelCard from 'components/PanelCard';

import { Container } from './styles';

const BloquedAccess: React.FC = () => {
  return (
    <Container>
      <PanelCard>
        <Card.Header>
          <h5>Painel bloqueado para acesso</h5>
        </Card.Header>
        <Card.Body>
          <div className="notice-content">
            <Card.Text>
              Verifique se existe alguma notificação em seu Helpdesk ou entre em
              contato com nosso SAC.
            </Card.Text>
          </div>
        </Card.Body>
      </PanelCard>
    </Container>
  );
};

export default BloquedAccess;
