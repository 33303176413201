import { format, parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetEmailPunishmentResponse = {
  data: {
    total: number;
    totalEmails: {
      [key: string]: number;
    };
    lista: {
      email: string;
      destinatario: string;
      data: string;
      total: number;
    }[];
  };
};

type RatchetEmailPunishment = {
  total: number;
  list: {
    email: string;
    recipient: string;
    date: string;
    total: number;
  }[];
};

const initialData: RatchetEmailPunishment = {
  total: 0,
  list: [],
};

function formatResponse(
  ratchetEmailResponse: RatchetEmailPunishmentResponse,
): RatchetEmailPunishment {
  const list = ratchetEmailResponse.data.lista.map(listItem => ({
    email: listItem.email,
    recipient: listItem.destinatario,
    date: format(parseISO(listItem.data), 'dd/MM/yyyy'),
    total: listItem.total,
  }));

  return {
    total: ratchetEmailResponse.data.total,
    list,
  };
}

async function loadRatchetEmailPunishment(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetEmailPunishment> {
  const emailsResponse = await api.get<RatchetEmailPunishmentResponse>(
    `painel/v1/ratchet/email/punicao`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const ratchetEmails = formatResponse(emailsResponse.data);

  return ratchetEmails;
}

export default function useRatchetEmailPunishment(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetEmailPunishment>(
    ['ratchetEmailPunishment', site, initialDate, finalDate],
    () => loadRatchetEmailPunishment(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetEmailPunishment = data || initialData;

  return {
    ratchetEmailPunishment,
    ...rest,
  };
}
