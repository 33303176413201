import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useExtraSites from 'hooks/useExtraSites';

import { Container } from './styles';

interface CdnItemResponse {
  id: number;
  idSite: number;
  idVhost: number;
  modoDev: boolean;
  pausa: boolean;
  site: string;
  status: string;
  fornecedor: string;
  dataHoraPausa: string;
}

interface CdnResponse {
  data: CdnItemResponse[];
}

interface Cdn {
  idSite: number;
  modoDev: boolean;
  pausa: boolean;
}

interface CdnFormValues {
  idSite: number;
  paused: boolean;
  devMode: boolean;
}

const Cdn: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [cdn, setCdn] = useState<Cdn>({
    idSite: 0,
    modoDev: false,
    pausa: false,
  });

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { extraSites } = useExtraSites(idSite);

  useEffect(() => {
    async function loadCdn() {
      try {
        setError(false);
        setLoading(true);

        const cdnResponse = await api.get<CdnResponse>(
          `painel/v1/site/${idSite}/cdn`,
        );

        if (cdnResponse.data.data.length > 0) {
          const { modoDev, pausa } = cdnResponse.data.data[0];
          setCdn({ idSite, modoDev, pausa });
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadCdn();
  }, [idSite]);

  const updateCache = useCallback(
    async (idSelectedSite: number) => {
      try {
        setUpdating(true);

        const extraSiteSelected = extraSites.find(extraSite => {
          return extraSite.id === Number(idSelectedSite);
        });

        await api.put(`painel/v1/site/${idSite}/cdn`, {
          ...(extraSiteSelected ? { idSiteExtra: idSelectedSite } : {}),
        });

        toast.fire(t('pages:siteCdn.cacheUpdateSuccess'));
      } catch (err) {
        swal.fire({
          title: t(`pages:siteCdn.cacheUpdateFail`),
          html: err.response.data.error_description,
        });
      } finally {
        setUpdating(false);
      }
    },
    [extraSites, idSite, t],
  );

  const updateCdnConfigs = useCallback(
    async (values: CdnFormValues) => {
      try {
        const { paused, devMode, idSite: idSelectedSite } = values;

        const extraSiteSelected = extraSites.find(extraSite => {
          return extraSite.id === Number(idSelectedSite);
        });

        if (paused !== cdn.pausa) {
          await api.put(`painel/v1/site/${idSite}/cdn`, {
            pausa: paused,
            ...(extraSiteSelected ? { idSiteExtra: idSelectedSite } : {}),
          });
        }

        if (devMode !== cdn.modoDev) {
          await api.put(`painel/v1/site/${idSite}/cdn`, {
            dev: devMode,
            ...(extraSiteSelected ? { idSiteExtra: idSelectedSite } : {}),
          });
        }

        setCdn({
          ...cdn,
          ...{ pausa: paused, modoDev: devMode },
        });

        toast.fire(t('pages:siteCdn.updateConfigsSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:siteCdn.updateConfigsFailed'),
          html: err.response.data.error_description,
        });
      }
    },
    [cdn, extraSites, idSite, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:siteCdn.title')}
          description={t('pages:siteCdn.description')}
          directHelpLink="https://ajuda.hostnet.com.br/cdn/"
        />

        <Formik
          enableReinitialize
          initialValues={{
            idSite,
            paused: cdn.pausa,
            devMode: cdn.modoDev,
          }}
          onSubmit={updateCdnConfigs}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    {t('common:site')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="idSite"
                      onChange={props.handleChange}
                    >
                      <option key={idSite} value={idSite}>
                        {site}
                      </option>
                      {extraSites.map(extraSite => (
                        <option key={extraSite.id} value={extraSite.id}>
                          {extraSite.site.replace('www.', '')}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    {t('common:break')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="pause-true"
                      type="radio"
                      label={t('common:active')}
                      name="pause"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('paused', true)}
                      checked={props.values.paused}
                    />
                    <Form.Check
                      inline
                      custom
                      id="pause-false"
                      type="radio"
                      label={t('common:inactive')}
                      name="pause"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('paused', false)}
                      checked={!props.values.paused}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:siteCdn.breakInfo')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    {t('pages:siteCdn.devMode')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="cdn-dev-true"
                      type="radio"
                      label="Ativo"
                      name="devMode"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('devMode', true)}
                      checked={props.values.devMode}
                    />
                    <Form.Check
                      inline
                      custom
                      id="cdn-dev-false"
                      type="radio"
                      label="Inativo"
                      name="devMode"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('devMode', false)}
                      checked={!props.values.devMode}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:siteCdn.devInfo')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mt-4">
                  <Form.Label column sm={2}>
                    {t('pages:siteCdn.cacheUpdate')}
                  </Form.Label>
                  <Col sm={10}>
                    <PanelButton
                      size="sm"
                      onClick={() => updateCache(props.values.idSite)}
                      disabled={updating}
                    >
                      {t('pages:siteCdn.updateCache')}
                    </PanelButton>
                    <Form.Text className="text-muted">
                      {t('pages:siteCdn.cacheUpdateInfo')}
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  disabled={updating || !props.dirty || props.isSubmitting}
                  size="sm"
                >
                  {props.isSubmitting ? t('common:saving') : t('common:save')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Cdn;
