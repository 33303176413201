import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaIdCard } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { Card, Form, Col, Row, InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import PhoneInput from 'components/PhoneInput';
import Error from 'components/Error';
import Loading from 'components/Loading';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

interface MxFormValues {
  name: string;
  email: string;
  domain: string;
  phone: string;
}

const MX: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  async function handleSubmit(
    values: MxFormValues,
    formikHelpers: FormikHelpers<MxFormValues>,
  ) {
    try {
      setError(false);
      setLoading(true);
      const data = {
        name: values.name,
        email: values.email,
        domain: values.domain,
        phone: values.phone,
      };
      await api.post('painel/v1/parcerias/mx/enviar', data);
      formikHelpers.resetForm();
      toast.fire(t('pages:mx.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:mx.failed'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:dealsAndPartneships.title')}
        description={t('pages:dealsAndPartneships.subTitle')}
        icon={<FaIdCard color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:mx.title')}
          description={t('pages:mx.description')}
          startOpen
          helpContent={
            <div>
              <p>{t('pages:mx.helpContent.p1')}.</p>
              <p>{t('pages:mx.helpContent.p2')}.</p>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:mx.helpLinks.link1'),
              url: 'http://www.mxcursos.com/',
            },
          ]}
        />
        <Formik
          validateOnMount
          initialValues={{ name: '', email: '', domain: '', phone: '' }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            domain: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={handleSubmit}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="mx.name">
                  <Form.Label column sm={2} className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mx.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mx.domain">
                  <Form.Label column sm={2} className="required">
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="domain"
                      value={props.values.domain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.domain && !!props.errors.domain
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.domain}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="mx.phone">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/parcerias-e-promocoes"
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default MX;
