import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import history from 'services/history';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface CreateRedirectFormValues {
  domain: string;
  url: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const [protocol, setProtocol] = useState('http://');

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const createRedirect = useCallback(
    async (values: CreateRedirectFormValues) => {
      try {
        const { domain, url } = values;

        await api.post('painel/v1/redirecionamento', {
          dominioAdicional: domain,
          redirecionamento: protocol + url,
          idSite,
        });

        history.push(`/site/redirecionamentos`);

        toast.fire(t('pages:siteRedirectsNew.success'));
      } catch (err) {
        swal.fire({
          title: t('pages:siteRedirectsNew.fail'),
          html: err.response.data.error_description,
        });
      }
    },
    [idSite, protocol, t],
  );

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader title={t('pages:siteRedirectsNew.title')} description="" />
        <Formik
          validateOnMount
          initialValues={{ domain: '', url: '' }}
          validationSchema={Yup.object().shape({
            domain: Yup.string().required(t('validations:requiredField')),
            url: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={createRedirect}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:siteRedirectsNew.legend')} />

                <Form.Group as={Row} controlId="siteRedirectsNew.domain">
                  <Form.Label column sm={2}>
                    {t('pages:siteRedirectsNew.redirectDomain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="domain"
                      value={props.values.domain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.domain && !!props.errors.domain
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:siteRedirectsNew.redirectDomainTip')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.domain}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="siteRedirectsNew.redirectTo">
                  <Form.Label column sm={2}>
                    {t('pages:siteRedirectsNew.redirectTo')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title={protocol}
                      >
                        <Dropdown.Item onSelect={() => setProtocol('http://')}>
                          http://
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => setProtocol('https://')}>
                          https://
                        </Dropdown.Item>
                      </DropdownButton>
                      <Form.Control
                        name="url"
                        value={props.values.url}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.touched.url && !!props.errors.url}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted d-block w-100">
                        {t('pages:siteRedirectsNew.redirectToTip')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.url}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/site/redirecionamentos"
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
