import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import TableButton from 'components/TableButton';
import TableHeader from 'components/TableHeader';
import TableNoData from 'components/TableNoData';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import Error from 'components/Error';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import CreditCardActions from './components/CreditCardActions';

import { Container } from './styles';

interface CreditCardItemResponse {
  idCartao: number;
  idFormaPagamento: number;
  idCliente: number;
  email: string;
  idSite: number;
  site: string;
  nome: string;
  numero: string;
  numerosFinais: string;
  dataExpiracao: string;
  expirado: boolean;
  validado: boolean;
  ordem: number;
}

interface CreditCardsResponse {
  data: CreditCardItemResponse[];
}

interface SelectCreditCardResponse {
  data: CreditCardItemResponse[];
}

interface DeleteCreditCardsResponse {
  data: CreditCardItemResponse[];
}

interface CreditCard {
  idCartao: number;
  idFormaPagamento: number;
  idCliente: number;
  email: string;
  idSite: number;
  site: string;
  nome: string;
  numero: string;
  numerosFinais: string;
  dataExpiracao: string;
  expirado: boolean;
  validado: boolean;
  ordem: number;
}

interface SelectedRowsInfo {
  selectedCount: number;
  selectedRows: CreditCard[];
}

const CreditCardsList: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [creditCards, setCreditCards] = useState<CreditCard[]>([]);
  const [filterText, setFilterText] = useState('');
  const [filteredCreditCards, setFilteredCreditCards] = useState<CreditCard[]>(
    [],
  );

  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const client = useSelector((state: StoreState) => state.client.info);
  const site = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    const filtered = filter(
      creditCards,
      ['numerosFinais', 'dataExpiracao', 'site'],
      filterText,
    );
    setFilteredCreditCards(filtered);
  }, [creditCards, filterText]);

  const formatCreditCards = useCallback(
    (creditCardsResponse: CreditCardItemResponse[]) => {
      const formatedCreditCards = creditCardsResponse.map(creditCard => {
        return {
          idCartao: creditCard.idCartao,
          idFormaPagamento: creditCard.idFormaPagamento,
          idCliente: creditCard.idCliente,
          idSite: creditCard.idSite,
          email: creditCard.email,
          site: creditCard.site,
          nome: creditCard.nome,
          numero: creditCard.numero,
          numerosFinais: `**** **** **** ${creditCard.numerosFinais}`,
          dataExpiracao: creditCard.dataExpiracao,
          expirado: creditCard.expirado,
          validado: creditCard.validado,
          ordem: creditCard.ordem,
        };
      });

      return formatedCreditCards;
    },
    [],
  );

  const selectCreditCard = useCallback(async () => {
    try {
      setUpdating(true);

      const idCreditCard = selectedRowsInfo.selectedRows[0].idCartao;

      await api.put<SelectCreditCardResponse>(
        `clientes/v1/cartao/${idCreditCard}`,
        {
          ordem: 1,
        },
      );

      const params =
        client.tipoCobranca === 'MULTIPLA'
          ? { idSite: site.idSite }
          : { idCliente: client.idCliente };

      const creditCardsResponse = await api.get<CreditCardsResponse>(
        `clientes/v1/cartao`,
        { params },
      );

      const formatedCreditCards = formatCreditCards(
        creditCardsResponse.data.data,
      );

      setCreditCards(formatedCreditCards);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:creditCards.selectSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:creditCards.selectFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }, [
    t,
    client.idCliente,
    client.tipoCobranca,
    site.idSite,
    formatCreditCards,
    selectedRowsInfo.selectedRows,
    toggleCleared,
  ]);

  const deleteCreditCard = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        text: t('pages:creditCards.warning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setUpdating(true);

      const idsCreditCards = selectedRowsInfo.selectedRows.map(
        ({ idCartao }) => idCartao,
      );

      const creditCardsResponse = await api.delete<DeleteCreditCardsResponse>(
        `clientes/v1/cartao`,
        {
          params: { idsCartoes: idsCreditCards },
        },
      );

      const formatedCreditCards = formatCreditCards(
        creditCardsResponse.data.data,
      );

      setCreditCards(formatedCreditCards);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:creditCards.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:creditCards.deleteFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }, [formatCreditCards, selectedRowsInfo.selectedRows, t, toggleCleared]);

  useEffect(() => {
    async function loadCreditCards() {
      try {
        setError(false);
        setLoading(true);

        const params =
          client.tipoCobranca === 'MULTIPLA'
            ? { idSite: site.idSite }
            : { idCliente: client.idCliente };

        const creditCardsResponse = await api.get<CreditCardsResponse>(
          `clientes/v1/cartao`,
          { params },
        );

        const formatedCreditCards = formatCreditCards(
          creditCardsResponse.data.data,
        );

        setCreditCards(formatedCreditCards);
      } catch (err) {
        setError(false);
      } finally {
        setLoading(false);
      }
    }

    loadCreditCards();
  }, [client.idCliente, client.tipoCobranca, site.idSite, formatCreditCards]);

  const defaultColumns = [
    {
      name: t('common:number'),
      selector: 'numerosFinais',
      cell: (row: CreditCard) => row.numerosFinais,
      width: '200px',
    },
    {
      name: t('common:expiration'),
      selector: 'dataExpiracao',
      cell: (row: CreditCard) => (
        <span className={row.expirado ? 'expirado' : ''}>
          {row.dataExpiracao}
        </span>
      ),
      width: '140px',
    },
    {
      name: t('common:validation'),
      selector: 'validado',
      cell: (row: CreditCard) => (row.validado ? 'Validado' : 'Não Validado'),
      width: '140px',
    },
    {
      name: t('common:main'),
      selector: 'ordem',
      cell: (row: CreditCard) =>
        row.ordem === 1
          ? 'Sim, será usado na cobrança automática.'
          : 'Não, será usado se o principal falhar.',
    },
  ];

  const columns =
    client.tipoCobranca === 'MULTIPLA'
      ? [
          ...defaultColumns,
          ...[
            {
              name: t('common:site'),
              selector: 'site',
            },
          ],
        ]
      : defaultColumns;

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title={t('pages:creditCards.title')}
          description={
            <div>
              <div>{t('pages:creditCards.description.p1')}</div>
              <div>{t('pages:creditCards.description.p2')}</div>
              <div>{t('pages:creditCards.description.p3')}</div>
            </div>
          }
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Cartao_de_Credito"
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/financeiro/cartoes-credito/cadastrar"
              size="sm"
            >
              {t('pages:creditCards.newCreditCard')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            selectableRows
            pagination
            data={filteredCreditCards}
            columns={columns}
            clearSelectedRows={toggleCleared}
            progressPending={loading}
            progressComponent={<Loading />}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            contextComponent={
              <CreditCardActions
                loading={updating}
                selectedRows={selectedRowsInfo.selectedRows}
                selectedCount={selectedRowsInfo.selectedCount}
                selectCreditCard={selectCreditCard}
                deleteCreditCard={deleteCreditCard}
              />
            }
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default CreditCardsList;
