import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';

export const AccordionLink = styled(Link)`
  display: flex;
  align-items: center;

  padding: 10px 10px 10px 20px;

  color: var(--color-cadet-blue);

  &:hover {
    text-decoration: none;
    color: var(--color-light-blue);
  }
`;

export const ChevronRightIcon = styled(FiChevronRight)`
  margin-right: 5px;
`;
