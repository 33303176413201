import { useQuery } from 'react-query';

import api from 'services/api';

type DomainItemResponse = {
  id: number;
  idContato: number;
  dominio: string;
  contato: string;
  promocao: 'TRUE' | 'FALSE';
  locked: 'TRUE' | 'FALSE';
  expiracao: string;
  status: string;
  contatoEmail: string;
  periodoRenovacao: boolean;
  renovacaoDisponivel: boolean;
};

type DomainsResponse = {
  data: DomainItemResponse[];
};

type RioDomain = {
  id: number;
  idContato: number;
  dominio: string;
  contato: string;
  promocao: boolean;
  locked: boolean;
  expiracao: string;
  status: string;
  contatoEmail: string;
  periodoRenovacao: boolean;
  renovacaoDisponivel: boolean;
};

const initialData: RioDomain[] = [];

function formatDomain(domain: DomainItemResponse): RioDomain {
  const { promocao, locked, ...domainRest } = domain;

  return {
    promocao: promocao === 'TRUE',
    locked: locked === 'TRUE',
    ...domainRest,
  };
}

async function loadRioDomains() {
  const { data } = await api.get<DomainsResponse>(`rrpproxy/v1/dominio`);

  const domains = data.data.map(formatDomain);

  return domains;
}

export default function useRioDomains() {
  const { data, isError, isLoading, isFetching } = useQuery<RioDomain[]>(
    'rioDomains',
    () => loadRioDomains(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const domains = data || initialData;

  return { domains, error: isError, loading: isLoading, fetching: isFetching };
}
