import React, { useCallback, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import swal from 'services/swal';
import history from 'services/history';
import toast from 'services/toast';

import { StoreState } from 'store/createStore';

import {
  Container,
  Companies,
  Aura,
  DinersClub,
  Hipercard,
  Mastercard,
  Visa,
} from './styles';

interface NewCreditCardFormValues {
  name: string;
  number: string;
  expiration: string;
  securityCode: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const [loading] = useState(false);
  const [error] = useState(false);

  const [aura, setAura] = useState(false);
  const [dinersClub, setDinersClub] = useState(false);
  const [hipercard, setHipercard] = useState(false);
  const [mastercard, setMastercard] = useState(false);
  const [visa, setVisa] = useState(false);

  const client = useSelector((state: StoreState) => state.client.info);
  const site = useSelector((state: StoreState) => state.site.info);

  const mustAddIdSiteParam =
    client.contaComSite && client.tipoCobranca === 'MULTIPLA';

  const createCreditCard = useCallback(
    async (creditCard: NewCreditCardFormValues) => {
      try {
        await api.post(`clientes/v1/cartao`, {
          idCliente: client.idCliente,
          ...(mustAddIdSiteParam && { idSite: site.idSite }),
          nome: creditCard.name.toUpperCase(),
          numero: creditCard.number.replace(/[^\d]/g, ''),
          numeroSeguranca: creditCard.securityCode.replace(/[^\d]/g, ''),
          mes: creditCard.expiration.split('/', 2)[0],
          ano: creditCard.expiration.split('/', 2)[1],
        });

        toast.fire(t('pages:creditCardNew.success'));

        history.push(`/financeiro/cartoes-credito`);
      } catch (err) {
        swal.fire({
          title: t('pages:creditCardNew.failed'),
          html:
            (err.response && err.response.data.error_description) ||
            err.message,
        });
      }
    },
    [client.idCliente, mustAddIdSiteParam, site.idSite, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:creditCardNew.title')}
          description={t('pages:creditCardNew.description')}
          helpContent={
            <div>
              <p>{t('pages:creditCardNew.helpContent.p1')}</p>
              <p>{t('pages:creditCardNew.helpContent.p2')}</p>
              <p>{t('pages:creditCardNew.helpContent.p3')}</p>
            </div>
          }
          helpLinks={[
            {
              url:
                'https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Validacao_Cartao_de_Credito',
              text: 'Validação de cartões de crédito.',
            },
            {
              url:
                'https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Cartao_de_Credito',
              text: 'Saiba mais sobre cartões de crédito.',
            },
          ]}
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            name: '',
            number: '',
            expiration: '',
            securityCode: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required(t('validations:requiredField'))
              .matches(/[^0-9 ]/, t('validations:name'))
              .matches(/^[a-zA-Z0-9 ]+$/, t('common:label.onlyAlphanumeric'))
              .min(10, 'Informe no mínimo 10 caracteres')
              .max(30, 'Informe no máximo 30 caracteres'),
            number: Yup.string()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:label.onlyNumbers'))
              .matches(
                /^(2[2-7]|4[0-9]|30|36|38|5[0-5]|60)[0-9]+$/,
                t('validations:invalidCreditCard'),
              )
              .min(14, 'Informe no mínimo 14 números')
              .max(16, 'Informe no máximo 16 números'),
            expiration: Yup.string()
              .required(t('validations:requiredField'))
              .matches(
                /^((0[1-9]{1}|1[0-2]{1})(\/20)([2-3]{1})([0-9]{1}))$/,
                t('validations:date'),
              ),
            securityCode: Yup.string()
              .required(t('validations:requiredField'))
              .matches(/^[0-9]+$/, t('common:label.onlyNumbers'))
              .min(3, 'Informe no mínimo 3 números')
              .max(4, 'Informe no máximo 4 números'),
          })}
          onSubmit={createCreditCard}
        >
          {props => {
            function selectCompany(value: string | undefined): void {
              setAura(false);
              setDinersClub(false);
              setHipercard(false);
              setMastercard(false);
              setVisa(false);

              if (value) {
                if (/^(50)/.test(value)) {
                  setAura(true);
                }

                if (/^(30[0-5]|36)/.test(value)) {
                  setDinersClub(true);
                }

                if (/^(38|60)/.test(value)) {
                  setHipercard(true);
                }

                if (/^(5[1-5]|2[2-7])/.test(value)) {
                  setMastercard(true);
                }

                if (/^(4)/.test(value)) {
                  setVisa(true);
                }
              }
              props.setFieldValue('number', value);
            }

            return (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <Form.Group as={Row} controlId="newCreditCard.name">
                    <Form.Label column sm={2} className="required">
                      {t('common:name')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        className="maiusculo"
                        name="name"
                        minLength={10}
                        maxLength={30}
                        value={props.values.name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={!!props.touched.name && !!props.errors.name}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:creditCardNew.cardName')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.name}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="newCreditCard.number">
                    <Form.Label column sm={2} className="required">
                      {t('common:number')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="number"
                        minLength={14}
                        maxLength={16}
                        value={props.values.number}
                        onChange={e => selectCompany(e.target.value)}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.number && !!props.errors.number
                        }
                        disabled={props.isSubmitting}
                      />
                      <Companies>
                        <Aura className={aura ? 'selected' : ''} />
                        <DinersClub className={dinersClub ? 'selected' : ''} />
                        <Hipercard className={hipercard ? 'selected' : ''} />
                        <Mastercard className={mastercard ? 'selected' : ''} />
                        <Visa className={visa ? 'selected' : ''} />
                      </Companies>
                      <Form.Text className="text-muted">
                        {t('common:label.onlyNumbers')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.number}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="newCreditCard.expiration">
                    <Form.Label column sm={2} className="required">
                      {t('common:expiration')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="expiration"
                        as={InputMask}
                        mask="99/9999"
                        value={props.values.expiration}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.expiration &&
                          !!props.errors.expiration
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:creditCardNew.cardExpiration')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.expiration}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="newCreditCard.securityCode">
                    <Form.Label column sm={2} className="required">
                      {t('common:securityCode')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="securityCode"
                        minLength={3}
                        maxLength={4}
                        value={props.values.securityCode}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.securityCode &&
                          !!props.errors.securityCode
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:creditCardNew.cardSecurityCode')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.securityCode}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/financeiro/cartoes-credito"
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
