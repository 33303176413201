import styled from 'styled-components';

export const Container = styled.div`
  .selected-date {
    font-size: 13px;
    min-width: 120px;
    text-align: center;
  }

  .table-section {
    font-size: 14px;
    font-weight: bold;
  }

  .btn-secondary:disabled {
    opacity: 0.3;
  }
`;
