import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

type BillingMethodOption = 'MULTIPLA' | 'UNICA';

interface PeriodicityItemResponse {
  idPeriodicidade: number;
  periodicidade: string;
}

interface PaymentMethodItemResponse {
  idFormaPagamento: number;
  formaPagamento: string;
}

interface ClientBillingMethodItemResponse {
  tipoCobranca: BillingMethodOption;
  podeAlterar: boolean;
  motivo: string;
}

interface PeriodicitiesResponse {
  data: {
    periodicidades: PeriodicityItemResponse[];
    periodicidadesPlano: PeriodicityItemResponse[];
  };
}

interface PaymentMethodsResponse {
  data: PaymentMethodItemResponse[];
}

interface ClientBillingMethodResponse {
  data: ClientBillingMethodItemResponse;
}

interface Periodicity {
  idPeriodicidade: number;
  periodicidade: string;
}

interface PaymentMethod {
  idFormaPagamento: number;
  formaPagamento: string;
}

interface BillingMethod {
  cobrancaTipo: BillingMethodOption;
  cobrancaTipoFormatado: string;
}

interface ClientBillingMethod {
  tipoCobranca: BillingMethodOption;
  podeAlterar: boolean;
  motivo: string;
}

interface EditPeriodicityFormValues {
  periodicity: number;
  planPeriodicity: number;
}

interface EditPaymentMethodFormValues {
  paymentMethod: number;
}

interface EditBillingMethodFormValues {
  billingMethod: string;
}

const PaymentMethods: React.FC = () => {
  const { t } = useTranslation();

  const client = useSelector((state: StoreState) => state.client.info);
  const site = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [periodicities, setPeriodicities] = useState<Periodicity[]>([]);
  const [planPeriodicities, setPlanPeriodicities] = useState<Periodicity[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [billingMethods, setBillingMethods] = useState<BillingMethod[]>([]);

  const [periodicity, setPeriodicity] = useState<Periodicity>({
    idPeriodicidade: 1,
    periodicidade: 'Mensal',
  });

  const [planPeriodicity, setPlanPeriodicity] = useState<Periodicity>({
    idPeriodicidade: 1,
    periodicidade: 'Mensal',
  });

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    idFormaPagamento: 14,
    formaPagamento: 'Cartão de crédito',
  });

  const [billingMethod, setBillingMethod] = useState<ClientBillingMethod>({
    tipoCobranca: 'UNICA',
    podeAlterar: false,
    motivo: '',
  });

  useEffect(() => {
    async function loadPeriodicities() {
      try {
        setError(false);
        setLoading(true);

        const params = {
          idCliente: client.idCliente,
          ...(client.contaComSite && site.idSite && { idSite: site.idSite }),
        };

        const periodicitiesResponse = await api.get<PeriodicitiesResponse>(
          `clientes/v1/periodicidade`,
          { params },
        );

        setPeriodicities(periodicitiesResponse.data.data.periodicidades);
        setPlanPeriodicities(
          periodicitiesResponse.data.data.periodicidadesPlano,
        );
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    async function loadPaymentMethods() {
      try {
        setError(false);
        setLoading(true);

        const paymentMethodsResponse = await api.get<PaymentMethodsResponse>(
          `clientes/v1/forma-pagamento`,
          { params: { idCliente: client.idCliente } },
        );

        setPaymentMethods(paymentMethodsResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    async function loadBillingMethods() {
      setBillingMethods([
        {
          cobrancaTipo: 'UNICA',
          cobrancaTipoFormatado: 'Cobrança única',
        },
        {
          cobrancaTipo: 'MULTIPLA',
          cobrancaTipoFormatado: 'Uma cobrança por cada site',
        },
      ]);
    }

    async function loadClient() {
      try {
        setError(false);
        setLoading(true);

        if (client.contaComSite) {
          const siteResponse = await api.get(`clientes/v1/site/${site.idSite}`);

          setPeriodicity({
            idPeriodicidade: siteResponse.data.data[0].idPeriodicidade,
            periodicidade: siteResponse.data.data[0].periodicidade,
          });

          setPlanPeriodicity({
            idPeriodicidade: siteResponse.data.data[0].idPeriodicidade,
            periodicidade: siteResponse.data.data[0].periodicidade,
          });

          setPaymentMethod({
            idFormaPagamento: siteResponse.data.data[0].idFormaPagamento,
            formaPagamento: siteResponse.data.data[0].formaPagamento,
          });
        } else {
          const clienteResponse = await api.get(
            `clientes/v1/cliente/${client.idCliente}`,
          );

          setPeriodicity({
            idPeriodicidade: clienteResponse.data.data[0].idPeriodicidade,
            periodicidade: clienteResponse.data.data[0].periodicidade,
          });

          setPaymentMethod({
            idFormaPagamento: clienteResponse.data.data[0].idFormaPagamento,
            formaPagamento: clienteResponse.data.data[0].formaPagamento,
          });
        }

        const billingMethodResponse = await api.get<ClientBillingMethodResponse>(
          `clientes/v1/tipo-cobranca`,
          { params: { idCliente: client.idCliente } },
        );

        setBillingMethod(billingMethodResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadPeriodicities();
    loadPaymentMethods();
    loadBillingMethods();

    loadClient();
  }, [client, site]);

  const editPeriodicity = useCallback(
    async (values: EditPeriodicityFormValues) => {
      try {
        const idPeriodicidade = values.periodicity;
        const idPeriodicidadePlano = values.planPeriodicity;

        await api.post(`clientes/v1/periodicidade/troca`, {
          idCliente: client.idCliente,
          idPeriodicidadeNova: idPeriodicidade,
          idPeriodicidadeNovaPlano: idPeriodicidadePlano,
          ...(client.contaComSite && site.idSite && { idSite: site.idSite }),
        });

        toast.fire(t('pages:paymentMethod.periodicitySuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:paymentMethod.periodicityFail'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [client, site, t],
  );

  const editPaymentMethod = useCallback(
    async (values: EditPaymentMethodFormValues) => {
      try {
        const idFormaPagamento = values.paymentMethod;

        await api.post(`clientes/v1/forma-pagamento/troca`, {
          idCliente: client.idCliente,
          idFormaPagamentoNova: idFormaPagamento,
          ...(client.contaComSite && site.idSite && { idSite: site.idSite }),
        });

        toast.fire(t('pages:paymentMethod.paymentMethodSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:paymentMethod.paymentMethodFail'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [client, site, t],
  );

  const editBillingMethod = useCallback(
    async (values: EditBillingMethodFormValues) => {
      try {
        const tipoCobranca = values.billingMethod;

        await api.post(`clientes/v1/tipo-cobranca/troca`, {
          idCliente: client.idCliente,
          tipo: tipoCobranca,
        });

        toast.fire(t('pages:paymentMethod.billingMethodSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:paymentMethod.billingMethodFail'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [client, t],
  );

  paymentMethods.sort((a, b) => (a.formaPagamento < b.formaPagamento ? 1 : -1));

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:paymentMethod.periodicityTitle')}
          description={t('pages:paymentMethod.periodicityDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Periodicidade"
        />
        <Formik
          enableReinitialize
          initialValues={{
            periodicity: periodicity.idPeriodicidade,
            planPeriodicity: planPeriodicity.idPeriodicidade,
          }}
          validationSchema={Yup.object().shape({
            periodicity: Yup.string().required(t('validations:invalidRadio')),
            planPeriodicity: Yup.string().required(
              t('validations:invalidRadio'),
            ),
          })}
          onSubmit={editPeriodicity}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:paymentMethod.periodicity')}
                  </Form.Label>
                  <Col sm={10}>
                    {periodicities.map(item => (
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        key={`periodicity-${item.periodicidade}`}
                        id={`periodicity-${item.periodicidade}`}
                        name="periodicity"
                        label={item.periodicidade}
                        value={item.idPeriodicidade}
                        checked={
                          Number(props.values.periodicity) ===
                          Number(item.idPeriodicidade)
                        }
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.periodicity &&
                          !!props.errors.periodicity
                        }
                        disabled={props.isSubmitting}
                      />
                    ))}
                    <Form.Text className="text-muted">
                      {t('pages:paymentMethod.periodicityInfo')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.periodicity}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {planPeriodicities.length > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:paymentMethod.planPeriodicity')}
                    </Form.Label>
                    <Col sm={10}>
                      {planPeriodicities.map(item => (
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          key={`planPeriodicity-${item.periodicidade}`}
                          id={`planPeriodicity-${item.periodicidade}`}
                          name="planPeriodicity"
                          label={item.periodicidade}
                          value={item.idPeriodicidade}
                          checked={
                            Number(props.values.planPeriodicity) ===
                            Number(item.idPeriodicidade)
                          }
                          onChange={props.handleChange}
                          isInvalid={
                            !!props.touched.periodicity &&
                            !!props.errors.periodicity
                          }
                          disabled={props.isSubmitting}
                        />
                      ))}
                      <Form.Text className="text-muted">
                        {t('pages:paymentMethod.planPeriodicityInfo')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.periodicity}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:paymentMethod.paymentMethodTitle')}
          description={t('pages:paymentMethod.paymentMethodDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Formas_de_Pagamento"
        />
        <Formik
          enableReinitialize
          initialValues={{
            paymentMethod: paymentMethod.idFormaPagamento,
          }}
          validationSchema={Yup.object().shape({
            paymentMethod: Yup.string().required(t('validations:invalidRadio')),
          })}
          onSubmit={editPaymentMethod}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              {paymentMethods.length > 1 && (
                <>
                  <Card.Body className="fieldset">
                    <Form.Group as={Row}>
                      <Form.Label column sm={2}>
                        {t('pages:paymentMethod.paymentMethod')}
                      </Form.Label>
                      <Col sm={10}>
                        {paymentMethods.map(item => (
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            key={`paymentMethod-${item.formaPagamento}`}
                            id={`paymentMethod-${item.formaPagamento}`}
                            name="paymentMethod"
                            label={item.formaPagamento}
                            value={item.idFormaPagamento}
                            checked={
                              Number(props.values.paymentMethod) ===
                              Number(item.idFormaPagamento)
                            }
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.paymentMethod &&
                              !!props.errors.paymentMethod
                            }
                            disabled={props.isSubmitting}
                          />
                        ))}
                        <Form.Text className="text-muted">
                          {t('pages:paymentMethod.paymentMethodInfo')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.paymentMethod}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </Card.Body>
                  <div className="border-top pt-2 pb-2 pl-3">
                    <PanelButton
                      type="submit"
                      className="mr-1"
                      disabled={props.isSubmitting || !props.isValid}
                    >
                      {props.isSubmitting
                        ? t('common:requesting')
                        : t('common:request')}
                    </PanelButton>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:paymentMethod.billingMethodTitle')}
          description={t('pages:paymentMethod.billingMethodDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Tipo_de_Cobranca"
        />
        <Formik
          enableReinitialize
          initialValues={{
            billingMethod: billingMethod.tipoCobranca,
          }}
          validationSchema={Yup.object().shape({
            billingMethod: Yup.string().required(t('validations:invalidRadio')),
          })}
          onSubmit={editBillingMethod}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:paymentMethod.billingMethod')}
                  </Form.Label>
                  <Col sm={10}>
                    {billingMethods.map(item => (
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        key={`billingMethod-${item.cobrancaTipo}`}
                        id={`billingMethod-${item.cobrancaTipo}`}
                        name="billingMethod"
                        label={item.cobrancaTipoFormatado}
                        value={item.cobrancaTipo}
                        checked={
                          props.values.billingMethod === item.cobrancaTipo
                        }
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.billingMethod &&
                          !!props.errors.billingMethod
                        }
                        disabled={
                          props.isSubmitting || !billingMethod.podeAlterar
                        }
                      />
                    ))}
                    <Form.Text className="text-muted">
                      {t('pages:paymentMethod.billingMethodInfo')}
                      <br />
                      {billingMethod.motivo}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.billingMethod}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={
                    props.isSubmitting ||
                    !props.isValid ||
                    !billingMethod.podeAlterar
                  }
                >
                  {props.isSubmitting ? t('common:saving') : t('common:save')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default PaymentMethods;
