import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FormSubtitle from 'components/FormSubtitle';
import PhoneInput from 'components/PhoneInput';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import swal from 'services/swal';
import history from 'services/history';
import toast from 'services/toast';

import { Container } from './styles';

interface StatesResponse {
  data: {
    idEstado: number;
    sigla: string;
    estado: string;
  }[];
}

interface State {
  idEstado: number;
  sigla: string;
  estado: string;
}

interface NewEntityFormValues {
  name: string;
  cpfCnpj: string;
  email: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  state: string;
  city: string;
  zipCode: string;
  phone: string;
  stateRegistration: string;
  municipalRegistration: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function loadStates() {
      try {
        setError(false);
        setLoading(true);

        const statesResponse = await api.get<StatesResponse>(
          'regiao/v1/estado?pais=Brasil',
        );

        setStates(statesResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadStates();
  }, []);

  const createEntity = useCallback(
    async (values: NewEntityFormValues) => {
      try {
        const fullPhone = `55.${values.phone.replace(/[^\d]/g, '')}`;

        await api.post(`clientes/v1/entidade-financeira`, {
          nome: values.name,
          cpfCnpj: values.cpfCnpj,
          email: values.email,
          endereco: values.address,
          numero: values.number,
          complemento: values.complement,
          bairro: values.neighborhood,
          cep: values.zipCode,
          cidade: values.city,
          estado: values.state,
          telefone: fullPhone,
          inscricaoEstadual: values.stateRegistration,
          inscricaoMunicipal: values.municipalRegistration,
        });

        toast.fire(t('pages:entityCreate.success'));

        history.push(`/financeiro/entidades`);
      } catch (err) {
        swal.fire({
          title: t('pages:entityCreate.failed'),
          html:
            (err.response && err.response.data.error_description) ||
            err.message,
        });
      }
    },
    [t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:entityCreate.title')}
          description={t('pages:entityCreate.description')}
          helpContent={
            <div>
              <p>{t('pages:entityCreate.helpContent.p1')}</p>
              <p>{t('pages:entityCreate.helpContent.p2')}</p>
              <p>{t('pages:entityCreate.helpContent.p3')}</p>
              <ul>
                <li>{t('pages:entityCreate.helpContent.li1')}</li>
              </ul>
            </div>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            name: '',
            cpfCnpj: '',
            email: '',
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            state: 'AC',
            city: '',
            zipCode: '',
            phone: '',
            stateRegistration: '',
            municipalRegistration: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            cpfCnpj: Yup.number().required(t('validations:requiredField')),
            address: Yup.string().required(t('validations:requiredField')),
            number: Yup.string().required(t('validations:requiredField')),
            neighborhood: Yup.string().required(t('validations:requiredField')),
            city: Yup.string().required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={createEntity}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:entityCreate.legend')} />

                <Form.Group as={Row} controlId="newEntity.name">
                  <Form.Label column sm={2} className="required">
                    {t('pages:entityCreate.companyName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.cpfCnpj">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.cpfCnpj')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="cpfCnpj"
                      maxLength={14}
                      value={props.values.cpfCnpj}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.cpfCnpj && !!props.errors.cpfCnpj
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('common:label.onlyNumbers')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.cpfCnpj}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.number">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.number')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="number"
                      value={props.values.number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.number && !!props.errors.number
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.complement">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.complement')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="complement"
                      value={props.values.complement}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.complement && !!props.errors.complement
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.complement}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.neighborhood">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.neighborhood')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="neighborhood"
                      value={props.values.neighborhood}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.neighborhood &&
                        !!props.errors.neighborhood
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.neighborhood}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.state">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="state"
                      as="select"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    >
                      {states.map(state => (
                        <option key={state.idEstado} value={state.sigla}>
                          {state.sigla}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.city">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="zipCode"
                      value={props.values.zipCode}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.zipCode && !!props.errors.zipCode
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.phone">
                  <Form.Label column sm={2}>
                    {t('common:label.phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newEntity.stateRegistration">
                  <Form.Label column sm={2}>
                    {t('pages:entityCreate.stateRegistration')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="stateRegistration"
                      value={props.values.stateRegistration}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.stateRegistration &&
                        !!props.errors.stateRegistration
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.stateRegistration}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  controlId="newEntity.municipalRegistration"
                >
                  <Form.Label column sm={2}>
                    {t('pages:entityCreate.municipalRegistration')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="municipalRegistration"
                      value={props.values.municipalRegistration}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.municipalRegistration &&
                        !!props.errors.municipalRegistration
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.municipalRegistration}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/financeiro/entidades"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
