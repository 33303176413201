import { useQuery } from 'react-query';

import api from 'services/api';

type ProfileItem = {
  site: string;
  arquivo: string;
  qtdExec: number;
  tempoTotal: string;
  tempoMin: string;
  tempoMax: string;
};

type RatchetWebProfilerResponse = {
  data: {
    dias: string[];
    profiles: ProfileItem[];
    sites: string[];
  };
};

type Profile = {
  site: string;
  arquivo: string;
  qtdExec: number;
  tempoTotal: string;
  tempoMin: string;
  tempoMax: string;
};

type RatchetWebProfiler = {
  dias: string[];
  profiles: Profile[];
  sites: string[];
};

const initialData: RatchetWebProfiler = {
  dias: [],
  profiles: [],
  sites: [],
};

function formatResponse(
  ratchetWebProfilerResponse: RatchetWebProfilerResponse,
): RatchetWebProfiler {
  return {
    dias: ratchetWebProfilerResponse.data.dias,
    profiles: ratchetWebProfilerResponse.data.profiles,
    sites: ratchetWebProfilerResponse.data.sites,
  };
}

async function loadRatchetWebProfiler(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetWebProfiler> {
  const emailTrafficResponse = await api.get<RatchetWebProfilerResponse>(
    `painel/v1/ratchet/web-profiler`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const emailTraffic = formatResponse(emailTrafficResponse.data);

  return emailTraffic;
}

export default function useRatchetWebProfiler(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetWebProfiler>(
    ['ratchetWebProfiler', site, initialDate, finalDate],
    () => loadRatchetWebProfiler(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetWebProfiler = data || initialData;

  return {
    ratchetWebProfiler,
    ...rest,
  };
}
