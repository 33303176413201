import React from 'react';

import { Container } from './styles';

interface OverlayProps {
  active: boolean;
  onClick: () => void;
}

const Overlay: React.FC<OverlayProps> = ({ active, onClick }) => {
  return <Container className={active ? 'active' : ''} onClick={onClick} />;
};

export default Overlay;
