import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import useTipNotices from 'hooks/useTipNotices';

import PanelCard from 'components/PanelCard';

import { Container } from './styles';

const Tip: React.FC = () => {
  const { tipNotices, loading: loadingTipNotices } = useTipNotices();
  const [random, setRandom] = useState(0);

  useEffect(() => {
    setRandom(Math.floor(Math.random() * tipNotices.length));
  }, [tipNotices, setRandom]);

  return (
    <Container>
      {!loadingTipNotices && tipNotices.length > 0 && (
        <PanelCard>
          <Card.Header>
            <h5>{tipNotices[random].title}</h5>
          </Card.Header>
          <Card.Body>
            <div className="notice-content">
              {/*eslint-disable */}
              <div
                dangerouslySetInnerHTML={{
                  __html: tipNotices[random].message,
                }}
              ></div>
              {/* eslint-enable */}
            </div>
          </Card.Body>
        </PanelCard>
      )}
    </Container>
  );
};

export default Tip;
