import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PhoneInput from 'components/PhoneInput';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import useRioDomainContacts from 'hooks/useRioDomainContacts';

import swal from 'services/swal';
import hashIds from 'services/hashIds';
import toast from 'services/toast';
import history from 'services/history';
import api from 'services/api';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

interface FormValues {
  type: 'FISICA' | 'JURIDICA';
  name: string;
  lastName: string;
  email: string;
  company: string;
  address: string;
  document: string;
  zipCode: string;
  countryCode: string;
  phone: string;
}

type RioContactEditProps = RouteComponentProps<MatchParams>;

const Edit: React.FC<RioContactEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idContact] = hashIds.decode(match.params.id);

  const [error, setError] = useState(false);

  const { contacts, fetching, refetch } = useRioDomainContacts(
    Number(idContact),
  );

  const [contact, setContact] = useState<typeof contacts[number]>({
    id: 0,
    name: '',
    lastName: '',
    company: '',
    contact: '',
    email: '',
    address: '',
    zipCode: '',
    document: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    phone: '',
    naturalPerson: false,
    validateIdentity: false,
  });

  useEffect(() => {
    if (contacts.length === 0 || idContact === undefined) {
      setError(true);
      return;
    }

    setError(false);

    setContact(contacts[0]);
  }, [contacts, idContact]);

  async function submitForm(values: FormValues) {
    try {
      const fullPhone = `${values.countryCode}.${values.phone.replace(
        /[^\d]/g,
        '',
      )}`;

      await api.put(`rrpproxy/v1/contato/${idContact}`, {
        endereco: values.address,
        cep: values.zipCode.replace(/[^\d]/g, ''),
        telefone: fullPhone,
      });

      refetch();

      toast.fire(t('pages:rioDomainContactEdit.success'));

      history.push(`/registro/contatos`);
    } catch (err) {
      swal.fire({
        title: t('pages:rioDomainContactEdit.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:rioDomainContactEdit.title')}
          description={t('pages:rioDomainContactEdit.description')}
          helpContent={
            <div>
              <p>
                <b>{t('common:warning')}</b>
              </p>
              <p>{t('pages:rioDomainContactEdit.helpContent.p1')}</p>
              <p>{t('pages:rioDomainContactEdit.helpContent.p2')}</p>
            </div>
          }
          startOpen
        />

        <Formik
          enableReinitialize
          initialValues={{
            type: contact.naturalPerson ? 'FISICA' : 'JURIDICA',
            name: contact.name,
            lastName: contact.lastName,
            company: contact.company,
            address: contact.address,
            email: contact.email,
            document: contact.document,
            zipCode: contact.zipCode,
            countryCode: contact.countryCode,
            phone: contact.phone,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            lastName: Yup.string().required(t('validations:requiredField')),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            company: Yup.string().when('type', {
              is: 'JURIDICA',
              then: Yup.string().required(t('validations:requiredField')),
            }),
            address: Yup.string().required(t('validations:requiredField')),
            document: Yup.string()
              .when('type', {
                is: 'JURIDICA',
                then: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 14,
                ),
                otherwise: Yup.string().test(
                  'validDocument',
                  t('validations:invalidFormat'),
                  val => !!val && val.replace(/[^\d]/g, '').length === 11,
                ),
              })
              .required(t('validations:requiredField')),
            countryCode: Yup.number()
              .required(t('validations:requiredField'))
              .typeError(t('validations:invalidNumber')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:rioDomainContactEdit.legend')}
                />

                <Form.Group as={Row} controlId="form-type">
                  <Form.Label column sm={2}>
                    {t('common:type')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="natural-contact"
                      type="radio"
                      label={t('common:naturalPerson')}
                      disabled
                      onChange={() => props.setFieldValue('type', 'FISICA')}
                      checked={props.values.type === 'FISICA'}
                    />
                    <Form.Check
                      inline
                      custom
                      id="legal-contact"
                      type="radio"
                      label={t('common:legalPerson')}
                      disabled
                      onChange={() => props.setFieldValue('type', 'JURIDICA')}
                      checked={props.values.type === 'JURIDICA'}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.name">
                  <Form.Label column sm={2} className="required">
                    {props.values.type === 'FISICA'
                      ? t('common:name')
                      : t('pages:rioDomainContactEdit.nameOfResponsible')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      plaintext
                      readOnly
                    />
                    {props.values.type === 'JURIDICA' && (
                      <Form.Text className="text-muted">
                        {t('pages:rioDomainContactEdit.contactNameTip')}
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.lastName">
                  <Form.Label column sm={2} className="required">
                    {props.values.type === 'FISICA'
                      ? t('common:lastName')
                      : t('pages:rioDomainContactEdit.responsibleLastName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="lastName"
                      value={props.values.lastName}
                      plaintext
                      readOnly
                    />
                    {props.values.type === 'JURIDICA' && (
                      <Form.Text className="text-muted">
                        {t('pages:rioDomainContactEdit.contactNameTip')}
                      </Form.Text>
                    )}
                    <Form.Control.Feedback type="invalid">
                      {props.errors.lastName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {props.values.type === 'JURIDICA' && (
                  <Form.Group as={Row} controlId="contact.company">
                    <Form.Label column sm={2} className="required">
                      {t('common:company')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="company"
                        value={props.values.company}
                        plaintext
                        readOnly
                      />
                      {props.values.type === 'JURIDICA' && (
                        <Form.Text className="text-muted">
                          {t('pages:rioDomainContactEdit.companyNameTip')}
                        </Form.Text>
                      )}
                      <Form.Control.Feedback type="invalid">
                        {props.errors.company}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row} controlId="contact.document">
                  <Form.Label column sm={2} className="required">
                    {props.values.type === 'JURIDICA'
                      ? t('common:cnpj')
                      : t('common:cpf')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="document"
                      value={props.values.document}
                      plaintext
                      readOnly
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.document}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="email"
                      value={props.values.email}
                      plaintext
                      readOnly
                    />
                    <Form.Text className="text-muted">
                      {t('pages:rioDomainContactEdit.emailTip')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as={InputMask}
                      mask="99999-999"
                      name="zipCode"
                      value={props.values.zipCode}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.zipCode && !!props.errors.zipCode
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.city">
                  <Form.Label column sm={2}>
                    {t('common:city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value="Rio de Janeiro" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.state">
                  <Form.Label column sm={2}>
                    {t('common:state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value="RJ" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.country">
                  <Form.Label column sm={2}>
                    {t('common:country')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value="Brasil" />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.phone">
                  <Form.Label column sm={2} className="required">
                    {t('common:phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} className="ddi-container">
                        <Form.Control
                          type="string"
                          name="countryCode"
                          maxLength={3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.countryCode &&
                            !!props.errors.countryCode
                          }
                          value={props.values.countryCode}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">DDI</Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.countryCode}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <PhoneInput
                          name="phone"
                          value={props.values.phone}
                          handleChange={props.handleChange}
                          handleBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.phone && !!props.errors.phone
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:number')}
                        </Form.Text>

                        <Form.Control.Feedback type="invalid">
                          {props.errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/contatos"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
