import styled from 'styled-components';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  p {
    font-size: 14px;
  }

  a {
    font-size: 13px;
    color: var(--color-midnight-blue);

    &:hover {
      text-decoration: none;
    }
  }

  .nav-link {
    font-size: 13px;
    color: var(--color-midnight-blue);

    &.active {
      border-bottom: 4px solid;
      border-color: var(--color-blue);
    }
  }
`;

export const NavItem = styled(Nav.Item)`
  flex: 1;
`;

export const ItemLabel = styled.h5`
  font-size: 18px;
  font-weight: 500;

  color: var(--color-blue);
`;

export const TabLink = styled(Link)`
  font-size: 13px;
  color: var(--color-midnight-blue);
  transition: all 0.3s ease-in-out;
`;
