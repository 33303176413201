import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableNoData from 'components/TableNoData';
import TableWrapper from 'components/TableWrapper';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';

import useInternationalDomainTransfers from 'hooks/useInternationalDomainTransfers';

import { Container } from './styles';

import InternationalTransferActions from './components/InternationalTransferActions';

const Transfer: React.FC = () => {
  const { t } = useTranslation();

  const {
    internationalTransfers,
    isLoading,
  } = useInternationalDomainTransfers();

  const [
    selectedInternationalTransfersRowsInfo,
    setSelectedInternationalTransfersRowsInfo,
  ] = useState<{
    selectedCount: number;
    selectedRows: typeof internationalTransfers;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:domainTransfer.internationalTransfers')}
          description=""
          helpContent={t('pages:domainTransfer.help')}
          helpLinks={[
            {
              text: t('pages:domainTransfer.internationalTransferLink'),
              url:
                'https://www.hostnet.com.br/info/transferencia-de-dominios-internacionais-para-a-hostnet/',
            },
          ]}
          directHelpLink="https://ajuda.hostnet.com.br/transferencia-de-dominios-internacionais-para-a-hostnet/"
        />
        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/registro/transferencia-internacional"
            >
              {t('pages:domainTransfer.transferInternationalDomain')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => value} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={
              selectedInternationalTransfersRowsInfo.selectedCount === 0
            }
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                cell: transfer => transfer.domain,
                width: '220px',
              },
              {
                name: t('common:date'),
                cell: transfer => transfer.requestDate,
                width: '120px',
              },
              {
                name: t('common:status'),
                cell: transfer => transfer.statusDescription,
              },
            ]}
            data={internationalTransfers}
            progressPending={isLoading}
            onSelectedRowsChange={rows =>
              setSelectedInternationalTransfersRowsInfo(rows)
            }
            contextComponent={
              <InternationalTransferActions
                selectedCount={
                  selectedInternationalTransfersRowsInfo.selectedCount
                }
                selectedRows={
                  selectedInternationalTransfersRowsInfo.selectedRows
                }
              />
            }
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domainTransfer.brTransfers')}
          description=""
          helpContent={t('pages:domainTransfer.help')}
          helpLinks={[
            {
              text: t('pages:domainTransfer.brTransferLink'),
              url:
                'https://www.hostnet.com.br/info/transferencia-de-entidade-da-registro-br-para-a-hostnet/',
            },
          ]}
          directHelpLink="https://ajuda.hostnet.com.br/transferencia-de-entidade-da-registro-br-para-a-hostnet/"
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/registro/transferencia-br">
              {t('pages:domainTransfer.transferBrDomain')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => value} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            selectableRows
            columns={[]}
            data={[]}
            progressPending={false}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Transfer;
