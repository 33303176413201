import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  FaEnvelope,
  FaHdd,
  FaLock,
  FaLockOpen,
  FaExchangeAlt,
} from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import PanelCard from 'components/PanelCard';
import SiteResourceUsage from 'components/SiteResourceUsage';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import TableIconButton from 'components/TableIconButton';

import useEmails from 'hooks/useEmails';
import useUltramail from 'hooks/useUltramail';
import useEmailServices from 'hooks/useEmailServices';

import { StoreState } from 'store/createStore';

import filter from 'utils/filter';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';
import history from 'services/history';

import checkEmailServicesAlert from 'services/checkEmailServicesAlert';

import EmailsActions from './components/EmailsActions';

import { Container } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const {
    trafegoTotalGB,
    trafegoEmailGB,
    planoIlimitado,
    trafegoPorcentualUtilizado,
  } = useSelector((state: StoreState) => state.site.resources);

  const {
    ultramail,
    isFetched,
    isFetching: fetchingUltramail,
    refetch: refetchUltramail,
  } = useUltramail(idSite);
  const { emails, loading, refetch: refetchEmails } = useEmails(idSite);
  const {
    emailServices,
    fetching: fetchingEmailServices,
    refetch: refetchEmailServices,
  } = useEmailServices(idSite);

  const hasActivatedEmailService = emailServices.find(
    emailService => emailService.ativo,
  );

  const canActivateUltramail =
    !ultramail.possuiSiteComUltramailAtivo && !hasActivatedEmailService;

  const shouldGoToTheEmailServicePage =
    !ultramail.possuiSiteComUltramailAtivo && hasActivatedEmailService;

  const fetching = fetchingEmailServices || fetchingUltramail;

  type Email = typeof emails[0];

  const [toggleCleared, setToggleCleared] = useState(false);

  const [emailsFilterText, setEmailsFilterText] = useState('');
  const [filteredEmails, setFilteredEmails] = useState(emails);
  const [updating, setUpdating] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof emails;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  async function changeEmailLock(idEmail: number, locked: boolean) {
    try {
      setUpdating(true);

      await api.put(`painel/v1/site/${idSite}/email/${idEmail}`, {
        bloqueio: !locked,
      });

      await refetchEmails();

      toast.fire(
        locked
          ? t('pages:emails.unlockSuccess')
          : t('pages:emails.blockSuccess'),
      );
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function deleteEmail() {
    try {
      const emailsIds = selectedRowsInfo.selectedRows.map(({ id }) => id);

      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:confirmOperation'),
        text: t('pages:emails.deleteWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setUpdating(true);

      await api.delete(`painel/v1/site/${idSite}/email`, {
        params: { idsEmails: emailsIds },
      });

      await refetchEmails();

      refetchUltramail();

      setToggleCleared(prev => !prev);

      toast.fire(t('pages:emails.emailRemovedSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:emails.emailRemoveFail'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function activateUltramailAlert() {
    swal
      .fire({
        icon: 'info',
        title: 'Ative o Ultramail',
        html: t('common:activateUltramailAction'),
        customClass: {
          confirmButton: 'btn btn-primary btn-sm mr-3',
          denyButton: 'btn btn-secondary btn-sm mr-3',
        },
        showDenyButton: true,
        confirmButtonText: t('common:activateUltramail'),
        denyButtonText: t('common:cancel'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !swal.isLoading(),
        preConfirm: async () => {
          try {
            setUpdating(true);

            await api.post(`painel/v1/site/${idSite}/servico-email`, {
              servico: 'ULTRAMAIL',
            });

            await Promise.all([refetchEmailServices(), refetchUltramail()]);

            setToggleCleared(!toggleCleared);

            toast.fire(t('pages:emailServices.activateSuccess'));
          } catch (err) {
            swal.fire({
              title: t('pages:emailServices.activateFailed'),
              html: err.response.data.error_description,
            });
          } finally {
            setUpdating(false);
          }
        },
      })
      .then(() => {
        return null;
      });
  }

  useEffect(() => {
    setFilteredEmails(
      filter(emails, ['mailBox', 'descricao'], emailsFilterText),
    );
  }, [emails, emailsFilterText]);

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <PanelCard>
        <Card.Body>
          <Row>
            <Col md={4} className="border-md-right pb-4 pb-xl-0 px-4">
              <SiteResourceUsage
                icon={<FaEnvelope />}
                label={t('common:email')}
                total={`${emails.length}`}
                used="Total"
                percentage={2}
                color="blue"
                showBar={false}
              />
            </Col>
            <Col md={4} className="border-md-right pb-4 pb-xl-0 px-4">
              <SiteResourceUsage
                icon={<FaHdd />}
                label={t('common:disk')}
                total={`${ultramail.total}GB`}
                used={`${ultramail.utilizado}GB`}
                percentage={ultramail.percentualUtilizado}
                color="blue"
              />
            </Col>
            <Col md={4} className="pb-4 pb-xl-0 px-4">
              <SiteResourceUsage
                icon={<FaExchangeAlt />}
                label={t('common:transfer')}
                total={
                  planoIlimitado ? t('common:unlimited') : `${trafegoTotalGB}GB`
                }
                used={`${trafegoEmailGB}GB`}
                percentage={trafegoPorcentualUtilizado}
                color="blue"
              />
            </Col>
          </Row>
        </Card.Body>
      </PanelCard>

      <TableWrapper>
        <TableHeader
          title="Contas de email"
          description="Gerencie suas contas de email criadas no Utramail Hostnet"
          directHelpLink="https://ajuda.hostnet.com.br/e-mail/"
        />

        <TableSubHeader>
          <div>
            <TableButton
              disabled={fetching}
              onClick={() => {
                if (isFetched && canActivateUltramail) {
                  activateUltramailAlert();
                } else if (isFetched && shouldGoToTheEmailServicePage) {
                  checkEmailServicesAlert();
                } else {
                  history.push('/emails/novo');
                }
              }}
            >
              {t('pages:emails.newEmail')}
            </TableButton>
            <TableButton forwardedAs={Link} to="/servico-email">
              {t('pages:emails.manageService')}
            </TableButton>
            <TableButton forwardedAs={Link} to="/site/quota-de-espaco">
              {t('pages:emails.manageQuota')}
            </TableButton>
          </div>

          <div>
            <FilterInput onChange={value => setEmailsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            subHeaderWrap
            pagination
            selectableRows
            clearSelectedRows={toggleCleared}
            columns={[
              {
                name: t('pages:emails.emailAccount'),
                selector: 'mailBox',
                minWidth: '240px',
                sortable: true,
                cell: (row: Email) => (
                  <div>{row.mailBox.replace('=', '@')}</div>
                ),
              },
              {
                name: t('pages:emails.emailSize'),
                selector: 'quota',
                width: '160px',
                sortable: true,
                cell: (row: Email) => `${row.quotaGB}GB`,
              },
              {
                name: t('pages:emails.quota'),
                width: '100px',
                selector: 'quotaWarning',
                sortable: true,
                cell: (row: Email) => (
                  <div>
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id={`tooltip-${row.id}`}>
                          {!row.quotaWarning
                            ? t('pages:emails.quotaWarningBelow')
                            : t('pages:emails.quotaWarningAbove')}
                        </Tooltip>
                      }
                    >
                      <span>
                        {!row.quotaWarning ? 'Ok' : t('common:warning')}
                      </span>
                    </OverlayTrigger>
                  </div>
                ),
              },
              {
                name: 'Bloqueado',
                width: '160px',
                cell: (row: Email) => (
                  <div className="d-flex justify-content-between w-100 align-items-center">
                    <div>
                      {row.bloqueado
                        ? t('pages:emails.blocked')
                        : t('pages:emails.active')}
                    </div>
                    <div className="p-1">
                      <TableIconButton
                        onClick={() => changeEmailLock(row.id, row.bloqueado)}
                        disabled={updating}
                        tooltipConfig={{
                          id: `email${row.id}`,
                          content: row.bloqueado
                            ? t('pages:emails.activate')
                            : t('pages:emails.block'),
                          placement: 'right',
                        }}
                      >
                        {row.bloqueado ? (
                          <FaLockOpen size={13} />
                        ) : (
                          <FaLock size={13} />
                        )}
                      </TableIconButton>
                    </div>
                  </div>
                ),
              },
              {
                name: t('pages:emails.emailDescription'),
                width: '240px',
                selector: 'descricao',
                sortable: true,
              },
            ]}
            data={filteredEmails}
            progressPending={loading}
            selectableRowDisabled={() => updating}
            noDataComponent={<TableNoData text={t('pages:emails.noEmails')} />}
            progressComponent={<Loading />}
            contextComponent={
              <EmailsActions
                loading={updating}
                deleteEmail={() => deleteEmail()}
                selectedRows={selectedRowsInfo.selectedRows}
                selectedCount={selectedRowsInfo.selectedCount}
              />
            }
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('pages:emails.emailsPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
