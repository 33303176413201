import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Card, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

type AvailableProduct = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  descricao: string;
  valor: number;
  valorFormatado: string;
  categoria: string;
  expansao: number;
  expansaoMaxima: number;
  unidade: string;
};

type PromotionalProductProps = {
  products: AvailableProduct[];
  onClick: (idProduct: number, amount: number) => void;
};

const PromotionalProducts: React.FC<PromotionalProductProps> = ({
  products,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          idProduct: products[0].idProduto,
        }}
        onSubmit={values => onClick(values.idProduct, 1)}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Card.Body className="fieldset">
              <FormSubtitle
                subTitle={t('pages:additionalServices.specialProducts')}
              />

              <Form.Group as={Row} controlId="promotionalProduct">
                <Form.Label column sm={2}>
                  {t('common:product')}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    name="idProduct"
                    onChange={props.handleChange}
                    disabled={props.isSubmitting}
                  >
                    {products.map(product => (
                      <option key={product.idProduto} value={product.idProduto}>
                        {product.produto} por {product.valorFormatado}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group
                className="mt-4"
                as={Row}
                controlId="promotional.submit"
              >
                <Form.Label column sm={2} />
                <Col sm={10}>
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:hire')}
                  </PanelButton>
                </Col>
              </Form.Group>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PromotionalProducts;
