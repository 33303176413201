import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { MdCheck, MdClose } from 'react-icons/md';
import { FaCloud } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import PanelDropdownButton from 'components/PanelDropdownButton';
import TableNoData from 'components/TableNoData';
import TableIconButton from 'components/TableIconButton';
import Loading from 'components/Loading';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import useCronJobs from 'hooks/useCronJobs';

import CrontabActions from './components/CrontabActions';

import { Container } from './styles';

type StatusFilter = {
  id: number;
  key: string;
  text: string;
};

const List: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [updating, setUpdating] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);

  const { cronJobs, isLoading, isError, refetch } = useCronJobs(idSite);

  type CronJob = typeof cronJobs[number];

  type SelectedRowsInfo = {
    selectedCount: number;
    selectedRows: CronJob[];
  };

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [filterText, setFilterText] = useState('');

  const [statusFilter, setStatusFilter] = useState<StatusFilter>({
    id: 1,
    key: 'ALL',
    text: t('pages:crontab.filter.all'),
  });

  let filteredCronJobs = filter(
    cronJobs,
    ['content', 'mailTo', 'description'],
    filterText,
  );

  filteredCronJobs = filteredCronJobs.filter(cronJob => {
    switch (statusFilter.key) {
      case 'ACTIVATED':
        return cronJob.active;
      case 'DEACTIVATED':
        return !cronJob.active;
      default:
        return cronJob;
    }
  });

  async function activate(idCronJob?: number) {
    try {
      setUpdating(true);

      const idsCronJobs = selectedRowsInfo.selectedRows.map(
        ({ idCronJob: selectedCronJobId }) => selectedCronJobId,
      );

      await api.post(`painel/v1/site/${idSite}/cron-job/ativacao`, {
        idsCronJobs: idCronJob ? [idCronJob] : idsCronJobs,
      });

      await refetch();

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:crontab.activateCommandSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:crontab.activateCommandFailure'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function deactivate(idCronJob?: number) {
    try {
      setUpdating(true);

      const idsCronJobs = selectedRowsInfo.selectedRows.map(
        ({ idCronJob: selectedCronJobId }) => selectedCronJobId,
      );

      await api.post(`painel/v1/site/${idSite}/cron-job/desativacao`, {
        idsCronJobs: idCronJob ? [idCronJob] : idsCronJobs,
      });

      await refetch();

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:crontab.deactivateCommandSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:crontab.deactivateCommandFailure'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  async function deleteCronJob() {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('pages:crontab.removeConfirmWarning'),
        text: t('pages:crontab.removeConfirmExplanation'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      const hasActiveCronJobsSelected = selectedRowsInfo.selectedRows.some(
        cronJob => cronJob.active,
      );

      if (hasActiveCronJobsSelected) {
        swal.fire({
          title: t('pages:crontab.removeCommandFailure'),
          html: t('pages:crontab.removeCommandWarning'),
        });

        return;
      }

      setUpdating(true);

      const idsCronJobs = selectedRowsInfo.selectedRows.map(
        ({ idCronJob: selectedCronJobId }) => selectedCronJobId,
      );

      await api.delete(`painel/v1/site/${idSite}/cron-job`, {
        params: { idsCronJobs },
      });

      await refetch();

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:crontab.removeCommandSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:crontab.removeCommandFailure'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  const statusFilterOptions: StatusFilter[] = [
    {
      id: 1,
      key: 'ALL',
      text: t('pages:crontab.filter.all'),
    },
    {
      id: 2,
      key: 'ACTIVATED',
      text: t('pages:crontab.filter.activated'),
    },
    {
      id: 3,
      key: 'DEACTIVATED',
      text: t('pages:crontab.filter.deactivated'),
    },
  ];

  if (isError) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title={t('pages:crontab.title')}
          description={t('pages:crontab.description')}
          directHelpLink="https://ajuda.hostnet.com.br/crontab/"
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/cloud/crontab/novo" size="sm">
              {t('pages:crontab.newCommand')}
            </TableButton>
          </div>
          <div className="d-flex">
            <PanelDropdownButton
              title={statusFilter.text}
              className="mr-2 mb-1"
            >
              {statusFilterOptions.map(statusFilterOption => (
                <Dropdown.Item
                  key={statusFilterOption.id}
                  onSelect={() => setStatusFilter(statusFilterOption)}
                  eventKey={String(statusFilterOption.id)}
                >
                  {statusFilterOption.text}
                </Dropdown.Item>
              ))}
            </PanelDropdownButton>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            selectableRows
            highlightOnHover
            pagination
            columns={[
              {
                name: t('pages:crontab.command'),
                selector: 'content',
                grow: 4,
                cell: (row: CronJob) => {
                  return <>{row.content}</>;
                },
              },
              {
                name: t('pages:crontab.status'),
                selector: 'active',
                minWidth: '320',
                cell: (row: CronJob) => {
                  return (
                    <div className="w-100 d-block d-lg-flex align-items-center text-center justify-content-between p-1">
                      <div>{row.active ? 'Ativo' : 'Inativo'}</div>
                      {row.active ? (
                        <TableIconButton
                          variant="outline-primary"
                          size="sm"
                          disabled={updating}
                          onClick={() => deactivate(row.idCronJob)}
                          tooltipConfig={{
                            id: 'deactivate-tooltip',
                            placement: 'right',
                            content: t('common:deactivate'),
                          }}
                        >
                          <MdClose size={16} />
                        </TableIconButton>
                      ) : (
                        <TableIconButton
                          variant="outline-primary"
                          size="sm"
                          disabled={updating}
                          onClick={() => activate(row.idCronJob)}
                          tooltipConfig={{
                            id: 'activate-tooltip',
                            placement: 'right',
                            content: t('common:activate'),
                          }}
                        >
                          <MdCheck size={16} />
                        </TableIconButton>
                      )}
                    </div>
                  );
                },
              },
              {
                name: t('pages:crontab.email'),
                selector: 'mailTo',
              },
              {
                name: t('pages:crontab.explanation'),
                selector: 'description',
              },
            ]}
            data={filteredCronJobs}
            clearSelectedRows={toggleCleared}
            selectableRowDisabled={() => updating}
            progressPending={isLoading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <CrontabActions
                loading={updating}
                activate={() => activate()}
                deactivate={() => deactivate()}
                deleteCronJob={() => deleteCronJob()}
                selectedRows={selectedRowsInfo.selectedRows}
                selectedCount={selectedRowsInfo.selectedCount}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
