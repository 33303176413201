import React from 'react';

import { AccordionLink, ChevronRightIcon } from './styles';

interface SideMenuAccordionLinkProps {
  text: string;
  rel?: string;
  target?: string;
  to:
    | string
    | {
        pathname: string;
        state: { option: number };
      };
}

const SideMenuAccordionLink: React.FC<SideMenuAccordionLinkProps> = ({
  text,
  to,
  ...props
}) => {
  return (
    <AccordionLink to={to} {...props}>
      <ChevronRightIcon />
      {text}
    </AccordionLink>
  );
};

export default SideMenuAccordionLink;
