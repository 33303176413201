import { useQuery } from 'react-query';

import api from 'services/api';

type DomainItemResponse = {
  id: number;
  idContato: number;
  dominio: string;
  promocao: 'TRUE' | 'FALSE';
  locked: 'TRUE' | 'FALSE';
  expiracao: string;
  status: string;
  contatoEmail: string;
  periodoRenovacao: boolean;
  periodoRecuperacao: boolean;
  renovacaoDisponivel: boolean;
};

type DomainsResponse = {
  data: DomainItemResponse[];
};

type InternationalDomain = {
  id: number;
  idContato: number;
  dominio: string;
  promocao: boolean;
  locked: boolean;
  expiracao: string;
  status: string;
  contatoEmail: string;
  periodoRenovacao: boolean;
  periodoRecuperacao: boolean;
  renovacaoDisponivel: boolean;
};

const initialData: InternationalDomain[] = [];

function formatDomain(domain: DomainItemResponse): InternationalDomain {
  const { promocao, locked, ...domainRest } = domain;

  return {
    promocao: promocao === 'TRUE',
    locked: locked === 'TRUE',
    ...domainRest,
  };
}

async function loadInternationalDomains(status: string[]) {
  const { data } = await api.get<DomainsResponse>(`enom/v1/dominio`, {
    params: {
      status,
    },
  });

  const domains = data.data.map(formatDomain);

  return domains;
}

export default function useInternationalDomains(
  status = ['ATIVO', 'CONGELADO', 'ERRO'],
) {
  const { data, isError, isLoading, isFetching } = useQuery<
    InternationalDomain[]
  >(['internationalDomains', status], () => loadInternationalDomains(status), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    placeholderData: initialData,
    retry: false,
  });

  const domains = data || initialData;

  return { domains, error: isError, loading: isLoading, fetching: isFetching };
}
