import styled from 'styled-components';

import { Card } from 'react-bootstrap';

export const CardForm = styled(Card)`
  legend {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 0;
  }

  legend + p {
    font-size: 0.9rem;
  }

  label.required:after {
    content: ' *';
    color: red;
  }

  label.form-label {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px;
    border-radius: 2px;

    &:focus {
      border: 1px solid var(--color-blue);
    }

    &.is-invalid {
      &:focus {
        border-color: #dc3545;
      }
    }
  }

  .input-group-text {
    font-size: 14px;
  }

  .dropdown-toggle {
    font-size: 14px;
  }
`;
