import styled from 'styled-components';

export const Container = styled.div`
  &.prioridade-Urgente,
  &.prioridade-Alta {
    .card {
      border: 1px solid var(--color-red);
    }
  }

  .notice-content {
    white-space: pre-wrap;
  }
`;

export const StyledLink = styled.a`
  font-size: 11px;
  padding: 8px 14px;
  border-radius: 2px;

  transition: all ease-in 0.3s;

  &.btn-primary {
    color: #fff;
    border-color: var(--color-blue);
    background-color: var(--color-blue);

    &:hover {
      border-color: var(--color-azure-blue);
      background-color: var(--color-azure-blue);
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }
`;
