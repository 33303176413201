import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableNoDataProps {
  text?: string;
}

const TableNoData: React.FC<TableNoDataProps> = ({ text = '' }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4 w-100 border-top">
      <p className="text-muted text-center m-0">
        {text || t('common:noDataToList')}
      </p>
    </div>
  );
};

export default TableNoData;
