import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Form, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Formik, Field } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import api from 'services/api';
import swal from 'services/swal';
import history from 'services/history';

import useBrDomainContacts from 'hooks/useBrDomainsContacts';

import HelpContentStep1 from './components/HelpContentStep1';
import HelpContentStep2 from './components/HelpContentStep2';

import { Container } from './styles';

interface Step1FormValues {
  type: 'FISICA' | 'JURIDICA';
  document: string;
}

interface Step2FormValues {
  idContact: number;
  items: string[];
  document: string;
}

interface EntityResponse {
  data: {
    id: string;
    isHostnet: boolean;
    domains: string[];
    clID: string;
    contact: string;
  }[];
}

interface EntityContactResponse {
  data: {
    id: string;
    isHostnet: boolean;
  }[];
}

interface TransferResponse {
  data: {
    email: string;
  };
}

const Transfer: React.FC = () => {
  const { t } = useTranslation();

  const { loading, contacts } = useBrDomainContacts();

  const [formStep, setFormStep] = useState(1);

  const [document, setDocument] = useState('');
  const [domains, setDomains] = useState<string[]>([]);

  const hostnetContacts = contacts.filter(contact => contact.idProvider === 3);

  async function submitStep1Form(values: Step1FormValues) {
    try {
      const entityResponse = await api.get<EntityResponse>(
        `/registrobr/v1/entidade-registrobr`,
        {
          params: {
            cpfCnpj: values.document,
          },
        },
      );

      if (entityResponse.data.data.length === 0) {
        throw new Error(t('pages:brDomainTransfer.errors.error1'));
      }

      const entityData = entityResponse.data.data[0];

      if (!entityData.isHostnet) {
        throw new Error(t('pages:brDomainTransfer.errors.error2'));
      }

      const entityContactResponse = await api.get<EntityContactResponse>(
        `/registrobr/v1/contato-registrobr`,
        {
          params: {
            contato: entityData.contact,
          },
        },
      );

      if (entityContactResponse.data.data.length === 0) {
        throw new Error(t('pages:brDomainTransfer.errors.error3'));
      }

      setDocument(entityData.id);
      setDomains(entityData.domains);

      setFormStep(2);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  async function submitStep2Form(values: Step2FormValues) {
    try {
      const transferResponse = await api.post<TransferResponse>(
        `/registrobr/v1/transferencia`,
        {
          idContato: values.idContact,
          cpfCnpj: values.document,
          dominios: values.items,
          url: 'painel4',
        },
      );

      swal.fire({
        icon: 'success',
        title: '',
        html: t('pages:brDomainTransfer.success', {
          email: transferResponse.data.data.email,
        }),
      });

      history.push(`/`);
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (hostnetContacts.length === 0) {
    return (
      <Container>
        <PageTitle
          title={t('titles:domainRegistry.title')}
          description={t('titles:domainRegistry.description')}
          icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
        />
        <FormWrapper>
          <FormHeader
            title={t('pages:brDomainTransfer.title')}
            description={t('pages:brDomainTransfer.description')}
          />

          <Card.Body className="fieldset">
            <div>{t('pages:brDomainTransfer.noContacts')}</div>
            <div className="mt-3">
              <PanelButton
                forwardedAs={Link}
                to="/registro/contato-br-adicionar"
              >
                {t('pages:brDomainTransfer.createContact')}
              </PanelButton>
            </div>
          </Card.Body>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      {formStep === 1 && (
        <FormWrapper>
          <FormHeader
            startOpen
            helpContent={<HelpContentStep1 />}
            title={t('pages:brDomainTransfer.title')}
            description={t('pages:brDomainTransfer.description')}
            helpLinks={[
              {
                text: t('pages:brDomainTransfer.brTransferLink'),
                url:
                  'https://www.hostnet.com.br/info/transferencia-de-entidade-da-registro-br-para-a-hostnet/',
              },
            ]}
          />

          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              type: 'FISICA',
              document: '',
            }}
            validationSchema={Yup.object().shape({
              document: Yup.string()
                .when('type', {
                  is: 'JURIDICA',
                  then: Yup.string().test(
                    'validDocument',
                    t('validations:invalidFormat'),
                    val => !!val && val.replace(/[^\d]/g, '').length === 14,
                  ),
                  otherwise: Yup.string().test(
                    'validDocument',
                    t('validations:invalidFormat'),
                    val => !!val && val.replace(/[^\d]/g, '').length === 11,
                  ),
                })
                .required(t('validations:requiredField')),
            })}
            onSubmit={submitStep1Form}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <FormSubtitle
                    subTitle={t('pages:brDomainEntitiesNew.legend')}
                  />

                  <Form.Group as={Row} controlId="form-type">
                    <Form.Label column sm={2}>
                      {t('common:type')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        id="natural-contact"
                        type="radio"
                        label={t('common:naturalPerson')}
                        disabled={props.isSubmitting}
                        onChange={() => props.setFieldValue('type', 'FISICA')}
                        checked={props.values.type === 'FISICA'}
                      />
                      <Form.Check
                        inline
                        custom
                        id="legal-contact"
                        type="radio"
                        label={t('common:legalPerson')}
                        disabled={props.isSubmitting}
                        onChange={() => props.setFieldValue('type', 'JURIDICA')}
                        checked={props.values.type === 'JURIDICA'}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="contact.document">
                    <Form.Label column sm={2} className="required">
                      {props.values.type === 'JURIDICA'
                        ? t('common:cnpj')
                        : t('common:cpf')}
                    </Form.Label>
                    <Col sm={10}>
                      <InputMask
                        name="document"
                        mask={
                          props.values.type === 'FISICA'
                            ? '999.999.999-99'
                            : '99.999.999/9999-99'
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        disabled={props.isSubmitting}
                        className={`form-control ${
                          !!props.touched.document &&
                          !!props.errors.document &&
                          'is-invalid'
                        }`}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:brDomainEntitiesNew.documentTip')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.document}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/registro/transferencia"
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}

      {formStep === 2 && (
        <FormWrapper>
          <FormHeader
            startOpen
            helpContent={<HelpContentStep2 />}
            title={t('pages:brDomainTransfer.title')}
            description={t('pages:brDomainTransfer.description')}
          />
          <Formik
            enableReinitialize
            validateOnMount
            initialValues={{
              idContact: hostnetContacts.length > 0 ? hostnetContacts[0].id : 0,
              document,
              items: [],
            }}
            onSubmit={submitStep2Form}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <FormSubtitle
                    subTitle={t('pages:brDomainTransfer.selectAContact')}
                  />

                  <Form.Group as={Row} controlId="brDomainTransfer.contact">
                    <Form.Label column sm={2} className="required">
                      {t('common:contact')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="idContact"
                        value={props.values.idContact}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      >
                        {hostnetContacts.map(contact => (
                          <option key={contact.id} value={contact.id}>
                            {`${contact.contact} - ${contact.name} - ${contact.email}`}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Text className="text-muted">
                        {t('pages:brDomainTransfer.contactTip')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <div className="mt-5" />

                  <FormSubtitle
                    subTitle={t('pages:brDomainTransfer.itemsToTransfer')}
                  />
                  <Card.Body className="p-0">
                    <ListGroup variant="flush">
                      <ListGroup.Item className="p-2">
                        <Row>
                          <Col sm="6">
                            <label htmlFor="entity-document">
                              <Field
                                type="checkbox"
                                id="entity-document"
                                value={document}
                                disabled
                                checked
                              />
                              <span className="ml-2">{document}</span>
                            </label>
                          </Col>
                        </Row>
                      </ListGroup.Item>

                      {domains.map(option => (
                        <ListGroup.Item key={option} className="p-2">
                          <Row>
                            <Col sm="6">
                              <label htmlFor={option}>
                                <Field
                                  type="checkbox"
                                  name="items"
                                  id={option}
                                  value={option}
                                />
                                <span className="ml-2">{option}</span>
                              </label>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                  <PanelButton
                    variant="secondary"
                    forwardedAs={Link}
                    to="/registro/transferencia"
                    disabled={props.isSubmitting}
                  >
                    {t('common:back')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}
    </Container>
  );
};

export default Transfer;
