import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';

import useBrDomainEntities from 'hooks/useBrDomainsEntities';

import filter from 'utils/filter';

import BrEntityActions from './components/BrEntityActions';

import { Container } from './styles';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { entities, isLoading } = useBrDomainEntities();

  const [brEntityFilterText, setBrEntityFilterText] = useState('');

  const [selectedBrEntitiesRowsInfo, setSelectedBrEntitiesRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof entities;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const filteredBrEntities =
    brEntityFilterText.length > 0
      ? filter(entities, ['cpfCnpj', 'contact'], brEntityFilterText)
      : entities;

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:brDomainEntities.title')}
          description={t('pages:brDomainEntities.description')}
          helpContent={t('pages:brDomainEntities.pendingEntitiesTip')}
          directHelpLink="https://ajuda.hostnet.com.br/verificando-dados-da-entidade-br/"
        />
        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/registro/entidade-br-adicionar"
            >
              {t('pages:brDomainEntities.newEntity')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setBrEntityFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedBrEntitiesRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:entity'),
                selector: 'cpfCnpj',
                sortable: true,
                width: '200px',
              },
              {
                name: t('common:contact'),
                selector: 'contact',
                sortable: true,
                width: '200px',
              },
              {
                name: t('common:status'),
                selector: 'status',
                sortable: true,
              },
            ]}
            data={filteredBrEntities}
            progressPending={isLoading}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedBrEntitiesRowsInfo(rows)}
            selectableRowDisabled={row => row.status !== 'ATIVO'}
            contextComponent={
              <BrEntityActions
                selectedRows={selectedBrEntitiesRowsInfo.selectedRows}
                selectedCount={selectedBrEntitiesRowsInfo.selectedCount}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
