import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';

import PanelCard from '../PanelCard';

import {
  Container,
  IconContainer,
  Name,
  SettingsIcon,
  DollarIcon,
  HelpCircleIcon,
  Option,
  EnvelopeIcon,
  GlobeAmericasIcon,
  DatabaseIcon,
} from './styles';

const QuickAccess: React.FC = () => {
  const { resources } = useSelector((state: StoreState) => state.site);

  const optionsPublic = [
    {
      id: 1,
      name: 'Conheça o Painel',
      icon: <SettingsIcon />,
      href: 'https://ajuda.hostnet.com.br/painel-de-controle/',
      color: 'violet',
    },
    {
      id: 2,
      name: 'Segunda via de boleto',
      icon: <DollarIcon />,
      to: '/financeiro/historico-boletos',
      color: 'green',
    },
    {
      id: 3,
      name: 'Central de ajuda',
      icon: <HelpCircleIcon />,
      href: 'https://ajuda.hostnet.com.br/',
      color: 'violet',
    },
    {
      id: 6,
      name: 'Registrar domínio',
      icon: <GlobeAmericasIcon />,
      to: `/registro/dominio-registrar`,
      color: 'blue',
    },
    {
      id: 8,
      name: 'Descontos',
      icon: <DollarIcon />,
      to: '/financeiro/extrato',
      color: 'green',
    },
  ];

  const optionsEmail = [
    {
      id: 4,
      name: 'Emails',
      icon: <EnvelopeIcon />,
      to: '/emails',
      color: 'blue',
    },
    {
      id: 7,
      name: 'Configurando email',
      icon: <HelpCircleIcon />,
      href: 'https://www.hostnet.com.br/config.email/',
      color: 'violet',
    },
  ];

  const optionsMySQL = [
    {
      id: 5,
      name: 'Banco de dados',
      icon: <DatabaseIcon />,
      to: '/banco-de-dados-mysql',
      color: 'blue',
    },
  ];

  let options = optionsPublic;

  if (resources.espacoEmailTotalGB > 0) {
    options = [...options, ...optionsEmail];
  }

  if (
    resources.mySqlTotalQtd > 0 ||
    (resources.planoIlimitado && resources.mySqlTotalQtd === 0)
  ) {
    options = [...options, ...optionsMySQL];
  }

  options.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Container>
      <PanelCard>
        <Card.Header>
          <h5>Acesso rápido</h5>
        </Card.Header>
        <Card.Body>
          {options.map(option => (
            <Option key={option.id} className="mb-3">
              <IconContainer className={option.color}>
                {option.icon}
              </IconContainer>
              {option.to && (
                <Link to={option.to}>
                  <Name>{option.name}</Name>
                </Link>
              )}
              {option.href && (
                <a href={option.href} target="_blank" rel="noopener noreferrer">
                  {option.name}
                </a>
              )}
            </Option>
          ))}
        </Card.Body>
      </PanelCard>
    </Container>
  );
};

export default QuickAccess;
