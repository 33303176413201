import React from 'react';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface UltramailDomainsActions {
  selectedCount: number;
  selectedRows: {
    dominio: string;
    ativo: boolean;
  }[];
  handleActivate: (domain: string) => void;
  handleDeactivate: (domain: string) => void;
  loading: boolean;
}

const UltramailDomainActions: React.FC<UltramailDomainsActions> = ({
  selectedCount,
  selectedRows,
  handleActivate,
  handleDeactivate,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && selectedRows[0].ativo && (
        <TableButton
          className="mr-2"
          variant="danger"
          size="sm"
          disabled={loading}
          onClick={() => handleDeactivate(selectedRows[0].dominio)}
        >
          {t('common:deactivate')}
        </TableButton>
      )}

      {selectedCount === 1 && !selectedRows[0].ativo && (
        <TableButton
          className="mr-2"
          size="sm"
          disabled={loading}
          onClick={() => handleActivate(selectedRows[0].dominio)}
        >
          {t('common:activate')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default UltramailDomainActions;
