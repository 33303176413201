import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import TableButton from 'components/TableButton';

import useBrDomains from 'hooks/useBrDomains';
import useInternationalDomains from 'hooks/useInternationalDomains';
import useRioDomains from 'hooks/useRioDomains';

import filter from 'utils/filter';

import BrDomainActions from './components/BrDomainActions';
import InternationalDomainActions from './components/InternationalDomainActions';
import RioDomainActions from './components/RioDomainActions';

import { Container } from './styles';

const Domains: React.FC = () => {
  const { t } = useTranslation();

  const { domains: brDomains, isFetching: fetchingBr } = useBrDomains();

  const {
    domains: internationalDomains,
    fetching: fetchingInternational,
  } = useInternationalDomains();

  const { domains: rioDomains, fetching: fetchingRio } = useRioDomains();

  const [
    selectedInternationalDomainsRowsInfo,
    setSelectedInternationalDomainsRowsInfo,
  ] = useState<{
    selectedCount: number;
    selectedRows: typeof internationalDomains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [selectedBrDomainsRowsInfo, setSelectedBrDomainsRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof brDomains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [selectedRioDomainsRowsInfo, setSelectedRioDomainsRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof rioDomains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [
    internationalDomainFilterText,
    setInternationalDomainFilterText,
  ] = useState('');
  const [brDomainFilterText, setBrDomainFilterText] = useState('');
  const [rioDomainFilterText, setRioDomainFilterText] = useState('');

  const [
    filteredInternationalDomains,
    setFilteredInternationalDomains,
  ] = useState(internationalDomains);
  const [filteredBrDomains, setFilteredBrDomains] = useState(brDomains);
  const [filteredRioDomains, setFilteredRioDomains] = useState(rioDomains);

  useEffect(() => {
    const validStatus = ['ATIVO', 'CONGELADO', 'ERRO'];

    const validStatusInternationalDomains = internationalDomains.filter(
      internationalDomain => validStatus.includes(internationalDomain.status),
    );

    setFilteredInternationalDomains(
      filter(
        validStatusInternationalDomains,
        ['dominio'],
        internationalDomainFilterText,
      ),
    );
  }, [internationalDomains, internationalDomainFilterText]);

  useEffect(() => {
    setFilteredBrDomains(filter(brDomains, ['domain'], brDomainFilterText));
  }, [brDomainFilterText, brDomains]);

  useEffect(() => {
    setFilteredRioDomains(filter(rioDomains, ['dominio'], rioDomainFilterText));
  }, [rioDomains, rioDomainFilterText]);

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.internationalDomains')}
          description={t('pages:domains.registeredInternationalDomains')}
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/registro/dns-proprio-internacional"
            >
              {t('pages:domains.manageOwnDns')}
            </TableButton>
          </div>
          <div>
            <FilterInput
              onChange={value => setInternationalDomainFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedInternationalDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'dominio',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:status'),
                selector: 'status',
                cell: row => {
                  return (
                    row.status.charAt(0).toUpperCase() +
                    row.status.slice(1).toLowerCase()
                  );
                },
                sortable: true,
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:contact'),
                selector: 'idContato',
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:expiration'),
                selector: 'expiracao',
                sortable: true,
                cell: row =>
                  row.expiracao === ''
                    ? ''
                    : format(parseISO(row.expiracao), 'dd/MM/yyyy'),
                maxWidth: '15%',
                minWidth: '140px',
              },
            ]}
            data={filteredInternationalDomains}
            progressPending={fetchingInternational}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <InternationalDomainActions
                selectedCount={
                  selectedInternationalDomainsRowsInfo.selectedCount
                }
                selectedRows={selectedInternationalDomainsRowsInfo.selectedRows}
              />
            }
            onSelectedRowsChange={rows =>
              setSelectedInternationalDomainsRowsInfo(rows)
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.brDomains')}
          description={t('pages:domains.registeredBrDomains')}
        />

        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setBrDomainFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedBrDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'domain',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:status'),
                selector: 'status',
                cell: row =>
                  row.status === 'ATIVO'
                    ? t('common:active')
                    : t('common:inactive'),
                sortable: true,
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:contact'),
                selector: 'relatedContact',
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:expiration'),
                selector: 'expiracao',
                cell: row => format(parseISO(row.expiration), 'dd/MM/yyyy'),
                maxWidth: '15%',
                minWidth: '140px',
              },
            ]}
            data={filteredBrDomains}
            progressPending={fetchingBr}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <BrDomainActions
                selectedCount={selectedBrDomainsRowsInfo.selectedCount}
                selectedRows={selectedBrDomainsRowsInfo.selectedRows}
              />
            }
            onSelectedRowsChange={rows => setSelectedBrDomainsRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domains.rioDomains')}
          description={t('pages:domains.registeredRioDomains')}
        />

        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setRioDomainFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRioDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'dominio',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:status'),
                selector: 'status',
                cell: row =>
                  row.status === 'ATIVO'
                    ? t('common:active')
                    : t('common:inactive'),
                sortable: true,
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:contact'),
                selector: 'idContato',
                maxWidth: '15%',
                minWidth: '100px',
              },
              {
                name: t('common:expiration'),
                selector: 'expiracao',
                cell: row => format(parseISO(row.expiracao), 'dd/MM/yyyy'),
                maxWidth: '15%',
                minWidth: '140px',
              },
            ]}
            data={filteredRioDomains}
            progressPending={fetchingRio}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <RioDomainActions
                selectedCount={selectedRioDomainsRowsInfo.selectedCount}
                selectedRows={selectedRioDomainsRowsInfo.selectedRows}
              />
            }
            onSelectedRowsChange={rows => setSelectedRioDomainsRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Domains;
