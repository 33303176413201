import { store } from 'store';
import { logout } from 'store/modules/auth/actions';

import api from 'services/api';

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  },
);
