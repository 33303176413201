import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  addMonths,
  format,
  lastDayOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import { FaEnvelope, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import ptBr from 'date-fns/locale/pt-BR/index';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import TableNoData from 'components/TableNoData';
import PanelDropdownButton from 'components/PanelDropdownButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import useUltramail from 'hooks/useUltramail';
import useRatchetEmail from 'hooks/useRatchetEmail';
import useRatchetEmailReported from 'hooks/useRatchetEmailReported';

import { Container } from './styles';

interface EmailReported {
  email: string;
  reporter: string;
  date: string;
  total: number;
}

type RatchetEmailStatistic = {
  email: string;
  totalEnvios: number;
  totalRecebimentos: number;
  totalFeedBackLoop: number;
  totalBounces: number;
  limiteEnvio: number;
  limiteRecebimento: number;
  periodo: 'dia' | 'mes';
  dtInput?: string;
  taxaPunicao?: number;
  limitePunicao?: number;
  totalPunicoes?: number;
};

const Reported: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const [selectedSite, setSelectedSite] = useState(site);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [period, setPeriod] = useState<'DAY' | 'MONTH'>('MONTH');

  const disableNextDate = verifyIfShouldDisableNextDate(selectedDate);

  const dateFormat =
    period === 'MONTH' ? "MMMM 'de' yyyy" : "dd 'de' MMMM yyyy";

  const formattedDate = format(selectedDate, dateFormat, {
    locale: ptBr,
  });

  const initialDate =
    period === 'MONTH'
      ? format(selectedDate, 'yyyy-MM-01')
      : format(selectedDate, 'yyyy-MM-dd');

  const finalDate =
    period === 'MONTH'
      ? format(lastDayOfMonth(selectedDate), 'yyyy-MM-dd')
      : format(selectedDate, 'yyyy-MM-dd');

  const { ratchetEmails, isFetching } = useRatchetEmail(
    selectedSite,
    initialDate,
    finalDate,
  );

  const { ratchetEmailReported } = useRatchetEmailReported(
    selectedSite,
    initialDate,
    finalDate,
  );

  const { ultramail, loading: loadingUltramail, isFetched } = useUltramail(
    idSite,
  );

  useEffect(() => {
    setSelectedSite(site);
  }, [site]);

  const sitesUltramail = ultramail.quotaDetalhada.filter(
    siteUltramail => siteUltramail.ativo && siteUltramail.mbox,
  );

  function filterAccountDataByPeriod() {
    if (period === 'MONTH') {
      return ratchetEmails.estatisticas.filter(
        emailStatistic => emailStatistic.periodo === 'mes',
      );
    }

    return ratchetEmails.estatisticas.filter(emailStatistic => {
      if (emailStatistic.dtInput) {
        const currentSelectedDate = new Date(
          format(selectedDate, 'yyyy-MM-dd'),
        );

        return (
          new Date(emailStatistic.dtInput).getTime() ===
          currentSelectedDate.getTime()
        );
      }

      return false;
    });
  }

  function verifyIfShouldDisableNextDate(ratchetDate: Date) {
    let nextDate = ratchetDate;

    if (period === 'MONTH') {
      nextDate = addMonths(ratchetDate, 1);
    } else {
      nextDate = addDays(ratchetDate, 1);
    }

    return nextDate.getTime() > new Date().getTime();
  }

  if (!ultramail.possuiSiteComUltramailAtivo && isFetched) {
    return (
      <Container>
        <PageTitle
          title={t('pages:emails.title')}
          description={t('pages:emails.description')}
          icon={<FaEnvelope color="#FFFFFF" size={24} />}
        />
        <TableWrapper>
          <TableHeader
            title="Estatísticas, envios e limites"
            description="Bounce são os destinatários de emails que retornaram erro no envio."
          />
          <div className="p-3">
            Não há sites com o Ultramail ativo. Para visualizar as estatísticas
            de e-mail é preciso ter o Ultramail ativo em pelo menos 1 de seus
            sites.
          </div>
        </TableWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title="Envios denunciados"
          description="Relatório contendo a quantidade e os emails que foram denunciados como SPAM, gerando denunciados."
          directHelpLink="https://ajuda.hostnet.com.br/recipient-is-known-to-bounce/"
        />
        <TableSubHeader>
          <div className="d-flex align-items-center">
            <div className="table-section">Total por site</div>
          </div>

          <div className="d-flex align-items-center">
            <PanelDropdownButton
              size="sm"
              disabled={loadingUltramail || isFetching}
              title={loadingUltramail ? 'Carregando ...' : selectedSite}
            >
              {sitesUltramail.map(ultramailSite => {
                return (
                  <Dropdown.Item
                    key={`${ultramailSite.site}ultramail`}
                    onSelect={() => setSelectedSite(ultramailSite.site)}
                  >
                    {ultramailSite.site}
                  </Dropdown.Item>
                );
              })}
            </PanelDropdownButton>

            <PanelDropdownButton
              size="sm"
              disabled={isFetching}
              title={
                period === 'MONTH' ? 'Relatório mensal' : 'Relatório diário'
              }
            >
              <Dropdown.Item onSelect={() => setPeriod('MONTH')}>
                Relatório mensal
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setPeriod('DAY')}>
                Relatório diário
              </Dropdown.Item>
            </PanelDropdownButton>

            <TableButton
              variant="secondary"
              className="secondary"
              disabled={isFetching}
              onClick={() => {
                if (period === 'DAY') {
                  setSelectedDate(subDays(selectedDate, 1));
                } else {
                  setSelectedDate(subMonths(selectedDate, 1));
                }
              }}
            >
              <FaChevronLeft />
            </TableButton>
            <span className="selected-date mr-2">{formattedDate}</span>
            <TableButton
              variant="secondary"
              className="secondary"
              disabled={isFetching || disableNextDate}
              onClick={() => {
                if (period === 'DAY') {
                  setSelectedDate(addDays(selectedDate, 1));
                } else {
                  setSelectedDate(addMonths(selectedDate, 1));
                }
              }}
            >
              <FaChevronRight />
            </TableButton>
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            subHeaderWrap
            progressPending={isFetching}
            columns={[
              {
                name: 'Domínio',
                selector: 'domain',
                minWidth: '180px',
              },
              {
                name: 'Enviados',
                selector: 'totalSent',
              },
              {
                name: 'Denunciados',
                selector: 'totalFeedBackLoop',
              },
            ]}
            data={[
              {
                domain: selectedSite,
                totalSent: ratchetEmails.totalEnvios,
                totalFeedBackLoop: ratchetEmails.totalFeedBackLoop,
              },
            ]}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>
      <TableWrapper>
        <TableHeader
          title="Por conta"
          description="Relatório contendo a quantidade e os emails que foram denunciados como SPAM"
        />

        <TableSubHeader>
          <div className="d-flex align-items-right" />
          <div className="d-flex align-items-right">
            <TableButton
              disabled={isFetching}
              onClick={() => {
                if (isFetched) {
                  const csvRows: string[] = [];
                  const data = filterAccountDataByPeriod();

                  csvRows.push(`"e-mails", "enviados", "denunciados"`);

                  data.forEach((row: RatchetEmailStatistic) => {
                    csvRows.push(
                      `"${row.email}", "${row.totalEnvios}", "${row.totalFeedBackLoop}"`,
                    );
                  });

                  const csvData = csvRows.join('\n');

                  const blob = new Blob([csvData], {
                    type: 'text/csv;charset=utf-8;',
                  });
                  const link = document.createElement('a');
                  if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute(
                      'download',
                      `denunciados-${format(selectedDate, 'yyyy-MM-dd')}.csv`,
                    );
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  } //
                }
              }}
            >
              Exportar lista
            </TableButton>
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={isFetching}
            columns={[
              {
                name: 'E-mail',
                selector: 'email',
                minWidth: '280px',
              },
              {
                name: 'Enviados',
                selector: 'totalEnvios',
              },
              {
                name: 'Denunciados',
                cell: row => row.totalFeedBackLoop,
              },
            ]}
            data={filterAccountDataByPeriod()}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Marcados como denunciados"
          description="Relatório contendo a quantidade e os emails que foram denunciados como SPAM"
        />
        {ratchetEmailReported.list.length > 0 ? (
          <TableSubHeader>
            <div className="d-flex align-items-right" />
            <div className="d-flex align-items-right">
              <TableButton
                disabled={isFetching}
                onClick={() => {
                  if (isFetched) {
                    const csvRows: string[] = [];
                    const data = ratchetEmailReported.list;

                    const headers = Object.keys(data[0]);
                    csvRows.push(headers.join(','));

                    data.forEach((row: EmailReported) => {
                      csvRows.push(
                        `"${row.email}","${row.reporter}","${row.date}","${row.total}"`,
                      );
                    });

                    const csvData = csvRows.join('\n');

                    const blob = new Blob([csvData], {
                      type: 'text/csv;charset=utf-8;',
                    });
                    const link = document.createElement('a');
                    if (link.download !== undefined) {
                      const url = URL.createObjectURL(blob);
                      link.setAttribute('href', url);
                      link.setAttribute(
                        'download',
                        `denunciados-${format(selectedDate, 'yyyy-MM-dd')}.csv`,
                      );
                      link.style.visibility = 'hidden';
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  }
                }}
              >
                Exportar lista
              </TableButton>
            </div>
          </TableSubHeader>
        ) : (
          ''
        )}

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={isFetching}
            columns={[
              {
                name: 'E-mail',
                selector: 'email',
                minWidth: '280px',
              },
              {
                name: 'Denunciante',
                cell: row => row.reporter,
              },
              {
                name: 'Denunciados',
                cell: row => row.total,
              },
              {
                name: 'Data',
                cell: row => row.date,
              },
            ]}
            data={ratchetEmailReported.list}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Reported;
