import { useQuery } from 'react-query';

import api from 'services/api';

type ExtensionItemResponse = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: boolean;
  tipo: string;
};

type ExtensionsResponse = {
  data: ExtensionItemResponse[];
};

type InternationalExtension = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: boolean;
  tipo: string;
};

const initialData: InternationalExtension[] = [];

async function loadInternationalDomainExtensions() {
  const { data } = await api.get<ExtensionsResponse>(`enom/v1/extensao`, {
    params: {
      'filter[tipo]': 'REGISTRO',
    },
  });

  const domains = data.data;

  return domains;
}

export default function useInternationalDomainExtensions() {
  const { data, ...rest } = useQuery<InternationalExtension[]>(
    'internationalDomainsExtensions',
    () => loadInternationalDomainExtensions(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const internationalExtensions = data || initialData;

  return {
    ...rest,
    internationalExtensions,
  };
}
