import React from 'react';

import { Container } from './styles';

const TableSubHeader: React.FC = ({ children }) => {
  return (
    <Container className="d-md-flex justify-content-between">
      {children}
    </Container>
  );
};

export default TableSubHeader;
