import basic from './basic';
import cloud from './cloud';
import extraSettings from './extraSettings';
import database from './database';
import email from './email';
import invoices from './invoices';
import webfacilConverted from './webfacilConverted';

export default {
  basic,
  cloud,
  extraSettings,
  database,
  email,
  invoices,
  webfacilConverted,
};
