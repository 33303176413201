import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface InternationalDomainActionsProps {
  selectedCount: number;
  selectedRows: { id: number }[];
}

const InternationalDomainActions: React.FC<InternationalDomainActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  const domainHashId = hashIds.encode(selectedRows[0].id);

  return (
    <TableActionsWrapper loading={false}>
      {selectedCount === 1 && (
        <TableButton
          forwardedAs={Link}
          to={`/registro/dominio-internacional-renovar/${domainHashId}`}
          className="mr-2"
        >
          {t('common:renew')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default InternationalDomainActions;
