type Option = {
  codigo: string;
  opcao: string;
};

type MenuElement = {
  element: React.ReactNode;
  requiredOptions: String[];
};

export function filterMenuOptions(
  userOptions: Option[],
  menuElements: MenuElement[],
) {
  const availableMenuElements = menuElements
    .map(menuElement => {
      const itemOptions = menuElement.requiredOptions;

      if (itemOptions.length === 0) {
        return menuElement.element;
      }

      if (
        userOptions.some(userOption => itemOptions.includes(userOption.codigo))
      ) {
        return menuElement.element;
      }

      return false;
    })
    .filter(menuItem => !!menuItem);

  return availableMenuElements;
}
