import React from 'react';
import { Card } from 'react-bootstrap';

import PanelButton from 'components/PanelButton';

import { Container } from './styles';

const BloquedAccess: React.FC = () => {
  function redirectHostnetId() {
    const currentLocation = window.location.origin;
    const returnUrl = encodeURIComponent(
      `/login/authorize?clientId=painel4&state=${currentLocation}`,
    );

    window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
  }

  return (
    <Container>
      <Card className="mt-5 card w-75 mx-auto">
        <Card.Body>
          <Card.Title>Painel bloqueado para acesso</Card.Title>
          <Card.Text>
            Verifique se existe alguma notificação em seu HelpDesk ou entre em
            contato com nosso SAC.
          </Card.Text>
          <a
            href="https://www.hostnet.com.br/atendimento-ao-cliente/"
            className="btn btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Ver canais de atendimento
          </a>
          <PanelButton variant="link ml-2" onClick={() => redirectHostnetId()}>
            Acessar com outra conta
          </PanelButton>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default BloquedAccess;
