import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaCogs } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

import { StoreState } from 'store/createStore';

import useMauticSms from 'hooks/useMauticSms';
import useMauticSmsLicenseKey from 'hooks/useMauticSmsLicenseKey';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';
import TableHeader from 'components/TableHeader';
import TableButton from 'components/TableButton';
import TableWrapper from 'components/TableWrapper';

import formatCurrency from 'utils/formatCurrency';

import api from 'services/api';
import swal from 'services/swal';

import LicenseKeyModal from './components/LicenseKeyModal';

type BuyProductResponse = {
  data: {
    url: string;
  };
};

const MauticSms: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [showKeyModal, setShowKeyModal] = useState(false);
  const [loadingPurchase, setLoadingPurchase] = useState(false);

  const { mauticSms, isLoading, refetch: refetchMauticSms } = useMauticSms(
    idSite,
  );
  const {
    mauticSmsLicenseKey,
    refetch: refetchLicenseKey,
  } = useMauticSmsLicenseKey(idSite);

  async function buyMauticSmsProduct(productId: number, free: boolean) {
    try {
      setLoadingPurchase(true);

      const response = await api.post<BuyProductResponse>(
        `painel/v1/site/${idSite}/mautic-sms`,
        {
          idProduto: productId,
        },
      );

      if (free) {
        swal.fire(
          'Pacote grátis adicionado.',
          'O pacote grátis foi adicionado a sua conta',
          'success',
        );

        refetchMauticSms();
        refetchLicenseKey();

        return;
      }

      window.location.href = response.data.data.url;
    } catch (err) {
      swal.fire(
        'Ação não concluída.',
        'Não foi possível concluir o pedido de compra',
      );
    } finally {
      setLoadingPurchase(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title="Mautic - SMS"
          startOpen={!mauticSms.contratado}
          description="Com este serviço da Hostnet, é possível integrar o aplicativo de automação de marketing Mautic com a infra-estrutura da Hostnet de envios de SMS."
          helpContent={
            <div>
              <p>
                Ao contratar, você terá acesso a uma chave de licença para
                adicionar no seu aplicativo Mautic que na Hostnet já vem
                instalado com um plugin exclusivo.
              </p>

              <b>Regras</b>

              <ul>
                <li>
                  A ativação é pré-paga, por isso, ao fazer a contratação será
                  necessário pagar a primeira mensalidade do plano escolhido.
                </li>
                <li>
                  Recomendamos a contratação no início do mês para melhor
                  aproveitamento do serviço.
                </li>
                <li>
                  O saldo de envios não é cumulativo, sendo assim, no último dia
                  de cada mês os seus créditos expiram e o saldo é zerado.
                </li>
                <li>
                  A recarga do saldo de envios será feita automaticamente todo
                  dia 1º de cada mês.
                </li>
              </ul>
            </div>
          }
        />

        {mauticSms.contratado && (
          <div>
            <Card.Body className="fieldset">
              <FormSubtitle subTitle={`${mauticSms.mes}/${mauticSms.ano}`} />
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Envios
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    plaintext
                    readOnly
                    value={mauticSms.quantidadeConsumida}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2}>
                  Total mensal
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    plaintext
                    readOnly
                    value={mauticSms.quantidadeMensal}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label column sm={2} />
                <Col sm={10}>
                  <Link to="/aplicativos/mautic-sms/consumo">
                    Ver consumo detalhado
                  </Link>
                </Col>
              </Form.Group>
            </Card.Body>
            <div className="border-top pt-2 pb-2 pl-3">
              <PanelButton
                type="submit"
                className="mr-1"
                onClick={() => setShowKeyModal(true)}
              >
                Ver chave de licença
              </PanelButton>
              <PanelButton
                onClick={() =>
                  window.open(
                    'https://apps.hostnet.com.br/instalar.licenca',
                    '_blank',
                  )
                }
              >
                Instalar licença SMS no Mautic
              </PanelButton>
            </div>
            <LicenseKeyModal
              close={() => setShowKeyModal(false)}
              show={showKeyModal}
              licenceKey={mauticSmsLicenseKey.licenca}
            />
          </div>
        )}
      </FormWrapper>

      <TableWrapper>
        <TableHeader
          title="Mautic - SMS"
          description="Alterar pacote / Contratar envios extras"
        />
        <div className="datatables-table">
          <DataTable
            dense
            noHeader
            defaultSortField="envioQtd"
            pagination
            columns={[
              {
                name: 'Descrição',
                selector: 'produto',
              },
              {
                name: 'Envios',
                selector: 'envioQtd',
                width: '100px',
                sortable: true,
              },
              {
                name: 'Vigência',
                selector: 'banco',
                cell: row =>
                  row.gratis ? 'Sem vigência' : 'Valor cobrado por 1 ano',
              },
              {
                name: 'Preço',
                selector: 'valor',
                cell: row =>
                  row.gratis
                    ? 'Grátis'
                    : `${formatCurrency(Number(row.valor))} mensais`,
                width: '200px',
              },
              {
                name: '',
                cell: row => {
                  if (row.contratado) {
                    return (
                      <div className="p-2">
                        <div className="pt-2 pb-2 font-italic">Contratado</div>
                      </div>
                    );
                  }

                  return (
                    <div className="p-2">
                      <TableButton
                        disabled={loadingPurchase}
                        onClick={() =>
                          buyMauticSmsProduct(row.idProduto, row.gratis)
                        }
                      >
                        {mauticSms.contratado && !row.envioExtra
                          ? 'Alterar'
                          : 'Contratar'}
                      </TableButton>
                    </div>
                  );
                },
                width: '150px',
              },
            ]}
            data={mauticSms.produtosDisponiveis}
            progressPending={isLoading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default MauticSms;
