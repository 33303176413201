import { useQuery } from 'react-query';

import api from 'services/api';

type TipoCobranca = 'MULTIPLA' | 'UNICA';

type BalanceItemResponse = {
  saldo: string;
  saldoFormatado: string;
};

type BalanceResponse = {
  data: BalanceItemResponse[];
};

type Balance = {
  balance: number;
  formattedBalance: string;
};

const initialData: Balance = {
  balance: 0,
  formattedBalance: '0',
};

function formatBalance(balanceItem: BalanceItemResponse): Balance {
  const { saldo, saldoFormatado } = balanceItem;

  return {
    balance: parseFloat(saldo),
    formattedBalance: saldoFormatado,
  };
}

async function loadBalance(
  idClient: number,
  idSite: number,
  tipoCobranca: TipoCobranca,
): Promise<Balance> {
  const balanceResponse = await api.get<BalanceResponse>(
    `financeiro/v1/cliente/${idClient}/saldo`,
    {
      params: {
        ...(tipoCobranca === 'MULTIPLA' && {
          sites: [idSite],
        }),
      },
    },
  );

  let balance = initialData;

  if (balanceResponse.data.data.length > 0) {
    balance = formatBalance(balanceResponse.data.data[0]);
  }

  return balance;
}

export default function useBalance(
  idClient: number,
  idSite: number,
  tipoCobranca: TipoCobranca,
) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Balance>(
    ['balance', idSite],
    () => loadBalance(idClient, idSite, tipoCobranca),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const balance = data || initialData;

  return {
    balance,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
