import { useQuery } from 'react-query';

import api from 'services/api';

type TipoCobranca = 'MULTIPLA' | 'UNICA';

type TotalCostResponse = {
  data: {
    diaVencimento: number;
    totalCobranca: string;
    periodicidade: string;
    formaPagamento: string;
  };
};

interface TotalCost {
  total: string;
  frequency: string;
  paymentForm: string;
  expirationDay: number;
}

const initialData: TotalCost = {
  total: '',
  frequency: '',
  paymentForm: '',
  expirationDay: 0,
};

function formatTotalCost(totalCost: TotalCostResponse): TotalCost {
  const {
    diaVencimento,
    totalCobranca,
    periodicidade,
    formaPagamento,
  } = totalCost.data;

  return {
    total: totalCobranca,
    frequency: periodicidade,
    paymentForm: formaPagamento,
    expirationDay: Number(diaVencimento),
  };
}

async function loadTotalCost(
  idClient: number,
  idSite: number,
  tipoCobranca: TipoCobranca,
): Promise<TotalCost> {
  const totalCostResponse = await api.get<TotalCostResponse>(
    `clientes/v1/cliente/${idClient}/custo-total`,
    {
      params: {
        ...(tipoCobranca === 'MULTIPLA' && {
          idSite,
        }),
      },
    },
  );

  const totalCost = formatTotalCost(totalCostResponse.data);

  return totalCost;
}

export default function useTotalCost(
  idClient: number,
  idSite: number,
  tipoCobranca: TipoCobranca,
) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<TotalCost>(
    ['totalCost', idSite],
    () => loadTotalCost(idClient, idSite, tipoCobranca),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const totalCost = data || initialData;

  return {
    totalCost,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
