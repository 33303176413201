import styled from 'styled-components';
import creditCardCompany from 'assets/images/creditCardCompany.png';

export const Container = styled.div`
  .maiusculo {
    text-transform: uppercase;
  }
`;

export const Companies = styled.div`
  height: 37px;
`;

export const Aura = styled.div`
  background-image: url(${creditCardCompany});
  background-position: 545px 0px;
  float: left;
  height: 32px;
  width: 47px;
  margin-top: 5px;
  opacity: 0.6;
  filter: grayscale(1);
  &.selected {
    opacity: 1;
    filter: grayscale(0);
  }
`;

export const DinersClub = styled.div`
  background-image: url(${creditCardCompany});
  background-position: 400px 0px;
  float: left;
  height: 32px;
  width: 47px;
  margin-top: 5px;
  opacity: 0.6;
  filter: grayscale(1);
  &.selected {
    opacity: 1;
    filter: grayscale(0);
  }
`;

export const Hipercard = styled.div`
  background-image: url(${creditCardCompany});
  background-position: 595px 0px;
  float: left;
  height: 32px;
  width: 47px;
  margin-top: 5px;
  opacity: 0.6;
  filter: grayscale(1);
  &.selected {
    opacity: 1;
    filter: grayscale(0);
  }
`;

export const Mastercard = styled.div`
  background-image: url(${creditCardCompany});
  background-position: 200px 0px;
  float: left;
  height: 32px;
  width: 47px;
  margin-top: 5px;
  opacity: 0.6;
  filter: grayscale(1);
  &.selected {
    opacity: 1;
    filter: grayscale(0);
  }
`;

export const Visa = styled.div`
  background-image: url(${creditCardCompany});
  background-position: 0px 0px;
  float: left;
  height: 32px;
  width: 47px;
  margin-top: 5px;
  opacity: 0.6;
  filter: grayscale(1);
  &.selected {
    opacity: 1;
    filter: grayscale(0);
  }
`;
