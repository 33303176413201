import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Card, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import { StoreState } from 'store/createStore';

import swal from 'services/swal';
import api from 'services/api';
import toast from 'services/toast';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import { Container } from './styles';

type OnOffOption = 'ATIVO' | 'INATIVO';

interface LoadVHostResponse {
  data: {
    domain: string;
    documentRoot: string;
    cache: boolean;
    uploadMaxFilesize: string;
    outputBuffering: string;
    haveLog: boolean;
    options: string[];
    allowOverride: string[];
    phpVersions: string[];
    langs: {
      php?: {
        errorReporting: string;
        log: boolean;
        maxChildren: string;
        requestTerminateTimeout: string;
        version: string;
        displayErrors: string;
      };
    };
  };
}

interface ExtraSettingsFormValues {
  phpVersion: string;
  modRewrite: OnOffOption;
  outputBuffering: string;
  log: OnOffOption;
  ssi: OnOffOption;
  indexes: OnOffOption;
  errorLog: OnOffOption;

  eAll: OnOffOption;
  eError: OnOffOption;
  eWarning: OnOffOption;
  eParse: OnOffOption;
  eNotice: OnOffOption;
  eStrict: OnOffOption;
  eDeprecated: OnOffOption;
  eCoreError: OnOffOption;
  eCoreWarning: OnOffOption;
  eCompileError: OnOffOption;
  eCompileWarning: OnOffOption;
  eUserError: OnOffOption;
  eUserNotice: OnOffOption;
  eUserWarning: OnOffOption;
  eRecoverableError: OnOffOption;
  eUserDeprecated: OnOffOption;
  displayErrors: OnOffOption;

  sessionAutoStart: OnOffOption;
  sessionUseTransSid: OnOffOption;
  userIniFile: string;
  userIniFileSave: OnOffOption;

  imagesBlock: OnOffOption;
  htaccessFile: string;
  htaccessFileSave: OnOffOption;
}

interface VHost {
  serverName: string;
  documentRoot: string;
  cache: boolean;
  modRewrite: boolean;
  errorLog: boolean;
  displayErrors: boolean;
  requestTerminateTimeout: string;
  phpVersion: string;
  phpVersions: string[];
  maxChildren: string;
  uploadMaxFilesize: string;
  outputBuffering: string;
  haveLog: boolean;
  options: string[];
  errorReporting: string[];
  allowOverride: string[];
}

const ExtraSettings: React.FC = () => {
  const { t } = useTranslation();

  const { site } = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [vhost, setVHost] = useState<VHost>({
    serverName: '',
    documentRoot: '',
    cache: false,
    modRewrite: false,
    errorLog: false,
    displayErrors: false,
    requestTerminateTimeout: '0',
    phpVersion: '',
    phpVersions: [],
    maxChildren: '0',
    uploadMaxFilesize: '0',
    outputBuffering: '0',
    haveLog: false,
    options: [],
    errorReporting: [],
    allowOverride: [],
  });
  const [htaccess, setHtaccess] = useState('');
  const [htaccessFileSave, setHtaccessFileSave] = useState(true);

  const [userIni, setUserIni] = useState('');
  const [userIniFileSave, setUserIniFileSave] = useState(true);

  const imageBlock = `\n# Adicionado pelo Painel de Controle\n<IfModule mod_rewrite.c>\nRewriteEngine on\nRewriteCond %{HTTP_REFERER} !^$\nRewriteCond %{HTTP_REFERER} !^http://(www.)?${site}/.*$ [NC]\nRewriteRule .(gif|jpg|jpeg|png|js|css|avi|bmp|bat|doc|docx|fla|html|ico|log|mp3|mpg|mov|nrg|ogg|pdf|php|ptt|rm|rar|swf|txt|ttf|vob|wab|wav|zip)$ - [F]\n</IfModule>`;
  const sessionAutoStart1 =
    '; Adicionado pelo Painel de Controle\nsession.auto_start = 1';
  const sessionAutoStart0 =
    '; Adicionado pelo Painel de Controle\nsession.auto_start = 0';
  const sessionUseTransSid1 =
    '; Adicionado pelo Painel de Controle\nsession.use_trans_sid = 1';
  const sessionUseTransSid0 =
    '; Adicionado pelo Painel de Controle\nsession.use_trans_sid = 0';

  const errorsReporting = [
    'eError',
    'eWarning',
    'eParse',
    'eNotice',
    'eStrict',
    'eDeprecated',
    'eCoreError',
    'eCoreWarning',
    'eCompileError',
    'eCompileWarning',
    'eUserError',
    'eUserNotice',
    'eUserWarning',
    'eRecoverableError',
    'eUserDeprecated',
  ];

  function formatVHost(vhostResponse: LoadVHostResponse): VHost {
    const response = vhostResponse.data;

    const errorsReportingOptions = [
      'E_ERROR',
      'E_WARNING',
      'E_PARSE',
      'E_NOTICE',
      'E_STRICT',
      'E_DEPRECATED',
      'E_CORE_ERROR',
      'E_CORE_WARNING',
      'E_COMPILE_ERROR',
      'E_COMPILE_WARNING',
      'E_USER_ERROR',
      'E_USER_NOTICE',
      'E_USER_WARNING',
      'E_RECOVERABLE_ERROR',
      'E_USER_DEPRECATED',
    ];

    const { options, langs, allowOverride } = response;
    const errorReporting = langs.php?.errorReporting;

    let errorReportingArray: string[] = [];
    if (errorReporting) {
      let errorReportingSplit: string[] = [];
      if (errorReporting.indexOf('E_ALL') === 0) {
        errorReportingSplit = errorReporting.split(' & ');

        errorReportingArray = errorsReportingOptions.filter(
          errorOption => !errorReportingSplit.includes(`~${errorOption}`),
        );

        errorReportingArray.push('E_ALL');
      } else {
        errorReportingArray = errorReporting.split(' | ');
      }
    }

    const modRewrite =
      options.includes('FollowSymLinks') &&
      allowOverride.includes('FileInfo') &&
      allowOverride.includes('Options');

    return {
      serverName: response.domain,
      documentRoot: response.documentRoot,
      cache: response.cache,
      modRewrite,
      errorLog: langs.php?.log || false,
      displayErrors: langs.php?.displayErrors === 'On',
      requestTerminateTimeout: langs.php?.requestTerminateTimeout ?? '0',
      phpVersion: langs.php?.version || '7.4',
      phpVersions: response.phpVersions,
      maxChildren: langs.php?.maxChildren || '0',
      uploadMaxFilesize: response.uploadMaxFilesize,
      outputBuffering: response.outputBuffering,
      errorReporting: errorReportingArray,
      haveLog: response.haveLog,
      options,
      allowOverride,
    };
  }

  useEffect(() => {
    async function loadVHost() {
      try {
        setLoading(true);
        setError(false);

        const vHostResponse = await api.get<LoadVHostResponse>(
          `painel/v1/vhost/${site}`,
        );
        const formattedVHost = formatVHost(vHostResponse.data);

        const htaccessResponse = await api.get(
          `https://a.apihn.co/ftp/v1/arquivo`,
          {
            params: { caminho: 'www/.htaccess', site },
          },
        );
        if (htaccessResponse.data.data.conteudo) {
          const decodedHtaccess = atob(htaccessResponse.data.data.conteudo);
          setHtaccess(decodedHtaccess);
        } else {
          setHtaccessFileSave(false);
        }

        const userIniResponse = await api.get(
          `https://a.apihn.co/ftp/v1/arquivo`,
          {
            params: { caminho: 'www/.user.ini', site },
          },
        );
        if (userIniResponse.data.data.conteudo) {
          const decodedUserIni = atob(userIniResponse.data.data.conteudo);
          setUserIni(decodedUserIni);
        } else {
          setUserIniFileSave(false);
        }

        setVHost(formattedVHost);
      } catch (err) {
        setError(true);
        swal.fire({
          title: t('pages:extraSettings.loadSiteError'),
          html: err.response.data.error_description,
        });
      } finally {
        setLoading(false);
      }
    }
    loadVHost();
  }, [site, t]);

  async function editSettings(values: ExtraSettingsFormValues) {
    try {
      const options: string[] = [];
      const allowOverride: string[] = [];
      const params = {
        phpVersion: values.phpVersion,
        modRewrite: values.modRewrite === 'ATIVO',
        outputBuffering: values.outputBuffering === 'ATIVO' ? 4 : 0,
        haveLog: values.log === 'ATIVO',
        allowOverride,
        options,
        phpLog: values.errorLog === 'ATIVO',
        errorReporting: [
          values.eAll === 'ATIVO' ? 'E_ALL' : '~E_ALL',
          values.eError === 'ATIVO' ? 'E_ERROR' : '~E_ERROR',
          values.eWarning === 'ATIVO' ? 'E_WARNING' : '~E_WARNING',
          values.eParse === 'ATIVO' ? 'E_PARSE' : '~E_PARSE',
          values.eNotice === 'ATIVO' ? 'E_NOTICE' : '~E_NOTICE',
          values.eStrict === 'ATIVO' ? 'E_STRICT' : '~E_STRICT',
          values.eDeprecated === 'ATIVO' ? 'E_DEPRECATED' : '~E_DEPRECATED',
          values.eCoreError === 'ATIVO' ? 'E_CORE_ERROR' : '~E_CORE_ERROR',
          values.eCoreWarning === 'ATIVO'
            ? 'E_CORE_WARNING'
            : '~E_CORE_WARNING',
          values.eCompileError === 'ATIVO'
            ? 'E_COMPILE_ERROR'
            : '~E_COMPILE_ERROR',
          values.eCompileWarning === 'ATIVO'
            ? 'E_COMPILE_WARNING'
            : '~E_COMPILE_WARNING',
          values.eUserError === 'ATIVO' ? 'E_USER_ERROR' : '~E_USER_ERROR',
          values.eUserNotice === 'ATIVO' ? 'E_USER_NOTICE' : '~E_USER_NOTICE',
          values.eUserWarning === 'ATIVO'
            ? 'E_USER_WARNING'
            : '~E_USER_WARNING',
          values.eRecoverableError === 'ATIVO'
            ? 'E_RECOVERABLE_ERROR'
            : '~E_RECOVERABLE_ERROR',
          values.eUserDeprecated === 'ATIVO'
            ? 'E_USER_DEPRECATED'
            : '~E_USER_DEPRECATED',
        ],
        displayErrors: values.displayErrors === 'ATIVO' ? 'On' : 'Off',
      };
      if (values.ssi === 'ATIVO') {
        params.options.push('IncludesNOEXEC');
      }
      if (values.indexes === 'ATIVO') {
        params.allowOverride.push('Indexes');
      }
      params.options.push('Includes');
      params.allowOverride.push('AuthConfig');
      params.allowOverride.push('Limit');
      await api.put(`painel/v1/vhost/${site}`, params);

      if (values.htaccessFileSave === 'ATIVO') {
        const blobHtaccess = new Blob([values.htaccessFile], {
          type: 'file/text',
        });
        const dataHtaccess = new FormData();
        dataHtaccess.append('arquivo', blobHtaccess, '.htaccess');
        await api.post(`https://a.apihn.co/ftp/v1/arquivo`, dataHtaccess, {
          headers: { 'content-type': 'multipart/form-data' },
          params: { site, caminho: 'www/', sobreescrever: 1 },
        });
      }

      if (values.userIniFileSave === 'ATIVO') {
        const blobUserIni = new Blob([values.userIniFile], {
          type: 'file/text',
        });
        const dataUserIni = new FormData();
        dataUserIni.append('arquivo', blobUserIni, '.user.ini');
        await api.post(`https://a.apihn.co/ftp/v1/arquivo`, dataUserIni, {
          headers: { 'content-type': 'multipart/form-data' },
          params: { site, caminho: 'www/', sobreescrever: 1 },
        });
      }

      toast.fire(t('pages:extraSettings.editSiteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:extraSettings.editSiteError'),
        html: err.response.data.error_description,
      });
    }
  }

  // return <Redirect to="/home" />;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:extraSettings.title')}
          description={t('pages:extraSettings.description')}
          helpContent={
            <div>
              <p>
                <strong>{t('pages:extraSettings.helpContent.p1')}</strong>
              </p>
              <p>{t('pages:extraSettings.helpContent.p2')}</p>
              <p>{t('pages:extraSettings.helpContent.p3')}</p>
              <p>{t('pages:extraSettings.helpContent.p4')}</p>
              <p>{t('pages:extraSettings.helpContent.p5')}</p>
              <p>
                <strong>{t('common:warning')}</strong>
              </p>
              <ul>
                <li>{t('pages:extraSettings.helpContent.li1')}</li>
                <li>{t('pages:extraSettings.helpContent.li2')}</li>
                <li>{t('pages:extraSettings.helpContent.li3')}</li>
              </ul>
            </div>
          }
        />
        <Formik
          enableReinitialize
          initialValues={{
            phpVersion: vhost.phpVersion,
            modRewrite: vhost.modRewrite ? 'ATIVO' : 'INATIVO',
            outputBuffering:
              vhost.outputBuffering === '0' ? 'INATIVO' : 'ATIVO',
            log: vhost.haveLog ? 'ATIVO' : 'INATIVO',
            ssi: vhost.options.includes('IncludesNOEXEC') ? 'ATIVO' : 'INATIVO',
            indexes: vhost.allowOverride.includes('Indexes')
              ? 'ATIVO'
              : 'INATIVO',
            errorLog: vhost.errorReporting.includes('E_ERROR')
              ? 'ATIVO'
              : 'INATIVO',

            eAll: vhost.errorReporting.includes('E_ALL') ? 'ATIVO' : 'INATIVO',
            eError: vhost.errorReporting.includes('E_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eWarning: vhost.errorReporting.includes('E_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eParse: vhost.errorReporting.includes('E_PARSE')
              ? 'ATIVO'
              : 'INATIVO',
            eNotice: vhost.errorReporting.includes('E_NOTICE')
              ? 'ATIVO'
              : 'INATIVO',
            eStrict: vhost.errorReporting.includes('E_STRICT')
              ? 'ATIVO'
              : 'INATIVO',
            eDeprecated: vhost.errorReporting.includes('E_DEPRECATED')
              ? 'ATIVO'
              : 'INATIVO',
            eCoreError: vhost.errorReporting.includes('E_CORE_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eCoreWarning: vhost.errorReporting.includes('E_CORE_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eCompileError: vhost.errorReporting.includes('E_COMPILE_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eCompileWarning: vhost.errorReporting.includes('E_COMPILE_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eUserError: vhost.errorReporting.includes('E_USER_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eUserNotice: vhost.errorReporting.includes('E_USER_NOTICE')
              ? 'ATIVO'
              : 'INATIVO',
            eUserWarning: vhost.errorReporting.includes('E_USER_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eRecoverableError: vhost.errorReporting.includes(
              'E_RECOVERABLE_ERROR',
            )
              ? 'ATIVO'
              : 'INATIVO',
            eUserDeprecated: vhost.errorReporting.includes('E_USER_DEPRECATED')
              ? 'ATIVO'
              : 'INATIVO',
            displayErrors: vhost.displayErrors ? 'ATIVO' : 'INATIVO',

            sessionAutoStart:
              userIni.indexOf(sessionAutoStart1) >= 0 ? 'ATIVO' : 'INATIVO',
            sessionUseTransSid:
              userIni.indexOf(sessionUseTransSid1) >= 0 ? 'ATIVO' : 'INATIVO',
            userIniFile: userIni,
            userIniFileSave: userIniFileSave ? 'ATIVO' : 'INATIVO',

            imagesBlock:
              htaccess.indexOf(imageBlock) >= 0 ? 'ATIVO' : 'INATIVO',
            htaccessFile: htaccess,
            htaccessFileSave: htaccessFileSave ? 'ATIVO' : 'INATIVO',
          }}
          onSubmit={editSettings}
        >
          {props => {
            function handleImagesBlockChange(value: string) {
              let htaccessFileText = props.values.htaccessFile;
              if (value === 'ATIVO') {
                htaccessFileText += `${imageBlock}`;
              } else {
                htaccessFileText = htaccessFileText.replace(imageBlock, '');
              }
              props.setFieldValue('imagesBlock', value);
              props.setFieldValue('htaccessFile', htaccessFileText);
            }

            function handleSessionAutoStartChange(value: string) {
              let userIniFileText = props.values.userIniFile;
              if (value === 'ATIVO') {
                if (userIniFileText.indexOf(sessionAutoStart0) >= 0) {
                  userIniFileText = userIniFileText.replace(
                    sessionAutoStart0,
                    sessionAutoStart1,
                  );
                }
                if (userIniFileText.indexOf(sessionAutoStart1) === -1) {
                  userIniFileText += `${
                    userIniFileText !== '' ? '\n' : ''
                  }${sessionAutoStart1}`;
                }
              } else {
                if (userIniFileText.indexOf(sessionAutoStart1) >= 0) {
                  userIniFileText = userIniFileText.replace(
                    sessionAutoStart1,
                    sessionAutoStart0,
                  );
                }
                if (userIniFileText.indexOf(sessionAutoStart0) === -1) {
                  userIniFileText += `${
                    userIniFileText !== '' ? '\n' : ''
                  }${sessionAutoStart0}`;
                }
              }
              props.setFieldValue('sessionAutoStart', value);
              props.setFieldValue('userIniFile', userIniFileText);
            }

            function handleSessionUseTransSidChange(value: string) {
              let userIniFileText = props.values.userIniFile;
              if (value === 'ATIVO') {
                if (userIniFileText.indexOf(sessionUseTransSid0) >= 0) {
                  userIniFileText = userIniFileText.replace(
                    sessionUseTransSid0,
                    sessionUseTransSid1,
                  );
                }
                if (userIniFileText.indexOf(sessionUseTransSid1) === -1) {
                  userIniFileText += `${
                    userIniFileText !== '' ? '\n' : ''
                  }${sessionUseTransSid1}`;
                }
              } else {
                if (userIniFileText.indexOf(sessionUseTransSid1) >= 0) {
                  userIniFileText = userIniFileText.replace(
                    sessionUseTransSid1,
                    sessionUseTransSid0,
                  );
                }
                if (userIniFileText.indexOf(sessionUseTransSid0) === -1) {
                  userIniFileText += `${
                    userIniFileText !== '' ? '\n' : ''
                  }${sessionUseTransSid0}`;
                }
              }
              props.setFieldValue('sessionUseTransSid', value);
              props.setFieldValue('userIniFile', userIniFileText);
            }

            return (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body className="fieldset">
                  <FormSubtitle
                    subTitle={t('pages:extraSettings.basicSettings')}
                  />
                  <Form.Group as={Row} controlId="extraSettings.phpVersion">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.phpVersion')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        as="select"
                        name="phpVersion"
                        value={props.values.phpVersion}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      >
                        {vhost.phpVersions.map(version => (
                          <option key={version} value={version}>
                            {version}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.modRewrite">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.modRewrite')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="modRewrite-true"
                        name="modRewrite"
                        label="Sim"
                        value="ATIVO"
                        checked={props.values.modRewrite === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="modRewrite-false"
                        name="modRewrite"
                        label="Não"
                        value="INATIVO"
                        checked={props.values.modRewrite === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.modRewriteInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="extraSettings.outputBuffering"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.outputBuffering')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="outputBuffering-ativo"
                        name="outputBuffering"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.outputBuffering === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="outputBuffering-inativo"
                        name="outputBuffering"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.outputBuffering === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.log">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.log')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="log-ativo"
                        name="log"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.log === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="log-inativo"
                        name="log"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.log === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.ssi">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.ssi')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="ssi-ativo"
                        name="ssi"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.ssi === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="ssi-inativo"
                        name="ssi"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.ssi === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.indexes">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.indexes')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="indexes-ativo"
                        name="indexes"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.indexes === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="indexes-inativo"
                        name="indexes"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.indexes === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.errorLog">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.errorLog')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="errorLog-true"
                        name="errorLog"
                        label="Sim"
                        value="ATIVO"
                        checked={props.values.errorLog === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="errorLog-false"
                        name="errorLog"
                        label="Não"
                        value="INATIVO"
                        checked={props.values.errorLog === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>

                  <div className="mt-5" />

                  <FormSubtitle
                    subTitle={t(
                      'pages:extraSettings.phpErrorReportingSettings',
                    )}
                  />

                  <Form.Group as={Row} controlId="extraSettings.eAll">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eAll')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eAll-ativo"
                        name="eAll"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eAll === 'ATIVO'}
                        onChange={value => {
                          const errorsValues: { [key: string]: string } = {};

                          errorsReporting.forEach(errorOption => {
                            errorsValues[errorOption] = 'ATIVO';
                          });

                          props.setValues({
                            ...props.values,
                            ...errorsValues,
                          });

                          props.handleChange(value);
                        }}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eAll-inativo"
                        name="eAll"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eAll === 'INATIVO'}
                        onChange={value => {
                          const errorsValues: { [key: string]: string } = {};

                          errorsReporting.forEach(errorOption => {
                            errorsValues[errorOption] = 'INATIVO';
                          });

                          props.setValues({
                            ...props.values,
                            ...errorsValues,
                          });

                          props.handleChange(value);
                        }}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eAllInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eError">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eError')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eError-ativo"
                        name="eError"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eError === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eError-inativo"
                        name="eError"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eError === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eErrorInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eWarning">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eWarning')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eWarning-ativo"
                        name="eWarning"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eWarning === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eWarning-inativo"
                        name="eWarning"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eWarning === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eWarningInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eParse">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eParse')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eParse-ativo"
                        name="eParse"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eParse === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eParse-inativo"
                        name="eParse"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eParse === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eParseInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eNotice">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eNotice')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eNotice-ativo"
                        name="eNotice"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eNotice === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eNotice-inativo"
                        name="eNotice"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eNotice === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eNoticeInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eStrict">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eStrict')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eStrict-ativo"
                        name="eStrict"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eStrict === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eStrict-inativo"
                        name="eStrict"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eStrict === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eStrictInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eDeprecated">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eDeprecated')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eDeprecated-ativo"
                        name="eDeprecated"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eDeprecated === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eDeprecated-inativo"
                        name="eDeprecated"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eDeprecated === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eDeprecatedInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eCoreError">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eCoreError')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCoreError-ativo"
                        name="eCoreError"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eCoreError === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCoreError-inativo"
                        name="eCoreError"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eCoreError === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eCoreErrorInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eCoreWarning">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eCoreWarning')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCoreWarning-ativo"
                        name="eCoreWarning"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eCoreWarning === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCoreWarning-inativo"
                        name="eCoreWarning"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eCoreWarning === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eCoreWarningInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eCompileError">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eCompileError')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCompileError-ativo"
                        name="eCompileError"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eCompileError === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCompileError-inativo"
                        name="eCompileError"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eCompileError === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eCompileErrorInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="extraSettings.eCompileWarning"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eCompileWarning')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCompileWarning-ativo"
                        name="eCompileWarning"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eCompileWarning === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eCompileWarning-inativo"
                        name="eCompileWarning"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eCompileWarning === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eCompileWarningInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eUserError">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eUserError')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserError-ativo"
                        name="eUserError"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eUserError === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserError-inativo"
                        name="eUserError"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eUserError === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eUserErrorInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eUserNotice">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eUserNotice')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserNotice-ativo"
                        name="eUserNotice"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eUserNotice === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserNotice-inativo"
                        name="eUserNotice"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eUserNotice === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eUserNoticeInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.eUserWarning">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eUserWarning')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserWarning-ativo"
                        name="eUserWarning"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eUserWarning === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserWarning-inativo"
                        name="eUserWarning"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eUserWarning === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eUserWarningInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="extraSettings.eRecoverableError"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eRecoverableError')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eRecoverableError-ativo"
                        name="eRecoverableError"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eRecoverableError === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eRecoverableError-inativo"
                        name="eRecoverableError"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eRecoverableError === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eRecoverableErrorInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="extraSettings.eUserDeprecated"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.eUserDeprecated')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserDeprecated-ativo"
                        name="eUserDeprecated"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.eUserDeprecated === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="eUserDeprecated-inativo"
                        name="eUserDeprecated"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.eUserDeprecated === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.eUserDeprecatedInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="extraSettings.displayErrors">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.displayErrors')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="displayErrors-ativo"
                        name="displayErrors"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.displayErrors === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="displayErrors-inativo"
                        name="displayErrors"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.displayErrors === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>

                  <div className="mt-5" />

                  <FormSubtitle subTitle={t('pages:extraSettings.userIni')} />

                  <Form.Group
                    as={Row}
                    controlId="extraSettings.sessionAutoStart"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.sessionAutoStart')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="sessionAutoStart-ativo"
                        name="sessionAutoStart"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.sessionAutoStart === 'ATIVO'}
                        onChange={ev =>
                          handleSessionAutoStartChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="sessionAutoStart-inativo"
                        name="sessionAutoStart"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.sessionAutoStart === 'INATIVO'}
                        onChange={ev =>
                          handleSessionAutoStartChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="extraSettings.sessionUseTransSid"
                  >
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.sessionUseTransSid')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="sessionUseTransSid-ativo"
                        name="sessionUseTransSid"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.sessionUseTransSid === 'ATIVO'}
                        onChange={ev =>
                          handleSessionUseTransSidChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="sessionUseTransSid-inativo"
                        name="sessionUseTransSid"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.sessionUseTransSid === 'INATIVO'}
                        onChange={ev =>
                          handleSessionUseTransSidChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.userIniFile">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.userIniFile')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="userIniFile"
                        as="textarea"
                        rows={7}
                        value={props.values.userIniFile}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.userIniFile &&
                          !!props.errors.userIniFile
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.userIniFileInfo')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="extraSettings.userIniFileSave"
                  >
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="userIniFileSave-ativo"
                        name="userIniFileSave"
                        label="Sim"
                        value="ATIVO"
                        checked={props.values.userIniFileSave === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="userIniFileSave-inativo"
                        name="userIniFileSave"
                        label="Não"
                        value="INATIVO"
                        checked={props.values.userIniFileSave === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.userIniFileSaveInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>

                  <div className="mt-5" />

                  <FormSubtitle subTitle={t('pages:extraSettings.htaccess')} />

                  <Form.Group as={Row} controlId="extraSettings.imagesBlock">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.imagesBlock')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="imagesBlock-ativo"
                        name="imagesBlock"
                        label="Ativo"
                        value="ATIVO"
                        checked={props.values.imagesBlock === 'ATIVO'}
                        onChange={ev =>
                          handleImagesBlockChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="imagesBlock-inativo"
                        name="imagesBlock"
                        label="Inativo"
                        value="INATIVO"
                        checked={props.values.imagesBlock === 'INATIVO'}
                        onChange={ev =>
                          handleImagesBlockChange(ev.target.value)
                        }
                        disabled={props.isSubmitting}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="extraSettings.htaccessFile">
                    <Form.Label column sm={2}>
                      {t('pages:extraSettings.htaccessFile')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="htaccessFile"
                        as="textarea"
                        spellCheck="false"
                        rows={7}
                        value={props.values.htaccessFile}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.htaccessFile &&
                          !!props.errors.htaccessFile
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.htaccessFileInfo')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid" />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="extraSettings.htaccessFileSave"
                  >
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="htaccessFileSave-ativo"
                        name="htaccessFileSave"
                        label="Sim"
                        value="ATIVO"
                        checked={props.values.htaccessFileSave === 'ATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="htaccessFileSave-inativo"
                        name="htaccessFileSave"
                        label="Não"
                        value="INATIVO"
                        checked={props.values.htaccessFileSave === 'INATIVO'}
                        onChange={props.handleChange}
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:extraSettings.htaccessFileSaveInfo')}
                      </Form.Text>
                    </Col>
                  </Form.Group>
                </Card.Body>

                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting || !props.isValid}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:send')}
                  </PanelButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default ExtraSettings;
