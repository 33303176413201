import { useQuery } from 'react-query';

import api from 'services/api';

type Disco = {
  conta: string;
  quotaAlocadaMB: number;
  quotaUtilizadaMB: number;
  servidor: string;
};

type Email = {
  alocadaGB: number;
  ativo: boolean;
  livreGB: number;
  percentualUtilizado: number;
  site: string;
  utilizadaGB: number;
};

type QuotaEmail = {
  emailAlocadoGB: number;
  emailAlocadoMB: number;
  emailContratadoGB: number;
  emailContratadoMB: number;
  emailPlanoGB: number;
  emailPlanoMB: number;
  emailTotalGB: number;
  emailTotalMB: number;
  emails: Email[];
};

type QuotaSite = {
  espacoDiscoPlanoMB: number;
  espacoDiscoServicosAdicionaisMB: number;
  espacoDiscoTotalMB: number;
  espacoDiscoTotalAlocadoMB: number;
  espacoDiscoTotalUtilizadoMB: number;
  discos: Disco[];
  planoIlimitado: boolean;
};

type QuotaEspacoResponse = {
  data: {
    quotaEmail: QuotaEmail;
    quotaSite: QuotaSite;
  };
};

type SiteQuota = {
  quotaEmail: {
    emailAlocadoGB: number;
    emailAlocadoMB: number;
    emailContratadoGB: number;
    emailContratadoMB: number;
    emailPlanoGB: number;
    emailPlanoMB: number;
    emailTotalGB: number;
    emailTotalMB: number;
    emails: {
      alocadaGB: number;
      ativo: boolean;
      livreGB: number;
      percentualUtilizado: number;
      site: string;
      utilizadaGB: number;
    }[];
  };
  quotaSite: {
    espacoDiscoPlanoMB: number;
    espacoDiscoServicosAdicionaisMB: number;
    espacoDiscoTotalMB: number;
    espacoDiscoTotalAlocadoMB: number;
    espacoDiscoTotalUtilizadoMB: number;
    planoIlimitado: boolean;
    discos: {
      conta: string;
      quotaAlocadaMB: number;
      quotaUtilizadaMB: number;
      servidor: string;
    }[];
  };
};

const initialData: SiteQuota = {
  quotaEmail: {
    emailAlocadoGB: 0,
    emailAlocadoMB: 0,
    emailContratadoGB: 0,
    emailContratadoMB: 0,
    emailPlanoGB: 0,
    emailPlanoMB: 0,
    emailTotalGB: 0,
    emailTotalMB: 0,
    emails: [],
  },
  quotaSite: {
    espacoDiscoPlanoMB: 0,
    espacoDiscoServicosAdicionaisMB: 0,
    espacoDiscoTotalMB: 0,
    espacoDiscoTotalAlocadoMB: 0,
    espacoDiscoTotalUtilizadoMB: 0,
    planoIlimitado: false,
    discos: [],
  },
};

async function loadSiteQuota(idSite: number): Promise<SiteQuota> {
  const quotaResponse = await api.get<QuotaEspacoResponse>(
    `painel/v1/site/${idSite}/quota`,
  );

  const quota = quotaResponse.data.data;

  return quota;
}

export default function useSiteQuota(idSite: number) {
  const { data, ...rest } = useQuery<SiteQuota>(
    ['siteQuota', idSite],
    () => loadSiteQuota(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const quota = data || initialData;

  return {
    quota,
    ...rest,
  };
}
