import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import PanelButton from 'components/PanelButton';

// import { Container } from './styles';

interface ReopenTicketFormValues {
  message: string;
}

interface ReopenTicketModalProps {
  show: boolean;
  fetching: boolean;
  attachmentError: boolean;
  attachment: File | undefined;
  close: () => void;
  reopenTicket: (
    values: ReopenTicketFormValues,
    formikHelpers: FormikHelpers<ReopenTicketFormValues>,
  ) => void;
  testAttachment: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ReopenTicketModal: React.FC<ReopenTicketModalProps> = ({
  show,
  fetching,
  attachmentError,
  attachment,
  close,
  reopenTicket,
  testAttachment,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:ticket.reopen')}</Modal.Title>
      </Modal.Header>

      <Formik
        validateOnMount
        initialValues={{
          message: '',
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string().required(t('pages:ticket.validation.message')),
        })}
        onSubmit={reopenTicket}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="ticket.Comments">
                <Form.Label>{t('common:message')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="message"
                  disabled={props.isSubmitting}
                  value={props.values.message}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  isInvalid={!!props.touched.message && !!props.errors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="newMessage.attachment">
                <Form.Label>{t('common:attachment')}</Form.Label>

                <Form.File id="attachment-input" custom>
                  <Form.File.Input
                    className={attachmentError ? 'is-invalid' : ''}
                    name="attachment"
                    onChange={testAttachment}
                  />
                  <Form.File.Label data-browse={t('common:selectFile')}>
                    {attachment && attachment.name}
                  </Form.File.Label>
                </Form.File>

                <small className={attachmentError ? 'text-danger' : ''}>
                  {t('pages:ticket.maxAttachmentLength')}
                </small>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <PanelButton
                variant="primary"
                disabled={fetching || !props.isValid}
                type="submit"
              >
                {fetching ? t('pages:ticket.updatingStatus') : t('common:send')}
              </PanelButton>
              <PanelButton variant="secondary" onClick={close}>
                {t('common:close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ReopenTicketModal;
