import { useQuery } from 'react-query';
import api from 'services/api';

interface EmailServiceItemResponse {
  ativo: boolean;
  nome: string;
  servico: string;
  site: string;
}

interface LoadEmailServiceResponse {
  data: EmailServiceItemResponse[];
}

interface EmailService {
  ativo: boolean;
  nome: string;
  servico: string;
  site: string;
}

const initialData: EmailService[] = [];

function formatEmailService(
  emailService: EmailServiceItemResponse,
): EmailService {
  return emailService;
}

async function loadEmailServices(idSite: number): Promise<EmailService[]> {
  const emailServiceResponse = await api.get<LoadEmailServiceResponse>(
    `painel/v1/site/${idSite}/servico-email`,
    { params: { sitesExtras: 1 } },
  );

  const emailServices = emailServiceResponse.data.data.map(formatEmailService);

  return emailServices;
}

export default function useEmailServices(idSite: number) {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    EmailService[]
  >(['emailServices', idSite], () => loadEmailServices(idSite), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const emailServices = data || initialData;

  return {
    emailServices,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
