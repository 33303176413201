import styled from 'styled-components';

export const Container = styled.div`
  &.prioridade-Urgente,
  &.prioridade-Alta {
    .card {
      border: 1px solid var(--color-red);
    }
  }

  .notice-content {
    white-space: pre-wrap;
  }
`;
