import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { FaCloud } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import Loading from 'components/Loading';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import TableSubHeader from 'components/TableSubHeader';
import TableNoData from 'components/TableNoData';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import DnsModal from './components/DnsModal';
import SiteSettingsActions from './components/SiteSettingsActions';

import { Container } from './styles';

interface VHostItemResponse {
  idVHost: string;
  documentRoot: string;
  domain: string;
  cache: boolean;
  tipo: 'Principal' | 'Extra';
  langs: { php?: {} };
}

interface LoadVHostsResponse {
  data: VHostItemResponse[];
}

interface LoadDnsResponse {
  data: string[];
}

interface VHost {
  idVHost: number;
  documentRoot: string;
  domain: string;
  cache: boolean;
  tipo: 'Principal' | 'Extra';
  lang: string;
}

interface SelectedRowsInfo {
  selectedCount: number;
  selectedRows: VHost[];
}

const SitesSettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { siteQtd, sitesCriados } = useSelector(
    (state: StoreState) => state.site.resources,
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [filterText, setFilterText] = useState('');

  const [vHosts, setVHosts] = useState<VHost[]>([]);
  const [filteredVHosts, setFilteredVHosts] = useState<VHost[]>([]);

  const [dns, setDns] = useState<string[]>([]);
  const [showDns, setShowDns] = useState(false);
  const [loadingDns, setLoadingDns] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  function formatVHost(vHostItemResponse: VHostItemResponse) {
    return {
      idVHost: parseInt(vHostItemResponse.idVHost, 10),
      documentRoot: vHostItemResponse.documentRoot,
      domain: vHostItemResponse.domain,
      cache: vHostItemResponse.cache,
      tipo: vHostItemResponse.tipo,
      lang: 'php' in vHostItemResponse.langs ? 'php' : '',
    };
  }

  useEffect(() => {
    async function loadVHosts() {
      try {
        setLoading(true);
        setError(false);

        const loadVHostsResponse = await api.get<LoadVHostsResponse>(
          `painel/v1/site/${idSite}/container/vhost`,
        );

        const formattedVHosts = loadVHostsResponse.data.data.map(formatVHost);

        setVHosts(formattedVHosts);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadVHosts();
  }, [idSite]);

  useEffect(() => {
    const filtered = filter(vHosts, ['domain', 'documentRoot'], filterText);
    setFilteredVHosts(filtered);
  }, [filterText, vHosts]);

  async function loadDns(site: string) {
    try {
      setDns([]);
      setShowDns(true);
      setLoadingDns(true);

      const dnsResponse = await api.get<LoadDnsResponse>(
        `painel/v1/site/${idSite}/dns?site=${site}`,
      );

      setDns(dnsResponse.data.data);
    } finally {
      setLoadingDns(false);
    }
  }

  async function deleteVhost() {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('pages:siteSettings.deleteSiteConfirm'),
        icon: 'warning',
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setFetching(true);

      await Promise.all(
        selectedRowsInfo.selectedRows.map(async item => {
          await api.delete(
            `painel/v1/site/${idSite}/container/vhost/${item.domain}`,
          );
        }),
      );

      const loadVHostsResponse = await api.get<LoadVHostsResponse>(
        `painel/v1/site/${idSite}/container/vhost`,
      );

      const formattedVHosts = loadVHostsResponse.data.data.map(formatVHost);

      setVHosts(formattedVHosts);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:siteSettings.deleteSiteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:siteSettings.deleteSiteError'),
        html: err.response.data.error_description,
      });
    } finally {
      setFetching(false);
    }
  }

  async function clearCache() {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('pages:siteSettings.cacheClearConfirm'),
        icon: 'warning',
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setFetching(true);

      await api.delete(`painel/v1/site/${idSite}/container/cache`);

      toast.fire(t('pages:siteSettings.cacheClearSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:siteSettings.cacheClearError'),
        html: err.response.data.error_description,
      });
    } finally {
      setFetching(false);
    }
  }

  const columns = [
    {
      name: t('common:label.site'),
      selector: 'domain',
      minWidth: '240px',
      cell: (row: VHost) => row.domain,
    },
    {
      name: t('pages:siteSettings.documentRoot'),
      selector: 'documentRoot',
      minWidth: '240px',
      cell: (row: VHost) => row.documentRoot,
    },
    {
      name: t('pages:siteSettings.type'),
      selector: 'tipo',
      width: '120px',
      cell: (row: VHost) => row.tipo,
    },
    {
      name: t('pages:siteSettings.lang'),
      selector: 'langs',
      width: '120px',
      cell: (row: VHost) => row.lang,
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:siteSettings.title')}
          description={
            <Trans
              i18nKey="pages:siteSettings.description"
              components={{ 1: <br /> }}
              values={{
                siteQtd,
                sitesCriados: vHosts.length,
                plural: sitesCriados === 1 ? '' : 's',
              }}
            />
          }
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-dos-sites/"
        />

        <TableSubHeader>
          <div className="mb-1 mb-md-0">
            <TableButton
              forwardedAs={Link}
              to="/cloud/configuracoes-sites/novo"
            >
              {t('pages:siteSettings.newSite')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            defaultSortField="tipo"
            defaultSortAsc={false}
            noHeader={selectedRowsInfo.selectedCount === 0}
            selectableRows
            selectableRowDisabled={() => fetching}
            highlightOnHover
            clearSelectedRows={toggleCleared}
            pagination
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            columns={columns}
            data={filteredVHosts}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            contextComponent={
              <SiteSettingsActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                deleteVHost={deleteVhost}
                clearCache={clearCache}
                loadDns={loadDns}
                loading={fetching}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </div>
      </TableWrapper>

      <DnsModal
        show={showDns}
        close={() => setShowDns(false)}
        dns={dns}
        loading={loadingDns}
      />
    </Container>
  );
};

export default SitesSettings;
