import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p>
        <strong>
          {t('pages:internationalDomainTransfer.helpContent.steps')}
        </strong>
      </p>
      <ul>
        <li>{t('pages:internationalDomainTransfer.helpContent.step1')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.step2')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.step3')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.step4')}</li>
      </ul>
      <p>
        <strong>{t('common:warning')}</strong>
      </p>
      <ul>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning1')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning2')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning3')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning4')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning5')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning6')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning7')}</li>
        <li>{t('pages:internationalDomainTransfer.helpContent.warning8')}</li>
      </ul>
    </div>
  );
};

export default HelpContent;
