import React from 'react';
import { DropdownButton } from 'react-bootstrap';
import { StyledComponentProps } from 'styled-components';

import { StyledDropdownButton } from './styles';

type PanelDropdownButton = StyledComponentProps<DropdownButton, {}, {}, ''>;

const PanelDropdownButton: React.FC<PanelDropdownButton> = props => {
  return (
    <StyledDropdownButton size="sm" variant="outline-primary" {...props} />
  );
};

export default PanelDropdownButton;
