import styled, { keyframes } from 'styled-components';

export const DropZone = styled.div`
  min-height: 174px;
  max-height: calc(100vh - 226px);
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid rgba(42, 42, 42, 0.05);
  background: rgba(204, 204, 204, 0.15);
  padding: 14px;
  box-shadow: inset 0 0 5px 0 rgb(43 43 43 / 10%);
  cursor pointer;
  p {
    margin: 3em 0;
    text-align: center;
    color: #888;
    font-size: 18px;
  }
`;

export const CloseButton = styled.div`
  width: 21px;
  height: 21px;
  display: block;
  border-radius: 12px;
  position: absolute;
  top: -11px;
  right: -11px;
  opacity: 0;
  border: 2px solid;
  background-color: rgba(250, 250, 250);
  color: rgba(0, 0, 0, 0.45);
  transition: all ease-in-out 0.3s;
  :hover {
    color: rgba(0, 0, 0, 0.85);
  }
  :before {
    content: '\\00d7';
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: -1px;
    line-height: 20px;
    left: 3px;
    text-align: center;
  }
`;

export const FileImage = styled.div`
  margin: 4px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  .imageContainer {
    overflow: hidden;
    height: 100%;
    width: 100vw;
    display: block;
    left: calc(-50vw + 50%);
    position: relative;
  }
  img {
    display: block;
    height: 100%;
    margin: 0 auto;
  }
`;

export const FileImageDetails = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  width: 128px;
  height: 128px;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
  opacity: 0;
  .filesize {
    margin-bottom: 1em;
    span {
      padding: 0 4px;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  .filename {
    span {
      padding: 0 4px;
      cursor: pointer;
    }
  }
  .filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  .filename:hover {
    white-space: nowrap;
    position: relative;
    z-index: 1;
    span {
      border: 1px solid rgba(200, 200, 200, 0.8);
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

export const FilePreview = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0.5rem;
  width: 128px;
  height: 128px;
  background-color: white;
  cursor: initial;
  box-shadow: 0 13px 6px -8px rgb(0 0 0 / 30%), 0 0 4px 0 rgb(0 0 0 / 30%);
  :hover {
    ${FileImage} {
      transition: all ease-in-out 0.3s;
      filter: blur(8px);
    }
    ${FileImageDetails} {
      transition: all ease-in-out 0.3s;
      opacity: 1;
    }
    ${CloseButton} {
      transition: all ease-in-out 0.3s;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

export const FileDetails = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 13px;
  width: 128px;
  height: 128px;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
  background: linear-gradient(to bottom, #eee, #ddd);
  .filesize {
    margin-bottom: 1em;
    span {
      padding: 0 4px;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  .filename {
    span {
      padding: 0 4px;
      cursor: pointer;
    }
  }
  .filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  .filename:hover {
    white-space: nowrap;
    position: relative;
    z-index: 1;
    span {
      border: 1px solid rgba(200, 200, 200, 0.8);
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const progress = keyframes`
  from {
    width: 0%;
  }
  10% {
    width: 20%
  }
  30% {
    width: 40%
  }
  60% {
    width: 60%
  }
  to {
    width: 100%;
  }
`;

export const Uploading = styled.div`
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 75%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 5px;
  overflow: hidden;
  .progress {
    height: 16px;
    background-color: var(--color-blue);
    animation: ${progress} 20s linear;
  }
  .complete {
    width: 100% !important;
    height: 16px;
    background-color: var(--success);
  }
  .error {
    width: 100% !important;
    height: 16px;
    background-color: var(--danger);
  }
`;
