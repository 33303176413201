import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const StyledButton = styled(Button)`
  font-size: 11px;
  padding: 8px 14px;
  border-radius: 2px;

  transition: all ease-in 0.3s;

  &.btn-primary {
    color: #fff;
    border-color: var(--color-blue);
    background-color: var(--color-blue);

    &:hover {
      border-color: var(--color-azure-blue);
      background-color: var(--color-azure-blue);
    }
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }
`;
