import api from './api';

interface LoginResponse {
  data: {
    url: string;
  };
}

export default async function switchAccount(key: string) {
  try {
    const loginResponse = await api.post<LoginResponse>(
      'painel/v1/usuario-adicional/login',
      {
        chave: key,
      },
    );

    window.location.replace(loginResponse.data.data.url);
  } catch (error) {
    throw new Error(
      'No momento não foi possível acessar esta conta, caso o problema persista entre em contato com o suporte.',
    );
  }
}
