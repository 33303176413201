import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaDatabase } from 'react-icons/fa';
import { MdSettingsBackupRestore } from 'react-icons/md';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableButton from 'components/TableButton';
import Error from 'components/Error';
import Loading from 'components/Loading';
import TableIconButton from 'components/TableIconButton';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import useDatabases from 'hooks/useDatabases';
import useDatabasesCredits from 'hooks/useDatabasesCredits';

import DatabaseActions from './components/DatabaseActions';
import ConnectionsModal from './components/ConnectionsModal';

import { StyledLink } from './styles';

interface DatabaseConfigs {
  banco: string;
  ownerExterno: string;
  ownerInterno: string;
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { databasesCredits } = useDatabasesCredits(idSite);
  const { databases, loading, error, refetch } = useDatabases(idSite);

  const [activeDatabases, setActiveDatabases] = useState<typeof databases>([]);
  const [removedDatabases, setRemovedDatabases] = useState<typeof databases>(
    [],
  );

  const [deleting, setDeleting] = useState(false);
  const [restoring, setRestoring] = useState(false);

  const [toggleCleared, setToggleCleared] = useState(false);
  const [showConnections, setShowConnections] = useState(false);
  const [databaseConfigs, setDatabaseConfigs] = useState<DatabaseConfigs>({
    banco: '',
    ownerExterno: '',
    ownerInterno: '',
  });

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof databases;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [filterText, setFilterText] = useState('');
  const [filteredDatabases, setFilteredDatabases] = useState<typeof databases>(
    [],
  );

  const deleteDatabase = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:label.warning'),
        text: t('pages:databasesMySql.warning', {
          count: selectedRowsInfo.selectedCount,
        }),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      const databasesIds = selectedRowsInfo.selectedRows.map(({ id }) => id);

      await api.delete(`painel/v1/site/${idSite}/banco-mysql`, {
        params: {
          idsBancos: databasesIds,
        },
      });

      await refetch();

      setToggleCleared(!toggleCleared);

      toast.fire(
        t('pages:databasesMySql.databaseRemoveSuccess', {
          count: selectedRowsInfo.selectedCount,
        }),
      );
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setDeleting(false);
    }
  }, [
    t,
    refetch,
    selectedRowsInfo.selectedCount,
    selectedRowsInfo.selectedRows,
    idSite,
    toggleCleared,
  ]);

  const restoreDatabase = useCallback(
    async (idDatabase: number) => {
      try {
        setRestoring(true);

        await api.put(
          `painel/v1/site/${idSite}/banco-mysql/${idDatabase}?restaurar=true`,
        );

        await refetch();

        setToggleCleared(!toggleCleared);

        toast.fire(t('pages:databasesMySql.databaseRestoreSuccess'));
      } catch (err) {
        swal.fire({
          title: t('common:label.operationFailed'),
          html: err.response.data.error_description,
        });
      } finally {
        setRestoring(false);
      }
    },
    [idSite, refetch, toggleCleared, t],
  );

  const openConnectionsModal = useCallback(() => {
    const [selectedDatabase] = selectedRowsInfo.selectedRows;

    setDatabaseConfigs({
      banco: selectedDatabase.banco,
      ownerExterno: selectedDatabase.ownerExterno,
      ownerInterno: selectedDatabase.ownerInterno,
    });

    setShowConnections(true);
  }, [selectedRowsInfo.selectedRows]);

  useEffect(() => {
    setActiveDatabases(databases.filter(database => database.ativo));
    setRemovedDatabases(databases.filter(database => !database.ativo));
  }, [databases]);

  useEffect(() => {
    setFilteredDatabases(
      filter(activeDatabases, ['banco', 'descricao'], filterText),
    );
  }, [activeDatabases, filterText]);

  const columns = [
    {
      name: t('pages:databasesMySql.database'),
      selector: 'banco',
      sortable: true,
      width: '15%',
    },
    {
      name: t('pages:databasesMySql.user'),
      selector: 'usuario',
      sortable: true,
      width: '15%',
    },
    {
      name: t('pages:databasesMySql.administration'),
      selector: 'server',
      cell: () => (
        <StyledLink
          target="_blank"
          rel="noopener noreferrer"
          href="http://amysql.f1.k8.com.br/"
        >
          phpMyAdmin
        </StyledLink>
      ),
      width: '130px',
    },
    {
      name: t('pages:databasesMySql.server'),
      selector: 'server',
      width: '100px',
    },
    {
      name: t('pages:databasesMySql.description'),
      selector: 'descricao',
    },
  ];

  const removedDatatablesColumns = [
    {
      name: t('common:button.restore'),
      cell: (data: typeof databases[number]) => (
        <TableIconButton
          disabled={restoring}
          onClick={() => restoreDatabase(data.id)}
        >
          <MdSettingsBackupRestore />
        </TableIconButton>
      ),
      width: '80px',
    },
    {
      name: t('pages:databasesMySql.database'),
      selector: 'banco',
      sortable: true,
    },
    {
      name: t('pages:databasesMySql.dateToRemove'),
      selector: 'remocao',
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:databasesMySql.tableTitle')}
          description={t('pages:databasesMySql.databaseUsage', {
            using: databasesCredits.utilizados,
            total: databasesCredits.ilimitado
              ? t('common:label.unlimited', { count: 2 })
              : databasesCredits.total,
          })}
          directHelpLink="https://ajuda.hostnet.com.br/mysql/"
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/banco-de-dados-mysql/adicionar"
            >
              {t('pages:databasesMySql.newDatabase')}
            </TableButton>
            <TableButton forwardedAs={Link} to="/banco-de-dados-mysql/backup">
              {t('pages:databasesMySql.requestBackup')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={columns}
            data={filteredDatabases}
            progressPending={loading}
            clearSelectedRows={toggleCleared}
            progressComponent={<Loading />}
            selectableRowDisabled={() => deleting}
            contextComponent={
              <DatabaseActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                loading={loading || deleting}
                showConnections={() => openConnectionsModal()}
                deleteDatabases={() => deleteDatabase()}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
          />
        </div>
      </TableWrapper>

      {removedDatabases.length > 0 && (
        <TableWrapper>
          <TableHeader
            title={t('pages:databasesMySql.trashTableTitle')}
            description=""
          />
          <DataTable
            noHeader
            pagination
            columns={removedDatatablesColumns}
            data={removedDatabases}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            className="datatables-table"
          />
        </TableWrapper>
      )}

      <ConnectionsModal
        site={site}
        show={showConnections}
        close={() => setShowConnections(false)}
        banco={databaseConfigs.banco}
        ownerExterno={databaseConfigs.ownerExterno}
        ownerInterno={databaseConfigs.ownerInterno}
      />
    </div>
  );
};

export default List;
