import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaCloud } from 'react-icons/fa';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import swal from 'services/swal';
import api from 'services/api';
import toast from 'services/toast';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  site: string;
}

type SiteSettingsEditProps = RouteComponentProps<MatchParams>;

interface LoadVHostResponse {
  data: {
    domain: string;
    documentRoot: string;
    cache: boolean;
    uploadMaxFilesize: string;
    outputBuffering: string;
    home: string;
    haveLog: boolean;
    options: string[];
    allowOverride: string[];
    phpVersions: string[];
    langs: {
      php?: {
        errorReporting: string;
        log: boolean;
        maxChildren: string;
        requestTerminateTimeout: string;
        version: string;
        displayErrors: string;
      };
    };
  };
}

type OnOffOption = 'ATIVO' | 'INATIVO';

interface SiteSettingsFormValues {
  serverName: string;
  documentRoot: string;
  documentRootDir: string;
  home: string;
  cache: OnOffOption;
  modRewrite: OnOffOption;
  requestTerminateTimeout: string;
  phpVersion: string;
  maxChildren: string;
  uploadMaxFilesize: string;
  errorLog: OnOffOption;

  eAll: OnOffOption;
  eError: OnOffOption;
  eWarning: OnOffOption;
  eParse: OnOffOption;
  eNotice: OnOffOption;
  eStrict: OnOffOption;
  eDeprecated: OnOffOption;
  eCoreError: OnOffOption;
  eCoreWarning: OnOffOption;
  eCompileError: OnOffOption;
  eCompileWarning: OnOffOption;
  eUserError: OnOffOption;
  eUserNotice: OnOffOption;
  eUserWarning: OnOffOption;
  eRecoverableError: OnOffOption;
  eUserDeprecated: OnOffOption;

  displayErrors: OnOffOption;

  execCGI: OnOffOption;
  followSymLinks: OnOffOption;
  symLinksIfOwnerMatch: OnOffOption;
  includes: OnOffOption;
  includesNOEXEC: OnOffOption;
  multiViews: OnOffOption;

  authConfig: OnOffOption;
  fileInfo: OnOffOption;
  indexes: OnOffOption;
  limit: OnOffOption;
  options: OnOffOption;
  outputBuffering: OnOffOption;
  log: OnOffOption;
}

interface VHost {
  serverName: string;
  documentRoot: string;
  documentRootDir: string;
  cache: boolean;
  modRewrite: boolean;
  errorLog: boolean;
  displayErrors: boolean;
  requestTerminateTimeout: string;
  phpVersion: string;
  phpVersions: string[];
  maxChildren: string;
  uploadMaxFilesize: string;
  outputBuffering: string;
  home: string;
  haveLog: boolean;
  options: string[];
  errorReporting: string[];
  allowOverride: string[];
}

interface Params {
  errorReporting: string[];
  options: string[];
  allowOverride: string[];

  serverName?: string;
  documentRoot?: string;
  cache?: boolean;
  modRewrite?: boolean;
  phpLog?: boolean;
  displayErrors?: 'On' | 'Off';
  requestTerminateTimeout?: string;
  phpVersion?: string;
  maxChildren?: string;
  uploadMaxFilesize?: string;
  outputBuffering?: 0 | 4;
  haveLog?: boolean;
}

const Edit: React.FC<SiteSettingsEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const { site } = match.params;

  const { limiteUpload } = useSelector(
    (state: StoreState) => state.site.resources,
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [advancedSettings, setAdvancedSettings] = useState(false);

  const [vhost, setVHost] = useState<VHost>({
    serverName: '',
    documentRoot: '',
    documentRootDir: '',
    home: '',
    cache: false,
    modRewrite: false,
    errorLog: false,
    displayErrors: false,
    requestTerminateTimeout: '0',
    phpVersion: '',
    phpVersions: [],
    maxChildren: '0',
    uploadMaxFilesize: '0',
    outputBuffering: '0',
    haveLog: false,
    options: [],
    errorReporting: [],
    allowOverride: [],
  });

  const errorsReporting = [
    'eError',
    'eWarning',
    'eParse',
    'eNotice',
    'eStrict',
    'eDeprecated',
    'eCoreError',
    'eCoreWarning',
    'eCompileError',
    'eCompileWarning',
    'eUserError',
    'eUserNotice',
    'eUserWarning',
    'eRecoverableError',
    'eUserDeprecated',
  ];

  function formatVHost(vhostResponse: LoadVHostResponse): VHost {
    const response = vhostResponse.data;

    const errorsReportingOptions = [
      'E_ERROR',
      'E_WARNING',
      'E_PARSE',
      'E_NOTICE',
      'E_STRICT',
      'E_DEPRECATED',
      'E_CORE_ERROR',
      'E_CORE_WARNING',
      'E_COMPILE_ERROR',
      'E_COMPILE_WARNING',
      'E_USER_ERROR',
      'E_USER_NOTICE',
      'E_USER_WARNING',
      'E_RECOVERABLE_ERROR',
      'E_USER_DEPRECATED',
    ];

    const { options, langs, allowOverride } = response;

    const errorReporting = langs.php?.errorReporting;

    let errorReportingArray: string[] = [];

    if (errorReporting) {
      let errorReportingSplit: string[] = [];

      if (errorReporting.indexOf('E_ALL') === 0) {
        errorReportingSplit = errorReporting.split(' & ');

        errorReportingArray = errorsReportingOptions.filter(
          errorOption => !errorReportingSplit.includes(`~${errorOption}`),
        );

        errorReportingArray.push('E_ALL');
      } else {
        errorReportingArray = errorReporting.split(' | ');
      }
    }

    const modRewrite =
      options.includes('FollowSymLinks') &&
      allowOverride.includes('FileInfo') &&
      allowOverride.includes('Options');

    const home = `${response.home}/`;
    const documentRootDir = response.documentRoot.replace(home, '');

    return {
      serverName: response.domain,
      documentRoot: response.documentRoot,
      documentRootDir,
      cache: response.cache,
      modRewrite,
      errorLog: langs.php?.log || false,
      displayErrors: langs.php?.displayErrors === 'On',
      requestTerminateTimeout: langs.php?.requestTerminateTimeout ?? '0',
      phpVersion: langs.php?.version || '7.4',
      phpVersions: response.phpVersions,
      maxChildren: langs.php?.maxChildren || '0',
      uploadMaxFilesize: response.uploadMaxFilesize,
      outputBuffering: response.outputBuffering,
      errorReporting: errorReportingArray,
      haveLog: response.haveLog,
      home,
      options,
      allowOverride,
    };
  }

  useEffect(() => {
    async function loadVHost() {
      try {
        setLoading(true);
        setError(false);

        const { data } = await api.get<LoadVHostResponse>(
          `painel/v1/vhost/${site}`,
        );

        const formattedVHost = formatVHost(data);

        setVHost(formattedVHost);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadVHost();
  }, [site]);

  async function editSite(values: SiteSettingsFormValues) {
    try {
      const options: string[] = [];
      const allowOverride: string[] = [];

      if (
        Number(values.uploadMaxFilesize) < 0 ||
        Number(values.uploadMaxFilesize) > 1024
      ) {
        swal.fire({
          title: 'Não foi possível editar o site',
          html: 'O limite de upload deve ser entre 0 e 1024',
        });

        return;
      }

      const params: Params = {
        errorReporting: [
          values.eError === 'ATIVO' ? 'E_ERROR' : '~E_ERROR',
          values.eWarning === 'ATIVO' ? 'E_WARNING' : '~E_WARNING',
          values.eParse === 'ATIVO' ? 'E_PARSE' : '~E_PARSE',
          values.eNotice === 'ATIVO' ? 'E_NOTICE' : '~E_NOTICE',
          values.eStrict === 'ATIVO' ? 'E_STRICT' : '~E_STRICT',
          values.eDeprecated === 'ATIVO' ? 'E_DEPRECATED' : '~E_DEPRECATED',
          values.eCoreError === 'ATIVO' ? 'E_CORE_ERROR' : '~E_CORE_ERROR',
          values.eCoreWarning === 'ATIVO'
            ? 'E_CORE_WARNING'
            : '~E_CORE_WARNING',
          values.eCompileError === 'ATIVO'
            ? 'E_COMPILE_ERROR'
            : '~E_COMPILE_ERROR',
          values.eCompileWarning === 'ATIVO'
            ? 'E_COMPILE_WARNING'
            : '~E_COMPILE_WARNING',
          values.eUserError === 'ATIVO' ? 'E_USER_ERROR' : '~E_USER_ERROR',
          values.eUserNotice === 'ATIVO' ? 'E_USER_NOTICE' : '~E_USER_NOTICE',
          values.eUserWarning === 'ATIVO'
            ? 'E_USER_WARNING'
            : '~E_USER_WARNING',
          values.eRecoverableError === 'ATIVO'
            ? 'E_RECOVERABLE_ERROR'
            : '~E_RECOVERABLE_ERROR',
          values.eUserDeprecated === 'ATIVO'
            ? 'E_USER_DEPRECATED'
            : '~E_USER_DEPRECATED',
          values.eAll === 'ATIVO' ? 'E_ALL' : '~E_ALL',
        ],
        options,
        allowOverride,
      };

      if (values.serverName !== vhost.serverName) {
        params.serverName = values.serverName;
      }
      if (values.documentRootDir !== vhost.documentRootDir) {
        params.documentRoot = values.home + values.documentRootDir;
      }
      if ((values.modRewrite === 'ATIVO') !== vhost.modRewrite) {
        params.modRewrite = values.modRewrite === 'ATIVO';
      }
      if (values.requestTerminateTimeout !== vhost.requestTerminateTimeout) {
        params.requestTerminateTimeout = values.requestTerminateTimeout;
      }
      if (values.maxChildren !== vhost.maxChildren) {
        params.maxChildren = values.maxChildren;
      }
      if (values.uploadMaxFilesize !== vhost.uploadMaxFilesize) {
        params.uploadMaxFilesize = values.uploadMaxFilesize;
      }
      if ((values.errorLog === 'ATIVO') !== vhost.errorLog) {
        params.phpLog = values.errorLog === 'ATIVO';
      }
      if (values.phpVersion !== vhost.phpVersion) {
        params.phpVersion = values.phpVersion;
      }
      if (
        (values.outputBuffering === 'ATIVO' ? '4' : '0') !==
        vhost.outputBuffering
      ) {
        params.outputBuffering = values.outputBuffering === 'ATIVO' ? 4 : 0;
      }
      if ((values.log === 'ATIVO') !== vhost.haveLog) {
        params.haveLog = values.log === 'ATIVO';
      }
      if ((values.cache === 'ATIVO') !== vhost.cache) {
        params.cache = values.cache === 'ATIVO';
      }
      if ((values.displayErrors === 'ATIVO') !== vhost.displayErrors) {
        params.displayErrors = values.displayErrors === 'ATIVO' ? 'On' : 'Off';
      }

      if (values.execCGI === 'ATIVO') {
        params.options.push('ExecCGI');
      }

      if (values.followSymLinks === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.options.push('FollowSymLinks');
      }

      if (values.symLinksIfOwnerMatch === 'ATIVO') {
        params.options.push('SymLinksIfOwnerMatch');
      }

      if (values.includes === 'ATIVO') {
        params.options.push('Includes');
      }

      if (values.includesNOEXEC === 'ATIVO') {
        params.options.push('IncludesNOEXEC');
      }

      if (values.multiViews === 'ATIVO') {
        params.options.push('MultiViews');
      }

      if (values.authConfig === 'ATIVO') {
        params.allowOverride.push('AuthConfig');
      }

      if (values.fileInfo === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.allowOverride.push('FileInfo');
      }

      if (values.indexes === 'ATIVO') {
        params.allowOverride.push('Indexes');
      }

      if (values.limit === 'ATIVO') {
        params.allowOverride.push('Limit');
      }

      if (values.options === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.allowOverride.push('Options');
      }

      await api.put(`painel/v1/vhost/${site}`, params);

      toast.fire(t('pages:siteSettingsEdit.editSiteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:siteSettingsEdit.editSiteError'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:siteSettingsEdit.formTitle')}
          description={t('pages:siteSettingsEdit.formSubtitle')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-dos-sites/"
        />
        <Formik
          enableReinitialize
          initialValues={{
            serverName: vhost.serverName,
            documentRoot: vhost.documentRoot,
            documentRootDir: vhost.documentRootDir,
            home: vhost.home,
            cache: vhost.cache ? 'ATIVO' : 'INATIVO',
            modRewrite: vhost.modRewrite ? 'ATIVO' : 'INATIVO',
            requestTerminateTimeout: vhost.requestTerminateTimeout,
            phpVersion: vhost.phpVersion,
            maxChildren: vhost.maxChildren,
            uploadMaxFilesize: vhost.uploadMaxFilesize,
            errorLog: vhost.errorLog ? 'ATIVO' : 'INATIVO',

            eAll: vhost.errorReporting.includes('E_ALL') ? 'ATIVO' : 'INATIVO',
            eError: vhost.errorReporting.includes('E_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eWarning: vhost.errorReporting.includes('E_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eParse: vhost.errorReporting.includes('E_PARSE')
              ? 'ATIVO'
              : 'INATIVO',
            eNotice: vhost.errorReporting.includes('E_NOTICE')
              ? 'ATIVO'
              : 'INATIVO',
            eStrict: vhost.errorReporting.includes('E_STRICT')
              ? 'ATIVO'
              : 'INATIVO',
            eDeprecated: vhost.errorReporting.includes('E_DEPRECATED')
              ? 'ATIVO'
              : 'INATIVO',
            eCoreError: vhost.errorReporting.includes('E_CORE_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eCoreWarning: vhost.errorReporting.includes('E_CORE_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eCompileError: vhost.errorReporting.includes('E_COMPILE_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eCompileWarning: vhost.errorReporting.includes('E_COMPILE_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eUserError: vhost.errorReporting.includes('E_USER_ERROR')
              ? 'ATIVO'
              : 'INATIVO',
            eUserNotice: vhost.errorReporting.includes('E_USER_NOTICE')
              ? 'ATIVO'
              : 'INATIVO',
            eUserWarning: vhost.errorReporting.includes('E_USER_WARNING')
              ? 'ATIVO'
              : 'INATIVO',
            eRecoverableError: vhost.errorReporting.includes(
              'E_RECOVERABLE_ERROR',
            )
              ? 'ATIVO'
              : 'INATIVO',
            eUserDeprecated: vhost.errorReporting.includes('E_USER_DEPRECATED')
              ? 'ATIVO'
              : 'INATIVO',

            displayErrors: vhost.displayErrors ? 'ATIVO' : 'INATIVO',

            execCGI: vhost.options.includes('ExecCGI') ? 'ATIVO' : 'INATIVO',
            followSymLinks: vhost.options.includes('FollowSymLinks')
              ? 'ATIVO'
              : 'INATIVO',
            symLinksIfOwnerMatch: vhost.options.includes('SymLinksIfOwnerMatch')
              ? 'ATIVO'
              : 'INATIVO',
            includes: vhost.options.includes('Includes') ? 'ATIVO' : 'INATIVO',
            includesNOEXEC: vhost.options.includes('IncludesNOEXEC')
              ? 'ATIVO'
              : 'INATIVO',
            multiViews: vhost.options.includes('MultiViews')
              ? 'ATIVO'
              : 'INATIVO',

            authConfig: vhost.allowOverride.includes('AuthConfig')
              ? 'ATIVO'
              : 'INATIVO',
            fileInfo: vhost.allowOverride.includes('FileInfo')
              ? 'ATIVO'
              : 'INATIVO',
            indexes: vhost.allowOverride.includes('Indexes')
              ? 'ATIVO'
              : 'INATIVO',
            limit: vhost.allowOverride.includes('Limit') ? 'ATIVO' : 'INATIVO',
            options: vhost.allowOverride.includes('Options')
              ? 'ATIVO'
              : 'INATIVO',
            outputBuffering:
              vhost.outputBuffering === '0' ? 'INATIVO' : 'ATIVO',
            log: vhost.haveLog ? 'ATIVO' : 'INATIVO',
          }}
          onSubmit={editSite}
          validationSchema={Yup.object().shape({
            uploadMaxFilesize: Yup.number()
              .min(2, 'O valor deve ser no mínimo 2')
              .max(
                limiteUpload,
                `O valor máximo permitido pelo plano é de ${limiteUpload}MB`,
              ),
          })}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:siteSettingsNew.basicSettings')}
                />

                <Form.Group as={Row} controlId="siteSettingsNew.serverName">
                  <Form.Label column sm={2}>
                    {t('pages:siteSettingsNew.serverName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="serverName"
                      plaintext
                      readOnly
                      value={props.values.serverName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.serverName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="siteSettingsNew.documentRoot">
                  <Form.Label column sm={2} className="required">
                    {t('pages:siteSettingsNew.documentRoot')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {props.values.home}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="documentRootDir"
                        value={props.values.documentRootDir}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.documentRootDir &&
                          !!props.errors.documentRootDir
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.documentRootDir}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:siteSettingsNew.advancedSettings')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="advanced-true"
                      label="Sim"
                      value="true"
                      checked={advancedSettings}
                      onChange={() => setAdvancedSettings(true)}
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="advanced-false"
                      label="Não"
                      value="false"
                      checked={!advancedSettings}
                      onChange={() => setAdvancedSettings(false)}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:siteSettingsNew.advancedSettingsInfo')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                {advancedSettings && (
                  <div className="mt-5">
                    <FormSubtitle
                      subTitle={t('pages:siteSettingsNew.advancedSettings')}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.cache">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.cache')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="cache-true"
                          name="cache"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.cache === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="cache-false"
                          name="cache"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.cache === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.modRewrite">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.modRewrite')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="modRewrite-true"
                          name="modRewrite"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.modRewrite === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="modRewrite-false"
                          name="modRewrite"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.modRewrite === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.modRewriteInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.requestTerminateTimeout"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.requestTerminateTimeout')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="requestTerminateTimeout"
                          value={props.values.requestTerminateTimeout}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.requestTerminateTimeout &&
                            !!props.errors.requestTerminateTimeout
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.requestTerminateTimeout}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.phpVersion">
                      <Form.Label column sm={2}>
                        {t('Versão do PHP')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="phpVersion"
                          value={props.values.phpVersion}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        >
                          {vhost.phpVersions.map(version => (
                            <option key={version} value={version}>
                              {version}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.maxChildren"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.maxChildren')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="maxChildren"
                          value={props.values.maxChildren}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.maxChildren &&
                            !!props.errors.maxChildren
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.maxChildren}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.uploadMaxFilesize"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.uploadMaxFilesize')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="number"
                          min="2"
                          max="1024"
                          step="2"
                          name="uploadMaxFilesize"
                          value={props.values.uploadMaxFilesize}
                          onChange={event =>
                            props.setFieldValue(
                              'uploadMaxFilesize',
                              String(event.target.value),
                            )
                          }
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.uploadMaxFilesize &&
                            !!props.errors.uploadMaxFilesize
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.uploadMaxFilesize}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.uploadMaxFilesizeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.errorLog">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.errorLog')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="errorLog-true"
                          name="errorLog"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.errorLog === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="errorLog-false"
                          name="errorLog"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.errorLog === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.phpErrorReportingSettings',
                      )}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.eAll">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eAll')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eAll-ativo"
                          name="eAll"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eAll === 'ATIVO'}
                          onChange={value => {
                            const errorsValues: { [key: string]: string } = {};

                            errorsReporting.forEach(errorOption => {
                              errorsValues[errorOption] = 'ATIVO';
                            });

                            props.setValues({
                              ...props.values,
                              ...errorsValues,
                            });

                            props.handleChange(value);
                          }}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eAll-inativo"
                          name="eAll"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eAll === 'INATIVO'}
                          onChange={value => {
                            const errorsValues: { [key: string]: string } = {};

                            errorsReporting.forEach(errorOption => {
                              errorsValues[errorOption] = 'INATIVO';
                            });

                            props.setValues({
                              ...props.values,
                              ...errorsValues,
                            });

                            props.handleChange(value);
                          }}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eAllInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eError-ativo"
                          name="eError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eError-inativo"
                          name="eError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eWarning">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eWarning-ativo"
                          name="eWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eWarning-inativo"
                          name="eWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eParse">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eParse')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eParse-ativo"
                          name="eParse"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eParse === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eParse-inativo"
                          name="eParse"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eParse === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eParseInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eNotice">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eNotice')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eNotice-ativo"
                          name="eNotice"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eNotice === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eNotice-inativo"
                          name="eNotice"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eNotice === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eNoticeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eStrict">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eStrict')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eStrict-ativo"
                          name="eStrict"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eStrict === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eStrict-inativo"
                          name="eStrict"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eStrict === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eStrictInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eDeprecated"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eDeprecated')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eDeprecated-ativo"
                          name="eDeprecated"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eDeprecated === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eDeprecated-inativo"
                          name="eDeprecated"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eDeprecated === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eDeprecatedInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eCoreError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCoreError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreError-ativo"
                          name="eCoreError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCoreError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreError-inativo"
                          name="eCoreError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCoreError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCoreErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCoreWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCoreWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreWarning-ativo"
                          name="eCoreWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCoreWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreWarning-inativo"
                          name="eCoreWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCoreWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCoreWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCompileError"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCompileError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileError-ativo"
                          name="eCompileError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCompileError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileError-inativo"
                          name="eCompileError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCompileError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCompileErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCompileWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCompileWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileWarning-ativo"
                          name="eCompileWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCompileWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileWarning-inativo"
                          name="eCompileWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCompileWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCompileWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eUserError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserError-ativo"
                          name="eUserError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserError-inativo"
                          name="eUserError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserNotice"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserNotice')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserNotice-ativo"
                          name="eUserNotice"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserNotice === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserNotice-inativo"
                          name="eUserNotice"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserNotice === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserNoticeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserWarning-ativo"
                          name="eUserWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserWarning-inativo"
                          name="eUserWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eRecoverableError"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eRecoverableError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eRecoverableError-ativo"
                          name="eRecoverableError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eRecoverableError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eRecoverableError-inativo"
                          name="eRecoverableError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eRecoverableError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eRecoverableErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserDeprecated"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserDeprecated')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserDeprecated-ativo"
                          name="eUserDeprecated"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserDeprecated === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserDeprecated-inativo"
                          name="eUserDeprecated"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserDeprecated === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserDeprecatedInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.displayErrors"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.displayErrors')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="displayErrors-ativo"
                          name="displayErrors"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.displayErrors === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="displayErrors-inativo"
                          name="displayErrors"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.displayErrors === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.apacheOptionsSettings',
                      )}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.execCGI">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.execCGI')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="execCGI-ativo"
                          name="execCGI"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.execCGI === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="execCGI-inativo"
                          name="execCGI"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.execCGI === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.followSymLinks"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.followSymLinks')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="followSymLinks-ativo"
                          name="followSymLinks"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.followSymLinks === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="followSymLinks-inativo"
                          name="followSymLinks"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.followSymLinks === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.symLinksIfOwnerMatch"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.symLinksIfOwnerMatch')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="symLinksIfOwnerMatch-ativo"
                          name="symLinksIfOwnerMatch"
                          label="Ativo"
                          value="ATIVO"
                          checked={
                            props.values.symLinksIfOwnerMatch === 'ATIVO'
                          }
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="symLinksIfOwnerMatch-inativo"
                          name="symLinksIfOwnerMatch"
                          label="Inativo"
                          value="INATIVO"
                          checked={
                            props.values.symLinksIfOwnerMatch === 'INATIVO'
                          }
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.includes">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.includes')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includes-ativo"
                          name="includes"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.includes === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includes-inativo"
                          name="includes"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.includes === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.includesNOEXEC"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.includesNOEXEC')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includesNOEXEC-ativo"
                          name="includesNOEXEC"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.includesNOEXEC === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includesNOEXEC-inativo"
                          name="includesNOEXEC"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.includesNOEXEC === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.multiViews">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.multiViews')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="multiViews-ativo"
                          name="multiViews"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.multiViews === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="multiViews-inativo"
                          name="multiViews"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.multiViews === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.apacheAllowOverrideSettings',
                      )}
                    />
                    <Form.Group as={Row} controlId="siteSettingsNew.authConfig">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.authConfig')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="authConfig-ativo"
                          name="authConfig"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.authConfig === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="authConfig-inativo"
                          name="authConfig"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.authConfig === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.fileInfo">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.fileInfo')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="fileInfo-ativo"
                          name="fileInfo"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.fileInfo === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="fileInfo-inativo"
                          name="fileInfo"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.fileInfo === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.indexes">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.indexes')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="indexes-ativo"
                          name="indexes"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.indexes === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="indexes-inativo"
                          name="indexes"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.indexes === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.limit">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.limit')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="limit-ativo"
                          name="limit"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.limit === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="limit-inativo"
                          name="limit"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.limit === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.options">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.options')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="options-ativo"
                          name="options"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.options === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="options-inativo"
                          name="options"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.options === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.outputBuffering"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.outputBuffering')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="outputBuffering-ativo"
                          name="outputBuffering"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.outputBuffering === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="outputBuffering-inativo"
                          name="outputBuffering"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.outputBuffering === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.log">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.log')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="log-ativo"
                          name="log"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.log === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="log-inativo"
                          name="log"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.log === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                )}
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/cloud/configuracoes-sites"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
