import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetConsumptionPerDatabaseItem = {
  banco: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
  media: number;
  mediaKb: number;
  mediaMb: number;
  mediaGb: number;
};

type RatchetConsumptionPerDateItem = {
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetDatabaseDiskResponse = {
  data: {
    media: number;
    mediaKb: number;
    mediaMb: number;
    mediaGb: number;
    total: number;
    totalKb: number;
    totalMb: number;
    totalGb: number;
    consumoBancos: RatchetConsumptionPerDatabaseItem[];
    consumoDiario: {
      [key: string]: RatchetConsumptionPerDateItem;
    };
  };
};

type RatchetConsumptionPerDatabase = {
  banco: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
  media: number;
  mediaKb: number;
  mediaMb: number;
  mediaGb: number;
};

type RatchetConsumptionPerDate = {
  date: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetDatabaseDisk = {
  media: number;
  mediaKb: number;
  mediaMb: number;
  mediaGb: number;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
  consumoBancos: RatchetConsumptionPerDatabase[];
  consumoDiario: RatchetConsumptionPerDate[];
};

const initialData: RatchetDatabaseDisk = {
  media: 0,
  mediaKb: 0,
  mediaMb: 0,
  mediaGb: 0,
  total: 0,
  totalKb: 0,
  totalMb: 0,
  totalGb: 0,
  consumoBancos: [],
  consumoDiario: [],
};

function formatResponse(
  ratchetDatabaseDiskResponse: RatchetDatabaseDiskResponse,
): RatchetDatabaseDisk {
  const statisticsPerDay = ratchetDatabaseDiskResponse.data.consumoDiario;

  const isObject = typeof statisticsPerDay === 'object';
  const isNotArray = !Array.isArray(statisticsPerDay);
  const isNotNull = statisticsPerDay !== null;

  const consumptionPerDate: RatchetConsumptionPerDate[] = [];

  if (isObject && isNotArray && isNotNull) {
    const dateKeys = Object.keys(statisticsPerDay);

    dateKeys.forEach(dateKey => {
      consumptionPerDate.push({
        ...statisticsPerDay[dateKey],
        ...{ date: dateKey },
      });
    });
  }

  return {
    media: ratchetDatabaseDiskResponse.data.media,
    mediaKb: ratchetDatabaseDiskResponse.data.mediaKb,
    mediaMb: ratchetDatabaseDiskResponse.data.mediaMb,
    mediaGb: ratchetDatabaseDiskResponse.data.mediaGb,
    total: ratchetDatabaseDiskResponse.data.total,
    totalKb: ratchetDatabaseDiskResponse.data.totalKb,
    totalMb: ratchetDatabaseDiskResponse.data.totalMb,
    totalGb: ratchetDatabaseDiskResponse.data.totalGb,
    consumoBancos: ratchetDatabaseDiskResponse.data.consumoBancos,
    consumoDiario: consumptionPerDate,
  };
}

async function loadRatchetDatabaseDisk(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetDatabaseDisk> {
  const ratchetDatabaseDiskResponse = await api.get<RatchetDatabaseDiskResponse>(
    `painel/v1/ratchet/disco/bd`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const ratchetDatabaseDisk = formatResponse(ratchetDatabaseDiskResponse.data);

  return ratchetDatabaseDisk;
}

export default function useRatchetDatabaseDisk(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetDatabaseDisk>(
    ['ratchetDatabaseDisk', site, initialDate, finalDate],
    () => loadRatchetDatabaseDisk(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetDatabaseDisk = data || initialData;

  return {
    ratchetDatabaseDisk,
    ...rest,
  };
}
