import { useQuery } from 'react-query';

import api from 'services/api';

type AdditionalAccessItemResponse = {
  email: string;
  chave: string;
};

type AdditionalAccessResponse = {
  data: AdditionalAccessItemResponse[];
};

type AdditionalAccess = {
  email: string;
  chave: string;
};

const initialData: AdditionalAccess[] = [];

function formatAccess(
  additionalAccess: AdditionalAccessItemResponse,
): AdditionalAccess {
  const { email, chave } = additionalAccess;

  return {
    email,
    chave,
  };
}

async function loadAdditionalAccess(): Promise<AdditionalAccess[]> {
  const additionalAccessResponse = await api.get<AdditionalAccessResponse>(
    `painel/v1/acesso-adicional`,
  );

  const additionalAccess = additionalAccessResponse.data.data.map(formatAccess);

  return additionalAccess;
}

export default function useAdditionalAccess() {
  const { data, ...rest } = useQuery<AdditionalAccess[]>(
    ['additionalAccess'],
    () => loadAdditionalAccess(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const additionalAccess = data || initialData;

  return {
    additionalAccess,
    ...rest,
  };
}
