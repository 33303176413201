import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import PanelButton from 'components/PanelButton';

interface ModalRenameProps {
  site: string;
  currDir: string;
  loadCount: number;
  setLoadCount: (value: number) => void;
  setLoading: (value: boolean) => void;
  showModalRename: boolean;
  setShowModalRename: (value: boolean) => void;
  selectedCount: number;
  nome: string;
  setToggleCleared: (value: boolean) => void;
  toggleCleared: boolean;
}

interface RequestFormValues {
  newName: string;
}

const ModalRename: React.FC<ModalRenameProps> = ({
  site,
  currDir,
  loadCount,
  setLoadCount,
  setLoading,
  showModalRename,
  setShowModalRename,
  selectedCount,
  nome,
  setToggleCleared,
  toggleCleared,
}) => {
  const { t } = useTranslation();

  const submitFormRename = useCallback(
    async (values: RequestFormValues, formikHelpers) => {
      try {
        setLoading(true);

        await api.put(`https://a.apihn.co/ftp/v1/arquivo`, {
          site,
          nome: values.newName,
          caminho: `${currDir}/${nome}`,
        });

        setLoadCount(loadCount + 1);

        toast.fire('Arquivo renomeado com sucesso!');
      } catch (err) {
        swal.fire({
          title: t('pages:fileManager.renameError'),
          html: err.response.data.error_description,
        });
      } finally {
        setToggleCleared(!toggleCleared);
        formikHelpers.resetForm();
        setShowModalRename(false);
        setLoading(false);
      }
    },
    [
      setLoading,
      nome,
      setShowModalRename,
      site,
      currDir,
      loadCount,
      setLoadCount,
      t,
      setToggleCleared,
      toggleCleared,
    ],
  );

  return (
    <Modal
      show={showModalRename}
      onHide={() => setShowModalRename(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:fileManager.rename')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          newName: '',
        }}
        validationSchema={Yup.object().shape({
          newName: Yup.string().required(
            t('pages:fileManager.validation.newName'),
          ),
        })}
        onSubmit={submitFormRename}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            {selectedCount > 0 && (
              <Modal.Body>
                <Form.Group as={Row} controlId="name">
                  <Form.Label column sm="2" className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control plaintext readOnly defaultValue={nome} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="newName">
                  <Form.Label column sm="2" className="required">
                    {t('pages:fileManager.newName')}
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      name="newName"
                      value={props.values.newName}
                      type="text"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.newName && !!props.errors.newName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.newName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Modal.Body>
            )}

            <Modal.Footer>
              <PanelButton
                type="submit"
                disabled={props.isSubmitting || !props.isValid}
              >
                {props.isSubmitting ? t('common:sending') : t('common:send')}
              </PanelButton>{' '}
              <PanelButton
                variant="secondary"
                onClick={() => setShowModalRename(false)}
              >
                {t('common:close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalRename;
