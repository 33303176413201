import React from 'react';

import {
  Container,
  TotalInfo,
  UsageInfo,
  ResourceName,
  ResourceBar,
  Used,
} from './styles';

interface SiteResourceUsageProps {
  label: string;
  total: string;
  used: string;
  color: string;
  percentage: number;
  icon: React.ReactNode;
  showBar?: boolean;
}

const SiteResourceUsage: React.FC<SiteResourceUsageProps> = ({
  label,
  total,
  used,
  color,
  percentage,
  icon,
  showBar = true,
}) => {
  return (
    <Container className={color}>
      <TotalInfo>
        <div>{icon}</div>
        <h2>{total}</h2>
      </TotalInfo>
      <UsageInfo>
        <ResourceName>{label}</ResourceName>
        <Used>{used}</Used>
      </UsageInfo>
      {showBar && (
        <div>
          <ResourceBar now={percentage} className="mt-3" />
        </div>
      )}
    </Container>
  );
};

export default SiteResourceUsage;
