import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;

  top: 0;
  bottom: 0;

  opacity: 0;
  z-index: -1;
  visibility: hidden;
  overflow: hidden;

  transition: opacity 0.3s linear;
  background: #000 none repeat scroll 0 0;

  @media only screen and (max-width: 991px) {
    &.active {
      opacity: 0.3;
      z-index: 1026;
      visibility: visible;
      overflow: hidden;
      transition: opacity 0.3s linear;
    }
  }
`;
