import React from 'react';
import { FiToggleRight } from 'react-icons/fi';

import {
  Container,
  SelectorButton,
  Info,
  SitePlan,
  SiteName,
  LoadingSpinner,
} from './styles';

interface SiteSelectorButtonProps {
  site: string;
  plan: string;
  loading: boolean;
  onClick(): void;
}

const SiteSelectorButton: React.FC<SiteSelectorButtonProps> = ({
  site,
  plan,
  loading,
  onClick,
}) => {
  return (
    <Container>
      <SelectorButton onClick={onClick}>
        <Info>
          <div>
            <SitePlan>{plan}</SitePlan>
            <SiteName className="text-muted">{site}</SiteName>
          </div>

          <div className="ml-3">
            {loading ? (
              <LoadingSpinner animation="border" size="sm" />
            ) : (
              <FiToggleRight size={15} color="#333333" />
            )}
          </div>
        </Info>
      </SelectorButton>
    </Container>
  );
};

export default SiteSelectorButton;
