import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface EmailsActionsProps {
  selectedCount: number;
  selectedRows: { id: number }[];
  deleteEmail: () => void;
  loading: boolean;
}

const EmailsActions: React.FC<EmailsActionsProps> = ({
  selectedCount,
  selectedRows,
  deleteEmail,
  loading,
}) => {
  const { t } = useTranslation();

  const emailHashId = hashIds.encode(selectedRows[0].id);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <TableButton
          forwardedAs={Link}
          to={`/emails/${emailHashId}/editar`}
          className="mr-2"
          size="sm"
          disabled={loading}
        >
          {t('common:button.edit')}
        </TableButton>
      )}
      <TableButton
        className="mr-2"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={() => deleteEmail()}
      >
        {t('common:button.delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default EmailsActions;
