import styled from 'styled-components';

export const Container = styled.div``;

export const SuspensionInfo = styled.div`
  p {
    text-indent: 32px;
  }
  margin-bottom: 48px;
`;
