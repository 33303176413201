import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaChevronLeft, FaChevronRight, FaCogs } from 'react-icons/fa';
import { ListGroup } from 'react-bootstrap';
import { MdArrowBack } from 'react-icons/md';
import { addMonths, format, subMonths } from 'date-fns';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ptBr from 'date-fns/locale/pt-BR/index';

import { StoreState } from 'store/createStore';

import useMauticSms from 'hooks/useMauticSms';
import useRatchetSms from 'hooks/useRatchetSms';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';
import PanelButton from 'components/PanelButton';

import { Container } from './styles';

const MauticSmsConsumption: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const {
    mauticSms,
    isLoading: isLoadingMauticService,
    isFetched,
  } = useMauticSms(idSite);
  const { ratchetSms, isFetching, isLoading, isError } = useRatchetSms(
    idSite,
    format(selectedDate, 'MM'),
    format(selectedDate, 'yyyy'),
  );

  const formattedDate = format(selectedDate, "MMMM 'de' yyyy", {
    locale: ptBr,
  });

  const disableNextDate = verifyIfShouldDisableNextDate(selectedDate);

  function verifyIfShouldDisableNextDate(ratchetDate: Date) {
    let nextDate = ratchetDate;

    nextDate = addMonths(ratchetDate, 1);

    return nextDate.getTime() > new Date().getTime();
  }

  if (isError) {
    return <Error />;
  }

  if (!mauticSms.contratado && isFetched) {
    return <Error message="Serviço do Mautic SMS não encontrado" />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title="Mautic - SMS"
          description="Relatório de consumo detalhado do serviço Mautic - SMS"
        />
        <TableSubHeader>
          <div className="d-flex align-items-center">
            <div className="table-section d-flex align-items-center">
              <PanelButton
                className="btn btn-secondary d-flex align-items-center"
                as={Link}
                to="/aplicativos/mautic-sms"
              >
                <MdArrowBack size={15} className="mr-1" />
                Voltar
              </PanelButton>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <TableButton
              variant="secondary"
              className="secondary"
              disabled={isFetching}
              onClick={() => {
                setSelectedDate(subMonths(selectedDate, 1));
              }}
            >
              <FaChevronLeft />
            </TableButton>
            <span className="selected-date mr-2">{formattedDate}</span>
            <TableButton
              variant="secondary"
              className="secondary"
              disabled={isFetching || disableNextDate}
              onClick={() => {
                setSelectedDate(addMonths(selectedDate, 1));
              }}
            >
              <FaChevronRight />
            </TableButton>
          </div>
        </TableSubHeader>

        <ListGroup variant="flush">
          <ListGroup.Item className="border-top">
            Total do pacote mensal: {ratchetSms.quantidadeMensal}
          </ListGroup.Item>
          <ListGroup.Item>
            Total extra: {ratchetSms.quantidadeAdicional}
          </ListGroup.Item>
          <ListGroup.Item>Total: {ratchetSms.quantidadeTotal}</ListGroup.Item>
          <ListGroup.Item>
            Envios Consumidos: {ratchetSms.quantidadeConsumida}
          </ListGroup.Item>
        </ListGroup>

        <TableSubHeader>
          <div className="d-flex align-items-center">
            <div className="table-section">Consumos por dia</div>
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={isLoading || isLoadingMauticService}
            columns={[
              {
                name: 'Dia',
                selector: 'data',
                minWidth: '180px',
              },
              {
                name: 'Consumo',
                selector: 'consumo',
                minWidth: '180px',
              },
            ]}
            data={ratchetSms.consumoDiario}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default MauticSmsConsumption;
