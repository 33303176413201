import styled from 'styled-components';

export const Container = styled.div`
  font-size: 14px;
  font-weight: 500;

  letter-spacing: 1px;

  margin-bottom: 20px;
  padding-bottom: 10px;

  border-bottom: 1px solid rgba(204, 204, 204, 0.35);

  color: #2c3e50;

  @media only screen and (max-width: 1366px) {
    font-size: 12px;
  }
`;
