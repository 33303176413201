import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader: React.FC = () => {
  return (
    <div className="p-3">
      <ContentLoader
        speed={1.5}
        width={230}
        height={540}
        title="Carregando..."
        viewBox="0 0 230 540"
        backgroundColor="#2e4775"
        foregroundColor="#b7c0cd"
      >
        <rect x="3" y="0" rx="5" ry="5" width="70" height="10" />

        <rect x="35" y="40" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="80" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="120" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="160" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="200" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="240" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="280" rx="5" ry="5" width="170" height="10" />

        <rect x="3" y="35" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="75" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="115" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="155" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="195" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="235" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="275" rx="4" ry="4" width="20" height="20" />

        <rect x="3" y="320" rx="5" ry="5" width="70" height="10" />

        <rect x="35" y="360" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="400" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="440" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="480" rx="5" ry="5" width="170" height="10" />
        <rect x="35" y="520" rx="5" ry="5" width="170" height="10" />

        <rect x="3" y="355" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="395" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="435" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="475" rx="4" ry="4" width="20" height="20" />
        <rect x="3" y="515" rx="4" ry="4" width="20" height="20" />
      </ContentLoader>
    </div>
  );
};

export default Loader;
