import styled from 'styled-components';

export const Container = styled.div``;

export const BarCodeContent = styled.div`
  overflow: auto;
  img {
    width: 512px;
  }
`;
