import { useQuery } from 'react-query';

import api from 'services/api';

type MauticSmsResponse = {
  data: {
    idCliente: number;
    idSite: number;
    contratado: boolean;
    quantidadeMensal: number;
    quantidadeAdicional: number;
    quantidadeTotal: number;
    quantidadeConsumida: number;
    quantidadeExcedida: number;
    mes: string;
    ano: string;
    produtosDisponiveis: {
      idProduto: number;
      idProdutoCategoria: number;
      categoria: string;
      produto: string;
      setup: number;
      valor: number;
      envioQtd: number;
      tipoProduto: string;
      contratado: boolean;
      envioExtra: boolean;
      gratis: boolean;
    }[];
  };
};

type MauticSms = {
  idCliente: number;
  idSite: number;
  contratado: boolean;
  quantidadeMensal: number;
  quantidadeAdicional: number;
  quantidadeTotal: number;
  quantidadeConsumida: number;
  quantidadeExcedida: number;
  mes: string;
  ano: string;
  produtosDisponiveis: {
    idProduto: number;
    idProdutoCategoria: number;
    categoria: string;
    produto: string;
    setup: number;
    valor: number;
    envioQtd: number;
    tipoProduto: string;
    contratado: boolean;
    envioExtra: boolean;
    gratis: boolean;
  }[];
};

const initialData: MauticSms = {
  idCliente: 0,
  idSite: 0,
  contratado: false,
  quantidadeMensal: 0,
  quantidadeAdicional: 0,
  quantidadeTotal: 0,
  quantidadeConsumida: 0,
  quantidadeExcedida: 0,
  mes: '',
  ano: '',
  produtosDisponiveis: [],
};

async function loadMauticSms(idSite: number): Promise<MauticSms> {
  const response = await api.get<MauticSmsResponse>(
    `painel/v1/site/${idSite}/mautic-sms`,
  );

  return {
    idCliente: response.data.data.idCliente,
    idSite: response.data.data.idSite,
    contratado: response.data.data.contratado,
    quantidadeMensal: response.data.data.quantidadeMensal,
    quantidadeAdicional: response.data.data.quantidadeAdicional,
    quantidadeTotal: response.data.data.quantidadeTotal,
    quantidadeConsumida: response.data.data.quantidadeConsumida,
    quantidadeExcedida: response.data.data.quantidadeExcedida,
    mes: response.data.data.mes,
    ano: response.data.data.ano,
    produtosDisponiveis: response.data.data.produtosDisponiveis,
  };
}

export default function useMauticSms(idSite: number) {
  const { data, ...rest } = useQuery<MauticSms>(
    ['mauticSms', idSite],
    () => loadMauticSms(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
    },
  );

  const mauticSms = data || initialData;

  return { mauticSms, ...rest };
}
