import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import Loading from 'components/Loading';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import filter from 'utils/filter';
import FtpSubaccountActions from './components/FtpSubaccountActions';

import { Container } from './styles';

interface FtpResponse {
  data: {
    usuario: string;
  };
}

interface FtpSubaccountItemResponse {
  idconta: string;
  idSubconta: string;
  diretorio: string;
  subconta: string;
}

interface FtpSubaccountResponse {
  data: FtpSubaccountItemResponse[];
}

interface DeleteFtpSubaccountResponse {
  data: FtpSubaccountItemResponse[];
}

interface FtpSubaccount {
  idConta: number;
  idSubconta: number;
  diretorio: string;
  subconta: string;
}

interface RowInfo {
  selectedCount: number;
  selectedRows: FtpSubaccount[];
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [, setFtpUser] = useState('');
  const [ftpSubaccounts, setFtpSubaccounts] = useState<FtpSubaccount[]>([]);
  const [filteredFtpSubaccounts, setFilteredFtpSubaccounts] = useState<
    FtpSubaccount[]
  >([]);

  const [filterText, setFilterText] = useState('');
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<RowInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  function formatSubaccount(subaccount: FtpSubaccountItemResponse) {
    return {
      idConta: parseInt(subaccount.idconta, 10),
      idSubconta: parseInt(subaccount.idSubconta, 10),
      diretorio: subaccount.diretorio,
      subconta: subaccount.subconta,
    };
  }

  useEffect(() => {
    async function loadFtpSubaccounts() {
      try {
        setError(false);
        setLoading(true);

        const ftpResponse = await api.get<FtpResponse>(
          `painel/v1/site/${idSite}/ftp`,
        );

        const { usuario } = ftpResponse.data.data;

        setFtpUser(usuario);

        const ftpSubaccountsResponse = await api.get<FtpSubaccountResponse>(
          `painel/v1/subconta-ftp`,
          {
            params: {
              conta: usuario,
            },
          },
        );

        const formattedSubaccounts = ftpSubaccountsResponse.data.data.map(
          formatSubaccount,
        );

        setFtpSubaccounts(formattedSubaccounts);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadFtpSubaccounts();
  }, [idSite]);

  useEffect(() => {
    const filtered = filter(
      ftpSubaccounts,
      ['subconta', 'diretorio'],
      filterText,
    );

    setFilteredFtpSubaccounts(filtered);
  }, [ftpSubaccounts, filterText]);

  async function deleteFtpSubaccount() {
    try {
      setUpdating(true);

      const subcontas = selectedRowsInfo.selectedRows.map(
        ({ subconta }) => subconta,
      );

      const deleteSubaccountsResponse = await api.delete<DeleteFtpSubaccountResponse>(
        `painel/v1/subconta-ftp`,
        {
          params: {
            subcontas,
          },
        },
      );

      const formattedSubaccounts = deleteSubaccountsResponse.data.data.map(
        formatSubaccount,
      );

      setFtpSubaccounts(formattedSubaccounts);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:ftpSubaccounts.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:ftpSubaccounts.deleteFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  const columns = [
    {
      name: 'Usuário',
      selector: 'subconta',
    },
    {
      name: 'Diretório',
      selector: 'diretorio',
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:ftpSubaccounts.title')}
          description={t('pages:ftpSubaccounts.description')}
          directHelpLink="https://ajuda.hostnet.com.br/subcontas-de-ftp/"
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/site/subconta-ftp/adicionar"
              size="sm"
            >
              {t('pages:ftpSubaccounts.newFtpSubaccount')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            selectableRows
            pagination
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            clearSelectedRows={toggleCleared}
            data={filteredFtpSubaccounts}
            columns={columns}
            progressPending={loading}
            progressComponent={<Loading />}
            contextComponent={
              <FtpSubaccountActions
                updating={updating}
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                deleteFtpSubaccount={deleteFtpSubaccount}
              />
            }
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
