import React from 'react';
import { Button } from 'react-bootstrap';
import { StyledComponentProps } from 'styled-components';

import { StyledTableButton } from './styles';

type TableButtonProps = StyledComponentProps<Button, {}, {}, ''>;

const TableButton: React.FC<TableButtonProps> = props => {
  return <StyledTableButton {...props} />;
};

export default TableButton;
