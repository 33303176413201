import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

export const StyledAlert = styled(Alert)`
  font-size: 15px;
  padding: 5px 15px;
  border-radius: 2px;
  margin-bottom: 0;

  &.alert-warning {
    color: #fff;
    background-color: #f3b800;
    border-color: #f3b800;
  }
  &.alert-info {
    color: #fff;
    background-color: #30a96e;
    border-color: #30a96e;
  }
`;
