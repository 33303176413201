import React, { useContext } from 'react';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { useAccordionToggle } from 'react-bootstrap';

import AccordionContext from 'react-bootstrap/AccordionContext';

import { ButtonToggle } from './styles';

interface SideMenuAccordionToggleProps {
  children: React.ReactNode;
  eventKey: string;
  callback?: () => void;
}

const SideMenuAccordionToggle: React.FC<SideMenuAccordionToggleProps> = ({
  children,
  eventKey,
  callback,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(),
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ButtonToggle
      type="button"
      onClick={decoratedOnClick}
      className={isCurrentEventKey ? 'open' : ''}
    >
      {children}
      <div>{isCurrentEventKey ? <FiChevronDown /> : <FiChevronRight />}</div>
    </ButtonToggle>
  );
};

export default SideMenuAccordionToggle;
