import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

interface File {
  nome: string;
  tipo: string;
  tamanho: string;
  caminho: string;
  ultModificacao: string;
  ultModificacaoTs: number;
  owner: string;
  permissao: string;
  permissaoSimbolica: string;
}

interface ModalPermissionsProps {
  site: string;
  currDir: string;
  setLoading: (value: boolean) => void;
  showModalPermissions: boolean;
  setShowModalPermissions: (value: boolean) => void;
  selectedRows: File[];
  loadCount: number;
  setLoadCount: (value: number) => void;
  setToggleCleared: (value: boolean) => void;
  toggleCleared: boolean;
}

const ModalPermissions: React.FC<ModalPermissionsProps> = ({
  site,
  currDir,
  setLoading,
  showModalPermissions,
  setShowModalPermissions,
  selectedRows,
  loadCount,
  setLoadCount,
  setToggleCleared,
  toggleCleared,
}) => {
  const { t } = useTranslation();

  const [ownerRead, setOwnerRead] = useState<boolean>(false);
  const [ownerWrite, setOwnerWrite] = useState<boolean>(false);
  const [ownerExec, setOwnerExec] = useState<boolean>(false);

  const [groupRead, setGroupRead] = useState<boolean>(false);
  const [groupWrite, setGroupWrite] = useState<boolean>(false);
  const [groupExec, setGroupExec] = useState<boolean>(false);

  const [publicRead, setPublicRead] = useState<boolean>(false);
  const [publicWrite, setPublicWrite] = useState<boolean>(false);
  const [publicExec, setPublicExec] = useState<boolean>(false);

  const [numericPermissions, setNumericPermissions] = useState<string>('000');

  function setPermissions(owner: string, group: string, publics: string): void {
    setOwnerRead(owner[0] === '1');
    setOwnerWrite(owner[1] === '1');
    setOwnerExec(owner[2] === '1');
    setGroupRead(group[0] === '1');
    setGroupWrite(group[1] === '1');
    setGroupExec(group[2] === '1');
    setPublicRead(publics[0] === '1');
    setPublicWrite(publics[1] === '1');
    setPublicExec(publics[2] === '1');
  }

  function changeNumericPermission(value: string) {
    const mask = ['000', '001', '010', '011', '100', '101', '110', '111'];
    setPermissions(mask[+value[0]], mask[+value[1]], mask[+value[2]]);
  }

  useEffect(() => {
    if (selectedRows[0] && selectedRows[0].permissao) {
      setNumericPermissions(selectedRows[0].permissao);
      const mask = ['000', '001', '010', '011', '100', '101', '110', '111'];
      setPermissions(
        mask[+selectedRows[0].permissao[0]],
        mask[+selectedRows[0].permissao[1]],
        mask[+selectedRows[0].permissao[2]],
      );
    }
  }, [selectedRows]);

  async function submitPermissions() {
    try {
      setLoading(true);

      await api.put(`https://a.apihn.co/ftp/v1/arquivo`, {
        site,
        permissao: parseInt(numericPermissions, 10),
        caminho: `${currDir}/${selectedRows[0].nome}`,
      });

      setLoadCount(loadCount + 1);

      toast.fire(t('pages:fileManager.updatePermissionsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:fileManager.updatePermissionsError'),
        html: err.response.data.error_description,
      });
    } finally {
      setToggleCleared(!toggleCleared);
      setShowModalPermissions(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    const a = +ownerRead * 4 + +ownerWrite * 2 + +ownerExec * 1;
    const b = +groupRead * 4 + +groupWrite * 2 + +groupExec * 1;
    const c = +publicRead * 4 + +publicWrite * 2 + +publicExec * 1;
    setNumericPermissions(`${a}${b}${c}`);
  }, [
    groupExec,
    groupRead,
    groupWrite,
    ownerExec,
    ownerRead,
    ownerWrite,
    publicExec,
    publicRead,
    publicWrite,
  ]);
  return (
    <Modal
      show={showModalPermissions}
      onHide={() => setShowModalPermissions(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:fileManager.permissions')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} controlId="fileManager.owner_permission">
          <Form.Label column sm={3}>
            {t('pages:fileManager.owner')}
          </Form.Label>
          <Col sm={9} className="align-self-center">
            <Form.Check
              inline
              custom
              checked={ownerRead}
              onChange={() => setOwnerRead(!ownerRead)}
              id="fileManager.owner_permission.read"
              type="checkbox"
              label="Ler"
              name="fileManager.owner_permission.read"
            />
            <Form.Check
              inline
              custom
              checked={ownerWrite}
              onChange={() => setOwnerWrite(!ownerWrite)}
              id="fileManager.owner_permission.write"
              type="checkbox"
              label="Gravar"
              name="fileManager.owner_permission.write"
            />
            <Form.Check
              inline
              custom
              checked={ownerExec}
              onChange={() => setOwnerExec(!ownerExec)}
              id="fileManager.owner_permission.exec"
              type="checkbox"
              label="Executar"
              name="fileManager.owner_permission.exec"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="fileManager.group_permission">
          <Form.Label column sm={3}>
            {t('pages:fileManager.group')}
          </Form.Label>
          <Col sm={9} className="align-self-center">
            <Form.Check
              inline
              custom
              checked={groupRead}
              onChange={() => setGroupRead(!groupRead)}
              id="fileManager.group_permission.read"
              type="checkbox"
              label="Ler"
              name="fileManager.group_permission.read"
            />
            <Form.Check
              inline
              custom
              checked={groupWrite}
              onChange={() => setGroupWrite(!groupWrite)}
              id="fileManager.group_permission.write"
              type="checkbox"
              label="Gravar"
              name="fileManager.group_permission.write"
            />
            <Form.Check
              inline
              custom
              checked={groupExec}
              onChange={() => setGroupExec(!groupExec)}
              id="fileManager.group_permission.exec"
              type="checkbox"
              label="Executar"
              name="fileManager.group_permission.exec"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="fileManager.public_permission">
          <Form.Label column sm={3}>
            {t('pages:fileManager.publics')}
          </Form.Label>
          <Col sm={9} className="align-self-center">
            <Form.Check
              inline
              custom
              checked={publicRead}
              onChange={() => setPublicRead(!publicRead)}
              id="fileManager.public_permission.read"
              type="checkbox"
              label="Ler"
              name="fileManager.public_permission.read"
            />
            <Form.Check
              inline
              custom
              checked={publicWrite}
              onChange={() => setPublicWrite(!publicWrite)}
              id="fileManager.public_permission.write"
              type="checkbox"
              label="Gravar"
              name="fileManager.public_permission.write"
            />
            <Form.Check
              inline
              custom
              checked={publicExec}
              onChange={() => setPublicExec(!publicExec)}
              id="fileManager.public_permission.exec"
              type="checkbox"
              label="Executar"
              name="fileManager.public_permission.exec"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="fileManager.numeric_permission">
          <Form.Label column sm="3" className="required">
            {t('pages:fileManager.numericValue')}
          </Form.Label>
          <Col sm="9">
            <Form.Control
              name="newName"
              type="text"
              value={numericPermissions}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setNumericPermissions(event.target.value)
              }
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) =>
                changeNumericPermission(event.target.value)
              }
            />
            <Form.Control.Feedback type="invalid" />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <PanelButton
          type="submit"
          disabled={
            !!selectedRows[0] &&
            !!(numericPermissions === selectedRows[0].permissao)
          }
          onClick={() => submitPermissions()}
        >
          {t('common:send')}
        </PanelButton>{' '}
        <PanelButton
          variant="secondary"
          onClick={() => setShowModalPermissions(false)}
        >
          {t('common:close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPermissions;
