import React from 'react';

interface CustomizedLabelProps {
  x?: number;
  y?: number;
  stroke?: string;
  value?: number;
  measure?: string;
}

const CustomizedLabel: React.FC<CustomizedLabelProps> = props => {
  const { x = 0, y = 0, stroke = '#212529', value = 0, measure = '' } = props;

  return (
    <text
      x={x}
      y={y}
      dy={-10}
      fill={stroke}
      fontSize={12}
      fontWeight="bold"
      textAnchor="middle"
    >
      {value} {measure}
    </text>
  );
};

export default CustomizedLabel;
