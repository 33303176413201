import { useQuery } from 'react-query';

import api from 'services/api';

type MySqlLimitsResponse = {
  data: {
    idProduto: number;
    produto: string;
    min: {
      memory: number;
      virtualCpu: number;
      keyBufferSize: number;
      queryCacheSize: number;
      tmpTableSize: number;
      innodbBufferPoolSize: number;
      innodbLogBufferSize: number;
      sortBufferSize: number;
      readBufferSize: number;
      readRndBufferSize: number;
      joinBufferSize: number;
      threadStack: number;
      maxConnections: number;
      maxUserConnections: number;
    };
    max: {
      memory: number;
      virtualCpu: number;
      keyBufferSize: number;
      queryCacheSize: number;
      tmpTableSize: number;
      innodbBufferPoolSize: number;
      innodbLogBufferSize: number;
      sortBufferSize: number;
      readBufferSize: number;
      readRndBufferSize: number;
      joinBufferSize: number;
      threadStack: number;
      maxConnections: number;
      maxUserConnections: number;
    };
  }[];
};

type MySqlLimits = {
  idProduto: number;
  produto: string;
  min: {
    memory: number;
    virtualCpu: number;
    keyBufferSize: number;
    queryCacheSize: number;
    tmpTableSize: number;
    innodbBufferPoolSize: number;
    innodbLogBufferSize: number;
    sortBufferSize: number;
    readBufferSize: number;
    readRndBufferSize: number;
    joinBufferSize: number;
    threadStack: number;
    maxConnections: number;
    maxUserConnections: number;
  };
  max: {
    memory: number;
    virtualCpu: number;
    keyBufferSize: number;
    queryCacheSize: number;
    tmpTableSize: number;
    innodbBufferPoolSize: number;
    innodbLogBufferSize: number;
    sortBufferSize: number;
    readBufferSize: number;
    readRndBufferSize: number;
    joinBufferSize: number;
    threadStack: number;
    maxConnections: number;
    maxUserConnections: number;
  };
};

const initialData: MySqlLimits = {
  idProduto: 0,
  produto: '',
  min: {
    memory: 0,
    virtualCpu: 0,
    keyBufferSize: 0,
    queryCacheSize: 0,
    tmpTableSize: 0,
    innodbBufferPoolSize: 0,
    innodbLogBufferSize: 0,
    sortBufferSize: 0,
    readBufferSize: 0,
    readRndBufferSize: 0,
    joinBufferSize: 0,
    threadStack: 0,
    maxConnections: 0,
    maxUserConnections: 0,
  },
  max: {
    memory: 0,
    virtualCpu: 0,
    keyBufferSize: 0,
    queryCacheSize: 0,
    tmpTableSize: 0,
    innodbBufferPoolSize: 0,
    innodbLogBufferSize: 0,
    sortBufferSize: 0,
    readBufferSize: 0,
    readRndBufferSize: 0,
    joinBufferSize: 0,
    threadStack: 0,
    maxConnections: 0,
    maxUserConnections: 0,
  },
};

async function loadMySqlLimits(idPlan: number): Promise<MySqlLimits> {
  const response = await api.get<MySqlLimitsResponse>(
    `clientes/v1/produto/${idPlan}/mysql`,
  );
  const mysqlLimits = response.data.data[0];

  return {
    idProduto: mysqlLimits.idProduto,
    produto: mysqlLimits.produto,
    min: {
      memory: mysqlLimits.min.memory,
      virtualCpu: mysqlLimits.min.virtualCpu,
      keyBufferSize: mysqlLimits.min.keyBufferSize / 1024,
      queryCacheSize: mysqlLimits.min.queryCacheSize / 1024,
      tmpTableSize: mysqlLimits.min.tmpTableSize / 1024,
      innodbBufferPoolSize: mysqlLimits.min.innodbBufferPoolSize / 1024,
      innodbLogBufferSize: mysqlLimits.min.innodbLogBufferSize / 1024,
      sortBufferSize: mysqlLimits.min.sortBufferSize,
      readBufferSize: mysqlLimits.min.readBufferSize,
      readRndBufferSize: mysqlLimits.min.readRndBufferSize,
      joinBufferSize: mysqlLimits.min.joinBufferSize,
      threadStack: mysqlLimits.min.threadStack,
      maxConnections: mysqlLimits.min.maxConnections,
      maxUserConnections: mysqlLimits.min.maxUserConnections,
    },
    max: {
      memory: mysqlLimits.max.memory,
      virtualCpu: mysqlLimits.max.virtualCpu,
      keyBufferSize: mysqlLimits.max.keyBufferSize / 1024,
      queryCacheSize: mysqlLimits.max.queryCacheSize / 1024,
      tmpTableSize: mysqlLimits.max.tmpTableSize / 1024,
      innodbBufferPoolSize: mysqlLimits.max.innodbBufferPoolSize / 1024,
      innodbLogBufferSize: mysqlLimits.max.innodbLogBufferSize / 1024,
      sortBufferSize: mysqlLimits.max.sortBufferSize,
      readBufferSize: mysqlLimits.max.readBufferSize,
      readRndBufferSize: mysqlLimits.max.readRndBufferSize,
      joinBufferSize: mysqlLimits.max.joinBufferSize,
      threadStack: mysqlLimits.max.threadStack,
      maxConnections: mysqlLimits.max.maxConnections,
      maxUserConnections: mysqlLimits.max.maxUserConnections,
    },
  };
}

export default function useMySqlLimits(idPlan: number) {
  const { data, ...rest } = useQuery<MySqlLimits>(
    ['mysqlLimits', idPlan],
    () => loadMySqlLimits(idPlan),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
    },
  );

  const mysqlLimits = data || initialData;

  return { mysqlLimits, ...rest };
}
