import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Form, Col, Row, Collapse } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';
import Loading from 'components/Loading';

import api from 'services/api';
import swal from 'services/swal';
import hashIds from 'services/hashIds';
import toast from 'services/toast';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type DnsProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
  }[];
}

interface DnsResponse {
  data: string[];
}

interface DnsFormValues {
  nameServer1: string;
  ipv41: string;
  ipv61: string;
  nameServer2: string;
  ipv42: string;
  ipv62: string;
  nameServer3: string;
  ipv43: string;
  ipv63: string;
  nameServer4: string;
  ipv44: string;
  ipv64: string;
  nameServer5: string;
  ipv45: string;
  ipv65: string;
  nameServer6: string;
  ipv46: string;
  ipv66: string;
}

const Dns: React.FC<DnsProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [dns, setDns] = useState<string[]>([]);
  const [domainName, setDomainName] = useState('');

  const [showIpsInputs, setShowIpsInputs] = useState(false);

  useEffect(() => {
    async function loadDns() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainResponse = await api.get<DomainResponse>(
          `registrobr/v1/dominio/${idDomain}`,
        );

        const dnsResponse = await api.get<DnsResponse>(
          `registrobr/v1/dominio/${idDomain}/dns`,
        );

        setDomainName(domainResponse.data.data[0].dominio);
        setDns(dnsResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDns();
  }, [idDomain]);

  async function updateDns(
    values: DnsFormValues,
    formikHelpers: FormikHelpers<DnsFormValues>,
  ) {
    try {
      const nameServers = [
        values.nameServer1,
        values.nameServer2,
        values.nameServer3,
        values.nameServer4,
        values.nameServer5,
        values.nameServer6,
      ].filter(nameServer => nameServer !== '');

      const nameServersIps = {
        nameServer1IPv4: values.ipv41.trim(),
        nameServer1IPv6: values.ipv61.trim(),
        nameServer2IPv4: values.ipv42.trim(),
        nameServer2IPv6: values.ipv62.trim(),
        nameServer3IPv4: values.ipv43.trim(),
        nameServer3IPv6: values.ipv63.trim(),
        nameServer4IPv4: values.ipv44.trim(),
        nameServer4IPv6: values.ipv64.trim(),
        nameServer5IPv4: values.ipv45.trim(),
        nameServer5IPv6: values.ipv65.trim(),
        nameServer6IPv4: values.ipv46.trim(),
        nameServer6IPv6: values.ipv66.trim(),
      };

      const filteredNameServersIps = Object.fromEntries(
        Object.entries(nameServersIps).filter(([, v]) => v !== ''),
      );

      const updateDnsResponse = await api.put<DnsResponse>(
        `registrobr/v1/dominio/${idDomain}/dns`,
        {
          idDominio: idDomain,
          nameServers,
          ...filteredNameServersIps,
        },
      );
      setDns(updateDnsResponse.data.data);

      toast.fire(t('pages:brDomainDns.updateDnsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:brDomainDns.updateDnsFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:brDomainDns.title')}
          description={t('pages:brDomainDns.description')}
          startOpen
          helpContent={
            <div>
              <ul>
                <li>{t('pages:brDomainDns.helpContent.help1')}.</li>
                <li>{t('pages:brDomainDns.helpContent.help2')}.</li>
              </ul>
            </div>
          }
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer1: dns[0] ?? '',
            ipv41: '',
            ipv61: '',
            nameServer2: dns[1] ?? '',
            ipv42: '',
            ipv62: '',
            nameServer3: dns[2] ?? '',
            ipv43: '',
            ipv63: '',
            nameServer4: dns[3] ?? '',
            ipv44: '',
            ipv64: '',
            nameServer5: dns[4] ?? '',
            ipv45: '',
            ipv65: '',
            nameServer6: dns[5] ?? '',
            ipv46: '',
            ipv66: '',
          }}
          validationSchema={Yup.object().shape({
            nameServer1: Yup.string()
              .min(4, t('pages:brDomainDns.validations.minLength'))
              .required(t('pages:brDomainDns.validations.required')),
            nameServer2: Yup.string()
              .min(4, 'pages:brDomainDns.validations.minLength')
              .required(t('pages:brDomainDns.validations.required')),
          })}
          onSubmit={updateDns}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={domainName} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="dns1">
                  <Form.Label column sm={2} className="required">
                    Name server 1
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer1"
                      value={props.values.nameServer1}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer1 &&
                        !!props.errors.nameServer1
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer1}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('pages:brDomainDns.configureIps')}{' '}
                      <PanelButton
                        variant="link"
                        className="p-0"
                        onClick={() => setShowIpsInputs(value => !value)}
                      >
                        {t('common:clickHere').toLowerCase()}
                      </PanelButton>
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv41">
                      <Form.Label column sm={2}>
                        IPv4 1
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv41"
                          value={props.values.ipv41}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv41 && !!props.errors.ipv41
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv41}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv61">
                      <Form.Label column sm={2}>
                        IPv6 1
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv61"
                          value={props.values.ipv61}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv61 && !!props.errors.ipv61
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv61}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>

                <Form.Group as={Row} controlId="dns2">
                  <Form.Label column sm={2} className="required">
                    Name server 2
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer2"
                      value={props.values.nameServer2}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer2 &&
                        !!props.errors.nameServer2
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer2}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv42">
                      <Form.Label column sm={2}>
                        IPv4 2
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv42"
                          value={props.values.ipv42}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv42 && !!props.errors.ipv42
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv42}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv62">
                      <Form.Label column sm={2}>
                        IPv6 2
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv62"
                          value={props.values.ipv62}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv62 && !!props.errors.ipv62
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv62}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>

                <Form.Group as={Row} controlId="dns3">
                  <Form.Label column sm={2}>
                    Name server 3
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer3"
                      value={props.values.nameServer3}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer3 &&
                        !!props.errors.nameServer3
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer3}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv43">
                      <Form.Label column sm={2}>
                        IPv4 3
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv43"
                          value={props.values.ipv43}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv43 && !!props.errors.ipv43
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv43}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv63">
                      <Form.Label column sm={2}>
                        IPv6 3
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv63"
                          value={props.values.ipv63}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv63 && !!props.errors.ipv63
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv63}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>

                <Form.Group as={Row} controlId="dns4">
                  <Form.Label column sm={2}>
                    Name server 4
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer4"
                      value={props.values.nameServer4}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer4 &&
                        !!props.errors.nameServer4
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer4}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv44">
                      <Form.Label column sm={2}>
                        IPv4 4
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv44"
                          value={props.values.ipv44}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv44 && !!props.errors.ipv44
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv44}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv64">
                      <Form.Label column sm={2}>
                        IPv6 4
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv64"
                          value={props.values.ipv64}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv64 && !!props.errors.ipv64
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv64}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>

                <Form.Group as={Row} controlId="dns5">
                  <Form.Label column sm={2}>
                    Name server 5
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer5"
                      value={props.values.nameServer5}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer5 &&
                        !!props.errors.nameServer5
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer5}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv45">
                      <Form.Label column sm={2}>
                        IPv4 5
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv45"
                          value={props.values.ipv45}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv45 && !!props.errors.ipv45
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv45}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv65">
                      <Form.Label column sm={2}>
                        IPv6 5
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv65"
                          value={props.values.ipv65}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv65 && !!props.errors.ipv65
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv65}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>

                <Form.Group as={Row} controlId="dns6">
                  <Form.Label column sm={2}>
                    Name server 6
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer6"
                      value={props.values.nameServer6}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer6 &&
                        !!props.errors.nameServer6
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer6}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Collapse in={showIpsInputs}>
                  <div>
                    <Form.Group as={Row} controlId="ipv46">
                      <Form.Label column sm={2}>
                        IPv4 6
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv46"
                          value={props.values.ipv46}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv46 && !!props.errors.ipv46
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv46}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ipv66">
                      <Form.Label column sm={2}>
                        IPv6 6
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="ipv66"
                          value={props.values.ipv66}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.ipv66 && !!props.errors.ipv66
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.ipv66}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common:optional')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </div>
                </Collapse>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Dns);
