import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetEmailTrafficItem = {
  email: string;
  total: number;
  totalMb: number;
  totalGb: number;
  totalRecebimento: number;
  totalRecebimentoMb: number;
  totalRecebimentoGb: number;
  totalEnvio: number;
  totalEnvioMb: number;
  totalEnvioGb: number;
};

type DailyTrafficItem = {
  total: number;
  totalMb: number;
  totalGb: number;
};

type RatchetEmailTrafficResponse = {
  data: {
    total: number;
    totalMb: number;
    totalGb: number;
    trafegoEmails: RatchetEmailTrafficItem[];
    trafegoDiario: {
      [key: string]: DailyTrafficItem;
    };
  };
};

type RatchetEmailTrafficAccount = {
  email: string;
  total: number;
  totalMb: number;
  totalGb: number;
  totalRecebimento: number;
  totalRecebimentoMb: number;
  totalRecebimentoGb: number;
  totalEnvio: number;
  totalEnvioMb: number;
  totalEnvioGb: number;
};

type DailyTraffic = {
  date: string;
  total: number;
  totalMb: number;
  totalGb: number;
};

type RatchetEmailTraffic = {
  total: number;
  totalMb: number;
  totalGb: number;
  trafegoEmails: RatchetEmailTrafficAccount[];
  trafegoDiario: DailyTraffic[];
};

const initialData: RatchetEmailTraffic = {
  total: 0,
  totalMb: 0,
  totalGb: 0,
  trafegoEmails: [],
  trafegoDiario: [],
};

function formatResponse(
  ratchetEmailTrafficResponse: RatchetEmailTrafficResponse,
): RatchetEmailTraffic {
  const ratchetDailyData = ratchetEmailTrafficResponse.data.trafegoDiario;

  const isObject = typeof ratchetDailyData === 'object';
  const isNotArray = !Array.isArray(ratchetDailyData);
  const isNotNull = ratchetDailyData !== null;

  const dailyData: DailyTraffic[] = [];

  if (isObject && isNotArray && isNotNull) {
    const dateKeys = Object.keys(ratchetDailyData);

    dateKeys.forEach(dateKey => {
      dailyData.push({ ...ratchetDailyData[dateKey], ...{ date: dateKey } });
    });
  }

  return {
    total: ratchetEmailTrafficResponse.data.total,
    totalMb: ratchetEmailTrafficResponse.data.totalMb,
    totalGb: ratchetEmailTrafficResponse.data.totalGb,
    trafegoEmails: ratchetEmailTrafficResponse.data.trafegoEmails,
    trafegoDiario: dailyData,
  };
}

async function loadRatchetEmailTraffic(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetEmailTraffic> {
  const emailTrafficResponse = await api.get<RatchetEmailTrafficResponse>(
    `painel/v1/ratchet/trafego/email`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const emailTraffic = formatResponse(emailTrafficResponse.data);

  return emailTraffic;
}

export default function useRatchetEmailTraffic(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetEmailTraffic>(
    ['ratchetEmailTraffic', site, initialDate, finalDate],
    () => loadRatchetEmailTraffic(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetEmailTraffic = data || initialData;

  return {
    ratchetEmailTraffic,
    ...rest,
  };
}
