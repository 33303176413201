import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import history from 'services/history';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface FtpResponse {
  data: {
    usuario: string;
  };
}

interface NewFtpSubaccountFormValues {
  directory: string;
  password: string;
  confirmPassword: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ftpUser, setFtpUser] = useState('');

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    async function loadFtpAccount() {
      try {
        setError(false);
        setLoading(true);

        const ftpResponse = await api.get<FtpResponse>(
          `painel/v1/site/${idSite}/ftp`,
        );

        const { usuario } = ftpResponse.data.data;

        if (usuario) {
          setFtpUser(usuario);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadFtpAccount();
  }, [idSite]);

  async function createFtpSubaccount(values: NewFtpSubaccountFormValues) {
    try {
      const { directory, password } = values;

      await api.post(`painel/v1/subconta-ftp`, {
        conta: ftpUser,
        diretorio: `/home/${ftpUser}/${directory}`,
        senha: password,
      });

      toast.fire(t('pages:newFtpSubaccount.requestSuccess'));

      history.push(`/site/subcontas-ftp`);
    } catch (err) {
      swal.fire({
        title: t('pages:newFtpSubaccount.requestFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:newFtpSubaccount.newFtpSubaccount')}
          description={t('pages:newFtpSubaccount.description')}
          directHelpLink="https://ajuda.hostnet.com.br/subcontas-de-ftp/"
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            directory: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={Yup.object().shape({
            directory: Yup.string()
              .required(
                t('pages:newFtpSubaccount.validation.informValidDirectory'),
              )
              .test(
                'directory',
                t('pages:newFtpSubaccount.validation.informValidDirectory'),
                value => {
                  if (
                    value &&
                    value.trim() !== '' &&
                    value.search(/^([\w.\-_]+)([/]?)([\w.\-_]+)$/) !== -1
                  ) {
                    return true;
                  }
                  return false;
                },
              ),
            password: Yup.string()
              .required(t('validations.password'))
              .min(8, t('validations.passwordMin'))
              .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
              .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
              .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
              .matches(
                RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                t('validations.passwordChar'),
              ),
            confirmPassword: Yup.string()
              .required(t('validations.passwordConfirm'))
              .oneOf(
                [Yup.ref('password'), null],
                t('validations.invalidPasswordConfirm'),
              ),
          })}
          onSubmit={createFtpSubaccount}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:directory')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          /home/{ftpUser}/
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        id="directory"
                        name="directory"
                        value={props.values.directory}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.directory && !!props.errors.directory
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted" />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.directory}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.password')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      id="password"
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.password && !!props.errors.password
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('common.matchPassword')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.confirmPassword')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={props.values.confirmPassword}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirmPassword &&
                        !!props.errors.confirmPassword
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/site/subcontas-ftp"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
