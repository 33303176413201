import React from 'react';
import { useTranslation } from 'react-i18next';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

interface RedirectActionsProps {
  loading: boolean;
  deleteRedirects: () => void;
  selectedCount: number;
  selectedRows: {
    idRedirecionamento: number;
    podeRemover: boolean;
  }[];
}

const RedirectActions: React.FC<RedirectActionsProps> = ({
  loading,
  deleteRedirects,
  selectedRows,
  selectedCount,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          {selectedRows[0].podeRemover && (
            <TableButton
              className="mx-1"
              variant="danger"
              disabled={loading}
              onClick={() => deleteRedirects()}
            >
              {t('common:delete')}
            </TableButton>
          )}
        </>
      )}
    </TableActionsWrapper>
  );
};

export default RedirectActions;
