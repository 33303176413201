import { useQuery } from 'react-query';

import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';

type ProductsItemResponse = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  valor: string;
  categoria: string;
  email: number;
  trafego: number;
  siteEspaco: number;
  bancoQtd: number;
  bancoEspaco: number;
  contratado: boolean;
  unidade: string;
  cpu: number;
  jaPossuiProdutoDaCategoria: boolean;
  quantidadeCustomizavel: boolean;
  multiplicadorCustomizacao: number;
  validade: string;
  descricao: string;
};

type CategoryItemResponse = {
  idProdutoCategoria: number;
  categoria: string;
  idCategoria: number;
  ordem: number;
  nome: string;
  descricao: string;
  validade: string;
  temProdutoQuantidadeCustomizavel: boolean;
};

type AvailableProductsResponse = {
  data: {
    categorias: CategoryItemResponse[];
    produtos: ProductsItemResponse[];
  };
};

type Product = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  siteEspaco: number;
  trafego: number;
  email: number;
  bancoQtd: number;
  bancoEspaco: number;
  contratado: boolean;
  valor: number;
  valorFormatado: string;
  categoria: string;
  expansao: number;
  expansaoMaxima: number;
  unidade: string;
  customizavel: boolean;
  jaPossuiProdutoDaCategoria: boolean;
  validade: string;
  descricao: string;
};

type Category = {
  idCategoria: number;
  categoria: string;
  temProdutoQuantidadeCustomizavel: boolean;
  order: number;
};

type AvailableProductsInfo = {
  products: Product[];
  categories: Category[];
};

const initialData: AvailableProductsInfo = {
  products: [],
  categories: [],
};

function formatAvailableProduct(product: ProductsItemResponse): Product {
  const totalExpansion =
    Number(product.trafego) +
    Number(product.email) +
    Number(product.siteEspaco) +
    Number(product.bancoEspaco) +
    Number(product.cpu);

  return {
    idProduto: product.idProduto,
    idProdutoCategoria: product.idProdutoCategoria,
    produto: product.produto,
    siteEspaco: product.siteEspaco,
    trafego: product.trafego,
    email: product.email,
    bancoQtd: product.bancoQtd,
    bancoEspaco: product.bancoEspaco,
    contratado: product.contratado,
    valor: Number(product.valor),
    valorFormatado: formatCurrency(Number(product.valor)),
    categoria: product.categoria,
    expansao: totalExpansion,
    expansaoMaxima: product.multiplicadorCustomizacao * totalExpansion,
    unidade: product.unidade,
    jaPossuiProdutoDaCategoria: product.jaPossuiProdutoDaCategoria,
    customizavel: product.quantidadeCustomizavel,
    validade: product.validade,
    descricao: product.descricao,
  };
}

function formatAvailableCategories(category: CategoryItemResponse): Category {
  return {
    idCategoria: category.idCategoria,
    categoria: category.categoria,
    temProdutoQuantidadeCustomizavel: category.temProdutoQuantidadeCustomizavel,
    order: category.ordem,
  };
}

async function loadAvailableProducts(
  idSite?: number,
): Promise<AvailableProductsInfo> {
  const availableProductsResponse = await api.get<AvailableProductsResponse>(
    `painel/v1/servico-adicional`,
    {
      params: {
        ...(idSite && { idSite }),
      },
    },
  );

  const products = availableProductsResponse.data.data.produtos.map(
    formatAvailableProduct,
  );

  const categories = availableProductsResponse.data.data.categorias.map(
    formatAvailableCategories,
  );

  return {
    products,
    categories,
  };
}

export default function useAvailableProducts(idSite?: number) {
  const { data, ...rest } = useQuery<AvailableProductsInfo>(
    ['availableProducts', idSite],
    () => loadAvailableProducts(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const availableProducts = data || initialData;

  return {
    availableProducts,
    ...rest,
  };
}
