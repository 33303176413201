import { action } from 'typesafe-actions';

export function authorizationRequest({
  token,
  scope,
  target,
}: {
  token: string;
  scope: string;
  target?: string;
}) {
  return action('@auth/AUTHORIZATION_REQUEST', { token, scope, target });
}

export function authorizationSuccess({
  accessToken,
  refreshToken,
  userId,
  expiresIn,
  accessType = '',
}: {
  accessToken: string;
  refreshToken: string;
  userId: number;
  expiresIn: number;
  accessType?: string;
}) {
  return action('@auth/AUTHORIZATION_SUCCESS', {
    accessToken,
    refreshToken,
    userId,
    expiresIn,
    accessType,
  });
}

export function authorizationFailure() {
  return action('@auth/AUTHORIZATION_FAILURE');
}

export function logout() {
  return action('@auth/LOGOUT');
}
