import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { FaGlobeAmericas } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';

import useBrDomains from 'hooks/useBrDomains';
import useRioDomains from 'hooks/useRioDomains';
import useInternationalDomains from 'hooks/useInternationalDomains';

import filter from 'utils/filter';

import BrDomainActions from './components/BrDomainActions';
import InternationalDomainActions from './components/InternationalDomainActions';
import RioDomainActions from './components/RioDomainActions';

import { Container } from './styles';

const Renew: React.FC = () => {
  const { t } = useTranslation();

  const brDomains = useBrDomains();
  const rioDomains = useRioDomains();
  const internationalDomains = useInternationalDomains(['ATIVO', 'EXPIRADO']);

  const [selectedBrDomainsRowsInfo, setSelectedBrDomainsRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof brDomains.domains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [
    selectedInternationalDomainsRowsInfo,
    setSelectedInternationalDomainsRowsInfo,
  ] = useState<{
    selectedCount: number;
    selectedRows: typeof internationalDomains.domains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [selectedRioDomainsRowsInfo, setSelectedRioDomainsRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof rioDomains.domains;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [
    internationalDomainsFilterText,
    setInternationalDomainsFilterText,
  ] = useState('');
  const [brDomainsFilterText, setBrDomainsFilterText] = useState('');
  const [rioDomainsFilterText, setRioDomainsFilterText] = useState('');

  let internationalDomainsAvailableToRenew = internationalDomains.domains.filter(
    internationalDomain => internationalDomain.renovacaoDisponivel,
  );

  internationalDomainsAvailableToRenew = filter(
    internationalDomainsAvailableToRenew,
    ['dominio'],
    internationalDomainsFilterText,
  );

  let brDomainsAvailableToRenew = brDomains.domains.filter(
    brDomain => brDomain.availableToRenew,
  );

  brDomainsAvailableToRenew = filter(
    brDomainsAvailableToRenew,
    ['domain'],
    brDomainsFilterText,
  );

  let rioDomainsAvailableToRenew = rioDomains.domains.filter(
    rioDomain => rioDomain.renovacaoDisponivel,
  );

  rioDomainsAvailableToRenew = filter(
    rioDomainsAvailableToRenew,
    ['dominio'],
    rioDomainsFilterText,
  );

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:domainRenew.internationalDomains')}
          description=""
          directHelpLink="https://ajuda.hostnet.com.br/renovacao-e-expiracao-de-dominios/"
          helpContent={
            <div>
              <p>
                <strong>{t('common:label.warning')}</strong>
              </p>

              <ul>
                <li>
                  {t('pages:domainRenew.internationalHelpContent.notice1')}
                </li>
                <li>
                  {t('pages:domainRenew.internationalHelpContent.notice2')}
                </li>
                <li>
                  {t('pages:domainRenew.internationalHelpContent.notice3')}
                </li>
                <li>
                  {t('pages:domainRenew.internationalHelpContent.notice4')}
                </li>
              </ul>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:domainRenew.internationalHelpContent.link1'),
              url:
                'https://www.hostnet.com.br/info/registro-de-dominios-internacionais/',
            },
          ]}
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput
              onChange={value => setInternationalDomainsFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedInternationalDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'dominio',
              },
              {
                name: t('common:status'),
                selector: 'status',
                maxWidth: '250px',
                cell: row =>
                  row.periodoRecuperacao
                    ? t('pages:domainRenew.renewalIsNotGuaranteed')
                    : row.status,
              },
              {
                name: t('common:expiration'),
                selector: 'expiracao',
                cell: row => format(parseISO(row.expiracao), 'dd/MM/yyyy'),
                sortable: true,
                maxWidth: '250px',
              },
            ]}
            data={internationalDomainsAvailableToRenew}
            progressPending={internationalDomains.fetching}
            progressComponent={<Loading />}
            contextComponent={
              <InternationalDomainActions
                selectedRows={selectedInternationalDomainsRowsInfo.selectedRows}
                selectedCount={
                  selectedInternationalDomainsRowsInfo.selectedCount
                }
              />
            }
            onSelectedRowsChange={rows =>
              setSelectedInternationalDomainsRowsInfo(rows)
            }
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domainRenew.brDomains')}
          description=""
          directHelpLink="https://ajuda.hostnet.com.br/renovacao-e-expiracao-de-dominios/"
          helpContent={
            <div>
              <p>
                <strong>{t('common:label.warning')}</strong>
              </p>

              <ul>
                <li>{t('pages:domainRenew.brHelpContent.notice1')}</li>
                <li>{t('pages:domainRenew.brHelpContent.notice2')}</li>
              </ul>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:domainRenew.brHelpContent.link1'),
              url:
                'https://www.hostnet.com.br/info/registro-de-dominios-nacionais/',
            },
          ]}
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setBrDomainsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedBrDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'domain',
              },
              {
                name: t('common:status'),
                selector: 'status',
                maxWidth: '250px',
              },
              {
                name: t('common:expiration'),
                selector: 'expiration',
                cell: row => format(parseISO(row.expiration), 'dd/MM/yyyy'),
                maxWidth: '250px',
                sortable: true,
              },
            ]}
            data={brDomainsAvailableToRenew}
            progressPending={brDomains.isFetching}
            progressComponent={<Loading />}
            contextComponent={
              <BrDomainActions
                selectedRows={selectedBrDomainsRowsInfo.selectedRows}
                selectedCount={selectedBrDomainsRowsInfo.selectedCount}
              />
            }
            onSelectedRowsChange={rows => setSelectedBrDomainsRowsInfo(rows)}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domainRenew.rioDomains')}
          description=""
          directHelpLink="https://ajuda.hostnet.com.br/renovacao-e-expiracao-de-dominios/"
          helpContent={
            <div>
              <p>
                <strong>{t('common:label.warning')}</strong>
              </p>

              <ul>
                <li>{t('pages:domainRenew.rioHelpContent.notice1')}</li>
                <li>{t('pages:domainRenew.rioHelpContent.notice2')}</li>
              </ul>
            </div>
          }
          helpLinks={[
            {
              text: t('pages:domainRenew.rioHelpContent.link1'),
              url: 'https://www.hostnet.com.br/info/registro-de-dominio-rio/',
            },
          ]}
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setRioDomainsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRioDomainsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:domain'),
                selector: 'dominio',
              },
              {
                name: t('common:status'),
                selector: 'status',
                maxWidth: '250px',
              },
              {
                name: t('common:expiration'),
                selector: 'expiracao',
                cell: row => format(parseISO(row.expiracao), 'dd/MM/yyyy'),
                maxWidth: '250px',
                sortable: true,
              },
            ]}
            data={rioDomainsAvailableToRenew}
            progressPending={rioDomains.fetching}
            progressComponent={<Loading />}
            onSelectedRowsChange={rows => setSelectedRioDomainsRowsInfo(rows)}
            contextComponent={
              <RioDomainActions
                selectedRows={selectedRioDomainsRowsInfo.selectedRows}
                selectedCount={selectedRioDomainsRowsInfo.selectedCount}
              />
            }
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Renew;
