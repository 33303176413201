import styled from 'styled-components';
import { FiSettings, FiDollarSign, FiHelpCircle } from 'react-icons/fi';
import { FaEnvelope, FaGlobeAmericas, FaDatabase } from 'react-icons/fa';

export const Container = styled.div`
  .card-body {
    height: 290px;
    overflow-y: auto;
  }

  a {
    color: var(--color-midnight-blue);
  }

  a:hover {
    text-decoration: none;
  }
`;

export const Option = styled.div`
  display: flex;

  font-size: 16px;

  align-items: center;
`;

export const IconContainer = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;

  margin-right: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  &.blue {
    background-color: var(--color-blue);
  }

  &.violet {
    background-color: var(--color-violet);
  }

  &.green {
    background-color: var(--color-green);
  }
`;

export const Name = styled.div`
  color: var(--color-midnight-blue);
`;

export const SettingsIcon = styled(FiSettings)`
  color: #fff;
`;

export const DollarIcon = styled(FiDollarSign)`
  color: #fff;
`;

export const HelpCircleIcon = styled(FiHelpCircle)`
  color: #fff;
`;

export const EnvelopeIcon = styled(FaEnvelope)`
  color: #fff;
`;

export const GlobeAmericasIcon = styled(FaGlobeAmericas)`
  color: #fff;
`;

export const DatabaseIcon = styled(FaDatabase)`
  color: #fff;
`;
