import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';
import history from 'services/history';
import checkEmailServicesAlert from 'services/checkEmailServicesAlert';

import filter from 'utils/filter';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';

import { StoreState } from 'store/createStore';

import useUltramail from 'hooks/useUltramail';
import useEmailRedirects from 'hooks/useEmailRedirects';

import RedirectActions from './components/RedirectActions';

import { Container } from './styles';

interface Redirect {
  emailOrigem: string;
  emailDestino: string;
}

interface SelectedRowsInfo {
  selectedCount: number;
  selectedRows: Redirect[];
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { isFetched, ultramail } = useUltramail(idSite);
  const { emailRedirects, isFetching, isError, refetch } = useEmailRedirects(
    site,
  );

  const [deleting, setDeleting] = useState(false);
  const [redirectsFilterText, setRedirectsFilterText] = useState('');
  const [toggleCleared, setToggleCleared] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const filteredRedirects = filter(
    emailRedirects,
    ['emailDestino', 'emailOrigem'],
    redirectsFilterText,
  );

  async function deleteRedirect() {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        text: t('pages:siteRedirects.redirectWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      const emailsOrigem = selectedRowsInfo.selectedRows.map(
        ({ emailOrigem }) => emailOrigem,
      );

      const emailsDestino = selectedRowsInfo.selectedRows.map(
        ({ emailDestino }) => emailDestino,
      );

      await api.delete(`painel/v1/email/redirecionamento`, {
        params: {
          site,
          emailsOrigem,
          emailsDestino,
        },
      });

      setToggleCleared(!toggleCleared);

      refetch();

      toast.fire(t('pages:redirectsEmail.deleteSuccess'));
    } catch (err) {
      swal.fire({
        icon: 'error',
        title: t('common:operationFailed'),
        text: `${t('pages:redirectsEmail.deleteFailed')}`,
      });
    } finally {
      setDeleting(false);
    }
  }

  if (isError) {
    return <Error />;
  }

  const redirectsColumns = [
    {
      name: t('common:email'),
      selector: 'emailOrigem',
      sortable: true,
    },
    {
      name: t('pages:redirectsEmail.redirectTo'),
      selector: 'emailDestino',
      sortable: true,
    },
  ];

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:redirectsEmail.title')}
          description={t('pages:redirectsEmail.description')}
          directHelpLink="https://ajuda.hostnet.com.br/redirecionamento-de-email-pelo-painel-de-controle/"
        />

        <TableSubHeader>
          <div>
            <TableButton
              onClick={() => {
                if (isFetched && !ultramail.possuiSiteComUltramailAtivo) {
                  checkEmailServicesAlert();
                } else {
                  history.push('/emails/redirecionamento/novo');
                }
              }}
            >
              {t('pages:redirectsEmail.newRedirect')}
            </TableButton>
          </div>

          <div>
            <FilterInput onChange={value => setRedirectsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            pagination
            selectableRows
            defaultSortField="emailOrigem"
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            clearSelectedRows={toggleCleared}
            data={filteredRedirects}
            columns={redirectsColumns}
            contextComponent={
              <RedirectActions
                loading={deleting}
                deleteRedirect={() => deleteRedirect()}
              />
            }
            progressPending={isFetching}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
