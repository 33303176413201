import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import PhoneInput from 'components/PhoneInput';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';

import api from 'services/api';
import hashIds from 'services/hashIds';
import toast from 'services/toast';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type EntityEditProps = RouteComponentProps<MatchParams>;

interface StatesResponse {
  data: {
    idEstado: number;
    sigla: string;
    estado: string;
  }[];
}

interface State {
  idEstado: number;
  sigla: string;
  estado: string;
}

interface EntityItemResponse {
  idCliente: number;
  idEntidadeFinanceira: number;
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  cpfCnpj: string;
  email: string;
  endereco: string;
  estado: string;
  inscEstadual: string;
  inscMunicipal: string;
  nome: string;
  numero: string;
  tel: string;
}

interface EntityResponse {
  data: EntityItemResponse[];
}

interface Entity {
  idCliente: number;
  idEntidadeFinanceira: number;
  bairro: string;
  cep: string;
  cidade: string;
  complemento: string;
  cpfCnpj: string;
  email: string;
  endereco: string;
  estado: string;
  inscEstadual: string;
  inscMunicipal: string;
  nome: string;
  numero: string;
  phone: string;
  tel: string;
}

interface EditEntityFormValues {
  name: string;
  cpfCnpj: string;
  email: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  state: string;
  city: string;
  zipCode: string;
  phone: string;
  stateRegistration: string;
  municipalRegistration: string;
}

const Edit: React.FC<EntityEditProps> = ({ match }) => {
  const [id] = hashIds.decode(match.params.id);

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [states, setStates] = useState<State[]>([]);
  const [entity, setEntity] = useState<Entity>({
    idCliente: 0,
    idEntidadeFinanceira: 0,
    bairro: '',
    cep: '',
    cidade: '',
    complemento: '',
    cpfCnpj: '',
    email: '',
    endereco: '',
    estado: '',
    inscEstadual: '',
    inscMunicipal: '',
    nome: '',
    numero: '',
    phone: '',
    tel: '',
  });

  useEffect(() => {
    async function loadEntity() {
      try {
        setError(false);
        setLoading(true);

        const statesResponse = await api.get<StatesResponse>(
          'regiao/v1/estado?pais=Brasil',
        );
        setStates(statesResponse.data.data);

        const entityResponse = await api.get<EntityResponse>(
          `clientes/v1/entidade-financeira/${id}`,
        );

        const [entityData] = entityResponse.data.data;

        const [, phone] = entityData.tel.split('.');

        const { cpfCnpj, ...rest } = entityData;

        const formatedEntity = { ...rest, ...{ cpfCnpj, phone } };

        setEntity(formatedEntity);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadEntity();
  }, [id]);

  const editEntity = useCallback(
    async (values: EditEntityFormValues) => {
      try {
        const fullPhone = `55.${values.phone.replace(/[^\d]/g, '')}`;

        await api.put(
          `clientes/v1/entidade-financeira/${entity.idEntidadeFinanceira}`,
          {
            nome: values.name,
            cpfCnpj: values.cpfCnpj,
            email: values.email,
            endereco: values.address,
            numero: values.number,
            complemento: values.complement,
            bairro: values.neighborhood,
            cep: values.zipCode,
            cidade: values.city,
            estado: values.state,
            telefone: fullPhone,
            inscricaoEstadual: values.stateRegistration,
            inscricaoMunicipal: values.municipalRegistration,
          },
        );

        toast.fire(t('pages:entityEdit.success'));

        history.push(`/financeiro/entidades`);
      } catch (err) {
        swal.fire({
          title: t('pages:entityEdit.failed'),
          html:
            (err.response && err.response.data.error_description) ||
            err.message,
        });
      }
    },
    [entity.idEntidadeFinanceira, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:entityEdit.title')}
          description={t('pages:entityEdit.description')}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            name: entity.nome,
            cpfCnpj: entity.cpfCnpj,
            email: entity.email,
            address: entity.endereco,
            number: entity.numero,
            complement: entity.complemento,
            neighborhood: entity.bairro,
            state: entity.estado,
            city: entity.cidade,
            zipCode: entity.cep,
            phone: entity.phone,
            stateRegistration: entity.inscEstadual || '',
            municipalRegistration: entity.inscMunicipal || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('validations:requiredField')),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            cpfCnpj: Yup.number().required(t('validations:requiredField')),
            address: Yup.string().required(t('validations:requiredField')),
            number: Yup.string().required(t('validations:requiredField')),
            neighborhood: Yup.string().required(t('validations:requiredField')),
            city: Yup.string().required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={editEntity}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:entityEdit.legend')} />

                <Form.Group as={Row} controlId="editEntity.name">
                  <Form.Label column sm={2} className="required">
                    {t('pages:entityEdit.companyName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.cpfCnpj">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.cpfCnpj')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="cpfCnpj"
                      maxLength={14}
                      value={props.values.cpfCnpj}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.cpfCnpj && !!props.errors.cpfCnpj
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('common:label.onlyNumbers')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.cpfCnpj}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="email"
                      type="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.number">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.number')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="number"
                      value={props.values.number}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.number && !!props.errors.number
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.number}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.complement">
                  <Form.Label column sm={2}>
                    {t('common:label.complement')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="complement"
                      value={props.values.complement}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.complement && !!props.errors.complement
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.complement}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.neighborhood">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.neighborhood')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="neighborhood"
                      value={props.values.neighborhood}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.neighborhood &&
                        !!props.errors.neighborhood
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.neighborhood}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.state">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="state"
                      as="select"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    >
                      {states.map(state => (
                        <option key={state.idEstado} value={state.sigla}>
                          {state.sigla}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.city">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="editEntity.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:label.zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="zipCode"
                      value={props.values.zipCode}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.zipCode && !!props.errors.zipCode
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="editEntity.phone">
                  <Form.Label column sm={2}>
                    {t('common:label.phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>+55</InputGroup.Text>
                      </InputGroup.Prepend>
                      <PhoneInput
                        name="phone"
                        value={props.values.phone}
                        handleChange={props.handleChange}
                        handleBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.phone && !!props.errors.phone
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="editEntity.stateRegistration">
                  <Form.Label column sm={2}>
                    {t('pages:entityEdit.stateRegistration')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="stateRegistration"
                      value={props.values.stateRegistration}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.stateRegistration &&
                        !!props.errors.stateRegistration
                      }
                      disabled={props.isSubmitting}
                    />
                  </Col>
                  <Form.Control.Feedback type="invalid">
                    {props.errors.stateRegistration}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Row}
                  controlId="newEntity.municipalRegistration"
                >
                  <Form.Label column sm={2}>
                    {t('pages:entityEdit.municipalRegistration')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      name="municipalRegistration"
                      value={props.values.municipalRegistration}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.municipalRegistration &&
                        !!props.errors.municipalRegistration
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.municipalRegistration}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/financeiro/entidades"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
