import { useQuery } from 'react-query';

import api from 'services/api';

type UltramailDomainItemResponse = {
  dominio: string;
  ativo: boolean;
  mboxHost: string;
  smtpHost: string;
  mboxSslHost: string;
  smtpSslHost: string;
};

type UltramailDomainsResponse = {
  data: UltramailDomainItemResponse[];
};

type UltramailDomain = {
  dominio: string;
  ativo: boolean;
  mboxHost: string;
  smtpHost: string;
  mboxSslHost: string;
  smtpSslHost: string;
};

const initialData: UltramailDomain[] = [];

function formatUltramailDomain(
  ultramailDomain: UltramailDomainItemResponse,
): UltramailDomain {
  return ultramailDomain;
}

async function loadUltramailDomains(): Promise<UltramailDomain[]> {
  const ultramailDomainsResponse = await api.get<UltramailDomainsResponse>(
    `painel/v1/ultramail-dominio`,
  );

  const ultramailDomains = ultramailDomainsResponse.data.data.map(
    formatUltramailDomain,
  );

  return ultramailDomains;
}

export default function useUltramailDomains() {
  const { data, ...rest } = useQuery<UltramailDomain[]>(
    ['ultramailDomains'],
    () => loadUltramailDomains(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ultramailDomains = data || initialData;

  return {
    ultramailDomains,
    ...rest,
  };
}
