import api from './api';
import swal from './swal';

type LoginResponse = {
  data: {
    url: string;
  };
};

export default async function backToMyAccount() {
  try {
    const loginResponse = await api.post<LoginResponse>(
      'painel/v1/usuario-adicional/retorno',
    );

    window.location.replace(loginResponse.data.data.url);
  } catch (error) {
    swal.fire({
      title: 'Não foi possível acessar o painel',
      html: 'Por favor, faça login novamente no painel de controle',
    });
  }
}
