import Swal from 'sweetalert2';

const swal = Swal.mixin({
  icon: 'error',
  customClass: {
    confirmButton: 'btn btn-primary btn-sm mr-3',
    cancelButton: 'btn btn-danger btn-sm',
  },
  buttonsStyling: false,
  confirmButtonText: 'OK',
  cancelButtonText: 'Cancelar',
});

export default swal;
