import styled from 'styled-components';

export const Container = styled.div`
  .card-body {
    height: 290px;
    overflow-y: auto;
  }
`;

export const NewsList = styled.ul`
  padding: 0;
  list-style: none;

  li:last-child {
    border: none !important;
  }
`;

export const Item = styled.li`
  &:hover {
    background-color: rgba(44, 69, 147, 0.1);
  }
`;

export const Title = styled.div`
  font-size: 16px;

  margin-bottom: 5px;

  a {
    color: inherit;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const Date = styled.div`
  font-size: 14px;
`;
