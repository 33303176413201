import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import Error from 'components/Error';

import { FaCogs } from 'react-icons/fa';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';

import api from 'services/api';

import { Container } from './styles';

const WebfacilConverted: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const { site } = useSelector((state: StoreState) => {
    return state.site.info;
  });

  useEffect(() => {
    async function loadMigradoPremium() {
      try {
        setLoading(true);
        setError(false);

        const migradoRequest = await api.get(
          `webfacil/v1/premium/migrado/${site}`,
        );

        if (migradoRequest.data.data.length > 0) {
          setPassword(migradoRequest.data.data[0].senhaWordpress);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    async function loadMigrado() {
      try {
        setLoading(true);
        setError(false);

        const migradoRequest = await api.get(`webfacil/v1/migrado/${site}`);

        if (migradoRequest.data.data.length > 0) {
          setPassword(migradoRequest.data.data[0].senhaWordpress);
        } else {
          loadMigradoPremium();
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadMigrado();
  }, [site]);

  const url = `https://www.${site}/wp-admin`;

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          startOpen
          title={t('pages:webfacilConverted.title')}
          description={t('pages:webfacilConverted.description')}
          helpContent={
            <div>
              <p>{t('pages:webfacilConverted.helpContent.p1')}</p>
              <p>{t('pages:webfacilConverted.helpContent.p2')}</p>
              <p>{t('pages:webfacilConverted.helpContent.p3')}</p>
              <p>{t('pages:webfacilConverted.helpContent.p4')}</p>
              <p>{t('pages:webfacilConverted.helpContent.p5')}</p>
              <p>
                <Trans i18nKey="wordpressAdmin">
                  <a href={url} target="_BLANK" rel="noreferrer">
                    https://www.{{ site }}/wp-admin
                  </a>
                </Trans>
              </p>
              <div>Usuário: Admin</div>
              <div>Senha: {password}</div>
              <br />
              <p>{t('pages:webfacilConverted.helpContent.p6')}</p>
              <p>
                <Trans i18nKey="wordpressAdmin">
                  {t('pages:webfacilConverted.helpContent.p7')}
                  {` `}
                  <a href="/helpdesk">Helpdesk</a>
                </Trans>
              </p>
            </div>
          }
          helpLinks={[
            {
              url: 'https://www.hostnet.com.br/info/wordpress/',
              text: t('pages:webfacilConverted.helpLinks.a4'),
            },
            {
              url:
                'https://ajuda.hostnet.com.br/primeiros-passos-no-wordpress/',
              text: t('pages:webfacilConverted.helpLinks.a1'),
            },
            {
              url:
                'https://ajuda.hostnet.com.br/painel-administrativo-do-wordpress/',
              text: t('pages:webfacilConverted.helpLinks.a2'),
            },
            {
              url: 'https://ajuda.hostnet.com.br/posts-e-paginas-no-wordpress/',
              text: t('pages:webfacilConverted.helpLinks.a3'),
            },
          ]}
        />
      </FormWrapper>
    </Container>
  );
};

export default WebfacilConverted;
