import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableButton from 'components/TableButton';
import TableNoData from 'components/TableNoData';
import PanelCard from 'components/PanelCard';

import useRioDomainContacts from 'hooks/useRioDomainContacts';
import useInternationalDomainContacts from 'hooks/useInternationalDomainContacts';
import useBrDomainContacts from 'hooks/useBrDomainsContacts';

import filter from 'utils/filter';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import BrContactActions from './components/BrContactActions';
import InternationalContactActions from './components/InternationalContactActions';
import RioContactActions from './components/RioContactActions';

import { Container } from './styles';

const Contacts: React.FC = () => {
  const { t } = useTranslation();

  const {
    contacts: internationalContacts,
    fetching: loadingInternationalContacts,
  } = useInternationalDomainContacts();

  const {
    contacts: brContacts,
    fetching: loadingBrContacts,
  } = useBrDomainContacts();

  const {
    contacts: rioContacts,
    fetching: loadingRioContacts,
  } = useRioDomainContacts();

  const [
    internationalContactFilterText,
    setInternationalContactFilterText,
  ] = useState('');
  const [brContactFilterText, setBrContactFilterText] = useState('');
  const [rioContactFilterText, setRioContactFilterText] = useState('');
  const [sendingValidation, setSendingValidation] = useState(false);

  const filteredInternationalContacts = filter(
    internationalContacts,
    ['name', 'email'],
    internationalContactFilterText,
  );

  const filteredBrContacts = filter(
    brContacts,
    ['name', 'email'],
    brContactFilterText,
  );

  const filteredRioContacts = filter(
    rioContacts,
    ['name', 'email'],
    rioContactFilterText,
  );

  const [
    selectedInternationalContactsRowsInfo,
    setSelectedInternationalContactsRowsInfo,
  ] = useState<{
    selectedCount: number;
    selectedRows: typeof internationalContacts;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [selectedBrContactsRowsInfo, setSelectedBrContactsRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof brContacts;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [
    selectedRioContactsRowsInfo,
    setSelectedRioContactsRowsInfo,
  ] = useState<{
    selectedCount: number;
    selectedRows: typeof rioContacts;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const internationalValidationIdentityEmails = internationalContacts
    .map(internationalContact =>
      internationalContact.validateIdentity ? internationalContact.email : '',
    )
    .filter(email => email);

  const rioValidationIdentityEmails = rioContacts
    .map(rioContact => (rioContact.validateIdentity ? rioContact.email : ''))
    .filter(email => email);

  async function handleInternationalIdentityValidation(idContact: number) {
    try {
      setSendingValidation(true);

      await api.post(`enom/v1/contato/${idContact}/validacao-identidade`);

      toast.fire(t('pages:domainContacts.identityValidationSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setSendingValidation(false);
    }
  }

  async function handleRioIdentityValidation(idContact: number) {
    try {
      setSendingValidation(true);

      await api.post(`rrpproxy/v1/contato/${idContact}/validacao-identidade`);

      toast.fire(t('pages:domainContacts.identityValidationSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setSendingValidation(false);
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      {internationalValidationIdentityEmails.length > 0 && (
        <>
          <PanelCard>
            <Card.Header>
              <h5 className="text-danger">
                {t(
                  'pages:domainContacts.internationalValidationIdentity.title',
                )}
              </h5>
            </Card.Header>
            <Card.Body>
              <div className="notice-content">
                <p>
                  {t(
                    'pages:domainContacts.internationalValidationIdentity.p1',
                    {
                      emails: internationalValidationIdentityEmails.join(','),
                    },
                  )}
                </p>
                <p>
                  {t('pages:domainContacts.internationalValidationIdentity.p2')}
                </p>
                <p>
                  {t('pages:domainContacts.internationalValidationIdentity.p3')}
                </p>
              </div>
            </Card.Body>
          </PanelCard>
        </>
      )}

      {rioValidationIdentityEmails.length > 0 && (
        <>
          <PanelCard>
            <Card.Header>
              <h5 className="text-danger">
                {t('pages:domainContacts.rioValidationIdentity.title', {
                  emails: rioValidationIdentityEmails.join(','),
                })}
              </h5>
            </Card.Header>
            <Card.Body>
              <div className="notice-content">
                <p>
                  {t('pages:domainContacts.rioValidationIdentity.p1', {
                    emails: rioValidationIdentityEmails.join(','),
                  })}
                </p>
                <p>{t('pages:domainContacts.rioValidationIdentity.p2')}</p>
                <p>{t('pages:domainContacts.rioValidationIdentity.p3')}</p>
              </div>
            </Card.Body>
          </PanelCard>
        </>
      )}

      <TableWrapper>
        <TableHeader
          title={t('pages:domainContacts.internationalTitle')}
          description={t('pages:domainContacts.internationalDescription')}
        />
        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/registro/contato-internacional-adicionar"
            >
              {t('pages:domainContacts.newInternationalContact')}
            </TableButton>
          </div>
          <div>
            <FilterInput
              onChange={value => setInternationalContactFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedInternationalContactsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:id'),
                selector: 'id',
                sortable: true,
                width: '100px',
              },
              {
                name: t('common:name'),
                selector: 'name',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:email'),
                selector: 'email',
                sortable: true,
              },
            ]}
            data={filteredInternationalContacts}
            progressPending={loadingInternationalContacts}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows =>
              setSelectedInternationalContactsRowsInfo(rows)
            }
            contextComponent={
              <InternationalContactActions
                selectedRows={
                  selectedInternationalContactsRowsInfo.selectedRows
                }
                selectedCount={
                  selectedInternationalContactsRowsInfo.selectedCount
                }
                validateEntity={(idContact: number) =>
                  handleInternationalIdentityValidation(idContact)
                }
                loading={sendingValidation}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domainContacts.brTitle')}
          description={t('pages:domainContacts.brDescription')}
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/registro/contato-br-adicionar">
              {t('pages:domainContacts.newBrContact')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setBrContactFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedBrContactsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:id'),
                selector: 'contact',
                sortable: true,
                width: '100px',
              },
              {
                name: t('common:name'),
                selector: 'name',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:email'),
                selector: 'email',
                sortable: true,
              },
            ]}
            data={filteredBrContacts}
            progressPending={loadingBrContacts}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedBrContactsRowsInfo(rows)}
            contextComponent={
              <BrContactActions
                selectedRows={selectedBrContactsRowsInfo.selectedRows}
                selectedCount={selectedBrContactsRowsInfo.selectedCount}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:domainContacts.rioTitle')}
          description={t('pages:domainContacts.rioDescription')}
        />
        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/registro/contato-rio-adicionar"
            >
              {t('pages:domainContacts.newRioContact')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setRioContactFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRioContactsRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:id'),
                selector: 'id',
                sortable: true,
                width: '100px',
              },
              {
                name: t('common:name'),
                selector: 'name',
                sortable: true,
                minWidth: '200px',
              },
              {
                name: t('common:email'),
                selector: 'email',
                sortable: true,
              },
              {
                name: t('common:document'),
                selector: 'document',
              },
            ]}
            data={filteredRioContacts}
            progressPending={loadingRioContacts}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedRioContactsRowsInfo(rows)}
            contextComponent={
              <RioContactActions
                selectedRows={selectedRioContactsRowsInfo.selectedRows}
                selectedCount={selectedRioContactsRowsInfo.selectedCount}
                validateEntity={(idContact: number) =>
                  handleRioIdentityValidation(idContact)
                }
                loading={sendingValidation}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Contacts;
