import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone, FileError, FileRejection } from 'react-dropzone';
import { Modal, Form } from 'react-bootstrap';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import formatDataSize from 'utils/formatDataSize';
import PanelButton from 'components/PanelButton';

import {
  DropZone,
  FilePreview,
  FileImage,
  FileImageDetails,
  FileDetails,
  CloseButton,
  Uploading,
} from './styles';

interface ExistentFile {
  nome: string;
  tipo: string;
  tamanho: string;
  caminho: string;
  ultModificacao: string;
  ultModificacaoTs: number;
  owner: string;
  permissaoSimbolica: string;
}

interface ModalFileUploadProps {
  site: string;
  currDir: string;
  loading: boolean;
  loadCount: number;
  setLoadCount: (value: number) => void;
  showModalFileUpload: boolean;
  setShowModalFileUpload: (value: boolean) => void;
  existentFiles: ExistentFile[];
}

interface MyFile extends File {
  uploading: string;
  path: string;
  size: number;
  type: string;
}

const ModalFileUpload: React.FC<ModalFileUploadProps> = ({
  site,
  currDir,
  loading,
  loadCount,
  setLoadCount,
  showModalFileUpload,
  setShowModalFileUpload,
  existentFiles,
}) => {
  const { t } = useTranslation();

  const [myFiles, setMyFiles] = useState<MyFile[]>([]);
  const [newFileExists, setNewFileExists] = useState<number>(0);
  const [sending, setSending] = useState<boolean>(false);

  useEffect(() => {
    let count = 0;
    myFiles.map((f: MyFile): boolean => {
      if (!f.uploading) {
        count += 1;
      }
      return true;
    });
    setNewFileExists(count);
  }, [myFiles]);

  const onDrop = useCallback(
    acceptedFiles => {
      if (newFileExists + acceptedFiles.length > 10) {
        swal.fire({
          icon: 'error',
          title: t(`pages:fileManager.fileAddError`),
          text: t('pages:fileManager.fileAddErrorExplanation'),
        });
        return false;
      }
      const newFiles: MyFile[] = [];
      acceptedFiles.map((file: MyFile): boolean => {
        if (myFiles.find((myFile: MyFile) => myFile.path === file.path)) {
          return false;
        }
        return !!newFiles.push(file);
      });
      return setMyFiles([...myFiles, ...newFiles]);
    },
    [myFiles, newFileExists, t],
  );

  function removeAll() {
    setMyFiles([]);
  }

  function removeFile(file: MyFile): void {
    const newFiles = myFiles.filter(f => {
      if (file.path === f.path) {
        return false;
      }
      return true;
    });
    setMyFiles(newFiles);
  }

  function setUploading(file: MyFile): void {
    const newFiles: MyFile[] = [];
    myFiles.map((f: MyFile): boolean => {
      if (f.path === file.path) {
        f.uploading = 'progress';
      }
      return !!newFiles.push(f);
    });
    setMyFiles(newFiles);
  }

  function setComplete(file: MyFile): void {
    const newFiles: MyFile[] = [];
    myFiles.map(f => {
      if (f.path === file.path) {
        f.uploading = 'complete';
      }
      return newFiles.push(f);
    });
    setMyFiles(newFiles);
  }
  function setError(file: MyFile): void {
    const newFiles: MyFile[] = [];
    myFiles.map(f => {
      if (f.path === file.path) {
        f.uploading = 'error';
      }
      return newFiles.push(f);
    });
    setMyFiles(newFiles);
  }

  function fileSizeValidator(file: File) {
    if (sending) {
      return {
        code: 'await-send-complete',
        message: ``,
      };
    }
    if (file.size > 10 * 1024 ** 2) {
      swal.fire({
        icon: 'error',
        title: t('pages:fileManager.fileSizeError', {
          file: '',
        }),
        text: t('pages:fileManager.fileSizeErrorExplanation'),
      });
      return {
        code: 'name-too-large',
        message: t('pages:fileManager.fileSizeErrorExplanation'),
      };
    }
    return null;
  }

  function dropRejected(filesRejections: FileRejection[]): void {
    if (!sending) {
      if (filesRejections.length > 10) {
        swal.fire({
          icon: 'error',
          title: t('pages:fileManager.fileAddError'),
          text: t('pages:fileManager.fileAddErrorExplanation'),
        });
      }
    }
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noKeyboard: true,
    maxFiles: 10,
    validator: (file: File): FileError | FileError[] | null =>
      fileSizeValidator(file),
    onDropRejected: (filesRejections: FileRejection[]): void =>
      dropRejected(filesRejections),
    noClick: true,
  });

  async function submitFormFileUpload() {
    const AsyncConfirm = (title: string) =>
      new Promise(resolve => {
        swal
          .fire({
            title: t('pages:fileManager.fileExists', { file: title }),
            text: t('pages:fileManager.fileExistsConfirm'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Sim',
          })
          .then(f => {
            if (f.isConfirmed) {
              return resolve(true);
            }
            return resolve(false);
          });
      });
    const myConfirmFiles: MyFile[] = [];

    /*eslint-disable */
    for (let i = 0; i < myFiles.length; i += 1) {
      const exists = existentFiles.find(
        (f: ExistentFile) => f.nome === myFiles[i].path,
      );
      if (!exists) {
        myConfirmFiles.push(myFiles[i]);
      }
      if (exists && !myFiles[i].uploading) {
        const confirm = await AsyncConfirm(myFiles[i].path);
        if (confirm) {
          myConfirmFiles.push(myFiles[i]);
        }
      }
    }
    /* eslint-enable */

    setSending(true);
    await Promise.all(
      myConfirmFiles.map(async (item: MyFile) => {
        try {
          if (item.uploading) {
            return false;
          }

          setUploading(item);
          const data = new FormData();
          data.append('arquivo', item);
          await api.post(`https://a.apihn.co/ftp/v1/arquivo`, data, {
            headers: { 'content-type': 'multipart/form-data' },
            params: { site, caminho: currDir || '/', sobreescrever: 1 },
          });
          setComplete(item);
          toast.fire(
            t('pages:fileManager.fileUploadSuccess', { file: item.path }),
          );
        } catch (err) {
          setError(item);
          swal.fire({
            title: t('pages:fileManager.fileUploadError'),
            html: err.response.data.error_description,
          });
        }
        return true;
      }),
    );
    setSending(false);
    setLoadCount(loadCount + 1);
  }

  function closeModal() {
    setMyFiles([]);
    setShowModalFileUpload(false);
  }

  function getAcceptedFiles() {
    return (
      <>
        {myFiles.map((f: MyFile) => {
          return (
            <FilePreview key={f.path} onClick={ev => ev.stopPropagation()}>
              {f.type.indexOf('image/') >= 0 &&
              f.type.indexOf('adobe.photoshop') < 0 ? (
                <>
                  <FileImage>
                    <div className="imageContainer">
                      <img alt={f.path} src={URL.createObjectURL(f)} />
                    </div>
                  </FileImage>
                  <FileImageDetails>
                    <div className="filesize">
                      <span>{formatDataSize(f.size)}</span>
                    </div>
                    <div className="filename">
                      <span>{f.path}</span>
                    </div>
                  </FileImageDetails>
                </>
              ) : (
                <FileDetails>
                  <div className="filesize">
                    <span>{formatDataSize(f.size)}</span>
                  </div>
                  <div className="filename">
                    <span>{f.path}</span>
                  </div>
                </FileDetails>
              )}
              {f.uploading && (
                <Uploading>
                  <div className={f.uploading} />
                </Uploading>
              )}
              <CloseButton onClick={() => removeFile(f)} />
            </FilePreview>
          );
        })}
      </>
    );
  }

  return (
    <Modal show={showModalFileUpload} onHide={closeModal} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:fileManager.uploadFile')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <DropZone {...getRootProps()} onClick={open}>
          <input {...getInputProps()} />
          {myFiles.length === 0 && <p>{t('pages:fileManager.dropzone')}</p>}
          <div>{getAcceptedFiles()}</div>
        </DropZone>
        {/* eslint-enable react/jsx-props-no-spreading */}
        <Form.Text className="text-muted">
          {t('pages:fileManager.dropzoneDescription')}
        </Form.Text>
      </Modal.Body>
      <Modal.Footer>
        <PanelButton
          onClick={() => submitFormFileUpload()}
          type="submit"
          disabled={!newFileExists || sending || loading}
        >
          {sending ? t('common:sending') : t('common:send')}
        </PanelButton>
        <PanelButton
          variant="danger"
          onClick={removeAll}
          disabled={myFiles.length < 1 || loading || sending}
        >
          {t('pages:fileManager.removeAll')}
        </PanelButton>
        <PanelButton variant="secondary" onClick={closeModal}>
          {t('common:close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalFileUpload;
