import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface BrEntityActionsProps {
  selectedCount: number;
  selectedRows: { idEntity: number }[];
}

const BrEntityActions: React.FC<BrEntityActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  const entityHashId = hashIds.encode(selectedRows[0].idEntity);

  return (
    <TableActionsWrapper loading={false}>
      {selectedCount === 1 && (
        <TableButton
          forwardedAs={Link}
          to={`/registro/entidade-br-editar/${entityHashId}`}
          className="mr-2"
        >
          {t('common:edit')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default BrEntityActions;
