import React from 'react';
import { useTranslation } from 'react-i18next';

import { filterMenuOptions } from 'components/SideMenu/filterMenuOptions';

import { Option } from 'store/modules/site/types';

import SideMenuAccordion from '../../components/SideMenuAccordion';
import SideMenuAccordionLink from '../../components/SideMenuAccordionLink';
import SideMenuAccordionExternalLink from '../../components/SideMenuAccordionExternalLink';

import { WindowIcon } from './styles';

interface SiteOptionsProps {
  options: Option[];
  site: string;
  tech: string;
}

type MenuElement = {
  element: React.ReactNode;
  requiredOptions: String[];
};

const SiteOptions: React.FC<SiteOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  const menuElements: MenuElement[] = [
    {
      element: (
        <SideMenuAccordionLink
          key="helpdesk.maintenance"
          text={t('components:sideMenu.maintenance')}
          to={{
            pathname: '/helpdesk',
            state: { option: 4 },
          }}
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="helpdesk.premiumSupport"
          text={t('components:sideMenu.premiumSupport')}
          to={{
            pathname: '/helpdesk',
            state: { option: 2 },
          }}
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionExternalLink
          key="site.pronto"
          text={t('components:sideMenu.siteCreated')}
          href="https://www.hostnet.com.br/criacao-de-site/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionExternalLink
          key="loja.pronta"
          text={t('components:sideMenu.storeCreated')}
          href="https://www.hostnet.com.br/criacao-de-loja-virtual/"
          target="_blank"
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/gerenciador-de-arquivos"
          text={t('components:sideMenu.fileManager')}
          to="/site/gerenciador-de-arquivos"
        />
      ),
      requiredOptions: ['gerenciador.de.arquivos'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/endereco-provisorio"
          text={t('components:sideMenu.temporaryAddress')}
          to="/site/endereco-provisorio"
        />
      ),
      requiredOptions: ['endereco.provisorio'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/quota-de-espaco"
          text={t('components:sideMenu.quota')}
          to="/site/quota-de-espaco"
        />
      ),
      requiredOptions: ['quota.de.espaco'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/cdn"
          text={t('components:sideMenu.cdnCloudflare')}
          to="/site/cdn"
        />
      ),
      requiredOptions: ['cdn.cloudflare'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/backup"
          text={t('components:sideMenu.backup')}
          to="/site/backup"
        />
      ),
      requiredOptions: ['backup.de.site'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/senha-ftp"
          text={t('components:sideMenu.ftpPassword')}
          to="/site/senha-ftp"
        />
      ),
      requiredOptions: ['senha.de.ftp'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/subcontas-ftp"
          text={t('components:sideMenu.ftpSubaccounts')}
          to="/site/subcontas-ftp"
        />
      ),
      requiredOptions: ['subcontas.de.ftp'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/redirecionamentos"
          text={t('components:sideMenu.redirect_plural')}
          to="/site/redirecionamentos"
        />
      ),
      requiredOptions: ['redirecionamentos'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/editar-nome"
          text={t('components:sideMenu.siteNameEdit')}
          to="/site/editar-nome"
        />
      ),
      requiredOptions: ['troca.nome.do.site'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/editar-plano"
          text={t('components:sideMenu.siteChangePlan')}
          to="/site/editar-plano"
        />
      ),
      requiredOptions: ['troca.de.plano'],
    },
    {
      element: (
        <SideMenuAccordionLink
          key="site/configuracoes-extras"
          text={t('components:sideMenu.extraSettings')}
          to="/site/configuracoes-extras"
        />
      ),
      requiredOptions: ['site.configuracoes.extras'],
    },
  ];

  const availableMenuItems = filterMenuOptions(options, menuElements);

  return (
    <SideMenuAccordion
      eventKey="3"
      text={t('components:sideMenu.site')}
      icon={<WindowIcon size={15} />}
      elements={availableMenuItems}
    />
  );
};

export default SiteOptions;
