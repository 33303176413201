import styled from 'styled-components';
import { FaLevelDownAlt } from 'react-icons/fa';

export const Container = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;

  .btn {
    margin-right: 10px;
    border-color: #ced4da;
    color: #212529;
  }

  .btn:not([disabled]):hover {
    color: white;
    border-color: transparent;
  }

  .btn[disabled],
  .btn[disabled]:hover {
    background-color: #fff;
  }

  a[disabled] {
    pointer-events: none;
    opacity: 0.65;
  }
`;

export const Arrow = styled(FaLevelDownAlt)`
  transform: rotate(270deg);
  margin-right: 10px;
`;
