import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import { Container } from './styles';

interface FtpInfoResponse {
  data: {
    bloqueado: boolean;
  };
}

interface FtpInfo {
  bloqueado: boolean;
}

interface FtpFormValues {
  acessoFTP: string;
  password: string;
  confirm: string;
}

const FtpPassword: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [ftpInfo, setFtpInfo] = useState<FtpInfo>({
    bloqueado: false,
  });

  useEffect(() => {
    async function loadFTP() {
      try {
        setLoading(true);
        const ftpInfoResponse = await api.get<FtpInfoResponse>(
          `painel/v1/site/${idSite}/ftp`,
        );
        setFtpInfo(ftpInfoResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    loadFTP();
  }, [idSite]);

  const submitForm = useCallback(
    async (values: FtpFormValues) => {
      try {
        if (values.acessoFTP === 'TRUE') {
          if (ftpInfo.bloqueado) {
            await api.post(`painel/v1/site/${idSite}/ftp/desbloquear`);
          }
          const ftpResponse = await api.put(
            `painel/v1/site/${idSite}/ftp/senha`,
            { pass: values.password },
          );
          setFtpInfo(ftpResponse.data.data);
          toast.fire(t('pages:ftpPassword.changePassSuccess'));
        } else {
          await api.post(`painel/v1/site/${idSite}/ftp/bloquear`);
          toast.fire(t('pages:ftpPassword.blockFtpSuccess'));
        }
      } catch (err) {
        swal.fire({
          title:
            values.acessoFTP === 'TRUE'
              ? t('pages:ftpPassword.changePassFailure')
              : t('pages:ftpPassword.blockFtpFailure'),
          html: err.response.data.error_description,
        });
      }
    },
    [t, idSite, ftpInfo],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:ftpPassword.title')}
          description={t('pages:ftpPassword.description')}
          directHelpLink="https://ajuda.hostnet.com.br/alterando-as-senhas-de-ftp-e-painel/"
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            acessoFTP: ftpInfo.bloqueado ? 'FALSE' : 'TRUE',
            password: '',
            confirm: '',
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().when('acessoFTP', {
              is: 'TRUE',
              then: Yup.string()
                .required(t('validations.password'))
                .min(8, t('validations.passwordMin'))
                .matches(
                  RegExp('^(?=.*[0-9])'),
                  t('validations.passwordNumber'),
                )
                .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
                .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
                .matches(
                  RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                  t('validations.passwordChar'),
                )
                .max(30, t('validations.passwordMax')),
            }),
            confirm: Yup.string().when('acessoFTP', {
              is: 'TRUE',
              then: Yup.string()
                .required(t('validations.passwordConfirm'))
                .oneOf(
                  [Yup.ref('password'), null],
                  t('validations.invalidPasswordConfirm'),
                )
                .when('password', {
                  is: (val: string) => val !== '' && val !== undefined,
                  then: Yup.string().required(t('validations.passwordConfirm')),
                }),
            }),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:label.site')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly defaultValue={site} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="form-method">
                  <Form.Label column sm={2}>
                    {t('pages:ftpPassword.ftpAccess')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="acessoFTP-true"
                      value="TRUE"
                      checked={props.values.acessoFTP === 'TRUE'}
                      type="radio"
                      label="Sim"
                      name="acessoFTP"
                      onChange={props.handleChange}
                    />
                    <Form.Check
                      inline
                      custom
                      id="acessoFTP-false"
                      value="FALSE"
                      checked={props.values.acessoFTP === 'FALSE'}
                      type="radio"
                      label="Não"
                      name="acessoFTP"
                      onChange={props.handleChange}
                    />
                  </Col>
                </Form.Group>
                {props.values.acessoFTP === 'TRUE' && (
                  <>
                    <Form.Group as={Row} controlId="ftp.Password">
                      <Form.Label column sm={2}>
                        {t('common:label.password')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="password"
                          name="password"
                          value={props.values.password}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.password && !!props.errors.password
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.password}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('common.matchPassword')}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="ftp.ConfirmPassword">
                      <Form.Label column sm={2}>
                        {t('common:label.confirmPassword')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="password"
                          name="confirm"
                          value={props.values.confirm}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.confirm && !!props.errors.confirm
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.confirm}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default FtpPassword;
