import React from 'react';
import { format, parseISO } from 'date-fns';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface HttpTrafficDay {
  date: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
}

interface HttpTrafficChartProps {
  data: HttpTrafficDay[];
}

const HttpTrafficChart: React.FC<HttpTrafficChartProps> = ({ data }) => {
  const formattedData = data.map(trafficData => {
    return {
      MegaBytes: trafficData.totalMb,
      date: format(parseISO(trafficData.date), 'dd/MM'),
    };
  });

  return (
    <ResponsiveContainer width="95%" height="95%" minHeight={300}>
      <LineChart
        width={500}
        height={300}
        data={formattedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="MegaBytes"
          stroke="#8884d8"
          // strokeDasharray="5 5"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HttpTrafficChart;
