import React from 'react';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import swal from 'services/swal';

import { Container } from './styles';

interface CheckNameServerFormValues {
  nameServer: string;
}

interface AddNameServerFormValues {
  nameServer: string;
  ip: string;
}

interface EditNameServerFormValues {
  nameServer: string;
  ip: string;
}

interface DeleteNameServerFormValues {
  nameServer: string;
}

interface CheckNameServerResponse {
  data: {
    whoisipaddress: string;
    whoisname: string;
  }[];
}

const OwnNameServers: React.FC = () => {
  const { t } = useTranslation();

  async function checkNameServer(values: CheckNameServerFormValues) {
    try {
      const checkNameServerResponse = await api.get<CheckNameServerResponse>(
        `enom/v1/nameserver`,
        {
          params: {
            nameServer: values.nameServer,
          },
        },
      );

      const nameServerInfo = checkNameServerResponse.data.data[0];

      swal.fire({
        icon: 'success',
        html: `Name server <b>${nameServerInfo.whoisname}</b> configurado com IP <b>${nameServerInfo.whoisipaddress}</b>`,
      });
    } catch (err) {
      swal.fire({
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  async function addNameServer(values: AddNameServerFormValues) {
    try {
      await api.post(`enom/v1/nameserver`, {
        nameServer: values.nameServer,
        ip: values.ip,
      });

      swal.fire({
        icon: 'success',
        html: t('pages:internationalOwnNameServer.newNameServerSuccess'),
      });
    } catch (err) {
      swal.fire({
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  async function editNameServer(values: EditNameServerFormValues) {
    try {
      await api.put(`enom/v1/nameserver`, {
        nameServer: values.nameServer,
        ip: values.ip,
      });

      swal.fire({
        icon: 'success',
        html: t('pages:internationalOwnNameServer.editNameServerSuccess'),
      });
    } catch (err) {
      swal.fire({
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  async function deleteNameServer(values: DeleteNameServerFormValues) {
    try {
      await api.delete(`enom/v1/nameserver`, {
        params: {
          nameServer: values.nameServer,
        },
      });

      swal.fire({
        icon: 'success',
        html: t('pages:internationalOwnNameServer.deleteNameServerSuccess'),
      });
    } catch (err) {
      swal.fire({
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:internationalOwnNameServer.checkNameServer')}
          description={t(
            'pages:internationalOwnNameServer.checkNameServerDescription',
          )}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer: '',
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={checkNameServer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t(
                    'pages:internationalOwnNameServer.checkNameServerFormSubtitle',
                  )}
                />

                <Form.Group as={Row} controlId="consult.nameServer">
                  <Form.Label column sm={2}>
                    Name Server
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer"
                      value={props.values.nameServer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer && !!props.errors.nameServer
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:internationalOwnNameServer.newNameServer')}
          description={t(
            'pages:internationalOwnNameServer.newNameServerDescription',
          )}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer: '',
            ip: '',
          }}
          validationSchema={Yup.object().shape({
            nameServer: Yup.string()
              .matches(
                /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]+$/,
                t('validations:invalidFormat'),
              )
              .required(t('validations:requiredField')),
            ip: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={addNameServer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t(
                    'pages:internationalOwnNameServer.editNameServerFormSubtitle',
                  )}
                />

                <Form.Group as={Row} controlId="consult.nameServer">
                  <Form.Label column sm={2}>
                    Name Server
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer"
                      value={props.values.nameServer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer && !!props.errors.nameServer
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="add.ip">
                  <Form.Label column sm={2}>
                    IP
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="ip"
                      value={props.values.ip}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.ip && !!props.errors.ip}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.ip}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:internationalOwnNameServer.editNameServer')}
          description={t(
            'pages:internationalOwnNameServer.editNameServerDescription',
          )}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer: '',
            ip: '',
          }}
          validationSchema={Yup.object().shape({
            nameServer: Yup.string()
              .matches(
                /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]+$/,
                t('validations:invalidFormat'),
              )
              .required(t('validations:requiredField')),
            ip: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={editNameServer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t(
                    'pages:internationalOwnNameServer.editNameServerFormSubtitle',
                  )}
                />

                <Form.Group as={Row} controlId="edit.nameServer">
                  <Form.Label column sm={2}>
                    Name Server
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer"
                      value={props.values.nameServer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer && !!props.errors.nameServer
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="edit.ip">
                  <Form.Label column sm={2}>
                    IP
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="ip"
                      value={props.values.ip}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.ip && !!props.errors.ip}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.ip}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:internationalOwnNameServer.deleteNameServer')}
          description={t(
            'pages:internationalOwnNameServer.deleteNameServerDescription',
          )}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            nameServer: '',
          }}
          validationSchema={Yup.object().shape({
            nameServer: Yup.string()
              .matches(
                /^([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]+$/,
                t('validations:invalidFormat'),
              )
              .required(t('validations:requiredField')),
          })}
          onSubmit={deleteNameServer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="delete.nameServer">
                  <Form.Label column sm={2}>
                    Name Server
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="nameServer"
                      value={props.values.nameServer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.nameServer && !!props.errors.nameServer
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.nameServer}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default OwnNameServers;
