import React, { useCallback, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';

import { Container, DropdownContainer } from './styles';

interface PanelDropdown {
  selectedValue: string;
  values: string[];
  onChange: (value: string) => void;
}

const PanelDropdown: React.FC<PanelDropdown> = ({
  selectedValue,
  values,
  onChange,
}) => {
  const [search, setSearch] = useState('');

  const filterValue = useCallback((item: string, searchText: string) => {
    return (
      !searchText || item.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
    );
  }, []);

  return (
    <Container>
      <Dropdown>
        <Dropdown.Toggle variant="primary btn-sm" className="dropdown-toggle">
          {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <FormControl
            autoFocus
            type="text"
            className="mx-3 my-2 search-input"
            placeholder="Procurar..."
            onChange={v => setSearch(v.target.value)}
            value={search}
          />
          <DropdownContainer>
            {values.map(item => {
              return filterValue(item, search) ? (
                <Dropdown.Item key={item} onClick={() => onChange(item)}>
                  {item}
                </Dropdown.Item>
              ) : null;
            })}
          </DropdownContainer>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default PanelDropdown;
