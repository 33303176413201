import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Modal, Row, Col } from 'react-bootstrap';

import PanelButton from 'components/PanelButton';

interface ChangeNameModalProps {
  show: boolean;
  idTicketStatus: number;
  ownerName: string;
  fetching: boolean;
  close: () => void;
  updateOwnerName: (values: UpdateOwnerNameFormValues) => void;
}

interface UpdateOwnerNameFormValues {
  ownerName: string;
}

const ChangeNameModal: React.FC<ChangeNameModalProps> = ({
  show,
  idTicketStatus,
  ownerName,
  fetching,
  close,
  updateOwnerName,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('common:responsible')}</Modal.Title>
      </Modal.Header>

      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ ownerName: '' }}
        onSubmit={updateOwnerName}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  {t('common:responsible')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control plaintext readOnly defaultValue={ownerName} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="changeNameModal.FirstName">
                <Form.Label column sm={3} className="required">
                  {t('pages:ticket.newResponsible')}
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    name="ownerName"
                    type="text"
                    value={props.values.ownerName}
                    onChange={props.handleChange}
                    isInvalid={
                      !!props.touched.ownerName && !!props.errors.ownerName
                    }
                    disabled={props.isSubmitting || idTicketStatus === 4}
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.errors.ownerName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <PanelButton type="submit" variant="primary" disabled={fetching}>
                {t('common:button.save')}
              </PanelButton>
              <PanelButton variant="secondary" onClick={() => close()}>
                {t('common:button.close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeNameModal;
