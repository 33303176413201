import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import { store } from 'store';

import { logout } from 'store/modules/auth/actions';

/**
 * Este componente não deve ser evoluído nem replicado. Este não é um código
 * desejável pois ele não segue o comportamento padrão do app.
 *
 * Esta rota foi criada somente para apagar os dados persistidos que mantém a
 * autenticação do painel para assim garantir que seja feita a nova autenticação
 * que vem da Intranet.
 *
 * Caso a requisição não passe por esse processo a nova autenticação será
 * ignorada pois o usuário já está autenticado.
 */
const IntranetPreAuth: React.FC = () => {
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);

  function isIntranetRequest() {
    const hasHostnetIdToken = urlSearchParams.get('hostnetid_token') !== null;
    const hasScope = urlSearchParams.get('scope') !== null;

    return hasHostnetIdToken && hasScope;
  }

  const hasRefreshSession = urlSearchParams.get('refreshSession') !== null;

  if (isIntranetRequest() || hasRefreshSession) {
    store.dispatch(logout());
    localStorage.clear();
    Cookies.remove('auth');

    window.location.href = `${window.location.origin}/${search}`;
  } else {
    return <Redirect to="/" />;
  }

  return <div>Carregando...</div>;
};

export default IntranetPreAuth;
