import { useQuery } from 'react-query';

import api from 'services/api';

type EmailRedirectItemResponse = {
  dominio: string;
  emailDestino: string;
  emailOrigem: string;
  idAlias: string;
  idDominio: string;
  idRedirecionamento: string;
  redirecionamento: string;
};

type EmailRedirectsResponse = {
  data: EmailRedirectItemResponse[];
};

type EmailRedirect = {
  dominio: string;
  emailDestino: string;
  emailOrigem: string;
  idAlias: string;
  idDominio: string;
  idRedirecionamento: string;
  redirecionamento: string;
};

const initialData: EmailRedirect[] = [];

function formatEmailRedirect(
  emailRedirect: EmailRedirectItemResponse,
): EmailRedirect {
  return emailRedirect;
}

async function loadEmailRedirects(site: string): Promise<EmailRedirect[]> {
  const emailRedirectsResponse = await api.get<EmailRedirectsResponse>(
    `painel/v1/email/redirecionamento`,
    {
      params: {
        site,
      },
    },
  );

  const emailRedirects = emailRedirectsResponse.data.data.map(
    formatEmailRedirect,
  );

  return emailRedirects;
}

export default function useEmailRedirects(site: string) {
  const { data, ...rest } = useQuery<EmailRedirect[]>(
    ['emailRedirects', site],
    () => loadEmailRedirects(site),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const emailRedirects = data || initialData;

  return {
    emailRedirects,
    ...rest,
  };
}
