import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import { Container } from './styles';

interface FilterInputProps {
  onChange: (val: string) => void;
  placeholder?: string;
}

const FilterInput: React.FC<FilterInputProps> = ({
  onChange,
  placeholder = 'Filtro',
}) => {
  return (
    <Container>
      <InputGroup className="mr-sm-2" size="sm">
        <FormControl
          placeholder={placeholder}
          aria-label={placeholder}
          size="sm"
          onChange={e => onChange(e.target.value)}
        />
        <InputGroup.Append>
          <InputGroup.Text>
            <FaSearch />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </Container>
  );
};

export default FilterInput;
