import { useQuery } from 'react-query';

import api from 'services/api';

type SiteItemResponse = {
  idSite: number;
  idCliente: number;
  idPlano: number;
  site: string;
  plano: string;
  memoria: string;
  cpu: string;
  valor: string;
  tecnologia: string;
  ftp: 'TRUE' | 'FALSE';
  registroPromocionalDisponivel: boolean;
};

type SitesResponse = {
  data: SiteItemResponse[];
};

type Site = {
  idSite: number;
  idCliente: number;
  idPlano: number;
  site: string;
  plano: string;
  memoria: string;
  cpu: string;
  valor: string;
  tecnologia: string;
  ftp: boolean;
  registroPromocionalDisponivel: boolean;
};

const initialData: Site[] = [];

function formatSite(site: SiteItemResponse): Site {
  const { idSite, idCliente, idPlano, ftp, ...siteRest } = site;

  return {
    idSite: Number(idSite),
    idCliente: Number(idCliente),
    idPlano: Number(idPlano),
    ftp: ftp === 'TRUE',
    ...siteRest,
  };
}

async function loadSites(): Promise<Site[]> {
  const sitesResponse = await api.get<SitesResponse>(`clientes/v1/site`);

  const sites = sitesResponse.data.data.map(formatSite);

  return sites;
}

export default function useSites() {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<Site[]>(
    ['sites'],
    () => loadSites(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const sites = data || initialData;

  return {
    sites,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
