import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaDatabase } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import Error from 'components/Error';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import toast from 'services/toast';
import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

interface DatabaseItemResponse {
  id: number;
  banco: string;
}

interface BackupOptionsResponse {
  data: {
    bancos: DatabaseItemResponse[];
    datas: string[];
  };
}

interface Database {
  id: number;
  banco: string;
}

interface BackupFormValues {
  date: string;
  database: number;
}

const Backup: React.FC = () => {
  const [t] = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [dates, setDates] = useState<string[]>([]);
  const [databases, setDatabases] = useState<Database[]>([]);

  useEffect(() => {
    async function loadOptions() {
      try {
        setError(false);
        setLoading(true);

        const backupMySqlResponse = await api.get<BackupOptionsResponse>(
          `painel/v1/site/${idSite}/banco-mysql/backup`,
        );

        const databasesOptions = backupMySqlResponse.data.data.bancos.map(
          ({ id, banco }) => ({
            id: Number(id),
            banco,
          }),
        );

        setDates(backupMySqlResponse.data.data.datas);
        setDatabases(databasesOptions);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadOptions();
  }, [idSite]);

  const requestBackup = useCallback(
    async (values: BackupFormValues) => {
      try {
        const selectedDatabase = databases.find(
          databaseSearch =>
            Number(databaseSearch.id) === Number(values.database),
        );

        if (selectedDatabase === undefined) {
          return;
        }

        const { isConfirmed } = await swal.fire({
          icon: 'info',
          title: t('common:confirmOperation'),
          html: t('pages:databaseMySqlBackup.confirmMessage', {
            date: format(parseISO(values.date), 'dd/MM/yyyy'),
            databaseName: selectedDatabase.banco,
          }),
          showCancelButton: true,
        });

        if (!isConfirmed) {
          return;
        }

        await api.post(
          `painel/v1/site/${idSite}/banco-mysql/${values.database}/backup`,
          { data: values.date },
        );

        toast.fire(t('pages:databaseMySqlBackup.success'));

        history.push(`/banco-de-dados-mysql`);
      } catch (err) {
        swal.fire({
          title: t('pages:databaseMySqlBackup.failed'),
          html: err.response.data.error_description,
        });
      }
    },
    [idSite, t, databases],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  if (databases.length === 0) {
    return (
      <Container>
        <FormWrapper>
          <FormHeader
            title={t('pages:databaseMySqlBackup.title')}
            description=""
          />
          <Card.Body>{t('pages:databaseMySqlBackup.noDatabases')}</Card.Body>
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton
              variant="secondary"
              forwardedAs={Link}
              to="/banco-de-dados-mysql"
            >
              {t('common:label.back')}
            </PanelButton>
          </div>
        </FormWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:databaseMySqlBackup.title')}
          description=""
          directHelpLink="https://ajuda.hostnet.com.br/backup-na-hostnet"
        />
        <Formik
          enableReinitialize
          initialValues={{
            date: dates[0],
            database: databases[0] && databases[0].id,
          }}
          onSubmit={requestBackup}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset pt-0">
                <div className="border-bottom mb-4">
                  <div>{t('pages:databaseMySqlBackup.legend.p1')}</div>
                  <div>{t('pages:databaseMySqlBackup.legend.p2')}</div>
                  <div>{t('pages:databaseMySqlBackup.legend.p3')}</div>
                  <br />
                </div>

                <Form.Group as={Row} controlId="form-database">
                  <Form.Label column sm={2}>
                    {t('common:label.database')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="database"
                      value={props.values.database}
                      onChange={props.handleChange}
                    >
                      {databases.map(database => (
                        <option key={database.id} value={database.id}>
                          {database.banco}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="form-date">
                  <Form.Label column sm={2}>
                    {t('common:label.date')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="date"
                      value={props.values.date}
                      onChange={props.handleChange}
                    >
                      {dates.map(date => (
                        <option key={date} value={date}>
                          {format(parseISO(date), 'dd/MM/yyyy')}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/banco-de-dados-mysql"
                  disabled={props.isSubmitting}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Backup;
