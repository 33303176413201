import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Container } from './styles';

export default function Loading() {
  return (
    <Container className="my-5">
      <Spinner animation="border" variant="primary" />
    </Container>
  );
}
