import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaIdCard } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import Loading from 'components/Loading';
import TableButton from 'components/TableButton';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';

import useAdditionalUsers from 'hooks/useAdditionalUsers';

import filter from 'utils/filter';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import AdditionalUsersActions from './components/AdditionalUsersActions';

import { Container } from './styles';

const AdditionalUsers: React.FC = () => {
  const { t } = useTranslation();

  const {
    additionalUsers,
    refetch,
    isFetching,
    isError,
  } = useAdditionalUsers();

  const [filterText, setFilterText] = useState('');
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof additionalUsers;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  const filteredAdditionalUsers: typeof additionalUsers = filter(
    additionalUsers,
    ['user', 'type'],
    filterText,
  );

  async function deleteAdditionalUsers(keys: string[]) {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:confirmOperation'),
        text: t('pages:additionalUsers.deleteWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      await api.delete(`painel/v1/usuario-adicional`, {
        params: {
          chaves: keys,
        },
      });

      await refetch();

      setToggleCleared(prev => !prev);

      toast.fire(t('pages:additionalUsers.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setDeleting(false);
    }
  }

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:additionalUsers.title')}
        description={t('titles:additionalUsers.description')}
        icon={<FaIdCard color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:additionalUsers.tableTitle')}
          description={t('pages:additionalUsers.subTitle')}
          directHelpLink="https://ajuda.hostnet.com.br/usuarios-no-painel-de-controle/"
        />

        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/usuarios-adicionais/adicionar">
              {t('pages:additionalUsers.newAdditionalUser')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            dense
            pagination
            selectableRows
            columns={[
              {
                name: t('common:email'),
                selector: 'user',
                sortable: true,
              },
              {
                name: t('common:level'),
                selector: 'type',
                sortable: true,
              },
              {
                name: t('pages:additionalUsers.registrationDate'),
                selector: 'registrationDate',
                sortable: true,
                width: '180px',
                cell: additionalUser => additionalUser.registrationDate,
              },
            ]}
            data={filteredAdditionalUsers}
            progressPending={isFetching}
            clearSelectedRows={toggleCleared}
            progressComponent={<Loading />}
            selectableRowDisabled={() => deleting}
            noDataComponent={<TableNoData />}
            contextComponent={
              <AdditionalUsersActions
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                loading={isFetching || deleting}
                deleteAdditionalUsers={deleteAdditionalUsers}
              />
            }
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default AdditionalUsers;
