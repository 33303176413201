import React from 'react';

import hashIds from 'services/hashIds';

import { Container, Label, List, ListItem } from './styles';

interface SideMenuSessionProps {
  label: string;
  elements: React.ReactNode[];
}

const SideMenuSession: React.FC<SideMenuSessionProps> = ({
  label,
  elements,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <List>
        {elements.map((element, index) => (
          <ListItem key={`${hashIds.encode(index)}sms`}>{element}</ListItem>
        ))}
      </List>
    </Container>
  );
};

export default SideMenuSession;
