import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';

import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';

import formatCurrency from 'utils/formatCurrency';

import { Range } from './styles';

type Product = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  descricao: string;
  valor: number;
  valorFormatado: string;
  categoria: string;
  expansao: number;
  expansaoMaxima: number;
  unidade: string;
};

interface CustomQuantityProductProps {
  product: Product;
  onSubmit: (idProduct: number, amount: number) => Promise<void>;
  submitting: boolean;
}

function getExtraDescription(idProduct: number, amount: number) {
  const descriptions = [
    {
      idProduct: 216,
      single: ' (1 banco de dados MySQL adicional)',
      multiple: ' (%amount% bancos de dados MySQL adicionais)',
    },
    {
      idProduct: 344,
      single: ' Memória e vCPU',
      multiple: ' Memória e vCPU',
    },
  ];

  const searchDescription = descriptions.find(
    productDescription => productDescription.idProduct === idProduct,
  );

  if (searchDescription && amount === 1) {
    return searchDescription.single;
  }

  if (searchDescription && amount > 1) {
    return searchDescription.multiple.replace('%amount%', String(amount));
  }

  return '';
}

const CustomQuantityProduct: React.FC<CustomQuantityProductProps> = ({
  product,
  onSubmit,
  submitting,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          totalAmount: product.expansao,
          totalValue: product.valor,
          contracts: 1,
        }}
        onSubmit={async (values, { resetForm }) => {
          await onSubmit(product.idProduto, values.contracts);
          resetForm();
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Card.Body className="fieldset">
              <FormSubtitle subTitle="Selecione a quantidade" />
              <Form.Group as={Row} controlId="product.custom">
                <Form.Label column sm={2}>
                  {t('common:quantity')}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    readOnly
                    type="text"
                    value={
                      props.values.totalAmount +
                      product.unidade +
                      getExtraDescription(
                        product.idProduto,
                        props.values.contracts,
                      )
                    }
                  />
                  <Range
                    type="range"
                    value={props.values.totalAmount}
                    step={product.expansao}
                    max={product.expansaoMaxima}
                    min={product.expansao}
                    disabled={submitting}
                    onChange={e => {
                      const value = Number(e.target.value);
                      const contracts = value / product.expansao;

                      props.setFieldValue('contracts', contracts);
                      props.setFieldValue(
                        'totalValue',
                        contracts * product.valor,
                      );

                      props.setFieldValue('totalAmount', value);
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="product.price">
                <Form.Label column sm={2}>
                  {t('common:price')}
                </Form.Label>
                <Col sm={10}>
                  <div>{formatCurrency(props.values.totalValue)}</div>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="product.price">
                <Form.Label column sm={2} />
                <Col sm={10}>
                  <PanelButton
                    type="submit"
                    className="mr-1"
                    disabled={props.isSubmitting}
                  >
                    {props.isSubmitting
                      ? t('common:sending')
                      : t('common:hire')}
                  </PanelButton>
                </Col>
              </Form.Group>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CustomQuantityProduct;
