import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const StyledCard = styled(Card)`
  border: none;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);

  .card-header {
    padding: 20px;
    border-bottom: none;
    background-color: transparent;

    h5 {
      margin-bottom: 0;
      color: #333;
      font-size: 15px;
      font-weight: 700;
      display: inline-block;
      margin-right: 10px;
      line-height: 1.1;
      position: relative;

      &:after {
        content: '';
        background-color: #d2d2d2;
        width: 60px;
        height: 1px;
        position: absolute;
        bottom: -20px;
        left: 0;
      }
    }
  }
`;
