import styled from 'styled-components';

export const Container = styled.div`
  .negativo {
    color: red;
  }
`;

export const Info = styled.div`
  font-size: 24px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.blue {
    color: var(--color-blue);
  }

  &.violet {
    color: var(--color-violet);
  }

  &.yellow {
    color: var(--color-yellow);
  }

  &.green {
    color: var(--color-green);
  }
`;
