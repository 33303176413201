export default function filter<T, U extends keyof T>(
  data: T[],
  attrs: U[],
  search = '',
): T[] {
  const filtered = data.filter(item => {
    const subject = attrs
      .map(index => (index in item ? item[index] : ''))
      .join(' ');

    return subject.toLowerCase().includes(search.toLowerCase());
  });

  return filtered;
}
