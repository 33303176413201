import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaDatabase } from 'react-icons/fa';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';

import { StoreState } from 'store/createStore';

import useMySqlSettings from 'hooks/useMySqlSettings';

import swal from 'services/swal';
import toast from 'services/toast';
import api from 'services/api';

import { Container } from './styles';

interface MySqlSettingsFormValues {
  maxAllowedPacket: number;
  maxUserConnections: number;
  maxConnections: number;
  connectTimeout: number;
  strictTransTables: string;
}

const Settings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { mySqlSettings, isLoading, isError, refetch } = useMySqlSettings(
    idSite,
  );

  async function handleFormSubmit(values: MySqlSettingsFormValues) {
    try {
      await api.put(`painel/v1/site/${idSite}/mysql`, {
        maxAllowedPacket: values.maxAllowedPacket * 1024,
        connectTimeout: values.connectTimeout,
        maxConnections: values.maxConnections,
        maxUserConnections: values.maxUserConnections,
        strictTransTables: values.strictTransTables === 'TRUE' ? 1 : 0,
      });

      await refetch();

      toast.fire(t('pages:mySqlCloudSettings.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:mySqlCloudSettings.failed'),
        html: err.response.data.error_description,
      });
    }
  }

  function restoreDefaultSettings(
    formikHelpers: FormikHelpers<MySqlSettingsFormValues>,
  ) {
    formikHelpers.setValues({
      maxAllowedPacket: 16,
      connectTimeout: 60,
      maxConnections: 100,
      maxUserConnections: 100,
      strictTransTables: 'TRUE',
    });
  }

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:mySqlCloudSettings.title')}
          description={t('pages:mySqlCloudSettings.description')}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            maxAllowedPacket: Math.round(mySqlSettings.maxAllowedPacket / 1024),
            maxUserConnections: mySqlSettings.maxUserConnections,
            maxConnections: mySqlSettings.maxConnections,
            connectTimeout: mySqlSettings.connectTimeout,
            strictTransTables: mySqlSettings.strictTransTable
              ? 'TRUE'
              : 'FALSE',
          }}
          validationSchema={Yup.object().shape({
            maxAllowedPacket: Yup.string().required(
              t('validations:requiredField'),
            ),
            maxUserConnections: Yup.string().required(
              t('validations:requiredField'),
            ),
            maxConnections: Yup.string().required(
              t('validations:requiredField'),
            ),
            connectTimeout: Yup.string().required(
              t('validations:requiredField'),
            ),
          })}
          onSubmit={handleFormSubmit}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset pt-0">
                <FormSubtitle
                  subTitle={t('pages:mySqlCloudSettings.basicSettings')}
                />

                <Form.Group as={Row} controlId="mysql.maxAllowedPacket">
                  <Form.Label column sm={2}>
                    max_allowed_packet
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      name="maxAllowedPacket"
                      min="1"
                      value={props.values.maxAllowedPacket}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.maxAllowedPacket &&
                        !!props.errors.maxAllowedPacket
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.maxAllowedPacket}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysql.maxUserConnections">
                  <Form.Label column sm={2}>
                    max_user_connections
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      name="maxUserConnections"
                      min="1"
                      value={props.values.maxUserConnections}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.maxUserConnections &&
                        !!props.errors.maxUserConnections
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.maxUserConnections}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysql.maxConnections">
                  <Form.Label column sm={2}>
                    max_connections
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      name="maxConnections"
                      min="1"
                      value={props.values.maxConnections}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.maxConnections &&
                        !!props.errors.maxConnections
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.maxConnections}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysql.connectTimeout">
                  <Form.Label column sm={2}>
                    connect_timeout
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      name="connectTimeout"
                      min="1"
                      value={props.values.connectTimeout}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.connectTimeout &&
                        !!props.errors.connectTimeout
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.connectTimeout}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="mysql.strictTransTables">
                  <Form.Label column sm={2}>
                    strict_trans_tables
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="strictTransTables-true"
                      value="TRUE"
                      checked={props.values.strictTransTables === 'TRUE'}
                      type="radio"
                      label="Sim"
                      name="strictTransTables"
                      onChange={props.handleChange}
                    />
                    <Form.Check
                      inline
                      custom
                      id="strictTransTables-false"
                      value="FALSE"
                      checked={props.values.strictTransTables === 'FALSE'}
                      type="radio"
                      label="Não"
                      name="strictTransTables"
                      onChange={props.handleChange}
                    />
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  onClick={() => restoreDefaultSettings(props)}
                  className="mr-1"
                  disabled={props.isSubmitting}
                >
                  {t('pages:mySqlCloudSettings.restore')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default Settings;
