import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'react-bootstrap';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import PanelCard from 'components/PanelCard';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';
import Loading from 'components/Loading';
import TableButton from 'components/TableButton';

import api from 'services/api';

import filter from 'utils/filter';
import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import { Container, Info } from './styles';

interface StatementItemResponse {
  idHistorico: number;
  acao: string;
  data: string;
  saldoParcial: string;
  valor: string;
  descricao: string;
  futuro: boolean;
}

interface StatementResponse {
  data: {
    saldo: string;
    saldoFuturo: string;
    debitoFuturo: string;
    extrato: StatementItemResponse[];
    lancamentosFuturos: StatementItemResponse[];
  };
}

interface DiscountItemResponse {
  idDesconto: number;
  idSite: number;
  valorFormatado: string;
}

interface DiscountResponse {
  data: DiscountItemResponse[];
}

interface Discount {
  idDesconto: number;
  idSite: number;
  valorFormatado: string;
}

interface StatementItem {
  idHistorico: number;
  acao: string;
  data: string;
  saldoParcial: string;
  valor: string;
  descricao: string;
  futuro: boolean;
}

interface Balance {
  saldo: number;
  saldoFuturo: number;
  debitoFuturo: number;
}

const Statements: React.FC = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [balance, setBalance] = useState<Balance>({
    saldo: 0,
    saldoFuturo: 0,
    debitoFuturo: 0,
  });

  const [discount, setDiscount] = useState<Discount[]>([]);
  const [statements, setStatements] = useState<StatementItem[]>([]);
  const [filteredStatements, setFilteredStatements] = useState<StatementItem[]>(
    [],
  );
  const [statementsFilterText, setStatementsFilterText] = useState('');

  const [futureStatements, setFutureStatements] = useState<StatementItem[]>([]);
  const [filteredFutureStatements, setFilteredFutureStatements] = useState<
    StatementItem[]
  >([]);

  const [futureStatementsFilterText, setFutureStatementsFilterText] = useState(
    '',
  );
  const { idCliente, tipoCobranca, contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    async function loadHistory() {
      try {
        setLoading(true);
        setError(false);

        const historyRequest = api.get<StatementResponse>(
          `financeiro/v1/cliente/${idCliente}/extrato`,
          {
            params: {
              ...(tipoCobranca === 'MULTIPLA' && contaComSite && { idSite }),
            },
          },
        );

        const discountRequest = api.get<DiscountResponse>(
          `clientes/v1/desconto`,
          {
            params: {
              status: 'ATIVO',
              ...(tipoCobranca === 'MULTIPLA' && contaComSite && { idSite }),
            },
          },
        );

        const [historyResponse, discountResponse] = await Promise.all([
          historyRequest,
          discountRequest,
        ]);

        if (discountResponse.data.data.length > 0) {
          const { idDesconto, valorFormatado } = discountResponse.data.data[0];

          setDiscount([
            {
              idSite,
              idDesconto,
              valorFormatado,
            },
          ]);
        }

        const {
          extrato,
          saldo,
          debitoFuturo,
          saldoFuturo,
        } = historyResponse.data.data;

        const historyStatements = extrato
          .reverse()
          .filter(item => item.futuro === false);

        const futureHistoryStatements = extrato.filter(
          item => item.futuro === true,
        );

        setBalance({
          saldo: parseFloat(saldo),
          debitoFuturo: parseFloat(debitoFuturo),
          saldoFuturo: parseFloat(saldoFuturo),
        });

        setStatements(historyStatements);
        setFutureStatements(futureHistoryStatements);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadHistory();
  }, [contaComSite, idCliente, idSite, tipoCobranca]);

  useEffect(() => {
    setFilteredStatements(
      filter(statements, ['descricao'], statementsFilterText),
    );
  }, [statements, statementsFilterText]);

  useEffect(() => {
    setFilteredFutureStatements(
      filter(futureStatements, ['descricao'], futureStatementsFilterText),
    );
  }, [futureStatements, futureStatementsFilterText]);

  const columns = [
    {
      name: t('common:date'),
      selector: 'data',
      sortable: true,
      cell: (row: StatementItem) => format(parseISO(row.data), 'dd/MM/yyyy'),
      width: '120px',
    },
    {
      name: t('common:description'),
      selector: 'descricao',
      wrap: true,
    },
    {
      name: t('common:value'),
      selector: 'valor',
      cell: (row: StatementItem) => (
        <span className={row.acao === '-' ? 'negativo' : ''}>
          {(row.acao === '-' ? '-' : '') +
            formatCurrency(parseFloat(row.valor))}
        </span>
      ),
      width: '120px',
    },
    {
      name: t('common:balance'),
      selector: 'saldoParcial',
      cell: (row: StatementItem) => (
        <span className={parseFloat(row.saldoParcial) < 0 ? 'negativo' : ''}>
          {formatCurrency(parseFloat(row.saldoParcial))}
        </span>
      ),
      width: '120px',
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <Row className="my-4">
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('common:balance')}</h6>
                <Info className="blue">{formatCurrency(balance.saldo)}</Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:financeStatements.paymentsDue')}</h6>
                <Info className="blue">
                  {balance.debitoFuturo > 0
                    ? formatCurrency(balance.debitoFuturo)
                    : 'R$0,00'}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        <Col className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:financeStatements.pendingPayments')}</h6>
                <Info className="blue">
                  {balance.saldo < 0
                    ? formatCurrency(Math.abs(balance.saldo))
                    : 'R$0,00'}
                </Info>
              </div>
            </Card.Body>
          </PanelCard>
        </Col>
        {discount.length > 0 && (
          <Col className="mb-4 mb-xl-0">
            <PanelCard>
              <Card.Body>
                <div className="mb-4">
                  <h6>{t('common:discount')}</h6>
                  <Info className="blue">{discount[0].valorFormatado}</Info>
                </div>
              </Card.Body>
            </PanelCard>
          </Col>
        )}
      </Row>

      <TableWrapper>
        <TableHeader
          title={t('pages:financeStatements.title')}
          description={t('pages:financeStatements.subTitle')}
          directHelpLink="https://ajuda.hostnet.com.br/extrato-financeiro/"
        />

        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/financeiro/adicionar-credito">
              {t('pages:financeStatements.addCredit')}
            </TableButton>
            <TableButton forwardedAs={Link} to="/financeiro/historico-boletos">
              {t('pages:financeStatements.bankSlipHistory')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setStatementsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            pagination
            data={filteredStatements}
            columns={columns}
            progressPending={loading}
            paginationComponentOptions={{
              rowsPerPageText: t('pages:financeStatements.registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:financeStatements.futureStatements')}
          description={t('pages:financeStatements.futureStatementsDescription')}
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput
              onChange={value => setFutureStatementsFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            pagination
            data={filteredFutureStatements}
            progressPending={loading}
            columns={columns}
            paginationComponentOptions={{
              rowsPerPageText: t('pages:financeStatements.registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Statements;
