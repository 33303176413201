import React from 'react';
import { Dropdown } from 'react-bootstrap';
import {
  FiChevronDown,
  FiHelpCircle,
  FiLogOut,
  FiPhone,
  FiUser,
} from 'react-icons/fi';

import { Container, DropdownMenu, DropDownContent } from './styles';

interface HeaderMenuOptions {
  email: string;
  logout: () => void;
  mobileMenuIsOpen: boolean;
}

const HeaderMenu: React.FC<HeaderMenuOptions> = ({
  email,
  logout,
  mobileMenuIsOpen,
}) => {
  return (
    <Container className={mobileMenuIsOpen ? 'open' : 'flex-grow-1'}>
      <DropdownMenu
        title={
          <>
            <span className="mr-2">{email}</span>
            <FiChevronDown size={15} color="#333333" />
          </>
        }
      >
        <DropDownContent>
          <Dropdown.Item
            target="_blank"
            href="https://id.hostnet.com.br/cadastro"
          >
            <FiUser size={15} className="mr-2" />
            Meu cadastro
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            target="_blank"
            href="https://www.hostnet.com.br/info/"
          >
            <FiHelpCircle size={15} className="mr-2" />
            Ajuda
          </Dropdown.Item>

          <Dropdown.Item
            target="_blank"
            href="https://www.hostnet.com.br/atendimento-ao-cliente/"
          >
            <FiPhone size={15} className="mr-2" />
            Atendimento
          </Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Item onClick={logout}>
            <FiLogOut size={15} className="mr-2" />
            Sair
          </Dropdown.Item>
        </DropDownContent>
      </DropdownMenu>
    </Container>
  );
};

export default HeaderMenu;
