import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

import TableNoData from 'components/TableNoData';
import TableIconButton from 'components/TableIconButton';
import history from 'services/history';

type AvailableProduct = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  descricao: string;
  valor: number;
  valorFormatado: string;
  contratado: boolean;
  categoria: string;
  expansao: number;
  expansaoMaxima: number;
  unidade: string;
  jaPossuiProdutoDaCategoria: boolean;
};

type TableListProductProps = {
  products: AvailableProduct[];
  onClick: (idProduct: number, amount: number) => void;
  submitting: boolean;
};

const TableListProduct: React.FC<TableListProductProps> = ({
  products,
  onClick,
  submitting,
}) => {
  const { t } = useTranslation();

  function getButtonToAccessService(idCategory: number) {
    const services = [
      {
        idCategory: 35,
        button: (
          <TableIconButton
            className="btn-buy"
            onClick={() => history.push('/helpdesk')}
          >
            Abrir chamado
          </TableIconButton>
        ),
      },
      {
        idCategory: 27,
        button: (
          <TableIconButton
            className="btn-buy"
            onClick={() => history.push('/helpdesk')}
          >
            Abrir chamado
          </TableIconButton>
        ),
      },
      {
        idCategory: 40,
        button: (
          <TableIconButton
            className="btn-buy"
            onClick={() => history.push('/helpdesk')}
          >
            Abrir chamado
          </TableIconButton>
        ),
      },
      {
        idCategory: 41,
        button: (
          <TableIconButton
            className="btn-buy"
            onClick={() => {
              window.location.href = `https://academia.hostnet.com.br/`;
            }}
          >
            Acessar
          </TableIconButton>
        ),
      },
    ];

    const service = services.find(
      serviceItem => serviceItem.idCategory === idCategory,
    );

    if (service) {
      return service.button;
    }

    return <></>;
  }

  return (
    <div>
      <div className="datatables-table">
        <DataTable
          dense
          noHeader
          pagination
          data={products}
          columns={[
            {
              name: t('common:name'),
              selector: 'produto',
              sortable: true,
              minWidth: '250px',
            },
            {
              name: t('common:description'),
              selector: 'descricao',
              sortable: true,
              minWidth: '100px',
            },
            {
              name: 'Vigência',
              selector: 'validade',
              sortable: true,
              width: '200px',
            },
            {
              name: t('common:value'),
              selector: 'valorFormatado',
              sortable: true,
              width: '110px',
            },
            {
              name: '',
              cell: row => {
                if (row.contratado) {
                  return getButtonToAccessService(row.idProdutoCategoria);
                }

                if (row.jaPossuiProdutoDaCategoria) {
                  return <div />;
                }

                return (
                  <TableIconButton
                    className="btn-buy"
                    onClick={() => onClick(row.idProduto, 1)}
                    disabled={submitting}
                  >
                    Contratar
                  </TableIconButton>
                );
              },
              width: '160px',
            },
          ]}
          noDataComponent={<TableNoData />}
          paginationComponentOptions={{
            rowsPerPageText: t('common:registersPerPage'),
            rangeSeparatorText: t('common:of'),
          }}
        />
      </div>
    </div>
  );
};

export default TableListProduct;
