/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import * as Yup from 'yup';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useAdditionalUsers from 'hooks/useAdditionalUsers';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type AdditionalUserEditProps = RouteComponentProps<MatchParams>;

interface AdditionalUserItemResponse {
  usuario: string;
  tipo: string;
  chave: string;
}

interface LoadAdditionalUserResponse {
  data: AdditionalUserItemResponse[];
}

interface EditAdditionalUserResponse {
  data: {
    tipo: AdditionalUserItemResponse;
  };
}

interface EditAdditionalUserFormValues {
  usuario: string;
  tipo: string;
  chave: string;
}

interface AdditionalUser {
  usuario: string;
  tipo: string;
  chave: string;
}

const Edit: React.FC<AdditionalUserEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const chave = match.params.id.replace(
    /(.{8})(.{4})(.{4})(.{4})/,
    '$1-$2-$3-$4-',
  );

  const { refetch } = useAdditionalUsers();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [additionalUser, setAdditionalUser] = useState<AdditionalUser>({
    usuario: '',
    tipo: '',
    chave: '',
  });

  function formatAdditionalUser({
    ...additionalUserRest
  }: AdditionalUserItemResponse) {
    return {
      ...additionalUserRest,
    };
  }

  useEffect(() => {
    async function loadAdditionalUser() {
      try {
        setLoading(true);
        setError(false);

        const additionalUserResponse = await api.get<LoadAdditionalUserResponse>(
          `painel/v1/usuario-adicional`,
        );

        if (additionalUserResponse.data.data.length > 0) {
          const user = additionalUserResponse.data.data.find(
            o => o.chave === chave,
          );

          if (user) {
            setAdditionalUser(formatAdditionalUser(user));
          }
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadAdditionalUser();
  }, [chave]);

  async function editAdditionalUsers(values: EditAdditionalUserFormValues) {
    try {
      setUpdating(true);

      let updatedAdditionalUser = null;

      const { tipo } = values;

      const editAdditionalUserResponse = await api.put<EditAdditionalUserResponse>(
        `painel/v1/usuario-adicional`,
        {
          chave,
          nivel: tipo,
        },
      );

      updatedAdditionalUser = editAdditionalUserResponse.data.data.tipo;

      if (updatedAdditionalUser) {
        setAdditionalUser(updatedAdditionalUser);
        refetch();
      }

      toast.fire(t('pages:additionalUsersEdit.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:additionalUsersEdit.failed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:additionalUsers.Edit.title')}
        description={t('titles:additionalUsers.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:additionalUsersEdit.title')}
          description={t('pages:additionalUsersEdit.description')}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            usuario: additionalUser.usuario,
            tipo: additionalUser.tipo,
            chave: additionalUser.chave,
          }}
          validationSchema={Yup.object().shape({
            tipo: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={editAdditionalUsers}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="additionalUsers.usuario">
                  <Form.Label column sm={2}>
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="usuario"
                      plaintext
                      readOnly
                      value={props.values.usuario}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.usuario}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="additionalUsers.tipo">
                  <Form.Label column sm={2} className="required">
                    {t('common:level')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="tipo"
                      onChange={props.handleChange}
                      value={props.values.tipo}
                      disabled={updating}
                    >
                      <option key="ADMINISTRADOR" value="ADMINISTRADOR">
                        Administrador
                      </option>
                      <option key="TECNICO" value="TECNICO">
                        Técnico
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  size="sm"
                  disabled={updating || !props.isValid || !props.dirty}
                >
                  {updating ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/usuarios-adicionais"
                  disabled={updating}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
