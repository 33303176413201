import produce from 'immer';

import { SitesAction, SitesState } from './types';

import { AuthAction } from '../auth/types';

type Action = SitesAction | AuthAction;

const initialState: SitesState = {
  list: [],
  idFavoriteSite: 0,
  updating: false,
  loading: false,
  error: false,
};

export default function sites(
  state = initialState,
  action: Action,
): SitesState {
  return produce(state, draft => {
    switch (action.type) {
      case '@sites/SITES_SUCCESS':
        draft.list = action.payload.sites;
        break;
      case '@sites/SET_FAVORITE_SITE':
        draft.idFavoriteSite = action.payload.idFavoriteSite;
        draft.updating = false;
        break;
      case '@sites/SELECT_FAVORITE_SITE_REQUEST':
        draft.updating = true;
        break;
      case '@sites/SELECT_FAVORITE_SITE_FAILURE':
        draft.updating = false;
        break;
      case '@auth/LOGOUT':
        draft = initialState;
        break;
      default:
        return draft;
    }

    return draft;
  });
}
