import styled from 'styled-components';

export const Container = styled.div`
  .card {
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.07);
  }

  .btn-primary {
    font-size: 13px;
  }

  .btn-container {
    padding: 1.25rem;
    padding-top: 0;
  }

  .img-container {
    text-align: center;

    img {
      // width: 80%;
      // max-width: 150px;
    }
  }
`;

export const CategoryIconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  min-width: 35px;
  height: 35px;

  margin-right: 10px;

  border-radius: 5px;
  background-color: var(--color-blue);
  // box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
`;

export const CardTitle = styled.span`
  font-weight: 600;
`;
