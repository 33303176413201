import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaQuestion } from 'react-icons/fa';
import { Card, Col, Dropdown, Nav, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';
import DataTable from 'react-data-table-component';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import PanelCard from 'components/PanelCard';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import PanelButton from 'components/PanelButton';
import PanelDropdownButton from 'components/PanelDropdownButton';

import useTickets from 'hooks/useTickets';
import useSupportServices from 'hooks/useSupportServices';

import { StoreState } from 'store/createStore';

import api from 'services/api';

import filter from 'utils/filter';

import {
  Container,
  Info,
  ReadMoreLink,
  StyledA,
  StyledLink,
  SupportServicesNav,
} from './styles';

interface AvailableSupportServiceItemResponse {
  idAtendimentoTipo: number;
  idDepartamento: number;
  identificador: string;
  nome: string;
  url: string;
  padrao: boolean;
}

interface AvailableSupportServicesResponse {
  data: AvailableSupportServiceItemResponse[];
}

interface NoticeResponse {
  data: {
    idAviso: number;
    cadastro: string;
    titulo: string;
    mensagem: string;
  }[];
}

interface Notice {
  idAviso: number;
  cadastro: string;
  titulo: string;
  mensagem: string;
}

type TicketStatusFilter = 'ALL' | 'OPEN' | 'CLOSED';

const List: React.FC = () => {
  const { t } = useTranslation();

  const location = useLocation<{ option?: number | undefined }>();

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { tickets } = useTickets();
  const { supportServices } = useSupportServices();

  type Ticket = typeof tickets[number];
  type SupportService = typeof supportServices[number];

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [defaultOption, setDefaultOption] = useState(1);
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>([]);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [ticketsFilterText, setTicketsFilterText] = useState('');

  const [
    selectedServiceIsContracted,
    setSelectedServiceIsContracted,
  ] = useState(true);

  const [
    ticketStatusFilter,
    setTicketStatusFilter,
  ] = useState<TicketStatusFilter>('ALL');

  const [avaiableSupportServices, setAvailableSupportServices] = useState<
    SupportService[]
  >([
    {
      idAtendimentoTipo: 1,
      idDepartamento: 1,
      identificador: 'sac',
      nome: t('pages:helpdesk.tab.sac'),
      url: '',
      padrao: true,
    },
  ]);

  const [selectedService, setSelectedService] = useState<SupportService>({
    idAtendimentoTipo: 1,
    idDepartamento: 1,
    identificador: 'sac',
    nome: t('pages:helpdesk.tab.sac'),
    url: '',
    padrao: true,
  });

  const [notice, setNotice] = useState<Notice[]>([]);

  useEffect(() => {
    const option =
      location.state && location.state.option ? location.state.option : 1;
    setDefaultOption(option);
  }, [location]);

  useEffect(() => {
    async function loadHelpdeskNoticesAndSupportServices() {
      try {
        setLoading(true);
        setError(false);

        const noticeRequest = api.get<NoticeResponse>(`painel/v1/avisos`, {
          params: {
            ...(contaComSite && { idSite }),
            helpdesk: 1,
          },
        });

        const availableSupportServicesRequest = api.get<AvailableSupportServicesResponse>(
          `helpdesk/v1/tipo-atendimento`,
          {
            params: {
              listarDisponiveis: 1,
              ...(contaComSite && { idSite }),
            },
          },
        );

        const [
          noticeResponse,
          availableSupportServicesResponse,
        ] = await Promise.all([noticeRequest, availableSupportServicesRequest]);

        setNotice(noticeResponse.data.data);
        setAvailableSupportServices(availableSupportServicesResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadHelpdeskNoticesAndSupportServices();
  }, [contaComSite, idSite]);

  useEffect(() => {
    const totalOpenTickets = tickets.filter(ticket => ticket.idSituacao !== 4)
      .length;

    setOpenTicketsCount(totalOpenTickets);
  }, [tickets]);

  useEffect(() => {
    const contracted = avaiableSupportServices.find(
      service =>
        service.idAtendimentoTipo === selectedService.idAtendimentoTipo,
    );

    setSelectedServiceIsContracted(!!contracted);
  }, [avaiableSupportServices, selectedService.idAtendimentoTipo]);

  useEffect(() => {
    let filtered = filter(tickets, ['assunto', 'idTicket'], ticketsFilterText);

    const statusFilter = selectedServiceIsContracted
      ? ticketStatusFilter
      : 'OPEN';

    filtered = filtered.filter(ticket => {
      switch (statusFilter) {
        case 'OPEN':
          return ticket.idSituacao !== 4;
        case 'CLOSED':
          return ticket.idSituacao === 4;
        default:
          return ticket;
      }
    });

    filtered = filtered.filter(ticket => {
      const idDepartamento =
        [1, 8, 12, 24, 26].indexOf(ticket.idDepartamento) !== -1
          ? 1
          : ticket.idDepartamento;
      return idDepartamento === selectedService.idDepartamento;
    });

    setFilteredTickets(filtered);
  }, [
    selectedService.idDepartamento,
    selectedServiceIsContracted,
    ticketStatusFilter,
    tickets,
    ticketsFilterText,
  ]);

  const handleChangeService = useCallback(
    (id: number) => {
      const supportService = supportServices.find(
        service => service.idAtendimentoTipo === id,
      );
      if (supportService) {
        setSelectedService(supportService);
      }
    },
    [supportServices],
  );

  useEffect(() => {
    handleChangeService(defaultOption);
  }, [handleChangeService, defaultOption]);

  const statusFilterTextName = useMemo(() => {
    switch (ticketStatusFilter) {
      case 'OPEN':
        return t('pages:helpdesk.filter.open');
      case 'CLOSED':
        return t('pages:helpdesk.filter.closed');
      default:
        return t('pages:helpdesk.filter.all');
    }
  }, [t, ticketStatusFilter]);

  const columns = [
    {
      name: t('pages:helpdesk.id'),
      selector: 'idTicket',
      sortable: true,
      minWidth: '120px',
      cell: (row: Ticket) => <>#{row.idTicket}</>,
    },
    {
      name: t('pages:helpdesk.subject'),
      selector: 'assunto',
      sortable: true,
      grow: 3,
      minWidth: '240px',
      cell: (row: Ticket) => (
        <StyledLink
          to={{
            pathname: `helpdesk/ticket/${row.idTicket}`,
            state: { ticket: row },
          }}
        >
          {row.assunto}
        </StyledLink>
      ),
    },
    {
      name: t('pages:helpdesk.opening'),
      selector: 'abertura',
      sortable: true,
      minWidth: '120px',
      cell: (row: Ticket) =>
        format(parseISO(row.abertura), 'dd/MM/yyyy', {
          locale: pt,
        }),
    },
    {
      name: t('pages:helpdesk.closing'),
      selector: 'fechamento',
      minWidth: '120px',
      sortable: true,
      cell: (row: Ticket) => {
        if (row.fechamento) {
          return format(parseISO(row.fechamento), 'dd/MM/yyyy', {
            locale: pt,
          });
        }
        return null;
      },
    },
    {
      name: t('pages:helpdesk.status'),
      selector: 'situacao',
      minWidth: '120px',
      sortable: false,
      cell: (row: Ticket) =>
        row.idSituacao === 4 ? (
          <>
            {row.situacao} <FaCheck className="ml-1 text-success" />
          </>
        ) : (
          row.situacao
        ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:helpdesk.title')}
        description={t('titles:helpdesk.description')}
        icon={<FaQuestion color="#FFFFFF" size={24} />}
      />

      {notice.map((aviso, index) => (
        <div>
          {index > 0 && (
            <div>
              <br />
            </div>
          )}
          <PanelCard>
            <Card.Header>
              <h5>{aviso.titulo}</h5>
            </Card.Header>
            <Card.Body>
              <div className="notice-content">
                <p>{aviso.mensagem}</p>
              </div>
            </Card.Body>
          </PanelCard>
        </div>
      ))}

      <Row className="my-4">
        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:helpdesk.openTickets')}</h6>
                <Info>
                  {loading ? <Spinner animation="border" /> : openTicketsCount}
                </Info>
              </div>
              <StyledLink to="/helpdesk/ticket/novo">
                {t('pages:helpdesk.openTicket')}
              </StyledLink>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:helpdesk.sac')}</h6>
                <Info>{t('pages:helpdesk.sacHelp')}</Info>
              </div>
              <StyledA
                target="_blank"
                href="https://www.hostnet.com.br/atendimento-ao-cliente"
                rel="noopener noreferrer"
              >
                {t('pages:helpdesk.sacOthers')}
              </StyledA>
            </Card.Body>
          </PanelCard>
        </Col>

        <Col xl={4} md={12} className="mb-4 mb-xl-0">
          <PanelCard>
            <Card.Body>
              <div className="mb-4">
                <h6>{t('pages:helpdesk.help')}</h6>
                <Info>{t('pages:helpdesk.hostnetInfo')}</Info>
              </div>
              <StyledA
                target="_blank"
                href="https://www.hostnet.com.br/info/"
                rel="noopener noreferrer"
              >
                {t('pages:helpdesk.helpLink')}
              </StyledA>
            </Card.Body>
          </PanelCard>
        </Col>
      </Row>

      <TableWrapper>
        <TableHeader
          title={t('pages:helpdesk.ticketsTitle')}
          description={
            <div>
              <div>{t('pages:helpdesk.ticketsSubtitle.p1')}</div>
              <div>{t('pages:helpdesk.ticketsSubtitle.p2')}</div>
              <div>{t('pages:helpdesk.ticketsSubtitle.p3')}</div>
            </div>
          }
          directHelpLink="https://ajuda.hostnet.com.br/como-abrir-chamado-atraves-do-helpdesk/"
          helpContent={
            <div>
              <p>{t('pages:helpdesk.helpContent.p1')}</p>
              <p>{t('pages:helpdesk.helpContent.p2')}</p>
              <ul>
                <li>{t('pages:helpdesk.helpContent.li1')}</li>
                <li>{t('pages:helpdesk.helpContent.li2')}</li>
                <li>{t('pages:helpdesk.helpContent.li3')}</li>
                <li>{t('pages:helpdesk.helpContent.li4')}</li>
              </ul>
            </div>
          }
        />

        <SupportServicesNav
          variant="pills"
          activeKey={selectedService.idAtendimentoTipo}
          onSelect={(id: string) => handleChangeService(Number(id))}
          className="py-2 flex-column flex-sm-row"
        >
          {supportServices.map(service => (
            <Nav.Item key={service.idAtendimentoTipo}>
              <Nav.Link eventKey={service.idAtendimentoTipo}>
                {service.nome}
              </Nav.Link>
            </Nav.Item>
          ))}
        </SupportServicesNav>

        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/helpdesk/ticket/novo">
              {t('pages:helpdesk.newTicket')}
            </TableButton>
          </div>

          <div className="d-flex">
            <PanelDropdownButton
              size="sm"
              title={statusFilterTextName}
              className="mr-2 mb-1"
            >
              <Dropdown.Item onSelect={() => setTicketStatusFilter('ALL')}>
                {t('pages:helpdesk.filter.all')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setTicketStatusFilter('OPEN')}>
                {t('pages:helpdesk.filter.open')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setTicketStatusFilter('CLOSED')}>
                {t('pages:helpdesk.filter.closed')}
              </Dropdown.Item>
            </PanelDropdownButton>
            <FilterInput onChange={value => setTicketsFilterText(value)} />
          </div>
        </TableSubHeader>

        {(selectedServiceIsContracted || filteredTickets.length > 0) && (
          <div className="datatables-table">
            <DataTable
              noHeader
              responsive
              pagination
              columns={columns}
              data={filteredTickets}
              progressPending={loading}
              progressComponent={<Loading />}
              noDataComponent={
                <TableNoData text={t('pages:helpdesk.noData')} />
              }
              paginationComponentOptions={{
                rowsPerPageText: t('pages:helpdesk.ticketsPerPage'),
                rangeSeparatorText: t('common:of'),
              }}
            />
          </div>
        )}

        {!selectedServiceIsContracted && filteredTickets.length === 0 && (
          <div className="text-muted text-center mb-4">
            <TableNoData text={t('pages:helpdesk.productNotFound')} />
            <PanelButton href={selectedService.url}>
              {t('pages:helpdesk.contractionLink')}
            </PanelButton>
            {selectedService.identificador === 'suporte.premium' && (
              <div className="mt-4 h6">
                <ReadMoreLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://ajuda.hostnet.com.br/suporte-premium/"
                >
                  Saiba mais sobre o Suporte Premium
                </ReadMoreLink>
              </div>
            )}

            {selectedService.identificador === 'suporte.mautic' && (
              <div className="mt-4 h6">
                <ReadMoreLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://ajuda.hostnet.com.br/suporte-premium-mautic/"
                >
                  Saiba mais sobre o Suporte Premium Mautic
                </ReadMoreLink>
              </div>
            )}

            {selectedService.identificador === 'manutencao.site' && (
              <div className="mt-4 h6">
                <ReadMoreLink
                  target="_blank"
                  rel="noreferrer"
                  href="https://ajuda.hostnet.com.br/manutencao-de-site/"
                >
                  Saiba mais sobre Manutenção de Site
                </ReadMoreLink>
              </div>
            )}
          </div>
        )}
      </TableWrapper>
    </Container>
  );
};

export default List;
