import React from 'react';
import { useSelector } from 'react-redux';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';
import * as Cookies from 'js-cookie';

import AuthLayout from 'pages/_layouts/auth';
import DefaultLayout from 'pages/_layouts/default';
import SinglePageLayout from 'pages/_layouts/single_page';

import Guard from './Guard';

import { StoreState } from '../store/createStore';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isPublic?: boolean;
  singlePage?: boolean;
  component: React.ComponentType;
  guards?: ((storeState: StoreState) => boolean)[];
  requiredOptions?: string[];
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isPublic = false,
  singlePage = false,
  component: Component,
  guards = [],
  requiredOptions = [],
  ...rest
}) => {
  const locationPath = window.location.pathname;
  const encodedLocationPath = encodeURIComponent(locationPath);

  const search = locationPath === '/' ? '' : `?target=${encodedLocationPath}`;

  const { authenticated } = useSelector((state: StoreState) => state.auth);

  const authCookie = Cookies.get('auth') === 'true';

  const userAuthenticated = authenticated && authCookie;

  if (isPrivate !== userAuthenticated && !isPublic) {
    return (
      <Redirect
        to={{
          pathname: isPrivate ? `/` : '/home',
          search,
        }}
      />
    );
  }

  let Layout = userAuthenticated ? DefaultLayout : AuthLayout;

  if (singlePage) {
    Layout = SinglePageLayout;
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        return (
          <Layout>
            <Guard guards={guards} requiredOptions={requiredOptions}>
              <Component />
            </Guard>
          </Layout>
        );
      }}
    />
  );
};

export default Route;
