import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { CogsIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface AppsOptionsProps {
  site: string;
  options: Option[];
  usuario: string;
}

const AppsOptions: React.FC<AppsOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="7"
      text={t('components:sideMenu.apps')}
      icon={<CogsIcon size={15} />}
      elements={[
        options.some(option => option.codigo === 'academia.hostnet') && (
          <SideMenuAccordionLink
            key="academia.hostnet"
            text={t('components:sideMenu.courses')}
            to="/aplicativos/cursos"
          />
        ),
        options.some(
          option => option.codigo === 'webfacil.migrado.wordpress',
        ) && (
          <SideMenuAccordionLink
            key="webfacil.migrado.wordpress"
            text={t('components:sideMenu.webfacil')}
            to="/aplicativos/webfacil/migrado"
          />
        ),
        <SideMenuAccordionLink
          key="wordpress"
          text={t('components:sideMenu.wordpress')}
          to="/aplicativos/wordpress"
        />,
        options.some(option => option.codigo === 'servidor.cloud') && [
          <SideMenuAccordionLink
            key="mautic"
            text={t('components:sideMenu.mautic')}
            to="/aplicativos/mautic"
          />,
          <SideMenuAccordionLink
            key="mauticSms"
            text={t('components:sideMenu.mauticSms')}
            to="/aplicativos/mautic-sms"
          />,
          <SideMenuAccordionLink
            key="appsInstalados"
            text={t('components:sideMenu.appsInstalados')}
            to="/aplicativos/apps-instalados"
          />,
        ],
      ]}
    />
  );
};

export default AppsOptions;
