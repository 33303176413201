import i18n from 'i18n';
import history from './history';
import swal from './swal';

export default function checkEmailServicesAlert() {
  swal
    .fire({
      icon: 'info',
      title: 'Ative o Ultramail',
      html: i18n.t('common:activateUltramailAction'),
      customClass: {
        confirmButton: 'btn btn-primary btn-sm mr-3',
        denyButton: 'btn btn-secondary btn-sm mr-3',
      },
      showDenyButton: true,
      confirmButtonText: i18n.t('common:manageEmailServices'),
      denyButtonText: i18n.t('common:cancel'),
    })
    .then(result => {
      if (result.isConfirmed) {
        history.push('/servico-email');
      }
    });
}
