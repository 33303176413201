import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaWindowRestore } from 'react-icons/fa';
import { MdDns } from 'react-icons/md';
import { parseISO, format, isValid } from 'date-fns';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import filter from 'utils/filter';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableIconButton from 'components/TableIconButton';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';

import RedirectActions from './components/RedirectActions';
import SubDomainsActions from './components/SubDomainsActions';
import DnsModal from './components/DnsModal';

import { Container } from './styles';

interface RedirectItemResponse {
  idCliente: number;
  idRedirecionamento: number;
  redirecionamento: string;
  dominio: string;
  cadastro: string;
  podeRemover: boolean;
}

interface RedirectsResponse {
  data: RedirectItemResponse[];
}

interface SubDomainItemResponse {
  idSubDominio: number;
  dominio: string;
  descricao: string;
  redirecionamento: string;
  subDominio: string;
  url: string;
  cadastro: string;
  podeRemover: boolean;
}

interface SubDomainsResponse {
  data: SubDomainItemResponse[];
}

interface DeleteRedirectsResponse {
  data: RedirectItemResponse[];
}

interface DeleteSubDomainsResponse {
  data: SubDomainItemResponse[];
}

interface Redirect {
  idCliente: number;
  idRedirecionamento: number;
  redirecionamento: string;
  dominio: string;
  cadastro: string;
  podeRemover: boolean;
}

interface SubDomain {
  idSubDominio: number;
  dominio: string;
  descricao: string;
  redirecionamento: string;
  subDominio: string;
  url: string;
  cadastro: string;
  podeRemover: boolean;
}

interface SelectedRedirectsRowsInfo {
  selectedCount: number;
  selectedRows: Redirect[];
}

interface SelectedSubDomainsRowsInfo {
  selectedCount: number;
  selectedRows: SubDomain[];
}

interface DnsResponse {
  data: string[];
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [dns, setDns] = useState<string[]>([]);
  const [showDns, setShowDns] = useState(false);
  const [loadingDns, setLoadingDns] = useState(false);

  const [redirects, setRedirects] = useState<Redirect[]>([]);
  const [filteredRedirects, setFilteredRedirects] = useState<Redirect[]>([]);
  const [redirectsFilterText, setRedirectsFilterText] = useState('');
  const [redirectsToggleCleared, setRedirectsToggleCleared] = useState(false);
  const [
    selectedRedirectsRowsInfo,
    setSelectedRedirectsRowInfo,
  ] = useState<SelectedRedirectsRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const [subDomains, setSubDomains] = useState<SubDomain[]>([]);
  const [filteredSubDomains, setFilteredSubDomains] = useState<SubDomain[]>([]);
  const [subDomainsFilterText, setSubDomainsFilterText] = useState('');
  const [subDomainsToggleCleared, setSubDomainsToggleCleared] = useState(false);
  const [
    selectedSubDomainsRowsInfo,
    setSelectedSubDomainsRowsInfo,
  ] = useState<SelectedSubDomainsRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const formatSubDomainItemResponse = useCallback(
    ({
      idSubDominio,
      dominio,
      subDominio,
      descricao,
      redirecionamento,
      url,
      cadastro,
      podeRemover,
    }: SubDomainItemResponse) => ({
      idSubDominio,
      dominio,
      subDominio,
      descricao,
      redirecionamento,
      url,
      cadastro,
      podeRemover,
    }),
    [],
  );

  const formatRedirectItemResponse = useCallback(
    ({
      idCliente,
      idRedirecionamento,
      dominio,
      cadastro,
      redirecionamento,
      podeRemover,
    }: RedirectItemResponse) => ({
      idCliente,
      idRedirecionamento,
      dominio,
      cadastro,
      redirecionamento,
      podeRemover,
    }),
    [],
  );

  useEffect(() => {
    async function loadRedirects() {
      try {
        setLoading(true);
        setError(false);

        const redirectsResponse = await api.get<RedirectsResponse>(
          'painel/v1/redirecionamento',
        );

        setRedirects(
          redirectsResponse.data.data.map(formatRedirectItemResponse),
        );

        const subDomainsResponse = await api.get<SubDomainsResponse>(
          'painel/v1/subdominio',
        );

        setSubDomains(
          subDomainsResponse.data.data.map(formatSubDomainItemResponse),
        );
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadRedirects();
  }, [formatRedirectItemResponse, formatSubDomainItemResponse]);

  useEffect(() => {
    const filtered = filter(
      redirects,
      ['dominio', 'redirecionamento'],
      redirectsFilterText.toLowerCase(),
    );

    setFilteredRedirects(filtered);
  }, [redirects, redirectsFilterText]);

  useEffect(() => {
    const filtered = filter(
      subDomains,
      ['subDominio', 'url'],
      subDomainsFilterText.toLowerCase(),
    );

    setFilteredSubDomains(filtered);
  }, [subDomains, subDomainsFilterText]);

  const deleteRedirects = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:label.warning'),
        text: t('pages:siteRedirects.redirectWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      const idsRedirects = selectedRedirectsRowsInfo.selectedRows.map(
        ({ idRedirecionamento }) => idRedirecionamento,
      );

      const deleteRedirectsResponse = await api.delete<DeleteRedirectsResponse>(
        `painel/v1/redirecionamento`,
        {
          params: { idsRedirecionamentos: idsRedirects },
        },
      );

      setRedirects(
        deleteRedirectsResponse.data.data.map(formatRedirectItemResponse),
      );
      setRedirectsToggleCleared(!redirectsToggleCleared);

      toast.fire(t('pages:siteRedirects.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:label.operationFailed'),
        text: `${t('pages:siteRedirects.deleteFailed')}: ${
          err.response.data.error_description
        }`,
      });
    } finally {
      setDeleting(false);
    }
  }, [
    formatRedirectItemResponse,
    redirectsToggleCleared,
    selectedRedirectsRowsInfo.selectedRows,
    t,
  ]);

  const deleteSubDomains = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        text: t('pages:siteRedirects.subdomainWarning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setDeleting(true);

      const idsSubDomains = selectedSubDomainsRowsInfo.selectedRows.map(
        ({ idSubDominio }) => idSubDominio,
      );

      const subDomainsResponse = await api.delete<DeleteSubDomainsResponse>(
        `painel/v1/subdominio`,
        {
          params: { idsSubdominios: idsSubDomains },
        },
      );

      setSubDomains(
        subDomainsResponse.data.data.map(formatSubDomainItemResponse),
      );
      setSubDomainsToggleCleared(!subDomainsToggleCleared);

      toast.fire(t('pages:siteRedirects.subDomainDeleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('common:operationFailed'),
        text: `${t('pages:siteRedirects.subDomainDeleteFailed')} ${
          err.response.data.error_description
        }`,
      });
    } finally {
      setDeleting(false);
    }
  }, [
    formatSubDomainItemResponse,
    selectedSubDomainsRowsInfo.selectedRows,
    subDomainsToggleCleared,
    t,
  ]);

  const loadDns = useCallback(
    async (idRedirect: number) => {
      try {
        setDns([]);
        setShowDns(true);
        setLoadingDns(true);

        const dnsResponse = await api.get<DnsResponse>(
          `painel/v1/redirecionamento/${idRedirect}/dns`,
        );

        setDns(dnsResponse.data.data);
      } catch (err) {
        swal.fire({
          title: t('common:operationFailed'),
          text: `${err.response.data.error_description}`,
        });
      } finally {
        setLoadingDns(false);
      }
    },
    [t],
  );

  const redirectsColumns = [
    {
      name: 'DNS',
      cell: (row: Redirect) => (
        <div>
          <TableIconButton
            tooltipConfig={{
              id: 'displayDns',
              placement: 'right',
              content: t('pages:siteRedirects.displayInfo'),
            }}
            onClick={() => loadDns(row.idRedirecionamento)}
          >
            <MdDns />
          </TableIconButton>
        </div>
      ),
      width: '80px',
    },
    {
      name: t('common:domain'),
      selector: 'dominio',
      sortable: true,
    },
    {
      name: t('pages:siteRedirects.redirectTo'),
      selector: 'redirecionamento',
      sortable: true,
    },
    {
      name: t('common:registration'),
      selector: 'cadastro',
      cell: (row: Redirect) => {
        const registrationDate = new Date(row.cadastro);

        if (!isValid(registrationDate)) {
          return '';
        }

        return format(parseISO(row.cadastro), 'dd/MM/yyyy');
      },
      sortable: true,
    },
  ];

  const subDomainsColumns = [
    {
      name: t('pages:siteRedirects.subDomain'),
      selector: 'subDominio',
      cell: (row: SubDomain) => (
        <>
          <span className="text-primary">{row.subDominio}</span>.{row.dominio}
        </>
      ),
      sortable: true,
    },
    {
      name: t('pages:siteRedirects.redirectTo'),
      selector: 'redirecionamento',
    },
    {
      name: t('common:registration'),
      selector: 'cadastro',
      cell: (row: SubDomain) => {
        const registrationDate = new Date(row.cadastro);

        if (!isValid(registrationDate)) {
          return '';
        }

        return format(parseISO(row.cadastro), 'dd/MM/yyyy');
      },
      sortable: true,
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:siteRedirects.redirectsTitle')}
          description={t('pages:siteRedirects.redirectsDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/redirecionamento-de-dominios-e-subdominios/"
        />
        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/site/redirecionamentos/novo">
              {t('pages:siteRedirects.newRedirect')}
            </TableButton>
          </div>

          <div>
            <FilterInput onChange={value => setRedirectsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRedirectsRowsInfo.selectedCount === 0}
            pagination
            defaultSortField="dominio"
            selectableRows
            selectableRowDisabled={row => !row.podeRemover}
            progressPending={loading}
            columns={redirectsColumns}
            data={filteredRedirects}
            clearSelectedRows={redirectsToggleCleared}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedRedirectsRowInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:label.registersPerPage'),
              rangeSeparatorText: t('common:label.of'),
            }}
            contextComponent={
              <RedirectActions
                loading={loading || deleting}
                selectedRows={selectedRedirectsRowsInfo.selectedRows}
                selectedCount={selectedRedirectsRowsInfo.selectedCount}
                deleteRedirects={() => deleteRedirects()}
              />
            }
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:siteRedirects.subDomainsTitle')}
          description={t('pages:siteRedirects.subDomainsDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/redirecionamento-de-dominios-e-subdominios/"
        />

        <TableSubHeader>
          <div>
            <TableButton forwardedAs={Link} to="/subdominio/novo">
              {t('pages:siteRedirects.newSubDomain')}
            </TableButton>
          </div>

          <div>
            <FilterInput onChange={value => setSubDomainsFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedSubDomainsRowsInfo.selectedCount === 0}
            pagination
            selectableRows
            progressPending={loading}
            clearSelectedRows={subDomainsToggleCleared}
            defaultSortField="subDominio"
            columns={subDomainsColumns}
            data={filteredSubDomains}
            progressComponent={<Loading />}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            onSelectedRowsChange={rows => setSelectedSubDomainsRowsInfo(rows)}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            contextComponent={
              <SubDomainsActions
                loading={loading || deleting}
                deleteSubDomain={deleteSubDomains}
                selectedRows={selectedSubDomainsRowsInfo.selectedRows}
                selectedCount={selectedSubDomainsRowsInfo.selectedCount}
              />
            }
          />
        </div>
      </TableWrapper>

      <DnsModal
        dns={dns}
        loading={loadingDns}
        open={showDns}
        hide={() => setShowDns(false)}
      />
    </Container>
  );
};

export default List;
