import React, { useState } from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FaQuestionCircle } from 'react-icons/fa';
import i18n from 'i18n';

import {
  Container,
  Title,
  Description,
  TableHeaderButton,
  Help,
  HelpContent,
  HelpLinks,
  LinkList,
  HelpLink,
} from './styles';

interface HelpLink {
  text: string;
  url: string;
}

interface FormHeaderProps {
  title: string;
  description: string;
  startOpen?: boolean;
  titleTooltip?: string;
  helpContent?: React.ReactNode;
  helpLinks?: HelpLink[];
  directHelpLink?: string;
}

const FormHeader: React.FC<FormHeaderProps> = ({
  title,
  titleTooltip,
  description,
  helpContent,
  startOpen = false,
  helpLinks = [
    {
      text: i18n.t('common:learnMoreInHelpCenter'),
      url: 'https://www.hostnet.com.br/info/',
    },
  ],
  directHelpLink,
}) => {
  const { t } = useTranslation();

  const [help, setHelp] = useState(startOpen);

  return (
    <Container>
      <Card.Body>
        <Row>
          <Col xs={9}>
            <Title>
              {title}{' '}
              {titleTooltip && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-apache" className="lg-tooltip">
                      {titleTooltip}
                    </Tooltip>
                  }
                >
                  <FaQuestionCircle color="#2c4593" />
                </OverlayTrigger>
              )}
            </Title>
            <Description>{description}</Description>
          </Col>
          <Col xs={3}>
            <Row className="justify-content-end mr-1">
              {directHelpLink && (
                <HelpLink
                  target="_blank"
                  rel="noreferrer"
                  href={directHelpLink}
                >
                  <FaQuestionCircle
                    color="#2c4593"
                    size={23}
                    className="mb-1"
                  />
                  <div>{t('common:label.help')}</div>
                </HelpLink>
              )}
              {helpContent && !directHelpLink && (
                <TableHeaderButton
                  onClick={() => setHelp(!help)}
                  className={help ? 'active' : ''}
                >
                  <FaQuestionCircle
                    color="#2c4593"
                    size={23}
                    className="mb-1"
                  />
                  <div>{t('common:label.help')}</div>
                </TableHeaderButton>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Body>

      <Help className={help && 'active'}>
        <Row>
          <Col xs={12} md={7}>
            <HelpContent>
              <Trans>{helpContent}</Trans>
            </HelpContent>
          </Col>
          <Col xs={12} md={5} className="border-left">
            <HelpLinks>
              <LinkList>
                {helpLinks.map(link => (
                  <li key={link.url} className="mb-2">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link.url}
                    >
                      {link.text}
                    </a>
                  </li>
                ))}
              </LinkList>
            </HelpLinks>
          </Col>
        </Row>
      </Help>
    </Container>
  );
};

export default FormHeader;
