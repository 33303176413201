import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaCloud } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';
import Error from 'components/Error';

import { StoreState } from 'store/createStore';

import swal from 'services/swal';
import toast from 'services/toast';
import history from 'services/history';
import api from 'services/api';

import useFtpInfo from 'hooks/useFtpInfo';
import useCronJobs from 'hooks/useCronJobs';

import { Container } from './styles';

interface NewCronjobFormValues {
  minutes: string;
  hours: string;
  days: string;
  months: string;
  daysOfTheWeek: string;
  command: string;
  email: string;
  description: string;
}

interface NewCronjobResponse {
  data: {
    confirmado: boolean;
    info: string;
  };
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { ftpInfo, loading, error } = useFtpInfo(idSite);
  const { refetch } = useCronJobs(idSite);

  const submitForm = useCallback(
    async (values: NewCronjobFormValues) => {
      try {
        const data = {
          idSite,
          meses: values.months,
          diasDaSemana: values.daysOfTheWeek,
          dias: values.days,
          horas: values.hours,
          minutos: values.minutes,
          comando: values.command.trim(),
          email: values.email,
          descricao: values.description,
        };

        const response = await api.post<NewCronjobResponse>(
          `painel/v1/site/${idSite}/cron-job`,
          data,
        );

        await refetch();

        toast.fire(response.data.data.info);

        history.push(`/cloud/crontab`);
      } catch (err) {
        swal.fire({
          title: t('pages:crontabScheduling.addCommandFailure'),
          html: err.response.data.error_description,
        });
      }
    },
    [idSite, refetch, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:crontabScheduling.formTitle')}
          description={t('pages:crontabScheduling.formSubtitle')}
          helpContent={
            <div>
              <p>{t('pages:crontabScheduling.helpContent.p1')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p2')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p3')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p4')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p5')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p6')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p7')}</p>
              <p>{t('pages:crontabScheduling.helpContent.p8')}</p>
            </div>
          }
        />

        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            minutes: '0',
            hours: '18',
            days: '*',
            months: '*',
            daysOfTheWeek: '*',
            command: `php7.2 /home/${ftpInfo.user}/exemplo.php`,
            email: '',
            description: '',
          }}
          validationSchema={Yup.object().shape({
            minutes: Yup.string()
              .required(t('pages:crontabScheduling.validation.minutes'))
              .test(
                'minutes',
                t('pages:crontabScheduling.validation.validate'),
                value =>
                  value !== undefined &&
                  value.trim() !== '' &&
                  value.search(
                    /^(?:\*|(0|[1-9]|[1-5][0-9])(,(0|[1-9]|[1-5][0-9]))*|(0|[1-9]|[1-5][0-9])-(0|[1-9]|[1-5][0-9]))$/,
                  ) !== -1,
              ),
            hours: Yup.string()
              .required(t('pages:crontabScheduling.validation.hours'))
              .test(
                'hours',
                t('pages:crontabScheduling.validation.validate'),
                value =>
                  value !== undefined &&
                  value.trim() !== '' &&
                  value.search(
                    /^(?:\*|(0|[1-9]|1[0-9]|2[0-3])(,(0|[1-9]|1[0-9]|2[0-3]))*|(0|[1-9]|1[0-9]|2[0-3])-(0|[1-9]|1[0-9]|2[0-3]))$/,
                  ) !== -1,
              ),
            days: Yup.string()
              .required(t('pages:crontabScheduling.validation.days'))
              .test(
                'days',
                t('pages:crontabScheduling.validation.validate'),
                value =>
                  value !== undefined &&
                  value.trim() !== '' &&
                  value.search(
                    /^(?:\*|([1-9]|1[0-9]|2[0-9]|3[0-1])(,([1-9]|1[0-9]|2[0-9]|3[0-1]))*|([1-9]|1[0-9]|2[0-9]|3[0-1])-([1-9]|1[0-9]|2[0-9]|3[0-1]))$/,
                  ) !== -1,
              ),
            months: Yup.string()
              .required(t('pages:crontabScheduling.validation.months'))
              .test(
                'months',
                t('pages:crontabScheduling.validation.validate'),
                value =>
                  value !== undefined &&
                  value.trim() !== '' &&
                  value.search(
                    /^(?:\*|([1-9]|1[0-2])(,([1-9]|1[0-2]))*|([1-9]|1[0-2])-([1-9]|1[0-2]))$/,
                  ) !== -1,
              ),
            daysOfTheWeek: Yup.string()
              .required(t('pages:crontabScheduling.validation.daysWeek'))
              .test(
                'daysOfTheWeek',
                t('pages:crontabScheduling.validation.validate'),
                value =>
                  value !== undefined &&
                  value.trim() !== '' &&
                  value.search(/^(?:\*|[0-6](,[0-6])*|[0-6]-[0-6])$/) !== -1,
              ),
            command: Yup.string().required(
              t('pages:crontabScheduling.validation.command'),
            ),
            email: Yup.string()
              .email(t('pages:crontabScheduling.validation.isemail'))
              .required(t('pages:crontabScheduling.validation.email')),
          })}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:crontabScheduling.dataTitle')}
                />

                <Form.Group as={Row} controlId="cronJob.minutes">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.minutes')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="minutes"
                      value={props.values.minutes}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.minutes && !!props.errors.minutes
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.minutes')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.minutes}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.hours">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.hours')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="hours"
                      min="0"
                      max="23"
                      value={props.values.hours}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.hours && !!props.errors.hours}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.hours')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.hours}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.days">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.days')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="days"
                      min="1"
                      max="31"
                      value={props.values.days}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.days && !!props.errors.days}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.days')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.days}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.months">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.months')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="months"
                      min="1"
                      max="12"
                      value={props.values.months}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.months && !!props.errors.months
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.months')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.months}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.daysOfTheWeek">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.daysWeek')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="daysOfTheWeek"
                      value={props.values.daysOfTheWeek}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.daysOfTheWeek &&
                        !!props.errors.daysOfTheWeek
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.daysWeek')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.daysOfTheWeek}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.command">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.command')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="command"
                      value={props.values.command}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.command && !!props.errors.command
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:crontabScheduling.descriptions.command')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.command}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.email">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="cronJob.description">
                  <Form.Label column sm={2}>
                    {t('pages:crontabScheduling.explanation')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.description &&
                        !!props.errors.description
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.description}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/cloud/crontab"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
