import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Card } from 'react-bootstrap';

import api from 'services/api';
import swal from 'services/swal';

import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import Loading from 'components/Loading';

import { Container, BarCodeContent } from './styles';

interface MatchParams {
  chave: string;
}

type BarCodeProps = RouteComponentProps<MatchParams>;

interface LinhaDigitavelResponse {
  data: {
    linhaDigitavel: string;
  };
}

const BarCode: React.FC<BarCodeProps> = ({ match }) => {
  const { t } = useTranslation();

  const { chave } = match.params;

  const [loading, setLoading] = useState(false);
  const [linhaDigitavel, setLinhaDigitavel] = useState<string>('');

  useEffect(() => {
    async function getLinhaDigitavel() {
      try {
        setLoading(true);
        const linhaDigitavelResponse = await api.get<LinhaDigitavelResponse>(
          `financeiro/v1/segunda-via`,
          {
            params: { chave },
          },
        );
        setLinhaDigitavel(linhaDigitavelResponse.data.data.linhaDigitavel);
      } catch (err) {
        swal.fire({
          title: t('pages:reissuePayment.fail'),
          html: err.response.data.error_description,
        });
      } finally {
        setLoading(false);
      }
    }
    if (chave) {
      getLinhaDigitavel();
    }
  }, [chave, t]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:reissuePayment.title')}
        description={t('pages:reissuePayment.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:reissuePaymentBarCode.title')}
          description={t('pages:reissuePaymentBarCode.description')}
        />
        <Card.Body>
          <BarCodeContent>
            {linhaDigitavel ? (
              <img
                width="512"
                src={`${linhaDigitavel}`}
                alt={t('pages:reissuePayment.title')}
              />
            ) : (
              <p className="text-muted">
                {t('pages:reissuePaymentBarCode.invalidKey')}
              </p>
            )}
          </BarCodeContent>
        </Card.Body>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(BarCode);
