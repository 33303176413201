import styled from 'styled-components';

export const Container = styled.div`
  .negativo {
    color: red;
  }

  .form-check-label {
    font-size: 13px;
  }

  .form-check-input {
    margin-top: 0.45rem;
  }
`;
