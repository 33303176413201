import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';

export const TotalInfo = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 36px;
  }

  svg {
    color: #666;
    font-size: 26px;
  }
`;

export const UsageInfo = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;

  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ResourceBar = styled(ProgressBar)`
  height: 6px;
`;

export const ResourceName = styled.div`
  font-size: 14px;
`;

export const Used = styled.div`
  font-size: 14px;
`;

export const Container = styled.div`
  &.blue {
    h2 {
      color: var(--color-blue);
    }

    .progress-bar {
      background-color: var(--color-blue);
    }

    ${Used} {
      color: var(--color-blue);
    }
  }

  &.violet {
    h2 {
      color: var(--color-violet);
    }

    .progress-bar {
      background-color: var(--color-violet);
    }

    ${Used} {
      color: var(--color-violet);
    }
  }

  &.yellow {
    h2 {
      color: var(--color-yellow);
    }

    .progress-bar {
      background-color: var(--color-yellow);
    }

    ${Used} {
      color: var(--color-yellow);
    }
  }

  &.green {
    h2 {
      color: var(--color-green);
    }

    .progress-bar {
      background-color: var(--color-green);
    }

    ${Used} {
      color: var(--color-green);
    }
  }
`;
