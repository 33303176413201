import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loading from 'components/Loading';

import history from 'services/history';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

const RedirectPanel3: React.FC = () => {
  const { search } = useLocation();

  const { site } = useSelector((store: StoreState) => store.site.info);
  const { accessToken } = useSelector((store: StoreState) => store.auth);

  const panel3Route = new URLSearchParams(search).get('route') ?? 'principal';

  useEffect(() => {
    if (panel3Route === null || panel3Route === '') {
      history.push('/home');
    }

    const panel3Url = 'https://painel.hostnet.com.br/';

    window.location.href = `${panel3Url}acesso.painel?route=${panel3Route}&token=${accessToken}&site=${site}`;
  }, [accessToken, panel3Route, site]);

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default RedirectPanel3;
