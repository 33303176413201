import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Container = styled.div``;

export const TableWrapperCard = styled(Card)`
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1),
    0 11px 6px -7px rgba(43, 43, 43, 0.1);
  border: none;
`;
