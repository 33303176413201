import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { FaCloud, FaQuestionCircle } from 'react-icons/fa';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import FormWrapper from 'components/FormWrapper';
import Loading from 'components/Loading';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';

import { StoreState } from 'store/createStore';

import swal from 'services/swal';
import toast from 'services/toast';
import history from 'services/history';
import { Container } from './styles';

interface LoadContainerResponse {
  data: {
    idContainer: string;
    container: string;
  };
}

interface Cointainer {
  idContainer: string;
  container: string;
}

type OnOffOption = 'ATIVO' | 'INATIVO';

interface SiteSettingsFormValues {
  serverName: string;
  baseDocumentRoot: string;
  documentRoot: string;
  cache: OnOffOption;
  modRewrite: OnOffOption;
  requestTerminateTimeout: string;
  maxChildren: string;
  uploadMaxFilesize: string;
  errorLog: OnOffOption;

  eAll: OnOffOption;
  eError: OnOffOption;
  eWarning: OnOffOption;
  eParse: OnOffOption;
  eNotice: OnOffOption;
  eStrict: OnOffOption;
  eDeprecated: OnOffOption;
  eCoreError: OnOffOption;
  eCoreWarning: OnOffOption;
  eCompileError: OnOffOption;
  eCompileWarning: OnOffOption;
  eUserError: OnOffOption;
  eUserNotice: OnOffOption;
  eUserWarning: OnOffOption;
  eRecoverableError: OnOffOption;
  eUserDeprecated: OnOffOption;

  displayErrors: OnOffOption;

  execCGI: OnOffOption;
  followSymLinks: OnOffOption;
  symLinksIfOwnerMatch: OnOffOption;
  includes: OnOffOption;
  includesNOEXEC: OnOffOption;
  multiViews: OnOffOption;

  authConfig: OnOffOption;
  fileInfo: OnOffOption;
  indexes: OnOffOption;
  limit: OnOffOption;
  options: OnOffOption;
  outputBuffering: OnOffOption;
  log: OnOffOption;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState(false);

  const [container, setContainer] = useState<Cointainer>({
    idContainer: '',
    container: '',
  });

  useEffect(() => {
    async function loadContainer() {
      try {
        setLoading(true);
        setError(false);

        const containerResponse = await api.get<LoadContainerResponse>(
          `painel/v1/site/${idSite}/container`,
        );

        const {
          idContainer,
          container: containerName,
        } = containerResponse.data.data;

        setContainer({
          idContainer,
          container: containerName,
        });
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadContainer();
  }, [idSite]);

  async function createSite(values: SiteSettingsFormValues) {
    try {
      const options: string[] = [];
      const allowOverride: string[] = [];

      const params = {
        serverName: values.serverName,
        documentRoot: values.baseDocumentRoot + values.documentRoot,
        modRewrite: values.modRewrite === 'ATIVO',
        requestTerminateTimeout: values.requestTerminateTimeout,
        maxChildren: values.maxChildren,
        uploadMaxFilesize: values.uploadMaxFilesize,
        phpLog: values.errorLog === 'ATIVO',
        errorReporting: [
          values.eAll === 'ATIVO' ? 'E_ALL' : '~E_ALL',
          values.eError === 'ATIVO' ? 'E_ERROR' : '~E_ERROR',
          values.eWarning === 'ATIVO' ? 'E_WARNING' : '~E_WARNING',
          values.eParse === 'ATIVO' ? 'E_PARSE' : '~E_PARSE',
          values.eNotice === 'ATIVO' ? 'E_NOTICE' : '~E_NOTICE',
          values.eStrict === 'ATIVO' ? 'E_STRICT' : '~E_STRICT',
          values.eDeprecated === 'ATIVO' ? 'E_DEPRECATED' : '~E_DEPRECATED',
          values.eCoreError === 'ATIVO' ? 'E_CORE_ERROR' : '~E_CORE_ERROR',
          values.eCoreWarning === 'ATIVO'
            ? 'E_CORE_WARNING'
            : '~E_CORE_WARNING',
          values.eCompileError === 'ATIVO'
            ? 'E_COMPILE_ERROR'
            : '~E_COMPILE_ERROR',
          values.eCompileWarning === 'ATIVO'
            ? 'E_COMPILE_WARNING'
            : '~E_COMPILE_WARNING',
          values.eUserError === 'ATIVO' ? 'E_USER_ERROR' : '~E_USER_ERROR',
          values.eUserNotice === 'ATIVO' ? 'E_USER_NOTICE' : '~E_USER_NOTICE',
          values.eUserWarning === 'ATIVO'
            ? 'E_USER_WARNING'
            : '~E_USER_WARNING',
          values.eRecoverableError === 'ATIVO'
            ? 'E_RECOVERABLE_ERROR'
            : '~E_RECOVERABLE_ERROR',
          values.eUserDeprecated === 'ATIVO'
            ? 'E_USER_DEPRECATED'
            : '~E_USER_DEPRECATED',
        ],
        displayErrors: values.displayErrors === 'ATIVO' ? 'On' : 'Off',
        options,
        allowOverride,
        outputBuffering: values.outputBuffering === 'ATIVO' ? 4 : 0,
        haveLog: values.log === 'ATIVO',
        cache: values.cache === 'ATIVO',
      };

      if (values.execCGI === 'ATIVO') {
        params.options.push('ExecCGI');
      }

      if (values.followSymLinks === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.options.push('FollowSymLinks');
      }

      if (values.symLinksIfOwnerMatch === 'ATIVO') {
        params.options.push('SymLinksIfOwnerMatch');
      }

      if (values.includes === 'ATIVO') {
        params.options.push('Includes');
      }

      if (values.includesNOEXEC === 'ATIVO') {
        params.options.push('IncludesNOEXEC');
      }

      if (values.multiViews === 'ATIVO') {
        params.options.push('MultiViews');
      }

      if (values.authConfig === 'ATIVO') {
        params.allowOverride.push('AuthConfig');
      }

      if (values.fileInfo === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.allowOverride.push('FileInfo');
      }

      if (values.indexes === 'ATIVO') {
        params.allowOverride.push('Indexes');
      }

      if (values.limit === 'ATIVO') {
        params.allowOverride.push('Limit');
      }

      if (values.options === 'ATIVO' || values.modRewrite === 'ATIVO') {
        params.allowOverride.push('Options');
      }

      await api.post(`painel/v1/site/${idSite}/container/vhost`, params);

      toast.fire(t('pages:siteSettingsNew.addSiteSuccess'));

      history.push(`/cloud/configuracoes-sites`);
    } catch (err) {
      swal.fire({
        title: t('pages:siteSettingsNew.addSiteError'),
        html: err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:siteSettingsNew.formTitle')}
          description={t('pages:siteSettingsNew.formSubtitle')}
          directHelpLink="https://ajuda.hostnet.com.br/criando-site-extra-no-plano-cloud/"
        />
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            serverName: '',
            baseDocumentRoot: `/home/${container.container}/`,
            documentRoot: '',
            cache: 'INATIVO',
            modRewrite: 'ATIVO',
            requestTerminateTimeout: '30',
            maxChildren: '64',
            uploadMaxFilesize: '2',
            errorLog: 'INATIVO',

            eAll: 'INATIVO',
            eError: 'INATIVO',
            eWarning: 'INATIVO',
            eParse: 'INATIVO',
            eNotice: 'INATIVO',
            eStrict: 'INATIVO',
            eDeprecated: 'INATIVO',
            eCoreError: 'INATIVO',
            eCoreWarning: 'INATIVO',
            eCompileError: 'INATIVO',
            eCompileWarning: 'INATIVO',
            eUserError: 'INATIVO',
            eUserNotice: 'INATIVO',
            eUserWarning: 'INATIVO',
            eRecoverableError: 'INATIVO',
            eUserDeprecated: 'INATIVO',

            displayErrors: 'ATIVO',

            execCGI: 'INATIVO',
            followSymLinks: 'ATIVO',
            symLinksIfOwnerMatch: 'INATIVO',
            includes: 'ATIVO',
            includesNOEXEC: 'INATIVO',
            multiViews: 'INATIVO',

            authConfig: 'ATIVO',
            fileInfo: 'ATIVO',
            indexes: 'ATIVO',
            limit: 'ATIVO',
            options: 'ATIVO',
            outputBuffering: 'INATIVO',
            log: 'INATIVO',
          }}
          validationSchema={Yup.object().shape({
            serverName: Yup.string().required(
              t('pages:siteSettingsNew.validation.serverName'),
            ),
            documentRoot: Yup.string().required(
              t('pages:siteSettingsNew.validation.documentRoot'),
            ),
            requestTerminateTimeout: Yup.number()
              .typeError(t('pages:siteSettingsNew.validation.numeric'))
              .required(
                t('pages:siteSettingsNew.validation.requestTerminateTimeout'),
              )
              .min(
                30,
                t('pages:siteSettingsNew.validation.min', { value: 30 }),
              ),
            maxChildren: Yup.number()
              .typeError(t('pages:siteSettingsNew.validation.numeric'))
              .required(t('pages:siteSettingsNew.validation.maxChildren'))
              .min(0, t('pages:siteSettingsNew.validation.min', { value: 0 })),
            uploadMaxFilesize: Yup.number()
              .typeError(t('pages:siteSettingsNew.validation.numeric'))
              .required(t('pages:siteSettingsNew.validation.uploadMaxFilesize'))
              .min(0, t('pages:siteSettingsNew.validation.min', { value: 0 })),
          })}
          onSubmit={createSite}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:siteSettingsNew.basicSettings')}
                />

                <Form.Group as={Row} controlId="siteSettingsNew.serverName">
                  <Form.Label column sm={2} className="required">
                    {t('pages:siteSettingsNew.serverName')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-">
                          {t('pages:siteSettingsNew.serverNameTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="serverName"
                      placeholder="www.novosite.com"
                      value={props.values.serverName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.serverName && !!props.errors.serverName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.serverName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="siteSettingsNew.documentRoot">
                  <Form.Label column sm={2} className="required">
                    {t('pages:siteSettingsNew.documentRoot')}{' '}
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-">
                          {t('pages:siteSettingsNew.documentRootTooltip')}
                        </Tooltip>
                      }
                    >
                      <FaQuestionCircle color="#2c4593" />
                    </OverlayTrigger>
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {props.values.baseDocumentRoot}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="documentRoot"
                        placeholder="novosite"
                        value={props.values.documentRoot}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.documentRoot &&
                          !!props.errors.documentRoot
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.documentRoot}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:siteSettingsNew.advancedSettings')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="advanced-true"
                      label="Sim"
                      value="true"
                      checked={advancedSettings}
                      onChange={() => setAdvancedSettings(true)}
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="advanced-false"
                      label="Não"
                      value="false"
                      checked={!advancedSettings}
                      onChange={() => setAdvancedSettings(false)}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:siteSettingsNew.advancedSettingsInfo')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                {advancedSettings && (
                  <div className="mt-5">
                    <FormSubtitle
                      subTitle={t('pages:siteSettingsNew.advancedSettings')}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.cache">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.cache')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="cache-true"
                          name="cache"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.cache === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="cache-false"
                          name="cache"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.cache === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.modRewrite">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.modRewrite')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="modRewrite-true"
                          name="modRewrite"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.modRewrite === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="modRewrite-false"
                          name="modRewrite"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.modRewrite === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.modRewriteInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.requestTerminateTimeout"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.requestTerminateTimeout')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="requestTerminateTimeout"
                          value={props.values.requestTerminateTimeout}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.requestTerminateTimeout &&
                            !!props.errors.requestTerminateTimeout
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.requestTerminateTimeout}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.maxChildren"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.maxChildren')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="maxChildren"
                          value={props.values.maxChildren}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.maxChildren &&
                            !!props.errors.maxChildren
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.maxChildren}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.uploadMaxFilesize"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.uploadMaxFilesize')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="text"
                          name="uploadMaxFilesize"
                          value={props.values.uploadMaxFilesize}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.uploadMaxFilesize &&
                            !!props.errors.uploadMaxFilesize
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Control.Feedback type="invalid">
                          {props.errors.uploadMaxFilesize}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.uploadMaxFilesizeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.errorLog">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.errorLog')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="errorLog-true"
                          name="errorLog"
                          label="Sim"
                          value="ATIVO"
                          checked={props.values.errorLog === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="errorLog-false"
                          name="errorLog"
                          label="Não"
                          value="INATIVO"
                          checked={props.values.errorLog === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.phpErrorReportingSettings',
                      )}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.eAll">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eAll')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eAll-ativo"
                          name="eAll"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eAll === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eAll-inativo"
                          name="eAll"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eAll === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eAllInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eError-ativo"
                          name="eError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eError-inativo"
                          name="eError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eWarning">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eWarning-ativo"
                          name="eWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eWarning-inativo"
                          name="eWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eParse">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eParse')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eParse-ativo"
                          name="eParse"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eParse === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eParse-inativo"
                          name="eParse"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eParse === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eParseInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eNotice">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eNotice')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eNotice-ativo"
                          name="eNotice"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eNotice === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eNotice-inativo"
                          name="eNotice"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eNotice === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eNoticeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eStrict">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eStrict')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eStrict-ativo"
                          name="eStrict"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eStrict === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eStrict-inativo"
                          name="eStrict"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eStrict === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eStrictInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eDeprecated"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eDeprecated')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eDeprecated-ativo"
                          name="eDeprecated"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eDeprecated === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eDeprecated-inativo"
                          name="eDeprecated"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eDeprecated === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eDeprecatedInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eCoreError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCoreError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreError-ativo"
                          name="eCoreError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCoreError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreError-inativo"
                          name="eCoreError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCoreError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCoreErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCoreWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCoreWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreWarning-ativo"
                          name="eCoreWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCoreWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCoreWarning-inativo"
                          name="eCoreWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCoreWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCoreWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCompileError"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCompileError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileError-ativo"
                          name="eCompileError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCompileError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileError-inativo"
                          name="eCompileError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCompileError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCompileErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eCompileWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eCompileWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileWarning-ativo"
                          name="eCompileWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eCompileWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eCompileWarning-inativo"
                          name="eCompileWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eCompileWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eCompileWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="siteSettingsNew.eUserError">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserError-ativo"
                          name="eUserError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserError-inativo"
                          name="eUserError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserNotice"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserNotice')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserNotice-ativo"
                          name="eUserNotice"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserNotice === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserNotice-inativo"
                          name="eUserNotice"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserNotice === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserNoticeInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserWarning"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserWarning')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserWarning-ativo"
                          name="eUserWarning"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserWarning === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserWarning-inativo"
                          name="eUserWarning"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserWarning === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserWarningInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eRecoverableError"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eRecoverableError')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eRecoverableError-ativo"
                          name="eRecoverableError"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eRecoverableError === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eRecoverableError-inativo"
                          name="eRecoverableError"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eRecoverableError === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eRecoverableErrorInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.eUserDeprecated"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.eUserDeprecated')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserDeprecated-ativo"
                          name="eUserDeprecated"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.eUserDeprecated === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="eUserDeprecated-inativo"
                          name="eUserDeprecated"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.eUserDeprecated === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:siteSettingsNew.eUserDeprecatedInfo')}
                        </Form.Text>
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.displayErrors"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.displayErrors')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="displayErrors-ativo"
                          name="displayErrors"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.displayErrors === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="displayErrors-inativo"
                          name="displayErrors"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.displayErrors === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.apacheOptionsSettings',
                      )}
                    />

                    <Form.Group as={Row} controlId="siteSettingsNew.execCGI">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.execCGI')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="execCGI-ativo"
                          name="execCGI"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.execCGI === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="execCGI-inativo"
                          name="execCGI"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.execCGI === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.followSymLinks"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.followSymLinks')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="followSymLinks-ativo"
                          name="followSymLinks"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.followSymLinks === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="followSymLinks-inativo"
                          name="followSymLinks"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.followSymLinks === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.symLinksIfOwnerMatch"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.symLinksIfOwnerMatch')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="symLinksIfOwnerMatch-ativo"
                          name="symLinksIfOwnerMatch"
                          label="Ativo"
                          value="ATIVO"
                          checked={
                            props.values.symLinksIfOwnerMatch === 'ATIVO'
                          }
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="symLinksIfOwnerMatch-inativo"
                          name="symLinksIfOwnerMatch"
                          label="Inativo"
                          value="INATIVO"
                          checked={
                            props.values.symLinksIfOwnerMatch === 'INATIVO'
                          }
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.includes">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.includes')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includes-ativo"
                          name="includes"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.includes === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includes-inativo"
                          name="includes"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.includes === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.includesNOEXEC"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.includesNOEXEC')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includesNOEXEC-ativo"
                          name="includesNOEXEC"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.includesNOEXEC === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="includesNOEXEC-inativo"
                          name="includesNOEXEC"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.includesNOEXEC === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.multiViews">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.multiViews')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="multiViews-ativo"
                          name="multiViews"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.multiViews === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="multiViews-inativo"
                          name="multiViews"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.multiViews === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>

                    <div className="mt-5" />

                    <FormSubtitle
                      subTitle={t(
                        'pages:siteSettingsNew.apacheAllowOverrideSettings',
                      )}
                    />
                    <Form.Group as={Row} controlId="siteSettingsNew.authConfig">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.authConfig')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="authConfig-ativo"
                          name="authConfig"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.authConfig === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="authConfig-inativo"
                          name="authConfig"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.authConfig === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.fileInfo">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.fileInfo')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="fileInfo-ativo"
                          name="fileInfo"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.fileInfo === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="fileInfo-inativo"
                          name="fileInfo"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.fileInfo === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.indexes">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.indexes')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="indexes-ativo"
                          name="indexes"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.indexes === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="indexes-inativo"
                          name="indexes"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.indexes === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.limit">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.limit')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="limit-ativo"
                          name="limit"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.limit === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="limit-inativo"
                          name="limit"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.limit === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.options">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.options')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="options-ativo"
                          name="options"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.options === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="options-inativo"
                          name="options"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.options === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="siteSettingsNew.outputBuffering"
                    >
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.outputBuffering')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="outputBuffering-ativo"
                          name="outputBuffering"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.outputBuffering === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="outputBuffering-inativo"
                          name="outputBuffering"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.outputBuffering === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="siteSettingsNew.log">
                      <Form.Label column sm={2}>
                        {t('pages:siteSettingsNew.log')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="log-ativo"
                          name="log"
                          label="Ativo"
                          value="ATIVO"
                          checked={props.values.log === 'ATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                        <Form.Check
                          inline
                          custom
                          type="radio"
                          id="log-inativo"
                          name="log"
                          label="Inativo"
                          value="INATIVO"
                          checked={props.values.log === 'INATIVO'}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                )}
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/cloud/configuracoes-sites"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
