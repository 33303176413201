import { useQuery } from 'react-query';

import api from 'services/api';

type DnsServersResponse = {
  data: string[];
};

type DnsServers = string[];

const initialData: DnsServers = [];

async function loadDnsServers(idSite: number): Promise<DnsServers> {
  const dnsServersResponse = await api.get<DnsServersResponse>(
    `painel/v1/site/${idSite}/dns`,
  );

  return dnsServersResponse.data.data;
}

export default function useDnsServers(idSite: number) {
  const { data, isError, isLoading } = useQuery<DnsServers>(
    ['dns', idSite],
    () => loadDnsServers(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      placeholderData: initialData,
    },
  );

  const dnsServers = data || initialData;

  return { dnsServers, error: isError, loading: isLoading };
}
