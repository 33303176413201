import { StoreState } from 'store/createStore';

export default function basic(
  storeState: StoreState,
  requiredOptions: string[] = [],
) {
  const options = storeState.options.list;

  return options.some(option => requiredOptions.includes(option.codigo));
}
