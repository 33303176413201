import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { FaWindowRestore } from 'react-icons/fa';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import Error from 'components/Error';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import hashIds from 'services/hashIds';
import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

interface MatchParams {
  id: string;
}

type SubDomainEditProps = RouteComponentProps<MatchParams>;

interface SubDomainItemResponse {
  idSubDominio: number;
  redirecionamento: string;
  dominio: string;
  subDominio: string;
}

interface SubDomainResponse {
  data: SubDomainItemResponse[];
}

interface SubDomain {
  idSubDominio: number;
  redirecionamento: string;
  dominio: string;
  subDominio: string;
}

interface EditSubDomainFormValues {
  redirectUrl: string;
}

const Edit: React.FC<SubDomainEditProps> = ({ match }) => {
  const [id] = hashIds.decode(match.params.id);

  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [protocol, setProtocol] = useState('http://');

  const [subDomain, setSubDomain] = useState<SubDomain>({
    idSubDominio: 0,
    dominio: '',
    redirecionamento: '',
    subDominio: '',
  });

  useEffect(() => {
    async function loadSubDomain() {
      try {
        setError(false);
        setLoading(true);

        const subDomainResponse = await api.get<SubDomainResponse>(
          `painel/v1/subdominio/${id}`,
        );

        if (subDomainResponse.data.data.length > 0) {
          const [subDomainItem] = subDomainResponse.data.data;

          setProtocol(
            subDomainItem.redirecionamento.indexOf('https://') !== -1
              ? 'https://'
              : 'http://',
          );

          setSubDomain(subDomainItem);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSubDomain();
  }, [id]);

  const editSubDomain = useCallback(
    async (values: EditSubDomainFormValues) => {
      try {
        const { redirectUrl } = values;

        await api.put(`painel/v1/subdominio/${id}`, {
          urlDestino: protocol + redirectUrl,
        });

        toast.fire(t('pages:subDomainEdit.success'));
      } catch (err) {
        swal.fire({
          title: t('pages:subDomainEdit.failed'),
          html: err.response.data.error_description,
        });
      }
    },
    [id, protocol, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader title={t('pages:subDomainEdit.title')} description="" />
        <Formik
          enableReinitialize
          initialValues={{
            redirectUrl: subDomain.redirecionamento.replace(/^https?:\/\//, ''),
          }}
          validationSchema={Yup.object().shape({
            redirectUrl: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={editSubDomain}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:subDomainEdit.legend')} />

                <Form.Group as={Row} controlId="subDomainEdit.subDomain">
                  <Form.Label column lg={2}>
                    {t('pages:subDomainEdit.subDomain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="redirectUrl"
                      defaultValue={subDomain.subDominio}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="subDomainEdit.domain">
                  <Form.Label column lg={2}>
                    {t('common:label.domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      plaintext
                      readOnly
                      name="redirectUrl"
                      defaultValue={subDomain.dominio}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="subDomainEdit.redirectUrl">
                  <Form.Label column lg={2}>
                    {t('pages:subDomainEdit.redirectTo')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title={protocol}
                      >
                        <Dropdown.Item onSelect={() => setProtocol('http://')}>
                          http://
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => setProtocol('https://')}>
                          https://
                        </Dropdown.Item>
                      </DropdownButton>

                      <Form.Control
                        name="redirectUrl"
                        value={props.values.redirectUrl}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.redirectUrl &&
                          !!props.errors.redirectUrl
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Control.Feedback type="invalid">
                        {props.errors.redirectUrl}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/site/redirecionamentos"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </div>
  );
};

export default withRouter(Edit);
