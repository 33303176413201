import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface InternationalTransferActionsProps {
  selectedCount: number;
  selectedRows: {
    idDomain: number;
    status: string;
  }[];
}

const InternationalTransferActions: React.FC<InternationalTransferActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  if (selectedCount !== 1) {
    return <div />;
  }

  const selectedDomain = selectedRows[0];

  const hashId = hashIds.encode(selectedDomain.idDomain);

  return (
    <TableActionsWrapper loading={false}>
      <TableButton
        forwardedAs={Link}
        to={`/registro/transferencia-internacional/${hashId}/historico`}
        className="mr-2"
      >
        {t('pages:domainTransfer.eventHistory')}
      </TableButton>
      {selectedDomain.status === 'ERRO' && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/transferencia-internacional/${hashId}/reiniciar`}
            className="mr-2"
          >
            {t('common:restart')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default InternationalTransferActions;
