import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpContentStep1: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb-2">
        {t('pages:brDomainTransfer.step1HelpContent.p1')}
      </div>
      <ul>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item1')}</li>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item2')}</li>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item3')}</li>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item4')}</li>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item5')}</li>
        <li>{t('pages:brDomainTransfer.step1HelpContent.item6')}</li>
      </ul>
    </div>
  );
};

export default HelpContentStep1;
