import React from 'react';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

interface RedirectActionsProps {
  loading: boolean;
  deleteRedirect: () => void;
}

const RedirectActions: React.FC<RedirectActionsProps> = ({
  loading,
  deleteRedirect,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={loading}>
      <TableButton
        className="mx-1"
        variant="danger"
        disabled={loading}
        onClick={deleteRedirect}
      >
        {t('common:delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default RedirectActions;
