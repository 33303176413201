import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FaWindowRestore } from 'react-icons/fa';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import {
  Card,
  Col,
  InputGroup,
  Row,
  Form,
  FormControl,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import * as Yup from 'yup';

import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';
import toast from 'services/toast';

import { StoreState } from 'store/createStore';

interface RedirectItemResponse {
  idCliente: number;
  idRedirecionamento: number;
  redirecionamento: string;
  dominio: string;
}

interface RedirectsResponse {
  data: RedirectItemResponse[];
}

interface Redirect {
  idCliente: number;
  idRedirecionamento: number;
  redirecionamento: string;
  dominio: string;
}

interface NewSubDomainFormValues {
  subDomain: string;
  domain: string;
  redirectUrl: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [protocol, setProtocol] = useState('http://');

  const [redirects, setRedirects] = useState<Redirect[]>([]);

  const sites = useSelector((state: StoreState) => state.sites.list);

  function getInitialDomainFormData() {
    if (sites.length > 0) {
      return sites[0].site;
    }

    if (redirects.length > 0) {
      return redirects[0].dominio;
    }

    return '';
  }

  useEffect(() => {
    async function loadRedirects() {
      try {
        setLoading(true);
        setError(false);

        const redirectsResponse = await api.get<RedirectsResponse>(
          'painel/v1/redirecionamento',
        );

        setRedirects(
          redirectsResponse.data.data.map(
            ({ idCliente, idRedirecionamento, dominio, redirecionamento }) => ({
              idCliente,
              idRedirecionamento,
              dominio,
              redirecionamento,
            }),
          ),
        );
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadRedirects();
  }, [sites.length]);

  const createSubDomain = useCallback(
    async (values: NewSubDomainFormValues) => {
      try {
        const { domain, redirectUrl, subDomain } = values;

        await api.post('painel/v1/subdominio', {
          dominio: domain,
          subDominio: subDomain,
          urlDestino: protocol + redirectUrl,
        });

        history.push(`/site/redirecionamentos`);

        toast.fire(t('pages:subDomainNew.success'));
      } catch (err) {
        swal.fire({
          title: t('pages:subDomainNew.fail'),
          html: err.response.data.error_description,
        });
      }
    },
    [protocol, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div>
      <PageTitle
        title={t('titles:sites.title')}
        description={t('titles:sites.description')}
        icon={<FaWindowRestore color="#ffffff" size={24} />}
      />

      <FormWrapper>
        <FormHeader title={t('pages:subDomainNew.title')} description="" />
        <Formik
          initialValues={{
            subDomain: '',
            domain: getInitialDomainFormData(),
            redirectUrl: '',
          }}
          validationSchema={Yup.object().shape({
            subDomain: Yup.string().required(t('validations:requiredField')),
            redirectUrl: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={createSubDomain}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset pt-1">
                <FormSubtitle subTitle={t('pages:subDomainNew.legend')} />

                <Form.Group as={Row} controlId="subDomainNew.subDomain">
                  <Form.Label column sm={2}>
                    {t('pages:subDomainNew.subDomain')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup>
                      <FormControl
                        name="subDomain"
                        value={props.values.subDomain}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.subDomain && !!props.errors.subDomain
                        }
                        disabled={props.isSubmitting}
                      />

                      <DropdownButton
                        as={InputGroup.Append}
                        variant="outline-secondary"
                        title={`.${props.values.domain}`}
                        id="input-group-dropdown-2"
                        drop="left"
                      >
                        {sites.map(site => (
                          <Dropdown.Item
                            key={site.idSite}
                            onSelect={() =>
                              props.setFieldValue('domain', site.site)
                            }
                          >
                            .{site.site}
                          </Dropdown.Item>
                        ))}

                        {redirects.map(redirect => (
                          <Dropdown.Item
                            key={redirect.idRedirecionamento}
                            onSelect={() =>
                              props.setFieldValue('domain', redirect.dominio)
                            }
                          >
                            .{redirect.dominio}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                      <Form.Text className="text-muted d-block w-100">
                        <Trans i18nKey="pages:subDomainNew.subDomainTip" />.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.subDomain}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="subDomainNew.redirectUrl">
                  <Form.Label column sm={2}>
                    {t('pages:subDomainNew.redirectTo')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-secondary"
                        title={protocol}
                      >
                        <Dropdown.Item onSelect={() => setProtocol('http://')}>
                          http://
                        </Dropdown.Item>
                        <Dropdown.Item onSelect={() => setProtocol('https://')}>
                          https://
                        </Dropdown.Item>
                      </DropdownButton>
                      <Form.Control
                        name="redirectUrl"
                        value={props.values.redirectUrl}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        isInvalid={
                          !!props.touched.redirectUrl &&
                          !!props.errors.redirectUrl
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted d-block w-100">
                        {t('pages:siteRedirectsNew.redirectToTip')}.
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.redirectUrl}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/site/redirecionamentos"
                  disabled={props.isSubmitting}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </div>
  );
};

export default New;
