import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1030;

  width: 0px;
  height: 100%;

  background-color: #fff;
  box-shadow: -2px 0 11px -2px rgba(0, 0, 0, 0.3);
  transition: width 0.35s ease;

  &.open {
    width: 400px;

    .close-button {
      display: flex;
    }
  }
`;

export const SitesListScrollBar = styled(SimpleBar)`
  max-height: calc(100% - 95px);
`;

export const CloseButton = styled.a`
  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  left: -40px;
  top: 80px;
  position: absolute;
  background-color: #fff;
  cursor: pointer;

  box-shadow: -2px 0 9px -2px rgba(0, 0, 0, 0.3);
  border-radius: 50% 0 0 50%;

  display: none;
`;

export const SitesList = styled.ul`
  list-style: none;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100% - 95px);

  li {
  }

  li:last-child {
    border-bottom: none !important;
  }

  li:hover {
    background-color: rgba(44, 69, 147, 0.1);
  }

  svg.favorite {
    color: var(--color-yellow) !important;
  }
`;

export const FavoriteSiteContainer = styled.div`
  padding-right: 18px;

  display: flex;
  align-items: center;
`;

export const SelectSiteButton = styled.button`
  width: 100%;
  padding: 18px;

  border: none;
  background: transparent;
  outline: none;

  text-align: left;
`;

export const FavoriteSiteButton = styled.button`
  border: none;
  outline: none;
  background: transparent;

  color: #d3d3d3;

  &.favorite {
    color: var(--color-yellow) !important;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const SearchButton = styled(Button)`
  background-color: var(--color-blue);
  border: var(--color-blue);

  display: flex;
  align-items: center;

  &:hover {
    border-color: #3857ba;
    background-color: #3857ba;
  }
`;

export const NotFound = styled.div`
  color: #999;
  text-align: center;
  margin-top: 30px;
`;

export const SelectAdditionalSiteButton = styled.button`
  width: 100%;
  padding: 18px;

  border: none;
  background: transparent;
  outline: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  text-align: left;
`;

export const AdditionalSiteContainer = styled.div`
  padding-right: 3px;

  color: #cec8c8;

  display: flex;
  align-items: center;
`;
