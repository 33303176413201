import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaDatabase } from 'react-icons/fa';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import hashIds from 'services/hashIds';
import toast from 'services/toast';
import swal from 'services/swal';

import useDatabases from 'hooks/useDatabases';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type MySQLEditProps = RouteComponentProps<MatchParams>;

interface DatabaseResponse {
  data: {
    id: number;
    ativo: boolean;
    banco: string;
    descricao: string;
    usuario: string;
  }[];
}

interface Database {
  id: number;
  ativo: boolean;
  banco: string;
  descricao: string;
  usuario: string;
}

interface SecurityResponse {
  data: {
    nivel: string;
  };
}

interface EditDatabaseFormValues {
  password: string;
  confirm: string;
  description: string;
  security: boolean;
}

const Edit: React.FC<MySQLEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDatabase] = hashIds.decode(match.params.id);

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { refetch } = useDatabases(idSite);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [database, setDatabase] = useState<Database>({
    id: 0,
    ativo: false,
    banco: '',
    descricao: '',
    usuario: '',
  });

  const [protectedDatabase, setProtectedDatabase] = useState(false);

  useEffect(() => {
    async function loadDatabase() {
      try {
        setError(false);
        setLoading(true);

        const databaseResponse = await api.get<DatabaseResponse>(
          `painel/v1/site/${idSite}/banco-mysql/${idDatabase}`,
        );

        const {
          id,
          ativo,
          banco,
          descricao,
          usuario,
        } = databaseResponse.data.data[0];

        setDatabase({
          id,
          ativo,
          banco,
          descricao,
          usuario,
        });

        const securityResponse = await api.get<SecurityResponse>(
          `painel/v1/site/${idSite}/banco-mysql/${idDatabase}/nivel-seguranca`,
        );

        setProtectedDatabase(securityResponse.data.data.nivel === 'ALTO');
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDatabase();
  }, [idDatabase, idSite]);

  const editDatabase = useCallback(
    async (
      values: EditDatabaseFormValues,
      formikHelpers: FormikHelpers<EditDatabaseFormValues>,
    ) => {
      try {
        const { password, confirm, description, security } = values;

        if (password.length >= 8 && password === confirm) {
          await api.put(`painel/v1/site/${idSite}/banco-mysql/${idDatabase}`, {
            usuario: database.usuario,
            senha: password,
          });
        }

        if (protectedDatabase !== security) {
          await api.put(
            `painel/v1/site/${idSite}/banco-mysql/${idDatabase}/nivel-seguranca`,
            {
              protegido: security,
            },
          );
        }

        if (database.descricao !== description) {
          await api.put(`painel/v1/site/${idSite}/banco-mysql/${idDatabase}`, {
            descricao: description,
          });
        }

        setDatabase({ ...database, ...{ descricao: description } });
        setProtectedDatabase(security);

        refetch();

        formikHelpers.resetForm();

        toast.fire(t('pages:databaseMySqlEdit.success'));
      } catch (err) {
        swal.fire({
          title: t('pages:databaseMySqlEdit.failed'),
          html: err.response.data.error_description,
        });
      }
    },
    [database, idDatabase, idSite, protectedDatabase, refetch, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:databases.title')}
        description={t('titles:databases.description')}
        icon={<FaDatabase color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={`${t('pages:databaseMySqlEdit.title')} - ${database.banco}`}
          description=""
          helpContent={
            <ul>
              <li>{t('pages:databaseMySqlEdit.changePassHelp1')}</li>
              <li>{t('pages:databaseMySqlEdit.changePassHelp2')}</li>
              <li>{t('pages:databaseMySqlEdit.changePassHelp3')}</li>
            </ul>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            password: '',
            confirm: '',
            description: database.descricao ? database.descricao : '',
            security: protectedDatabase,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(8, t('validations.passwordMin'))
              .matches(RegExp('^(?=.*[0-9])'), t('validations.passwordNumber'))
              .matches(RegExp('^(?=.*[a-z])'), t('validations.passwordLower'))
              .matches(RegExp('^(?=.*[A-Z])'), t('validations.passwordUpper'))
              .matches(
                RegExp('^(?=.*[!@#$%*()_+=-?/{}<>])[^&^]*$'),
                t('validations.passwordChar'),
              ),
            confirm: Yup.string()
              .oneOf(
                [Yup.ref('password'), null],
                t('validations.invalidPasswordConfirm'),
              )
              .when('password', {
                is: (val: string) => val !== '' && val !== undefined,
                then: Yup.string().required(t('validations.passwordConfirm')),
              }),
            description: Yup.string(),
          })}
          onSubmit={editDatabase}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset pt-0">
                <FormSubtitle
                  subTitle={t('pages:databaseMySqlEdit.changePass')}
                />

                <Form.Group as={Row} controlId="database.Password">
                  <Form.Label column sm={2}>
                    {t('common:label.password')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="password"
                      value={props.values.password}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.password && !!props.errors.password
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.password}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('common.matchPassword')}
                    </Form.Text>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="database.ConfirmPassword">
                  <Form.Label column sm={2}>
                    {t('common:label.confirmPassword')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      name="confirm"
                      value={props.values.confirm}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.confirm && !!props.errors.confirm
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.confirm}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <Card.Body className="fieldset pt-0">
                <FormSubtitle
                  subTitle={t('pages:databaseMySqlEdit.editDescription')}
                />

                <Form.Group as={Row} controlId="database.description">
                  <Form.Label column sm={2}>
                    {t('common:label.description')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="description"
                      value={props.values.description}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.description &&
                        !!props.errors.description
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.description}
                    </Form.Control.Feedback>
                    <Form.Text className="text-muted">
                      {t('pages:databaseMySqlEdit.descriptionTip')}
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>

              <Card.Body className="fieldset pt-0">
                <FormSubtitle
                  subTitle={t('pages:databaseMySqlEdit.changeSecurity')}
                />

                <Form.Group as={Row} controlId="database.protected">
                  <Form.Label column sm={2}>
                    {t('pages:databaseMySqlEdit.securityLevel')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      id="protected-true"
                      type="radio"
                      label={t('pages:databaseMySqlEdit.protected')}
                      name="protected"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('security', true)}
                      checked={props.values.security}
                    />
                    <Form.Check
                      inline
                      custom
                      id="protected-false"
                      type="radio"
                      label={t('pages:databaseMySqlEdit.free')}
                      name="protected"
                      disabled={props.isSubmitting}
                      onChange={() => props.setFieldValue('security', false)}
                      checked={!props.values.security}
                    />
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={
                    props.isSubmitting || !props.dirty || !props.isValid
                  }
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/banco-de-dados-mysql"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
