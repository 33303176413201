import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetHttpDiskDayItem = {
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetHttpDiskResponse = {
  data: {
    media: number;
    mediaKb: number;
    mediaMb: number;
    mediaGb: number;
    total: number;
    totalKb: number;
    totalMb: number;
    totalGb: number;
    consumoDiario: {
      [key: string]: RatchetHttpDiskDayItem;
    };
  };
};

type RatchetHttpDiskDay = {
  date: string;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
};

type RatchetHttpDisk = {
  media: number;
  mediaKb: number;
  mediaMb: number;
  mediaGb: number;
  total: number;
  totalKb: number;
  totalMb: number;
  totalGb: number;
  consumoDiario: RatchetHttpDiskDay[];
};

const initialData: RatchetHttpDisk = {
  media: 0,
  mediaKb: 0,
  mediaMb: 0,
  mediaGb: 0,
  total: 0,
  totalKb: 0,
  totalMb: 0,
  totalGb: 0,
  consumoDiario: [],
};

function formatResponse(
  ratchetHttpDiskResponse: RatchetHttpDiskResponse,
): RatchetHttpDisk {
  const ratchetDailyData = ratchetHttpDiskResponse.data.consumoDiario;

  const isObject = typeof ratchetDailyData === 'object';
  const isNotArray = !Array.isArray(ratchetDailyData);
  const isNotNull = ratchetDailyData !== null;

  const dailyData: RatchetHttpDiskDay[] = [];

  if (isObject && isNotArray && isNotNull) {
    const dateKeys = Object.keys(ratchetDailyData);

    dateKeys.forEach(dateKey => {
      dailyData.push({ ...ratchetDailyData[dateKey], ...{ date: dateKey } });
    });
  }

  return {
    media: ratchetHttpDiskResponse.data.media,
    mediaKb: ratchetHttpDiskResponse.data.mediaKb,
    mediaMb: ratchetHttpDiskResponse.data.mediaMb,
    mediaGb: ratchetHttpDiskResponse.data.mediaGb,
    total: ratchetHttpDiskResponse.data.total,
    totalKb: ratchetHttpDiskResponse.data.totalKb,
    totalMb: ratchetHttpDiskResponse.data.totalMb,
    totalGb: ratchetHttpDiskResponse.data.totalGb,
    consumoDiario: dailyData,
  };
}

async function loadRatchetHttpDisk(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetHttpDisk> {
  const httpDiskResponse = await api.get<RatchetHttpDiskResponse>(
    `painel/v1/ratchet/disco/http`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const ratchetHttpDisk = formatResponse(httpDiskResponse.data);

  return ratchetHttpDisk;
}

export default function useRatchetHttpDisk(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetHttpDisk>(
    ['ratchetHttpDisk', site, initialDate, finalDate],
    () => loadRatchetHttpDisk(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetHttpDisk = data || initialData;

  return {
    ratchetHttpDisk,
    ...rest,
  };
}
