import { useQuery } from 'react-query';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import api from 'services/api';
import formatCurrency from 'utils/formatCurrency';

type ClientProductsItemResponse = {
  idClienteProduto: number;
  idProduto: number;
  idProdutoCategoria: number;
  idCliente: number;
  idSite: number;
  site: string;
  produto: string;
  valor: string;
  dataHora: string;
};

type ClientProductsResponse = {
  data: ClientProductsItemResponse[];
};

type ClientProduct = {
  idClienteProduto: number;
  idProduto: number;
  idProdutoCategoria: number;
  idCliente: number;
  idSite: number;
  site: string;
  produto: string;
  valor: string;
  valorFormatado: string;
  dataContratacao: string;
};

const initialData: ClientProduct[] = [];

function formatClientProduct(
  clientProduct: ClientProductsItemResponse,
): ClientProduct {
  return {
    idClienteProduto: clientProduct.idClienteProduto,
    idProduto: clientProduct.idProduto,
    idProdutoCategoria: clientProduct.idProdutoCategoria,
    idCliente: clientProduct.idCliente,
    idSite: clientProduct.idSite,
    site: clientProduct.site,
    produto: clientProduct.produto,
    valor: clientProduct.valor,
    valorFormatado: formatCurrency(Number(clientProduct.valor)),
    dataContratacao: format(parseISO(clientProduct.dataHora), 'dd/MM/yyyy', {
      locale: pt,
    }),
  };
}

async function loadClientProducts(idClient: number): Promise<ClientProduct[]> {
  const clientProductsResponse = await api.get<ClientProductsResponse>(
    `clientes/v1/cliente/${idClient}/produto`,
  );

  const clientProducts = clientProductsResponse.data.data.map(
    formatClientProduct,
  );

  return clientProducts;
}

export default function useClientProducts(idClient: number) {
  const { data, ...rest } = useQuery<ClientProduct[]>(
    'clientProducts',
    () => loadClientProducts(idClient),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const clientProcuts = data || initialData;

  return {
    clientProcuts,
    ...rest,
  };
}
