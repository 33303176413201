import React, { AnchorHTMLAttributes } from 'react';

import { ExternalLink, ChevronRightIcon } from './styles';

interface SideMenuAccordionExternalLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
}

const SideMenuAccordionExternalLink: React.FC<SideMenuAccordionExternalLinkProps> = ({
  text,
  ...rest
}) => {
  return (
    <ExternalLink {...rest}>
      <ChevronRightIcon />
      <div>{text}</div>
    </ExternalLink>
  );
};

export default SideMenuAccordionExternalLink;
