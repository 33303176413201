import styled from 'styled-components';

export const Container = styled.div``;

export const StyledLink = styled.a`
  font-size: 14px;
  color: var(--color-midnight-blue);
  transition: all 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: var(--color-blue);
    text-decoration: none;
  }
`;
