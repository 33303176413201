import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface BrDomainActionsProps {
  selectedCount: number;
  selectedRows: {
    idDomain: number;
    promotional: boolean;
  }[];
}

const BrDomainActions: React.FC<BrDomainActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  if (selectedCount !== 1) {
    return <div />;
  }

  const selectedDomain = selectedRows[0];

  return (
    <TableActionsWrapper loading={false}>
      {selectedDomain.promotional && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-br-comprar/${hashIds.encode(
              selectedRows[0].idDomain,
            )}`}
            className="mr-2"
          >
            {t('common:buy')}
          </TableButton>
        </>
      )}

      {!selectedDomain.promotional && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-br-dns/${hashIds.encode(
              selectedRows[0].idDomain,
            )}`}
            className="mr-2"
          >
            {t('common:dns')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-br-configuracao/${hashIds.encode(
              selectedRows[0].idDomain,
            )}`}
            className="mr-2"
          >
            {t('common:settings')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-br-renovar/${hashIds.encode(
              selectedRows[0].idDomain,
            )}`}
            className="mr-2"
          >
            {t('pages:domains.addYears')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default BrDomainActions;
