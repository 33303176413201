import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Accordion } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import { Option } from 'store/modules/site/types';

import backToMyAccount from 'services/backToMyAccount';

import {
  Container,
  GemIcon,
  HomeIcon,
  QuestionIcon,
  SideMenuScrollBar,
  PlusIcon,
  SignOutIcon,
} from './styles';

import Loader from './components/Loader';
import SideMenuButton from './components/SideMenuButton';
import SideMenuSession from './components/SideMenuSession';
import SideMenuLink from './components/SideMenuLink';
import SideMenuExternalLink from './components/SideMenuExternalLink';

import AppsOptions from './options/AppsOptions';
import AccountOptions from './options/AccountOptions';
import CloudOptions from './options/CloudOptions';
import ConsumptionOptions from './options/ConsumptionOptions';
import DomainRegistryOptions from './options/DomainRegistryOptions';
import DatabaseOptions from './options/DatabaseOptions';
import FinanceOptions from './options/FinanceOptions';
import SiteOptions from './options/SiteOptions';
import EmailOptions from './options/EmailOptions';

import { filterMenuOptions } from './filterMenuOptions';

interface SideMenuProps {
  collapsed: boolean;
  pendingTickets: number;
  siteOptions: Option[];
  loadingSite: boolean;
}

type MenuElement = {
  element: React.ReactNode;
  requiredOptions: String[];
};

const SideMenu: React.FC<SideMenuProps> = ({
  collapsed,
  pendingTickets,
  siteOptions: sessionOptions,
  loadingSite,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { site, tecnologia } = useSelector(
    (state: StoreState) => state.site.info,
  );

  const { email1: usuario } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const accountMenuElements: MenuElement[] = [
    {
      element: (
        <SideMenuLink
          text={t('components:sideMenu.home')}
          to="/home"
          notifications={0}
          icon={<HomeIcon size={15} />}
        />
      ),
      requiredOptions: [],
    },
    {
      element: (
        <SideMenuButton
          text={t('components:sideMenu.backToMyDashboard')}
          onClick={() => backToMyAccount()}
          notifications={0}
          icon={<SignOutIcon size={15} />}
        />
      ),
      requiredOptions: ['voltar.para.meu.painel'],
    },
    {
      element: (
        <SideMenuLink
          text={t('components:sideMenu.subscribe')}
          to="/contratar"
          icon={<PlusIcon size={15} />}
        />
      ),
      requiredOptions: ['contratar.produtos'],
    },
    {
      element: (
        <SideMenuExternalLink
          text={t('components:sideMenu.franchiseControl')}
          to="https://painel-franquia.hostnet.com.br/"
          icon={<GemIcon size={15} />}
        />
      ),
      requiredOptions: ['painel.representante'],
    },
    {
      element: (
        <AccountOptions
          site={site}
          usuario={usuario}
          options={sessionOptions}
        />
      ),
      requiredOptions: ['administracao.da.conta'],
    },
    {
      element: <FinanceOptions site={site} options={sessionOptions} />,
      requiredOptions: ['financeiro'],
    },
    {
      element: (
        <SideMenuLink
          text={t('components:sideMenu.helpdesk')}
          to="/helpdesk"
          notifications={pendingTickets}
          icon={<QuestionIcon size={15} />}
        />
      ),
      requiredOptions: ['helpdesk'],
    },
    {
      element: <DomainRegistryOptions site={site} />,
      requiredOptions: ['registro.dominios'],
    },
  ];

  const availableAccountMenuElements = filterMenuOptions(
    sessionOptions,
    accountMenuElements,
  );

  const siteMenuElements: MenuElement[] = [
    {
      element: (
        <SiteOptions site={site} options={sessionOptions} tech={tecnologia} />
      ),
      requiredOptions: [],
    },
    {
      element: <CloudOptions />,
      requiredOptions: ['servidor.cloud'],
    },
    {
      element: (
        <AppsOptions site={site} options={sessionOptions} usuario={usuario} />
      ),
      requiredOptions: [
        'servidor.cloud',
        'banco.mysql',
        'webfacil.migrado.wordpress',
      ],
    },
    {
      element: <EmailOptions site={site} options={sessionOptions} />,
      requiredOptions: ['email'],
    },
    {
      element: (
        <DatabaseOptions
          site={site}
          options={sessionOptions}
          usuario={usuario}
        />
      ),
      requiredOptions: ['banco.mysql'],
    },
    {
      element: <ConsumptionOptions site={site} options={sessionOptions} />,
      requiredOptions: ['consumo'],
    },
  ];

  const availableSiteMenuElements = filterMenuOptions(
    sessionOptions,
    siteMenuElements,
  );

  function getActiveMenuKey() {
    const sessions = [
      {
        key: '1',
        pathname: '/cancelamento',
      },
      {
        key: '2',
        pathname: '/financeiro',
      },
      {
        key: '3',
        pathname: '/site',
      },
      {
        key: '4',
        pathname: '/cloud',
      },
      {
        key: '5',
        pathname: '/emails',
      },
      {
        key: '6',
        pathname: '/registro',
      },
      {
        key: '7',
        pathname: '/aplicativos',
      },
      {
        key: '8',
        pathname: '/consumo',
      },
      {
        key: '9',
        pathname: '/banco-de-dados-mysql',
      },
    ];

    let activeKey = '0';

    sessions.forEach(session => {
      if (location.pathname.startsWith(session.pathname)) {
        activeKey = session.key;
      }
    });

    return activeKey;
  }

  return (
    <Container className={collapsed ? 'closed' : ''}>
      {loadingSite && <Loader />}

      {!loadingSite && (
        <SideMenuScrollBar>
          <Accordion defaultActiveKey={getActiveMenuKey()}>
            <SideMenuSession
              label="Conta"
              elements={availableAccountMenuElements}
            />
            <SideMenuSession
              label="Site"
              elements={availableSiteMenuElements}
            />
          </Accordion>
        </SideMenuScrollBar>
      )}
    </Container>
  );
};

export default SideMenu;
