import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetEmailStatisticItem = {
  email: string;
  totalEnvios: number;
  totalRecebimentos: number;
  totalFeedBackLoop: number;
  totalBounces: number;
  limiteEnvio: number;
  limiteRecebimento: number;
  dtInput?: string;
  taxaPunicao?: number;
  limitePunicao?: number;
  totalPunicoes?: number;
};

type RatchetEmailResponse = {
  data: {
    estatisticas: {
      [key: string]: {
        [key: string]: RatchetEmailStatisticItem;
      };
    };
    limiteEnvio: number;
    limiteRecebimento: number;
    limitePunicao: number;
    totalRecebimentos: number;
    totalEnvios: number;
    totalBounces: number;
    totalFeedBackLoop: number;
    totalPunicoes: number;
    taxaBounces: number;
    taxaFeedBackLoop: number;
    taxaPunicoes: number;
  };
};

type RatchetEmailStatistic = {
  email: string;
  totalEnvios: number;
  totalRecebimentos: number;
  totalFeedBackLoop: number;
  totalBounces: number;
  limiteEnvio: number;
  limiteRecebimento: number;
  periodo: 'dia' | 'mes';
  dtInput?: string;
  taxaPunicao?: number;
  limitePunicao?: number;
  totalPunicoes?: number;
};

type RatchetEmail = {
  limiteEnvio: number;
  limiteRecebimento: number;
  limitePunicao: number;
  totalRecebimentos: number;
  totalEnvios: number;
  totalBounces: number;
  totalFeedBackLoop: number;
  totalPunicoes: number;
  taxaBounces: number;
  taxaFeedBackLoop: number;
  taxaPunicoes: number;
  estatisticas: RatchetEmailStatistic[];
};

const initialData: RatchetEmail = {
  limiteEnvio: 0,
  limiteRecebimento: 0,
  limitePunicao: 0,
  totalRecebimentos: 0,
  totalEnvios: 0,
  totalFeedBackLoop: 0,
  totalBounces: 0,
  totalPunicoes: 0,
  taxaBounces: 0,
  taxaFeedBackLoop: 0,
  taxaPunicoes: 0,
  estatisticas: [],
};

function formatResponse(
  ratchetEmailResponse: RatchetEmailResponse,
): RatchetEmail {
  const statistics = ratchetEmailResponse.data.estatisticas;

  const isObject = typeof statistics === 'object';
  const isNotArray = !Array.isArray(statistics);
  const isNotNull = statistics !== null;

  const accountData: RatchetEmailStatistic[] = [];

  if (isObject && isNotArray && isNotNull) {
    const emailKeys = Object.keys(statistics);

    emailKeys.forEach(emailKey => {
      const dateKeys = Object.keys(statistics[emailKey]);

      dateKeys.forEach(dateKey => {
        const ratchetEmailData = statistics[emailKey][dateKey];

        let periodo: 'dia' | 'mes' = 'dia';

        if (dateKey.length > 10) {
          periodo = 'mes';
        }

        accountData.push({ ...ratchetEmailData, ...{ periodo } });
      });
    });
  }

  return {
    limiteEnvio: ratchetEmailResponse.data.limiteEnvio,
    limiteRecebimento: ratchetEmailResponse.data.limiteRecebimento,
    limitePunicao: ratchetEmailResponse.data.limitePunicao,
    totalRecebimentos: ratchetEmailResponse.data.totalRecebimentos,
    totalEnvios: ratchetEmailResponse.data.totalEnvios,
    totalBounces: ratchetEmailResponse.data.totalBounces,
    totalFeedBackLoop: ratchetEmailResponse.data.totalFeedBackLoop,
    totalPunicoes: ratchetEmailResponse.data.totalPunicoes,
    taxaBounces: ratchetEmailResponse.data.taxaBounces,
    taxaFeedBackLoop: ratchetEmailResponse.data.taxaFeedBackLoop,
    taxaPunicoes: ratchetEmailResponse.data.taxaPunicoes,
    estatisticas: accountData,
  };
}

async function loadRatchetEmails(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetEmail> {
  const emailsResponse = await api.get<RatchetEmailResponse>(
    `painel/v1/ratchet/email`,
    {
      params: {
        site,
        ...(initialDate !== '' && { dataInicial: initialDate }),
        ...(finalDate !== '' && { dataFinal: finalDate }),
      },
    },
  );

  const ratchetEmails = formatResponse(emailsResponse.data);

  return ratchetEmails;
}

export default function useRatchetEmail(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetEmail>(
    ['ratchetEmail', site, initialDate, finalDate],
    () => loadRatchetEmails(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetEmails = data || initialData;

  return {
    ratchetEmails,
    ...rest,
  };
}
