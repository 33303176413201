import { useQuery } from 'react-query';

import api from 'services/api';

interface SupportServiceItemResponse {
  idAtendimentoTipo: number;
  idDepartamento: number;
  identificador: string;
  nome: string;
  url: string;
  padrao: boolean;
}

interface SupportServicesResponse {
  data: SupportServiceItemResponse[];
}

interface SupportService {
  idAtendimentoTipo: number;
  idDepartamento: number;
  identificador: string;
  nome: string;
  url: string;
  padrao: boolean;
}

function formatSupportService({
  idDepartamento,
  ...rest
}: SupportServiceItemResponse): SupportService {
  return {
    idDepartamento: Number(idDepartamento),
    ...rest,
  };
}

const initialData: SupportService[] = [];

async function loadSupportServices(): Promise<SupportService[]> {
  const supportServicesResponse = await api.get<SupportServicesResponse>(
    `helpdesk/v1/tipo-atendimento`,
  );

  const supportServices = supportServicesResponse.data.data.map(
    formatSupportService,
  );

  return supportServices;
}

export default function useSupportServices() {
  const { data, isError, isLoading, isFetching, refetch } = useQuery<
    SupportService[]
  >('supportServices', () => loadSupportServices(), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const supportServices = data || initialData;

  return {
    supportServices,
    error: isError,
    loading: isLoading,
    isFetching,
    refetch,
  };
}
