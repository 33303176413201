import { useQuery } from 'react-query';

import api from 'services/api';

interface ContactItemResponse {
  idContato: number;
  idCliente: number;
  idProvedor: number;
  provedor: string;
  contato: string;
  nome: string;
  email: string;
  endereco: string;
  numero: string;
  complemento: string;
  cidade: string;
  estado: string;
  pais: string;
  cep: string;
  telefone: string;
  ramal: string;
  dataCadastro: string;
  status: string;
}

interface ContactsResponse {
  data: ContactItemResponse[];
}

interface BrContact {
  id: number;
  idProvider: number;
  name: string;
  contact: string;
  email: string;
  address: string;
  number: string;
  complement: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  extensionNumber: string;
  status: string;
}

function formatContact(contactResponse: ContactItemResponse) {
  return {
    id: contactResponse.idContato,
    idProvider: contactResponse.idProvedor,
    name: contactResponse.nome,
    contact: contactResponse.contato,
    email: contactResponse.email,
    address: contactResponse.endereco,
    number: contactResponse.numero,
    complement: contactResponse.complemento,
    zipCode: contactResponse.cep,
    city: contactResponse.cidade,
    state: contactResponse.estado,
    country: contactResponse.pais,
    phone: contactResponse.telefone.split('.')[1],
    extensionNumber: contactResponse.ramal,
    status: contactResponse.status,
  };
}

async function loadBrDomainContacts(): Promise<BrContact[]> {
  const { data } = await api.get<ContactsResponse>(`registrobr/v1/contato`);

  const contacts = data.data.map(formatContact);

  return contacts;
}

export default function useBrDomainContacts(idContact?: number) {
  const initialData: BrContact[] = [];

  const { data, isError, isLoading, isFetching, isFetched, refetch } = useQuery<
    BrContact[]
  >('brDomainContacts', () => loadBrDomainContacts(), {
    refetchOnWindowFocus: false,
    staleTime: 86400000,
    retry: false,
  });

  const contacts = data || initialData;

  const filteredContacts = idContact
    ? contacts.filter(contact => contact.id === idContact)
    : contacts;

  return {
    contacts: filteredContacts,
    loading: isLoading,
    fetching: isFetching,
    isFetched,
    error: isError,
    refetch,
  };
}
