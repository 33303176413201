import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  margin-top: 30px;
  margin-bottom: 30px;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  min-width: 40px;
  height: 40px;

  margin-right: 20px;

  border-radius: 5px;
  background-color: var(--color-blue);
  box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
`;

export const TitleContainer = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-size: 18px;
`;

export const Description = styled.div`
  font-size: 13px;
`;

export const NewPanelSwitch = styled.div`
  min-width: 125px;
`;
