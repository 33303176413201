import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import PanelButton from 'components/PanelButton';

// import { Container } from './styles';

interface SensitiveDataModalProps {
  show: boolean;
  sensitiveData: string;
  idTicketStatus: number;
  fetching: boolean;
  close: () => void;
  updateSensitiveData: (values: UpdateSensitiveDataFormValues) => void;
}

interface UpdateSensitiveDataFormValues {
  sensitiveData: string;
}

const SensitiveDataModal: React.FC<SensitiveDataModalProps> = ({
  show,
  sensitiveData,
  idTicketStatus,
  fetching,
  close,
  updateSensitiveData,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('common:sensitiveData')}</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          sensitiveData,
        }}
        validationSchema={Yup.object().shape({
          sensitiveData: Yup.string().required(
            t('pages:ticket.validation.sensitiveData'),
          ),
        })}
        onSubmit={updateSensitiveData}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group as={Row}>
                <Form.Label column sm={3}>
                  {t('common:sensitiveData')}
                </Form.Label>
                <Col sm={9}>
                  {sensitiveData ? (
                    <Form.Control
                      plaintext
                      readOnly
                      defaultValue={sensitiveData}
                    />
                  ) : (
                    <Form.Control
                      plaintext
                      readOnly
                      className="text-muted"
                      defaultValue={t('pages:ticket.noSensitiveData')}
                    />
                  )}
                </Col>
              </Form.Group>

              {idTicketStatus !== 4 && (
                <Form.Group as={Row} controlId="newTicket.newSensitiveData">
                  <Form.Label column sm={3} className="required">
                    {t('pages:ticket.newSensitiveData')}
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Control
                      name="sensitiveData"
                      type="text"
                      value={props.values.sensitiveData}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.sensitiveData &&
                        !!props.errors.sensitiveData
                      }
                      disabled={props.isSubmitting || idTicketStatus === 4}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.sensitiveData}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
            </Modal.Body>

            <Modal.Footer>
              {idTicketStatus !== 4 && (
                <PanelButton
                  type="submit"
                  variant="primary"
                  disabled={fetching}
                >
                  {t('common:save')}
                </PanelButton>
              )}
              <PanelButton variant="secondary" onClick={close}>
                {t('common:close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default SensitiveDataModal;
