import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaCloud } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { Container } from './styles';

type OnOffOption = 'On' | 'Off';

type PhpIniItemResponse = {
  output_compression: OnOffOption;
  allow_url_fopen: OnOffOption;
  allow_call_time_pass_reference: OnOffOption;
  max_execution_time: string;
  memory_limit: string;
};

interface ContainerResponse {
  data: {
    phpIni: PhpIniItemResponse;
  };
}

interface EditContainerResponse {
  data: {
    phpIni: PhpIniItemResponse;
  };
}

interface PhpIni {
  outputCompression: OnOffOption;
  allowUrlFopen: OnOffOption;
  allowCallTimePassReference: OnOffOption;
  maxExecutionTime: number;
  memoryLimit: number;
}

interface EditPhpSettingsFormValues {
  outputCompression: OnOffOption;
  allowUrlFopen: OnOffOption;
  allowCallTimePassReference: OnOffOption;
  maxExecutionTime: number;
  memoryLimit: number;
}

const PhpSettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [phpIni, setPhpIni] = useState<PhpIni>({
    outputCompression: 'Off',
    allowUrlFopen: 'Off',
    allowCallTimePassReference: 'Off',
    maxExecutionTime: 100,
    memoryLimit: 8,
  });

  const formatPhpIni = useCallback(
    ({
      allow_call_time_pass_reference,
      allow_url_fopen,
      max_execution_time,
      memory_limit,
      output_compression,
    }: PhpIniItemResponse) => ({
      outputCompression: output_compression,
      allowUrlFopen: allow_url_fopen,
      allowCallTimePassReference: allow_call_time_pass_reference,
      maxExecutionTime: parseInt(max_execution_time, 10),
      memoryLimit: parseInt(memory_limit, 10),
    }),

    [],
  );
  useEffect(() => {
    async function loadContainer() {
      try {
        setError(false);
        setLoading(true);

        const containerResponse = await api.get<ContainerResponse>(
          `painel/v1/site/${idSite}/container`,
        );

        const formattedPhpIni = formatPhpIni(
          containerResponse.data.data.phpIni,
        );

        setPhpIni(formattedPhpIni);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadContainer();
  }, [formatPhpIni, idSite]);

  const editContainer = useCallback(
    async (values: EditPhpSettingsFormValues) => {
      try {
        const {
          outputCompression,
          allowUrlFopen,
          allowCallTimePassReference,
          maxExecutionTime,
          memoryLimit,
        } = values;

        const containerResponse = await api.put<EditContainerResponse>(
          `painel/v1/site/${idSite}/container`,
          {
            phpini: {
              output_compression: outputCompression,
              allow_url_fopen: allowUrlFopen,
              allow_call_time_pass_reference: allowCallTimePassReference,
              max_execution_time: maxExecutionTime,
              memory_limit: memoryLimit,
            },
          },
        );

        const formattedPhpIni = formatPhpIni(
          containerResponse.data.data.phpIni,
        );

        setPhpIni(formattedPhpIni);

        toast.fire(t('pages:phpSettings.successSettings'));
      } catch (err) {
        swal.fire({
          title: t('pages:phpSettings.failSettings'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [formatPhpIni, idSite, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }
  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:phpSettings.title')}
          description={t('pages:phpSettings.description')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-php/"
        />
        <Formik
          enableReinitialize
          initialValues={{
            outputCompression: phpIni.outputCompression,
            allowUrlFopen: phpIni.allowUrlFopen,
            allowCallTimePassReference: phpIni.allowCallTimePassReference,
            maxExecutionTime: phpIni.maxExecutionTime,
            memoryLimit: phpIni.memoryLimit,
          }}
          validationSchema={Yup.object().shape({
            outputCompression: Yup.string().required(
              t('pages:phpSettings.validation.informValidRadio'),
            ),
            allowUrlFopen: Yup.string().required(
              t('pages:phpSettings.validation.informValidRadio'),
            ),
            allowCallTimePassReference: Yup.string().required(
              t('pages:phpSettings.validation.informValidRadio'),
            ),
            maxExecutionTime: Yup.number()
              .min(
                100,
                t('pages:phpSettings.validation.sizeMinMaxExecutionTime'),
              )
              .max(
                1000,
                t('pages:phpSettings.validation.sizeMaxMaxExecutionTime'),
              )
              .required(t('pages:phpSettings.validation.informValidNumber')),
            memoryLimit: Yup.number()
              .min(8, t('pages:phpSettings.validation.sizeMinMemoryLimit'))
              .required(t('pages:phpSettings.validation.informValidNumber')),
          })}
          onSubmit={editContainer}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:phpSettings.basicSettings')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:phpSettings.outputCompression')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="outputCompression-true"
                      name="outputCompression"
                      label={t('common:label.active')}
                      value="On"
                      checked={props.values.outputCompression === 'On'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.outputCompression &&
                        !!props.errors.outputCompression
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="outputCompression-false"
                      name="outputCompression"
                      label={t('common:label.inactive')}
                      value="Off"
                      checked={props.values.outputCompression === 'Off'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.outputCompression &&
                        !!props.errors.outputCompression
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:phpSettings.outputCompressionInfo')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.outputCompression}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:phpSettings.allowUrlFopen')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="allowUrlFopen-true"
                      name="allowUrlFopen"
                      label={t('common:label.active')}
                      value="On"
                      checked={props.values.allowUrlFopen === 'On'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.allowUrlFopen &&
                        !!props.errors.allowUrlFopen
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="allowUrlFopen-false"
                      name="allowUrlFopen"
                      label={t('common:label.inactive')}
                      value="Off"
                      checked={props.values.allowUrlFopen === 'Off'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.allowUrlFopen &&
                        !!props.errors.allowUrlFopen
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:phpSettings.allowUrlFopenInfo')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.allowUrlFopen}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:phpSettings.allowCallTimePassReference')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="allowCallTimePassReference-true"
                      name="allowCallTimePassReference"
                      label={t('common:active')}
                      value="On"
                      checked={props.values.allowCallTimePassReference === 'On'}
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.allowCallTimePassReference &&
                        !!props.errors.allowCallTimePassReference
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Check
                      inline
                      custom
                      type="radio"
                      id="allowCallTimePassReference-false"
                      name="allowCallTimePassReference"
                      label={t('common:label.inactive')}
                      value="Off"
                      checked={
                        props.values.allowCallTimePassReference === 'Off'
                      }
                      onChange={props.handleChange}
                      isInvalid={
                        !!props.touched.allowCallTimePassReference &&
                        !!props.errors.allowCallTimePassReference
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:phpSettings.allowCallTimePassReferenceInfo')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.allowCallTimePassReference}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:phpSettings.maxExecutionTime')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      id="maxExecutionTime"
                      name="maxExecutionTime"
                      placeholder={t(
                        'pages:phpSettings.maxExecutionTimePlaceholder',
                      )}
                      value={props.values.maxExecutionTime}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.maxExecutionTime &&
                        !!props.errors.maxExecutionTime
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:phpSettings.maxExecutionTimeInfo')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.maxExecutionTime}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:phpSettings.phpMemory')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:phpSettings.memoryLimit')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="number"
                      id="memoryLimit"
                      name="memoryLimit"
                      placeholder={t(
                        'pages:phpSettings.memoryLimitPlaceholder',
                      )}
                      value={props.values.memoryLimit}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.memoryLimit &&
                        !!props.errors.memoryLimit
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:phpSettings.memoryLimitInfo')}
                      <br />
                      {t('pages:phpSettings.memoryLimitTip')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.memoryLimit}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default PhpSettings;
