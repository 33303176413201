import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import PanelButton from 'components/PanelButton';

interface ModalNewFolderProps {
  site: string;
  currDir: string;
  loadCount: number;
  setLoadCount: (value: number) => void;
  showModalNewFolder: boolean;
  setShowModalNewFolder: (value: boolean) => void;
  setLoading: (value: boolean) => void;
}

interface RequestFormValues {
  directoryName: string;
}

const ModalNewFolder: React.FC<ModalNewFolderProps> = ({
  site,
  currDir,
  loadCount,
  setLoadCount,
  showModalNewFolder,
  setShowModalNewFolder,
  setLoading,
}) => {
  const { t } = useTranslation();

  const submitFormNewFolder = useCallback(
    async (values: RequestFormValues, formikHelpers) => {
      try {
        setLoading(true);

        await api.post(`https://a.apihn.co/ftp/v1/dir`, {
          site,
          name: values.directoryName,
          caminho: `${currDir}/${values.directoryName}`,
        });

        setLoadCount(loadCount + 1);

        toast.fire(t('pages:fileManager:newFolderSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:fileManager:newFolderError'),
          html: err.response.data.error_description,
        });
      } finally {
        formikHelpers.resetForm();
        setShowModalNewFolder(false);
        setLoading(false);
      }
    },
    [
      currDir,
      loadCount,
      site,
      t,
      setLoading,
      setLoadCount,
      setShowModalNewFolder,
    ],
  );

  return (
    <Modal
      show={showModalNewFolder}
      onHide={() => setShowModalNewFolder(false)}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:fileManager.newFolder')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{ directoryName: '' }}
        validationSchema={Yup.object().shape({
          directoryName: Yup.string().required(
            t('pages:fileManager.validation.directoryName'),
          ),
        })}
        onSubmit={async (values, formikHelpers) =>
          submitFormNewFolder(values, formikHelpers)
        }
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group as={Row} controlId="directoryName">
                <Form.Label column sm="2" className="required">
                  {t('common:label.name')}
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="directoryName"
                    value={props.values.directoryName}
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    isInvalid={
                      !!props.touched.directoryName &&
                      !!props.errors.directoryName
                    }
                    disabled={props.isSubmitting}
                  />
                  <Form.Control.Feedback type="invalid">
                    {props.errors.directoryName}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <PanelButton
                type="submit"
                disabled={props.isSubmitting || !props.isValid}
              >
                {props.isSubmitting ? t('common:sending') : t('common:send')}
              </PanelButton>{' '}
              <PanelButton
                variant="secondary"
                onClick={() => setShowModalNewFolder(false)}
              >
                {t('common:close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalNewFolder;
