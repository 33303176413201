import React, { useCallback, useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { Formik } from 'formik';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import PageTitle from 'components/PageTitle';
import FormSubtitle from 'components/FormSubtitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import { StoreState } from 'store/createStore';

import { Container, SuspensionInfo } from './styles';

interface Suspension {
  congelamento: boolean;
  diasAtraso: number;
  suspenso: boolean;
  suspensoAte: string;
  diasUltimaSuspensao: number;
  podeSuspender: boolean;
}

interface SuspensionResponse {
  data: Suspension;
}

const SuspensionPause: React.FC = () => {
  const { t } = useTranslation();

  const site = useSelector((state: StoreState) => state.site.info);
  const conta = useSelector((state: StoreState) => state.client.info);
  const [updateSuspension, setUpdateSuspension] = useState<Boolean>(false);

  const [suspension, setSuspension] = useState<Suspension>({
    congelamento: false,
    diasAtraso: 0,
    suspenso: false,
    suspensoAte: '0000-00-00',
    diasUltimaSuspensao: 0,
    podeSuspender: true,
  });

  useEffect(() => {
    async function getSuspension() {
      const suspensionResponse = await api.get<SuspensionResponse>(
        `/financeiro/v1/congelamento/suspensao`,
        {
          params: {
            idCliente: conta.idCliente,
            ...(conta.tipoCobranca === 'MULTIPLA' &&
              conta.contaComSite && { site: site.site }),
          },
        },
      );
      setSuspension(suspensionResponse.data.data);
    }
    getSuspension();
  }, [
    conta.contaComSite,
    conta.idCliente,
    conta.tipoCobranca,
    site,
    updateSuspension,
  ]);

  const setSuspensionPause = useCallback(async () => {
    try {
      await api.post(`/financeiro/v1/congelamento/suspensao`, {
        idCliente: conta.idCliente,
        site: site.site,
      });
      toast.fire('pages:suspensionPause.suspensionPauseSuccess');
    } catch (err) {
      swal.fire({
        title: t('pages:suspensionPause.suspensionPauseFailure'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdateSuspension(!updateSuspension);
    }
  }, [conta.idCliente, site, t, updateSuspension]);

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          title={t('pages:suspensionPause.title')}
          description={t('pages:suspensionPause.description')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Suspensao_de_Congelamento"
        />
        {suspension.podeSuspender &&
        suspension.congelamento &&
        !suspension.suspenso &&
        suspension.diasAtraso <= 20 &&
        (suspension.diasUltimaSuspensao >= 30 ||
          suspension.diasUltimaSuspensao === 0) ? (
          <Formik
            enableReinitialize
            initialValues={{
              termToConclude: 'FALSE',
            }}
            validationSchema={Yup.object().shape({
              termToConclude: Yup.string().required(
                t('pages:suspensionPause.validation.informValidRadio'),
              ),
            })}
            onSubmit={setSuspensionPause}
          >
            {props => (
              <Form onSubmit={props.handleSubmit}>
                <Card.Body>
                  <FormSubtitle
                    subTitle={t('pages:suspensionPause.suspensionPauseTerms')}
                  />
                  <SuspensionInfo className="text-danger">
                    <Trans>{t('pages:suspensionPause.suspensionInfo')}</Trans>
                  </SuspensionInfo>

                  <FormSubtitle
                    subTitle={t('pages:suspensionPause.formSubtitle')}
                  />

                  <Form.Group as={Row} className="mt-4">
                    <Form.Label column sm={2}>
                      {t('common:user')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        defaultValue={conta.email1}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Col sm={{ span: 10, offset: 2 }}>
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="termToConclude-yes"
                        name="termToConclude"
                        label={t('common:yes')}
                        value="TRUE"
                        checked={props.values.termToConclude === 'TRUE'}
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.termToConclude &&
                          !!props.errors.termToConclude
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Check
                        inline
                        custom
                        type="radio"
                        id="termToConclude-no"
                        name="termToConclude"
                        label={t('common:no')}
                        value="FALSE"
                        checked={props.values.termToConclude === 'FALSE'}
                        onChange={props.handleChange}
                        isInvalid={
                          !!props.touched.termToConclude &&
                          !!props.errors.termToConclude
                        }
                        disabled={props.isSubmitting}
                      />
                      <Form.Text className="text-muted">
                        {t('pages:suspensionPause.termToConcludeInfo')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {props.errors.termToConclude}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Card.Body>
                <div className="border-top pt-2 pb-2 pl-3">
                  <PanelButton
                    type="submit"
                    disabled={
                      props.isSubmitting ||
                      !props.isValid ||
                      props.values.termToConclude === 'FALSE'
                    }
                  >
                    {props.isSubmitting
                      ? t('pages:suspensionPause.suspending')
                      : t('pages:suspensionPause.suspend')}
                  </PanelButton>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <Card.Body>
            {suspension.diasAtraso > 20 && (
              <SuspensionInfo className="text-danger">
                {t('pages:suspensionPause.daysWarning', { site: site.site })}
              </SuspensionInfo>
            )}
            {suspension.diasUltimaSuspensao < 30 &&
              suspension.diasUltimaSuspensao > 0 && (
                <SuspensionInfo className="text-danger">
                  {t('pages:suspensionPause.usedWarning', { site: site.site })}
                </SuspensionInfo>
              )}
            {(!suspension.congelamento || suspension.suspenso) && (
              <SuspensionInfo className="text-muted">
                {t('pages:suspensionPause.notSuspended', { site: site.site })}
              </SuspensionInfo>
            )}
          </Card.Body>
        )}
      </FormWrapper>
    </Container>
  );
};

export default SuspensionPause;
