import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

import hashIds from 'services/hashIds';

interface EntityActionsProps {
  selectedCount: number;
  selectedRows: { idEntidadeFinanceira: number }[];
  loading: boolean;
  selectEntity: () => void;
  deleteEntity: () => void;
}

const EntityActions: React.FC<EntityActionsProps> = ({
  selectedCount,
  loading,
  selectedRows,
  selectEntity,
  deleteEntity,
}) => {
  const { t } = useTranslation();
  const entityHashId = hashIds.encode(selectedRows[0].idEntidadeFinanceira);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/financeiro/entidades/${entityHashId}`}
            size="sm"
            className="mx-1"
            disabled={loading}
          >
            {t('common:edit')}
          </TableButton>
          <TableButton
            className="mx-1"
            size="sm"
            disabled={loading}
            onClick={selectEntity}
          >
            {t('common:select')}
          </TableButton>
        </>
      )}
      <TableButton
        className="mx-1"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={deleteEntity}
      >
        {t('common:delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default EntityActions;
