import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch, FiX } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import { PiUserSwitch } from 'react-icons/pi';
import {
  FormControl,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import filter from 'utils/filter';

import PanelButton from 'components/PanelButton';

import swal from 'services/swal';

import {
  Container,
  SearchButton,
  SitesList,
  CloseButton,
  SelectSiteButton,
  FavoriteSiteButton,
  FavoriteSiteContainer,
  NotFound,
  SitesListScrollBar,
  SelectAdditionalSiteButton,
  AdditionalSiteContainer,
} from './styles';

interface Site {
  idSite: number;
  site: string;
  plano: string;
}

interface AdditionalAccess {
  email: string;
  chave: string;
}

interface SiteSelectorProps {
  open: boolean;
  loading: boolean;
  updating: boolean;
  sites: Site[];
  additionalAccess: AdditionalAccess[];
  idFavoriteSite: number;
  selectSite: (idSite: number) => void;
  selectAdditionalSite: (key: string) => void;
  handleFavoriteSite: (idSite: number) => void;
  handleSiteSelector: () => void;
}

const SiteSelector: React.FC<SiteSelectorProps> = ({
  open,
  sites,
  additionalAccess,
  loading,
  updating,
  idFavoriteSite,
  selectSite,
  selectAdditionalSite,
  handleFavoriteSite,
  handleSiteSelector,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [showAdditionalUserModal, setShowAdditionalUserModal] = useState(false);
  const [selectedAdditionalAccess, setSelectedAdditionalAccess] = useState({
    key: '',
  });

  const [loadingAdditionalUserUrl, setLoadingAdditionalUserUrl] = useState(
    false,
  );

  const filteredSites = filter(sites, ['site'], search);
  const filteredAdditionalAccess = filter(additionalAccess, ['email'], search);

  async function switchDashboard() {
    try {
      setLoadingAdditionalUserUrl(true);

      await selectAdditionalSite(selectedAdditionalAccess.key);
    } catch (error) {
      if (error instanceof Error) {
        swal.fire({
          title: 'Não foi possível acessar o painel',
          html: error.message,
        });
      }
    } finally {
      setLoadingAdditionalUserUrl(false);
      setShowAdditionalUserModal(false);
    }
  }

  return (
    <Container className={open ? 'open' : ''}>
      <div className="p-4 border-bottom">
        <CloseButton className="close-button" onClick={handleSiteSelector}>
          <FiX size={18} className="ml-1" />
        </CloseButton>
        <InputGroup className="mb-2">
          <FormControl
            value={search}
            onChange={event => setSearch(event.target.value)}
            placeholder="Filtrar sites"
          />
          <InputGroup.Append>
            <SearchButton>
              <FiSearch />
            </SearchButton>
          </InputGroup.Append>
        </InputGroup>
      </div>
      <SitesListScrollBar>
        <SitesList>
          {filteredSites.map(site => (
            <li
              key={site.idSite}
              className="border-bottom d-flex d-align-center"
            >
              <SelectSiteButton
                type="button"
                disabled={loading}
                onClick={() => {
                  handleSiteSelector();
                  selectSite(site.idSite);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <div>{site.site}</div>
                    <small className="text-muted">{site.plano}</small>
                  </div>
                  <div />
                </div>
              </SelectSiteButton>
              <FavoriteSiteContainer>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-${site.site}`}>
                      {t('common:favoriteSite')}
                    </Tooltip>
                  }
                >
                  <FavoriteSiteButton
                    type="button"
                    disabled={updating}
                    onClick={() => handleFavoriteSite(site.idSite)}
                    className={idFavoriteSite === site.idSite ? 'favorite' : ''}
                  >
                    <FaStar fontSize={18} />
                  </FavoriteSiteButton>
                </OverlayTrigger>
              </FavoriteSiteContainer>
            </li>
          ))}

          {filteredAdditionalAccess.map(additionalAccessItem => (
            <li
              key={additionalAccessItem.chave}
              className="border-bottom d-flex d-align-center"
            >
              <SelectAdditionalSiteButton
                type="button"
                disabled={loading}
                onClick={() => {
                  setSelectedAdditionalAccess({
                    key: additionalAccessItem.chave,
                  });
                  setShowAdditionalUserModal(true);
                }}
              >
                <div className="d-flex justify-content-between">
                  <div>
                    <div>{additionalAccessItem.email}</div>
                    <small className="text-muted">
                      Acessar como usuário adicional
                    </small>
                  </div>
                  <div />
                </div>
                <AdditionalSiteContainer>
                  <PiUserSwitch fontSize={22} />
                </AdditionalSiteContainer>
              </SelectAdditionalSiteButton>
            </li>
          ))}
        </SitesList>
      </SitesListScrollBar>
      {filteredSites.length === 0 && filteredAdditionalAccess.length === 0 && (
        <NotFound>Sem sites</NotFound>
      )}

      <Modal
        centered
        show={showAdditionalUserModal}
        onHide={() => setShowAdditionalUserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acessar painel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirme para sair do seu painel de controle e acessar o painel de
          controle deste usuário.
        </Modal.Body>
        <Modal.Footer>
          <PanelButton
            variant="secondary"
            onClick={() => setShowAdditionalUserModal(false)}
          >
            {t('common:close')}
          </PanelButton>
          <PanelButton
            variant="primary"
            disabled={loadingAdditionalUserUrl}
            onClick={() => {
              switchDashboard();
            }}
          >
            {loadingAdditionalUserUrl ? 'Acessando...' : 'Confirmar'}
          </PanelButton>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SiteSelector;
