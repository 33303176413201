import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';

export const Item = styled(ListGroup.Item)`
  font-size: 11px;
  text-align: center;

  width: 100%;
  padding: 0.25rem 0.75rem;

  &.active {
    color: white;

    background-color: var(--color-blue);
    border-color: var(--color-blue);
  }

  &:focus {
    color: white;
    outline: none;
    background-color: var(--color-blue);
    border-color: var(--color-blue);
  }
`;
