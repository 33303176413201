import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaHome } from 'react-icons/fa';

import { StoreState } from 'store/createStore';

import News from 'components/News';
import Loading from 'components/Loading';
import QuickAccess from 'components/QuickAccess';
import PageTitle from 'components/PageTitle';
import Error from 'components/Error';

import useNotices from 'hooks/useNotices';
import useAutomaticNotices from 'hooks/useAutomaticNotices';
import useHostnetBlogPosts from 'hooks/useHostnetBlogPosts';
import useClient from 'hooks/useClient';

import Tip from './components/Tip';
import SiteResources from './components/SiteResources';
import PaymentInfo from './components/PaymentInfo';
import Notice from './components/Notice';
import SiteConfig from './components/SiteConfig';
import BloquedAccess from './components/BloquedAccess';

import { Container } from './styles';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { client, loading: loadingClient } = useClient();

  const { info } = useSelector((state: StoreState) => state.site);
  const idSite = contaComSite ? info.idSite : undefined;

  const { notices, error: noticesError } = useNotices(idSite);

  const {
    automaticNotices,
    loading: loadingAutomaticNotices,
    error: autoNoticesError,
  } = useAutomaticNotices(idSite);

  const { posts, isLoading: loadingPosts } = useHostnetBlogPosts();

  if (noticesError || autoNoticesError) {
    return <Error />;
  }

  if (loadingClient) {
    return <Loading />;
  }

  if (client.painelBloqueado) {
    return (
      <Container>
        <PageTitle
          icon={<FaHome color="#FFFFFF" size={24} />}
          title={t('titles:home.title')}
          description={t('titles:home.description')}
        />
        <BloquedAccess />
      </Container>
    );
  }

  return (
    <Container>
      <PageTitle
        icon={<FaHome color="#FFFFFF" size={24} />}
        title={t('titles:home.title')}
        description={t('titles:home.description')}
      />

      {notices.map(notice => (
        <Notice
          key={`notice-${notice.id}`}
          id={notice.id}
          title={notice.title}
          message={notice.message}
          level={notice.level}
        />
      ))}

      {automaticNotices.map(notice => (
        <Notice
          key={`automatic-notice-${notice.id}`}
          id={notice.id}
          title={notice.title}
          message={notice.message}
          code={notice.code}
          redTitle={['validacao.contato.registro.pendente'].includes(
            notice.code,
          )}
        />
      ))}

      <Tip />

      {loadingAutomaticNotices && <Loading />}

      <div className="my-4">{contaComSite && <SiteResources />}</div>

      <div>{contaComSite && <PaymentInfo />}</div>

      <Row className="my-4">
        <Col md={8} className="mb-4">
          <News posts={posts} loading={loadingPosts} />
        </Col>
        <Col md={4}>
          <QuickAccess />
        </Col>
      </Row>

      <div>{contaComSite && <SiteConfig />}</div>
    </Container>
  );
};

export default Home;
