import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import { Formik } from 'formik';

import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useEmails from 'hooks/useEmails';

import { Container, StyledSpan } from './styles';

type TypeOption = 'html' | 'mail' | 'pear';
type FormatOption = 'html' | 'txt';

type ScriptItemResponse = {
  email: string;
  senha: string;
  tipo: TypeOption;
  formato: FormatOption;
  script: string;
};

interface Script {
  email: string;
  senha: string;
  tipo: TypeOption;
  formato: FormatOption;
  script: string;
}

interface NewScriptResponse {
  data: ScriptItemResponse;
}

interface NewScriptFormValues {
  email: string;
  senha: string;
  tipo: TypeOption;
  formato: FormatOption;
  script: string;
}

const ScriptSettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { idCliente } = useSelector((state: StoreState) => state.client.info);

  const { tecnologia } = useSelector((state: StoreState) => state.site.info);

  const { emails, loading } = useEmails(idSite);

  const [newScript, setNewScript] = useState<Script>({
    email: '',
    senha: '',
    tipo: 'html',
    formato: 'html',
    script: '',
  });

  const formatScript = useCallback(
    ({ email, senha, tipo, formato, script }: ScriptItemResponse) => ({
      email,
      senha,
      tipo,
      formato,
      script,
    }),

    [],
  );

  const [error] = useState(false);

  async function listScript(values: NewScriptFormValues) {
    try {
      const { email, senha, tipo, formato } = values;

      const scriptResponse = await api.get<NewScriptResponse>(
        `painel/v1/email/script`,
        {
          params: {
            idCliente,
            site: email.replace(/.*@/, ''),
            email,
            senha,
            tipo,
            formato,
          },
        },
      );

      const formattedScript = formatScript(scriptResponse.data.data);

      setNewScript(formattedScript);

      toast.fire(t('pages:emailScript.successSettings'));
    } catch (err) {
      swal.fire({
        title: t('pages:emailScript.failSettings'),
        html: err.response && err.response.data.error_description,
      });
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:emailScript.title')}
          description={t('pages:emailScript.description')}
          helpContent={
            <div>
              <p>{t('pages:emailScript.helpContent.p1')}</p>
              <p>{t('pages:emailScript.helpContent.p2')}</p>
              <p>{t('pages:emailScript.helpContent.p3')}</p>
              <p>{t('pages:emailScript.helpContent.p4')}</p>
            </div>
          }
          helpLinks={[
            {
              text: 'Saiba mais sobre script de envio de email',
              url: 'https://ajuda.hostnet.com.br/script-de-envio-de-e-mail/',
            },
            {
              text: 'Formulário de contato em PHP',
              url:
                'https://ajuda.hostnet.com.br/formulario-de-contato-em-php-com-arquivo-anexado/',
            },
            {
              text: 'Função mail() do PHP',
              url: 'https://ajuda.hostnet.com.br/funcao-mail-do-php/',
            },
          ]}
        />
        <Formik
          enableReinitialize
          validateOnMount
          initialValues={{
            email: emails.length > 0 ? emails[0].mailBox.replace('=', '@') : '',
            senha: '',
            tipo: 'html',
            formato: 'html',
            script: '',
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('pages:emailScript.validation.informValidRadio')),
            senha: Yup.string().required(
              t('pages:emailScript.validation.informValidPassword'),
            ),
            tipo: Yup.string().required(
              t('pages:emailScript.validation.informValidRadio'),
            ),
            formato: Yup.string().required(
              t('pages:emailScript.validation.informValidRadio'),
            ),
          })}
          onSubmit={listScript}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                {emails.length > 0 && (
                  <div>
                    <div>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:emailScript.type')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="tipo-html"
                            name="tipo"
                            label={t('pages:emailScript.htmlType')}
                            value="html"
                            checked={props.values.tipo === 'html'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.tipo && !!props.errors.tipo
                            }
                            disabled={props.isSubmitting}
                          />
                          {tecnologia === 'PHP' && (
                            <>
                              <Form.Check
                                inline
                                custom
                                type="radio"
                                id="tipo-mail"
                                name="tipo"
                                label={t('pages:emailScript.mailType')}
                                value="mail"
                                checked={props.values.tipo === 'mail'}
                                onChange={props.handleChange}
                                isInvalid={
                                  !!props.touched.tipo && !!props.errors.tipo
                                }
                                disabled={props.isSubmitting}
                              />
                              <Form.Check
                                inline
                                custom
                                type="radio"
                                id="tipo-pear"
                                name="tipo"
                                label={t('pages:emailScript.pearType')}
                                value="pear"
                                checked={props.values.tipo === 'pear'}
                                onChange={props.handleChange}
                                isInvalid={
                                  !!props.touched.tipo && !!props.errors.tipo
                                }
                                disabled={props.isSubmitting}
                              />
                            </>
                          )}
                          <Form.Text className="text-muted">
                            {t('pages:emailScript.infoType')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {props.errors.tipo}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:emailScript.format')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="formato-html"
                            name="formato"
                            label={t('pages:emailScript.htmlFormat')}
                            value="html"
                            checked={props.values.formato === 'html'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.formato && !!props.errors.formato
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="formato-texto"
                            name="formato"
                            label={t('pages:emailScript.textFormat')}
                            value="txt"
                            checked={props.values.formato === 'txt'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.formato && !!props.errors.formato
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Text className="text-muted">
                            {t('pages:emailScript.infoFormat')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {props.errors.formato}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>

                    <Form.Group as={Row} controlId="form-email">
                      <Form.Label column sm={2}>
                        {t('common:label.email')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="email"
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                          isInvalid={
                            !!props.touched.email && !!props.errors.email
                          }
                        >
                          {emails.map(email => (
                            <option
                              key={`${email.mailAddr}@${email.dominio}`}
                              value={`${email.mailAddr}@${email.dominio}`}
                            >
                              {`${email.mailAddr}@${email.dominio}`}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Text className="text-muted">
                          {t('pages:emailScript.infoSender')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.email}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="form-senha">
                      <Form.Label column sm={2}>
                        {t('common:label.password')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          type="password"
                          name="senha"
                          value={props.values.senha}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.senha && !!props.errors.senha
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('pages:emailScript.infoPassword')}
                        </Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.senha}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </div>
                )}
                <div>
                  {emails.length < 1 && (
                    <p>{t('pages:emailScript.noSecuritySettings')}</p>
                  )}
                </div>
              </Card.Body>
              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting
                    ? t('common:requesting')
                    : t('common:request')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      {newScript.script.length > 0 && (
        <FormWrapper>
          <FormHeader
            title={t('pages:emailScript.title')}
            description={t('pages:emailScript.contentDescription')}
            helpContent={
              <div>
                <p>{t('pages:emailScript.helpContent.p5')}</p>
              </div>
            }
            helpLinks={[
              {
                text: 'Saiba mais sobre script de envio de email',
                url: 'https://ajuda.hostnet.com.br/script-de-envio-de-e-mail/',
              },
              {
                text: 'Formulário de contato em PHP',
                url:
                  'https://ajuda.hostnet.com.br/formulario-de-contato-em-php-com-arquivo-anexado/',
              },
              {
                text: 'Função mail() do PHP',
                url: 'https://ajuda.hostnet.com.br/funcao-mail-do-php/',
              },
            ]}
          />
          <Card.Body>
            <StyledSpan>{newScript.script}</StyledSpan>
          </Card.Body>
        </FormWrapper>
      )}
    </Container>
  );
};

export default ScriptSettings;
