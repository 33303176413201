import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { ChartIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface ConsumptionOptionsProps {
  site: string;
  options: Option[];
}

const ConsumptionOptions: React.FC<ConsumptionOptionsProps> = () => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="8"
      text={t('components:sideMenu.consumption')}
      icon={<ChartIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.traffic')}
          to="/trafego-espaco"
        />,
      ]}
    />
  );
};

export default ConsumptionOptions;
