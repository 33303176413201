import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListGroup, Modal } from 'react-bootstrap';

import Loading from 'components/Loading';
import PanelButton from 'components/PanelButton';

interface DnsModalProps {
  show: boolean;
  loading: boolean;
  close: () => void;
  dns: string[];
}

const DnsModal: React.FC<DnsModalProps> = ({ show, close, loading, dns }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={() => close()} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('common:dnsServers')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loading ? (
          <Loading />
        ) : (
          <ListGroup variant="flush">
            {dns.map(value => (
              <ListGroup.Item key={value}>{value.slice(0, -1)}</ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {!loading && dns.length === 0 && <p>{t('common:noDataToList')}</p>}
      </Modal.Body>

      <Modal.Footer>
        <PanelButton variant="primary" onClick={() => close()}>
          {t('common:close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default DnsModal;
