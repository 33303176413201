import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import FormSubtitle from 'components/FormSubtitle';
import PhoneInput from 'components/PhoneInput';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';
import Error from 'components/Error';

import useCountries from 'hooks/useCountries';
import useInternationalDomainContacts from 'hooks/useInternationalDomainContacts';

import toast from 'services/toast';
import swal from 'services/swal';
import history from 'services/history';
import api from 'services/api';
import hashIds from 'services/hashIds';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type InternationalContactEditProps = RouteComponentProps<MatchParams>;

interface EditContactFormValues {
  name: string;
  lastName: string;
  company: string;
  email: string;
  address: string;
  zipCode: string;
  country: string;
  city: string;
  state: string;
  countryCode: string;
  phone: string;
}

const Edit: React.FC<InternationalContactEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idContact] = hashIds.decode(match.params.id);
  const { countries } = useCountries();

  const {
    contacts,
    refetch: refetchContacts,
    fetching,
  } = useInternationalDomainContacts(Number(idContact));

  const [error, setError] = useState(false);

  const [contact, setContact] = useState<typeof contacts[number]>({
    id: 0,
    name: '',
    lastName: '',
    company: '',
    email: '',
    address: '',
    zipCode: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    phone: '',
    validateIdentity: false,
  });

  useEffect(() => {
    if (contacts.length === 0 || idContact === undefined) {
      setError(true);
      return;
    }

    setError(false);

    setContact(contacts[0]);
  }, [contacts, idContact]);

  async function editContact(values: EditContactFormValues) {
    try {
      await api.put(`enom/v1/contato/${idContact}`, {
        email: values.email,
        nome: values.name,
        sobreNome: values.lastName,
        empresa: values.company,
        endereco: values.address,
        cep: values.zipCode.replace(/\D/g, ''),
        pais: values.country,
        cidade: values.city,
        estado: values.state,
        telefone: `${values.countryCode}.${values.phone.replace(/\D/g, '')}`,
      });

      toast.fire(t('pages:internationalDomainContactEdit.success'));

      refetchContacts();

      history.push(`/registro/contatos`);
    } catch (err) {
      swal.fire({
        title: t('pages:internationalDomainContactEdit.fail'),
        html:
          (err.response && err.response.data.error_description) || err.message,
      });
    }
  }

  if (fetching) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:internationalDomainContactEdit.title')}
          description={t('pages:internationalDomainContactEdit.description')}
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            name: contact.name,
            lastName: contact.lastName,
            company: contact.company,
            email: contact.email,
            address: contact.address,
            zipCode: contact.zipCode,
            country: contact.country,
            city: contact.city,
            state: contact.state,
            countryCode: contact.countryCode,
            phone: contact.phone,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .matches(
                /^[a-zA-Z][a-zA-Z.\s-]*$/,
                t('validations:invalidFormat'),
              )
              .required(t('validations:requiredField')),
            lastName: Yup.string()
              .matches(
                /^[a-zA-Z][a-zA-Z.\s-]*$/,
                t('validations:invalidFormat'),
              )
              .required(t('validations:requiredField')),
            company: Yup.string().matches(
              /^[a-zA-Z0-9.\s-]*$/,
              t('validations:invalidFormat'),
            ),
            email: Yup.string()
              .email(t('validations:invalidEmail'))
              .required(t('validations:requiredField')),
            address: Yup.string().required(t('validations:requiredField')),
            zipCode: Yup.string().required(t('validations:requiredField')),
            country: Yup.string().required(t('validations:requiredField')),
            countryCode: Yup.number()
              .required(t('validations:requiredField'))
              .typeError(t('validations:invalidNumber')),
            city: Yup.string().required(t('validations:requiredField')),
            state: Yup.string().required(t('validations:requiredField')),
            phone: Yup.string()
              .required(t('validations:requiredField'))
              .test('len', t('validations:phone'), val => {
                return !!val && val.replace(/[^\d]/g, '').length > 9;
              }),
          })}
          onSubmit={editContact}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t(
                    'pages:internationalDomainContactEdit.contactDetails',
                  )}
                />

                <Form.Group as={Row} controlId="contact.name">
                  <Form.Label column sm={2} className="required">
                    {t('common:name')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="name"
                      value={props.values.name}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.name && !!props.errors.name}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.name}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.lastName">
                  <Form.Label column sm={2} className="required">
                    {t('common:lastName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={props.values.lastName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.lastName && !!props.errors.lastName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.lastName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.company">
                  <Form.Label column sm={2}>
                    {t('common:company')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="company"
                      value={props.values.company}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.company && !!props.errors.company
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.company}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.email">
                  <Form.Label column sm={2} className="required">
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="email"
                      value={props.values.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.email && !!props.errors.email}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:internationalDomainContactEdit.emailTip')}.
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.email}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.address">
                  <Form.Label column sm={2} className="required">
                    {t('common:address')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="address"
                      value={props.values.address}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.address && !!props.errors.address
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.address}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.zipCode">
                  <Form.Label column sm={2} className="required">
                    {t('common:zipCode')}
                  </Form.Label>
                  <Col sm={10}>
                    <InputMask
                      name="zipCode"
                      mask="99999-999"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.zipCode}
                      disabled={props.isSubmitting}
                      className={`form-control ${
                        !!props.touched.zipCode &&
                        !!props.errors.zipCode &&
                        'is-invalid'
                      }`}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.zipCode}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.country">
                  <Form.Label column sm={2} className="required">
                    {t('common:country')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="country"
                      value={props.values.country}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.country && !!props.errors.country
                      }
                      disabled={props.isSubmitting}
                    >
                      {countries.map(country => (
                        <option key={country.idPais} value={country.paisEn}>
                          {country.pais}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.country}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.city">
                  <Form.Label column sm={2} className="required">
                    {t('common:city')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="city"
                      value={props.values.city}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.city && !!props.errors.city}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.state">
                  <Form.Label column sm={2} className="required">
                    {t('common:state')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="state"
                      value={props.values.state}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={!!props.touched.state && !!props.errors.state}
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.state}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="contact.phone">
                  <Form.Label column sm={2} className="required">
                    {t('common:phone')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} className="ddi-container">
                        <Form.Control
                          type="string"
                          placeholder="DDI"
                          name="countryCode"
                          maxLength={3}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.countryCode &&
                            !!props.errors.countryCode
                          }
                          value={props.values.countryCode}
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">DDI</Form.Text>
                        <Form.Control.Feedback type="invalid">
                          {props.errors.countryCode}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col}>
                        <PhoneInput
                          name="phone"
                          value={props.values.phone}
                          handleChange={props.handleChange}
                          handleBlur={props.handleBlur}
                          isInvalid={
                            !!props.touched.phone && !!props.errors.phone
                          }
                          disabled={props.isSubmitting}
                        />
                        <Form.Text className="text-muted">
                          {t('common:number')}
                        </Form.Text>

                        <Form.Control.Feedback type="invalid">
                          {props.errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/contatos"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
