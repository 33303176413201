import React from 'react';

import { OptionLink, Text, ExternalIcon } from './styles';

interface SideMenuLinkProps {
  text: string;
  to: string;
  icon: React.ReactNode;
  notifications?: number;
}

const SideMenuExternalLink: React.FC<SideMenuLinkProps> = ({
  text,
  to,
  icon,
}) => {
  return (
    <OptionLink to={{ pathname: to }} target="_blank">
      {icon}
      <Text>{text}</Text>
      <div>
        <ExternalIcon />
      </div>
    </OptionLink>
  );
};

export default SideMenuExternalLink;
