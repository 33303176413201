import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

// import { Container } from './styles';

interface InternationalDomainActionsProps {
  selectedCount: number;
  selectedRows: {
    id: number;
    promocao: boolean;
    status: string;
  }[];
}

const InternationalDomainActions: React.FC<InternationalDomainActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  if (selectedCount !== 1) {
    return <div />;
  }

  const selectedDomain = selectedRows[0];

  if (selectedDomain.status === 'ERRO') {
    return <div />;
  }

  return (
    <TableActionsWrapper loading={false}>
      {selectedDomain.promocao && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-internacional-comprar/${hashIds.encode(
              selectedDomain.id,
            )}`}
            className="mr-2"
          >
            {t('common:buy')}
          </TableButton>
        </>
      )}

      {!selectedDomain.promocao && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-internacional-dns/${hashIds.encode(
              selectedDomain.id,
            )}`}
            className="mr-2"
          >
            {t('common:dns')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-internacional-configuracao/${hashIds.encode(
              selectedDomain.id,
            )}`}
            className="mr-2"
          >
            {t('common:settings')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-internacional-renovar/${hashIds.encode(
              selectedDomain.id,
            )}`}
            className="mr-2"
          >
            {t('pages:domains.addYears')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default InternationalDomainActions;
