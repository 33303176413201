import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import TableButton from 'components/TableButton';
import TableHeader from 'components/TableHeader';
import TableNoData from 'components/TableNoData';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import Error from 'components/Error';

import api from 'services/api';
import swal from 'services/swal';
import toast from 'services/toast';

import filter from 'utils/filter';

import { StoreState } from 'store/createStore';

import EntityActions from './components/EntityActions';

import { Container } from './styles';

interface ClienteItemResponse {
  idCliente: number;
  idEntidadeFinanceira: number;
}

interface SiteItemResponse {
  idSite: number;
  idEntidadeFinanceira: number;
  site: string;
}

interface EntityItemResponse {
  idEntidadeFinanceira: number;
  nome: string;
  cpfCnpj: string;
  cliente: ClienteItemResponse[];
  sites: SiteItemResponse[];
}

interface EntitiesResponse {
  data: EntityItemResponse[];
}

interface SelectEntityResponse {
  data: EntityItemResponse[];
}

interface DeleteEntitiesResponse {
  data: EntityItemResponse[];
}

interface Entity {
  idEntidadeFinanceira: number;
  nome: string;
  cpfCnpj: string;
  selected: boolean;
  default: boolean;
}

interface SelectedRowsInfo {
  selectedCount: number;
  selectedRows: Entity[];
}

const List: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [entities, setEntities] = useState<Entity[]>([]);
  const [filterText, setFilterText] = useState('');
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>([]);

  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRowsInfo, setSelectedRowsInfo] = useState<SelectedRowsInfo>({
    selectedCount: 0,
    selectedRows: [],
  });

  const site = useSelector((state: StoreState) => state.site.info);
  const client = useSelector((state: StoreState) => state.client.info);

  useEffect(() => {
    const filtered = filter(entities, ['nome', 'cpfCnpj'], filterText);
    setFilteredEntities(filtered);
  }, [entities, filterText]);

  const getSelectedEntityByClient = useCallback(
    (entity: EntityItemResponse) => {
      const findClient = entity.cliente.filter(
        searchClient => searchClient.idCliente === client.idCliente,
      );

      return findClient.length !== 0;
    },
    [client.idCliente],
  );

  const getSelectedEntityBySite = useCallback(
    (entity: EntityItemResponse) => {
      const findSite = entity.sites.filter(
        searchSite => searchSite.idSite === site.idSite,
      );

      return findSite.length !== 0;
    },
    [site.idSite],
  );

  const formatEntities = useCallback(
    (entitiesResponse: EntityItemResponse[]) => {
      const multiple = client.tipoCobranca === 'MULTIPLA';

      const formatedEntities = entitiesResponse.map(entity => {
        const selected = multiple
          ? getSelectedEntityBySite(entity)
          : getSelectedEntityByClient(entity);

        return {
          idEntidadeFinanceira: entity.idEntidadeFinanceira,
          nome: entity.nome,
          cpfCnpj: entity.cpfCnpj,
          selected,
          default: false,
        };
      });

      const hasEntitySelected = formatedEntities.find(
        entity => entity.selected,
      );

      const defaultEntity = {
        nome: `${client.nome} (Principal)`,
        cpfCnpj: client.cpfCnpj,
        idEntidadeFinanceira: 0,
        default: true,
        selected: !hasEntitySelected,
      };

      const allEntities = [...[defaultEntity], ...formatedEntities];

      return allEntities;
    },
    [
      client.cpfCnpj,
      client.nome,
      client.tipoCobranca,
      getSelectedEntityByClient,
      getSelectedEntityBySite,
    ],
  );

  const selectEntity = useCallback(async () => {
    try {
      setUpdating(true);

      const idEntity = selectedRowsInfo.selectedRows[0].idEntidadeFinanceira;

      const entitiesResponse = await api.put<SelectEntityResponse>(
        `clientes/v1/cliente/${client.idCliente}/entidade-financeira`,
        {
          idEntidadeFinanceira: idEntity,
          ...(client.contaComSite && { idSite: site.idSite }),
        },
      );

      const formatedEntities = formatEntities(entitiesResponse.data.data);

      setEntities(formatedEntities);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:entities.selectSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:entities.selectFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }, [
    client.contaComSite,
    client.idCliente,
    formatEntities,
    selectedRowsInfo.selectedRows,
    site.idSite,
    t,
    toggleCleared,
  ]);

  const deleteEntity = useCallback(async () => {
    try {
      const { isConfirmed } = await swal.fire({
        title: t('common:warning'),
        text: t('pages:entities.warning'),
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setUpdating(true);

      const idsEntities = selectedRowsInfo.selectedRows.map(
        ({ idEntidadeFinanceira }) => idEntidadeFinanceira,
      );

      const entitiesResponse = await api.delete<DeleteEntitiesResponse>(
        `clientes/v1/entidade-financeira`,
        { params: { idsEntidadesFinanceiras: idsEntities } },
      );

      const formatedEntities = formatEntities(entitiesResponse.data.data);

      setEntities(formatedEntities);

      setToggleCleared(!toggleCleared);

      toast.fire(t('pages:entities.deleteSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:entities.deleteFailed'),
        html: err.response.data.error_description,
      });
    } finally {
      setUpdating(false);
    }
  }, [formatEntities, selectedRowsInfo.selectedRows, t, toggleCleared]);

  useEffect(() => {
    async function loadEntities() {
      try {
        setError(false);
        setLoading(true);

        const entitiesResponse = await api.get<EntitiesResponse>(
          `clientes/v1/entidade-financeira`,
        );

        const formatedEntities = formatEntities(entitiesResponse.data.data);

        setEntities(formatedEntities);
      } catch (err) {
        setError(false);
      } finally {
        setLoading(false);
      }
    }

    loadEntities();
  }, [formatEntities, getSelectedEntityByClient, getSelectedEntityBySite]);

  const columns = [
    {
      name: 'Entidade',
      selector: 'nome',
    },
    {
      name: 'CPF/CNPJ',
      selector: 'cpfCnpj',
    },
    {
      name: 'Selecionado',
      cell: (row: Entity) => (row.selected ? 'Entidade em uso' : ''),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title={t('pages:entities.title')}
          description={t('pages:entities.description')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Entidades_Financeiras"
        />

        <TableSubHeader>
          <div>
            <TableButton
              forwardedAs={Link}
              to="/financeiro/entidades/cadastrar"
              size="sm"
            >
              {t('pages:entities.newEntity')}
            </TableButton>
          </div>
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount === 0}
            selectableRows
            pagination
            data={filteredEntities}
            columns={columns}
            clearSelectedRows={toggleCleared}
            progressPending={loading}
            progressComponent={<Loading />}
            selectableRowDisabled={row => 'default' in row && row.default}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            contextComponent={
              <EntityActions
                loading={updating}
                selectedRows={selectedRowsInfo.selectedRows}
                selectedCount={selectedRowsInfo.selectedCount}
                selectEntity={selectEntity}
                deleteEntity={deleteEntity}
              />
            }
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default List;
