import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import Loading from 'components/Loading';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableNoData from 'components/TableNoData';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';

import useClientProducts from 'hooks/useClientProducts';
import useAvailableProducts from 'hooks/useAvailableProducts';

import { StoreState } from 'store/createStore';

import filter from 'utils/filter';

import swal from 'services/swal';
import api from 'services/api';

import { Container } from './styles';

import CustomQuantityProduct from './components/CustomQuantityProduct';
import PromotionalProducts from './components/PromotionalProducts';
import TableListProduct from './components/TableListProduct';
import TableListCombinedProduct from './components/TableListCombinedProducts';

const AdditionalServices: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const categoryId = Number(urlSearchParams.get('cat') ?? 0);

  const { idCliente, contaComSite } = useSelector(
    (store: StoreState) => store.client.info,
  );

  const { idSite } = useSelector((store: StoreState) => store.site.info);

  const {
    clientProcuts,
    isLoading: loadingClientProducts,
    refetch: refetchClientProducts,
  } = useClientProducts(idCliente);

  const {
    availableProducts,
    isLoading: loadingAvailableProducts,
    refetch: refetchAvailableProducts,
  } = useAvailableProducts(idSite);

  const [submitting, setSubmitting] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId);
  const [clientProductsFilterText, setProductsFilterText] = useState('');

  const filteredClientProducts = filter(
    clientProcuts,
    ['produto', 'site'],
    clientProductsFilterText,
  );

  const selectedCategory = availableProducts.categories.find(category => {
    return category.idCategoria === selectedCategoryId;
  });

  const selectedAvailableProducts = availableProducts.products.filter(
    product => {
      return product.idProdutoCategoria === selectedCategoryId;
    },
  );

  let displayMode: 'TABLE' | 'CUSTOM_QUANTITY' = 'TABLE';

  if (selectedCategory?.temProdutoQuantidadeCustomizavel) {
    displayMode = 'CUSTOM_QUANTITY';
  }

  const customQuantityProduct = selectedAvailableProducts.find(
    product => product.customizavel,
  );

  const promotionalProducts = selectedAvailableProducts.filter(
    product => !product.customizavel,
  );

  useEffect(() => {
    if (availableProducts.categories.length === 0) {
      return;
    }

    if (categoryId !== 0) {
      setSelectedCategoryId(categoryId);
      return;
    }

    setSelectedCategoryId(availableProducts.categories[0].idCategoria);
  }, [availableProducts.categories, categoryId]);

  async function buyProduct(productId: number, amount: number) {
    try {
      const { isConfirmed } = await swal.fire({
        icon: 'warning',
        title: t('common:confirmOperation'),
        text: 'Deseja prosseguir com a contratação?',
        showCancelButton: true,
      });

      if (!isConfirmed) {
        return;
      }

      setSubmitting(true);

      await api.post(`clientes/v1/cliente/${idCliente}/produto`, {
        idProduto: productId,
        tipoProduto: 'SERVICO_ADICIONAL',
        quantidade: amount,
        ...(contaComSite && { idSite }),
      });

      await refetchClientProducts();
      await refetchAvailableProducts();

      await swal.fire({
        icon: 'success',
        title: 'Contratação feita com sucesso',
      });
    } catch (err) {
      swal.fire({
        title: 'Não foi possível concluir a contratação',
        html: err.response.data.error_description,
      });
    } finally {
      setSubmitting(false);
    }
  }

  if (loadingAvailableProducts) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:additionalServices.title')}
        description={t('titles:additionalServices.description')}
        icon={<FaPlus color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:additionalServices.tableTitle')}
          description={t('pages:additionalServices.subTitle')}
        />

        <Card.Body className="fieldset">
          <Form.Group as={Row} controlId="products.categories">
            <Form.Label column sm={2}>
              {t('common:category')}
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                name="idCategory"
                defaultValue={selectedCategoryId}
                onChange={event =>
                  setSelectedCategoryId(Number(event.target.value))
                }
              >
                {availableProducts.categories.map(category => (
                  <option
                    key={category.idCategoria}
                    value={category.idCategoria}
                  >
                    {category.categoria}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
        </Card.Body>

        {displayMode === 'CUSTOM_QUANTITY' && customQuantityProduct && (
          <div>
            <CustomQuantityProduct
              product={customQuantityProduct}
              onSubmit={buyProduct}
              submitting={submitting}
            />
          </div>
        )}

        {displayMode === 'CUSTOM_QUANTITY' && promotionalProducts.length > 0 && (
          <div>
            <PromotionalProducts
              products={promotionalProducts}
              onClick={buyProduct}
            />
          </div>
        )}

        {displayMode === 'TABLE' && selectedCategoryId !== 25 && (
          <TableListProduct
            products={selectedAvailableProducts}
            onClick={buyProduct}
            submitting={submitting}
          />
        )}

        {displayMode === 'TABLE' && selectedCategoryId === 25 && (
          <TableListCombinedProduct
            products={selectedAvailableProducts}
            onClick={buyProduct}
            submitting={submitting}
          />
        )}
      </FormWrapper>

      <TableWrapper>
        <TableHeader
          title={t('pages:additionalServices.contractedServices')}
          description={t('pages:additionalServices.cancelServiceHelpdesk')}
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setProductsFilterText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            dense
            noHeader
            pagination
            progressPending={loadingClientProducts}
            progressComponent={<Loading />}
            data={filteredClientProducts}
            columns={[
              {
                name: t('common:description'),
                selector: 'produto',
                sortable: true,
                minWidth: '280px',
              },
              {
                name: t('common:site'),
                selector: 'site',
                sortable: true,
              },
              {
                name: 'Contratação',
                selector: 'dataContratacao',
                sortable: true,
                minWidth: '120px',
              },
              {
                name: t('common:price'),
                selector: 'valorFormatado',
                sortable: true,
                minWidth: '120px',
              },
            ]}
            noDataComponent={<TableNoData />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default AdditionalServices;
