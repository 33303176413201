import { useQuery } from 'react-query';

import api from 'services/api';

type ExtensionItemResponse = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: 'TRUE' | 'FALSE';
  tipo: string;
};

type ExtensionsResponse = {
  data: ExtensionItemResponse[];
};

type BrExtension = {
  idExtensao: number;
  extensao: string;
  valor: string;
  periodoMinimo: number;
  periodoMaximo: number;
  promocao: 'TRUE' | 'FALSE';
  tipo: string;
};

const initialData: BrExtension[] = [];

async function loadBrDomainExtensions() {
  const { data } = await api.get<ExtensionsResponse>(`registrobr/v1/extensao`, {
    params: {
      'filter[tipo]': 'REGISTRO',
    },
  });

  const domains = data.data;

  return domains;
}

export default function useBrDomainExtensions() {
  const { data, ...rest } = useQuery<BrExtension[]>(
    'brDomainsExtensions',
    () => loadBrDomainExtensions(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const brExtensions = data || initialData;

  return {
    brExtensions,
    ...rest,
  };
}
