import { useQuery } from 'react-query';

import api from 'services/api';

interface MySqlSettingsResponse {
  data: {
    strictTransTable: boolean;
    timeZone: string;
    maxConnections: number;
    maxUserConnections: number;
    connectTimeout: number;
    maxAllowedPacket: number;
    keyBufferSize: number;
    queryCacheSize: number;
    tmpTableSize: number;
    innodbBufferPoolSize: number;
    innodbLogBufferSize: number;
    innodbFileFormat: string;
    sortBufferSize: number;
    readBufferSize: number;
    readRndBufferSize: number;
    joinBufferSize: number;
    threadStack: number;
    isWebMySql: boolean;
    memory: number;
    virtualCpu: number;
  };
}

interface MySqlSettings {
  strictTransTable: boolean;
  timeZone: string;
  maxConnections: number;
  maxUserConnections: number;
  connectTimeout: number;
  maxAllowedPacket: number;
  keyBufferSize: number;
  queryCacheSize: number;
  tmpTableSize: number;
  innodbBufferPoolSize: number;
  innodbLogBufferSize: number;
  innodbFileFormat: string;
  sortBufferSize: number;
  readBufferSize: number;
  readRndBufferSize: number;
  joinBufferSize: number;
  threadStack: number;
  isWebMySql: boolean;
  memory: number;
  virtualCpu: number;
}

const initialData: MySqlSettings = {
  strictTransTable: true,
  timeZone: '',
  maxConnections: 0,
  maxUserConnections: 0,
  connectTimeout: 0,
  maxAllowedPacket: 0,
  keyBufferSize: 0,
  queryCacheSize: 0,
  tmpTableSize: 0,
  innodbBufferPoolSize: 0,
  innodbLogBufferSize: 0,
  innodbFileFormat: '',
  sortBufferSize: 0,
  readBufferSize: 0,
  readRndBufferSize: 0,
  joinBufferSize: 0,
  threadStack: 0,
  isWebMySql: true,
  memory: 0,
  virtualCpu: 0,
};

function formatMySqlSettings(
  mysqlSettingsResponse: MySqlSettingsResponse,
): MySqlSettings {
  return mysqlSettingsResponse.data;
}

async function loadMySqlSettings(idSite: number): Promise<MySqlSettings> {
  const mySqlSettingsResponse = await api.get<MySqlSettingsResponse>(
    `painel/v1/site/${idSite}/mysql`,
  );

  const mySqlSettings = formatMySqlSettings(mySqlSettingsResponse.data);

  return mySqlSettings;
}

export default function useMySqlSettings(idSite: number) {
  const { data, ...rest } = useQuery<MySqlSettings>(
    ['mySqlSettings', idSite],
    () => loadMySqlSettings(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const mySqlSettings = data || initialData;

  return {
    mySqlSettings,
    ...rest,
  };
}
