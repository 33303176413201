import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FilterInput from 'components/FilterInput';
import TableSubHeader from 'components/TableSubHeader';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableNoData from 'components/TableNoData';
import PanelButton from 'components/PanelButton';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import { StoreState } from 'store/createStore';

import filter from 'utils/filter';

import useUltramail from 'hooks/useUltramail';
import useEmailServices from 'hooks/useEmailServices';

import EmailServicesActions from './components/EmailServicesActions';

import { Container } from './styles';

const EmailServices: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const { refetch: refetchUltramail } = useUltramail(idSite);
  const {
    refetch: refetchEmailServices,
    emailServices,
    loading,
    error,
  } = useEmailServices(idSite);

  const [updating, setUpdating] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [toggleCleared, setToggleCleared] = useState(false);

  const [filteredServices, setFilteredServices] = useState(emailServices);

  const [selectedRowsInfo, setSelectedRowsInfo] = useState<{
    selectedCount: number;
    selectedRows: typeof emailServices;
  }>({
    selectedCount: 0,
    selectedRows: [],
  });

  useEffect(() => {
    setFilteredServices(filter(emailServices, ['nome', 'site'], filterText));
  }, [emailServices, filterText]);

  const addEmailService = useCallback(
    async (site: string, service: string) => {
      try {
        setUpdating(true);

        await api.post(`painel/v1/site/${idSite}/servico-email`, {
          servico: service,
          site,
        });

        await refetchEmailServices();

        refetchUltramail();

        setToggleCleared(!toggleCleared);

        toast.fire(t('pages:emailServices.activateSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:emailServices.activateFailed'),
          html: err.response.data.error_description,
        });
      } finally {
        setUpdating(false);
      }
    },
    [idSite, refetchEmailServices, refetchUltramail, t, toggleCleared],
  );

  const removeEmailService = useCallback(
    async (site: string) => {
      try {
        setUpdating(true);

        await api.delete(`painel/v1/site/${idSite}/servico-email?site=${site}`);

        await refetchEmailServices();

        refetchUltramail();

        setToggleCleared(!toggleCleared);

        toast.fire(t('pages:emailServices.deleteSuccess'));
      } catch (err) {
        swal.fire({
          title: t('pages:emailServices.deleteFailed'),
          html: err.response.data.error_description,
        });
      } finally {
        setUpdating(false);
      }
    },
    [idSite, refetchEmailServices, refetchUltramail, t, toggleCleared],
  );

  const columns = [
    {
      name: t('common:site'),
      selector: 'site',
      sortable: true,
    },
    {
      name: t('pages:emailServices.mailService'),
      selector: 'nome',
      sortable: true,
      cell: (row: typeof emailServices[number]) => row.nome || '---',
    },
  ];

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:emailServices.title')}
          description={
            <div>
              <div>{t('pages:emailServices.description.p1')}</div>
              <div>{t('pages:emailServices.description.p2')}</div>
            </div>
          }
          directHelpLink="https://ajuda.hostnet.com.br/o-que-e-o-ultramail/"
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader={selectedRowsInfo.selectedCount !== 1}
            selectableRows
            highlightOnHover
            clearSelectedRows={toggleCleared}
            onSelectedRowsChange={rows => setSelectedRowsInfo(rows)}
            selectableRowDisabled={() => updating}
            columns={columns}
            data={filteredServices}
            contextComponent={
              <EmailServicesActions
                loading={updating}
                selectedCount={selectedRowsInfo.selectedCount}
                selectedRows={selectedRowsInfo.selectedRows}
                addEmailService={addEmailService}
                removeEmailService={removeEmailService}
              />
            }
            progressComponent={<Loading />}
            noDataComponent={<TableNoData />}
          />
        </div>

        <div className="border-top pt-2 pb-2 pl-3">
          <PanelButton variant="secondary" forwardedAs={Link} to="/emails">
            {t('common:back')}
          </PanelButton>
        </div>
      </TableWrapper>
    </Container>
  );
};

export default EmailServices;
