import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillAlt, FaMoneyCheck } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { parseISO, format } from 'date-fns';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import Loading from 'components/Loading';
import TableIconButton from 'components/TableIconButton';
import TableNoData from 'components/TableNoData';
import Error from 'components/Error';
import PanelDropdownButton from 'components/PanelDropdownButton';

import api from 'services/api';

import formatCurrency from 'utils/formatCurrency';

import { StoreState } from 'store/createStore';

import filter from 'utils/filter';

import { Container } from './styles';

interface BankSlipItemResponse {
  idCobranca: number;
  chave: string;
  vencimento: string;
  descricao: string;
  valor: string;
  status: string;
}

interface BankSlipResponse {
  data: BankSlipItemResponse[];
}

interface BankSlip {
  idCobranca: number;
  chave: string;
  vencimento: string;
  descricao: string;
  valor: number;
  status: string;
}

type FilterStatus = 'Todos os boletos' | 'Abertos';

const BankSlipHistory: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [bankSlips, setBankSlips] = useState<BankSlip[]>([]);
  const [filteredBankSlips, setFilteredBankSlips] = useState<BankSlip[]>([]);
  const [filterText, setFilterText] = useState('');

  const [filterStatus, setFilterStatus] = useState<FilterStatus>('Abertos');

  const { idSite } = useSelector((state: StoreState) => state.site.info);
  const {
    idCliente,
    email1: usuario,
    tipoCobranca,
    contaComSite,
  } = useSelector((state: StoreState) => state.client.info);

  useEffect(() => {
    async function loadBankSlipHistory() {
      try {
        setError(false);
        setLoading(true);

        const debtsResponse = await api.get<BankSlipResponse>(
          `financeiro/v1/cliente/${idCliente}/cobranca`,
          {
            params: {
              idFormaPagamento: 10,
              ...(tipoCobranca === 'MULTIPLA' && contaComSite && { idSite }),
            },
          },
        );

        setBankSlips(
          debtsResponse.data.data.map(({ valor, ...rest }) => ({
            valor: parseFloat(valor),
            ...rest,
          })),
        );
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadBankSlipHistory();
  }, [contaComSite, idCliente, idSite, tipoCobranca]);

  useEffect(() => {
    const filtered = filter(bankSlips, ['descricao'], filterText);

    const filteredByStatus = filtered.filter(bankSlip => {
      switch (filterStatus) {
        case 'Abertos': {
          return bankSlip.status === 'ABERTO';
        }

        default: {
          return true;
        }
      }
    });

    setFilteredBankSlips(filteredByStatus);
  }, [bankSlips, filterStatus, filterText]);

  const columns = [
    {
      name: t('common:description'),
      selector: 'descricao',
      wrap: true,
      cell: (row: BankSlip) => (
        <div className="d-flex justify-content-between w-100 align-items-center p-1">
          <div className="p-1">{row.descricao}</div>
          <div className="ml-3">
            <TableIconButton
              tooltipConfig={{
                id: 'display',
                placement: 'right',
                content: 'Visualizar boleto',
              }}
              href={`https://painel.hostnet.com.br/boleto/index.php?ID=${row.idCobranca}&Chave=${row.chave}&v=3&usuarioPainel4=${usuario}`}
              target="_blank"
            >
              <FaMoneyCheck size={16} />
            </TableIconButton>
          </div>
        </div>
      ),
    },
    {
      name: t('common:label.value'),
      selector: 'valor',
      cell: (row: BankSlip) => formatCurrency(row.valor),
      width: '120px',
    },
    {
      name: t('pages:bankSlipHistory.paymentDueDate'),
      width: '120px',
      selector: 'vencimento',
      sortable: true,
      cell: (row: BankSlip) => format(parseISO(row.vencimento), 'dd/MM/yyyy'),
    },
  ];

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:financial.title')}
        description={t('titles:financial.description')}
        icon={<FaMoneyBillAlt color="#FFFFFF" size={24} />}
      />

      <TableWrapper>
        <TableHeader
          title={t('pages:bankSlipHistory.title')}
          description={t('pages:bankSlipHistory.description')}
          directHelpLink="https://ajuda.hostnet.com.br/cobrancas-e-pagamentos/#Boleto_Bancario"
        />

        <TableSubHeader>
          <div />
          <div className="d-flex">
            <PanelDropdownButton title={filterStatus} className="mr-2 mb-1">
              <Dropdown.Item
                onSelect={() => setFilterStatus('Todos os boletos')}
              >
                {t('pages:bankSlipHistory.filterStatus.all')}
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => setFilterStatus('Abertos')}>
                {t('pages:bankSlipHistory.filterStatus.open')}
              </Dropdown.Item>
            </PanelDropdownButton>

            <FilterInput onChange={value => setFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            highlightOnHover
            pagination
            columns={columns}
            data={filteredBankSlips}
            progressPending={loading}
            progressComponent={<Loading />}
            paginationComponentOptions={{
              rowsPerPageText: t('common:registersPerPage'),
              rangeSeparatorText: t('common:of'),
            }}
            noDataComponent={<TableNoData text={t('common:noDataToList')} />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default BankSlipHistory;
