import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

const CreditCardExpirationButtons: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-3 border-top">
      <PanelButton
        className="btn btn-secondary"
        forwardedAs={Link}
        to="/financeiro/cartoes-credito"
      >
        {t('common:creditCard_plural')}
      </PanelButton>
    </div>
  );
};

export default CreditCardExpirationButtons;
