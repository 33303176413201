import { useQuery } from 'react-query';

import api from 'services/api';

interface CredistsResponse {
  data: {
    ilimitado: boolean;
    percentualUtilizado: number;
    plano: number;
    servicosAdicionais: number;
    total: number;
    utilizados: number;
  };
}

interface DatabasesCredits {
  ilimitado: boolean;
  percentualUtilizado: number;
  total: number;
  utilizados: number;
}

async function loadDatabasesCredits(idSite: number): Promise<DatabasesCredits> {
  const creditsResponse = await api.get<CredistsResponse>(
    `painel/v1/site/${idSite}/banco-mysql/credito`,
  );

  const {
    ilimitado,
    percentualUtilizado,
    total,
    utilizados,
  } = creditsResponse.data.data;

  const credits = { ilimitado, percentualUtilizado, total, utilizados };

  return credits;
}

const initialData: DatabasesCredits = {
  total: 0,
  utilizados: 0,
  percentualUtilizado: 0,
  ilimitado: false,
};

export default function useDatabasesCredits(idSite: number) {
  const {
    data,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<DatabasesCredits>(
    ['databasesCredits', idSite],
    () => loadDatabasesCredits(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const databasesCredits = data || initialData;

  return {
    databasesCredits,
    error: isError,
    loading: isLoading,
    fetching: isFetching,
    refetch,
  };
}
