import React from 'react';
import { useTranslation } from 'react-i18next';

const HelpContent: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p>
        <strong>
          {t('pages:internationalDomainTransferEvents.helpContent.steps')}
        </strong>
      </p>

      <p>{t('pages:internationalDomainTransferEvents.helpContent.step3')}</p>
      <p>{t('pages:internationalDomainTransferEvents.helpContent.step4')}</p>

      <p>
        <strong>{t('common:warning')}</strong>
      </p>
      <ul>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning1')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning2')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning3')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning4')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning5')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning6')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning7')}
        </li>
        <li>
          {t('pages:internationalDomainTransferEvents.helpContent.warning8')}
        </li>
      </ul>
    </div>
  );
};

export default HelpContent;
