import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { StoreState } from 'store/createStore';

import {
  Container,
  Description,
  IconBox,
  NewPanelSwitch,
  Title,
  TitleContainer,
} from './styles';

interface PageTitleProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const PageTitle: React.FC<PageTitleProps> = ({
  icon,
  title,
  description = '',
}) => {
  const [checked, setChecked] = useState(true);

  const { email1: usuario } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { list: options } = useSelector((state: StoreState) => state.options);

  const showSwitch = options.find(
    option => option.codigo === 'switch.painel.3',
  );

  const switchChange = useCallback(() => setChecked(state => !state), [
    setChecked,
  ]);

  useEffect(() => {
    if (!checked) {
      window.location.href = `http://painel.hostnet.com.br/principal?v=3&usuarioPainel4=${usuario}`;
    }
  }, [checked, usuario]);

  return (
    <Container>
      <IconBox>{icon}</IconBox>

      <TitleContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleContainer>
      <NewPanelSwitch className="d-none d-lg-flex">
        {showSwitch && (
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Novo painel"
            checked={checked}
            disabled={!checked}
            onChange={() => switchChange()}
          />
        )}
      </NewPanelSwitch>
    </Container>
  );
};

export default PageTitle;
