import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface DatabaseActionsProps {
  selectedCount: number;
  selectedRows: { id: number }[];
  loading: boolean;
  showConnections: () => void;
  deleteDatabases: () => void;
}

const DatabaseActions: React.FC<DatabaseActionsProps> = ({
  selectedCount,
  selectedRows,
  loading,
  showConnections,
  deleteDatabases,
}) => {
  const { t } = useTranslation();

  const databaseHashId = hashIds.encode(selectedRows[0].id);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/banco-de-dados-mysql/${databaseHashId}/editar`}
            className="mr-2"
            size="sm"
            disabled={loading}
          >
            {t('common:button.edit')}
          </TableButton>

          <TableButton
            className="mr-2"
            onClick={() => showConnections()}
            disabled={loading}
          >
            {t('pages:databasesMySql.connectionAddresses')}
          </TableButton>
        </>
      )}
      <TableButton
        className="mr-2"
        variant="danger"
        size="sm"
        disabled={loading}
        onClick={() => deleteDatabases()}
      >
        {t('common:button.delete')}
      </TableButton>
    </TableActionsWrapper>
  );
};

export default DatabaseActions;
