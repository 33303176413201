import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface InternationalContactActionsProps {
  selectedCount: number;
  selectedRows: {
    id: number;
    validateIdentity: boolean;
  }[];
  validateEntity: (idContact: number) => void;
  loading: boolean;
}

const InternationalContactActions: React.FC<InternationalContactActionsProps> = ({
  selectedCount,
  selectedRows,
  validateEntity,
  loading,
}) => {
  const { t } = useTranslation();

  const contactHashId = hashIds.encode(selectedRows[0].id);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            disabled={loading}
            forwardedAs={Link}
            to={`/registro/contato-internacional-editar/${contactHashId}`}
            className="mr-2"
          >
            {t('common:edit')}
          </TableButton>
          {selectedRows[0].validateIdentity && (
            <TableButton
              disabled={loading}
              onClick={() => validateEntity(selectedRows[0].id)}
            >
              {t('pages:domainContacts.identityValidation')}
            </TableButton>
          )}
        </>
      )}
    </TableActionsWrapper>
  );
};

export default InternationalContactActions;
