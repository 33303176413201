import styled from 'styled-components';

import { Spinner } from 'react-bootstrap';

export const Container = styled.div`
  min-width: 140px;
`;

export const SelectorButton = styled.button`
  padding: 0;
  background: none;
  border: none;
  outline: none !important;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const SitePlan = styled.div`
  font-size: 14px;

  text-align: left;
  font-weight: bold;
`;

export const SiteName = styled.small`
  display: block;
`;

export const LoadingSpinner = styled(Spinner)`
  color: var(--color-blue);
`;
