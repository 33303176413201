import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { ActionType } from 'typesafe-actions';

import api from 'services/api';

import * as actions from './actions';

interface FavoriteSiteItemResponse {
  idHostnet: number;
  idSite: number;
}

interface FavoriteSiteResponse {
  data: FavoriteSiteItemResponse[];
}

type SelectFavoriteSiteRequestAction = ActionType<
  typeof actions.selectFavoriteSiteRequest
>;

function* selectFavoriteSite({ payload }: SelectFavoriteSiteRequestAction) {
  try {
    const { idSite } = payload;

    const favoriteSiteResponse: AxiosResponse<FavoriteSiteResponse> = yield call(
      api.put,
      `painel/v1/site-favorito`,
      {
        idSite,
      },
    );

    const favoriteSite = favoriteSiteResponse.data.data[0];

    yield put(actions.setFavoriteSite(favoriteSite.idSite));
  } catch (err) {
    yield put(actions.selectFavoriteSiteFailure());
  }
}
export default all([
  takeLatest('@sites/SELECT_FAVORITE_SITE_REQUEST', selectFavoriteSite),
]);
