import styled from 'styled-components';

export const Container = styled.div``;

export const IpInputsContainer = styled.div<{ teste: boolean }>`
  display: ${props => (props.teste ? 'block' : 'none')};

  transition: filter 0.2s;

  &[teste] {
    background-color: black;
  }
`;
