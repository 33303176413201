const renunciationOptions = [
  {
    id: 1,
    text: 'Falta de tempo para trabalhar no site.',
  },
  {
    id: 2,
    text: 'Projeto não teve continuidade.',
  },
  {
    id: 3,
    text: 'Site não trouxe o retorno esperado.',
  },
  {
    id: 4,
    text: 'Não consegui criar meu site.',
  },
  {
    id: 5,
    text: 'Não tenho conhecimento técnico para manter e atualizar o site.',
  },
];

const pricesOptions = [
  {
    id: 6,
    text: 'Mudança para empresa de hospedagem com menor preço.',
    input: true,
    inputName: 'otherHost',
  },
  {
    id: 7,
    text: 'Desativação do site para redução de custos.',
    input: false,
  },
  {
    id: 8,
    text: 'Parceria/serviço que inclui hospedagem grátis.',
    input: false,
  },
];

const customerServiceOptions = [
  {
    id: 9,
    text: 'Dificuldade de contato com a Central de Atendimento.',
  },
  {
    id: 10,
    text: 'Insatisfação com as respostas e instruções.',
  },
  {
    id: 11,
    text: 'Tempo médio de resposta muito longo.',
  },
];

const resourcesAndSystemsOptions = [
  {
    id: 12,
    text: 'Baixa capacidade de recursos (espaço, tráfego, email).',
    input: true,
  },
  {
    id: 13,
    text: 'Erros e bugs nos sistemas oferecidos.',
    input: true,
  },
  {
    id: 14,
    text: 'Falta de recursos técnicos para o site.',
    input: true,
  },
];

const unavailabilityOptions = [
  {
    id: 15,
    text: 'Falhas no acesso ao site.',
  },
  {
    id: 16,
    text: 'Falhas no acesso ao email.',
  },
  {
    id: 17,
    text: 'Falhas na conexão com banco de dados.',
  },
];

const otherOptions = [
  {
    id: 18,
    text: 'Término das atividades da empresa/negócio.',
    input: false,
  },
  {
    id: 19,
    text: 'Solicitação de mudança pelo webmaster.',
    input: false,
  },
  {
    id: 20,
    text: 'Outra razão:',
    input: true,
  },
];

const allOptions = [
  ...renunciationOptions,
  ...pricesOptions,
  ...customerServiceOptions,
  ...resourcesAndSystemsOptions,
  ...unavailabilityOptions,
  ...otherOptions,
];

export {
  allOptions,
  renunciationOptions,
  pricesOptions,
  customerServiceOptions,
  resourcesAndSystemsOptions,
  unavailabilityOptions,
  otherOptions,
};
