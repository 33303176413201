import styled from 'styled-components';

export const Container = styled.div`
  width: 250px;
  min-width: 250px;
  height: 73px;

  font-size: 13px;
  padding: 20px 10px;

  color: #fff;
  background: #0a1d3e;

  display: flex;
  justify-content: space-between;

  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
  }

  span.alpha {
    font-size: 80%;
    margin-top: -5px;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
    flex-flow: row-reverse;
    padding: 20px;
  }

  @media only screen and (min-width: 1400px) {
    a {
      font-size: 14px;
    }
  }
`;

export const MobileOptions = styled.div`
  display: none;

  @media only screen and (max-width: 991px) {
    display: block;
  }
`;

export const MobileOptionsButton = styled.button`
  padding: 0;
  height: 100%;

  border: none;
  background: none;
  outline: none !important;
`;

export const MobileMenuButton = styled.button`
  padding: 0;
  height: 100%;

  border: none;
  background: none;
  outline: none !important;
`;

export const LogoImage = styled.img`
  width: 180px;
  height: 40px;
`;
