import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'react-bootstrap';

import toast from 'services/toast';

import PanelButton from 'components/PanelButton';

interface LicenseKeyModalProps {
  show: boolean;
  close: () => void;
  licenceKey: string;
}

const LicenseKeyModal: React.FC<LicenseKeyModalProps> = ({
  show,
  close,
  licenceKey,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={() => close()} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Chave de Licença</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          Com esta chave de licença da Hostnet, é possível integrar o seu
          aplicativo Mautic instalado através do Instalador de Apps com vários
          serviços da Hostnet, como por exemplo o Relatório e Estatísticas de
          Envio de E-mail ou o serviço de SMS.
        </div>

        <div className="mt-4 font-weight-bold mb-2">Chave de ativação</div>
        <Form.Control value={licenceKey} disabled />
        <Form.Text className="text-muted mb-3">
          Cole esta chave nas configurações do plugin Hostnet Tools
        </Form.Text>
        <PanelButton
          onClick={() => {
            navigator.clipboard.writeText(licenceKey);
            toast.fire('Chave copiada para área de transferência');
          }}
        >
          Copiar chave
        </PanelButton>
        <div className="mt-4">
          Para adicionar a licença no Mautic, siga os passos:
          <ul>
            <li>
              Acesse o Mautic e entre em Configurações Plugins Procure na
              listagem pelo plugin Hostnet Tools.
            </li>
            <li>Clique nele e cole a chave no campo indicado.</li>
            <li>
              Coloque o campo <b>Publicado</b> como <b>Sim</b> e salve.
            </li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PanelButton variant="primary" onClick={() => close()}>
          {t('common:button.close')}
        </PanelButton>
      </Modal.Footer>
    </Modal>
  );
};

export default LicenseKeyModal;
