import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { DatabaseIcon } from './styles';

type Option = {
  codigo: string;
  opcao: string;
};

interface DatabaseOptionsProps {
  site: string;
  options: Option[];
  usuario: string;
}

const DatabaseOptions: React.FC<DatabaseOptionsProps> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="9"
      text={t('components:sideMenu.database')}
      icon={<DatabaseIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.databaseMySql')}
          to="/banco-de-dados-mysql"
        />,
        options.some(option => option.codigo === 'servidor.cloud') && [
          <SideMenuAccordionLink
            key="databaseConfig"
            text={t('components:sideMenu.databaseConfig')}
            to="/banco-de-dados-mysql/configuracao"
          />,
          <SideMenuAccordionLink
            key="databaseMemory"
            text={t('components:sideMenu.databaseMemory')}
            to="/banco-de-dados-mysql/configuracao-memoria"
          />,
        ],
      ]}
    />
  );
};

export default DatabaseOptions;
