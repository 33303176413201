import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Error from 'components/Error';
import Loading from 'components/Loading';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';

import { Container } from './styles';

const Confirm: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const urlSearchParams = new URLSearchParams(search);
  const transferKey = urlSearchParams.get('k');

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (transferKey === null) {
      return;
    }

    async function confirmTransfer(key: string) {
      try {
        await api.put(`/registrobr/v1/transferencia`, {
          chave: key,
        });

        swal.fire({
          icon: 'success',
          title: '',
          html: t('pages:brDomainTransferConfirm.success'),
        });

        history.push(`/`);
      } catch (err) {
        const responseErrorMessage =
          (err.response && err.response.data.error_description) || err.message;

        setErrorMessage(responseErrorMessage);

        swal.fire({
          title: t('common:operationFailed'),
          html: responseErrorMessage,
        });
      }
    }

    confirmTransfer(transferKey);
  }, [t, transferKey]);

  if (!transferKey) {
    return <Error message={t('common:requestFailed')} />;
  }

  if (errorMessage) {
    return <Error message={errorMessage} />;
  }

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default Confirm;
