import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PanelButton from 'components/PanelButton';

const CongelamentoButtons: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="p-3 border-top">
      <PanelButton
        className="btn btn-secondary mr-2"
        forwardedAs={Link}
        to="/financeiro/extrato"
      >
        {t('pages:home.financeStatements')}
      </PanelButton>

      <PanelButton
        className="btn btn-secondary"
        forwardedAs={Link}
        to="/financeiro/suspender-congelamento"
      >
        Suspensão de congelamento
      </PanelButton>
    </div>
  );
};

export default CongelamentoButtons;
