import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, ListGroup, Modal } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import PanelButton from 'components/PanelButton';

import { Item } from './styles';

interface CloseTicketFormValues {
  rating: number;
  comments: string;
}

interface CloseTicketModalProps {
  show: boolean;
  fetching: boolean;
  close: () => void;
  closeTicket: (
    values: CloseTicketFormValues,
    formikHelpers: FormikHelpers<CloseTicketFormValues>,
  ) => void;
}

const CloseTicketModal: React.FC<CloseTicketModalProps> = ({
  show,
  fetching,
  close,
  closeTicket,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} onHide={close} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('pages:ticket.closeTicket')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          rating: 2,
          comments: '',
        }}
        validationSchema={Yup.object().shape({
          comments: Yup.string().required(
            t('pages:ticket.validation.comments'),
          ),
        })}
        onSubmit={closeTicket}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <Form.Label>{t('common:label.rating')}</Form.Label>
                <ListGroup horizontal className="justify-content-end">
                  <Item
                    active={props.values.rating === 2}
                    onClick={() => props.setFieldValue('rating', 2)}
                    type="button"
                  >
                    {t('common:veryGood')}
                  </Item>
                  <Item
                    active={props.values.rating === 3}
                    onClick={() => props.setFieldValue('rating', 3)}
                    type="button"
                  >
                    {t('common:good')}
                  </Item>
                  <Item
                    active={props.values.rating === 4}
                    onClick={() => props.setFieldValue('rating', 4)}
                    type="button"
                  >
                    {t('common:regular')}
                  </Item>
                  <Item
                    active={props.values.rating === 5}
                    onClick={() => props.setFieldValue('rating', 5)}
                    type="button"
                  >
                    {t('common:bad')}
                  </Item>
                  <Item
                    active={props.values.rating === 6}
                    onClick={() => props.setFieldValue('rating', 6)}
                    type="button"
                  >
                    {t('common:terrible')}
                  </Item>
                </ListGroup>
              </Form.Group>

              <Form.Group controlId="ticket.Comments">
                <Form.Label>{t('pages:ticket.comments')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="comments"
                  disabled={props.isSubmitting}
                  value={props.values.comments}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  isInvalid={
                    !!props.touched.comments && !!props.errors.comments
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {props.errors.comments}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <PanelButton
                variant="primary"
                disabled={fetching || !props.isValid}
                type="submit"
              >
                {fetching ? t('pages:ticket.updatingStatus') : t('common:send')}
              </PanelButton>
              <PanelButton variant="secondary" onClick={close}>
                {t('common:close')}
              </PanelButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CloseTicketModal;
