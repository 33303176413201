import React from 'react';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module';

import './i18n';

import 'config/Interceptors';

import queryClient from 'config/QueryClient';
import GoogleTagManagerConfig from 'config/GoogleTagManager';

import history from 'services/history';

import { store, persistor, refreshStore } from './store';

import GlobalStyle from './styles/global';

import Routes from './routes';

refreshStore();

TagManager.initialize({
  gtmId: GoogleTagManagerConfig.gtmId,
});

const App: React.FC = () => {
  return (
    <Router history={history}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <Routes />
            <GlobalStyle />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Router>
  );
};

export default App;
