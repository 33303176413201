import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaCogs } from 'react-icons/fa';
import { Form, Card, Row, Col } from 'react-bootstrap';

import { StoreState } from 'store/createStore';

import Loading from 'components/Loading';
import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';

import api from 'services/api';

import { Container } from './styles';

interface App {
  idVersao: number;
  idSoftware: number;
  software: string;
  descricao: string;
  versao: string;
  aviso: string;
  tamanho: number;
  tamanhoMB: string;
}

const defaultApp = {
  idVersao: 0,
  idSoftware: 0,
  software: '',
  descricao: '',
  versao: '',
  aviso: '',
  tamanho: 0,
  tamanhoMB: '',
};

const Wordpress: React.FC = () => {
  const { t } = useTranslation();

  const action = 'https://apps.hostnet.com.br/login';

  const { list: options } = useSelector((state: StoreState) => state.options);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dadosCliente, setDadosCliente] = useState('');
  const [selectedApp, setSelectedApp] = useState<App | undefined>(defaultApp);
  const [apps, setApps] = useState<App[]>([defaultApp]);
  const [isCloud, setIsCloud] = useState(false);

  const { idCliente } = useSelector((state: StoreState) => state.client.info);
  const { site, idSite } = useSelector((state: StoreState) => state.site.info);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    async function getDadosCliente() {
      try {
        setLoading(true);
        setError(false);
        const data = {
          site,
          idSite,
          idCliente,
        };
        const response = await api.post(`painel/v1/apps/cript`, data);
        setDadosCliente(response.data.data);

        const idsSoftwares = [14, 140, 141];
        const appsResponse = await api.get(`instalador/v1/software/dados`, {
          params: {
            idsSoftwares,
          },
        });
        const wordpressPadrao = appsResponse.data.data.find(
          (w: App) => w.idSoftware === 14,
        );
        setApps(appsResponse.data.data);
        setSelectedApp(wordpressPadrao);
        if (options.some(option => option.codigo === 'servidor.cloud')) {
          setIsCloud(true);
        } else {
          setIsCloud(false);
        }
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getDadosCliente();
  }, [idCliente, idSite, site, options]);

  function handleChange(value: string) {
    let app: App | undefined = defaultApp;
    switch (value) {
      case 'wordpress_padrao': {
        app = apps.find((a: App) => a.idSoftware === 14);
        break;
      }
      case 'wordpress_premium_com_beaver_builder': {
        app = apps.find((a: App) => a.idSoftware === 140);
        break;
      }
      case 'wordpress_premium_com_elementor_pro': {
        app = apps.find((a: App) => a.idSoftware === 141);
        break;
      }
      default: {
        app = apps.find((a: App) => a.idSoftware === 14);
        break;
      }
    }
    setSelectedApp(app);
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:apps.title')}
        description={t('titles:apps.description')}
        icon={<FaCogs color="#FFFFFF" size={24} />}
      />
      <FormWrapper>
        <FormHeader
          directHelpLink="https://ajuda.hostnet.com.br/instalando-o-wordpress-premium-na-hostnet/"
          title={t('pages:wordpress.title')}
          description={t('pages:wordpress.description')}
        />
        <Form ref={formRef} method="POST" action={action} target="_BLANK">
          <Card.Body className="fieldset">
            <p>{t('pages:wordpress.wordpressDescription.p1')}</p>
            <p>{t('pages:wordpress.wordpressDescription.p2')}</p>
            {isCloud ? (
              <Form.Group as={Row} controlId="app">
                <Form.Label column sm={2}>
                  {t('App')}
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    name="StApp"
                    onChange={ev => handleChange(ev.target.value)}
                  >
                    <option value="wordpress_padrao">
                      {t('pages:wordpress.wordpressPadrao')}
                    </option>
                    <option value="wordpress_premium_com_beaver_builder">
                      {t('pages:wordpress.wordpressBeaverBuilder')}
                    </option>
                    <option value="wordpress_premium_com_elementor_pro">
                      {t('pages:wordpress.wordpressElementorPro')}
                    </option>
                  </Form.Control>
                </Col>
              </Form.Group>
            ) : (
              <input type="hidden" name="StApp" value="WordPress" />
            )}
            <Form.Group as={Row} controlId="app.versao">
              <Form.Label column sm={2}>
                {t('pages:wordpress.version')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="version"
                  plaintext
                  readOnly
                  value={
                    selectedApp && selectedApp.versao ? selectedApp.versao : ''
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="app.tamanho">
              <Form.Label column sm={2}>
                {t('pages:wordpress.size')}
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  name="tamanho"
                  plaintext
                  readOnly
                  value={
                    selectedApp && selectedApp.tamanhoMB
                      ? selectedApp.tamanhoMB
                      : ''
                  }
                />
              </Col>
            </Form.Group>
            <input type="hidden" name="StOrigem" value="Painel" />
            <input name="DadosCliente" value={dadosCliente} type="hidden" />
          </Card.Body>
          <div className="border-top pt-2 pb-2 pl-3">
            <PanelButton type="submit" className="mr-1" disabled={false}>
              {t('pages:wordpress.accessInstaller')}
            </PanelButton>
          </div>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default Wordpress;
