import { useQuery } from 'react-query';

import api from 'services/api';

type HostnetCoursesAccountResponse = {
  data: {
    email: string;
    cadastrado: boolean;
  };
};

interface HostnetCoursesAccount {
  email: string;
  cadastrado: boolean;
}

async function loadHostnetCoursesAccount(
  idSite: number,
): Promise<HostnetCoursesAccount> {
  const accountResponse = await api.get<HostnetCoursesAccountResponse>(
    `painel/v1/academia-hostnet/conta`,
    {
      data: {
        idSite,
      },
    },
  );

  return {
    email: accountResponse.data.data.email,
    cadastrado: accountResponse.data.data.cadastrado,
  };
}

export default function useHostnetCoursesAccount(idSite: number) {
  const initialData: HostnetCoursesAccount = {
    email: '',
    cadastrado: false,
  };

  const { data, ...rest } = useQuery<HostnetCoursesAccount>(
    'hostnetCoursesAccount',
    () => loadHostnetCoursesAccount(idSite),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const account = data || initialData;

  return {
    account,
    ...rest,
  };
}
