import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';
import hashIds from 'services/hashIds';

interface SubDomainsActionsProps {
  selectedRows: {
    idSubDominio: number;
    podeRemover: boolean;
  }[];
  selectedCount: number;
  loading: boolean;
  deleteSubDomain: () => void;
}

const SubDomainsActions: React.FC<SubDomainsActionsProps> = ({
  selectedRows,
  selectedCount,
  loading,
  deleteSubDomain,
}) => {
  const { t } = useTranslation();

  const subDomainHashId = hashIds.encode(selectedRows[0].idSubDominio);

  return (
    <TableActionsWrapper loading={loading}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/subdominio/${subDomainHashId}`}
            className="mx-1"
            disabled={loading}
          >
            {t('common:edit')}
          </TableButton>

          {selectedRows[0].podeRemover && (
            <TableButton
              className="mx-1"
              variant="danger"
              onClick={deleteSubDomain}
              disabled={loading}
            >
              {t('common:delete')}
            </TableButton>
          )}
        </>
      )}
    </TableActionsWrapper>
  );
};

export default SubDomainsActions;
