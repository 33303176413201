import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

export const Container = styled.div`
  .notice-content {
    font-size: 0.9em;
    line-height: 1.4;
  }
`;

export const FinancialInfo = styled.div`
  font-size: 24px;
  font-weight: 700;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.blue {
    color: var(--color-blue);
  }

  &.violet {
    color: var(--color-violet);
  }

  &.yellow {
    color: var(--color-yellow);
  }

  &.green {
    color: var(--color-green);
  }
`;

export const BasicLink = styled(Link)`
  font-size: 13px;
  color: #122a56;
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
  }
`;

export const TabConfig = styled.div`
  .nav-link {
    &.active {
      border-bottom: 4px solid;
    }
  }
`;

export const NavItem = styled(Nav.Item)`
  flex: 1;
`;
