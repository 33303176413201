import api from './api';

interface UploadFileResponse {
  data: {
    caminho: string;
    nome: string;
    tipo: string;
  };
}

async function upload(file: File) {
  try {
    const formData = new FormData();

    formData.append('arquivo', file);

    const uploadFileResponse = await api.post<UploadFileResponse>(
      'https://a.apihn.co/v1/arquivo',
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
        timeout: 15000,
      },
    );

    const { tipo, nome, caminho } = uploadFileResponse.data.data;

    return { tipo, nome, caminho };
  } catch (err) {
    return false;
  }
}

export default { upload };
