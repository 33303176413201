import { format, parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import api from 'services/api';

type RatchetEmailReportedResponse = {
  data: {
    total: number;
    totalEmails: {
      [key: string]: number;
    };
    lista: {
      email: string;
      denunciante: string;
      data: string;
      total: number;
    }[];
  };
};

type RatchetEmailReported = {
  total: number;
  list: {
    email: string;
    reporter: string;
    date: string;
    total: number;
  }[];
};

const initialData: RatchetEmailReported = {
  total: 0,
  list: [],
};

function formatResponse(
  ratchetEmailResponse: RatchetEmailReportedResponse,
): RatchetEmailReported {
  const list = ratchetEmailResponse.data.lista.map(listItem => ({
    email: listItem.email,
    reporter: listItem.denunciante,
    date: format(parseISO(listItem.data), 'dd/MM/yyyy'),
    total: listItem.total,
  }));

  return {
    total: ratchetEmailResponse.data.total,
    list,
  };
}

async function loadRatchetEmailReported(
  site: string,
  initialDate = '',
  finalDate = '',
): Promise<RatchetEmailReported> {
  const emailsResponse = await api.get<RatchetEmailReportedResponse>(
    `painel/v1/ratchet/email/fbl`,
    {
      params: {
        'filter[site]': site,
        ...(initialDate !== '' && { 'filter[dataInicial]': initialDate }),
        ...(finalDate !== '' && { 'filter[dataFinal]': finalDate }),
      },
    },
  );

  const ratchetEmails = formatResponse(emailsResponse.data);

  return ratchetEmails;
}

export default function useRatchetEmailReported(
  site: string,
  initialDate = '',
  finalDate = '',
) {
  const { data, ...rest } = useQuery<RatchetEmailReported>(
    ['ratchetEmailReported', site, initialDate, finalDate],
    () => loadRatchetEmailReported(site, initialDate, finalDate),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const ratchetEmailReported = data || initialData;

  return {
    ratchetEmailReported,
    ...rest,
  };
}
