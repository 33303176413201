import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TableButton from 'components/TableButton';
import TableActionsWrapper from 'components/TableActionsWrapper';

import hashIds from 'services/hashIds';

interface RioDomainActionsProps {
  selectedCount: number;
  selectedRows: { id: number }[];
}

const RioDomainActions: React.FC<RioDomainActionsProps> = ({
  selectedCount,
  selectedRows,
}) => {
  const { t } = useTranslation();

  return (
    <TableActionsWrapper loading={false}>
      {selectedCount === 1 && (
        <>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-rio-dns/${hashIds.encode(
              selectedRows[0].id,
            )}`}
            className="mr-2"
          >
            {t('common:dns')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-rio-configuracao/${hashIds.encode(
              selectedRows[0].id,
            )}`}
            className="mr-2"
          >
            {t('common:settings')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-rio-renovar/${hashIds.encode(
              selectedRows[0].id,
            )}`}
            className="mr-2"
          >
            {t('pages:domains.addYears')}
          </TableButton>
          <TableButton
            forwardedAs={Link}
            to={`/registro/dominio-rio-contato-editar/${hashIds.encode(
              selectedRows[0].id,
            )}`}
            className="mr-2"
          >
            {t('pages:domains.changeContact')}
          </TableButton>
        </>
      )}
    </TableActionsWrapper>
  );
};

export default RioDomainActions;
