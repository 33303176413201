import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.div`
  position: relative;
  margin-bottom: 5px;
  padding: 25px 25px 5px 15px;

  font-size: 12px;
  font-weight: 500;

  opacity: 1;

  color: var(--color-alice-blue);
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;

  list-style: none;
`;

export const ListItem = styled.li`
  display: block;

  margin: 5px 0 0;
`;
