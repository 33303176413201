import React, { useState } from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MdFileDownload } from 'react-icons/md';

import PanelButton from 'components/PanelButton';

import { Chat, Item, Text, AttachButton } from './styles';

interface Attachment {
  anexo: string;
  caminho: string;
}

interface MessageProps {
  idMessage: number;
  author: string;
  message: string;
  rated: boolean;
  origin: 'CLIENTE' | 'ATENDENTE';
  date: string;
  attachments: Attachment[];
  fetching: boolean;
  updateMessageRating: (idMessage: number, rating: number) => void;
}

const Message: React.FC<MessageProps> = ({
  idMessage,
  author,
  message,
  rated,
  attachments,
  origin,
  date,
  updateMessageRating,
  fetching,
}) => {
  const { t } = useTranslation();

  const [rating, setRating] = useState<number | null>(null);

  function renderAttachment(attachs: Attachment[]) {
    return (
      attachs.length > 0 && (
        <>
          <Text className="text-muted small border-top my-2 pt-2">
            {t('pages:ticket.messages.withAttachments')}
          </Text>
          <ListGroup variant="flush">
            {attachs.map(attachment => (
              <ListGroup.Item
                key={attachment.caminho}
                className="p-0 bg-transparent border-0"
              >
                <AttachButton
                  className="btn btn-outline-primary btn-sm"
                  download="attachment"
                  href={`https://a.apihn.co/download.php?caminho=${attachment.caminho}`}
                >
                  {attachment.anexo} <MdFileDownload size="1.2em" />
                </AttachButton>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </>
      )
    );
  }

  return (
    <Chat>
      {origin === 'CLIENTE' ? (
        <div className="row mb-3 ml-1 received-chat">
          <div className="col">
            <div className="msg">
              <p className="msg-header">{author}</p>
              <p className="mb-0 msg-body">{message}</p>
              {renderAttachment(attachments)}
            </div>
            <p className="text-muted mb-0">
              <small>{date}</small>
            </p>
          </div>
        </div>
      ) : (
        <div className="row mb-3 mr-1 send-chat">
          <div className="col">
            <div className="msg">
              <p className="msg-header">{author}</p>
              {/*eslint-disable */}
              <div
                className="mb-0 msg-body"
                dangerouslySetInnerHTML={{ __html: message }}
              />
              {/* eslint-enable */}
              {renderAttachment(attachments)}
            </div>
            <p className="text-muted mb-1">
              <small>{date}</small>
            </p>
            {rated ? (
              <>
                <p className="mb-1 text-muted small">
                  {t('pages:ticket.messages.ratedMessage')}
                </p>
              </>
            ) : (
              <>
                <p className="mb-1 text-muted small">
                  {t('pages:ticket.messages.rateMessage')}
                </p>
                <div>
                  <Form.Group>
                    <ListGroup
                      horizontal="md"
                      className="justify-content-end text-center"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => (
                        <Item
                          key={n}
                          active={rating === n}
                          onClick={() => setRating(n)}
                          type="button"
                        >
                          {n}
                        </Item>
                      ))}
                    </ListGroup>
                    <PanelButton
                      className="mt-2"
                      onClick={() => {
                        if (rating !== null) {
                          updateMessageRating(idMessage, rating);
                        }
                      }}
                      disabled={rating === null || fetching}
                    >
                      {t('pages:ticket.messages.rate')}
                    </PanelButton>
                  </Form.Group>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Chat>
  );
};

export default Message;
