import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';

import Error from 'components/Error';
import Loading from 'components/Loading';
import FormHeader from 'components/FormHeader';
import FormWrapper from 'components/FormWrapper';
import PageTitle from 'components/PageTitle';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';

import api from 'services/api';
import hashIds from 'services/hashIds';
import swal from 'services/swal';
import toast from 'services/toast';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type SettingsProps = RouteComponentProps<MatchParams>;

interface DomainResponse {
  data: {
    dominio: string;
    locked: 'TRUE' | 'FALSE';
  }[];
}

interface FormValues {
  locked: boolean;
}

const Settings: React.FC<SettingsProps> = ({ match }) => {
  const { t } = useTranslation();

  const [idDomain] = hashIds.decode(match.params.id);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sendingAuthInfo, setSendingAuthInfo] = useState(false);

  const [domainName, setDomainName] = useState('');
  const [locked, setLocked] = useState(false);

  useEffect(() => {
    async function loadSettings() {
      try {
        if (idDomain === undefined) {
          setError(true);
          return;
        }

        setError(false);
        setLoading(true);

        const domainResponse = await api.get<DomainResponse>(
          `rrpproxy/v1/dominio/${idDomain}`,
        );

        setDomainName(domainResponse.data.data[0].dominio);
        setLocked(domainResponse.data.data[0].locked === 'TRUE');
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSettings();
  }, [idDomain]);

  async function submitForm(values: FormValues) {
    try {
      await api.put(`rrpproxy/v1/dominio/${idDomain}`, {
        bloqueioTransferencia: values.locked ? 'TRUE' : 'FALSE',
      });

      toast.fire(t('pages:rioDomainSettings.settingsSuccess'));
    } catch (err) {
      swal.fire({
        title: t('pages:rioDomainSettings.settingsFailed'),
        html: err.response.data.error_description,
      });
    }
  }

  async function sendAuthInfo() {
    try {
      setSendingAuthInfo(true);

      await api.post(`rrpproxy/v1/dominio/${idDomain}/auth-info`);

      toast.fire(t('pages:rioDomainSettings.authInfoSuccess'));
    } catch (err) {
      swal.fire(t('pages:rioDomainSettings.authInfoFailed'));
    } finally {
      setSendingAuthInfo(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:domainRegistry.title')}
        description={t('titles:domainRegistry.description')}
        icon={<FaGlobeAmericas color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('common:settings')}
          description={t('pages:rioDomainSettings.description')}
        />

        <Formik
          enableReinitialize
          initialValues={{ locked }}
          onSubmit={submitForm}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle
                  subTitle={t('pages:rioDomainSettings.domainSettings')}
                />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('common:domain')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control plaintext readOnly value={domainName} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="locked-field">
                  <Form.Label column sm={2}>
                    {t('pages:rioDomainSettings.transferLock')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      inline
                      custom
                      disabled={props.isSubmitting || sendingAuthInfo}
                      id="locked-enabled"
                      type="radio"
                      label={t('common:yes')}
                      name="locked-enabled"
                      onChange={() => props.setFieldValue('locked', true)}
                      checked={props.values.locked}
                    />
                    <Form.Check
                      inline
                      custom
                      disabled={props.isSubmitting || sendingAuthInfo}
                      id="locked-disabled"
                      type="radio"
                      label={t('common:no')}
                      name="locked-disabled"
                      onChange={() => props.setFieldValue('locked', false)}
                      checked={!props.values.locked}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="authinfo-field">
                  <Form.Label column sm={2}>
                    AuthInfo
                  </Form.Label>
                  <Col sm={10}>
                    <PanelButton
                      onClick={() => sendAuthInfo()}
                      disabled={props.isSubmitting || sendingAuthInfo}
                    >
                      {t('pages:rioDomainSettings.receiveAuthinfo')}
                    </PanelButton>
                    <Form.Text className="text-muted">
                      {t('pages:rioDomainSettings.authInfoAdvice')}.
                    </Form.Text>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || sendingAuthInfo}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/registro/dominios"
                  disabled={props.isSubmitting || sendingAuthInfo}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Settings);
