import React from 'react';
import { useTranslation } from 'react-i18next';

import SideMenuAccordion from 'components/SideMenu/components/SideMenuAccordion';
import SideMenuAccordionLink from 'components/SideMenu/components/SideMenuAccordionLink';

import { CloudIcon } from './styles';

const CloudOptions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SideMenuAccordion
      eventKey="4"
      text={t('components:sideMenu.cloud')}
      icon={<CloudIcon size={15} />}
      elements={[
        <SideMenuAccordionLink
          text={t('components:sideMenu.cloudServerCrontab')}
          to="/cloud/crontab"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.cloudSettings')}
          to="/cloud/configuracoes-cloud"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.sitesSettings')}
          to="/cloud/configuracoes-sites"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.phpSettings')}
          to="/cloud/configuracoes-php"
        />,
        <SideMenuAccordionLink
          text={t('components:sideMenu.cloudServerResourcesReport')}
          to="/cloud/monitoramento-recursos"
        />,
      ]}
    />
  );
};

export default CloudOptions;
