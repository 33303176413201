import React from 'react';

import hashIds from 'services/hashIds';

import { MenuAccordion, Text, List, ListItem } from './styles';

import SideMenuAccordionToggle from '../SideMenuAccordionToggle';

interface SideMenuAccordionProps {
  text: string;
  icon: React.ReactNode;
  elements: React.ReactNode[];
  eventKey: string;
}

const SideMenuAccordion: React.FC<SideMenuAccordionProps> = ({
  text,
  icon,
  elements,
  eventKey,
}) => {
  return (
    <>
      <SideMenuAccordionToggle eventKey={eventKey}>
        {icon}
        <Text>{text}</Text>
      </SideMenuAccordionToggle>
      <MenuAccordion eventKey={eventKey}>
        <List>
          {elements.map((element, index) => (
            <ListItem key={`${hashIds.encode(index)}sma`}>{element}</ListItem>
          ))}
        </List>
      </MenuAccordion>
    </>
  );
};

export default SideMenuAccordion;
