import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import * as Cookies from 'js-cookie';

import api from 'services/api';
import history from 'services/history';

import { ApiConfig } from 'config/Api';

import * as authActions from './actions';
import * as sitesActions from '../sites/actions';
import * as clientActions from '../client/actions';
import * as siteActions from '../site/actions';
import * as optionsActions from '../options/actions';

interface AuthorizationResponse {
  user_id: number;
  refresh_token: string;
  access_token: string;
  expires_in: number;
}

interface Site {
  idSite: number;
  site: string;
  idPlano: string;
  plano: string;
  idCliente: string;
  valor: string;
  tecnologia: string;
  ftp: 'TRUE' | 'FALSE';
  memoria: string;
  cpu: string;
}

interface SitesResponse {
  data: Site[];
}

interface Client {
  idCliente: number;
  idEntidadeFinanceira: number;
  email1: string;
  nome: string;
  responsavel: string;
  cpfCnpj: string;
  tipoConta: string;
  tipoPessoa: string;
  tipoCobranca: 'MULTIPLA' | 'UNICA';
  painelBloqueado: boolean;
}

interface ClientResponse {
  data: Client[];
}

interface SiteFavoriteItemResponse {
  idHostnet: number;
  idSite: number;
}

interface SiteFavoriteResponse {
  data: SiteFavoriteItemResponse[];
}

interface OptionsResponse {
  data: {
    idOpcao: number;
    ativo: true;
    codigo: string;
    descricao: string;
    opcao: string;
  }[];
}

type AuthorizationRequest = ActionType<typeof authActions.authorizationRequest>;

export function* authorize({ payload }: AuthorizationRequest) {
  try {
    const { token, scope, target } = payload;

    const secret = btoa(`${ApiConfig.CLIENT_ID}:${ApiConfig.CLIENT_SECRET}`);

    const headers = { Authorization: `Basic ${secret}` };

    const authResponse: AxiosResponse<AuthorizationResponse> = yield call(
      api.post,
      'oauth/token',
      {
        grant_type: 'hostnet_jwt',
        token,
        scope,
      },
      { headers },
    );

    const tokenInfo = authResponse.data;

    const decodedTokenInfo = jwtDecode<{ scopes: string[] }>(
      tokenInfo.access_token,
    );

    let accessType = '';

    if (decodedTokenInfo.scopes.includes('usuarioAdicional')) {
      accessType = 'usuarioAdicional';
    }

    api.defaults.headers.Authorization = `Bearer ${tokenInfo.access_token}`;

    const siteRequestCall = call(api.get, `painel/v1/sites`);
    const clientRequestCall = call(api.get, `clientes/v1/cliente`);
    const favoriteSiteCall = call(api.get, `painel/v1/site-favorito`);

    const [sitesResponse, clientResponse, favoriteSiteResponse]: [
      AxiosResponse<SitesResponse>,
      AxiosResponse<ClientResponse>,
      AxiosResponse<SiteFavoriteResponse>,
    ] = yield all([siteRequestCall, clientRequestCall, favoriteSiteCall]);

    if (clientResponse.data.data.length === 0) {
      history.push('/acesso-indisponivel');
      return;
    }

    const {
      idCliente,
      idEntidadeFinanceira,
      email1,
      nome,
      responsavel,
      cpfCnpj,
      tipoConta,
      tipoPessoa,
      tipoCobranca,
    } = clientResponse.data.data[0];

    const clientData = {
      idCliente,
      idEntidadeFinanceira,
      email1,
      nome,
      responsavel,
      cpfCnpj,
      tipoConta,
      tipoPessoa,
      tipoCobranca,
      contaComSite: false,
    };

    const favoriteSite = favoriteSiteResponse.data.data;

    const idFavoriteSite = favoriteSite.length > 0 ? favoriteSite[0].idSite : 0;

    const sites = sitesResponse.data.data.map(
      ({
        idSite,
        idPlano,
        idCliente: idClienteSite,
        site,
        plano,
        valor,
        tecnologia,
        ftp,
        memoria,
        cpu,
      }) => ({
        idSite,
        idPlano: parseInt(idPlano, 10),
        idCliente: parseInt(idClienteSite, 10),
        site,
        plano,
        valor,
        tecnologia,
        ftp: ftp === 'TRUE',
        memoria,
        cpu,
      }),
    );

    const authorizationData = {
      accessToken: tokenInfo.access_token,
      refreshToken: tokenInfo.refresh_token,
      userId: tokenInfo.user_id,
      expiresIn: tokenInfo.expires_in,
      accessType,
    };

    clientData.contaComSite = sites.length > 0;

    yield put(clientActions.clientSuccess({ client: clientData }));
    yield put(sitesActions.sitesSuccess({ sites }));

    let idSite: number | undefined;

    if (sites.length > 0) {
      idSite = idFavoriteSite || sites[0].idSite;
      const homeSite = sites.find(site => site.idSite === idSite);
      const siteName = homeSite === undefined ? '' : homeSite.site;

      yield put(sitesActions.setFavoriteSite(idFavoriteSite));

      yield put(siteActions.setSelectedSiteId(idSite));
      yield put(siteActions.setSelectedSiteName(siteName));

      yield put(siteActions.siteRequest(idSite));
    }

    if (sites.length === 0) {
      const optionsResponse: AxiosResponse<OptionsResponse> = yield call(
        api.get,
        `painel/v1/opcao`,
        {
          params: {
            ...(idSite && { idSite }),
          },
        },
      );

      const options = optionsResponse.data.data.map(
        ({ idOpcao, codigo, ativo, opcao }) => ({
          idOpcao: Number(idOpcao),
          codigo: String(codigo),
          ativo,
          opcao,
        }),
      );

      yield put(optionsActions.optionsSuccess({ options }));
    }

    yield put(authActions.authorizationSuccess(authorizationData));

    Cookies.set('auth', 'true');

    const route = target ? `${target}` : '/home';
    const initialRoute = route.startsWith('/') ? route : `/${route}`;

    history.push(initialRoute);
  } catch (err) {
    yield put(authActions.authorizationFailure());
  }
}

export function setToken({ payload }: ReturnType<typeof Object>) {
  if (!payload || !payload.auth) return;

  const { accessToken = null } = payload.auth;

  if (accessToken) {
    api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  }
}

export function logout() {
  Cookies.remove('auth');

  localStorage.setItem('painelhostnetwebts:logout', 'true');

  const currentLocation = window.location.href;
  const returnUrl = encodeURIComponent(
    `/login/authorize?clientId=painel4&state=${currentLocation}`,
  );

  window.location.href = `https://id.hostnet.com.br/login/?returnUrl=${returnUrl}`;
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/AUTHORIZATION_REQUEST', authorize),
  takeLatest('@auth/LOGOUT', logout),
]);
