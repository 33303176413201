import React, { useState } from 'react';
import { FaChartBar, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  format,
  addMonths,
  subMonths,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';
import DataTable from 'react-data-table-component';
import ptBr from 'date-fns/locale/pt-BR/index';

import { StoreState } from 'store/createStore';

import PageTitle from 'components/PageTitle';
import TableHeader from 'components/TableHeader';
import TableWrapper from 'components/TableWrapper';
import TableSubHeader from 'components/TableSubHeader';
import TableButton from 'components/TableButton';
import Loading from 'components/Loading';
import TableNoData from 'components/TableNoData';
import FilterInput from 'components/FilterInput';

import useRatchetHttpTraffic from 'hooks/useRatchetHttpTraffic';
import useRatchetEmailTraffic from 'hooks/useRatchetEmailTraffic';
import useRatchetHttpDisk from 'hooks/useRatchetHttpDisk';
import useRatchetDatabaseDisk from 'hooks/useRatchetDatabaseDisk';
import useRatchetWebProfiler from 'hooks/useRatchetWebProfiler';

import filter from 'utils/filter';
import { Container } from './styles';

import HttpTrafficChart from './components/HttpTrafficChart';
import EmailTrafficChart from './components/EmailTrafficChart';
import DatabaseDiskChart from './components/DatabaseDiskChart';

const Consumption: React.FC = () => {
  const { t } = useTranslation();

  const { site } = useSelector((state: StoreState) => state.site.info);

  const [emailTrafficFilterText, setEmailTrafficFilterText] = useState('');
  const [webProfilerFilterText, setWebProfilerFilterText] = useState('');

  const [selectedDate, setSelectedDate] = useState(new Date());

  const formattedDate = format(selectedDate, "MMMM 'de' yyyy", {
    locale: ptBr,
  });

  const initialDate = format(selectedDate, 'yyyy-MM-01');
  const finalDate = format(lastDayOfMonth(selectedDate), 'yyyy-MM-dd');

  const {
    ratchetHttpTraffic,
    isLoading: loadingHttpTraffic,
  } = useRatchetHttpTraffic(site, initialDate, finalDate);

  const {
    ratchetEmailTraffic,
    isLoading: loadingEmailTraffic,
  } = useRatchetEmailTraffic(site, initialDate, finalDate);

  const { ratchetHttpDisk, isLoading: loadingHttpDisk } = useRatchetHttpDisk(
    site,
    initialDate,
    finalDate,
  );

  const {
    ratchetDatabaseDisk,
    isLoading: loadingDatabaseDisk,
  } = useRatchetDatabaseDisk(site, initialDate, finalDate);

  const {
    ratchetWebProfiler,
    isLoading: loadingWebProfiler,
  } = useRatchetWebProfiler(site, initialDate, finalDate);

  const emailTrafficAccountFiltered = filter(
    ratchetEmailTraffic.trafegoEmails,
    ['email'],
    emailTrafficFilterText,
  );

  const webProfilerFiltered = filter(
    ratchetWebProfiler.profiles,
    ['arquivo', 'site'],
    webProfilerFilterText,
  );

  function verifyIfShouldDisableNextDate(ratchetDate: Date) {
    let nextDate = ratchetDate;
    nextDate = addMonths(ratchetDate, 1);

    return nextDate.getTime() > new Date().getTime();
  }

  const disableNextDate = verifyIfShouldDisableNextDate(selectedDate);

  const loading =
    loadingHttpDisk ||
    loadingDatabaseDisk ||
    loadingEmailTraffic ||
    loadingWebProfiler ||
    loadingHttpTraffic;

  return (
    <Container>
      <PageTitle
        title={t('titles:consumption.title')}
        description={t('titles:consumption.description')}
        icon={<FaChartBar color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title="Relatório de tráfego e espaço"
          description="Este relatório não está completo, pois o processamento deste mês ainda não foi finalizado."
          directHelpLink="https://ajuda.hostnet.com.br/trafego-espaco-em-disco-mysql-e-email/"
        />
        <TableSubHeader>
          <div className="d-flex align-items-center">
            <div className="table-section">Resumo - Consumo de tráfego</div>
          </div>

          <div className="d-flex align-items-center">
            <TableButton
              variant="secondary"
              className="secondary"
              disabled={loading}
              onClick={() => {
                setSelectedDate(subMonths(selectedDate, 1));
              }}
            >
              <FaChevronLeft />
            </TableButton>
            <span className="selected-date mr-2">{formattedDate}</span>
            <TableButton
              variant="secondary"
              className="secondary"
              disabled={disableNextDate || loading}
              onClick={() => {
                setSelectedDate(addMonths(selectedDate, 1));
              }}
            >
              <FaChevronRight />
            </TableButton>
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Tipo de tráfego',
                selector: 'type',
                sortable: true,
              },
              {
                name: 'Total',
                selector: 'total',
                cell: row => row.total,
                sortable: true,
              },
            ]}
            data={[
              {
                type: 'Tráfego mensal de HTTP',
                total: `${ratchetHttpTraffic.totalGb}GB`,
              },
              {
                type: 'Tráfego mensal de email',
                total: `${ratchetEmailTraffic.totalMb}MB`,
              },
              {
                type: 'Total do tráfego mensal',
                total: `${(
                  ratchetHttpTraffic.totalGb + ratchetEmailTraffic.totalGb
                ).toFixed(2)}GB`,
              },
            ]}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>

        <TableSubHeader>
          <div className="d-flex align-items-center">
            <div className="table-section">
              Resumo - Consumo de espaço em disco
            </div>
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Espaço em disco',
                selector: 'type',
              },
              {
                name: 'Total',
                selector: 'total',
              },
            ]}
            data={[
              {
                type: 'Média de espaço em disco',
                total: `${ratchetHttpDisk.mediaGb}GB`,
              },
            ]}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Tráfego do site (por dia do mês)"
          description="Tráfego de HTTP consumido (tráfego gerado pelo acesso ao seu site)."
        />

        <HttpTrafficChart data={ratchetHttpTraffic.trafegoDiario} />
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Tráfego do site (por dia do mês)"
          description="Tráfego de HTTP consumido (tráfego gerado pelo acesso ao seu site)."
        />

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            paginationPerPage={7}
            paginationRowsPerPageOptions={[7, 14, 21]}
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Data',
                selector: 'date',
                cell: row => {
                  const trafficDate = parseISO(row.date);

                  const formattedTrafficDate = format(
                    trafficDate,
                    'dd/MM/yyyy',
                  );
                  const dayOfTheWeek = format(trafficDate, 'EEEE', {
                    locale: ptBr,
                  });

                  return `${formattedTrafficDate} (${dayOfTheWeek})`;
                },
                width: '200px',
                sortable: true,
              },
              {
                name: 'Tráfego',
                selector: 'totalMb',
                cell: row => {
                  if (row.totalMb > 999) {
                    return `${row.totalGb}GB`;
                  }

                  return `${row.totalMb}MB`;
                },
                sortable: true,
              },
            ]}
            data={ratchetHttpTraffic.trafegoDiario}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Tráfego por email"
          description="Tráfego de email consumido (tráfego gerado pelo envio e recebimento de emails)."
        />

        <TableSubHeader>
          <div className="d-flex align-items-center" />
          <div>
            <FilterInput onChange={value => setEmailTrafficFilterText(value)} />
          </div>
        </TableSubHeader>

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Email',
                selector: 'email',
              },
              {
                name: 'Entrada',
                selector: 'totalRecebimentoMb',
                cell: row => `${row.totalRecebimentoMb}MB`,
              },
              {
                name: 'Saída',
                selector: 'totalEnvioMb',
                cell: row => `${row.totalEnvioMb}MB`,
              },
              {
                name: 'Total',
                selector: 'totalMb',
                cell: row => `${row.totalMb}MB`,
              },
            ]}
            data={emailTrafficAccountFiltered}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Tráfego por dia do mês (todos os e-mails)"
          description="Tráfego de email consumido (tráfego gerado pelo envio e recebimento de emails)."
        />

        <EmailTrafficChart data={ratchetEmailTraffic.trafegoDiario} />
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Tráfego por dia do mês (todos os e-mails)"
          description="Tráfego de email consumido (tráfego gerado pelo envio e recebimento de emails)."
        />

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            paginationPerPage={7}
            paginationRowsPerPageOptions={[7, 14, 21]}
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Data',
                selector: 'date',
                cell: row => {
                  const trafficDate = parseISO(row.date);

                  const formattedTrafficDate = format(
                    trafficDate,
                    'dd/MM/yyyy',
                  );
                  const dayOfTheWeek = format(trafficDate, 'EEEE', {
                    locale: ptBr,
                  });

                  return `${formattedTrafficDate} (${dayOfTheWeek})`;
                },
                width: '200px',
                sortable: true,
              },
              {
                name: 'Tráfego',
                selector: 'totalMb',
                cell: row => {
                  if (row.totalMb > 999) {
                    return `${row.totalGb}GB`;
                  }

                  return `${row.totalMb}MB`;
                },
                sortable: true,
              },
            ]}
            data={ratchetEmailTraffic.trafegoDiario}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Espaço Web/MySQL (por dia do mês)"
          description="Espaço em disco utilizado pelo banco de dados"
        />

        <DatabaseDiskChart data={ratchetHttpDisk.consumoDiario} />
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Espaço Web/MySQL (por dia do mês)"
          description="Espaço em disco utilizado pelo banco de dados"
        />

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            paginationPerPage={7}
            paginationRowsPerPageOptions={[7, 14, 21]}
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Data',
                selector: 'date',
                cell: row => {
                  const trafficDate = parseISO(row.date);

                  const formattedTrafficDate = format(
                    trafficDate,
                    'dd/MM/yyyy',
                  );
                  const dayOfTheWeek = format(trafficDate, 'EEEE', {
                    locale: ptBr,
                  });

                  return `${formattedTrafficDate} (${dayOfTheWeek})`;
                },
                width: '200px',
                sortable: true,
              },
              {
                name: 'Espaço',
                selector: 'totalGb',
                cell: row => {
                  return `${row.totalGb}GB`;
                },
                sortable: true,
              },
            ]}
            data={ratchetHttpDisk.consumoDiario}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="Média por banco de dados"
          description="Espaço em disco utilizado pelo banco de dados"
        />

        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Banco',
                selector: 'banco',
                width: '200px',
                sortable: true,
              },
              {
                name: 'Média',
                selector: 'mediaMb',
                cell: row => {
                  return `${row.mediaMb}MB`;
                },
                sortable: true,
              },
            ]}
            data={ratchetDatabaseDisk.consumoBancos}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>

      <TableWrapper>
        <TableHeader
          title="WebProfiler"
          description="O WebProfiler registra a maior parte dos arquivos que utilizaram tempo superior ou igual a 1 segundo durante uma execução no servidor."
        />
        <TableSubHeader>
          <div className="d-flex align-items-center" />
          <div>
            <FilterInput onChange={value => setWebProfilerFilterText(value)} />
          </div>
        </TableSubHeader>
        <div className="datatables-table">
          <DataTable
            noHeader
            dense
            pagination
            subHeaderWrap
            progressPending={loading}
            columns={[
              {
                name: 'Site',
                selector: 'site',
                maxWidth: '250px',
              },
              {
                name: 'Arquivo',
                selector: 'arquivo',
              },
              {
                name: 'Execuções',
                selector: 'qtdExec',
                width: '100px',
              },
              {
                name: 'Tempo gasto',
                selector: 'tempoTotal',
                width: '120px',
              },
              {
                name: 'Tempo mínimo',
                selector: 'tempoMin',
                width: '120px',
              },
              {
                name: 'Tempo máximo',
                selector: 'tempoMax',
                width: '120px',
              },
            ]}
            data={webProfilerFiltered}
            noDataComponent={<TableNoData />}
            progressComponent={<Loading />}
          />
        </div>
      </TableWrapper>
    </Container>
  );
};

export default Consumption;
