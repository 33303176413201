import React from 'react';
import { useTranslation } from 'react-i18next';

import TableActionsWrapper from 'components/TableActionsWrapper';
import TableButton from 'components/TableButton';

interface EmailService {
  ativo: boolean;
  nome: string;
  servico: string;
  site: string;
}

interface EmailServicesActionsProps {
  loading: boolean;
  selectedCount: number;
  selectedRows: EmailService[];
  addEmailService: (site: string, service: string) => void;
  removeEmailService: (site: string) => void;
}

const EmailServicesActions: React.FC<EmailServicesActionsProps> = ({
  loading,
  selectedCount,
  selectedRows,
  addEmailService,
  removeEmailService,
}) => {
  const { t } = useTranslation();

  const [siteService] = selectedRows;

  if (selectedCount > 1) {
    return <div />;
  }

  return (
    <TableActionsWrapper loading={loading}>
      {siteService.servico === '' && !siteService.ativo && (
        <>
          <TableButton
            className="mr-2"
            size="sm"
            disabled={loading}
            onClick={() => addEmailService(siteService.site, 'ULTRAMAIL')}
          >
            {t('pages:emailServices.activateUltramail')}
          </TableButton>
          <TableButton
            className="mr-2"
            size="sm"
            disabled={loading}
            onClick={() => addEmailService(siteService.site, 'GMAIL')}
          >
            {t('pages:emailServices.activateGSuite')}
          </TableButton>
        </>
      )}

      {siteService.servico === 'ULTRAMAIL' && siteService.ativo && (
        <>
          <TableButton
            className="mx-1"
            variant="danger"
            onClick={() => removeEmailService(siteService.site)}
            disabled={loading}
          >
            {`${t('common:delete')} Ultramail`}
          </TableButton>
        </>
      )}

      {siteService.servico === 'GMAIL' && siteService.ativo && (
        <TableButton
          className="mx-1"
          variant="danger"
          onClick={() => removeEmailService(siteService.site)}
          disabled={loading}
        >
          {t('pages:emailServices.deleteGSuite')}
        </TableButton>
      )}
    </TableActionsWrapper>
  );
};

export default EmailServicesActions;
