import styled, { css } from 'styled-components';
import { FaFile, FaFolder } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

export const Container = styled.div`
  /* .rdt_TableRow:first-of-type [name='select-row-undefined'] {
    display: none;
  } */
`;

const iconCSS = css`
  margin: 10px;
  color: white;
`;

export const FolderIcon = styled(FaFolder)`
  ${iconCSS}
`;

export const FileIcon = styled(FaFile)`
  ${iconCSS}
`;

export const Filename = styled.div`
  color: var(--color-midnight-blue);
  cursor: pointer;
`;

export const Foldername = styled.div`
  color: var(--color-midnight-blue);
  cursor: pointer;
`;

export const Filesize = styled.small`
  margin-top: 2px;
  display: block;
`;

export const StyledIcon = styled.div`
  cursor: pointer;
  background-color: var(--color-blue);
  &.blue {
    background-color: var(--color-blue);
  }
  &.gray {
    background-color: gray;
  }
  border-radius: 50%;
  margin: 6px 8px 6px 0px;
`;

export const BreadcrumbItem = styled(Button)`
  padding: 0;
  color: var(--color-blue);
  font-size: 14px;
`;

export const StyledTableFooter = styled.div`
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
