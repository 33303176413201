import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaCloud } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { format, subMonths } from 'date-fns';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';

import PageTitle from 'components/PageTitle';
import Error from 'components/Error';
import Loading from 'components/Loading';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';

import api from 'services/api';

import { StoreState } from 'store/createStore';

import CustomizedLabel from './components/CustomizedLabel';

import { Container } from './styles';

interface CpuResponse {
  data: {
    dataHorario: string;
    horarioFormatado: string;
    limite: number;
    limiteFormatado: string;
    consumo: number;
    consumoFormatado: string;
  }[];
}

interface MemoryResponse {
  data: {
    dataHorario: string;
    horarioFormatado: string;
    limite: number;
    limiteFormatado: string;
    consumo: number;
    consumoFormatado: string;
  }[];
}

interface DeadTasksResponse {
  data: {
    idDomain: number;
    site: string;
    data: string;
    processos: number;
    dataFormatada: string;
  }[];
}

interface Cpu {
  dataHorario: string;
  horarioFormatado: string;
  limite: number;
  limiteFormatado: string;
  consumo: number;
  consumoFormatado: string;
}

interface Memory {
  dataHorario: string;
  horarioFormatado: string;
  limite: number;
  limiteFormatado: string;
  consumo: number;
  consumoFormatado: string;
}

interface DeadTask {
  idDomain: number;
  site: string;
  data: string;
  processos: number;
  dataFormatada: string;
}

const ResourceMonitoring: React.FC = () => {
  const { t } = useTranslation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cpu, setCpu] = useState<Cpu[]>([]);
  const [memory, setMemory] = useState<Memory[]>([]);
  const [tasks, setTasks] = useState<DeadTask[]>([]);

  const { site } = useSelector((state: StoreState) => state.site.info);

  useEffect(() => {
    setCpu([]);
    setMemory([]);

    async function loadCpu() {
      const params = {
        site,
        servico: 'cpu',
      };

      const cpuResponse = await api.get<CpuResponse>(
        'painel/v1/container/consumo',
        { params },
      );

      if (cpuResponse.data.data.length === 0) {
        return;
      }

      const initialCpuResponse = [
        cpuResponse.data.data[cpuResponse.data.data.length - 3],
        cpuResponse.data.data[cpuResponse.data.data.length - 1],
      ];

      const lastCpuResponse = [
        cpuResponse.data.data[cpuResponse.data.data.length - 1],
      ];

      setCpu(currentCpu =>
        currentCpu.length === 0
          ? [...currentCpu, ...initialCpuResponse]
          : [...currentCpu, ...lastCpuResponse],
      );
    }

    async function loadMemory() {
      const params = {
        site,
        servico: 'memory',
      };

      const memoryResponse = await api.get<MemoryResponse>(
        'painel/v1/container/consumo',
        { params },
      );

      if (memoryResponse.data.data.length === 0) {
        return;
      }

      const initialMemoryResponse = [
        memoryResponse.data.data[memoryResponse.data.data.length - 3],
        memoryResponse.data.data[memoryResponse.data.data.length - 1],
      ];

      const lastMemoryResponse = [
        memoryResponse.data.data[memoryResponse.data.data.length - 1],
      ];

      setMemory(currentMemory =>
        currentMemory.length === 0
          ? [...currentMemory, ...initialMemoryResponse]
          : [...currentMemory, ...lastMemoryResponse],
      );
    }

    loadCpu();
    loadMemory();

    const intervalId = setInterval(() => {
      loadCpu();
      loadMemory();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [site]);

  useEffect(() => {
    async function loadDeadTasks() {
      try {
        setError(false);
        setLoading(true);

        const selectedDate = new Date();

        const startDate = format(subMonths(selectedDate, 3), 'yyyy-MM-dd');
        const endDate = format(selectedDate, 'yyyy-MM-dd');

        const params = {
          site,
          ...(startDate && { dataInicial: startDate }),
          ...(endDate && { dataFinal: endDate }),
        };

        const deadTasksResponse = await api.get<DeadTasksResponse>(
          'painel/v1/ratchet/processo-morto',
          { params },
        );

        setTasks(deadTasksResponse.data.data);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadDeadTasks();
  }, [site]);

  let limit = 15;

  if (window.screen.width < 400) {
    limit = 3;
  } else if (window.screen.width < 1200) {
    limit = 8;
  }

  if (cpu.length > limit) {
    cpu.splice(0, 1);
  }

  if (memory.length > limit) {
    memory.splice(0, 1);
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:cloud.title')}
        description={t('titles:cloud.description')}
        icon={<FaCloud color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:resourceMonitoring.cpuTitle')}
          description={t('pages:resourceMonitoring.cpuDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/monitoramento-de-recursos/#Consumo_de_CPU"
        />
        <Card.Body style={{ padding: 0, marginLeft: 10 }}>
          {cpu.length === 0 ? (
            <p style={{ marginTop: 20, marginLeft: 20 }}>
              {t('pages:resourceMonitoring.cpuEmpty')}
            </p>
          ) : (
            <ResponsiveContainer width="95%" height="95%" minHeight={500}>
              <LineChart
                data={cpu}
                margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              >
                <Line
                  name="%"
                  type="monotone"
                  dataKey="consumoFormatado"
                  stroke="#95358B"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                >
                  <LabelList content={<CustomizedLabel measure="" />} />
                </Line>
                <CartesianGrid
                  horizontal={false}
                  stroke="#ccc"
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey="horarioFormatado"
                  padding={{ left: 20, right: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                />
                <YAxis
                  type="number"
                  domain={[0, cpu[0].limiteFormatado]}
                  unit="%"
                  padding={{ top: 20, bottom: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                  tickCount={9}
                />
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={50}
                  wrapperStyle={{ left: 75, fontWeight: 'bold' }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:resourceMonitoring.memoryTitle')}
          description={t('pages:resourceMonitoring.memoryDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/monitoramento-de-recursos/#Consumo_de_Memoria"
        />
        <Card.Body style={{ padding: 0, marginLeft: 10 }}>
          {memory.length === 0 ? (
            <p style={{ marginTop: 20, marginLeft: 20 }}>
              {t('pages:resourceMonitoring.memoryEmpty')}
            </p>
          ) : (
            <ResponsiveContainer width="95%" height="95%" minHeight={500}>
              <LineChart
                data={memory}
                margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              >
                <Line
                  name="MB"
                  type="monotone"
                  dataKey="consumoFormatado"
                  stroke="#95358B"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                >
                  <LabelList content={<CustomizedLabel measure="" />} />
                </Line>
                <CartesianGrid
                  horizontal={false}
                  stroke="#ccc"
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey="horarioFormatado"
                  padding={{ left: 20, right: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                />
                <YAxis
                  type="number"
                  domain={[0, memory[0].limiteFormatado]}
                  unit="MB"
                  padding={{ top: 20, bottom: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                  tickCount={9}
                />
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={50}
                  wrapperStyle={{ left: 75, fontWeight: 'bold' }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:resourceMonitoring.deadTaskTitle')}
          description={t('pages:resourceMonitoring.deadTaskDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/monitoramento-de-recursos/#Monitoramento_de_processos_mortos"
        />
        <Card.Body style={{ padding: 0, marginLeft: 10 }}>
          {tasks.length === 0 ? (
            <p style={{ marginTop: 20, marginLeft: 20 }}>
              {t('pages:resourceMonitoring.deadTaskEmpty')}
            </p>
          ) : (
            <ResponsiveContainer width="95%" height="95%" minHeight={500}>
              <LineChart
                data={tasks}
                margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
              >
                <Line
                  name="Processos mortos"
                  type="monotone"
                  dataKey="processos"
                  stroke="#95358B"
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                >
                  <LabelList content={<CustomizedLabel />} />
                </Line>
                <CartesianGrid
                  horizontal={false}
                  stroke="#ccc"
                  strokeDasharray="3 3"
                />
                <XAxis
                  dataKey="dataFormatada"
                  padding={{ left: 20, right: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                />
                <YAxis
                  padding={{ top: 20, bottom: 20 }}
                  fontSize="12px"
                  fontWeight="bold"
                  tickCount={9}
                />
                <Tooltip />
                <Legend
                  align="left"
                  verticalAlign="top"
                  height={50}
                  wrapperStyle={{ left: 75, fontWeight: 'bold' }}
                />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Card.Body>
      </FormWrapper>
    </Container>
  );
};

export default ResourceMonitoring;
