import { useQuery } from 'react-query';

import api from 'services/api';

type TipoCobranca = 'MULTIPLA' | 'UNICA';

type PaymentSessionResponse = { data: [] } | { data: { chave: string } };

interface PaymentSession {
  active: boolean;
  key: string;
}

const initialData: PaymentSession = {
  active: false,
  key: '',
};

function formatPaymentSession(
  paymentSessionResponse: PaymentSessionResponse,
): PaymentSession {
  if (Array.isArray(paymentSessionResponse.data)) {
    return {
      active: false,
      key: '',
    };
  }

  return {
    active: true,
    key: paymentSessionResponse.data.chave,
  };
}

async function listPaymentSession(
  idSite: number,
  tipoCobranca: TipoCobranca,
): Promise<PaymentSession> {
  const paymentSessionResponse = await api.get<PaymentSessionResponse>(
    `assine/v1/pagamento/sessao-ativa`,
    {
      params: {
        ...(tipoCobranca === 'MULTIPLA' && {
          idSite,
        }),
      },
    },
  );

  const paymentSession = formatPaymentSession(paymentSessionResponse.data);

  return paymentSession;
}

export default function usePaymentSession(
  idSite: number,
  tipoCobranca: TipoCobranca,
) {
  const { data, ...rest } = useQuery<PaymentSession>(
    ['paymentSession', idSite],
    () => listPaymentSession(idSite, tipoCobranca),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const paymentSession = data || initialData;

  return {
    paymentSession,
    ...rest,
  };
}
