import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Loading from 'components/Loading';
import Error from 'components/Error';

import { StoreState } from 'store/createStore';

import useUltramail from 'hooks/useUltramail';

import { Container } from './styles';

const Cryptography: React.FC = () => {
  const { t } = useTranslation();

  const { idSite } = useSelector((state: StoreState) => state.site.info);

  const [selectedSite, setSelectedSite] = useState('');
  const [servers, setServers] = useState({
    mboxSslHost: '',
    smtpSslHost: '',
  });

  const { isFetching, ultramail, error } = useUltramail(idSite);

  useEffect(() => {
    if (!ultramail.possuiSiteComUltramailAtivo) {
      return;
    }

    const activeUltramail = ultramail.quotaDetalhada.find(
      ultramailSite => ultramailSite.ativo,
    );

    if (activeUltramail) {
      setSelectedSite(activeUltramail.site);
    }
  }, [ultramail]);

  useEffect(() => {
    const siteServersInfo = ultramail.servidores.find(
      serverInfo => serverInfo.site === selectedSite,
    );

    if (siteServersInfo) {
      setServers({
        mboxSslHost: siteServersInfo.mboxSslHost,
        smtpSslHost: siteServersInfo.smtpSslHost,
      });
    }
  }, [selectedSite, ultramail.servidores]);

  if (isFetching) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:emailCryptography.title')}
          description={t('pages:emailCryptography.description')}
          helpContent={
            <div>
              <p>{t('pages:emailCryptography.helpContent.p1')}</p>
              <p>{t('pages:emailCryptography.helpContent.p2')}</p>
              <p>
                <strong>{t('pages:emailCryptography.helpContent.p3')}</strong>
              </p>
              <p>{t('pages:emailCryptography.helpContent.p4')}</p>
              <p>{t('pages:emailCryptography.helpContent.p5')}</p>
              <p>
                <strong>{t('pages:emailCryptography.helpContent.p6')}</strong>
              </p>
              <p>{t('pages:emailCryptography.helpContent.p7')}</p>
            </div>
          }
          helpLinks={[
            {
              url:
                'https://www.hostnet.com.br/info/ultramail-com-criptografia/',
              text: t('pages:emailCryptography.linkCryptography'),
            },
            {
              url:
                'https://www.hostnet.com.br/info/ultramail-com-criptografia/#Configurando_o_programa_de_e-mail_usando_TLS',
              text: t('pages:emailCryptography.linkConfigureProgram'),
            },
          ]}
        />

        <Form>
          <Card.Body className="fieldset">
            {ultramail.possuiSiteComUltramailAtivo && (
              <div>
                <Form.Group as={Row} controlId="form-site">
                  <Form.Label column sm={2}>
                    {t('common:site')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      onChange={event => setSelectedSite(event.target.value)}
                    >
                      {ultramail.quotaDetalhada.map(
                        siteUltramail =>
                          siteUltramail.ativo && (
                            <option
                              key={siteUltramail.site}
                              value={siteUltramail.site}
                            >
                              {siteUltramail.site.toLowerCase()}
                            </option>
                          ),
                      )}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <div>
                  <Form.Group as={Row} controlId="form-mbox">
                    <Form.Label column sm={2}>
                      {t('pages:emailCryptography.mbox')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={servers.mboxSslHost}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="form-smtp">
                    <Form.Label column sm={2}>
                      {t('pages:emailCryptography.smtp')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        plaintext
                        readOnly
                        value={servers.smtpSslHost}
                      />
                    </Col>
                  </Form.Group>
                </div>
              </div>
            )}

            <div>
              {!ultramail.possuiSiteComUltramailAtivo && (
                <p>{t('pages:emailCryptography.noServers')}</p>
              )}
            </div>
          </Card.Body>
        </Form>
      </FormWrapper>
    </Container>
  );
};

export default Cryptography;
