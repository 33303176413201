import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';
import { Formik } from 'formik';

import * as Yup from 'yup';

import Error from 'components/Error';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Loading from 'components/Loading';

import { StoreState } from 'store/createStore';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useUltramail from 'hooks/useUltramail';

import { Container } from './styles';

type SpfOption = 'fail' | 'softfail' | 'neutral';
type DmarcOption = 'reject' | 'quarantine' | 'none';

type SecuritySettingsItemResponse = {
  domain: string;
  blockSpam: string;
  spf: { policy: SpfOption };
  dmarc: { policy: DmarcOption };
};

interface SecuritySettingsResponse {
  data: SecuritySettingsItemResponse;
}

interface SecuritySettings {
  domain: string;
  blockSpam: string;
  spf: SpfOption;
  dmarc: DmarcOption;
}

interface EditSecuritySettingsResponse {
  data: SecuritySettingsItemResponse;
}

interface EditSecuritySettingsFormValues {
  domain: string;
  blockSpam: string;
  spf: SpfOption;
  dmarc: DmarcOption;
}

const SecuritySettings: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);

  const { ultramail } = useUltramail(idSite);

  const [selectedSite, setSelectedSite] = useState(site);

  const [securitySettings, setSecuritySettings] = useState<SecuritySettings>({
    domain: site,
    blockSpam: 'false',
    spf: 'fail',
    dmarc: 'reject',
  });

  const formatSecuritySettings = useCallback(
    ({ domain, blockSpam, spf, dmarc }: SecuritySettingsItemResponse) => ({
      domain,
      blockSpam: String(blockSpam),
      spf: spf.policy,
      dmarc: dmarc.policy,
    }),

    [],
  );

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!ultramail.possuiSiteComUltramailAtivo) {
      return;
    }

    const activeUltramail = ultramail.quotaDetalhada.find(
      ultramailSite => ultramailSite.ativo,
    );

    if (activeUltramail) {
      setSelectedSite(activeUltramail.site);
    }
  }, [ultramail]);

  useEffect(() => {
    async function loadSecuritySettings() {
      try {
        setError(false);
        setLoading(true);

        const securitySettingsResponse = await api.get<SecuritySettingsResponse>(
          `painel/v1/email/protecao`,
          {
            params: { site: selectedSite },
          },
        );

        const formattedSecuritySettings = formatSecuritySettings(
          securitySettingsResponse.data.data,
        );

        setSecuritySettings(formattedSecuritySettings);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadSecuritySettings();
  }, [selectedSite, formatSecuritySettings]);

  const editSecuritySettings = useCallback(
    async (values: EditSecuritySettingsFormValues) => {
      try {
        const { domain, blockSpam, spf, dmarc } = values;

        const securitySettingsResponse = await api.put<EditSecuritySettingsResponse>(
          `painel/v1/email/protecao`,
          {
            site: domain,
            blockSpam: blockSpam === 'true',
            spf: {
              policy: spf,
            },
            dmarc: {
              policy: dmarc,
            },
          },
        );

        const formattedSecuritySettings = formatSecuritySettings(
          securitySettingsResponse.data.data,
        );

        setSecuritySettings(formattedSecuritySettings);

        toast.fire(t('pages:emailSecuritySettings.successSettings'));
      } catch (err) {
        swal.fire({
          title: t('pages:emailSecuritySettings.failSettings'),
          html: err.response && err.response.data.error_description,
        });
      }
    },
    [formatSecuritySettings, t],
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('pages:emails.title')}
        description={t('pages:emails.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:emailSecuritySettings.title')}
          description={t('pages:emailSecuritySettings.description')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-de-seguranca-de-e-mail/"
        />
        <Formik
          enableReinitialize
          initialValues={{
            domain: securitySettings.domain,
            blockSpam: securitySettings.blockSpam,
            spf: securitySettings.spf,
            dmarc: securitySettings.dmarc,
          }}
          validationSchema={Yup.object().shape({
            blockSpam: Yup.string().required(
              t('pages:emailSecuritySettings.validation.informValidRadio'),
            ),
            spf: Yup.string().required(
              t('pages:emailSecuritySettings.validation.informValidRadio'),
            ),
            dmarc: Yup.string().required(
              t('pages:emailSecuritySettings.validation.informValidRadio'),
            ),
          })}
          onSubmit={editSecuritySettings}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                {ultramail.possuiSiteComUltramailAtivo && (
                  <div>
                    <Form.Group as={Row} controlId="form-domain">
                      <Form.Label column sm={2}>
                        {t('common:site')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="domain"
                          value={props.values.domain}
                          onChange={event =>
                            setSelectedSite(event.target.value)
                          }
                          disabled={props.isSubmitting}
                        >
                          {ultramail.quotaDetalhada.map(
                            siteUltramail =>
                              siteUltramail.ativo && (
                                <option
                                  key={siteUltramail.site}
                                  value={siteUltramail.site}
                                >
                                  {siteUltramail.site.toLowerCase()}
                                </option>
                              ),
                          )}
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <div>
                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:emailSecuritySettings.blockSpam')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="blockSpam-false"
                            name="blockSpam"
                            label={t(
                              'pages:emailSecuritySettings.basicBlockSpam',
                            )}
                            value="false"
                            checked={props.values.blockSpam === 'false'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.blockSpam &&
                              !!props.errors.blockSpam
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="blockSpam-true"
                            name="blockSpam"
                            label={t(
                              'pages:emailSecuritySettings.advancedBlockSpam',
                            )}
                            value="true"
                            checked={props.values.blockSpam === 'true'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.blockSpam &&
                              !!props.errors.blockSpam
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Text className="text-muted">
                            {t('pages:emailSecuritySettings.infoBlockSpam')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {props.errors.blockSpam}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:emailSecuritySettings.spf')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="spf-fail"
                            name="spf"
                            label={t('pages:emailSecuritySettings.failSpf')}
                            value="fail"
                            checked={props.values.spf === 'fail'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.spf && !!props.errors.spf
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="spf-softfail"
                            name="spf"
                            label={t('pages:emailSecuritySettings.softfailSpf')}
                            value="softfail"
                            checked={props.values.spf === 'softfail'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.spf && !!props.errors.spf
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="spf-neutral"
                            name="spf"
                            label={t('pages:emailSecuritySettings.neutralSpf')}
                            value="neutral"
                            checked={props.values.spf === 'neutral'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.spf && !!props.errors.spf
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Text className="text-muted">
                            {t('pages:emailSecuritySettings.infoSpf')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {props.errors.spf}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row}>
                        <Form.Label column sm={2}>
                          {t('pages:emailSecuritySettings.dmarc')}
                        </Form.Label>
                        <Col sm={10}>
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="dmarc-reject"
                            name="dmarc"
                            label={t('pages:emailSecuritySettings.rejectDmarc')}
                            value="reject"
                            checked={props.values.dmarc === 'reject'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.dmarc && !!props.errors.dmarc
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="dmarc-quarantine"
                            name="dmarc"
                            label={t(
                              'pages:emailSecuritySettings.quarantineDmarc',
                            )}
                            value="quarantine"
                            checked={props.values.dmarc === 'quarantine'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.dmarc && !!props.errors.dmarc
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Check
                            inline
                            custom
                            type="radio"
                            id="dmarc-none"
                            name="dmarc"
                            label={t('pages:emailSecuritySettings.noneDmarc')}
                            value="none"
                            checked={props.values.dmarc === 'none'}
                            onChange={props.handleChange}
                            isInvalid={
                              !!props.touched.dmarc && !!props.errors.dmarc
                            }
                            disabled={props.isSubmitting}
                          />
                          <Form.Text className="text-muted">
                            {t('pages:emailSecuritySettings.infoDmarc')}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            {props.errors.dmarc}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </div>
                )}
                <div>
                  {!ultramail.possuiSiteComUltramailAtivo && (
                    <p>{t('pages:emailSecuritySettings.noSecuritySettings')}</p>
                  )}
                </div>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={props.isSubmitting || !props.isValid}
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>

      <FormWrapper>
        <FormHeader
          title={t('pages:emailSecuritySettings.advancedTitle')}
          description={t('pages:emailSecuritySettings.advancedDescription')}
          directHelpLink="https://ajuda.hostnet.com.br/configuracoes-de-seguranca-de-e-mail/"
        />
        <Card.Body>
          <PanelButton
            className="mr-1"
            forwardedAs={Link}
            to="/emails/configuracoes-seguranca/spf"
            disabled=""
          >
            {t('pages:emailSecuritySettings.buttonSpf')}
          </PanelButton>
          <PanelButton
            className="mr-1"
            forwardedAs={Link}
            to="/emails/configuracoes-seguranca/dmarc"
            disabled=""
          >
            {t('pages:emailSecuritySettings.buttonDmarc')}
          </PanelButton>
          <PanelButton
            forwardedAs={Link}
            to="/emails/configuracoes-seguranca/dkim"
            disabled=""
          >
            {t('pages:emailSecuritySettings.buttonDkim')}
          </PanelButton>
        </Card.Body>
      </FormWrapper>
    </Container>
  );
};

export default SecuritySettings;
