import styled from 'styled-components';
import { Badge } from 'react-bootstrap';

export const Text = styled.div`
  flex: 1;
`;

export const OptionButton = styled.button`
  display: flex;
  height: 44px;
  font-size: 14px;

  width: 100%;

  outline: none;
  border-left: 3px solid transparent !important;
  border: none;
  text-align: left;

  align-items: center;

  margin: 5px 0 0;
  padding: 7px 10px 7px 20px;

  text-decoration: none;
  //transition: all 0s linear;
  color: var(--color-cadet-blue);

  &.active {
    color: var(--color-cadet-blue);
    background: var(--color-midnight-blue);
    border-left-color: var(--color-light-blue);
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;

export const MenuBadge = styled(Badge)`
  padding: 4px 7px;
  margin-right: 20px;
`;
