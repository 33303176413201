import styled from 'styled-components';

export const Container = styled.div`
  input[type='range']::-webkit-slider-runnable-track {
    background: #9d9d9d;
    height: 2px;
  }

  input[type='range']::-moz-range-track {
    background: #9d9d9d;
    height: 2px;
  }

  input[type='range']::-ms-track {
    background: #9d9d9d;
    height: 2px;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #2c4593;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: -8px;
    position: relative;
    z-index: 1;
  }

  input[type='range']::-moz-range-thumb {
    -moz-appearance: none;
    background: #2c4593;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: -8px;
    position: relative;
    z-index: 1;
  }

  input[type='range']::-ms-thumb {
    background: #2c4593;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-top: -8px;
    position: relative;
    z-index: 1;
  }

  input[type='range']::-moz-focus-outer {
    border: 0;
  }

  .support {
    height: 2px;
    width: 0%;
    background-color: #2c4593;
    background-color: lightgray;
    margin-top: -7.5px;
    margin-bottom: 12px;
    position: relative;
  }
`;

export const Range = styled.input.attrs({
  type: 'range',
})`
  width: 100%;
  -webkit-appearance: none;
`;
