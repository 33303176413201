import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

import TableNoData from 'components/TableNoData';
import TableIconButton from 'components/TableIconButton';

type AvailableProduct = {
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  siteEspaco: number;
  contratado: boolean;
  trafego: number;
  bancoQtd: number;
  bancoEspaco: number;
  email: number;
  descricao: string;
  valor: number;
  valorFormatado: string;
  categoria: string;
  expansao: number;
  expansaoMaxima: number;
  unidade: string;
  jaPossuiProdutoDaCategoria: boolean;
};

type TableListCombinedProductsProps = {
  products: AvailableProduct[];
  onClick: (idProduct: number, amount: number) => void;
  submitting: boolean;
};

const TableListCombinedProduct: React.FC<TableListCombinedProductsProps> = ({
  products,
  onClick,
  submitting,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="datatables-table">
        <DataTable
          dense
          noHeader
          pagination
          data={products}
          columns={[
            {
              name: t('common:name'),
              selector: 'produto',
              sortable: true,
              minWidth: '200px',
            },
            {
              name: 'Espaço',
              selector: 'siteEspaco',
              sortable: true,
              cell: row => `${row.siteEspaco / 1024}GB`,
            },
            {
              name: 'Tráfego',
              selector: 'trafego',
              sortable: true,
              cell: row => `${row.trafego / 1024}GB`,
            },
            {
              name: 'E-mail',
              selector: 'email',
              sortable: true,
              cell: row => `${row.email / 1024}GB`,
            },
            {
              name: 'Banco de dados',
              selector: 'email',
              sortable: true,
              cell: row => `${row.bancoEspaco}MB - ${row.bancoQtd} BDs`,
            },
            {
              name: 'Preço',
              selector: 'valorFormatado',
              sortable: true,
            },
            {
              name: '',
              cell: row => {
                if (row.contratado) {
                  return <span className="font-italic">Contratado</span>;
                }

                if (row.jaPossuiProdutoDaCategoria) {
                  return <div />;
                }

                return (
                  <div className="pt-2 pb-2">
                    <TableIconButton
                      className="btn-buy"
                      onClick={() => onClick(row.idProduto, 1)}
                      disabled={submitting}
                    >
                      Contratar
                    </TableIconButton>
                  </div>
                );
              },
              width: '130px',
            },
          ]}
          noDataComponent={<TableNoData />}
          paginationComponentOptions={{
            rowsPerPageText: t('common:registersPerPage'),
            rangeSeparatorText: t('common:of'),
          }}
        />
      </div>
    </div>
  );
};

export default TableListCombinedProduct;
