import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaIdCard, FaMoneyCheck } from 'react-icons/fa';
import DataTable from 'react-data-table-component';

import PageTitle from 'components/PageTitle';
import TableWrapper from 'components/TableWrapper';
import TableHeader from 'components/TableHeader';
import TableSubHeader from 'components/TableSubHeader';
import FilterInput from 'components/FilterInput';
import TableIconButton from 'components/TableIconButton';

import filter from 'utils/filter';

import { Container } from './styles';

interface DealsAndPartneships {
  parceiro: string;
  descricao: string;
  link: string;
}

const DealsAndPartneshipsDefaults: DealsAndPartneships[] = [
  {
    parceiro: 'Desconto MX Cursos',
    descricao:
      'O MX Cursos possui uma série de cursos online, 100% em vídeo aulas com suporte e certificado, que ensina você a desenvolver seus próprios sites, lojas virtuais e blogs e clientes Hostnet tem descontos especiais.',
    link: '/parcerias-e-promocoes/mx-cursos',
  },
];

const DealsAndPartneships: React.FC = () => {
  const { t } = useTranslation();

  const dealsAndPartneships = DealsAndPartneshipsDefaults;

  const [
    filteredDealsAndPartneships,
    setFilteredDealsAndPartneships,
  ] = useState<DealsAndPartneships[]>([]);

  const [
    dealsAndPartneshipsFilterText,
    setDealsAndPartneshipsFilterText,
  ] = useState('');

  useEffect(() => {
    setFilteredDealsAndPartneships(
      filter(dealsAndPartneships, ['parceiro'], dealsAndPartneshipsFilterText),
    );
  }, [dealsAndPartneshipsFilterText, dealsAndPartneships]);

  const columns = [
    {
      name: t('pages:dealsAndPartneships.partneship'),
      selector: 'parceiro',
      sortable: true,
      minWidth: '200px',
      cell: (row: DealsAndPartneships) => (
        <div className="d-flex justify-content-between w-100 align-items-center p-1">
          <div className="p-1">{row.parceiro}</div>
          <div className="ml-3">
            <TableIconButton size="sm" forwardedAs={Link} to={row.link}>
              <FaMoneyCheck size={16} />
            </TableIconButton>
          </div>
        </div>
      ),
    },
    {
      name: t('pages:dealsAndPartneships.partneshipDescription'),
      selector: 'descricao',
      wrap: true,
      grow: 2,
      minWidth: '280px',
      maxWidth: 'calc(100vw - 60px)',
    },
  ];

  return (
    <Container>
      <PageTitle
        title={t('pages:dealsAndPartneships.title')}
        description={t('pages:dealsAndPartneships.subTitle')}
        icon={<FaIdCard color="#FFFFFF" size={24} />}
      />
      <TableWrapper>
        <TableHeader
          title={t('pages:dealsAndPartneships.title')}
          description={t('pages:dealsAndPartneships.subTitle')}
          helpContent={
            <div>
              <p>{t('pages:dealsAndPartneships.helpContent.p1')}</p>
              <p>{t('pages:dealsAndPartneships.helpContent.p2')}</p>
            </div>
          }
        />
        <TableSubHeader>
          <div />
          <div>
            <FilterInput
              onChange={value => setDealsAndPartneshipsFilterText(value)}
            />
          </div>
        </TableSubHeader>

        <DataTable
          noHeader
          dense
          subHeaderWrap
          columns={columns}
          data={filteredDealsAndPartneships}
          className="datatables-table"
        />
      </TableWrapper>
    </Container>
  );
};

export default DealsAndPartneships;
