/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import { Link } from 'react-router-dom';
import { decode } from 'html-entities';
import { FaQuestion } from 'react-icons/fa';
import { Card, Col, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import Loading from 'components/Loading';
import Error from 'components/Error';
import PanelButton from 'components/PanelButton';
import FormSubtitle from 'components/FormSubtitle';

import api from 'services/api';
import history from 'services/history';
import swal from 'services/swal';
import toast from 'services/toast';

import useTickets from 'hooks/useTickets';

import { StoreState } from 'store/createStore';

import { Container } from './styles';

interface PrioritiesResponse {
  data: {
    idPrioridade: number;
    prioridade: string;
  }[];
}

interface CategoriesResponse {
  data: {
    idCategoria: number;
    categoria: string;
  }[];
}

interface TechLevelResponse {
  data: {
    idNivelTecnico: number;
    nivelTecnico: string;
  }[];
}

interface SupportServicesResponse {
  data: {
    idAtendimentoTipo: number;
    identificador: string;
    idDepartamento: number;
    nome: string;
    padrao: boolean;
    maximoDeChamadosPorMes: number;
    chamadosConsumidosNesteMes: number;
    limiteAtingido: boolean;
    controleMensalDeTickets: boolean;
  }[];
}

interface UploadAttachmentResponse {
  data: {
    caminho: string;
    nome: string;
    tipo: string;
  };
}

interface CreateTicketResponse {
  data: {
    idTicket: number;
  };
}

interface SupportService {
  idAtendimentoTipo: number;
  idDepartamento: number;
  identificador: string;
  nome: string;
  padrao: boolean;
  maximoDeChamadosPorMes: number;
  chamadosConsumidosNesteMes: number;
  limiteAtingido: boolean;
  controleMensalDeTickets: boolean;
}

interface Priority {
  idPrioridade: number;
  prioridade: string;
}

interface Category {
  idCategoria: number;
  categoria: string;
}

interface TechLevel {
  idNivelTecnico: number;
  nivelTecnico: string;
}

interface NewTicketFormValues {
  idDepartment: string;
  subject: string;
  firstName: string;
  message: string;
  priority: number;
  category: number;
  techLevel: number;
  sensitiveData: string;
}

const New: React.FC = () => {
  const { t } = useTranslation();

  const { idSite, site } = useSelector((state: StoreState) => state.site.info);
  const { contaComSite } = useSelector(
    (state: StoreState) => state.client.info,
  );

  const { refetch: refetchTickets } = useTickets();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [priorities, setPriorities] = useState<Priority[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [techLevels, setTechLevels] = useState<TechLevel[]>([]);

  const [attachment, setAttachment] = useState<File>();
  const [attachmentError, setAttachmentError] = useState(false);

  const [supportServices, setSupportServices] = useState<SupportService[]>([]);
  const [
    selectedSupportService,
    setSelectedSupportService,
  ] = useState<SupportService>({
    idAtendimentoTipo: 1,
    idDepartamento: 1,
    identificador: 'sac',
    nome: 'SAC',
    padrao: true,
    maximoDeChamadosPorMes: 0,
    chamadosConsumidosNesteMes: 0,
    limiteAtingido: false,
    controleMensalDeTickets: false,
  });

  useEffect(() => {
    async function loadOptions() {
      try {
        setLoading(true);
        setError(false);

        const prioritiesCall = api.get<PrioritiesResponse>(
          'helpdesk/v1/prioridade',
        );

        const categoriesCall = api.get<CategoriesResponse>(
          'helpdesk/v1/categoria',
          { params: { visibilidade: 'PUBLICO' } },
        );

        const techLevelCall = api.get<TechLevelResponse>(
          'helpdesk/v1/nivel-tecnico',
        );

        const supportServicesCall = api.get<SupportServicesResponse>(
          'helpdesk/v1/consumo-chamado',
          {
            params: {
              ...(contaComSite && { idSite }),
            },
          },
        );

        const [
          prioritiesResponse,
          categoriesResponse,
          techLevelResponse,
          supportServicesResponse,
        ] = await Promise.all([
          prioritiesCall,
          categoriesCall,
          techLevelCall,
          supportServicesCall,
        ]);

        setPriorities(prioritiesResponse.data.data);
        setCategories(categoriesResponse.data.data);
        setTechLevels(techLevelResponse.data.data);

        const formattedSupportServices = supportServicesResponse.data.data.map(
          ({
            idAtendimentoTipo,
            idDepartamento,
            identificador,
            nome,
            padrao,
            maximoDeChamadosPorMes,
            chamadosConsumidosNesteMes,
            limiteAtingido,
            controleMensalDeTickets,
          }) => ({
            idAtendimentoTipo,
            idDepartamento,
            identificador,
            nome,
            padrao,
            maximoDeChamadosPorMes,
            chamadosConsumidosNesteMes,
            limiteAtingido,
            controleMensalDeTickets,
          }),
        );

        setSupportServices(formattedSupportServices);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    loadOptions();
  }, [contaComSite, idSite]);

  function testAttachment(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;

    if (!files) {
      setAttachment(undefined);
      setAttachmentError(false);

      return;
    }

    if (files.length === 0) {
      return;
    }

    const file = files[0];

    if (file.size > 4 * 1024 * 1024) {
      setAttachment(undefined);
      setAttachmentError(true);
    } else {
      setAttachment(file);
      setAttachmentError(false);
    }
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  async function uploadAttachment(file: File) {
    try {
      const formData = new FormData();

      formData.append('arquivo', file);

      const uploadAttachmentResponse = await api.post<UploadAttachmentResponse>(
        'https://a.apihn.co/v1/arquivo',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
          timeout: 15000,
        },
      );

      const { tipo, nome, caminho } = uploadAttachmentResponse.data.data;

      return {
        tipo,
        nome,
        caminho,
      };
    } catch (err) {
      return false;
    }
  }

  async function openTicket(
    values: NewTicketFormValues,
    formikHelpers: FormikHelpers<NewTicketFormValues>,
  ) {
    try {
      const anexos: {
        tipo: string;
        nome: string;
        caminho: string;
      }[] = [];

      const params = {
        responsavel: values.firstName,
        idPrioridade: values.priority,
        idCategoria: values.category,
        idNivelTecnico: values.techLevel,
        assunto: values.subject,
        mensagem: values.message,
        dadosSigilosos: values.sensitiveData,
        notificarCliente: 1,
        idDepartamento: values.idDepartment,
        anexos,
        origem: 'PAINEL',
        ...(contaComSite && { idSite }),
      };

      let uploadFileError = false;

      if (attachment) {
        const attachInfo = await uploadAttachment(attachment);

        if (attachInfo) {
          params.anexos = [attachInfo];
        } else {
          uploadFileError = true;
        }
      }

      const createTicketResponse = await api.post<CreateTicketResponse>(
        `helpdesk/v1/chamado`,
        params,
      );

      formikHelpers.resetForm();
      setAttachment(undefined);

      if (uploadFileError) {
        swal.fire({
          icon: 'warning',
          title: t('pages:ticketNew.attachWarningTitle'),
          html: t('pages:ticketNew.attachWarningText'),
        });
      } else {
        toast.fire(t('pages:ticketNew.success'));
      }

      await refetchTickets();

      const { idTicket } = createTicketResponse.data.data;

      history.push(`/helpdesk/ticket/${idTicket}`);
    } catch (err) {
      swal.fire({
        title: t('pages:ticketNew.fail'),
        html: err.response.data.error_description,
      });
    }
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:helpdesk.title')}
        description={t('titles:helpdesk.description')}
        icon={<FaQuestion color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:ticketNew.formTitle')}
          description={t('pages:ticketNew.formSubtitle')}
          helpContent={
            <div>
              <p>{t('pages:ticketNew.helpContent.l1a')}</p>
              <p>{t('pages:ticketNew.helpContent.l2')}</p>
              <p>{t('pages:ticketNew.helpContent.l3')}</p>
              <p>{t('pages:ticketNew.helpContent.l4')}</p>
              <p>{t('pages:ticketNew.helpContent.l5')}</p>
              <p>{t('pages:ticketNew.helpContent.l6')}</p>
            </div>
          }
        />

        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            idDepartment: '1',
            subject: '',
            firstName: '',
            message: '',
            priority: 3,
            category: 2,
            techLevel: 1,
            sensitiveData: '',
          }}
          validationSchema={Yup.object().shape({
            subject: Yup.string().required(
              t('pages:ticketNew.validation.subject'),
            ),
            message: Yup.string().required(
              t('pages:ticketNew.validation.message'),
            ),
            firstName: Yup.string().required(
              t('pages:ticketNew.validation.firstName'),
            ),
          })}
          onSubmit={openTicket}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <FormSubtitle subTitle={t('pages:ticketNew.dataTitle')} />

                <Form.Group as={Row}>
                  <Form.Label column sm={2}>
                    {t('pages:ticketNew.department')}
                  </Form.Label>
                  <Col sm={10}>
                    {supportServices.length === 1 && (
                      <Form.Control
                        plaintext
                        readOnly
                        value={supportServices[0].nome}
                      />
                    )}
                    {supportServices.length > 1 && (
                      <Form.Control
                        as="select"
                        name="idDepartment"
                        onChange={e => {
                          props.handleChange(e);

                          const service = supportServices.find(
                            supportService =>
                              supportService.idDepartamento ===
                              Number(e.target.value),
                          );

                          if (service) {
                            setSelectedSupportService(service);
                          }
                        }}
                      >
                        {supportServices.map(service => (
                          <option
                            key={service.idDepartamento}
                            value={service.idDepartamento}
                          >
                            {service.nome}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </Col>
                </Form.Group>

                {contaComSite && (
                  <Form.Group as={Row} controlId="ticketNew.Site">
                    <Form.Label column sm={2}>
                      {t('pages:ticketNew.site')}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        name="site"
                        plaintext
                        readOnly
                        defaultValue={site}
                      />
                    </Col>
                  </Form.Group>
                )}

                {selectedSupportService.controleMensalDeTickets && (
                  <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                      {t('pages:ticketNew.usedTickets')}
                    </Form.Label>
                    <Col
                      sm={10}
                      className="d-flex align-content-center align-self-center flex-column"
                    >
                      <div>
                        {t('pages:ticketNew.limitUsed', {
                          current:
                            selectedSupportService.chamadosConsumidosNesteMes,
                          limit: selectedSupportService.maximoDeChamadosPorMes,
                        })}
                      </div>
                      {selectedSupportService.chamadosConsumidosNesteMes ===
                        selectedSupportService.maximoDeChamadosPorMes && (
                        <div className="text-danger">
                          {t('pages:ticketNew.limitReached')}
                        </div>
                      )}
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row} controlId="ticketNew.FirstName">
                  <Form.Label column sm={2} className="required">
                    {t('pages:ticketNew.firstName')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={props.values.firstName}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.firstName && !!props.errors.firstName
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.firstName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {selectedSupportService.padrao && (
                  <>
                    <Form.Group as={Row} controlId="ticketNew.Priority">
                      <Form.Label column sm={2} className="required">
                        {t('pages:ticketNew.priority')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          name="priority"
                          value={props.values.priority}
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        >
                          {priorities.map(priority => (
                            <option
                              key={priority.idPrioridade}
                              value={priority.idPrioridade}
                            >
                              {priority.prioridade}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="ticketNew.Category">
                      <Form.Label column sm={2} className="required">
                        {t('pages:ticketNew.category')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          value={props.values.category}
                          name="category"
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        >
                          {categories.map(category => (
                            <option
                              key={category.idCategoria}
                              value={category.idCategoria}
                            >
                              {category.categoria}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="ticketNew.Level">
                      <Form.Label column sm={2} className="required">
                        {t('pages:ticketNew.level')}
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          as="select"
                          defaultValue="1"
                          name="techLevel"
                          onChange={props.handleChange}
                          disabled={props.isSubmitting}
                        >
                          {techLevels.map(techLevel => (
                            <option
                              key={techLevel.nivelTecnico}
                              value={techLevel.idNivelTecnico}
                            >
                              {decode(techLevel.nivelTecnico)}
                            </option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                  </>
                )}

                <Form.Group as={Row} controlId="ticketNew.Subject">
                  <Form.Label column sm={2} className="required">
                    {t('pages:ticketNew.subject')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="subject"
                      value={props.values.subject}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.subject && !!props.errors.subject
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.subject}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="ticketNew.Message">
                  <Form.Label column sm={2} className="required">
                    {t('pages:ticketNew.message')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="message"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      isInvalid={
                        !!props.touched.message && !!props.errors.message
                      }
                      disabled={props.isSubmitting}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.message}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="common:label.SensitiveData">
                  <Form.Label column sm={2}>
                    {t('pages:ticketNew.sensitiveData')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="sensitiveData"
                      value={props.values.sensitiveData}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      disabled={props.isSubmitting}
                    />
                    <Form.Text className="text-muted">
                      {t('pages:ticketNew.sensitiveDataDescription')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {props.errors.sensitiveData}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="newMessage.attachment">
                  <Form.Label column sm={2}>
                    {t('pages:ticketNew.attachment')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.File id="attachment" custom>
                      <Form.File.Input
                        className={attachmentError ? 'is-invalid' : ''}
                        onChange={testAttachment}
                        disabled={props.isSubmitting}
                        onBlur={() => setAttachmentError(false)}
                      />
                      <Form.File.Label
                        data-browse={t('pages:ticketNew.attachmentLabel')}
                      >
                        {attachment && attachment.name}
                      </Form.File.Label>
                    </Form.File>
                    <small className={attachmentError ? 'text-danger' : ''}>
                      {t('pages:ticketNew.maxAttachmentLength')}
                    </small>
                  </Col>
                </Form.Group>
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  className="mr-1"
                  disabled={
                    props.isSubmitting ||
                    !props.isValid ||
                    (selectedSupportService.controleMensalDeTickets &&
                      selectedSupportService.chamadosConsumidosNesteMes ===
                        selectedSupportService.maximoDeChamadosPorMes)
                  }
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>

                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/helpdesk"
                  disabled={props.isSubmitting}
                >
                  {t('common:back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default New;
